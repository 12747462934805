<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [spinnerText]="spinnerText"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            (gotoSite)="onGotoSite();"
    >

        <div class="toro-task-tracker-safeties-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">

                <div class="toro-mini-mode-content-panel">
                    <div class="toro-mini-mode-content">
                        <div class="tn-icon-row"><fa-icon [icon]="issuesIcon" [ngStyle]="{'color': issuesIconColor}"></fa-icon></div>

                        <div class="tmm-centered-column-flex-container">
                            <div class="tmm-absolute-container-host">
                                <div class="tmm-absolute-container tmm-ellipsis-text bold">{{'STRINGS.ISSUES' | translate}}</div>
                            </div>
                            <div class="tmm-absolute-container-host">
                                <div class="tmm-absolute-container tmm-ellipsis-text">{{todayDateString}}</div>
                            </div>
                        </div>

                    </div>

                    <div class="toro-mini-mode-content tn-value">{{todayIssueCount}}</div>
                </div>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [spinnerText]="spinnerText"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
    >

        <div class="toro-task-tracker-safeties-widget-content">
            <div class="toro-widget-content-pane ttl-size-one" [ngClass]="{'fade-on-resize': isResizing}">

                <div class="ttl-border-container ttl-so-top-container">
                    <toro-widget-link-button imageSrc="../../../../../assets/images/taskTrackerIcon_color.png" (click)="onTaskTrackerLinkClick()">
                    </toro-widget-link-button>

                    <div class="ttl-so-top-label toro-component-shield-host">
                        <span [ngClass]="language">{{'STRINGS.SAFETY_AND_TRAINING_ISSUES' | translate | uppercase}}</span>
                        <span *ngIf="showCompany" class="tt-st-summary-company">{{'STRINGS.COURSE' | translate}}: {{companyName}}</span>
                        <toro-component-shield></toro-component-shield>
                    </div>

                    <div *ngIf="isValidCompanyApiKeySet" class="ttl-dept-select-container">
                        <p-multiSelect
                                [options]="departmentsList"
                                [(ngModel)]="selectedDepartmentIds"
                                [styleClass]="'department-selector'"
                                [displaySelectedLabel]="true"
                                [selectedItemsLabel]="'STRINGS.N_DEPARTMENTS_SELECTED' | translate"
                                [appendTo]="'body'"
                                [defaultLabel]="'STRINGS.SELECT_DEPARTMENTS' | translate | titlecase"
                                (onPanelHide)="onDepartmentSelectionChange()"
                        ></p-multiSelect>
                    </div>

                    <div *ngIf="isValidCompanyApiKeySet" class="ttl-so-top-content">
                        <div class="tt-st-summary-container toro-component-shield-host">
                            <span class="tt-st-summary-icon"><fa-icon [icon]="issuesIcon" [ngStyle]="{'color': issuesIconColor}"></fa-icon></span>
                            <span class="tt-st-summary-count">{{todayIssueCount}}</span>
                            <div>
                                <div>{{(todayIssueCount !== 1 ? 'STRINGS.ISSUES_ON' : 'STRINGS.ISSUE_ON') | translate }}</div>
                                <div>{{todayDateString}}</div>
                            </div>
                            <toro-component-shield></toro-component-shield>
                        </div>
                        <div class="tt-st-history-container">
                            <div class="tt-st-hist-lbl-container toro-component-shield-host">
                                <span class="tt-st-hist-lbl">{{'STRINGS.SAFETY_HISTORY' | translate | uppercase}}</span><span class="tt-st-hist-date">{{historyDateString}}</span>
                                <toro-component-shield></toro-component-shield>
                            </div>
                            <div class="tt-st-hist-daily-counts-container">
                                <toro-task-tracker-daily-safety-item
                                        *ngFor="let item of summarySafetyCounts; let i = index;"
                                        [safetyItem]="item"
                                        [isToday]="i === summarySafetyCounts.length - 1"
                                ></toro-task-tracker-daily-safety-item>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isValidCompanyApiKeySet" class="tt-no-account-container toro-component-shield-host" >
                        No API Key Set
                    </div>
                </div>

            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-task-tracker-api-key-dialog
        *ngIf="isApiKeyDialogDisplayed"
        [apiKey]="newApiKey"
        (setApiKey)="onSetApiKey($event)"
        (cancelClick)="isApiKeyDialogDisplayed = false"
></toro-task-tracker-api-key-dialog>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
