<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [middleContent]="displayCols > 1 ? headerAlertInfo : null"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (middleContentClick)="onShowAlerts()"
    >

        <div class="toro-turf-guard-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tg-mm-temperature" class="toro-mini-mode-content">

                        <div id="tg-mm-left-panel">
                            <div>
                                <i class="tmm-ul-icon toro-id-tg-temperature"></i>
                            </div>
                            <div id="tg-mm-sensor-info">
                                <div *ngIf="turfGuardWidgetData?.highAlertsCount > 0" class="tmm-ellipsis-text">
                                    <fa-icon icon="info-circle"></fa-icon>
                                    <span class="sensor-count">{{turfGuardWidgetData.highAlertsCount}}</span>
                                    <span class="sensor-text">{{'STRINGS.SENSORS_HIGH' | translate}}</span>
                                </div>
                                <div *ngIf="turfGuardWidgetData?.lowAlertsCount > 0" class="tmm-ellipsis-text">
                                    <fa-icon icon="info-circle"></fa-icon>
                                    <span class="sensor-count">{{turfGuardWidgetData.lowAlertsCount}}</span>
                                    <span class="sensor-text">{{'STRINGS.SENSORS_LOW' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div id="tg-mm-right-panel">
                            <span class="tmm-large-value-label">{{'STRINGS.COURSE_AVERAGE' | translate}}</span>
                            <span class="tg-mm-value-container align-bottom">
                                <span class="tmm-small-value">{{averageCourseValueLabel || '--'}}</span>
                                <span class="tmm-small-value-label">{{turfGuardWidgetData ? chartValueUnits : '-'}}</span>
                            </span>

                            <toro-turf-guard-mini-mode-gauge
                                    *ngIf="turfGuardWidgetData"
                                    [rangeUnits]=""
                                    [rangeStart]="+chartMinValue.toFixed(0)"
                                    [rangeEnd]="+chartMaxValue.toFixed(0)"
                                    [lowThreshold]="turfGuardWidgetData.lowAlertLevel"
                                    [highThreshold]="turfGuardWidgetData.highAlertLevel"
                                    [value]="+averageCourseValueLabel"
                            ></toro-turf-guard-mini-mode-gauge>
                        </div>

                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [middleContent]="displayCols > 1 ? headerAlertInfo : null"
            [isBusy]="isBusy || (displayCols === 1 && !isGaugeLoaded)"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (middleContentClick)="onShowAlerts()"
    >

        <div class="toro-turf-guard-widget-content">

            <div *ngIf="displayCols === 1" class="toro-widget-content-pane toro-size-one toro-component-shield-host" [ngClass]="{'fade-on-resize': isResizing && isGaugeLoaded}">
                <div class="toro-flex-child turf-guard-gauge-container">
                    <highcharts-chart
                            *ngIf="gaugeOptions"
                            class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isGaugeLoaded}"
                            [Highcharts]="Highcharts"
                            [options]="gaugeOptions"
                    >
                    </highcharts-chart>
                </div>

                <div class="turf-guard-non-gauge-content" [ngClass]="{'fade-in-chart': isGaugeLoaded}">
                    <div class="turf-guard-course-avg-value">{{ averageCourseValueLabel }}<span class="turf-guard-units">˚{{averageCourseValueUnits}}</span></div>
                    <div class="turf-guard-course-avg-label">{{ 'STRINGS.COURSE_AVERAGE' | translate }}</div>
                    <div *ngIf="turfGuardWidgetData?.highAlertsCount > 0 || turfGuardWidgetData?.lowAlertsCount > 0" class="turf-guard-alerts-container">
                        <div *ngIf="turfGuardWidgetData?.highAlertsCount > 0" class="turf-guard-alerts-link">
                            <a (click)="onShowAlerts()">
                                <fa-icon icon="info-circle"></fa-icon>
                                {{ this.highAlertsString }}</a>
                        </div>
                        <div *ngIf="turfGuardWidgetData?.lowAlertsCount > 0" class="turf-guard-alerts-link">
                            <a (click)="onShowAlerts()">
                                <fa-icon icon="info-circle"></fa-icon>
                                {{ this.lowAlertsString }}</a>
                        </div>
                    </div>
                    <div class="toro-widget-link" [ngClass]="language">
                        <toro-widget-button
                                [text]="'STRINGS.VIEW_HOLES' | translate | titlecase"
                                (buttonClick)="onViewHoles()"
                        ></toro-widget-button>
                    </div>
                </div>

                <toro-component-shield></toro-component-shield>
            </div>

            <div #tgChartContainer *ngIf="displayCols > 1" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container [ngTemplateOutlet]="turfGuardChart"></ng-container>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<!-- Templates -->

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #headerAlertInfo>
    <toro-turf-guard-alerts-header [turfGuardWidgetData]="turfGuardWidgetData" [isResizing]="isResizing"></toro-turf-guard-alerts-header>
</ng-template>

<ng-template #turfGuardChart>
    <toro-turf-guard-chart
            *ngIf="turfGuardWidgetData"
            [turfGuardWidgetData]="turfGuardWidgetData"
            [chartValueUnits]="chartValueUnits"
            [chartDataValues]="chartDataValues"
            [chartMinValue]="chartMinValue"
            [chartMaxValue]="chartMaxValue"
            [chartDataLimits]="chartDataLimits"
            [displayCols]="displayCols"
            [isDialogContent]="this.showChartDialog"
            (pointClick)="onViewSensors($event)"
            (chartLoaded)="onChartLoaded()"
    ></toro-turf-guard-chart>
</ng-template>

<!-- Dialogs -->

<toro-turf-guard-chart-dlg *ngIf="showChartDialog" [title]="title" [turfGuardWidgetData]="turfGuardWidgetData" (cancel)="this.showChartDialog = false">
    <ng-container [ngTemplateOutlet]="turfGuardChart"></ng-container>
</toro-turf-guard-chart-dlg>

<toro-turf-guard-sensors-dlg
        *ngIf="showSensorsDialog"
        [title]="title"
        [sensorType]="TurfGuardSensorType.Temperature"
        [turfGuardWidgetData]="turfGuardWidgetData"
        [selectedZoneIndex]="selectedHoleIndex"
        [showAllAlerts]="showAllAlerts"
        (cancel)="showSensorsDialog = false; showAllAlerts = false;">
</toro-turf-guard-sensors-dlg>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
