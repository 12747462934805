<div class="drag-overlay-container">
    <div class="drag-overlay-icon-container">
        <div class="do-row do-row-r1">
            <fa-icon icon="arrow-up" [ngClass]="{'disabled': disableMoveUp}"></fa-icon>
        </div>
        <div class="do-row do-row-r2">
            <fa-icon *ngIf="!isMobileGridDisplay" id="do-left-arrow" icon="arrow-left" [ngClass]="{'disabled': disableMoveLeft || isMobileGridDisplay}"></fa-icon>
            <fa-icon *ngIf="!isMobileGridDisplay" id="do-right-arrow" icon="arrow-right" [ngClass]="{'disabled': isMobileGridDisplay}"></fa-icon>
        </div>
        <div class="do-row do-row-r3" style="display: flex; justify-content: center">
            <fa-icon icon="arrow-down"></fa-icon>
        </div>
    </div>
</div>
