<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [draggable]="false" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>
        <div id="toro-dlg-header-container" [ngClass]="language">
            <div *ngIf="isTitleCase" class="toro-flex-child">{{title | translate | titlecase}}</div>
            <div *ngIf="!isTitleCase" class="toro-flex-child">{{title | translate}}</div>
        </div>
    </p-header>

    <div id="toro-weather-graph-dlg-container">
        <ng-content></ng-content>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
