import * as moment from 'moment';
import { PwForecastData } from './pw-forecast-data.model';
import { PwValueUnit } from './pw-value-unit.model';

export class PwDailyForecast {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.date) this.date = moment.utc(json.date).toDate();
            if (json.ambientTemperatureMax) this.ambientTemperatureMax = new PwValueUnit(json.ambientTemperatureMax);
            if (json.ambientTemperatureMin) this.ambientTemperatureMin = new PwValueUnit(json.ambientTemperatureMin);
            if (json.dayTime) this.dayTime = new PwForecastData(json.dayTime);
            if (json.nightTime) this.nightTime = new PwForecastData(json.nightTime);
        }
    }

    date: Date;
    ambientTemperatureMax: PwValueUnit;
    ambientTemperatureMin: PwValueUnit;
    dayTime: PwForecastData;
    nightTime: PwForecastData;
}
