import * as moment from 'moment';
import { WeatherTimeSeries } from './weather-time-series.model';

export class WeatherGraphsData {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.dewPoint) this.dewPoint = new WeatherTimeSeries(json.dewPoint);
            if (json.wind) this.wind = new WeatherTimeSeries(json.wind);
            if (json.temperature) this.temperature = new WeatherTimeSeries(json.temperature);
            if (json.precipitation) this.precipitation = new WeatherTimeSeries(json.precipitation);
            if (json.solarRadiation) this.solarRadiation = new WeatherTimeSeries(json.solarRadiation);
            if (json.relativeHumidity) this.relativeHumidity = new WeatherTimeSeries(json.relativeHumidity);
        }
    }

    lastUpdated: Date;
    dewPoint: WeatherTimeSeries;
    wind: WeatherTimeSeries;
    temperature: WeatherTimeSeries;
    precipitation: WeatherTimeSeries;
    solarRadiation: WeatherTimeSeries;
    relativeHumidity: WeatherTimeSeries;
}
