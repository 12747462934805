import { Observable, of } from 'rxjs';
import { AppInjector } from 'src/app/demo/demo.module';
import { DecisionTreeApiService } from './decision-tree-api.service';
import { DecisionTreeConfig } from './models/decision-tree-config.model';
import { DemoModeMockDataService } from 'src/app/demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { SensorThresholdsModel } from './models/sensor-thresholds.model';
import { SensorThresholdsUpdateModel } from './models/sensor-thresholds-update.model';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class DecisionTreeManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private decisionTreeApiService: DecisionTreeApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDecisionTreeConfig(toroGridsterWidget: ToroGridsterWidget): Observable<DecisionTreeConfig> {
        if (environment.isDemoMode) {
            // Config associated with mock widget is properly set;
            return of(this.demoModeMockDataService.decisionTreeConfig);
        }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isDecisionTreeConfig')) {
            return of(<DecisionTreeConfig>toroGridsterWidget.config);
        }

        const config = new DecisionTreeConfig(toroGridsterWidget.config);
        return of(config);
    }

    getSensorThresholds(holeNumber?: number, sensorNumber?: number): Observable<SensorThresholdsModel[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getSensorThresholds(holeNumber))}

        return this.decisionTreeApiService.getSensorThresholds(holeNumber, sensorNumber);
    }

    addUpdateSensorThresholds(sensorThresholds: SensorThresholdsUpdateModel): Observable<void> {
        return this.decisionTreeApiService.addUpdateSensorThresholds(sensorThresholds);
    }

    deleteSensorThresholds(thresholdIds: number[]): Observable<void> {
        return this.decisionTreeApiService.deleteSensorThresholds(thresholdIds);
    }
}
