import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MyTurfApiService } from './my-turf-api.service';
import { MyTurfPartsOrder } from './models/my-turf-parts-order.model';
import { MyTurfTokenInfo } from '../auth/models/my-turf-token-info.model';
import { MyTurfUnavailableAssets } from './models/my-turf-unavailable-assets.model';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MyTurfManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private myTurfApiService: MyTurfApiService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiEndpoint(): Observable<string> {
        if (environment.isDemoMode) { return of(''); }

        return this.myTurfApiService.getApiEndpoint();
    }

    getPartsOrders(): Observable<MyTurfPartsOrder[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.myTurfPartsOrders); }
        return this.myTurfApiService.getPartsOrders();
    }

    getToken(): Observable<MyTurfTokenInfo> {
        return this.myTurfApiService.getToken();
    }

    getMyTurfUserName(): Observable<any> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.myTurfUserName); }

        return this.getToken()
            .pipe(
                switchMap((tokenInfo: MyTurfTokenInfo) => {
                    const decodedToken = new JwtHelperService().decodeToken(tokenInfo.token);
                    if (decodedToken == null || decodedToken.emails == null || decodedToken.emails[0] == null ) { return null; }

                    return decodedToken.emails;
                }));
    }

    getUnavailableAssets(): Observable<MyTurfUnavailableAssets[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.myTurfUnavailableAssets); }
        return this.myTurfApiService.getUnavailableAssets();
    }

    get myTurfLoginUrl(): string {
        const redirectUrl = encodeURI(window.location.origin);
        return `${environment.myTurfAuthUri}&client_id=${environment.myTurfClientId}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid offline_access&response_type=code&prompt=consent`;
    }

    goToMyTurfSite(page: string, myTurfToken: string, redirectUrl?: string) {
        const myTurfForm = document.createElement('form');
        myTurfForm.action = `${environment.myTurfUrl}/${page}`;
        myTurfForm.method = 'post';
        // myTurfForm.enctype = 'multipart/form-data';
        myTurfForm.target = '_blank';

        const idField = document.createElement('input');
        idField.setAttribute('type', 'hidden');
        idField.setAttribute('name', 'id_token');
        idField.setAttribute('value', myTurfToken);
        myTurfForm.appendChild(idField);

        if (redirectUrl != null) {
            const redirectUrlField = document.createElement('input');
            redirectUrlField.setAttribute('type', 'hidden');
            redirectUrlField.setAttribute('name', 'redirect_url');
            redirectUrlField.setAttribute('value', redirectUrl);
            myTurfForm.appendChild(redirectUrlField);
        }

        document.body.appendChild(myTurfForm);

        myTurfForm.submit();
        document.body.removeChild(myTurfForm);
    }

    get demoSiteLink(): string {
        return this.demoModeMockDataService.myTurfSiteLink;
    }

}
