import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'toro-demo',
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.less']
})
export class DemoComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.router.navigate(['/']);
    }

}
