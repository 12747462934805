<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu ss-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onSiteScoutLinkClick()"
    >

        <div class="toro-asset-tracking-widget-content toro-flex-child mini-mode-widget-container">
            <div class="toro-widget-content-pane">
                <ng-container [ngTemplateOutlet]="map"></ng-container>
            </div>

            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu ss-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onSiteScoutLinkClick()"
    >

        <div class="toro-asset-tracking-widget-content toro-flex-child toro-link-button-host">
            <ng-container [ngTemplateOutlet]="map"></ng-container>
            <toro-waiting-indicator *ngIf="isManualDataRefresh"></toro-waiting-indicator>

            <ng-container *ngIf="showMapOverlay" [ngTemplateOutlet]="mapOverlay"></ng-container>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="soil-scout-title-icon" src="../../../../../../assets/images/soil-scout/soil-scout-logo.png" alt="">
</ng-template>

<ng-template #map>
    <div id="soil-scout-map-container" [ngClass]="{'fade-in-on-load': fadeInMapDialog}">

        <p-gmap *ngIf="mapOptions != null"
                [options]="mapOptions"
                [overlays]="mapOverlays"
                [style]="{'width':'100%', 'height':'100%'}"
                (onMapReady)="onMapReady($event)"
                (onMapDragEnd)="onMapDragEnd()"
                (onZoomChanged)="onZoomChanged()"
        ></p-gmap>

        <div id="ssm-toolbar-container" *ngIf="!isGridsterInMobileMode || showMiniModeModal">
            <div id="ssm-button-container">
                <div id="ssm-recenter-button" class="ssm-button" (click)="onRecenterClick()">
                    {{ 'STRINGS.RECENTER' | translate }}
                </div>

                <p-dropdown
                        [options]="visualizationOptions"
                        [(ngModel)]="selectedMapView"
                        (onChange)="onMapViewChange()"
                ></p-dropdown>
            </div>
        </div>

        <div id="ssm-overlay-container" style="width: 695px; max-width: 98%;">
            <ng-container *ngIf="showMapOverlay" [ngTemplateOutlet]="mapOverlay"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #mapOverlay>
    <div id="ss-map-overlay">
        <div class="ss-map-overlay-container">
            <div class="ss-map-overlay-header">
                <div class="prevent-select">
                    <span id="ss-scout-name">{{ selectedDevice?.name }}</span>
                    <span id="ss-scout-status" class="connected" [ngClass]="{'not-connected': selectedDevice?.device_status != 'OK'}">
                        {{ (selectedDevice?.device_status == 'OK' ? 'STRINGS.CONNECTED' : 'STRINGS.NOT_CONNECTED') | translate }}
                    </span>
                    <span id="ss-scout-last-seen"> {{'STRINGS.LAST_SEEN_ON' | translate}}: {{overlayDateString}}</span>
                </div>
                <div class="ss-overlay-close-btn" (click)="closeMapOverlay()">
                    <span class="prevent-select">X</span>
                </div>
            </div>
            <div class="ss-map-overlay-content" >
                <div class="ss-map-overlay-panel">
                    <div class="ss-map-overlay-datum">
                        <toro-soil-scout-overlay-datum
                                [sensorType]="SoilScoutSensorType.Moisture"
                                [minValue]="moistureValueRanges.rangeMin"
                                [maxValue]="moistureValueRanges.rangeMax"
                                [value]="selectedDevice?.last_measurement.moisture"
                                (showSensorChart)="toggleSensorChartPanel($event)"
                        ></toro-soil-scout-overlay-datum>
                    </div>
                    <div class="ss-map-overlay-datum">
                        <toro-soil-scout-overlay-datum
                                [sensorType]="SoilScoutSensorType.Temperature"
                                [minValue]="temperatureValueRanges.rangeMin"
                                [maxValue]="temperatureValueRanges.rangeMax"
                                [value]="selectedDevice?.last_measurement.temperature"
                                (showSensorChart)="toggleSensorChartPanel($event)"
                        ></toro-soil-scout-overlay-datum>
                    </div>
                </div>
                <div class="ss-map-overlay-panel">
                    <div class="ss-map-overlay-datum">
                        <toro-soil-scout-overlay-datum
                                [sensorType]="SoilScoutSensorType.Salinity"
                                [minValue]="salinityValueRanges.rangeMin"
                                [maxValue]="salinityValueRanges.rangeMax"
                                [value]="selectedDevice?.last_measurement.salinity"
                                (showSensorChart)="toggleSensorChartPanel($event)"
                        ></toro-soil-scout-overlay-datum>
                    </div>
                    <div class="ss-map-overlay-datum">
                        <toro-soil-scout-overlay-datum
                                [sensorType]="SoilScoutSensorType.WaterBalance"
                                [minValue]="0"
                                [maxValue]="1"
                                [value]="selectedDevice?.last_measurement.water_balance"
                                (showSensorChart)="toggleSensorChartPanel($event)"
                        ></toro-soil-scout-overlay-datum>
                    </div>
                </div>
            </div>

            <div id="ss-map-overlay-backdrop"></div>
        </div>

        <div id="ss-sensor-chart-container" *ngIf="isSensorChartPanelVisible" [ngClass]="{'show': isSensorChartPanelDisplayed}">
            <div class="ss-map-overlay-container">

                <div class="ss-map-overlay-header">
                    <div id="ssmo-arrow" (click)="toggleSensorChartPanel(null)"><fa-icon class="prevent-select" icon="arrow-left"></fa-icon> </div>
                    <div id="ssmo-title" class="prevent-select">
                        <img [src]="'assets/images/soil-scout/' + sensorPanelIcon" alt="">
                        {{ sensorPanelTitle | translate }}
                        <div id="ssmo-device-name"><fa-icon icon="circle"></fa-icon> {{ selectedDevice?.name }}</div>
                    </div>
                    <div class="ss-overlay-close-btn" (click)="closeMapOverlay()">
                        <span class="prevent-select">X</span>
                    </div>
                </div>

                <div class="ss-map-overlay-content">
                    <toro-soil-scout-line-chart
                            [devices]="[selectedDevice]"
                            [scout]="selectedDevice"
                            [selectedSensorType]="overlaySensorType"
                    ></toro-soil-scout-line-chart>
                </div>
            </div>
        </div>

    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<toro-soil-scout-login-dlg
        *ngIf="isLoginDialogDisplayed"
        (loginClick)="onLogin($event)"
        (cancelClick)="isLoginDialogDisplayed = false"
></toro-soil-scout-login-dlg>

<toro-soil-scout-thresholds-dlg
        *ngIf="isThresholdsDialogDisplayed"
        [selectedTab]="SoilScoutThresholdTab.Moisture"
        [defaultMoistureRange]="defaultMoistureRange"
        [defaultSalinityRange]="defaultSalinityRange"
        [defaultTemperatureRange]="defaultTemperatureRange"
        [userPreferences]="SoilScoutWidgetBase.userPreferences"
        (cancelClick)="isThresholdsDialogDisplayed = false"
        (updateRanges)="onUpdateRanges($event)"
></toro-soil-scout-thresholds-dlg>
