import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { NsnAnnouncements } from './models/nsn-announcements.model';
import { NsnApiService } from './nsn-api.service';
import { NsnConnectDevice } from './models/nsn-connect-device.model';
import { NsnShipments } from './models/nsn-shipments.model';
import { NsnStatus } from './models/nsn-status.model';

@Injectable({
    providedIn: 'root'
})
export class NsnManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private nsnApiService: NsnApiService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiEndpoint(): Observable<any> {
        if (environment.isDemoMode) { return of(''); }

        return this.nsnApiService.getApiEndpoint();
    }

    getAnnouncements(): Observable<NsnAnnouncements> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.nsnAnnouncements); }
        return this.nsnApiService.getAnnouncements();
    }

    getStatus(): Observable<NsnStatus> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.nsnStatus); }
        return this.nsnApiService.getStatus();
    }

    getShipments(): Observable<NsnShipments> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.nsnShipments); }
        return this.nsnApiService.getShipments();
    }

    getNsnConnectDevices(): Observable<NsnConnectDevice[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.nsnConnectedDevices); }
        return this.nsnApiService.getNsnConnectDevices();
    }
}
