import { PogoPinInfo } from './pogo-pin-info.model';
import { PogoZoneConditions } from './pogo-zone-conditions.model';

export class PogoZone {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.conditions) this.conditions = new PogoZoneConditions(json.conditions);
            if (json.pins) this.pins = json.pins.map(p => new PogoPinInfo(p));
        }
    }

    zone_name: string;
    zone_type_name: string;
    zone_depth_meters: number;
    conditions: PogoZoneConditions;
    pins: PogoPinInfo[];
}
