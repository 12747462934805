export class GetStationsStation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.hwAddress = json.HwAddress;
            this.name = json.Name;
            this.id = json.Id;

            delete this['HwAddress'];
            delete this['Name'];
            delete this['Id'];
        }
    }

    hwAddress: string;
    id: number;
    name: string;
}
