<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{width: '900px', maxWidth: '95%'}">
    <p-header>{{'STRINGS.SEND_YOUR_FEEDBACK' | translate | titlecase}}</p-header>

    <div id="toro-user-feedback-container">
        <p-editor [(ngModel)]="messageContent" [style]="{'height':'350px'}" [placeholder]="'STRINGS.FEEDBACK_PLACEHOLDER' | translate">
            <p-header>
                <span class="ql-formats">
                    <select class="ql-size">
                        <option selected>{{'STRINGS.NORMAL' | translate}}</option>
                        <option value="large">{{'STRINGS.HEADING' | translate}}</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button type="button" class="ql-bold" [title]="'STRINGS.BOLD_TEXT' | translate" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" [title]="'STRINGS.ITALICIZE_TEXT' | translate" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" [title]="'STRINGS.UNDERLINE_TEXT' | translate" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color" [title]="'STRINGS.TEXT_COLOR' | translate" defaultValue="rgb(0, 0, 0)"></select>
                    <span class="ql-format-separator"></span>
                    <select class="ql-background" [title]="'STRINGS.BACKGROUND_COLOR' | translate" defaultValue="rgb(255, 255, 255)"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered" [title]="'STRINGS.NUMBERED_LIST' | translate"></button>
                    <button class="ql-list" value="bullet" [title]="'STRINGS.BULLETED_LIST' | translate"></button>
                </span>
                <span class="ql-formats">
                    <button aria-label="Image" class="ql-image" [title]="'STRINGS.ATTACH_IMAGE' | translate"></button>
                </span>
            </p-header>
        </p-editor>

        <toro-waiting-indicator
                *ngIf="isBusy"
                [isVisible]="isBusy"
                backgroundColor="rgba(0,0,0,.2)"
        ></toro-waiting-indicator>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
        <p-button type="button" [label]="'STRINGS.SEND' | translate" [disabled]="isBusy" (onClick)="onSave()"></p-button>
    </p-footer>
</p-dialog>

