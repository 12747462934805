import * as moment from 'moment';
import { Observable, of, tap } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DatePipe } from '@angular/common';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Site } from './models/site.model';
import { SiteApiService } from './site-api.service';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SiteManagerService {
    private site: Site;
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private siteApiService: SiteApiService,
                private datePipe: DatePipe
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }

        this.getSite()
            .pipe(take(1))
            .subscribe((site: Site) => this.site = site);

    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getSite(): Observable<Site> {
        if (environment.isDemoMode) return of(this.demoModeMockDataService.site);

        return this.siteApiService.getSite()
            .pipe(tap((site: Site) => this.site = site))
    }

    // =========================================================================================================================================================
    // Public Methods - non api
    // =========================================================================================================================================================

    // Method to get the day offset from a site's timezone to UTC.
    public siteDayOffsetFromUTC(): number {
        if (this.site == null) return this.siteDayOffsetFromUTC();

        // Get current UTC moment object.
        const utcMoment = moment.utc();
        const utcDay = utcMoment.date();

        // Create a moment object for the Site date
        const siteMoment = moment.utc(utcMoment).utcOffset(this.site.timeZoneInfo.offsetFromUtcInSeconds / 60);
        const siteDay = siteMoment.date();

        return siteDay - utcDay;
    }

    // Method to get the three letter day name (e.g., Sat) for a site for a given date. Sites in
    // different timezones can be on different days of the week.
    public getDayAtSiteFromUtcDate(utcDate: Date): string {
        if (this.site == null) return this.getDayAtSiteFromUtcDate(utcDate);

        // Note: We only care about the date portion of the date. The time is ignored.

        const currentSiteDate = new Date(utcDate);
        currentSiteDate.setDate(currentSiteDate.getDate() + this.siteDayOffsetFromUTC());

        return this.datePipe.transform(currentSiteDate, 'EEE');
    }

    public getSiteMomentFromLocalDate(date: Date): moment.Moment {
        if (this.site == null) return this.getSiteMomentFromLocalDate(date);

        // The series start time is in UTC. We first adjust to course time based on the UTC offsite associated with the site.
        const utcTime = moment.utc(date);
        const courseTime = utcTime.clone();
        return courseTime.utcOffset(Math.floor(this.site.timeZoneInfo.offsetFromUtcInSeconds / 60));
    }
}
