<div class="wiz-integration-content">
    <div id="full-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.ADD_INTEGRATION_KEY_FOR_PERRY_WEATHER' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
        </toro-step-group>

        <toro-step-action>
            <div>
                <img src="assets/images/wizard/perryWeather.png" alt="Perry Weather image">
            </div>
            <div class="wic-perry-weather-info">
                <div>{{'CASE_SENSITIVE.ENTER_PERRY_WEATHER_INTEGRATION_KEY' | translate}}</div>
                <p-inputMask
                        [(ngModel)]="integrationKey"
                        class="perry-weather-integration-key"
                        mask="********-****-****-****-************"
                        characterPattern="[a-fA-F0-9]"
                        [size]="40"
                        [placeholder]="'CASE_SENSITIVE.INTEGRATION_KEY' | translate"
                        (paste)="onPasteApiKey($event)"
                        (cut)="onCutApiKey($event)"
                ></p-inputMask>
                <div *ngIf="showError" id="wic-perry-weather-error">{{'SPECIAL_MSG.PERRY_WEATHER_DATA_COULD_NOT_BE_RETRIEVED' | translate}}</div>
                <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)"></toro-waiting-indicator>
            </div>
        </toro-step-action>
    </div>
</div>

<ng-template #step1>
    <div id="step1">
        {{'SPECIAL_MSG.PERRY_WEATHER_SW_ENTER_INTEGRATION_KEY' | translate}}
    </div>
</ng-template>

