<div id="tid-ezl-pp-summary-container">

    <div id="tid-ezm-placement-panel" class="prevent-select">
        <div id="tid-ezm-lr-placement-panel">
            <div id="tid-ezm-lrp-header" class="tid-ezm-header" [ngClass]="language">
                <div>{{'STRINGS.LEFT' | translate | uppercase}}</div>
                <div>{{'STRINGS.RIGHT' | translate | uppercase}}</div>
            </div>
            <div id="tid-ezm-lrp-holes">
                <toro-ez-locator-left-right-placement *ngFor="let h of pinSheet.pinSheetEntries" [pinSheet]="h"
                ></toro-ez-locator-left-right-placement>
            </div>
            <div id="tid-ezm-lrp-footer" class="tid-ezm-footer">
                <div>{{leftCount}}</div>
                <div>{{rightCount}}</div>
            </div>
        </div>
        <div id="tid-ezm-fmb-placement-panel">
            <div id="tid-ezm-fmb-header" class="tid-ezm-header">
                <div>{{'STRINGS.EZ_LOCATOR_FRONT_ABBR' | translate | uppercase}}</div>
                <div>{{'STRINGS.EZ_LOCATOR_MIDDLE_ABBR' | translate | uppercase}}</div>
                <div>{{'STRINGS.EZ_LOCATOR_BACK_ABBR' | translate | uppercase}}</div>
            </div>
            <div style="flex: 1; margin: 0 10px;">
                <toro-ez-locator-fmb-placement *ngFor="let h of pinSheet.pinSheetEntries" [pinSheet]="h"
                ></toro-ez-locator-fmb-placement>
            </div>
            <div id="tid-ezm-fmb-footer" class="tid-ezm-footer">
                <div>{{frontCount}}</div>
                <div>{{middleCount}}</div>
                <div>{{backCount}}</div>
            </div>
        </div>
    </div>

</div>
