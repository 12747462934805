/* eslint-disable @typescript-eslint/dot-notation */
import * as moment from 'moment';
import { Duration } from 'moment';

export class ToroStartingTime {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.Time) { this.time = new Date(json.Time); }
            if (json.TimeElapsed) { this.timeElapsed = moment.duration(json.TimeElapsed); }

            delete this['utcTime'];
        }
    }

    display: string;
    isNotValid: boolean;
    isValid: boolean;
    time: Date;
    timeElapsed: Duration;
}
