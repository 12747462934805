<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onGotoSite();"
    >

        <div class="toro-task-tracker-labor-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tmm-tt-labor-summary" class="toro-mini-mode-content" [ngClass]="language">
                        <i class="tmm-ul-icon toro-id-employees"></i>
                        <div class="tmm-bot-right-container">
                            <div class="tmm-medium-value tmm-br-value tmm-split-value"><span class="ttls-green">{{employeesScheduled}}</span><span class="ttls-orange">{{employeesOff}}</span></div>
                            <div class="tmm-ellipsis-text tmm-split-value tmm-split-value-labels">
                                <span class="tmm-ellipsis-text">{{'STRINGS.SCHEDULED' | translate}}</span><span class="tmm-ellipsis-text">{{'STRINGS.OFF' | translate}}</span>
                             </div>
                            <div class="tmm-bot-label tmm-ellipsis-text">{{'STRINGS.EMPLOYEE_SUMMARY' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="toro-mini-mode-content-panel ">
                    <div id="tmm-tt-task-assignments" class="toro-mini-mode-content" [ngClass]="language">
                        <i class="tmm-ul-icon toro-id-task-assignment"></i>
                        <div class="tmm-bot-right-container">
                            <div class="tmm-medium-value tmm-br-value tmm-split-value"><span class="ttls-green">{{tasksCompleted}}</span><span class="ttls-blue">{{tasksOutstanding}}</span></div>
                            <div class="tmm-ellipsis-text tmm-split-value tmm-split-value-labels">
                                <span class="tmm-ellipsis-text">{{'STRINGS.COMPLETED' | translate}}</span><span class="tmm-ellipsis-text">{{'STRINGS.OUTSTANDING' | translate}}</span>
                            </div>
                            <div class="tmm-bot-label tmm-ellipsis-text">{{'STRINGS.TASK_ASSIGNMENTS' | translate}}</div>
                        </div>
                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
    >

        <div class="toro-task-tracker-labor-widget-content">

            <div class="toro-widget-content-pane ttl-size-one" [ngClass]="{'fade-on-resize': isResizing}">
                <div class="ttl-border-container ttl-so-top-container" style="border: none; flex: none;">
                    <toro-widget-link-button imageSrc="../../../../../assets/images/taskTrackerIcon_color.png" (click)="onTaskTrackerLinkClick()">
                    </toro-widget-link-button>

                    <div class="ttl-so-top-label toro-component-shield-host">
                        <span [ngClass]="language">{{'STRINGS.TODAYS_LABOR_SUMMARY' | translate | uppercase}}</span>
                        <span *ngIf="showCompany" class="tt-st-summary-company">{{'STRINGS.COURSE' | translate}}: {{companyName}}</span>
                        <toro-component-shield></toro-component-shield>
                    </div>

                    <div class="ttl-dept-select-container">
                        <p-multiSelect
                                appendTo="body"
                                [options]="departmentsList"
                                [(ngModel)]="selectedDepartmentIds"
                                [styleClass]="'department-selector'"
                                [displaySelectedLabel]="true"
                                [defaultLabel]="'STRINGS.SELECT_DEPARTMENTS' | translate | titlecase"
                                (onPanelHide)="onDepartmentSelectionChange()"
                        ></p-multiSelect>
                    </div>

                    <div class="ttl-so-top-content toro-component-shield-host">
                        <div class="ttl-so-top-content-data left-content">
                            <div class="ttl-so-icon-container">
                                <fa-icon icon="file-invoice-dollar"></fa-icon>
                            </div>
                            <div class="ttl-so-data-container">
                                <div class="ttl-so-data-value">{{totalLaborDollars}}</div>
                                <div class="ttl-so-data-label" [ngClass]="language">{{'STRINGS.LABOR_DOLLARS' | translate | titlecase }}</div>
                            </div>
                        </div>
                        <div class="ttl-so-top-content-data right-content">
                            <div class="ttl-so-icon-container">
                                <fa-icon [icon]="['far', 'clock']"></fa-icon>
                            </div>
                            <div class="ttl-so-data-container">
                                <div class="ttl-so-data-value">{{totalLaborHours}}</div>
                                <div class="ttl-so-data-label" [ngClass]="language">{{'STRINGS.LABOR_HOURS'  | translate | titlecase }}</div>
                            </div>
                        </div>

                        <toro-component-shield></toro-component-shield>
                    </div>
                </div>

                <div class="ttl-so-bottom-container toro-component-shield-host">
                    <div class="ttl-border-container ttl-so-bottom-data-container ttl-so-bottom-left-container">
                        <div class="ttl-data-container-header">
                            <div class="ttl-header-icon-container">
                                <fa-icon icon="user"></fa-icon>
                            </div>
                            <span class="ttl-header-label" [ngClass]="language">{{'STRINGS.EMPLOYEE_SUMMARY' | translate | uppercase}}</span>
                        </div>
                        <div class="ttl-progress-bar-container ttl-emp-sum-pb" [ngClass]="{'has-values': employeeSummaryHasValues}">
                            <p-progressBar [value]="employeeSummaryProgressValue" [showValue]="false"></p-progressBar>
                        </div>
                        <div class="ttl-data-container-values">
                            <div class="ttl-values-container">
                                <div class="ttl-values-value ttl-values-value-green">{{employeesScheduled}}</div>
                                <div class="ttl-values-label">{{'STRINGS.SCHEDULED' | translate}}</div>
                            </div>
                            <div class="ttl-values-container">
                                <div class="ttl-values-value ttl-values-value-orange">{{employeesOff}}</div>
                                <div class="ttl-values-label">{{'STRINGS.OFF' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="ttl-border-container ttl-so-bottom-data-container ttl-so-bottom-right-container">
                        <div class="ttl-data-container-header">
                            <div class="ttl-header-icon-container">
                                <fa-icon icon="tasks"></fa-icon>
                            </div>
                            <span class="ttl-header-label" [ngClass]="language">{{'STRINGS.TASK_ASSIGNMENTS' | translate | uppercase}}</span>
                        </div>
                        <div class="ttl-progress-bar-container ttl-task-assignment-pb" [ngClass]="{'has-values': taskAssignmentsHasValues}">
                            <p-progressBar [value]="taskAssignmentsProgressValue" [showValue]="false"></p-progressBar>
                        </div>
                        <div class="ttl-data-container-values">
                            <div class="ttl-values-container">
                                <div class="ttl-values-value ttl-values-value-green">{{tasksCompleted}}</div>
                                <div class="ttl-values-label">{{'STRINGS.COMPLETED' | translate}}</div>
                            </div>
                            <div class="ttl-values-container">
                                <div class="ttl-values-value ttl-values-value-blue">{{tasksOutstanding}}</div>
                                <div class="ttl-values-label">{{'STRINGS.OUTSTANDING' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <toro-component-shield></toro-component-shield>
                </div>
            </div>

            <div *ngIf="displayRows === 2 || isGridsterInMobileMode" class="toro-widget-content-pane ttl-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                <div class="ttl-border-container ttl-chart-container">
                    <div class="ttl-chart-header toro-component-shield-host">
                        <span class="ttl-chart-title">{{'STRINGS.COST_BY_AREA' | translate | uppercase}}</span>
                        <ng-container [ngTemplateOutlet]="costByAreaLegend"></ng-container>

                        <toro-component-shield></toro-component-shield>
                    </div>

                    <toro-task-tracker-labor-summary-chart *ngIf=!hideChartOnChange [data]="dailySummary" [departmentIdsFilter]="selectedDepartmentIdsForChart" (chartClick)="onChartClick()"></toro-task-tracker-labor-summary-chart>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #costByAreaChart>
    <toro-task-tracker-labor-summary-chart [data]="dailySummary" [departmentIdsFilter]="selectedDepartmentIdsForChart" (chartClick)="onChartClick()"></toro-task-tracker-labor-summary-chart>
</ng-template>

<ng-template #costByAreaLegend>
    <div class="ttl-chart-legend">
        <div class="ttl-chart-legend-item">
            <div class="ttl-chart-legend-color-swatch ttl-chart-legend-reg-pay"></div>
            <span class="ttl-chart-legend-label">{{'STRINGS.REG_PAY' | translate}}</span>
        </div>
        <div class="ttl-chart-legend-item">
            <div class="ttl-chart-legend-color-swatch ttl-chart-legend-ot-pay"></div>
            <span class="ttl-chart-legend-label">{{'STRINGS.OVERTIME' | translate}}</span>
        </div>
    </div>
</ng-template>

<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="modalTitle" [isTitleCase]="false" (cancel)="toggleChartDisplay(false)">
    <div id="task-tracker-chart-dlg-container">
        <ng-container *ngIf="showChart" [ngTemplateOutlet]="costByAreaChart"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="costByAreaLegend"></ng-container>
</toro-weather-graph-chart-dlg>

<toro-task-tracker-api-key-dialog
        *ngIf="isApiKeyDialogDisplayed"
        [apiKey]="newApiKey"
        (setApiKey)="onSetApiKey($event)"
        (cancelClick)="onCancelSetApiKey()"
></toro-task-tracker-api-key-dialog>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" title="Mini Mode Modal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
