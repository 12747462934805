<div id="enable-step-group-container">
    <div id="esg-header">
        <img src="../../../../../../assets/images/wizard/{{widgetGroup?.image}}" alt="">
    </div>
    <div id="esg-body">
        <toro-enable-widget-toggle
                *ngFor="let w of widgetGroup?.widgets"
                [widget]="w"
        ></toro-enable-widget-toggle>
    </div>

</div>
