<div class="tt-dm-item-container toro-component-shield-host">
    <div class="tt-dm-label-wrapper">
        <div class="tt-dm-item-label tt-dept-name">{{dailyMeasurement.departmentName}}</div>
        <div class="tt-dm-item-label">{{dailyMeasurement.name}}</div>
    </div>
    <div class="tt-dm-value-wrapper">
        <div class="tt-dm-item-value-container">
            <div>
                <span class="tt-dm-item-value">{{userCurrentAvg}}</span>
                <span class="tt-dm-item-units">{{userUnits}}</span>
            </div>
        </div>
        <div class="tt-dm-item-icon"><fa-icon [icon]="icon" [ngStyle]="{'color': iconColor}"></fa-icon></div>
    </div>
    <toro-component-shield></toro-component-shield>
</div>
