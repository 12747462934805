import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-decision-tree-breakdown-item',
    templateUrl: './decision-tree-breakdown-item.component.html',
    styleUrls: ['./decision-tree-breakdown-item.component.less']
})
export class DecisionTreeBreakdownItemComponent implements OnInit {
    @HostBinding('class') class = 'toro-decision-tree-breakdown-item'

    @ViewChild('thresholdValue') thresholdValue

    @Input() headerIcon: any;
    @Input() iconSize: string;
    @Input() headerText: string;
    @Input() itemColor = 'black';
    @Input() gaugeBackgroundColor = 'white';
    @Input() decimalPlaces = 0
    @Input() thresholdDecimalPlaces = 0;
    @Input() valueUnits: string = null;
    @Input() styleClass: string;

    private _rangeBottom = 0;
    @Input() set rangeBottom(value: number) {
        this._rangeBottom = value;
        this.setDisplayValues();
    }

    get rangeBottom(): number {
        return this._rangeBottom;
    }

    private _rangeTop = 0;
    @Input() set rangeTop(value: number) {
        this._rangeTop = value;
        this.setDisplayValues();
    }

    get rangeTop(): number {
        return this._rangeTop;
    }

    private _threshold = 60;
    @Input() set threshold(value: number) {
        this._threshold = value;
        this.setDisplayValues();
    }

    get threshold(): number {
        return this._threshold;
    }

    private _value = 0;
    @Input() set value(val: number) {
        this._value = val;
        this.setDisplayValues();
    }

    get value(): number {
        return this._value;
    }

    isMetric = false;
    meterPercentage = 100;
    thresholdPercentage = 50;
    thresholdValueHOffset = 0;
    tooltipText = '';
    displayValue = '--';
    displayRangeTop = '--';
    displayRangeBottom = '--';
    displayThreshold = '--';
    timeoutRef: NodeJS.Timeout;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.setPercentages();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDisplayValues());

        this.setDisplayValues();

        // this.tooltipText = `${this.translateService.instant('STRINGS.VALUE')}: ${this.value.toFixed(this.decimalPlaces)}${this.valueUnits} \
        //                     \r\n${this.translateService.instant('STRINGS.THRESHOLD')}: ${this.threshold.toFixed(this.thresholdDecimalPlaces)}${this.valueUnits} \
        //                     \r\n${this.translateService.instant('STRINGS.RANGE')}: (${this.rangeBottom}${this.valueUnits}-${this.rangeTop}${this.valueUnits})`
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        clearTimeout(this.timeoutRef);

        this.timeoutRef = setTimeout(() => {
            this.isMetric = this.userFormatService.isMetric;

            this.displayValue = this.userFormatService.toUserNumber(this.value, this.decimalPlaces, this.decimalPlaces);
            this.displayRangeTop = this.userFormatService.toUserNumber(this.rangeTop, 0, this.decimalPlaces);
            this.displayRangeBottom = this.userFormatService.toUserNumber(this.rangeBottom, 0, this.decimalPlaces);
            this.displayThreshold = this.userFormatService.toUserNumber(this.threshold, this.decimalPlaces, this.decimalPlaces);
        }, 100)
    }

    private setPercentages() {
        setTimeout(() => {
            this.meterPercentage = (this.value - this.rangeBottom) / (this.rangeTop - this.rangeBottom);
            if (this.meterPercentage < this.rangeBottom) {
                this.meterPercentage = this.rangeBottom;
            } else if (this.meterPercentage > this.rangeTop) {
                this.meterPercentage = this.rangeTop;
            }

            this.meterPercentage *= 100;
            this.thresholdPercentage = (this.threshold - this.rangeBottom) / (this.rangeTop - this.rangeBottom) * 100;
            this.thresholdValueHOffset = this.thresholdValue.nativeElement.clientHeight / 2;
        })
    }
}
