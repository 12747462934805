<div id="integration-card-container">
    <div id="integration-card-header" class="prevent-select">
        <img [src]="integration.headerImage" [ngStyle]="{'height': integration.headerImageHeightInPixels + 'px'}" [alt]="'STRINGS.WIDGET_LOGO' | translate">
    </div>

    <div id="integration-card-body" class="prevent-select">
        {{integration.description | translate}}
    </div>

    <div id="integration-card-footer" [ngClass]="{'no-link': !integration?.linkText}">
        <a *ngIf="integration?.linkText" href="javascript:void(0)" (click)="onLinkClick()">{{integration.linkText | translate}}</a>
        <div id="integration-card-button" (click)="onSetup()">
            <img src="/assets/images/wizard/setup.png" alt="">
            {{'STRINGS.SETUP' | translate | uppercase}}
        </div>
    </div>

    <div id="configured-badge" [ngClass]="{'is-configured': integration.isConfigured}">
        <fa-icon icon="check-circle" [pTooltip]="(integration.isConfigured ? 'STRINGS.CONFIGURED' : 'STRINGS.NOT_CONFIGURED') | translate" tooltipPosition="top"></fa-icon>
    </div>
</div>
