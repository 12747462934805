import { LynxPercentAdjust } from './mqtt-percent-adjust/lynx-percent-adjust.model';

export class StationAdjustInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    programId: number;
    programName: string;
    stationId: number;
    adjustment: LynxPercentAdjust;

}
