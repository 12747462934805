<div class="lynx-station-status-card-container toro-component-shield-host" *ngIf="stationInfo != null">
    <div *ngIf="!useFilteredLayout" id="icon-container" [ngClass]="{'status-running': stationInfo.status == StationStatus.Running, 'status-ran-under': stationInfo.status == StationStatus.RanUnder}">
        <fa-icon icon="tint"></fa-icon>
    </div>
    <div *ngIf="!useFilteredLayout"><span class="lss-area-station">{{stationInfo.area}} / {{stationInfo.hole}} / </span><span class="lss-station">{{stationInfo.station}}</span></div>

    <div *ngIf="useFilteredLayout" class="lssc-filtered-layout">
        <span class="lss-area-station">{{stationInfo.area}} / {{stationInfo.hole}}</span><span class="lss-station">{{stationInfo.station}}</span>
    </div>
    <toro-component-shield></toro-component-shield>
</div>

<div class="lynx-station-status-card-container toro-component-shield-host" *ngIf="stationInfo == null && useFilteredLayout" style="justify-content: center; border: unset; box-shadow: unset;">
    No stations are in this state.
</div>
