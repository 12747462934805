export class TaskTrackerCostSummary {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    name: string;
    regHours: number;
    regWages: number;
    otHours: number;
    otWages: number;
    totalHours: number;
    totalWages: number;
}
