import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashAuthenticatedUser } from '../../../../../api/auth/models/dash-authenticated-user.model';
import { PlaybooksLinkUrl } from '../../../../../api/playbooks/models/playbooks-link-url.model';
import { PlaybooksManagerService } from '../../../../../api/playbooks/playbooks-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../../models/wizard-settings.model';

@UntilDestroy()
@Component({
    selector: 'toro-playbooks-integration',
    templateUrl: './playbooks-integration.component.html',
    styleUrls: ['./playbooks-integration.component.less']
})
export class PlaybooksIntegrationComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'wiz-integration-component  toro-playbooks-integration';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();
    @Output() settingsChange = new EventEmitter<WizardSettings>();

    @Input() settings: WizardSettings;

    private readonly courseUrlSearchString = '?courseurl=';
    isBusy = false;
    showError = false;
    spinnerText = 'CASE_SENSITIVE.VERIFYING_PLAYBOOKS_COURSE_ID'
    readonly stepsCount = 1;
    private elements: any;

    playbooksUrl: string;
    courseId: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private playbooksManager: PlaybooksManagerService
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.verifyConfiguration();

                // this.broadcastService.setupToggleWidgetState.next({type: WidgetType.Playbooks, isEnabled: true});
                // this.settings.playbooksCourseId = this.playbooksUrl;
                // this.settingsChange.next(this.settings);
                // this.configured.next(WizardIntegration.Playbooks);
            });

        // We want to modify the behavior of the p-inputNumber component to allow us to assess the controls
        // value after each key press. To do that, we will search the dom for our unique container component
        // and then find out "common" p-inputNumber class element. There will only be one in our container.
        // Once we have our element, we will assign the keyup event handler.
        const container = document.getElementsByClassName('wic-playbooks-info');
        this.elements = container[0].getElementsByClassName('playbooks-url');

        if (this.elements.length != 1) throw new Error('There should be one and only one p-inputNumber element here.');
        this.elements[0].addEventListener('keyup', this.keyUp.bind(this))

        if (this.settings && this.settings.playbooksCourseId != null) {
            this.courseId = this.settings.playbooksCourseId;
            this.canConnect.next(true);
        }
    }

    ngOnDestroy() {
        this.elements[0].removeEventListener('keyup', this.keyUp.bind(this))
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    onNavigateToSite() {
        // TODO: Navigate to Playbooks URL that allows for login (if one exists). If not, we may need to change the underlying logic of this integration
        //       to simply allow the user to enter a CourseId, like we do on the widget.
        //
        //       If we do end up grabbing the URL, we will need to parse the url to grab the CourseId.

        alert('Not implemented: Need Login URL.')
        // this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_MY_TURF_SITE' });
        // setTimeout(() => window.open(environment.playbooksBaseLinkUrl, '_blank'), 1000);
        // setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    private keyUp(event) {
        this.courseId = '--';
        this.canConnect.next(false);

        if (event.target.value.length >= 47 && event.target.value.includes(this.courseUrlSearchString)) {
            this.parseUrl(event.target.value);
        }
    }

    onParseUrl(event: ClipboardEvent) {
        this.courseId = '--';
        this.canConnect.next(false);

        const pastedText = event?.clipboardData?.getData('text');
        if (pastedText && pastedText.includes(this.courseUrlSearchString)) {
            this.parseUrl(pastedText);
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private parseUrl(url: string) {
        const courseId = url.substring(url.indexOf(this.courseUrlSearchString) + this.courseUrlSearchString.length).trim();
        if (courseId && courseId.length === 36) {
            this.courseId = courseId;
            this.canConnect.next(true);
        }
    }

    private verifyConfiguration() {
        this.showError = false;
        this.isBusy = true;

        this.playbooksManager.setCourseId(this.courseId)
            .pipe(
                take(1),
                switchMap(result => {
                    this.authManager.setDashAuthenticatedUser(new DashAuthenticatedUser(result));
                    this.courseId = this.authManager.dashAuthenticatedUser.playbooksCourseId;

                    return this.playbooksManager.getLinkUrls()
                        .pipe(
                            take(1),
                            finalize(() => this.isBusy = false)
                        )
                })
            )
            .subscribe({
                next: (result: PlaybooksLinkUrl[]) => {
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.Playbooks, isEnabled: true });
                    this.settings.playbooksCourseId = this.courseId;
                    this.settingsChange.next(this.settings);
                    this.configured.next(WizardIntegration.Playbooks);

                    // Notify TaskTracker Widgets. This will only matter if the TaskTracker widgets are already being displayed.
                    this.broadcastService.setupPlaybooksCourseIdChanged.next(this.courseId);
                },
                error: err => {
                    this.showError = true;
                    this.canConnect.next(false);
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.Playbooks, isEnabled: false });
                }
            });

    }

}
