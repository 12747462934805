<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="tid-ezl-dialog">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="td-header-left">
                <img id="tid-ez-title-icon" src="../../../../../../assets/images/soil-scout/soil-scout-logo.png" alt="">
                <div class="toro-flex-child">{{title | translate}} {{'WIDGET.SOIL_SCOUT' | translate | titlecase}}</div>
            </div>
            <div id="td-header-right">
                <div class="toro-widget-toolbar-button" (click)="onGotoSite()"><fa-icon icon="external-link-alt"></fa-icon></div>
            </div>
        </div>
    </p-header>

    <div id="toro-modal-content-container">
        <div id="toro-modal-content-wrapper">

            <div id="ss-modal-toolbar">
                <div id="ss-modal-btn-container">
                    <div class="ss-modal-toolbar-btn"
                         [ngClass]="{'is-selected': selectedSensorType == SoilScoutSensorType.Moisture}"
                         (click)="onSensorSelection(SoilScoutSensorType.Moisture)"
                    >
                        <span class="prevent-select">{{'STRINGS.MOISTURE' | translate}}</span>
                    </div>
                    <div class="ss-modal-toolbar-btn"
                         [ngClass]="{'is-selected': selectedSensorType == SoilScoutSensorType.Salinity}"
                         (click)="onSensorSelection(SoilScoutSensorType.Salinity)"
                    >
                        <span class="prevent-select">{{'STRINGS.SALINITY' | translate}}</span>
                    </div>
                    <div class="ss-modal-toolbar-btn"
                         [ngClass]="{'is-selected': selectedSensorType == SoilScoutSensorType.Temperature}"
                         (click)="onSensorSelection(SoilScoutSensorType.Temperature)"
                    >
                        <span class="prevent-select">{{'STRINGS.TEMPERATURE' | translate}}</span>
                    </div>
                    <div class="ss-modal-toolbar-btn"
                         [ngClass]="{'is-selected': selectedSensorType == SoilScoutSensorType.WaterBalance}"
                         (click)="onSensorSelection(SoilScoutSensorType.WaterBalance)"
                    >
                        <span class="prevent-select">{{'CASE_SENSITIVE.WATER_BALANCE' | translate}}</span>
                    </div>
                </div>
            </div>

            <div id="ss-chart-container">
                <toro-soil-scout-bar-chart
                        [chartData]="chartData"
                        [sensorType]="selectedSensorType"
                        (sensorSelected)="onShowSensorDetails($event)"
                ></toro-soil-scout-bar-chart>
            </div>

            <div id="ss-sensor-chart-container" *ngIf="isSensorChartPanelVisible" [ngClass]="{'show': isSensorChartPanelDisplayed}">
                    <div id="ss-sensor-chart-toolbar" [ngClass]="{'is-mobile': isMobile}">
                        <div id="sensor-tb-header" (click)="toggleSensorChartPanel()"><span id="header-text" class="prevent-select"><fa-icon icon="arrow-left"></fa-icon>{{selectedSoilScout.name}}</span></div>
                        <div id="sensor-btn-container" >
                            <div class="sensor-btn" [ngClass]="{'selected': selectedSensorType == SoilScoutSensorType.Moisture}" (click)="selectedSensorType = SoilScoutSensorType.Moisture">
                                <span class="prevent-select">{{ 'STRINGS.MOISTURE' | translate }}</span>
                            </div>
                            <div class="sensor-btn" [ngClass]="{'selected': selectedSensorType == SoilScoutSensorType.Salinity}" (click)="selectedSensorType = SoilScoutSensorType.Salinity">
                                <span class="prevent-select">{{ 'STRINGS.SALINITY' | translate }}</span>
                            </div>
                            <div class="sensor-btn" [ngClass]="{'selected': selectedSensorType == SoilScoutSensorType.Temperature}" (click)="selectedSensorType = SoilScoutSensorType.Temperature">
                                <span class="prevent-select">{{ 'STRINGS.TEMPERATURE' | translate }}</span>
                            </div>
                            <div class="sensor-btn" [ngClass]="{'selected': selectedSensorType == SoilScoutSensorType.WaterBalance}" (click)="selectedSensorType = SoilScoutSensorType.WaterBalance">
                                <span class="prevent-select">{{ 'CASE_SENSITIVE.WATER_BALANCE' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div style="flex: 1; display: flex; border: 1px solid #f4f4f4;">
                        <toro-soil-scout-line-chart
                            [devices]="chartData.devices"
                            [scout]="selectedSoilScout"
                            [selectedSensorType]="selectedSensorType"
                        ></toro-soil-scout-line-chart>
                    </div>
            </div>

        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
