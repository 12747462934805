import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from './broadcast.service';
import { DashUserInfo } from '../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../api/dash-user/models/dash-user-preferences.model';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { take } from 'rxjs/operators';
import { ToroAnalyticsEnums } from '../enumerations/analytics.enums';
import { ToroEnums } from '../enumerations/toro.enums';
import { ToroNotification } from '../../api/notifications/models/toro-notification.model';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import SiteDataCategoryType = ToroEnums.SiteDataCategoryType;
import AlertType = ToroEnums.AlertType;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import { MixpanelEvent } from './models/mixpanel-event.model';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private isAnalyticsHostInitialized = false;
    private lastReceivedNotificationId: number;
    private dashUserPreferences: DashUserPreferences;
    private siteName: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private translateService: TranslateService
    ) {

        if (!environment.enableAnalytics || environment.isDemoMode) { return; }

        this.dashUserManager.getDashUserInfo()
            .pipe(take(1))
            .subscribe((userInfo: DashUserInfo) => {
                this.dashUserPreferences = userInfo.preferences;
                this.lastReceivedNotificationId = this.dashUserPreferences.lastReceivedNotificationId;
            });

        this.broadcastService.notificationsRetrieved
            .pipe(untilDestroyed(this))
            .subscribe((notifications: ToroNotification[]) => {
                if (this.lastReceivedNotificationId == null) { return; }
                this.sendNotificationsAnalytics(notifications.filter(n => n.id > this.lastReceivedNotificationId));
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe((userPrefs: DashUserPreferences) => this.dashUserPreferences = userPrefs);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    // Use for events that occur before system is fully initialized, e.g., login events.
    public initEvent(eventName: string, category: string, user: string, siteName?: string) {
        this.siteName = siteName;

        if (!environment.enableAnalytics) { return; }

        if (!this.isAnalyticsHostInitialized) { this.initMixpanel(); }

        mixpanel.track(eventName, new MixpanelEvent({
            id_source: this.eventSource,
            id_category: category,
            id_site: this.siteName,
            id_user:  user,

            distinct_id: `${this.eventSource} | ${user}`
        }));
    }

    public event(eventName: string, category: string, details?: string) {
        if (!environment.enableAnalytics) { return; }

        if (!this.isAnalyticsHostInitialized) { this.initMixpanel(); }

        mixpanel.track(eventName, new MixpanelEvent({
            id_source: this.eventSource,
            id_category: category,
            id_site: this.siteName,
            id_user:  `${this.dashUserManager.userEmail}`,
            id_details: details,

            distinct_id: this.distinctId
        }));
    }

    public widgetEvent(eventName: string, category: string, widgetName: string, details?: string) {
        if (!environment.enableAnalytics) { return; }

        if (!this.isAnalyticsHostInitialized) { this.initMixpanel(); }

        mixpanel.track(eventName, new MixpanelEvent({
            id_source: this.eventSource,
            id_category: category,
            id_site: this.siteName,
            id_user: `${this.dashUserManager.userEmail}`,
            id_widget: widgetName,
            id_details: details,

            distinct_id: this.distinctId
        }));
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private initMixpanel() {
        mixpanel.init('f185c9dc08b6f834b29bb17bc01b3202');
    }

    private sendNotificationsAnalytics(notifications: ToroNotification[]) {
        if (notifications.length < 1 || this.lastReceivedNotificationId < 0) { return; }

        const shortList = notifications.sort((a, b) => a.id - b.id);
        this.lastReceivedNotificationId = shortList[shortList.length - 1].id;

        shortList.forEach(n => {
            const eventLabel = `Cat: ${SiteDataCategoryType[n.categoryType]}, Type: ${AlertType[n.alertType]}, Text: ${n.text}`;
            this.event(AnalyticsEvent.NotificationReceived, AnalyticsCategory.Notifications, eventLabel);
        });

        this.updateDashUserPrefs();
    }

    private updateDashUserPrefs() {
        this.dashUserPreferences.lastReceivedNotificationId = this.lastReceivedNotificationId;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPreferences).subscribe();
    }

    private get eventSource(): string {
        return `${this.translateService.instant('TORO.APP_NAME')} - ${environment.name}`;
    }

    private get distinctId(): string {
        return `${this.eventSource} | ${this.dashUserManager.userEmail}`;
    }
}
