<div class="waiting-indicator-container" [ngClass]="{'waiting-indicator-fade-out': !isVisible}" [style.background]="backgroundColor">
    <div *ngIf="!showError" class="waiting-indicator-content" [ngStyle]="{'color': color}">
        <div id="progress-spinner-wrapper">
<!--            <fa-icon icon="asterisk" class="fa-spin"></fa-icon>-->
<!--            <div id="waiting-indicator-progress" *ngIf="percentComplete >= 0">{{percentComplete}}%</div>-->
            <p-progressSpinner [style]="{width: '75px', height: '75px'}" animationDuration="1s" strokeWidth="3"></p-progressSpinner>
        </div>
        <div *ngIf="text != null && text.trim() !== ''" class="waiting-text status-text">{{ text }}</div>
    </div>

    <div *ngIf="showError" class="waiting-error-content" [ngStyle]="{'color': color, 'max-width': '400px'}">
        <div class="waiting-text" [ngStyle]="{'font-size': fontSize}">{{ error | translate }}</div>
        <div id="waiting-error-btn-container">
            <p-button label="{{ 'STRINGS.OK' | translate }}" (click)="onOkClick()"></p-button>
        </div>
    </div>
</div>
