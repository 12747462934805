import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import LynxStationStatus = ToroEnums.LynxStationStatus;

import { Component, OnInit } from '@angular/core';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
// import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { GetCoursesCourse } from '../../../../../api/lynx-cloud/models/mqtt-get-courses/get-courses-course.model';
import { LynxCloudCourseItem } from './models/lynx-cloud-course-item.model';
import { LynxCloudService } from '../../../../../api/lynx-cloud/lynx-cloud.service';
import { LynxStationStatusModel } from './models/lynx-station-status.model';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { SelectItem } from 'primeng/api';
import { StationStatuses } from '../../../../../api/lynx-cloud/models/station-status/station-statuses.model';
import { StationStatusItem } from '../../../../../api/lynx-cloud/models/station-status/station-status-item.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-widget-lynx-cloud',
    templateUrl: './widget-lynx-cloud.component.html',
    styleUrls: ['./widget-lynx-cloud.component.less']
})
export class WidgetLynxCloudComponent extends LynxWidgetBase implements OnInit {

    iconColor = 'goldenrod';
    title = 'WIDGET.LYNX_CLOUD';

    LynxStationStatus = LynxStationStatus;

    stationsCount: number;
    showDetailsDialog = false;
    lynxCourses: GetCoursesCourse[] = [];
    lynxCoursesList: SelectItem[];
    selectedCourse: LynxCloudCourseItem;
    stationStatusList: LynxStationStatusModel[];
    filteredStationStatusList: LynxStationStatusModel[];
    stationsRanUnderCount = 0;
    stationsRunningCount = 0;
    activeStatusFilter: LynxStationStatus = LynxStationStatus.RanUnder
    stationStatusItems: StationStatusItem[] = [];
    // lynxCloudInstallationIds: string[] = [];

    private dashUserPrefs: DashUserPreferences;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                // private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxCloudService: LynxCloudService,
                protected translateService: TranslateService,
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        // this.lynxCloudInstallationIds = this.authManager.dashAuthenticatedUser.LynxCloudInstallationIds;

        this.isBusy = true;

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onDataRefresh() {
        this.getWidgetData();
    }

    onCourseChange(eventInfo: { originalEvent: MouseEvent, value: LynxCloudCourseItem }) {
        this.selectedCourse = eventInfo.value;
        this.dashUserPrefs.lynxCloudCourse = eventInfo.value;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();
        this.getWidgetData();

        this.analyticsService.widgetEvent(AnalyticsEvent.LynxStationStatusesWidgetCourseChanged, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    setCarouselPage(event: { page: number }) {``
        this.onCourseChange({ originalEvent: null, value: this.lynxCoursesList[event.page].value});
    }

    onShowModal() {
        // Upon initial load of the dialog, if there are no 'ran under' stations, but there are 'running stations',
        // default to the 'Running Stations' tab.
        if (this.filteredStationStatusList.length < 1) {
            if (this.stationStatusList.filter(s => s.status === LynxStationStatus.Running).length > 0) {
                this.activeStatusFilter = LynxStationStatus.Running;
                this.setFilteredStationStatusList();
            }
        }

        this.showDetailsDialog = true;
    }

    onLynxCloudLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_LYNX_CLOUD' });
        setTimeout(() => window.open(environment.lynxCloudUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onFilter(selectedFilter: LynxStationStatus) {
        if (this.activeStatusFilter === selectedFilter || this.stationStatusList == null) return;

        this.activeStatusFilter = selectedFilter;
        this.setFilteredStationStatusList();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LynxStationStatusesWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();
        this.widgetMenuItems.splice(0, 1);
    }

    protected getWidgetData(isManualRefresh = false) {
        // TODO: Display message to user re: No Lynx Cloud Installations and bail.

        if (this.isWidgetInitialized && this.selectedCourse != null && !isManualRefresh) {
            this.getStationStatuses(this.selectedCourse).subscribe();
            return;
        }

        // Get all data only during component initialization or manual refresh request.
        this.getAllData();
    }

    // =========================================================================================================================================================
    // Helper Methods for data retrieval
    // =========================================================================================================================================================

    private getAllData() {
         if (!this.lynxCloudService.isInitialized  && !environment.isDemoMode) {
             setTimeout(() => this.getWidgetData(), 100);
             return;
         }

        this.lynxCourses = [];

        const sources: Observable<any>[] = [
            this.lynxCloudService.getCourseList().pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(
                tap(([lynxCourses, dashUserInfo]) => {
                    this.lynxCourses = lynxCourses;
                    this.lynxCoursesList = this.lynxCourses.map(c => ({ label: c.name, value: new LynxCloudCourseItem({ installationId: c.installationId, courseId: c.id})}));
                    this.dashUserPrefs = dashUserInfo.preferences;
                }),
                switchMap(([lynxCourses, dashUserInfo]) => {
                    if (lynxCourses == null || lynxCourses.length < 1) { return of(null); }

                    // Set default to course to first one in list.
                    let course = (<GetCoursesCourse>lynxCourses[0]);
                    this.selectedCourse = new LynxCloudCourseItem({ installationId: course.installationId, courseId: course.id });

                    // If the user previous selected a course, use that
                    if (this.dashUserPrefs != null && this.dashUserPrefs.lynxCloudCourse != null) {
                        const prevSelectedCourse = new LynxCloudCourseItem(dashUserInfo.preferences.lynxCloudCourse);
                        const isCourseValid = this.lynxCoursesList.find(c => c.value.installationId === prevSelectedCourse.installationId && c.value.courseId === prevSelectedCourse.courseId) != null;

                        this.selectedCourse = isCourseValid ? prevSelectedCourse : this.selectedCourse;
                    }

                    return of(this.selectedCourse);
                }),
                switchMap((course: LynxCloudCourseItem) => this.getStationStatuses(course))
            )
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((statuses: StationStatuses) => {
                this.clearIsUnableToFetchData();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    private getStationStatuses(courseItem: LynxCloudCourseItem): Observable<StationStatuses> {
        return this.lynxCloudService.getStationStatuses(courseItem)
            .pipe(
                tap((statuses: StationStatuses) => {
                    this.stationsRunningCount = statuses.runningCount;
                    this.stationsRanUnderCount = statuses.ranUnderCount;

                    this.stationStatusItems = [...statuses.runningStations, ...statuses.ranUnderStations];
                    this.stationStatusItems.sort((a, b) => {
                        let result = a.areaName.localeCompare(b.areaName);
                        if (result !== 0) return result;

                        result = a.holeName.localeCompare(b.holeName);
                        if (result !== 0) return result;

                        return a.stationName.localeCompare(b.stationName);
                    })

                    this.lastUpdateTimestamp = new Date();
                    this.setComponentData();
                    this.buildStationStatusList();
                })
            )
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        if (!this.stationStatusItems || this.stationStatusItems.length < 1) {
            this.stationsCount = 0;
            return;
        }
        this.stationsCount = this.stationsRunningCount + this.stationsRanUnderCount;
    }

    private buildStationStatusList() {
        this.stationStatusList = [];
        this.filteredStationStatusList = []

        if (this.stationStatusItems == null || this.stationStatusItems.length < 1) return;

        this.stationStatusList = this.stationStatusItems.map(item => new LynxStationStatusModel(item.areaName, item.holeName, item.stationName, item.status));
        this.setFilteredStationStatusList();
    }

    private setFilteredStationStatusList() {
        this.filteredStationStatusList = this.stationStatusList.filter(s => s.status === this.activeStatusFilter).slice();
    }
}
