import PogoMoistureRating = ToroEnums.PogoMoistureRating;

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@Component({
    selector: 'toro-pogo-zone-tab-card',
    templateUrl: './pogo-zone-tab-card.component.html',
    styleUrls: ['./pogo-zone-tab-card.component.less']
})
export class PogoZoneTabCardComponent implements OnInit {
    @HostBinding('class') class = 'toro-pogo-zone-tab-card'

    @Input() count: number;
    @Input() rating: PogoMoistureRating;
    @Input() ratingColor: string;
    @Input() isMiniMode = false;

    ratingIcon = '';
    ratingText = '';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        this.setIconAndColor();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setIconAndColor() {
        switch (this.rating) {
            case PogoMoistureRating.CriticallyLow:
                this.ratingIcon = 'mdi mdi-arrow-down-thin';
                this.ratingText = 'CASE_SENSITIVE.CRITICALLY_LOW'
                this.ratingColor = '#af4440';  //'#FF0000';
                break;
            case PogoMoistureRating.Low:
                this.ratingIcon = 'mdi mdi-arrow-bottom-left-thin';
                this.ratingText = 'STRINGS.LOW'
                this.ratingColor = '#c2ae96';
                break;
            case PogoMoistureRating.Good:
                this.ratingIcon = 'mdi mdi-minus';
                this.ratingText = 'STRINGS.OPTIMAL'
                this.ratingColor = '#2d882d';
                break;
            case PogoMoistureRating.High:
                this.ratingIcon = 'mdi mdi-arrow-top-right-thin';
                this.ratingText = 'STRINGS.HIGH'
                this.ratingColor = '#3AC4D8';
                break;
            case PogoMoistureRating.CriticallyHigh:
                this.ratingIcon = 'mdi mdi-arrow-up-thin';
                this.ratingText = 'CASE_SENSITIVE.CRITICALLY_HIGH'
                this.ratingColor = '#1C5195';
                break;
        }
    }
}
