import PogoMoistureRating = ToroEnums.PogoMoistureRating;
import PogoTopNav = ToroEnums.PogoTopNav;

import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DatePipe } from '@angular/common';
import { MarkerCoord } from '../models/marker-coord.model';
import { PogoMarkerPixelLocation } from '../models/pogo-marker-pixel-location-model';
import { PogoPinInfo } from '../../../../../api/pogo/models/pogo-pin-info.model';
import { PogoProperty } from '../../../../../api/pogo/models/pogo-property.model';
import { PogoZone } from '../../../../../api/pogo/models/pogo-zone.model';
import { SelectItem } from 'primeng/api';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-pogo-green-viewer',
    templateUrl: './pogo-green-viewer.component.html',
    styleUrls: ['./pogo-green-viewer.component.less']
})
export class PogoGreenViewerComponent implements OnInit, AfterViewInit {
    @HostBinding('class') class = 'toro-pogo-green-viewer';

    @ViewChild('greenContainer') greenContainer: ElementRef;
    @ViewChild('greenImage') greenImage: ElementRef;

    PogoTopNav = PogoTopNav;

    private readonly approachMarkerWidth = 14.67
    private readonly approachMarkerHalfWidth = this.approachMarkerWidth / 2;
    private readonly minMaxMarkerWidth = 40;
    private readonly minMaxMarkerHeight = 25.5;

    @Output() selectedZoneChange = new EventEmitter<PogoZone>();
    @Output() viewerClicked = new EventEmitter<PogoTopNav>();

    @Input() isMiniModal = false;
    @Input() property: PogoProperty;
    @Input() zonesList: PogoZone[];

    private _selectedZone: PogoZone
    @Input() set selectedZone(value: PogoZone) {
        this._selectedZone = value;
        this.resetMarkers();

        if (value == null) { return; }

        this.setZoneInformation();
        this.selectedZoneChange.next(value);
    }

    get selectedZone(): PogoZone {
        return this._selectedZone;
    }

    containerReady = false;
    maxImageWidth: string;
    maxImageHeight: string;

    moistureSummaryIcon: string;
    zoneMoistureAvg: string;
    zoneMoistureDu: string;
    zoneMoistureMin: string;
    zoneMoistureMax: string;

    hasMinLocation = false;
    hasMaxLocation = false;
    imageLoadTmrRef: any;
    imageUnavailable = false;
    hasApproachMarker = false;
    hasPinMarker = false;
    showGreenImage = false;
    approachMarkerLocation: PogoMarkerPixelLocation;
    pinMarkerLocation: PogoMarkerPixelLocation;
    minMoistureLocation: PogoMarkerPixelLocation;
    maxMoistureLocation: PogoMarkerPixelLocation;
    greenContainDiv: any;
    zoneSelectList: SelectItem[] = [];
    imageUrl: string;
    useMinMaxIcon = true;
    selectedZoneTimestamp: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        if (this.isMiniModal) {
            this.zonesList.forEach(z => {
                this.zoneSelectList.push({ label: z.zone_name, value: z });
            })
        }

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setZoneSummaryInformation());
    }

    ngAfterViewInit() {
        this.greenContainDiv = this.greenContainer.nativeElement;
        this.setZoneInformation();
    }

    private setZoneInformation() {
        if (this.greenContainDiv == null || this.selectedZone == null) return;

        clearTimeout(this.imageLoadTmrRef);
        this.imageLoadTmrRef = setTimeout(() => {
            this.imageUnavailable = true;
        }, 2000)

        this.imageUrl = null;
        setTimeout(() => this.setZoneSummaryInformation());

        // Give the dialog a chance to fully draw to size (i.e., wait for dialog animation).
        setTimeout(() => {
            this.maxImageWidth = `${this.greenContainDiv.offsetWidth - 10}px`;
            this.maxImageHeight = `${this.greenContainDiv.offsetHeight - 20}px`;
            this.containerReady = true
        }, 0);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onGreenImageLoad() {
        this.resetMarkers();

        const image = this.greenImage.nativeElement;
        const imageExtent = this.selectedZone.conditions.img_extent;

        const approachMarker = this.selectedZone.conditions.approach_marker;
        this.hasApproachMarker = approachMarker != null;

        let pinMarker: PogoPinInfo;
        const pinMarkers = this.selectedZone.pins;
        if (pinMarkers.length > 0) {
            pinMarker = pinMarkers[pinMarkers.length - 1];
            this.hasPinMarker = true;
        }

        const scaleX = image.width / (imageExtent.max_lng - imageExtent.min_lng);
        const scaleY = image.height / (imageExtent.max_lat - imageExtent.min_lat);

        // Approach Marker Location
        if (this.hasApproachMarker) {
            let pixelX = ((approachMarker.lng - imageExtent.min_lng) * scaleX) - this.approachMarkerHalfWidth;
            let pixelY = (image.height - (approachMarker.lat - imageExtent.min_lat) * scaleY) - this.approachMarkerHalfWidth

            if (pixelX < 0) {
                pixelX = 0
            } else if (pixelX + this.approachMarkerHalfWidth > image.width) {
                pixelX -= this.approachMarkerHalfWidth;
            }

            if (pixelY < 0) {
                pixelY = 0
            } else if (pixelY + this.approachMarkerHalfWidth > image.height) {
                pixelY -= this.approachMarkerHalfWidth;
            }

            this.approachMarkerLocation = new PogoMarkerPixelLocation(pixelX, pixelY);

            // Adjust for size of marker to center over Approach Marker lat/long
            // this.markerLeft = (pixelX - 7.335 > 0) ?  `${pixelX - 7.335}px` : '0';
            // this.markerTop = (pixelY - 7.335 > 0) ? `${pixelY - 7.335}px` : '0';
        }

        if (this.hasPinMarker) {
            const pixelX = (pinMarker.longitude - imageExtent.min_lng) * scaleX;
            const pixelY = image.height - (pinMarker.latitude - imageExtent.min_lat) * scaleY

            // Adjust for size and shape of Pin Marker. Position hole portion of image at Pin lat/long
            this.pinMarkerLocation = new PogoMarkerPixelLocation(pixelX - 15.5, pixelY - 34);

            // Use for seeing position of lat/lon of pin position
            // this.holeMarkerLeft = `${pixelX - 3}px`;
            // this.holeMarkerTop = `${pixelY - 3.75}px`;
        }

        // Min Moisture Location
        const minLocation = this.selectedZone.conditions.min_location;
        this.hasMinLocation = minLocation != null;
        if (this.hasMinLocation) {
            let pixelX = (minLocation.lng - imageExtent.min_lng) * scaleX;
            let pixelY = image.height - (minLocation.lat - imageExtent.min_lat) * scaleY

            const adjustedLocation = this.adjustMinMaxLocation(pixelX, pixelY, image);
            this.minMoistureLocation = new PogoMarkerPixelLocation(adjustedLocation.x, adjustedLocation.y);
        }

        // Max Moisture Location
        const maxLocation = this.selectedZone.conditions.max_location;
        this.hasMaxLocation = maxLocation != null;
        if (this.hasMaxLocation) {
            let pixelX = (maxLocation.lng - imageExtent.min_lng) * scaleX;
            let pixelY = image.height - (maxLocation.lat - imageExtent.min_lat) * scaleY

            const adjustedLocation = this.adjustMinMaxLocation(pixelX, pixelY, image);
            this.maxMoistureLocation = new PogoMarkerPixelLocation(adjustedLocation.x, adjustedLocation.y);
        }

        clearTimeout(this.imageLoadTmrRef);
        this.showGreenImage = true;
    }

    onViewerClick(nav: PogoTopNav) {
        this.viewerClicked.next(nav);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setZoneSummaryInformation() {
        if (this.selectedZone == null) return;

        this.imageUrl = this.selectedZone?.conditions?.moisture_img;
        this.zoneMoistureAvg = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_avg, 1, 1) || '--';
        this.zoneMoistureDu = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_du, 1, 1) || '--';
        this.zoneMoistureMin = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_min, 1, 1) || '--';
        this.zoneMoistureMax = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_max, 1, 1) || '--';
        this.selectedZoneTimestamp = this.datePipe.transform(this.selectedZone?.conditions?.timestamp, 'medium', 'UTC',  this.translateService.currentLang);

        this.setSummaryIcon();
    }

    private setSummaryIcon() {
        switch (this.selectedZone?.conditions?.moistureRating) {
            case PogoMoistureRating.CriticallyLow:
                this.moistureSummaryIcon = 'mdi mdi-arrow-down-thin';
                break;
            case PogoMoistureRating.Low:
                this.moistureSummaryIcon = 'mdi mdi-arrow-bottom-left-thin';
                break;
            case PogoMoistureRating.Good:
                this.moistureSummaryIcon = 'mdi mdi-minus';
                break;
            case PogoMoistureRating.High:
                this.moistureSummaryIcon = 'mdi mdi-arrow-top-right-thin';
                break;
            case PogoMoistureRating.CriticallyHigh:
                this.moistureSummaryIcon = 'mdi mdi-arrow-up-thin';
                break;
        }
    }

    private resetMarkers() {
        this.imageUnavailable = false;
        this.showGreenImage = false;
        this.hasApproachMarker = false;
        this.hasPinMarker = false;
        this.hasMinLocation = false;
        this.hasMaxLocation = false;
    }

    private adjustMinMaxLocation(x: number, y: number, image: any): MarkerCoord {
        if (x < 0) {
            x = 0
        } else if (x + this.minMaxMarkerWidth > image.width) {
            x = image.width - this.minMaxMarkerWidth;
        }

        if (y < 0) {
            y = 0
        } else if (y + this.minMaxMarkerHeight > image.height) {
            y = image.height - this.minMaxMarkerHeight;
        }

        return new MarkerCoord(x, y);
    }
}
