<div class="lynx-current-weather-card-container toro-component-shield-host">

    <div class="lcwc-station-name">
        {{ weatherStation?.name }}
    </div>

    <div class="lcwc-station-detail">

        <div class="lcwc-property-col">
            <div class="lcwc-property-row">
                <span class="lcwc-property-label">{{'STRINGS.TEMPERATURE' | translate}}</span>
                <span class="lcwc-property-value">{{temperature}}° <span class="lcwc-property-units">{{tempUnits}}</span></span>
            </div>
            <div class="lcwc-property-row">
                <span class="lcwc-property-label">{{'STRINGS.HUMIDITY' | translate}}</span>
                <span class="lcwc-property-value">{{humidity}}<span class="lcwc-property-units"> %</span></span>
            </div>
        </div>

        <div class="lcwc-property-col">
            <div class="lcwc-property-row">
                <span class="lcwc-property-label">{{'STRINGS.EVAPOTRANSPIRATION' | translate}}</span>
                <span class="lcwc-property-value">{{et}} <span class="lcwc-property-units">{{etUnits}}</span></span>
            </div>
            <div class="lcwc-property-row">
                <span class="lcwc-property-label">{{'STRINGS.PRECIPITATION' | translate}}</span>
                <span class="lcwc-property-value">{{precipitation}} <span class="lcwc-property-units">{{precipitationUnits}}</span></span>
            </div>
        </div>

    </div>

    <div class="lcwc-footer">
        <toro-last-updated
                [lastUpdateTimestamp]="weatherStation.lastUpdatedTimestamp"
                [staleDataThresholdInMinutes]="staleDataThresholdInMinutes"
                [hideAlertBanner]="hideAlertBanner"
        ></toro-last-updated>
    </div>

    <toro-component-shield></toro-component-shield>
</div>
