import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { take } from 'rxjs/operators';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';

import LanguageCode = ToroEnums.LanguageCode;

@Component({
    selector: 'toro-widget-test',
    templateUrl: './widget-test.component.html',
    styleUrls: ['./widget-test.component.less']
})
export class WidgetTestComponent extends ToroDashboardWidget implements OnInit {

    iconColor = 'orange';
    title = 'WIDGET.TEST';

    private _isActive = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.setWidgetMenu();

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Menu Item Callback(s)
    // =========================================================================================================================================================

    doIt() {
        // alert(ToroUtils.Translate.instant(this.title));
        this._isActive = !this._isActive;

        if (this._isActive) {
            this.authManager.dashAuthenticatedUser.userPreferences.language = LanguageCode.English_US;
            this.dashUserManager.updateDashUserPreferences(this.authManager.dashAuthenticatedUser.userPreferences)
                .pipe(take(1))
                .subscribe(() => {

                }, error => {

                });
        } else {
            this.authManager.dashAuthenticatedUser.userPreferences.language = LanguageCode.Spanish;
            this.dashUserManager.updateDashUserPreferences(this.authManager.dashAuthenticatedUser.userPreferences)
                .pipe(take(1))
                .subscribe(() => {

                }, error => {

                });
        }

        this.setWidgetMenu();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        throw new Error('Not Implemented');
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            { label: 'One', icon: this.isActive, command: this.doIt.bind(this) },
            { label: 'Two', icon: 'pi pi-fw' },
            { label: 'Three', icon: 'pi pi-fw' },
            { separator: true }
        );
    }

    private get isActive(): string {
        return this._isActive ? 'pi pi-fw pi-check' : 'pi pi-fw';
    }

    protected getWidgetData() {
        // this.tempManager.getWeatherForecast()
        //     .subscribe(response => {
        //         console.log();
        //     }, error => {
        //         console.log();
        //     });
    }

}
