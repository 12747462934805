/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TurfGuardHole } from './models/turf-guard-hole.model';
import { TurfGuardSensor } from './models/turf-guard-sensor.model';

@Injectable({
    providedIn: 'root'
})
export class TurfGuardApiService {
    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getTurfGuardHoles(): Observable<TurfGuardHole[]> {
        return this.apiService.apiRequest<any>(TurfGuardApiService.turfGuardHolesUrl, HttpMethod.Get)
            .pipe(map((response: TurfGuardHole[]) => response.map(item => new TurfGuardHole(item))));
    }

    getTurfGuardSensors(holeIds: number[]): Observable<TurfGuardSensor[]> {
        return this.apiService.apiRequest<any>(TurfGuardApiService.getTurfGuardSensorsUrl(holeIds), HttpMethod.Get)
            .pipe(map((response: TurfGuardSensor[]) => response.map(item => new TurfGuardSensor(item))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}turfGuard`; }

    private static get turfGuardHolesUrl(): string { return `${TurfGuardApiService.baseUrl}/holes`; }

    private static getTurfGuardSensorsUrl(holeIds: number[]): string {
        const queryParams = holeIds.reduce((list, holeId) => `${list ? list + '&' : ''}holeIds=${holeId}`, '');
        return `${ TurfGuardApiService.baseUrl}/sensors?${queryParams}`;
    }
}
