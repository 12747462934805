import WidgetType = ToroEnums.WidgetType;
import { IWidgetDataSettings } from '../../../../api/_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';

export class WidgetDataSettings implements IWidgetDataSettings{

    constructor(widgetType: WidgetType) {
        this.widgetType = widgetType;
    }

    widgetType: WidgetType;
    showStaleDataAlert: boolean = null;
    dataRefreshIntervalMinutes: number = null;
    staleDataThresholdMinutes: number = null;

}
