import * as moment from 'moment';
import { TaskTrackerDailySafetyCount } from './task-tracker-daily-safety-count.model';

export class TaskTrackerSafety {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.date) { this.date = moment(json.date).toDate(); }
            if (json.weeklyCount) { this.weeklyCount = json.weeklyCount.map(c => new TaskTrackerDailySafetyCount(c)); }
        }
    }

    departmentId: number;
    departmentName: string;
    todayIssuesCount: number;
    date: Date;
    weeklyCount: TaskTrackerDailySafetyCount[] = [];
}
