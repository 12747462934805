import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PwForecast } from './models/pw-forecast.model';
import { PwHistorical } from './models/pw-historical.model';
import { PwHistoricalEt } from './models/pw-historical-et.model';
import { PwLocation } from './models/pw-location.model';
import { PwObservation } from './models/pw-observation.model';

@Injectable({
    providedIn: 'root'
})
export class PerryWeatherApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getLocation(locationId: number): Observable<any> {
        return this.apiService.apiRequest<any>(this.getLocationUrl(locationId), HttpMethod.Get)
            .pipe(map((location: PwLocation) => new PwLocation(location)))
    }

    createLocation(location: PwLocation): Observable<number> {
        return this.apiService.apiRequest<any>(this.createLocationUrl, HttpMethod.Put, location);
    }

    deleteLocation(locationId: number): Observable<void> {
        return this.apiService.apiRequest<any>(this.getDeleteLocationUrl(locationId), HttpMethod.Delete);
    }

    getObservation(locationId: number): Observable<PwObservation> {
        return this.apiService.apiRequest<any>(this.getObservationUrl(locationId), HttpMethod.Get)
            .pipe(map((observation: PwObservation) => new PwObservation(observation)))
    }

    // Date is expected to be UTC
    getForecast(locationId: number, date: Date, days = 0, hours = 0): Observable<PwForecast> {
        return this.apiService.apiRequest<any>(this.getForecastUrl(locationId, date, days, hours), HttpMethod.Get)
            .pipe(map((forecast: PwForecast) => new PwForecast(forecast)))
    }

    getHistorical(locationId: number, startDate: Date, days = 0, hours = 0): Observable<PwHistorical> {
        return this.apiService.apiRequest<any>(this.getHistoricalUrl(locationId, startDate, days, hours), HttpMethod.Get)
            .pipe(map((historical: PwHistorical) => new PwHistorical(historical)));
    }

    getHistoricalEt(locationId: number, fromDate: Date, toDate: Date): Observable<PwHistoricalEt> {
        return this.apiService.apiRequest<any>(this.getHistoricalEtUrl(locationId, fromDate, toDate), HttpMethod.Get)
            .pipe(map((historical: PwHistoricalEt) => new PwHistoricalEt(historical)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}perryWeather`; }

    private getLocationUrl(locationId: number) { return `${PerryWeatherApiService.baseUrl}/location/${locationId}`; }

    private get createLocationUrl(): string { return `${PerryWeatherApiService.baseUrl}/location`; }

    private getDeleteLocationUrl(locationId: number): string { return `${PerryWeatherApiService.baseUrl}/location/${locationId}`; }

    private getObservationUrl(locationId: number): string { return `${PerryWeatherApiService.baseUrl}/observation/${locationId}`; }

    private getForecastUrl(locationId: number, date: Date, days: number, hours: number): string {
        let url = `${PerryWeatherApiService.baseUrl}/forecast/${locationId}?date=${date.toISOString()}`
        if (days > 0) url += `&days=${days}`
        if (hours > 0) url += `&hours=${hours}`;

        return url;
    }

    private getHistoricalUrl(locationId: number, startDate: Date, days: number, hours: number): string {
        let url = `${PerryWeatherApiService.baseUrl}/historical/${locationId}?startDate=${startDate.toISOString()}`
        if (days > 0) url += `&days=${days}`
        if (hours > 0) url += `&hours=${hours}`;

        return url;
    }

    private getHistoricalEtUrl(locationId: number, fromDate: Date, toDate: Date): string {
        return `${PerryWeatherApiService.baseUrl}/historicalEt/${locationId}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    }
}
