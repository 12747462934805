<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
    >

        <div class="weather-radar-widget-container toro-flex-child mini-mode-widget-container">
            <div class="toro-widget-content-pane toro-component-shield-host">

                <p-gmap [options]="mapOptions"
                        [overlays]="mapOverlays"
                        [style]="{'width':'100%', 'height':'100%'}"
                        (onMapReady)="onMapReady($event)"
                        (onMapDragEnd)="onMapDragEnd()"
                        (onZoomChanged)="onZoomChanged()"
                ></p-gmap>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
    >

        <div class="weather-radar-widget-container toro-flex-child">
            <p-gmap [options]="mapOptions"
                    [overlays]="mapOverlays"
                    [style]="{'width':'100%', 'height':'100%'}"
                    (onMapReady)="onMapReady($event)"
                    (onMapDragEnd)="onMapDragEnd()"
                    (onZoomChanged)="onZoomChanged()"
            ></p-gmap>

            <div id="twr-toolbar-container">
                <div id="twr-button-container">
                    <div id="twr-recenter-button" class="twr-button" (click)="onRecenterClick()">
                        Recenter
                    </div>

                    <div id="twr-legend-button" class="twr-button" (click)="onShowRadar()">
                        Legend
                    </div>
                </div>

                <div id="twr-legend" *ngIf="showRadarLegend">
                    <img src="../../../../../assets/images/radar-legend.png" alt="">
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="closeMiniModalWindow()">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
