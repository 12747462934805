import { ApiService, HttpMethod } from '../_common/api.service';
import { DtnCurrentWeatherForecast, DtnDailyWeatherForecast, DtnHourlyWeatherForecast } from './models/dtn-forecast.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClearAgApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDailyForecast(lat: number, lon: number, startDay = 0, endDay = 0): Observable<DtnDailyWeatherForecast> {
        return this.apiService.apiRequest<any>(this.getDailyForecastUrl(lat, lon, startDay, endDay), HttpMethod.Get)
            .pipe(map(result => new DtnDailyWeatherForecast(result)));
    }

    getCurrentForecast(lat: number, lon: number): Observable<DtnCurrentWeatherForecast> {
        return this.apiService.apiRequest<any>(this.getCurrentForecastUrl(lat, lon), HttpMethod.Get)
            .pipe(map(result => new DtnCurrentWeatherForecast(result)));
    }

    getHourlyForecast(lat: number, lon: number, start: number, end: number): Observable<DtnHourlyWeatherForecast> {
        return this.apiService.apiRequest<any>(this.getHourlyForecastUrl(lat, lon, start, end), HttpMethod.Get)
            .pipe(map(result => new DtnHourlyWeatherForecast(result)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${environment.clearAgApiUrl}`; }

    private getDailyForecastUrl(lat: number, lon: number, startDay = 0, endDay = 9): string {
        return `${ClearAgApiService.baseUrl}/forecast/daily?${this.appIdAndKey}&location=[(${lat}, ${lon})]&start=${startDay}&end=${endDay}`;
    }

    private getCurrentForecastUrl(lat: number, lon: number): string {
        return `${ClearAgApiService.baseUrl}/currentconditions?${this.appIdAndKey}&location=[(${lat}, ${lon})]`;
    }

    // Start of time range. Supported ranges are from 0 to 240. Hour 0 represents the current hour.
    // End of time range. Supported ranges are from 0 to 240. (Hours beyond 240 may not be returned.)
    private getHourlyForecastUrl(lat: number, lon: number, start: number, end: number): string {
        return `${ClearAgApiService.baseUrl}/forecast/hourly?${this.appIdAndKey}&location=[(${lat}, ${lon})]&start=${start}&end=${end}`;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get appIdAndKey(): string {
        return `app_id=${environment.clearAgAppId}&app_key=${environment.clearAgAppKey}`;
    }

}
