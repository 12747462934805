
export class DtnDailyWeatherForecast {
    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key] = new DtnDailyWeatherDays(json[key]);
            });
        }
    }

    [coordinates: string]: DtnDailyWeatherDays;
}

export class DtnCurrentWeatherForecast {
    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key] = new DtnCurrentConditions(json[key]?.current_conditions);
            });
        }
    }

    [coordinates: string]: DtnCurrentConditions;
}

export class Descriptor {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    code: number;
    icon: string;
    text: string;
}
export class Descriptors {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.cloud_cover_descriptor != null) this.cloud_cover_descriptor = new Descriptor(json.cloud_cover_descriptor);
            if (json.precipitation_descriptor != null) this.precipitation_descriptor = new Descriptor(json.precipitation_descriptor);
            if (json.visibility_obstruction_descriptor != null) this.visibility_obstruction_descriptor = new Descriptor(json.visibility_obstruction_descriptor);
            if (json.weather_descriptor != null) this.weather_descriptor = new Descriptor(json.weather_descriptor);
            if (json.wind_direction_descriptor != null) this.wind_direction_descriptor = new Descriptor(json.wind_direction_descriptor);
        }
    }

    cloud_cover_descriptor: Descriptor;
    precipitation_descriptor: Descriptor;
    visibility_obstruction_descriptor: Descriptor;
    weather_descriptor: Descriptor;
    wind_direction_descriptor: Descriptor;
}

export class Measurement {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    unit: string;
    value: number;
}

export class DtnCurrentConditions {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.air_temp != null) this.air_temp = new Measurement(json.air_temp);
            if (json.cloud_cover != null) this.cloud_cover = new Measurement(json.cloud_cover);
            if (json.descriptors != null)  this.descriptors = new Descriptors(json.descriptors);
            if (json.dew_point != null) this.dew_point = new Measurement(json.dew_point);
            if (json.ice_acc_last_hour != null) this.ice_acc_last_hour = new Measurement(json.ice_acc_last_hour);
            if (json.liquid_acc_last_hour != null) this.liquid_acc_last_hour = new Measurement(json.liquid_acc_last_hour);
            if (json.long_wave_radiation != null) this.long_wave_radiation = new Measurement(json.long_wave_radiation);
            if (json.msl_pressure != null) this.msl_pressure = new Measurement(json.msl_pressure);
            if (json.precip_acc_last_hour != null) this.precip_acc_last_hour = new Measurement(json.precip_acc_last_hour);
            if (json.relative_humidity != null) this.relative_humidity = new Measurement(json.relative_humidity);
            if (json.short_wave_radiation != null) this.short_wave_radiation = new Measurement(json.short_wave_radiation);
            if (json.snow_acc_last_hour != null) this.snow_acc_last_hour = new Measurement(json.snow_acc_last_hour);
            if (json.station_pressure != null) this.station_pressure = new Measurement(json.station_pressure);
            if (json.u_wind_speed != null) this.u_wind_speed = new Measurement(json.u_wind_speed);
            if (json.v_wind_speed != null) this.v_wind_speed = new Measurement(json.v_wind_speed);
            if (json.visibility != null) this.visibility = new Measurement(json.visibility);
            if (json.wind_direction != null) this.wind_direction = new Measurement(json.wind_direction);
            if (json.wind_speed != null) this.wind_speed = new Measurement(json.wind_speed);
        }
    }

    air_temp: Measurement;
    cloud_cover: Measurement;
    descriptors: Descriptors;
    dew_point: Measurement;
    ice_acc_last_hour: Measurement;
    liquid_acc_last_hour: Measurement;
    long_wave_radiation: Measurement;
    msl_pressure: Measurement;
    precip_acc_last_hour: Measurement;
    relative_humidity: Measurement;
    short_wave_radiation: Measurement;
    snow_acc_last_hour: Measurement;
    station_pressure: Measurement;
    u_wind_speed: Measurement;
    v_wind_speed: Measurement;
    valid_time_end: number;
    valid_time_start: number;
    visibility: Measurement;
    wind_direction: Measurement;
    wind_speed: Measurement;

    get wind_direction_string() : string {
        if (this.wind_direction.value >= 348.75 && this.wind_direction.value <= 11.25) return 'N';
        if (this.wind_direction.value > 11.25 && this.wind_direction.value <= 33.75) return 'NNE';
        if (this.wind_direction.value > 33.75 && this.wind_direction.value <= 56.25) return 'NE';
        if (this.wind_direction.value > 56.25 && this.wind_direction.value <= 78.75) return 'ENE';

        if (this.wind_direction.value > 78.75 && this.wind_direction.value <= 101.25) return 'E';
        if (this.wind_direction.value > 101.25 && this.wind_direction.value <= 123.75) return 'ESE';
        if (this.wind_direction.value > 123.75 && this.wind_direction.value <= 146.25) return 'SE';
        if (this.wind_direction.value > 146.25 && this.wind_direction.value <= 168.75) return 'SSE';

        if (this.wind_direction.value > 168.75 && this.wind_direction.value <= 191.25) return 'S';
        if (this.wind_direction.value > 191.25 && this.wind_direction.value <= 213.75) return 'SSW';
        if (this.wind_direction.value > 213.75 && this.wind_direction.value <= 236.25) return 'SW';
        if (this.wind_direction.value > 236.25 && this.wind_direction.value <= 258.75) return 'WSW';

        if (this.wind_direction.value > 258.75 && this.wind_direction.value <= 281.25) return 'W';
        if (this.wind_direction.value > 281.25 && this.wind_direction.value <= 303.75) return 'WNW';
        if (this.wind_direction.value > 303.75 && this.wind_direction.value <= 326.25) return 'NW';
        if (this.wind_direction.value > 326.25 && this.wind_direction.value < 348.75) return 'NNW';

        return '--';
    }
}

export class DtnDailyWeatherDayCommon {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.cloud_cover_avg != null) this.cloud_cover_avg = new Measurement(json.cloud_cover_avg);
            if (json.descriptors != null)  this.descriptors = new Descriptors(json.descriptors);
            if (json.dew_point_avg != null) this.dew_point_avg = new Measurement(json.dew_point_avg);
            if (json.ice_acc_period != null) this.ice_acc_period = new Measurement(json.ice_acc_period);
            if (json.liquid_acc_period != null) this.liquid_acc_period = new Measurement(json.liquid_acc_period);
            if (json.long_wave_radiation_avg != null) this.long_wave_radiation_avg = new Measurement(json.long_wave_radiation_avg);
            if (json.precip_acc_period != null) this.precip_acc_period = new Measurement(json.precip_acc_period);
            if (json.precip_prob != null) this.precip_prob = new Measurement(json.precip_prob);
            if (json.relative_humidity_avg != null) this.relative_humidity_avg = new Measurement(json.relative_humidity_avg);
            if (json.short_wave_radiation_avg != null) this.short_wave_radiation_avg = new Measurement(json.short_wave_radiation_avg);
            if (json.snow_acc_period != null) this.snow_acc_period = new Measurement(json.snow_acc_period);
            if (json.wind_gust_max != null) this.wind_gust_max = new Measurement(json.wind_gust_max);
            if (json.wind_speed_2m_avg != null) this.wind_speed_2m_avg = new Measurement(json.wind_speed_2m_avg);
            if (json.wind_speed_2m_max != null) this.wind_speed_2m_max = new Measurement(json.wind_speed_2m_max);
            if (json.wind_speed_2m_min != null) this.wind_speed_2m_min = new Measurement(json.wind_speed_2m_min);
            if (json.wind_speed_avg != null) this.wind_speed_avg = new Measurement(json.wind_speed_avg);
            if (json.wind_speed_max != null) this.wind_speed_max = new Measurement(json.wind_speed_max);
            if (json.wind_speed_min != null) this.wind_speed_min = new Measurement(json.wind_speed_min);
        }
    }

    cloud_cover_avg: Measurement;
    descriptors: Descriptors;
    dew_point_avg: Measurement;
    ice_acc_period: Measurement;
    liquid_acc_period: Measurement;
    long_wave_radiation_avg: Measurement;
    precip_acc_period: Measurement;
    precip_prob: Measurement;
    relative_humidity_avg: Measurement;
    short_wave_radiation_avg: Measurement;
    snow_acc_period: Measurement;
    wind_gust_max: Measurement;
    wind_speed_2m_avg: Measurement;
    wind_speed_2m_max: Measurement;
    wind_speed_2m_min: Measurement;
    wind_speed_avg: Measurement;
    wind_speed_max: Measurement;
    wind_speed_min: Measurement;
}

export class DtnDailyWeatherDayDay extends DtnDailyWeatherDayCommon {
    constructor(json: any = null) {
        super(json);

        if (json) {
            if (json.air_temp_max != null) this.air_temp_max = new Measurement(json.air_temp_max);
        }
    }

    air_temp_max: Measurement;
}

export class DtnDailyWeatherDayNight extends DtnDailyWeatherDayCommon {
    constructor(json: any = null) {
        super(json);

        if (json) {
            if (json.air_temp_min != null) this.air_temp_min = new Measurement(json.air_temp_min);
        }
    }

    air_temp_min: Measurement;
}

export class DtnDailyWeatherDayOverall extends DtnDailyWeatherDayCommon {
    constructor(json: any = null) {
        super(json);

        if (json) {
            if (json.air_temp_avg != null) this.air_temp_avg = new Measurement(json.air_temp_avg);
            if (json.air_temp_max != null) this.air_temp_max = new Measurement(json.air_temp_max);
            if (json.air_temp_min != null) this.air_temp_min = new Measurement(json.air_temp_min);
            if (json.dew_point_max != null) this.dew_point_max = new Measurement(json.dew_point_max);
            if (json.dew_point_min != null) this.dew_point_min = new Measurement(json.dew_point_min);
            if (json.pet_period != null) this.pet_period = new Measurement(json.pet_period);
            if (json.relative_humidity_max != null) this.relative_humidity_max = new Measurement(json.relative_humidity_max);
            if (json.relative_humidity_min != null) this.relative_humidity_min = new Measurement(json.relative_humidity_min);
            if (json.sunshine_duration != null) this.sunshine_duration = new Measurement(json.sunshine_duration);
        }
    }

    air_temp_avg: Measurement;
    air_temp_max: Measurement;
    air_temp_min: Measurement;
    dew_point_max: Measurement;
    dew_point_min: Measurement;
    pet_period: Measurement;
    relative_humidity_max: Measurement;
    relative_humidity_min: Measurement;
    sunshine_duration: Measurement;
}

export class DtnDailyWeatherDay {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.day != null) this.day = new DtnDailyWeatherDayDay(json.day);
            if (json.night != null) this.night = new DtnDailyWeatherDayNight(json.night);
            if (json.overall != null) this.overall = new DtnDailyWeatherDayOverall(json.overall);
        }
    }

    day: any;
    night: any;
    overall: DtnDailyWeatherDayOverall;
}

export class DtnDailyWeatherDays {
    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key] = new DtnDailyWeatherDay(json[key]);
            });
        }
    }

    [date: string]: DtnDailyWeatherDay;
}

// Hourly Forecast
export class DtnHourlyWeatherForecast {
    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key] = new DtnHourlyWeatherHours(json[key]);
            });
        }
    }

    [coordinates: string]: DtnHourlyWeatherHours;
}

export class DtnHourlyWeatherHours {
    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key] = new DtnWeatherHour(json[key]);
            });
        }
    }

    [date: string]: DtnWeatherHour;
}

export class DtnWeatherHour {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.air_temp != null) this.air_temp = new Measurement(json.air_temp);
            if (json.cloud_cover != null) this.cloud_cover = new Measurement(json.cloud_cover);
            if (json.conditional_prob_freezing != null) this.conditional_prob_freezing = new Measurement(json.conditional_prob_freezing);
            if (json.conditional_prob_frozen != null) this.conditional_prob_frozen = new Measurement(json.conditional_prob_frozen);
            if (json.conditional_prob_liquid != null) this.conditional_prob_liquid = new Measurement(json.conditional_prob_liquid);
            if (json.conditional_prob_refrozen != null) this.conditional_prob_refrozen = new Measurement(json.conditional_prob_refrozen);
            if (json.delta_t != null) this.delta_t = new Measurement(json.delta_t);
            if (json.descriptors != null) this.descriptors = new Descriptors(json.descriptors);
            if (json.dew_point != null) this.dew_point = new Measurement(json.dew_point);
            if (json.ice_acc_overall != null) this.ice_acc_overall = new Measurement(json.ice_acc_overall);
            if (json.ice_acc_period != null) this.ice_acc_period = new Measurement(json.ice_acc_period);
            if (json.liquid_acc_overall != null) this.liquid_acc_overall = new Measurement(json.liquid_acc_overall);
            if (json.liquid_acc_period != null) this.liquid_acc_period = new Measurement(json.liquid_acc_period);
            if (json.long_wave_radiation != null) this.long_wave_radiation = new Measurement(json.long_wave_radiation);
            if (json.precip_acc_overall != null) this.precip_acc_overall = new Measurement(json.precip_acc_overall);
            if (json.precip_acc_period != null) this.precip_acc_period = new Measurement(json.precip_acc_period);
            if (json.precip_prob != null) this.precip_prob = new Measurement(json.precip_prob);
            if (json.relative_humidity != null) this.relative_humidity = new Measurement(json.relative_humidity);
            if (json.short_wave_radiation != null) this.short_wave_radiation = new Measurement(json.short_wave_radiation);
            if (json.snow_acc_overall != null) this.snow_acc_overall = new Measurement(json.snow_acc_overall);
            if (json.snow_acc_period != null) this.snow_acc_period = new Measurement(json.snow_acc_period);
            if (json.visibility != null) this.visibility = new Measurement(json.visibility);
            if (json.wind_direction != null) this.wind_direction = new Measurement(json.wind_direction);
            if (json.wind_gust != null) this.wind_gust = new Measurement(json.wind_gust);
            if (json.wind_speed != null) this.wind_speed = new Measurement(json.wind_speed);
            if (json.wind_speed_2m != null) this.wind_speed_2m = new Measurement(json.wind_speed_2m);
        }
    }

    air_temp: Measurement;
    cloud_cover: Measurement;
    conditional_prob_freezing: Measurement;
    conditional_prob_frozen: Measurement;
    conditional_prob_liquid: Measurement;
    conditional_prob_refrozen: Measurement;
    delta_t: Measurement;
    descriptors: Descriptors;
    dew_point: Measurement;
    ice_acc_overall: Measurement;
    ice_acc_period: Measurement;
    liquid_acc_overall: Measurement;
    liquid_acc_period: Measurement;
    long_wave_radiation: Measurement;
    precip_acc_overall: Measurement;
    precip_acc_period: Measurement;
    precip_prob: Measurement;
    relative_humidity: Measurement;
    short_wave_radiation: Measurement;
    snow_acc_overall: Measurement;
    snow_acc_period: Measurement;
    valid_time_end: number;
    valid_time_start: number;
    visibility: Measurement;
    wind_direction: Measurement;
    wind_gust: Measurement;
    wind_speed: Measurement;
    wind_speed_2m: Measurement;
}
