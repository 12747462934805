export class CurrentWeather {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    dewPoint: number;
    rainfall: number;
    relHumidity: number;
    temperature: number;
    windDirection: string;
    windSpeed: number;
}
