import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { MenuItem } from 'primeng/api';
import { Site } from '../../../../../api/site/models/site.model';
import { SiteManagerService } from '../../../../../api/site/site-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherGraphNormalizationService } from '../../../../../common/services/weather-graph-normalization.service';
import { WeatherGraphsData } from '../../../../../api/weather/models/weather-graphs-data.model';
import { WeatherManagerService } from '../../../../../api/weather/weather-manager.service';

import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import WeatherGraphType = ToroEnums.WeatherGraphType;

@Component({
    selector: 'toro-widget-weather-graphs',
    templateUrl: './widget-weather-graphs.component.html',
    styleUrls: ['./widget-weather-graphs.component.less']
})
export class WidgetWeatherGraphsComponent extends ToroDashboardWidget implements OnInit {
    private readonly ONE_ROW_GRAPH_HEIGHT = 132;
    private readonly TWO_ROW_GRAPH_HEIGHT = 150;
    private readonly NO_VALUE = '--';

    iconColor = '#3178c6';
    title = 'WIDGET.WEATHER_GRAPHS';

    widgetMenuItems: MenuItem[] = [];
    graphHeight = this.ONE_ROW_GRAPH_HEIGHT;
    weatherGraphs: WeatherGraphType[] = [];
    weatherGraphData: WeatherGraphsData;
    site: Site;

    // Mini-mode vars
    windUnits = '-';
    dewPointUnits = '-';
    precipitationUnits = '-';
    wind: string;
    dewPoint: string;
    humidity: string;
    precipitation: string;
    isMetric = false;

    private dashUserPrefs: DashUserPreferences;
    private isWindGraphVisible = true;
    private isTemperatureGraphVisible = true;
    private isPrecipitationGraphVisible = true;
    private isHumidityGraphVisible = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private siteManager: SiteManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService,
                private weatherGraphNormalizationService: WeatherGraphNormalizationService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        if (this.isGridsterInMobileMode) {
            this.broadcastService.userPreferencesChange
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.setMiniModeComponentData();
                });
        }

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.WeatherGraphsWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        this.graphHeight = (this.displayRows === 1 && !this.isGridsterInMobileMode) ? this.ONE_ROW_GRAPH_HEIGHT : this.TWO_ROW_GRAPH_HEIGHT;
    }

    protected getWidgetData() {
        const sources: Observable<any>[] = [
            this.weatherManager.getWeatherGraphsData().pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.siteManager.getSite().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                this.isWidgetInitialized = true;
                this.isBusy = false;
            }))
            .subscribe(([weatherGraphData, dashUserInfo, site]) => {
                this.site = site;
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = weatherGraphData.lastUpdated;
                this.dashUserPrefs = dashUserInfo.preferences;
                this.weatherGraphData = weatherGraphData;
                this.weatherGraphNormalizationService.setYAxisExtents(weatherGraphData);

                // TODO: TEMPORARY
                if (this.dashUserPrefs.weatherGraphs.includes(WeatherGraphType.SolarRadiation)) {
                    const index = this.dashUserPrefs.weatherGraphs.indexOf(WeatherGraphType.SolarRadiation);
                    this.dashUserPrefs.weatherGraphs.splice(index, 1, WeatherGraphType.RelativeHumidity);
                    this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();
                }

                this.weatherGraphs = this.dashUserPrefs.weatherGraphs;

                this.isWindGraphVisible = this.dashUserPrefs.weatherGraphs.includes(WeatherGraphType.Wind);
                this.isTemperatureGraphVisible = this.dashUserPrefs.weatherGraphs.includes(WeatherGraphType.Temperature);
                this.isPrecipitationGraphVisible = this.dashUserPrefs.weatherGraphs.includes(WeatherGraphType.Precipitation);
                this.isHumidityGraphVisible = this.dashUserPrefs.weatherGraphs.includes(WeatherGraphType.RelativeHumidity);

                if (this.isGridsterInMobileMode) {
                    this.setMiniModeComponentData();
                }

                this.setWidgetMenu();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: this.translateService.instant('STRINGS.WIND'),
                icon: this.getMenuIcon(this.isWindGraphVisible),
                command: this.toggleWindGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.TEMPERATURE'),
                icon: this.getMenuIcon(this.isTemperatureGraphVisible),
                command: this.toggleTemperatureGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.PRECIPITATION'),
                icon: this.getMenuIcon(this.isPrecipitationGraphVisible),
                command: this.togglePrecipitationGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.RELATIVE_HUMIDITY'),
                icon: this.getMenuIcon(this.isHumidityGraphVisible),
                command: this.toggleHumidityGraph.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private toggleWindGraph() {
        this.isWindGraphVisible = !this.isWindGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Wind, this.isWindGraphVisible), 250);

        const analyticsEvent = this.isWindGraphVisible
            ? AnalyticsEvent.WeatherGraphsWidgetWindGraphDisplayed : AnalyticsEvent.WeatherGraphsWidgetWindGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private toggleTemperatureGraph() {
        this.isTemperatureGraphVisible = !this.isTemperatureGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Temperature, this.isTemperatureGraphVisible), 250);

        const analyticsEvent = this.isTemperatureGraphVisible
            ? AnalyticsEvent.WeatherGraphsWidgetTemperatureGraphDisplayed : AnalyticsEvent.WeatherGraphsWidgetTemperatureGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private togglePrecipitationGraph() {
        this.isPrecipitationGraphVisible = !this.isPrecipitationGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Precipitation, this.isPrecipitationGraphVisible), 250);

        const analyticsEvent = this.isPrecipitationGraphVisible
            ? AnalyticsEvent.WeatherGraphsWidgetPrecipitationGraphDisplayed : AnalyticsEvent.WeatherGraphsWidgetPrecipitationGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private toggleHumidityGraph() {
        this.isHumidityGraphVisible = !this.isHumidityGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.RelativeHumidity, this.isHumidityGraphVisible), 250);

        const analyticsEvent = this.isHumidityGraphVisible
            ? AnalyticsEvent.WeatherGraphsWidgetRelativeHumidityGraphDisplayed : AnalyticsEvent.WeatherGraphsWidgetRelativeHumidityGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private getMenuIcon(isVisible: boolean) {
        return isVisible ? 'pi pi-fw pi-check' : 'pi pi-fw';
    }

    private addRemoveGraph(graphType: WeatherGraphType, isVisible: boolean) {
        if (isVisible && !this.weatherGraphs.includes(graphType)) {
            this.weatherGraphs.push(graphType);
        } else if (!isVisible) {
            this.weatherGraphs = this.weatherGraphs.filter(g => g !== graphType);
        }

        this.dashUserPrefs.weatherGraphs = this.weatherGraphs;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();
    }

    setMiniModeComponentData() {
        if (this.weatherGraphData == null) { return; }

        this.isMetric = this.userFormatService.isMetric;
        this.windUnits = this.userFormatService.windSpeedUnits;
        this.dewPointUnits = this.userFormatService.dewPointUnits;
        this.precipitationUnits = this.userFormatService.rainfallUnits;

        // NOTE: The current DateTime value is the last index in the array. I.e., The last index is NOW.

        this.wind = this.weatherGraphData.wind.values.length > 0 ? this.userFormatService.windSpeed(this.weatherGraphData.wind.values[this.weatherGraphData.wind.values.length - 1]).toString() : this.NO_VALUE;
        this.dewPoint = this.weatherGraphData.dewPoint.values.length > 0 ? this.userFormatService.dewPoint(this.weatherGraphData.dewPoint.values[this.weatherGraphData.dewPoint.values.length - 1]).toString() : this.NO_VALUE;
        this.precipitation = this.weatherGraphData.precipitation.values.length > 0 ? this.userFormatService.rainfall(this.weatherGraphData.precipitation.values[this.weatherGraphData.precipitation.values.length - 1]).toString() : this.NO_VALUE;
        this.humidity = this.weatherGraphData.relativeHumidity.values.length > 0 ? this.userFormatService.humidity(this.weatherGraphData.relativeHumidity.values[this.weatherGraphData.relativeHumidity.values.length - 1]).toString() : this.NO_VALUE;
    }
}
