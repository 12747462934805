import { GetGeoTopoHole } from './get-geo-topo-hole.model';

export class GetGeoTopoArea {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.id = json.Id;
            this.name = json.Name;
            this.holes = json.Holes.map(h => new GetGeoTopoHole(h));

            delete this['Id'];
            delete this['Name'];
            delete this['Holes'];
        }
    }

    id: number;
    name: string;
    holes: GetGeoTopoHole[];
}
