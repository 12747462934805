<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu ss-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            (gotoSite)="onSiteScoutLinkClick()"
    >

        <div class="toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane soil-scout-content-panel">

                <div class="toro-mini-mode-content-panel">
                    <div class="ss-mm-sensor-type ellipses-text">{{ 'STRINGS.TEMPERATURE' | translate }}</div>
                    <div class="ss-mm-sub-label ellipses-text">{{ 'STRINGS.AVERAGE' | translate }}</div>
                    <div class="ss-mm-avg-value" [ngStyle]="{'color': dataValueColor}">{{SoilScoutWidgetBase.siteTemperatureDisplayValue}}</div>
                    <div *ngIf="SoilScoutWidgetBase.temperatureSensorAlertsCount > 0" class="ss-mm-alerts">
                        <img class="ss-alert-img" src="../../../../../../assets/images/soil-scout/ss-alert.png" alt=""> {{ SoilScoutWidgetBase.temperatureSensorAlertsCount }}
                    </div>
                </div>
                <div class="toro-mini-mode-content-panel">
                    <div class="ss-mm-sensor-type ellipses-text">{{ 'STRINGS.MOISTURE' | translate }}</div>
                    <div class="ss-mm-sub-label ellipses-text">{{ 'STRINGS.AVERAGE' | translate }}</div>
                    <div class="ss-mm-avg-value" [ngStyle]="{'color': 'gray'}">{{SoilScoutWidgetBase.siteMoistureDisplayValue}}</div>
                    <div *ngIf="SoilScoutWidgetBase.moistureSensorAlertsCount > 0" class="ss-mm-alerts">
                        <img class="ss-alert-img" src="../../../../../../assets/images/soil-scout/ss-alert.png" alt=""> {{ SoilScoutWidgetBase.moistureSensorAlertsCount }}
                    </div>
                </div>
                <div class="toro-mini-mode-content-panel">
                    <div class="ss-mm-sensor-type ellipses-text">{{ 'STRINGS.SALINITY' | translate }}</div>
                    <div class="ss-mm-sub-label ellipses-text">{{ 'STRINGS.AVERAGE' | translate }}</div>
                    <div class="ss-mm-avg-value" [ngClass]="{'is-metric': SoilScoutWidgetBase.isMetric}" [ngStyle]="{'color': 'gray'}">{{SoilScoutWidgetBase.siteSalinityDisplayValue}}</div>
                    <div *ngIf="SoilScoutWidgetBase.salinitySensorAlertsCount > 0" class="ss-mm-alerts">
                        <img class="ss-alert-img" src="../../../../../../assets/images/soil-scout/ss-alert.png" alt=""> {{ SoilScoutWidgetBase.salinitySensorAlertsCount }}
                    </div>
                </div>

            </div>
            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu ss-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onSiteScoutLinkClick()"
    >

        <div id="ss-widget-content" class="toro-flex-child toro-component-shield-host">
            <toro-soil-scout-gauge
                    [Highcharts]="Highcharts"
                    [gaugeOptions]="gaugeOptions"
                    [isGaugeLoaded]="isGaugeLoaded"
                    [sensorAlertsCount]="SoilScoutWidgetBase.temperatureSensorAlertsCount"
                    (showDetails)="onShowDetails()"
            ></toro-soil-scout-gauge>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="soil-scout-title-icon" src="../../../../../../assets/images/soil-scout/soil-scout-logo.png" alt="">
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<toro-soil-scout-modal
        *ngIf="showDesktopModal"
        [isMiniModal]="isGridsterInMobileMode"
        [chartData]="SoilScoutWidgetBase.siteChartData"
        [selectedSensorType]="sensorType"
        (cancel)="showDesktopModal = false;"
        (gotoSite)="onSiteScoutLinkClick()"
></toro-soil-scout-modal>

<toro-soil-scout-login-dlg
        *ngIf="isLoginDialogDisplayed"
        (loginClick)="onLogin($event)"
        (cancelClick)="isLoginDialogDisplayed = false"
></toro-soil-scout-login-dlg>

<toro-soil-scout-thresholds-dlg
        *ngIf="isThresholdsDialogDisplayed"
        [selectedTab]="SoilScoutThresholdTab.Temperature"
        [defaultMoistureRange]="defaultMoistureRange"
        [defaultSalinityRange]="defaultSalinityRange"
        [defaultTemperatureRange]="defaultTemperatureRange"
        [userPreferences]="SoilScoutWidgetBase.userPreferences"
        (cancelClick)="isThresholdsDialogDisplayed = false"
        (updateRanges)="onUpdateRanges($event)"
></toro-soil-scout-thresholds-dlg>
