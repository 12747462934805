<div class="toro-weather-graph-container" [ngStyle]="{'height': height + 'px'}">
    <div class="twg-chart-container"><ng-container [ngTemplateOutlet]="playbooksChart"></ng-container></div>
    <ng-container [ngTemplateOutlet]="playbooksLegend"></ng-container>
</div>

<ng-template #playbooksChart>
    <toro-playbooks-nutrient-chart *ngIf="graphType == PlaybooksChartType.Nutrient && data" [data]="data" (chartClick)="onChartClick()">
    </toro-playbooks-nutrient-chart>

    <toro-playbooks-gdd-chart *ngIf="graphType == PlaybooksChartType.Gdd && data" [data]="data" (chartClick)="onChartClick()">
    </toro-playbooks-gdd-chart>
</ng-template>

<ng-template #playbooksLegend>
    <div *ngIf="graphType == PlaybooksChartType.Nutrient" class="twg-legend-container play-books-legend-container">
        <div *ngFor="let info of seriesLegendInfo" class="twg-legend"><fa-icon icon="square" [ngStyle]="{'color': info.color}"></fa-icon>
            <span class="twg-legend-label">{{info.name}}</span>
        </div>
    </div>
</ng-template>

<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="modalTitle" (cancel)="toggleChartDisplay(false)">
    <div id="twgd-chart-dlg-container"><ng-container *ngIf="showChart" [ngTemplateOutlet]="playbooksChart"></ng-container></div>
    <ng-container [ngTemplateOutlet]="playbooksLegend"></ng-container>
</toro-weather-graph-chart-dlg>
