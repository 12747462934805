import PogoMoistureRating = ToroEnums.PogoMoistureRating;
import PogoTopNav = ToroEnums.PogoTopNav;

import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { PogoZone } from '../../../../../api/pogo/models/pogo-zone.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-pogo-zone-card',
    templateUrl: './pogo-zone-card.component.html',
    styleUrls: ['./pogo-zone-card.component.less']
})
export class PogoZoneCardComponent implements OnInit {
    @HostBinding('class') class = 'toro-pogo-zone-card';

    PogoTopNav = PogoTopNav;

    @Output() zoneSelectionChange = new EventEmitter<PogoZone>();

    @Input() zone: PogoZone;
    @Input() index: number;
    @Input() isMobile = false;

    private _dataType: PogoTopNav;
    @Input() set dataType(value: PogoTopNav) {
        this._dataType = value;
        this.setData();
    }

    get dataType(): PogoTopNav {
        return this._dataType;
    }

    private _selectedZone: PogoZone;
    @Input() set selectedZone(value: PogoZone) {
        if (value == null) return;

        this._selectedZone = value;

        this.isSelected = this.zone.zone_name == value.zone_name
            && this.zone.conditions.min_location == value.conditions.min_location
            && this.zone.conditions.max_location == value.conditions.max_location;
    }

    get selectedZone(): PogoZone {
        return this._selectedZone;
    }

    ratingColor: string;
    ratingIcon = '';
    zoneData: string;
    isSelected = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private hostElement: ElementRef,
                private userFormatService: UserFormatService,
    ) { }

    ngOnInit(): void {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setData());
    }

    // =========================================================================================================================================================
    // public methods
    // =========================================================================================================================================================

    getNativeElement(): HTMLElement {
        return this.hostElement.nativeElement;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    selectZone() {
        this.isSelected = true;
        this.zoneSelectionChange.next(this.zone);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setData() {
        switch (this.dataType) {
            case PogoTopNav.Moisture:
                this.zoneData = this.userFormatService.toUserNumber(this.zone?.conditions?.moisture_avg, 2, 2);
                break;
            case PogoTopNav.DistributionUniformity:
                this.zoneData = this.userFormatService.toUserNumber(this.zone?.conditions?.moisture_du, 2, 2);
                break;
            case PogoTopNav.TurfPerformance:
                this.zoneData = this.zone?.conditions?.moisture_tpi;
                break;
        }

        this.setIconAndColor();
    }

    private setIconAndColor() {
        switch (this.zone?.conditions?.moistureRating) {
            case PogoMoistureRating.CriticallyLow:
                this.ratingIcon = 'mdi mdi-arrow-down-thin';
                break;
            case PogoMoistureRating.Low:
                this.ratingIcon = 'mdi mdi-arrow-bottom-left-thin';
                break;
            case PogoMoistureRating.Good:
                this.ratingIcon = 'mdi mdi-minus';
                break;
            case PogoMoistureRating.High:
                this.ratingIcon = 'mdi mdi-arrow-top-right-thin';
                break;
            case PogoMoistureRating.CriticallyHigh:
                this.ratingIcon = 'mdi mdi-arrow-up-thin';
                break;
        }

        this.ratingColor = this.getRatingColor();
    }

    private getRatingColor() {
        switch (this.dataType) {
            case PogoTopNav.Moisture:
                return this.zone?.conditions?.moisture_avg_color;
            case PogoTopNav.DistributionUniformity:
                return this.zone?.conditions?.moisture_du_color
            case PogoTopNav.TurfPerformance:
                return this.zone?.conditions?.moisture_tpi_color
        }
    }

}
