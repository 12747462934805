export class PwForecastPrecip {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    probability: number;
    accumulation: number;
    precipType: string;
    unit: string;
}
