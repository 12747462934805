export class EzLocatorPinSheetSummary {

    placementLeftCount = 0;
    placementRightCount = 0;
    lowStimpCount = 0;
    midStimpCount = 0;
    highStimpCount = 0;
    variance = 0;

}
