import WeatherAverageSources = ToroEnums.WeatherAverageSources;

import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

export class WeatherAveragesLegendItem {

    constructor(source: WeatherAverageSources, color: string) {
        this.source = source;
        this.color = color;

        this.setSourceName();
    }

    source: WeatherAverageSources;
    sourceName: string;
    color: string;
    borderStyle = 'solid';

    private setSourceName() {
        switch (this.source) {
            case WeatherAverageSources.ToroDtnWeather:
                this.sourceName = 'CASE_SENSITIVE.WEATHER_AVG_SOURCE_TORO';
                break;
            case WeatherAverageSources.PerryWeather:
                this.sourceName = 'CASE_SENSITIVE.WEATHER_AVG_SOURCE_PERRY';
                break;
            case WeatherAverageSources.Lynx:
                this.sourceName = 'CASE_SENSITIVE.WEATHER_AVG_SOURCE_LYNX';
                break;
            case WeatherAverageSources.Average:
                this.sourceName = 'STRINGS.AVERAGE';
                this.borderStyle = 'dotted';
                break;
            default:
                this.sourceName = 'STRINGS.UNKNOWN';
        }
    }
}
