import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Note } from './models/note.model';
import { NoteApiService } from './note-api.service';

@Injectable({
    providedIn: 'root'
})
export class NoteManagerService {
    // private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private noteService: NoteApiService
    ) {
        // if (environment.isDemoMode) {
        //   this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        // }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getNote(userId: string, siteId: number): Observable<any> {
        if (environment.isDemoMode) { return of(''); }

        return this.noteService.getNote(userId, siteId);
    }

    updateNote(note: Note): Observable<any> {
        if (environment.isDemoMode) { return of(''); }

        return this.noteService.updateNote(note);
    }

}
