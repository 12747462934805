import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import ToroColor = ToroEnums.ToroColor;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { NsnAnnouncements } from '../../../../../api/nsn/models/nsn-announcements.model';
import { NsnConnectDevice } from '../../../../../api/nsn/models/nsn-connect-device.model';
import { NsnManagerService } from '../../../../../api/nsn/nsn-manager.service';
import { NsnShipments } from '../../../../../api/nsn/models/nsn-shipments.model';
import { NsnStatus } from '../../../../../api/nsn/models/nsn-status.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-nsn',
    templateUrl: './widget-nsn.component.html',
    styleUrls: ['./widget-nsn.component.less']
})
export class WidgetNsnComponent extends ToroDashboardWidget implements OnInit {
    private readonly NO_VALUE = '--';

    iconColor = ToroColor.ToroRed;
    title = 'WIDGET.TORO_NSN';

    announcementsCount = 0;
    shipmentsCount = 0;
    nsnConnectDevices: NsnConnectDevice[] = [];
    serviceExpiry = this.NO_VALUE;
    serviceStatus = this.NO_VALUE;
    toroNsnUrl: string;
    toroNsnAnnouncementsUrl: string;
    toroNsnShipmentsUrl: string;

    isUnableToFetchAnnouncements = false;
    isUnableToFetchShipments = false;
    private isUnableToFetchStatus = false;
    private isUnableToFetchDevices = false;
    private fetchCallCount = 0;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private nsnManager: NsnManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.toroNsnUrl = environment.nsnUrl;
        this.toroNsnAnnouncementsUrl = environment.nsnUrl;
        this.toroNsnShipmentsUrl = `${environment.nsnUrl}/Facility-Overview`;

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.getWidgetData();
            });

        this.isBusy = true;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.NsnWidgetName;
    }

    protected getWidgetData() {
        this.getNsnDataInPieces();
        return;

        // const sources: Observable<any>[] = [
        //     // this.nsnManager.getAnnouncements().pipe(take(1)),
        //     this.nsnManager.getStatus().pipe(take(1)),
        //     this.nsnManager.getNsnConnectDevices().pipe(take(1)),
        //     this.nsnManager.getShipments().pipe(take(1))
        // ];
        //
        // forkJoin(sources)
        //     .pipe(finalize(() => {
        //         this.isWidgetInitialized = true;
        //         this.isBusy = false;
        //     }))
        //     // .subscribe(([announcements, status, devices, shipments]) => {
        //     .subscribe(([status, devices, shipments]) => {
        //         this.clearIsUnableToFetchData();
        //         this.lastUpdateTimestamp = new Date();
        //         // this.announcementsCount = announcements ? (<NsnAnnouncements>announcements).announcementsCount : 0;
        //         this.shipmentsCount = shipments ? (<NsnShipments>shipments).shipmentsCount : 0;
        //         this.serviceStatus = status ? (<NsnStatus>status).status : this.NO_VALUE;
        //         this.serviceExpiry = status ? this.userFormatService.toUserDateString((<NsnStatus>status).expirationDate) : this.NO_VALUE;
        //         this.nsnConnectDevices = devices.slice();
        //     }, error => {
        //         this.isUnableToFetchData = true;
        //         if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
        //     });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onNsnClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_NSN_SITE' });

        this.analyticsService.widgetEvent(AnalyticsEvent.NsnWidgetNsnButtonClicked, AnalyticsCategory.Interaction, AnalyticsEvent.NsnWidgetName);
        setTimeout(() => window.open(this.toroNsnUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onAnnouncementsClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.NsnWidgetAnnouncementsButtonClicked, AnalyticsCategory.Interaction, AnalyticsEvent.NsnWidgetName);
    }

    onShipmentsClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.NsnWidgetShipmentsButtonClicked, AnalyticsCategory.Interaction, AnalyticsEvent.NsnWidgetName);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    // Method to retrieve the NSN Widget data via individual calls (as opposed to using forkJoin). We switched to this method because the NSN
    // endpoints are less than reliable. This method will allow us to retrieve the data we can and ignore that which we cannot.
    private getNsnDataInPieces() {
        this.fetchCallCount = 0;

        this.nsnManager.getAnnouncements()
            .pipe(
                take(1),
                finalize(() => {
                    ++this.fetchCallCount;
                    this.updateFetchStatus();
                })
            )
            .subscribe({
                next: (announcements: NsnAnnouncements) => {
                    this.isUnableToFetchAnnouncements = false;
                    this.announcementsCount = announcements ? (<NsnAnnouncements>announcements).announcementsCount : 0;
                },
                error: err => {
                    this.isUnableToFetchAnnouncements = true;
                }
            });

        this.nsnManager.getStatus()
            .pipe(
                take(1),
                finalize(() => {
                    ++this.fetchCallCount;
                    this.updateFetchStatus();
                })
            )
            .subscribe({
                next: (status: NsnStatus) => {
                    this.isUnableToFetchStatus = false;

                    // Localize the string returned from NSN
                    this.serviceStatus = status ? (<NsnStatus>status).status : this.NO_VALUE;
                    this.serviceStatus = this.translateService.instant(`STRINGS.${this.serviceStatus.toUpperCase()}`)

                    this.serviceExpiry = status ? this.userFormatService.toUserDateString((<NsnStatus>status).expirationDate) : this.NO_VALUE;
                    if (this.serviceExpiry === 'Invalid date') { this.serviceExpiry = this.translateService.instant('STRINGS.INVALID_DATE'); }
                },
                error: err => {
                    this.isUnableToFetchStatus = true;
                }
            });

        this.nsnManager.getNsnConnectDevices()
            .pipe(
                take(1),
                finalize(() => {
                    ++this.fetchCallCount;
                    this.updateFetchStatus();
                })
            )
            .subscribe({
                next: (devices: NsnConnectDevice[]) => {
                    this.isUnableToFetchDevices = false;
                    this.nsnConnectDevices = devices.slice();
                },
                error: err => {
                    this.isUnableToFetchDevices = true;
                }
            });

        this.nsnManager.getShipments()
            .pipe(
                take(1),
                finalize(() => {
                    ++this.fetchCallCount;
                    this.updateFetchStatus();
                })
            )
            .subscribe({
                next: (shipments: NsnShipments) => {
                    this.isUnableToFetchShipments = false;
                    this.shipmentsCount = shipments ? (<NsnShipments>shipments).shipmentsCount : 0;
                },
                error: err => {
                    this.isUnableToFetchShipments = true;
                }
            });
    }

    private updateFetchStatus() {
        if (this.isUnableToFetchAnnouncements && this.isUnableToFetchStatus && this.isUnableToFetchDevices && this.isUnableToFetchShipments) {
            if (this.fetchCallCount === 4) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
        }

        if (this.fetchCallCount === 4) {
            this.lastUpdateTimestamp = new Date();
            this.isWidgetInitialized = true;
            this.isBusy = false;
        }
    }
}

