<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="tid-ezl-dialog">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="td-header-left">
                <div id="id-title">{{videoInfo.title | translate}}</div>
                <div id="id-description">{{videoInfo.description | translate}}</div>
            </div>
        </div>
    </p-header>

    <div id="id-yt-video-container">
        <div class="id-yt-video-abs-container">
            <div id="id-yt-video-aspect-container" class="video-containerX">
                <iframe #player id="id-yt-player-iframe" [src]="safeUrl" allowfullscreen></iframe>
            </div>
        </div>

        <div *ngIf="!isPlayerReady" class="id-yt-video-abs-container">
            <toro-waiting-indicator [text]="'Loading video...'"></toro-waiting-indicator>
        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
