<div id="id-video-card-container" class="toro-component-shield-host">
    <div id="id-video-card-title">
        {{videoInfo.title | translate}}
    </div>
    <div id="id-video-card-desc">
        {{videoInfo.description | translate}}
    </div>
    <div id="id-video-card-thumb">
        <img [src]="thumbNailUrl" alt="">
    </div>

    <toro-component-shield (shieldClick)="toggleDesktopModal()"></toro-component-shield>
</div>

<toro-video-panel-modal
        *ngIf="showDesktopModal"
        [videoInfo]="videoInfo"
        (cancel)="showDesktopModal = false"
></toro-video-panel-modal>
