<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.PUMP_STATION_SETTINGS' | translate | titlecase}}</p-header>

    <div id="psd-pump-name">{{pumpStation.name}}</div>

    <div id="pump-station-details-dlg-content">
        <div class="psd-content-pane" id="psd-left-pane">
            <div class="psd-content-header-row">{{ 'STRINGS.PRESSURE' | translate }}</div>
            <div class="psd-content-row">
                <div class="psd-content-label">{{ 'STRINGS.MINIMUM' | translate }}:</div>
                <div class="psd-content-value">{{ this.pressureMin }}<span class="psd-units">{{ this.pressureUnits }}</span></div>
            </div>
            <div class="psd-content-row">
                <div class="psd-content-label">{{ 'STRINGS.SET_POINT' | translate }}:</div>
                <div class="psd-content-value">{{ this.pressureSetPoint }}<span class="psd-units">{{ this.pressureUnits }}</span></div>
            </div>
            <div class="psd-content-row">
                <div class="psd-content-label">{{ 'STRINGS.MAXIMUM' | translate }}:</div>
                <div class="psd-content-value">{{ this.pressureMax }}<span class="psd-units">{{ this.pressureUnits }}</span></div>
            </div>
        </div>

        <div class="psd-content-pane" id="psd-right-pane">
            <div class="psd-content-header-row">{{ 'STRINGS.FLOW' | translate }}</div>
            <div class="psd-content-row">
                <div class="psd-content-label">{{ 'STRINGS.MINIMUM' | translate }}:</div>
                <div class="psd-content-value">{{ this.flowMin }}<span class="psd-units">{{ this.flowUnits }}</span></div>
            </div>
            <div class="psd-content-row">
                <div class="psd-content-label">{{ 'STRINGS.MAXIMUM' | translate }}:</div>
                <div class="psd-content-value">{{ this.flowMax }}<span class="psd-units">{{ this.flowUnits }}</span></div>
            </div>
        </div>

    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
