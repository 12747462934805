/* eslint-disable @typescript-eslint/dot-notation */

export class TurfGuardHoleAlert {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });
        }
    }

    temperature: number;
    moisture: number;
    salinity: number;

}
