import * as moment from 'moment';

export class SoilScoutCsvMeasurement {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.timestampUtc) this.timestampUtc = moment.utc(json.timestampUtc).toDate();
        }
    }

    timestampUtc: Date;
    siteName: string;
    scoutId: number;
    scoutName: string;
    imei: string;
    lat: string;
    lon: string;
    temperature: number;
    moisture: number;
    salinity: number;
    waterBalance: number;
}
