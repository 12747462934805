import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { GddGraphData } from '../../models/gdd-graph-data.model';
import { ToroEnums } from '../../../../../../common/enumerations/toro.enums';

@UntilDestroy()
@Component({
    selector: 'toro-playbooks-gdd-chart',
    templateUrl: './playbooks-gdd-chart.component.html',
    styleUrls: ['./playbooks-gdd-chart.component.less']
})
export class PlaybooksGddChartComponent implements OnDestroy {
    @HostBinding('class') class = 'toro-weather-graph-chart';
    @ViewChild('wgChartContainer') wgChartContainer: ElementRef;

    @Output() chartLoaded = new EventEmitter();
    @Output() chartClick = new EventEmitter();

    private _data: GddGraphData;
    @Input() set data(value: GddGraphData) {
        if (value == null) { return; }

        this._data = value;
        this.updateChart();
    }

    get data(): GddGraphData {
        return this._data;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                // private userFormatService: UserFormatService
    ) {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                // Slight delay added to allow Highcharts to render properly w/o throwing error.
                setTimeout(() => this.updateChart(), 250);
            });
    }

     ngOnDestroy() {
        this.chart = null;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateChart() {
        if (!this.chart) {
            setTimeout(() => this.setupChart());
        } else {
            this.chart.update({
                series: this.gddSeriesData,
            });
        }
    }

    private setSizeOfChartContainer() {
        if (!this.wgChartContainer) { return; }
        this.chartContainerWidth = this.wgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.wgChartContainer.nativeElement.offsetHeight - 2;
    }

    private setupChart() {
        const self = this;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'column',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) {self.chart = event.target; },
                    click() { self.chartClick.emit(); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [8, 5, 0, 5],
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: { enabled: false },
            },
            tooltip: { enabled: false },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                    enableMouseTracking: true,  // Determines if mouse selection occurs on bars.
                },
                series: {
                    events: {
                        click() { self.chartClick.emit(); }
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}',
                        // formatter(this, options) { return self.userFormatService.toUserNumber(this.y, 2, 2); },
                    }
                },
            },
            series: [
                {
                    name: 'gddData',
                    // colorByPoint: true,
                    data: [
                        {
                            name: '0',
                            y: self.data.gdd0c,
                            color: ToroEnums.PlaybooksGddColor.GDD0
                        },
                        {
                            name: '10',
                            y: self.data.gdd10c,
                            color: ToroEnums.PlaybooksGddColor.GDD10
                        },
                        {
                            name: '32',
                            y: self.data.gdd32,
                            color: ToroEnums.PlaybooksGddColor.GDD32
                        },
                        {
                            name: '50',
                            y: self.data.gdd50,
                            color: ToroEnums.PlaybooksGddColor.GDD50
                        }
                    ]
                }
            ]
        };
    }

    private get gddSeriesData(): any[] {
        const dataSeries = [];
        dataSeries.push({data: this.data.gridValues});
        return dataSeries;
    }
}
