export class WeatherSourceData {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    tempInFahrenheit?: number = null;
    precipInInches?: number = null;
    humidity?: number = null;
    et?: number = null;

}
