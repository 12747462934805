import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TaskTrackerDailySafetyCount } from '../../../../../../api/task-tracker/models/task-tracker-daily-safety-count.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-task-tracker-daily-safety-item',
    templateUrl: './task-tracker-daily-safety-item.component.html',
    styleUrls: ['./task-tracker-daily-safety-item.component.less']
})
export class TaskTrackerDailySafetyItemComponent implements OnInit {
    @HostBinding('class') class = 'toro-task-tracker-daily-safety-item';

    @Input() safetyItem: TaskTrackerDailySafetyCount;
    @Input() isToday = false;

    issuesIcon: any = 'check-circle';
    hasIssues = false;
    tooltipText = '';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private tranlsateService: TranslateService) { }

    ngOnInit(): void {
        this.issuesIcon = this.safetyItem.issues > 0 ? 'exclamation-triangle' : 'check-circle';
        this.hasIssues = this.safetyItem.issues > 0;

        const issuesText = this.tranlsateService.instant(this.safetyItem.issues === 1 ? 'STRINGS.ISSUE' : 'STRINGS.ISSUES');
        this.tooltipText = `${this.safetyItem.date.toDateString()}\n${issuesText}: ${this.safetyItem.issues}`;
    }
}
