<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="tid-ezl-dialog">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="td-header-left">
                <img id="tid-ez-title-icon" src="../../../../../../assets/images/ezLocator-logo.png" alt="">
                <div class="toro-flex-child">{{title | translate}} {{'STRINGS.PINSHEET' | translate | titlecase}}</div>
            </div>
            <div id="td-header-right">
                <div class="toro-widget-toolbar-button" (click)="onGotoSite()"><fa-icon icon="external-link-alt"></fa-icon></div>
            </div>
        </div>
    </p-header>

    <div id="toro-bt-dt-modal-content-container">
        <div id="ti-ez-modal-content-wrapper">

            <div id="tid-ezm-fixed-greens-container" >
                <div id="tid-ezm-scroll-greens-container">

                    <div id="tid-ezm-green-wrapper" class="prevent-select" *ngFor="let g of greenImages; let i = index;" >
                        <div id="tid-ezm-green-header">
                            <div>{{g.greenName}}</div>
                            <div>{{'STRINGS.EZ_LOCATOR_DEPTH_ABBR' | translate | uppercase}}: {{g.greenDepth}}</div>
                            <div>{{adjustedPinSheetEntries[i]?.holeFront || '--'}}, {{adjustedPinSheetEntries[i]?.holeSide || '--'}}</div>
                        </div>

                        <div id="tid-ezm-green-container">
                            <img class="prevent-select" src="{{'data:image/jpg;base64,' + g.greenImage}}"  [ngStyle]="{'height': modalImageSize + 'px', 'width': modalImageSize + 'px'}" alt="">
                            <fa-icon class="tid-ezl-pin" icon="circle"
                                     *ngIf="adjustedPinSheetEntries[i] != null && adjustedPinSheetEntries[i].holeYPixels != null"
                                     [ngStyle]="{'top': adjustedPinSheetEntries[i].holeYPixels - holeXOffset + 'px', 'left': adjustedPinSheetEntries[i].holeXPixels - holeYOffset + 'px', 'font-size': holeDiameter + 'px'}">
                            </fa-icon>
                        </div>

                        <div id="tid-ezm-green-footer">
                            <div id="tid-ezm-green-hole-center">{{adjustedPinSheetEntries[i]?.holeCenter || '--'}}</div>
                            <div id="tid-ezm-green-stimp" [ngStyle]="{'background-color': adjustedPinSheetEntries[i]?.stimpBackgroundColor || 'lightgray'}">
                                {{adjustedPinSheetEntries[i]?.stimp || '--'}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <toro-ez-locator-pin-placement-summary [pinSheet]="pinSheet"></toro-ez-locator-pin-placement-summary>

        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
