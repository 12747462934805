import * as moment from 'moment';
import { duration } from 'moment';

export class GetCourseReportStation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (this.completedAutomatic == null) this.completedAutomatic = json.CompletedAutomatic;
            if (this.completedManual == null) this.completedManual = json.CompletedManual;
            if (this.hasError == null) this.hasError = json.HasError;
            if (this.isRunning == null) this.isRunning = json.IsRunning
            if (this.stationId == null) this.stationId = json.StationId;
            if (this.totalDelta == null) this.totalDelta = duration(json.TotalDelta);

            delete this['CompletedAutomatic'];
            delete this['CompletedManual'];
            delete this['HasError'];
            delete this['IsRunning'];
            delete this['StationId'];
            delete this['TotalDelta'];
        }
    }

    completedAutomatic: string;
    completedManual: string;
    hasError: boolean;
    isRunning: boolean;
    stationId:number;
    totalDelta: moment.Duration;
}
