import { GenericMqttMessage } from '../generic-mqtt-message.model';
import { GetGeoTopoContent } from './get-geo-topo-content.model';

export class GetGeoTopoMessage extends GenericMqttMessage {

    constructor(json: any = null) {
        super(null);

        if (json) {
            Object.assign(this, json);

            if (json.content) this.content = new GetGeoTopoContent(json.content);
        }
    }

    content: GetGeoTopoContent;

}
