import WizardFaq = ToroEnums.WizardFaq;

import { Component, HostBinding, OnInit } from '@angular/core';
import { FaqModel } from '../models/faq.model';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';

@Component({
    selector: 'toro-step-faq',
    templateUrl: './step-faq.component.html',
    styleUrls: ['./step-faq.component.less']
})
export class StepFaqComponent implements OnInit {
    @HostBinding('class') class = 'toro-step-faq'

    WizardFaq = WizardFaq;

    faqs: FaqModel[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        this.setupFAQs();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setupFAQs() {

        this.faqs = [
            new FaqModel(
                WizardFaq.NotUpdating,
                'WIZARD_FAQS.WIDGETS_NOT_UPDATING_Q',
                'WIZARD_FAQS.WIDGETS_NOT_UPDATING_A',
                '../../../../../assets/images/wizard/faq-updating.png',
                '175px'
            ),
            new FaqModel(
                WizardFaq.ShowHideWidgets,
                'WIZARD_FAQS.HOW_TO_SHOW_AND_HIDE_WIDGETS_Q',
                'WIZARD_FAQS.HOW_TO_SHOW_AND_HIDE_WIDGETS_A',
                '../../../../../assets/images/wizard/faq-showHide.png',
                '270px'
            ),
            new FaqModel(
                WizardFaq.CustomIntegrations,
                'WIZARD_FAQS.CAN_I_ADD_CUSTOM_INTEGRATIONS_Q',
                'WIZARD_FAQS.CAN_I_ADD_CUSTOM_INTEGRATIONS_A'
            ),
            new FaqModel(
                WizardFaq.RealTimeUpdates,
                'WIZARD_FAQS.REAL_TIME_UPDATES_Q',
                'WIZARD_FAQS.REAL_TIME_UPDATES_A',
                '../../../../../assets/images/wizard/faq-dataSettings.png',
                '270px'
            ),
        ]
    }

}
