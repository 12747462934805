import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GcsaaChannelItem } from '../../../../../api/gcsaa-newsfeed/models/gcsaa-channel-item.model';

@Component({
    selector: 'toro-gcsaa-article-modal',
    templateUrl: './gcsaa-article-modal.component.html',
    styleUrls: ['./gcsaa-article-modal.component.less']
})
export class GcsaaArticleModalComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() gotoSite = new EventEmitter();

    @Input() article: GcsaaChannelItem;

    articleHtml: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        this.cleanupArticleHtml();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.cancel.emit();
    }

    onGotoSite() {
        this.gotoSite.emit();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private cleanupArticleHtml() {
        if (this.article == null || this.article.descriptionHtml == null) return;

        // Remove any pure line spacers from article.
        this.articleHtml = this.article.descriptionHtml.replace(/>&nbsp;</g, '');

        // Configure any <a> to open the link in a new tab.
        this.articleHtml = this.articleHtml.replace(/<a(.*?)>/g, '<a$1 target="_blank">');
    }
}
