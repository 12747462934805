import { SoilScoutDeviceMeasurement } from './soil-scout-device-measurement.model';

export class SoilScoutGroup {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.last_measurement) this.last_measurement = new SoilScoutDeviceMeasurement(json.last_measurement);
        }
    }

    id: number;
    name: string;
    site: number;
    devices: number[] = [];
    last_measurement: SoilScoutDeviceMeasurement;
}
