export class RunningStationsSummary {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    areaId: number;
    areaName: string;
    count: number;

}
