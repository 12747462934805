import { ToroEnums } from '../../../common/enumerations/toro.enums';
import ForecastCondition = ToroEnums.ForecastCondition;

export class ForecastWeather {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    conditions: string;
    conditionsValue: ForecastCondition;
    highTemperature: number;                // In Fahrenheit
    lowTemperature: number;                 // In Fahrenheit
    precipitation: number;
    cloudCover: number;
}
