import { Component, OnInit } from '@angular/core';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxArea } from '../../../../../api/lynx/models/lynx-area.model';
import { LynxCourse } from '../../../../../api/lynx/models/lynx-course.model';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { RunningStationsSummary } from '../../../../../api/lynx/models/running-stations-summary.model';
import { SelectItem } from 'primeng/api';
import { TimeStampedCollection } from '../../../../../api/_common/time-stamped-collection.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@Component({
    selector: 'toro-widget-lynx-running-stations',
    templateUrl: './widget-lynx-running-stations.component.html',
    styleUrls: ['./widget-lynx-running-stations.component.less'],
})
export class WidgetLynxRunningStationsComponent extends LynxWidgetBase implements OnInit {

    iconColor = 'goldenrod';
    title = 'WIDGET.LYNX_RUNNING_STATIONS';

    readonly maxAreasToDisplay = 4;
    readonly areaCountThresholdForListJustification = 5;
    runningStationsSummaries: RunningStationsSummary[];
    runningStationsDetails: TimeStampedCollection<LynxArea[]>;
    stationsCount: number;
    showDetailsDialog = false;
    lynxCourses: LynxCourse[] = [];
    lynxCoursesList: SelectItem[];
    selectedCourseId: number;

    private dashUserPrefs: DashUserPreferences;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onViewAll() {
        if (!this.stationsCount) { return; }
        this.showDetailsDialog = true;

        this.analyticsService.widgetEvent(AnalyticsEvent.LynxRunningStationsWidgetViewStationDetails, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onDataRefresh() {
        this.getWidgetData();
    }

    onCourseChange(eventInfo: { originalEvent: MouseEvent, value: number }) {
        this.dashUserPrefs.lynxCourseId = eventInfo.value;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();
        this.getWidgetData();

        this.analyticsService.widgetEvent(AnalyticsEvent.LynxRunningStationsWidgetCourseChanged, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LynxRunningStationsWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.lynxCourses = [];

        const sources: Observable<any>[] = [
            this.lynxManager.getCourses().pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(
                tap(([lynxCourses, dashUserInfo]) => {

                    // TODO: TEST
                    // lynxCourses.push(...lynxCourses);

                    this.lynxCourses = lynxCourses.slice();
                    this.lynxCoursesList = this.lynxCourses.map(c => ({ label: c.name, value: c.id }));
                    this.dashUserPrefs = dashUserInfo.preferences;
                }),
                switchMap(([lynxCourses, dashUserInfo]) => {
                    if (lynxCourses == null || lynxCourses.length < 1) { return of(null); }

                    // Set default to course to first one in list.
                    let selectedCourse = lynxCourses[0];

                    // If the user previous selected a course, use that
                    if (dashUserInfo != null && dashUserInfo.preferences.lynxCourseId != null) {
                        selectedCourse = lynxCourses.find(c => c.id === dashUserInfo.preferences.lynxCourseId) || lynxCourses[0];
                    }

                    this.selectedCourseId = selectedCourse.id;
                    return of(selectedCourse.id);
                }),
                switchMap((courseId: number) => this.lynxManager.getRunningStationsSummary(courseId))
            )
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((runningStationsSummary: TimeStampedCollection<RunningStationsSummary[]>) => {
                this.clearIsUnableToFetchData();
                this.runningStationsSummaries = runningStationsSummary.items
                    .sort((a, b) => a.areaName.toLocaleLowerCase().localeCompare(b.areaName.toLocaleLowerCase()));
                this.lastUpdateTimestamp = runningStationsSummary.lastUpdated;
                this.setComponentData();

                this.getRunningStationsDetails();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        if (!this.runningStationsSummaries || this.runningStationsSummaries.length < 1) {
            this.stationsCount = 0;
            return;
        }
        this.stationsCount = this.runningStationsSummaries.reduce((sum, area) => sum + area.count, 0);
    }

    private getRunningStationsDetails() {
        this.lynxManager.getRunningStationsDetails(this.selectedCourseId)
            .pipe(take(1))
            .subscribe((runningStations: TimeStampedCollection<LynxArea[]>) => {
                runningStations.items = runningStations.items.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
                this.runningStationsDetails = runningStations;
            }, error => {
                this.dashMessageService.showWidgetDataFetchErrorMessage('WIDGET.LYNX_RUNNING_STATIONS');
            });
    }

}
