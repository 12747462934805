export class SoilScoutLocation {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    latitude: number;
    longitude: number;
    height: number;
    soil_type: string;
    soil_density: number;
    field_capacity: number;
    wilting_point: number;
    irrigation_threshold: number;
    site_id: number;

}
