import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { AuthManagerService } from '../auth/auth-manager.service';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { EtResult } from './models/et-result.model';
import { FrostRiskData } from './models/frost-risk-data.model';
import { GoogleMapLocation } from '../_common/models/google-map-location.model';
import { Injectable } from '@angular/core';
import { LocalWeatherForecast } from './models/local-weather-forecast.model';
import { ToroConstants } from '../../common/constants/toro.constants';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';
import { WeatherApiService } from './weather-api.service';
import { WeatherGraphsData } from './models/weather-graphs-data.model';
import { WeatherIcon } from './models/weather-icon.model';
import { WeatherIconInfo } from './models/weather-icon-info.model';
import { WeatherRadarConfig } from './models/weather-radar-config.model';

@Injectable({
    providedIn: 'root'
})
export class WeatherManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private weatherApiService: WeatherApiService,
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getEvapotranspiration(): Observable<EtResult> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.evapotranspiration); }
        return this.weatherApiService.getEvapotranspiration();
    }

    getFrostRisk(): Observable<FrostRiskData> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.frostRisk); }
        return this.weatherApiService.getFrostRisk();
    }

    getLocalWeatherForecast(): Observable<LocalWeatherForecast> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.localWeatherForecast); }
        return this.weatherApiService.getLocalWeatherForecast();
    }

    getWeatherForecast(): Observable<LocalWeatherForecast> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.localWeatherForecast); }
        return this.weatherApiService.getWeatherForecast();
    }

    forceWeatherDataUpdate(siteId: number): Observable<any> {
        return this.weatherApiService.forceWeatherDataUpdate(siteId);
    }

    getWeatherGraphsData(): Observable<WeatherGraphsData> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.weatherGraphData); }
        return this.weatherApiService.getWeatherGraphsData();
    }

    getWeatherIcon(condition, cloudCover): WeatherIconInfo {
        const conditionMap = ToroConstants.WeatherStandardConditionNames;
        let standardConditionName = 'regular';

        // First - standardize the condition name.
        for (const prop in conditionMap) {
            if (standardConditionName != 'regular') break;

            if (conditionMap.hasOwnProperty(prop)) {
                conditionMap[prop].forEach((name) => {
                    if (condition.toLowerCase().includes(name)) return standardConditionName = prop;
                });
            }
        }

        const cloudCoverIndex = Math.floor(Math.max(0, cloudCover - 1) / 20);
        const baseIcon = ToroConstants.WeatherConditionAndCloudCoverToIconMap[standardConditionName][cloudCoverIndex];

        const weatherIconInfo = new WeatherIconInfo();
        weatherIconInfo.primaryIcon = new WeatherIcon(`climacon-icon-${baseIcon}`, this.getWeatherIconColor(baseIcon));

        if (baseIcon !== 'sun') {
            const baseOverlayIcon = baseIcon.replace('-sun', '');
            if (baseOverlayIcon !== '') {
                weatherIconInfo.overlayIcon = new WeatherIcon(`climacon-icon-${baseOverlayIcon}`, this.getWeatherIconColor(baseOverlayIcon));
            }
        }

        return weatherIconInfo;
    }

    getWeatherRadarConfig(toroGridsterWidget: ToroGridsterWidget, bypassCache = false): Observable<WeatherRadarConfig> {
        const location = new GoogleMapLocation({
            lat: this.authManager.dashAuthenticatedUser.latitude,
            lng: this.authManager.dashAuthenticatedUser.longitude,
        });

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('origin')) {
            const weatherRadarConfig = toroGridsterWidget.config as WeatherRadarConfig;

            if (!environment.isDemoMode && (weatherRadarConfig.origin.lat !== location.lat || weatherRadarConfig.origin.lng !== location.lng)) {
                weatherRadarConfig.origin = location;
                weatherRadarConfig.center = location;
            }

            return of(<WeatherRadarConfig>toroGridsterWidget.config);
        } else if (environment.isDemoMode) {
            // Fetch the demo config for map center point. This occurs if the demo widget is removed and re-added.
            return of(this.demoModeMockDataService.weatherRadarConfig);
        }

        const config = new WeatherRadarConfig(toroGridsterWidget.config);
        config.origin = location;
        config.center = location;

        return of(config);
    }

    getWeatherIconViaForecastHeuristic(precipChance: number, precipTotal: number, cloudCover: number): WeatherIconInfo {
        let condition = 'clear';

        if (cloudCover > 20 || precipChance > 10 || precipTotal > 0) condition = 'cloudy';

        if (precipChance > 0 && precipTotal >= .01) {
            condition = precipTotal >= .05 ? 'rain' : 'drizzle'
        }

        return this.getWeatherIcon(condition, cloudCover);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getWeatherIconColor(icon: string) {
        return (icon.includes('sun') ? 'goldenrod' : (icon === 'cloud') ? 'darkgray' : 'steelBlue');
    }

}
