<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="'STRINGS.NEWSFEED' | translate | titlecase"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-decision-tree-contentX toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane">
                <div class="toro-mini-mode-content-panel">

                    <ng-container [ngTemplateOutlet]="gcsaaRssFeed"></ng-container>

                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="'STRINGS.NEWSFEED' | translate | titlecase"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onNavigateToSite()"
    >

        <div class="toro-flex-child toro-component-shield-host">
            <div id="tdt-main-panel-content">
                <ng-container [ngTemplateOutlet]="gcsaaRssFeed"></ng-container>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="tid-gcsaa-title-icon" src="../../../../../assets/images/gcsaa/gcsaa-logo-of.png" alt="">
</ng-template>

<ng-template #gcsaaRssFeed>
    <div id="gcsaa-feed-scroll-container">
        <toro-gcsaa-feed-item-card
                *ngFor="let item of rssFeed?.items?.slice(0,3)"
                [item]="item"
                (showModal)="onShowArticleModal($event)"
        ></toro-gcsaa-feed-item-card>
    </div>
</ng-template>

<!--<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">-->
<!--    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">-->
<!--        <ng-container [ngTemplateOutlet]="standardView"></ng-container>-->
<!--    </div>-->
<!--</toro-mini-mode-widget-modal>-->

<toro-gcsaa-article-modal
        *ngIf="showArticleModal"
        [article]="selectedArticle"
        (cancel)="showArticleModal = false"
        (gotoSite)="onNavigateToSite()"
></toro-gcsaa-article-modal>
