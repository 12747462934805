/// <reference types='@runette/leaflet-fullscreen' />
import * as moment from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { icon, latLng, marker, Marker, tileLayer, TileLayer } from 'leaflet';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { FullscreenControlComponent } from '@runette/ngx-leaflet-fullscreen';
import { GestureHandling } from 'leaflet-gesture-handling';
import { GreenSiteManagerService } from '../../../../api/green-sight/green-site-manager.service';
import { KittyHawkConfig } from '../../../../api/green-sight/models/kitty-hawk-config.model';
import { KittyHawkFavorite } from '../../../../api/green-sight/models/kitty-hawk-favorite.model';
import { KittyHawkGreen } from '../../../../api/green-sight/models/kitty-hawk-green.model';
import { KittyHawkGreenMapInfo } from '../../../../api/green-sight/models/kitty-hawk-green-map-info.model';
import { KittyHawkLatLng } from '../../../../api/green-sight/models/kitty-hawk-lat-lng.model';
import { KittyHawkLayer } from '../../../../api/green-sight/models/kitty-hawk-layer.model';
import { KittyHawkLocation } from '../../../../api/green-sight/models/kitty-hawk-location.model';
import { KittyHawkMission } from '../../../../api/green-sight/models/kitty-hawk-mission.model';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { ToroUtils } from '../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { WidgetManagerService } from '../../../../api/widgets/widget-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import GreenSightViewer = ToroEnums.GreenSightViewer;

@UntilDestroy()
@Component({
    selector: 'toro-widget-green-sight',
    templateUrl: './widget-green-sight.component.html',
    styleUrls: ['./widget-green-sight.component.less']
})
export class WidgetGreenSightComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('fullscreenControl', { static: false }) fullscreenComponent: FullscreenControlComponent;

    iconColor = 'goldenrod';
    title = 'WIDGET.GREEN_SIGHT';

    private readonly MIN_ZOOM = 14;
    private readonly MAX_ZOOM = 21;

    GreenSightViewer = GreenSightViewer;

    hasActiveGsaAccount = true;
    leafletOptions: {};
    layersControl;
    layers;
    viewerOptions: SelectItem[];
    accountName: string;
    kittyHawkGreens: KittyHawkGreen[] = [];
    kittyHawkMissions: KittyHawkMission[];
    kittyHawkConfig: KittyHawkConfig;
    minDate: Date;
    maxDate: Date;
    invalidDates: Date[];
    layerOptions: SelectItem[];
    showFavoritesDialog = false;
    leafletMap: any;
    fullscreenOptions = {};

    // Map reference for the leaflet-fullscreen-control. It's a direct reference to the leafletMap.
    // It exists to avoid the dreaded ExpressionChangedAfterItHasBeenCheckedError.
    fullScreenMap: any;

    set selectedLayerName(value: string) {
        if (!this.kittyHawkConfig || this.kittyHawkConfig.layer === value) return;

        this.kittyHawkConfig.layer = value;
        this.updateKittyHawkConfig();

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetOverlayChanged, AnalyticsCategory.Interaction, value);
    }

    get selectedLayerName(): string {
        return this.kittyHawkConfig ? this.kittyHawkConfig.layer : '';
    }

    private _selectedView: GreenSightViewer;
    set selectedView(value: GreenSightViewer) {
        if (value === this._selectedView) { return; }

        this._selectedView = value;
        this.updateKittyHawkConfig();
        this.configureView();

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetViewerChanged, AnalyticsCategory.Interaction, this._selectedView);
    }

    get selectedView(): GreenSightViewer {
        return this._selectedView;
    }

    set selectedDate(value: Date) {
        if (!this.kittyHawkConfig || this.kittyHawkConfig.date === value) { return; }

        this.kittyHawkConfig.date = value;
        this.updateKittyHawkConfig();
        this.getGsaLayers();

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetDateChanged, AnalyticsCategory.Interaction, this._selectedView);
    }

    get selectedDate(): Date {
        return this.kittyHawkConfig ? this.kittyHawkConfig.date : null;
    }

    private tileLayers: {};
    private extendedTileLayer: any;
    private kittyHawkLocation: KittyHawkLocation;
    private currentKittyHawkLayer: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                private confirmationService: ConfirmationService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private greenSiteManager: GreenSiteManagerService,
                protected translateService: TranslateService,
                private widgetManager: WidgetManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        this.isBusy = true;
        super.ngOnInit();

        // No need to update this widget's data.
        this.updateIntervalInMinutes = undefined;

        this.viewerOptions = [
            { label: ToroUtils.Translate.instant('STRINGS.GS_COURSE_VIEWER'), value: GreenSightViewer.Course },
            { label: ToroUtils.Translate.instant('STRINGS.GS_GREENS_VIEWER'), value: GreenSightViewer.Greens },
            { label: ToroUtils.Translate.instant('STRINGS.GS_FAVORITES_VIEWER'), value: GreenSightViewer.Favorites },
        ];

        this.setExtendedTileLayer();

        this.accountName = ToroUtils.Translate.instant('ACCOUNT.GREEN_SIGHT');
        if (this.associatedWidget.config) { this.kittyHawkConfig = new KittyHawkConfig(this.associatedWidget.config); }

        this.setFullscreenOptions();

        // Monitor preference changes and update component language.
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setFullscreenOptions(true));
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.GreenSightWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        const gsaLocationId = this.authManager.dashAuthenticatedUser.gsaSiteId;

        const sources: Observable<any>[] = [
            this.greenSiteManager.getLocation(gsaLocationId).pipe(take(1)),
            this.greenSiteManager.getMissions(gsaLocationId).pipe(take(1)),
            this.greenSiteManager.getGreens(gsaLocationId).pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe(([location, missions, greens]) => {
                this.clearIsUnableToFetchData();
                this.kittyHawkLocation = location;
                this.kittyHawkMissions = (<KittyHawkMission[]>missions).sort((m1, m2) => m1.created.getTime() - m2.created.getTime());
                this.kittyHawkGreens = greens;

                // Set min/max dates for calendar control.
                const missionDates = this.kittyHawkMissions.map(m => moment(m.created).startOf('day'));
                this.minDate = missionDates.length > 0 ? missionDates[0].toDate() : moment().startOf('day').toDate();
                this.maxDate = missionDates.length > 0 ? missionDates[this.kittyHawkMissions.length - 1].toDate() : moment().startOf('day').toDate();

                // Create list of invalid dates
                if (missionDates && missionDates.length > 1) {
                    this.invalidDates = [];
                    let date = moment(missionDates[0]).add(1, 'd');
                    while (date < missionDates[missionDates.length - 1]) {
                        const validMissionDate = missionDates.find(m => m.isSame(date));
                        if (!validMissionDate) { this.invalidDates.push(date.toDate()); }

                        date = date.add(1, 'd');
                    }
                }

                if (!this.kittyHawkConfig) {
                    this.kittyHawkConfig = new KittyHawkConfig();
                    this.kittyHawkConfig.center = new KittyHawkLatLng({ lat: this.kittyHawkLocation.latitude, lng: this.kittyHawkLocation.longitude });
                }

                this.selectedView = this.viewerOptions.find(i => i.value === this.kittyHawkConfig.view).value;
                this.getGsaLayers();
                this.configureView();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setWidgetMenu() {
        this.widgetMenuItems = [
            { label: this.translateService.instant('STRINGS.RECENTER_MAP'), icon: 'pi pi-fw pi-map-marker', command: this.onRecenterMap.bind(this) },
            { label: this.translateService.instant('STRINGS.RESET_MAP'), icon: 'pi pi-fw pi-undo', command: this.onResetMap.bind(this) },
            { separator: true },
            { label: this.translateService.instant('STRINGS.ADD_FAVORITE'), icon: 'pi pi-fw pi-thumbs-up', command: this.onAddFavorite.bind(this) },
        ];
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onMapReady(leafletMap: any) {
        this.leafletMap = leafletMap;

        // Add gestureHandling (only once) to allow widget to be scrolled w/o zooming. This handler implements
        // command/ctrl scroll to zoom.
        if (!this.leafletMap.hasOwnProperty('gestureHandling')) {
            this.leafletMap.addInitHook('addHandler', 'gestureHandling', GestureHandling);
        }

        setTimeout(() => {
            this.fullScreenMap = leafletMap;
        });

        this.updateKittyHawkConfig();

        // Monitor the maps layers control for changes to the selected GreenSight layer.
        this.leafletMap.on('baselayerchange', (function(event) {
            this.currentKittyHawkLayer = event.layer;
            this.kittyHawkConfig.layer = event.name;
            this.updateKittyHawkConfig();

            this.analyticsService.event(AnalyticsEvent.GreenSightWidgetOverlayChanged, AnalyticsCategory.Interaction, event.layer.options.name);
        }).bind(this));

        // If we've already fetched the api data... This will occur when
        // we switch between the various view (e.g., course, greens, etc.)
        if (this.kittyHawkLocation) { this.configureView(); }
    }

    onMapMoveEnd(event: any) {
        this.updateKittyHawkConfig();
    }

    onMapZoomEnd(event: any) {
        this.updateKittyHawkConfig();
    }

    onRecenterMap() {
        this.leafletMap.setView([this.kittyHawkLocation.latitude, this.kittyHawkLocation.longitude]);
        this.updateKittyHawkConfig();

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetRecenterMap, AnalyticsCategory.Interaction);
    }

    onResetMap() {
        this.leafletMap.setView({ lat: this.kittyHawkLocation.latitude, lng: this.kittyHawkLocation.longitude }, 16);
        this.updateKittyHawkConfig();

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetResetMap, AnalyticsCategory.Interaction);
    }

    onAddFavorite() {
        if (!this.leafletMap) { return; }

        this.showFavoritesDialog = true;
        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetAddFavoriteClicked, AnalyticsCategory.Interaction);
    }

    onSaveFavorite(name: string) {
        const favorite: KittyHawkFavorite = {
            id: new Date().getTime().toString(),
            name,
            center: this.leafletMap.getCenter(),
            zoom: this.leafletMap.getZoom(),
            bounds: this.leafletMap.getBounds()
        };

        this.kittyHawkConfig.favoriteLocations.push(favorite);
        this.updateKittyHawkConfig();

        this.showFavoritesDialog = false;
        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetFavoriteSaved, AnalyticsCategory.Interaction);
    }

    onCancelAddFavorite() {
        this.showFavoritesDialog = false;
        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetAddFavoriteCancelled, AnalyticsCategory.Interaction);
    }

    onDeleteFavorite(mapInfo: { id: string, name: string }) {
        this.confirmationService.confirm({
            message: ToroUtils.Translate.instant('STRINGS.DELETE_LOCATION_PROMPT'),
            accept: () => {
                if (mapInfo.id) {
                    this.kittyHawkConfig.favoriteLocations = this.kittyHawkConfig.favoriteLocations.filter(f => f.id !== mapInfo.id);
                } else if (mapInfo.name) {
                    this.kittyHawkConfig.favoriteLocations = this.kittyHawkConfig.favoriteLocations.filter(f => f.name !== mapInfo.name);
                }

                this.updateKittyHawkConfig();
                this.analyticsService.event(AnalyticsEvent.GreenSightWidgetFavoriteDeleted, AnalyticsCategory.Interaction);
            }
        });
    }

    onMiniMapClick(mapInfo: KittyHawkGreenMapInfo, viewer: GreenSightViewer) {
        this.selectedView = GreenSightViewer.Course;
        this.configureBaseCourseView(mapInfo);

        this.analyticsService.event(AnalyticsEvent.GreenSightWidgetMiniMapClicked, AnalyticsCategory.Interaction, viewer);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setFullscreenOptions(isUserPrefChange = false) {
        if (!isUserPrefChange) {
            this.fullscreenOptions = {
                title: {
                    true: this.translateService.instant('STRINGS.EXIT_FULLSCREEN'),
                    false: this.translateService.instant('STRINGS.VIEW_FULLSCREEN')
                }
            };
        } else {
            // A bit of trickery to get the language to update on the fullscreen control.

            this.fullscreenComponent.control.remove();

            // @ts-ignore
            this.fullscreenComponent.control.options.title = {
                true: this.translateService.instant('STRINGS.EXIT_FULLSCREEN'),
                false: this.translateService.instant('STRINGS.VIEW_FULLSCREEN')
            };
            this.fullscreenComponent.control.addTo(this.leafletMap);
        }
    }

    private configureView() {
        this.selectedDate = this.kittyHawkConfig.date;

        if (this.selectedView === GreenSightViewer.Course) {
            this.configureBaseCourseView();
            if (this.leafletMap) {
                setTimeout(() => {
                    this.leafletMap.invalidateSize();
                    // this.getGsaLayers();
                    this.applyCourseViewLayers();
                });
            }
        }
    }

    private configureBaseCourseView(mapInfo?: KittyHawkGreenMapInfo) {
        this.leafletOptions = {
            layers: [
                tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                    minZoom: this.MIN_ZOOM,
                    maxZoom: this.MAX_ZOOM,
                    opacity: 0.5,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    attribution: ToroUtils.Translate.instant('STRINGS.GREEN_SITE_GOOGLE_ATTRIBUTION')
                })
            ],
            zoom: !mapInfo ? this.kittyHawkConfig.zoom : mapInfo.zoom,
            center: !mapInfo
                ? latLng(this.kittyHawkConfig.center.lat, this.kittyHawkConfig.center.lng)
                : latLng(mapInfo.lat, mapInfo.lng),
            gestureHandling: true,
        };

        // To overcome leaflet image bundling issue.
        const iconRetinaUrl = 'marker-icon-2x.png';
        const iconUrl = 'marker-icon.png';
        const shadowUrl = 'marker-shadow.png';
        const iconDefault = icon({
            iconRetinaUrl,
            iconUrl,
            shadowUrl,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41]
        });
        Marker.prototype.options.icon = iconDefault;

        this.layers = [
            marker([this.kittyHawkLocation.latitude, this.kittyHawkLocation.longitude])
        ];
    }

    private getGsaLayers() {
        this.tileLayers = {};

        // Get the first mission for selectedDate.
        const mission = this.kittyHawkMissions.find(m => moment(m.created).isSame(this.selectedDate, 'day'));
        if (!mission) { return; }

        this.greenSiteManager.getLayers(mission.uuid)
            .pipe(take(1))
            .subscribe(response => {
                this.layerOptions = [];

                response.forEach((r: KittyHawkLayer) => {
                    // Skip 'Non ET' Thermal layer. Does not seem to contain any viable tiles.
                    if (r.map_type.tab_name.toLowerCase() === 'thermal' && !r.map_type.filename.includes('et_')) {
                        return;
                    }

                    this.layerOptions.push({ label: r.map_type.tab_name, value: r.map_type.tab_name });

                    const uri = `https://api.greensightag.com/mission/${r.mission_uuid}/map/${r.map_type_id}/tile/{z}/{x}/{y}`;
                    this.tileLayers[r.map_type.tab_name] = new this.extendedTileLayer(uri, {
                        name: r.map_type.tab_name,
                        minZoom: this.MIN_ZOOM,
                        maxZoom: this.MAX_ZOOM,
                        maxNativeZoom: this.MAX_ZOOM,
                        tileSize: 256,
                        tms: true,
                        opacity: 1.0,
                    });
                });

                if (this.selectedView === GreenSightViewer.Course) {
                    this.applyCourseViewLayers();
                }
            }, error => {
                this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.analyticsWidgetName, 'Unable to get GSA Layers.');
            });
    }

    private applyCourseViewLayers() {
        if (!this.leafletMap || this.selectedView !== GreenSightViewer.Course) { return; }

        this.layersControl = { baseLayers: [] };

        // Remove any previously added layers
        if (this.leafletMap && this.currentKittyHawkLayer) {
            this.leafletMap.removeLayer(this.currentKittyHawkLayer);
        }

        this.layersControl.baseLayers = this.tileLayers;

        if (this.tileLayers.hasOwnProperty(this.kittyHawkConfig.layer)) {
            this.tileLayers[this.kittyHawkConfig.layer].addTo(this.leafletMap);
            this.currentKittyHawkLayer = this.tileLayers[this.kittyHawkConfig.layer];
        }
    }

    private setExtendedTileLayer() {
        const gsaToken = this.authManager.dashAuthenticatedUser.gsaToken;

        this.extendedTileLayer = <any>TileLayer.extend({
            createTile(coords) {
                const tile = document.createElement('img');
                const xhr = new XMLHttpRequest();
                xhr.open('GET', this.getTileUrl(coords), true);
                xhr.responseType = 'blob';
                xhr.setRequestHeader('Accept', 'image/png');
                xhr.setRequestHeader('Authorization', 'bearer ' + gsaToken);
                xhr.onload = function(e) {
                    if (this.status === 200) {
                        const blob = this.response;
                        tile.src = (window.URL || window.webkitURL).createObjectURL(blob);
                    } else {
                        tile.style.display = 'none';
                    }
                };
                xhr.send();
                return tile;
            }
        });
    }

    private updateKittyHawkConfig() {
        this.kittyHawkConfig.view = this.selectedView;
        this.kittyHawkConfig.date = this.selectedDate;

        if (this.selectedView === GreenSightViewer.Course && this.leafletMap) {
            this.kittyHawkConfig.center = this.leafletMap.getCenter();
            this.kittyHawkConfig.zoom = this.leafletMap.getZoom();
        }

        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.kittyHawkConfig)
            .pipe(take(1))
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }
}

