import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { environment } from '../../../../../../environments/environment';
import { TaskTrackerManagerService } from '../../../../../api/task-tracker/task-tracker-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../../models/wizard-settings.model';

@UntilDestroy()
@Component({
    selector: 'toro-task-tracker-integration',
    templateUrl: './task-tracker-integration.component.html',
    styleUrls: ['./task-tracker-integration.component.less']
})
export class TaskTrackerIntegrationComponent implements OnInit {
    @HostBinding('class') class = 'wiz-integration-component  toro-task-tracker-integration';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();
    @Output() settingsChange = new EventEmitter<WizardSettings>();

    @Input() settings: WizardSettings;

    readonly stepsCount = 4;
    private elements: any;

    isBusy = false;
    showError = false;
    spinnerText = 'CASE_SENSITIVE.VERIFYING_API_KEY'
    apiKey: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private taskTrackerManager: TaskTrackerManagerService
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.verifyConfiguration();
            });

        // We want to modify the behavior of the p-inputNumber component to allow us to assess the controls
        // value after each key press. To do that, we will search the dom for our unique container component
        // and then find out "common" p-inputNumber class element. There will only be one in our container.
        // Once we have our element, we will assign the keyup event handler.
        const container = document.getElementsByClassName('wic-task-tracker-info');
        this.elements = container[0].getElementsByClassName('task-tracker-api-key');

        if (this.elements.length != 1) throw new Error('There should be one and only one input element here.');
        this.elements[0].addEventListener('keyup', this.keyUp.bind(this))

        if (this.settings && this.settings.taskTrackerApiKey != null) {
            this.apiKey = this.settings.taskTrackerApiKey;
            this.canConnect.next(true);
        }
    }

    ngOnDestroy() {
        this.elements[0].removeEventListener('keyup', this.keyUp.bind(this))
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    onNavigateToSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_TASK_TRACKER_SITE' });
        setTimeout(() => window.open(environment.taskTrackerUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    private keyUp(event) {
        this.canConnect.emit(event.target.value.length == 36 && !event.target.value.includes('_'));
    }

    onPasteApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    onCutApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private verifyConfiguration() {
        this.showError = false;
        this.isBusy = true;

        const sources: Observable<any>[] = [
            this.taskTrackerManager.getActiveCompanies(false).pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ]

        forkJoin(sources)
            .pipe(
                take(1),
                finalize(() => this.isBusy = false)
            )
            .subscribe({
                next: ([companies, dashUser]) => {
                    const company = companies.find(c => c.uuid === this.apiKey);
                    const companyId = company?.id;
                    if (companyId != null) {
                        this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerSafety, isEnabled: true });
                        this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerLaborSummary, isEnabled: true });
                        this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.TaskTrackerDailyMeasurements, isEnabled: true });

                        const userPrefs = dashUser.preferences;
                        userPrefs.taskTrackerApiKey = this.apiKey;
                        this.dashUserManager.updateDashUserPreferences(userPrefs)
                            .subscribe(() => {
                                this.settings.taskTrackerApiKey = this.apiKey;
                                this.settingsChange.next(this.settings);
                                this.configured.next(WizardIntegration.TaskTracker);

                                // Notify TaskTracker Widgets. This will only matter if the TaskTracker widgets are already being displayed.
                                this.broadcastService.setupTaskTrackerApiKeyChanged.next(this.apiKey);
                            })
                    } else {
                        this.showError = true;
                    }
                },
                error: err => {
                    console.log(err);
                }
            });
    }

}
