import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { TaskTrackerDailyMeasurementInfo } from '../../models/task-tracker-daily-measurement-info.model';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-task-tracker-daily-measurement-item',
    templateUrl: './task-tracker-daily-measurement-item.component.html',
    styleUrls: ['./task-tracker-daily-measurement-item.component.less']
})
export class TaskTrackerDailyMeasurementItemComponent implements OnInit {
    private readonly UP_ICON_COLOR = '#4fbd60';
    private readonly DOWN_ICON_COLOR = 'red';

    @Input() dailyMeasurement: TaskTrackerDailyMeasurementInfo;

    icon: any;
    iconColor: string;
    userCurrentAvg: string;
    userUnits: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) { }

    ngOnInit(): void {
        this.icon = this.dailyMeasurement.currentAvg > this.dailyMeasurement.previousAvg ? 'arrow-up' : 'arrow-down';
        this.iconColor = this.dailyMeasurement.currentAvg > this.dailyMeasurement.previousAvg ? this.UP_ICON_COLOR : this.DOWN_ICON_COLOR;

        this.setComponentData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        let value: number;

        switch (this.dailyMeasurement.units?.toLowerCase()) {
            case 'f':
                this.userCurrentAvg = <string>this.userFormatService.temperature(this.dailyMeasurement.currentAvg, true);
                this.userUnits = this.userFormatService.temperatureUnits;
                break;
            case 'us gal':
                value = !this.userFormatService.isMetric ? this.dailyMeasurement.currentAvg : this.userFormatService.gallonsToLitres(this.dailyMeasurement.currentAvg);
                this.userCurrentAvg = this.userFormatService.toUserNumber(value)
                this.userUnits = !this.userFormatService.isMetric ? this.dailyMeasurement?.units : this.translateService.instant('UNITS.LITRES_ABBR');
                break;
            case 'us qt':
                value = !this.userFormatService.isMetric ? this.dailyMeasurement.currentAvg : this.userFormatService.quartzToLitres(this.dailyMeasurement.currentAvg);
                this.userCurrentAvg = this.userFormatService.toUserNumber(value)
                this.userUnits = !this.userFormatService.isMetric ? this.dailyMeasurement?.units : this.translateService.instant('UNITS.LITRES_ABBR');
                break;case 'ft':
                value = !this.userFormatService.isMetric ? this.dailyMeasurement.currentAvg : this.userFormatService.feetToMeters(this.dailyMeasurement.currentAvg);
                this.userCurrentAvg = this.userFormatService.toUserNumber(value)
                this.userUnits = !this.userFormatService.isMetric ? this.dailyMeasurement?.units : this.translateService.instant('UNITS.METERS_ABBR');
                break;
            default:
                this.userCurrentAvg = this.userFormatService.toUserNumber(this.dailyMeasurement.currentAvg);
                this.userUnits = this.dailyMeasurement?.units;
                break;
        }
    }

}
