<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="miniWidgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
    >

        <div class="toro-decision-tree-content toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tdt-content" class="toro-mini-mode-content">

                        <div id="tdt-icon-content" class="tdt-content-row">
                            <i class="tmm-ul-icon toro-id-grass"></i>
                        </div>

                        <div id="tdt-value-content" class="tdt-content-row">
                            <ng-container [ngTemplateOutlet]="irrigationStatusPane"></ng-container>
                        </div>

                        <div id="tdt-hole-sensor-content" class="tdt-content-row">
                            <div class="tmm-bot-left-container tmm-ellipsis-text"><span>{{selectedZoneName}}</span><span>{{selectedSensorName}}</span></div>
                        </div>

                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-decision-tree-content toro-flex-child toro-component-shield-host">

            <div id="tdt-main-panel-content">
                <div id="tdt-mp-selector-row" class="tdt-mp-row tdt-mp-fixed-row">
                    <p-dropdown
                            [placeholder]="'STRINGS.SELECT_ZONE' | translate | titlecase"
                            [options]="zoneListItems"
                            [(ngModel)]="selectedZoneId"
                            (onChange)="onZoneChange($event)"
                    ></p-dropdown>
                    <p-dropdown
                            [placeholder]="'STRINGS.SELECT_SENSOR' | translate | titlecase"
                            [options]="sensorListItems"
                            [(ngModel)]="selectedSensorNumber"
                            [disabled]="sensorListItems == null || sensorListItems.length < 1"
                            (onChange)="onSensorChange($event)"
                    ></p-dropdown>
                </div>
                <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                    <toro-decision-tree-datum-row
                            [borderColor]="DatumRowColors.SoilMoistureTextAndBorder"
                            [iconBackgroundColor] = "DatumRowColors.SoilMoistureTextAndBorder"
                            [textBackgroundColor]="DatumRowColors.SoilMoistureBackground"
                            [text]="'STRINGS.SOIL_MOISTURE' | translate | titlecase"
                            [icon]="'toro-id-soil-moisture'"
                            [iconSize]="'25px'"
                            [textJustify]="DatumTextJustify.Right"
                            [textColor]="DatumRowColors.SoilMoistureTextAndBorder"
                            [value]="soilMoisture"
                            [decimalPlaces]="2"
                            [minValue]="soilMoistureMin"
                            [maxValue]="soilMoistureMax"
                            [valueUnits]="'%'"
                    ></toro-decision-tree-datum-row>
                </div>
                <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                    <toro-decision-tree-datum-row
                            [inputId]="'greenCanopyCover'"
                            [borderColor]="DatumRowColors.CanopyCoverTextAndBorder"
                            [iconBackgroundColor] = "DatumRowColors.CanopyCoverTextAndBorder"
                            [textBackgroundColor]="DatumRowColors.CanopyCoverBackground"
                            [text]="'STRINGS.GREEN_CANOPY_COVER' | translate | titlecase"
                            [icon]="'toro-id-grass'"
                            [iconSize]="'20px'"
                            [textJustify]="DatumTextJustify.Right"
                            [textColor]="DatumRowColors.CanopyCoverTextAndBorder"
                            [editorType]="DatumRowEditorType.TextBox"
                            [(value)]="percentCanopyCover"
                            [minValue]="percentCanopyCoverMin"
                            [maxValue]="percentCanopyCoverMax"
                            [valueUnits]="'%'"
                    ></toro-decision-tree-datum-row>
                </div>
                <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                    <toro-decision-tree-datum-row
                            [borderColor]="DatumRowColors.EstimatedRainTextAndBorder"
                            [iconBackgroundColor] = "DatumRowColors.EstimatedRainTextAndBorder"
                            [textBackgroundColor]="DatumRowColors.EstimatedRainBackground"
                            [text]="'STRINGS.ESTIMATED_RAIN' | translate | titlecase"
                            [icon]="'toro-id-estimated-rain'"
                            [iconSize]="'22px'"
                            [textJustify]="DatumTextJustify.Right"
                            [textColor]="DatumRowColors.EstimatedRainTextAndBorder"
                            [decimalPlaces]="2"
                            [value]="estimatedRain"
                            [minValue]="estimatedRainMin"
                            [maxValue]="estimatedRainMax"
                            [valueUnits]="estimatedRainUnits"
                    ></toro-decision-tree-datum-row>
                </div>
                <ng-container [ngTemplateOutlet]="irrigationStatusPane"></ng-container>
                <div *ngIf="isButtonPanelVisible" id="tdt-mp-btn-container" class="tdt-mp-row">
                    <toro-widget-button
                            [text]="'STRINGS.SET_THRESHOLDS' | translate | titlecase"
                            [disabled]="false"
                            (buttonClick)="toggleThresholdsPanel()"
                    ></toro-widget-button>

                    <toro-widget-button
                            [text]="'STRINGS.SEE_BREAKDOWN' | translate | titlecase"
                            [disabled]="false"
                            (buttonClick)="onShowBreakdown()"
                    ></toro-widget-button>
                </div>
            </div>

            <div id="tdt-info-panel" class="tdt-sub-panel" *ngIf="isInfoPanelVisible" [ngClass]="{'show': isInfoPanelDisplayed}">
                <div class="tdt-sub-panel-header">
                    <div class="tdt-sub-panel-header-title">{{'STRINGS.WIDGET_INFO' | translate | titlecase}}</div>
                    <div class="tdt-sub-panel-close-button" (click)="onCloseSubPanel(DecisionTreePanel.WidgetInfo)">
                        <fa-icon icon="arrow-alt-circle-left"></fa-icon>
                    </div>
                </div>
                <div class="tdt-sub-panel-content-container">
                    <div id="tdt-info-text" class="tdt-sub-panel-scroll-container">
                        {{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P1' | translate}}
                        <p>{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P2' | translate}}</p>
                        <p>{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P3' | translate}}</p>

                        <div id="tdt-info-canopy-images">
                            <div class="tdt-info-canopy-image-row">
                                <img src="../../../../../../assets/images/decision-tree/green_canopy_80.png" alt="">
                                <img src="../../../../../../assets/images/decision-tree/white_canopy_80.png" alt="">
                            </div>
                            <div class="tdt-info-canopy-image-row">
                                <img src="../../../../../../assets/images/decision-tree/green_canopy_60.png" alt="">
                                <img src="../../../../../../assets/images/decision-tree/white_canopy_60.png" alt="">
                            </div>
                        </div>
                        <span class="tdt-info-figure-text">{{'SPECIAL_MSG.DECISION_TREE_WIDGET_FIGURE_1' | translate}}</span>

                        <p>{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P4a' | translate}}</p>

                        <div class="tdt-flex-row">
                            <div class="tdt-num-list-num">1.</div>
                            <div class="tdt-num-list-item">{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P4_1' | translate}}</div>
                        </div>
                        <div class="tdt-flex-row">
                            <div class="tdt-num-list-num">2.</div>
                            <div class="tdt-num-list-item">{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P4_2' | translate}}</div>
                        </div>
                        <div class="tdt-flex-row">
                            <div class="tdt-num-list-num">3.</div>
                            <div class="tdt-num-list-item">{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P4_3' | translate}}</div>
                        </div>

                        <p>{{'SPECIAL_MSG.DECISION_TREE_WIDGET_INFO_P5' | translate}}</p>
                    </div>
                </div>
            </div>

            <div id="tdt-thresholds-panel" class="tdt-sub-panel" *ngIf="isThresholdsPanelVisible" [ngClass]="{'show': isThresholdsPanelDisplayed}">
                <div class="tdt-sub-panel-header">
                    <div class="tdt-sub-panel-header-title">{{'STRINGS.THRESHOLDS' | translate | titlecase}}</div>
                    <div class="tdt-sub-panel-close-button" (click)="onCloseSubPanel(DecisionTreePanel.Thresholds)">
                        <fa-icon icon="arrow-alt-circle-left"></fa-icon>
                    </div>
                </div>
                <div id="tdt-threshold-panel-content" class="tdt-sub-panel-content-container">
                    <div id="tdt-threshold-info-container" class="toro-component-shield-host">
                        <div id="tdt-threshold-info-icon"><fa-icon icon="info-circle"></fa-icon></div>
                        <div id="tdt-threshold-info-text">{{'SPECIAL_MSG.THRESHOLD_INFO' | translate}}</div>
                        <toro-component-shield></toro-component-shield>
                    </div>

                    <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                        <toro-decision-tree-datum-row
                                containerClass="soil-moisture-threshold"
                                [inputId]="'soilMoistureThreshold'"
                                [borderColor]="DatumRowColors.SoilMoistureTextAndBorder"
                                [iconBackgroundColor] = "DatumRowColors.SoilMoistureTextAndBorder"
                                [textBackgroundColor]="DatumRowColors.SoilMoistureBackground"
                                [text]="'STRINGS.SOIL_SENSOR' | translate"
                                [textJustify]="DatumTextJustify.Center"
                                [textColor]="DatumRowColors.ThresholdTextColor"
                                [icon]="'toro-id-soil-moisture'"
                                [iconSize]="'25px'"
                                [editorType]="DatumRowEditorType.TextBox"
                                [(value)]="soilMoistureThreshold"
                                [minValue]="soilMoistureThresholdMin"
                                [maxValue]="soilMoistureThresholdMax"
                                [valueUnits]="'%'"
                        ></toro-decision-tree-datum-row>
                    </div>
                    <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                        <toro-decision-tree-datum-row
                                containerClass="canopy-cover-threshold"
                                [inputId]="'canopyCoverThreshold'"
                                [borderColor]="DatumRowColors.CanopyCoverTextAndBorder"
                                [iconBackgroundColor] = "DatumRowColors.CanopyCoverTextAndBorder"
                                [textBackgroundColor]="DatumRowColors.CanopyCoverBackground"
                                [text]="'SPECIAL_MSG.THRESHOLD_CANOPY_COVER_TEXT' | translate"
                                [textJustify]="DatumTextJustify.Center"
                                [textColor]="DatumRowColors.ThresholdTextColor"
                                [icon]="'toro-id-grass'"
                                [iconSize]="'20px'"
                                [editorType]="DatumRowEditorType.TextBox"
                                [(value)]="percentCanopyCoverThreshold"
                                [minValue]="percentCanopyCoverMin"
                                [maxValue]="percentCanopyCoverMax"
                                [valueUnits]="'%'"
                        ></toro-decision-tree-datum-row>
                    </div>
                    <div class="tdt-mp-row tdt-mp-fixed-row tdt-mp-datum-row">
                        <toro-decision-tree-datum-row
                                containerClass="estimated-rain-threshold"
                                [inputId]="'estimatedRainThreshold'"
                                [borderColor]="DatumRowColors.EstimatedRainTextAndBorder"
                                [iconBackgroundColor] = "DatumRowColors.EstimatedRainTextAndBorder"
                                [textBackgroundColor]="DatumRowColors.EstimatedRainBackground"
                                [text]="(!isMetric ? 'SPECIAL_MSG.THRESHOLD_ESTIMATED_RAIN_TEXT' : 'SPECIAL_MSG.THRESHOLD_ESTIMATED_RAIN_TEXT_METRIC') | translate"
                                [textJustify]="DatumTextJustify.Center"
                                [textColor]="DatumRowColors.ThresholdTextColor"
                                [icon]="'toro-id-estimated-rain'"
                                [iconSize]="'22px'"
                                [editorType]="DatumRowEditorType.TextBox"
                                [(value)]="estimateRainThreshold"
                                [minValue]="estimatedRainMin"
                                [maxValue]="estimatedRainMax"
                                [decimalPlaces]="2"
                                [step]="0.01"
                        ></toro-decision-tree-datum-row>
                    </div>

                </div>
            </div>

            <div id="tdt-breakdown-panel" class="tdt-sub-panel" *ngIf="isBreakdownPanelVisible" [ngClass]="{'show': isBreakdownPanelDisplayed}">
                <div class="tdt-sub-panel-header">
                    <div class="tdt-sub-panel-header-title">{{'STRINGS.BREAKDOWN' | translate | titlecase}}</div>
                    <div class="tdt-sub-panel-close-button" (click)="onCloseSubPanel(DecisionTreePanel.Breakdown)">
                        <fa-icon icon="arrow-alt-circle-left"></fa-icon>
                    </div>
                </div>

                <div id="tdt-breakdown-panel-content" class="tdt-sub-panel-content-container">
                    <div id="tdtb-values-container">

                        <toro-decision-tree-breakdown-item
                                [headerIcon]="'toro-id-soil-moisture'"
                                [headerText]="'STRINGS.SOIL_MOISTURE'"
                                [itemColor]="DatumRowColors.SoilMoistureTextAndBorder"
                                [gaugeBackgroundColor]="DatumRowColors.SoilMoistureBackground"
                                [value]="soilMoisture"
                                [valueUnits]="'%'"
                                [rangeTop]="soilMoistureMax"
                                [rangeBottom]="soilMoistureMin"
                                [threshold]="soilMoistureThreshold"
                        ></toro-decision-tree-breakdown-item>

                        <toro-decision-tree-breakdown-item
                                [headerIcon]="'toro-id-grass'"
                                [iconSize]="greenCanopyIconSize"
                                [headerText]="'STRINGS.PERCENT_GREEN_CANOPY_COVER'"
                                [itemColor]="DatumRowColors.CanopyCoverTextAndBorder"
                                [gaugeBackgroundColor]="DatumRowColors.CanopyCoverBackground"
                                [value]="percentCanopyCover"
                                [valueUnits]="'%'"
                                [rangeTop]="percentCanopyCoverMax"
                                [rangeBottom]="percentCanopyCoverMin"
                                [threshold]="percentCanopyCoverThreshold"
                        ></toro-decision-tree-breakdown-item>

                        <toro-decision-tree-breakdown-item
                                [styleClass]="'estimated-rain'"
                                [iconSize]="estRainIconSize"
                                [headerIcon]="'toro-id-estimated-rain'"
                                [headerText]="'STRINGS.ESTIMATED_RAIN'"
                                [itemColor]="DatumRowColors.EstimatedRainTextAndBorder"
                                [gaugeBackgroundColor]="DatumRowColors.EstimatedRainBackground"
                                [decimalPlaces]="2"
                                [thresholdDecimalPlaces]="2"
                                [value]="estimatedRain"
                                [valueUnits]="estimatedRainThresholdUnits"
                                [rangeTop]="estimatedRainMax"
                                [rangeBottom]="estimatedRainMin"
                                [threshold]="estimateRainThreshold"
                        ></toro-decision-tree-breakdown-item>

                    </div>
                    <ng-container [ngTemplateOutlet]="irrigationStatusPane"></ng-container>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #irrigationStatusPane>
    <div id="tdt-info-pane" class="tdt-mp-row tdt-mp-dynamic-row toro-component-shield-host" [ngClass]="{'dtip-irrigate': irrigationStatus === DTIrrigationStatus.Irrigate, 'dtip-hold': irrigationStatus === DTIrrigationStatus.HoldIrrigation}">
        <fa-icon *ngIf="infoIcon !== ''" [icon]="infoIcon"></fa-icon>
        <span>{{infoText | translate}}</span>
        <toro-component-shield></toro-component-shield>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
