import { LynxWeatherStationStatus } from './lynx-weather-station-status.model';

export class LynxWeatherStation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.statuses) this.statuses = json.statuses.map(s => new LynxWeatherStationStatus(s));
        }
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    id: number;
    lynxId: number;
    name: string;
    statuses: LynxWeatherStationStatus[];
    index?: number;

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    // NOTE: Used by WidgetLynxLocalWeatherGraphs Component
    get lastUpdatedTimestamp(): Date {
        const currentStatus =  this.statuses.find(s => s.time == null);
        return currentStatus ? currentStatus.lastUpdated : null;
    }
}
