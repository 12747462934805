import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { EnableWidget } from '../models/enable-widget.model';
import { EnableWidgetGroup } from '../models/enable-widget-group.model';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';

@Component({
    selector: 'toro-step-enabling',
    templateUrl: './step-enabling.component.html',
    styleUrls: ['./step-enabling.component.less']
})
export class StepEnablingComponent implements OnInit {
    @HostBinding('class') class = 'toro-step-enabling';

    @Output() selectedWidgetsChange = new EventEmitter<{ type: WidgetType, isSelected: boolean }[]>();

    private _selectedWidgets: { type: WidgetType, isSelected }[];
    @Input() set selectedWidgets(value: { type: WidgetType, isSelected }[]) {
        this._selectedWidgets = value;
        this.selectedWidgetsChange.emit(this._selectedWidgets);
    }

    get selectedWidgets(): { type: WidgetType, isSelected }[] {
        return this._selectedWidgets;
    }

    widgetGroups: EnableWidgetGroup[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        this.setupWidgetGroups();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setupWidgetGroups() {
        // Images must be in assets/images/wizard/
        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'toroLogo.png',
                widgets: [
                    new EnableWidget(WidgetType.Nsn, 'WIZARD_WIDGETS.TORO_NSN', this.isWidgetEnabled(WidgetType.Nsn)),
                    new EnableWidget(WidgetType.DecisionTree, 'WIZARD_WIDGETS.DECISION_TREE', this.isWidgetEnabled(WidgetType.DecisionTree)),
                    new EnableWidget(WidgetType.Gcsaa, 'WIZARD_WIDGETS.GCSAA', this.isWidgetEnabled(WidgetType.Gcsaa))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'toroWeather.png',
                widgets: [
                    new EnableWidget(WidgetType.Evapotranspiration, 'WIZARD_WIDGETS.EVAPOTRANSPIRATION', this.isWidgetEnabled(WidgetType.Evapotranspiration)),
                    new EnableWidget(WidgetType.LocalWeatherForecast, 'WIZARD_WIDGETS.LOCAL_WEATHER', this.isWidgetEnabled(WidgetType.LocalWeatherForecast)),
                    new EnableWidget(WidgetType.FrostWarning, 'WIZARD_WIDGETS.FROST_WARNING', this.isWidgetEnabled(WidgetType.FrostWarning)),
                    new EnableWidget(WidgetType.WeatherRadar, 'WIZARD_WIDGETS.WEATHER_RADAR', this.isWidgetEnabled(WidgetType.WeatherRadar)),
                    new EnableWidget(WidgetType.WeatherGraphs, 'WIZARD_WIDGETS.WEATHER_GRAPHS', this.isWidgetEnabled(WidgetType.WeatherGraphs)),
                    new EnableWidget(WidgetType.WeatherAverages, 'WIZARD_WIDGETS.WEATHER_AVERAGES', this.isWidgetEnabled(WidgetType.WeatherAverages)),
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'toroLynxLogo.png',
                widgets: [
                    new EnableWidget(WidgetType.LynxStationStatuses, 'WIZARD_WIDGETS.LYNX_CLOUD', this.isWidgetEnabled(WidgetType.LynxStationStatuses)),
                    new EnableWidget(WidgetType.LynxPumpStations, 'WIZARD_WIDGETS.LYNX_PUMP_STATIONS', this.isWidgetEnabled(WidgetType.LynxPumpStations)),
                    new EnableWidget(WidgetType.LynxLocalCurrentWeather, 'WIZARD_WIDGETS.LYNX_LOCAL_CURRENT_WEATHER', this.isWidgetEnabled(WidgetType.LynxLocalCurrentWeather)),
                    new EnableWidget(WidgetType.LynxLocalWeatherGraphs, 'WIZARD_WIDGETS.LYNX_LOCAL_WEATHER_GRAPHS', this.isWidgetEnabled(WidgetType.LynxLocalWeatherGraphs))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'toroTurfGuardLogo.png',
                widgets: [
                    new EnableWidget(WidgetType.TurfGuardMoisture, 'WIZARD_WIDGETS.TURF_GUARD_MOISTURE', this.isWidgetEnabled(WidgetType.TurfGuardMoisture)),
                    new EnableWidget(WidgetType.TurfGuardSalinity, 'WIZARD_WIDGETS.TURF_GUARD_SALINITY', this.isWidgetEnabled(WidgetType.TurfGuardSalinity)),
                    new EnableWidget(WidgetType.TurfGuardTemperature, 'WIZARD_WIDGETS.TURF_GUARD_TEMPERATURE', this.isWidgetEnabled(WidgetType.TurfGuardTemperature)),
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'myTurf.png',
                widgets: [
                    new EnableWidget(WidgetType.MyTurfOrders, 'WIZARD_WIDGETS.MY_TURF_PARTS_ORDERS', this.isWidgetEnabled(WidgetType.MyTurfOrders)),
                    new EnableWidget(WidgetType.MyTurfMaintenance, 'WIZARD_WIDGETS.MY_TURF_MAINTENANCE', this.isWidgetEnabled(WidgetType.MyTurfMaintenance))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'assetTracking.png',
                widgets: [
                    new EnableWidget(WidgetType.EquipmentTracking, 'WIZARD_WIDGETS.TRACKING', this.isWidgetEnabled(WidgetType.EquipmentTracking))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'ezLocator.png',
                widgets: [
                    new EnableWidget(WidgetType.EzLocator, 'WIZARD_WIDGETS.EZ_LOCATOR', this.isWidgetEnabled(WidgetType.EzLocator))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'playbooks.png',
                widgets: [
                    new EnableWidget(WidgetType.Playbooks, 'WIZARD_WIDGETS.PLAYBOOKS', this.isWidgetEnabled(WidgetType.Playbooks))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'taskTracker.png',
                widgets: [
                    new EnableWidget(WidgetType.TaskTrackerDailyMeasurements, 'WIZARD_WIDGETS.TASK_TRACKER_DAILY_MEASUREMENTS', this.isWidgetEnabled(WidgetType.TaskTrackerDailyMeasurements)),
                    new EnableWidget(WidgetType.TaskTrackerLaborSummary, 'WIZARD_WIDGETS.TASK_TRACKER_LABOR_SUMMARY', this.isWidgetEnabled(WidgetType.TaskTrackerLaborSummary)),
                    new EnableWidget(WidgetType.TaskTrackerSafety, 'WIZARD_WIDGETS.TASK_TRACKER_SAFETY_AND_TRAINING', this.isWidgetEnabled(WidgetType.TaskTrackerSafety))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'pogo-logo.png',
                widgets: [
                    new EnableWidget(WidgetType.Pogo, 'WIZARD_WIDGETS.POGO', this.isWidgetEnabled(WidgetType.Pogo))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'perryWeather.png',
                widgets: [
                    new EnableWidget(WidgetType.PerryWeather, 'WIZARD_WIDGETS.PERRY_WEATHER', this.isWidgetEnabled(WidgetType.PerryWeather))
                ]
            }));

        this.widgetGroups.push(new EnableWidgetGroup(
            {
                image: 'soil-scout-logo-full.png',
                widgets: [
                    new EnableWidget(WidgetType.SoilScoutMoisture, 'WIZARD_WIDGETS.SOIL_SCOUT_MOISTURE', this.isWidgetEnabled(WidgetType.SoilScoutMoisture)),
                    new EnableWidget(WidgetType.SoilScoutSalinity, 'WIZARD_WIDGETS.SOIL_SCOUT_SALINITY', this.isWidgetEnabled(WidgetType.SoilScoutSalinity)),
                    new EnableWidget(WidgetType.SoilScoutTemperature, 'WIZARD_WIDGETS.SOIL_SCOUT_TEMPERATURE', this.isWidgetEnabled(WidgetType.SoilScoutTemperature)),
                    new EnableWidget(WidgetType.SoilScoutMap, 'WIZARD_WIDGETS.SOIL_SCOUT_MAP', this.isWidgetEnabled(WidgetType.SoilScoutMap))
                ]
            }));

    }

    private isWidgetEnabled(type: WidgetType) {
        const activeWidget = this.selectedWidgets.find(w => w.type === type);
        return !!activeWidget;
    }
}
