import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'toro-step-group',
    templateUrl: './step-group.component.html',
    styleUrls: ['./step-group.component.less']
})
export class StepGroupComponent {
    @HostBinding('class') class = 'toro-step-group';

    @Input() stepNumber: number;

    constructor() { }

}
