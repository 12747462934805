import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { UserFormatService } from '../../../../../common/services/user-format.service';

export enum DatumRowEditorType {
    None,
    TextBox,
}

export enum DatumTextJustify {
    Left = 'flex-start',
    Center = 'center',
    Right = 'flex-end'
}

@UntilDestroy()
@Component({
    selector: 'toro-decision-tree-datum-row',
    templateUrl: './decision-tree-datum-row.component.html',
    styleUrls: ['./decision-tree-datum-row.component.less']
})
export class DecisionTreeDatumRowComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') class = 'toro-decision-tree-datum-row';

    DatumRowEditorType = DatumRowEditorType;

    @Output() valueChange = new EventEmitter<number>();

    @Input() containerClass: string = null;
    @Input() borderColor = 'lightgray'
    @Input() iconColor = 'white';
    @Input() iconBackgroundColor = 'white';
    @Input() textColor = 'black';
    @Input() textBackgroundColor = 'white';
    @Input() text = '';
    @Input() icon: string;
    @Input() iconSize: string;
    @Input() textJustify = DatumTextJustify.Center;
    @Input() editorType = DatumRowEditorType.None;
    @Input() minValue = 0;
    @Input() maxValue = 0;
    @Input() valueUnits: string = null;
    @Input() decimalPlaces = 0;
    @Input() step = 1;
    @Input() inputId: string = null;

    private _value: number;
    @Input() set value(val: number) {
        // Stop circular settings
        if (val == this._value) { return; }

        this._value = val;
        this.setDisplayValue();

        // Guard against emitting valueChange event during component initialization.
        if (!this.componentIsInitialized) { return; }

        // Since we are potentially using a spinner, we don't want to go hame on the event emissions.
        clearTimeout(this.valueUpdateTimerRef);
        this.valueUpdateTimerRef = setTimeout(() => {
            this.valueChange.emit(this.value);
        }, 500)
    }

    get value(): number {
        return this._value;
    }

    displayValue: string;
    locale = 'en-US'

    private valueUpdateTimerRef: NodeJS.Timeout;
    private componentIsInitialized = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDisplayValue());

        this.locale = this.userFormatService.locale;

        setTimeout(() => this.componentIsInitialized = true, 100);
    }

    ngAfterViewInit() {
        // Attach keyup event handler to our p-inputnumber control so we can react to keyboard events. Only attach if we've set an id for the p-inputNumber control.
        if (this.inputId == null) { return; }
        const pInputControls = document.getElementById(this.inputId).getElementsByClassName('ui-inputnumber-input');
        pInputControls[0].addEventListener('keyup', updateValueAfterKeyUp(this))
    }

    ngOnDestroy() {
        if (this.valueUpdateTimerRef != null) { clearTimeout(this.valueUpdateTimerRef); }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValue() {
        if (this.value == null) { return; }

        this.locale = this.userFormatService.locale;
        this.displayValue = this.userFormatService.toUserNumber(this.value, this.decimalPlaces, this.decimalPlaces)
    }
}

function updateValueAfterKeyUp(thisRef) {
    return () => {
        const controls = document.getElementById(thisRef.inputId).getElementsByClassName('ui-inputnumber-input');
        thisRef.value = +(<HTMLInputElement>controls[0]).value.replace(/[^0-9.]/g, '');
    }
}
