import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from '../../common/services/analytics.service';
import { DashMessageService } from '../../common/services/dash-message.service';
import { DashUserManagerService } from '../../api/dash-user/dash-user-manager.service';
import { environment } from '../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { SendEmailModel } from '../../api/dash-user/models/send-email.model';
import { ToroAnalyticsEnums } from '../../common/enumerations/analytics.enums';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@Component({
    selector: 'toro-user-feedback-dlg',
    templateUrl: './user-feedback-dlg.component.html',
    styleUrls: ['./user-feedback-dlg.component.less']
})
export class UserFeedbackDlgComponent implements AfterViewInit {
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();

    messageContent: string;
    uploadedFiles: any[] = [];
    isBusy = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private dashMessageService: DashMessageService,
                private dashUserManager: DashUserManagerService,
    ) { }

    ngAfterViewInit() {
        // Set focus to edit portion of editor (instead of toolbar).
        setTimeout(() => {
            (<HTMLElement>document.getElementsByClassName('ql-editor')[0]).focus();
        }, 100);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCancel() {
        this.cancel.emit();
        this.analyticsService.event(AnalyticsEvent.FeedbackDialogCancelled, AnalyticsCategory.Feedback);
    }

    onSave() {
        this.isBusy = true;
        const sem = new SendEmailModel(environment.feedbackRecipients, environment.feedbackSubject, this.messageContent);

        this.dashUserManager.sendFeedback(sem)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe(() => {
                this.analyticsService.event(AnalyticsEvent.FeedbackSubmitted, AnalyticsCategory.Feedback);
                this.save.emit();
            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

}
