<div id="spec-grid-card-container"
     class="toro-component-shield-host"
     [ngStyle]="{'width': (width != null ? width + 'px' : '100%'), 'height': (height != null ? height + 'px' : '100%')}"
     [style.background-color]="backGroundColor"
     [ngClass]="{'selected': selectedItem != null && isSelected}"
>
    {{ item.dataValue }}

    <toro-component-shield (shieldClick)="onClick()"></toro-component-shield>
</div>
