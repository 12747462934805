import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'toro-step-action',
    templateUrl: './step-action.component.html',
    styleUrls: ['./step-action.component.less']
})
export class StepActionComponent {
    @HostBinding('class') class = 'toro-step-action'

    constructor() { }
}
