export class SpecFsProfile {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.deviceProfiles != null) this.deviceProfiles = json.deviceProfiles.map(d => new SpecDeviceProfile(d));
        }
    }

    name: string;
    deviceProfiles: SpecDeviceProfile[];
}

export class SpecDeviceProfile {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.dataTypeProfiles != null) this.dataTypeProfiles = json.dataTypeProfiles.map(d => new SpecDataTypeProfiles(d));
        }
    }

    deviceType: string
    dataTypeProfiles: SpecDataTypeProfiles[];
}

export class SpecDataTypeProfiles {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.paletteDefinition != null) this.paletteDefinition = json.paletteDefinition.map(d => new SpecPaletteDefinitionItem(d));
        }
    }

    dataType: string;
    paletteDefinition: SpecPaletteDefinitionItem[];
}

export class SpecPaletteDefinitionItem {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    lowValue: number;
    highValue: number;
    color: string;
}
