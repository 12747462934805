<div id="pogo-green-viewer-container">

    <div id="pgv-header">
        <ng-container [ngTemplateOutlet]="zonePropertyLegend"></ng-container>
    </div>

    <div #greenContainer id="pgv-image-container" class="toro-component-shield-host">

        <div *ngIf="containerReady" id="pgv-image-wrapper">
            <img #greenImage
                 id="green-image"
                 *ngIf="!imageUnavailable && imageUrl != null"
                 [ngClass]="{'fade-in': showGreenImage}"
                 [src]="imageUrl"
                 [alt]="selectedZone?.zone_name ?? ''"
                 [ngStyle]="{'max-width': maxImageWidth, 'max-height': maxImageHeight }"
                 (load)="onGreenImageLoad()"
            >

            <div *ngIf="imageUnavailable">{{ 'STRINGS.IMAGE_UNAVAILABLE' | translate }}</div>
            <fa-icon *ngIf="hasApproachMarker" id="approach-marker" icon="square" [ngStyle]="{'top': approachMarkerLocation.top, 'left': approachMarkerLocation.left}"></fa-icon>
            <span *ngIf="hasPinMarker" id="pin-marker" [class]="'mdi mdi-golf'" [ngStyle]="{'top': pinMarkerLocation.top, 'left': pinMarkerLocation.left}"></span>

            <fa-icon *ngIf="useMinMaxIcon && hasMinLocation" icon="bullseye" style="font-size: 17px; color: #BF7069; position: absolute; z-index: 2;" [ngStyle]="{'top': minMoistureLocation.topIndicator, 'left': minMoistureLocation.leftIndicator}"></fa-icon>
            <!-- Unadjusted position to gauge alignment offset for icon  -->
            <!--            <fa-icon *ngIf="useMinMaxIcon && hasMaxLocation" icon="bullseye" style="font-size: 17px; color: green; background-color: white; position: absolute; z-index: 2;" [ngStyle]="{'top': maxMoistureLocation.top, 'left': maxMoistureLocation.left}"></fa-icon>-->
            <fa-icon *ngIf="useMinMaxIcon && hasMaxLocation" icon="bullseye" style="font-size: 17px; color: #7CD2E5; position: absolute; z-index: 2;" [ngStyle]="{'top': maxMoistureLocation.topIndicator, 'left': maxMoistureLocation.leftIndicator}"></fa-icon>

            <div *ngIf="!useMinMaxIcon && hasMinLocation" class="zone-min-max min" [ngStyle]="{'top': minMoistureLocation.top, 'left': minMoistureLocation.left}">{{ zoneMoistureMin }}</div>
            <div *ngIf="!useMinMaxIcon && hasMaxLocation" class="zone-min-max max" [ngStyle]="{'top': maxMoistureLocation.top, 'left': maxMoistureLocation.left}">{{ zoneMoistureMax }}</div>
            <!--            <fa-icon *ngIf="hasApproachMarker" icon="circle" style="font-size: 6px; color: white; position: absolute;" [ngStyle]="{'top': holeMarkerTop, 'left': holeMarkerLeft}"></fa-icon>-->
        </div>

        <div class="toro-component-shield" (click)="onViewerClick(null)"></div>
    </div>

    <div id="pgv-footer">
        <ng-container [ngTemplateOutlet]="zoneSummary"></ng-container>
    </div>

</div>

<ng-template #zonePropertyLegend>
    <div class="toro-flex-childt" id="zone-property-legend-container">
        <div id="zone-property-info" class="toro-component-shield-host">
            <div *ngIf="!isMiniModal" id="zone-name" class="toro-ellipses-text prevent-select">{{ selectedZone?.zone_name || '--' }}</div>
            <div *ngIf="!isMiniModal" class="zone-legend-samples"><div>{{ selectedZoneTimestamp ?? '' }}</div></div>

            <div id="property-name" class="prevent-select" [ngClass]="{'is-mini-modal': isMiniModal}">{{ property?.property_name }}</div>

            <div *ngIf="isMiniModal" id="pgv-zone-selector">
                <p-dropdown
                        [appendTo]="'body'"
                        styleClass="pgv-select"
                        [placeholder]="'STRINGS.SELECT_ZONE' | translate | titlecase"
                        [options]="zoneSelectList"
                        [(ngModel)]="selectedZone"
                ></p-dropdown>
            </div>
            <div *ngIf="isMiniModal" class="zone-legend-samples mini"><div>{{ selectedZoneTimestamp ?? '' }}</div></div>

            <div *ngIf="!isMiniModal" class="toro-component-shield" (click)="onViewerClick(null)"></div>
        </div>
    </div>
</ng-template>

<ng-template #zoneSummary>
    <div id="zone-legend-container" *ngIf="useMinMaxIcon" style="padding: 3px 0 5px 0; display: flex; align-items: center; justify-content: center; font-size: 12px;">
        <div style="margin-right: 10px;">
            Moisture:
        </div>
        <div style="margin-right: 15px; min-width: 80px; min-height: 16px;">
            <fa-icon icon="bullseye" style="font-size: 13px; color: #BF7069;"></fa-icon>
            Min:
            {{ zoneMoistureMin }}
        </div>
        <div style="min-width: 80px; min-height: 16px;">
            <fa-icon icon="bullseye" style="font-size: 13px; color: #7CD2E5;"></fa-icon>
            Max: {{ zoneMoistureMax }}
        </div>
    </div>
    <div id="zone-summary-container">
        <div class="zone-summary-datum toro-component-shield-host">
            <div class="zone-summary-lbl prevent-select">{{ 'STRINGS.MOISTURE' | translate }}</div>
            <div class="zone-summary-val prevent-select" style="position: relative;">
                <span class="zone-summary-icon" [class]="moistureSummaryIcon"></span>
                {{ zoneMoistureAvg }}
            </div>
            <div class="toro-component-shield" (click)="onViewerClick(PogoTopNav.Moisture)"></div>
        </div>
        <div class="zone-summary-datum toro-component-shield-host">
            <div class="zone-summary-lbl prevent-select">{{ 'STRINGS.DISTRIBUTION_UNIFORMITY_ABBR' | translate }}</div>
            <div class="zone-summary-val prevent-select">{{ zoneMoistureDu }}</div>
            <div class="toro-component-shield" (click)="onViewerClick(PogoTopNav.DistributionUniformity)"></div>
        </div>
        <div class="zone-summary-datum toro-component-shield-host">
            <div class="zone-summary-lbl prevent-select">{{ 'STRINGS.TURF_PERFORMANCE_ABBR' | translate }}</div>
            <div class="zone-summary-val prevent-select">{{ selectedZone?.conditions?.moisture_tpi }}</div>
            <div class="toro-component-shield" (click)="onViewerClick(PogoTopNav.TurfPerformance)"></div>
        </div>
    </div>
</ng-template>


