import { KittyHawkLatLng } from './kitty-hawk-lat-lng.model';
import { KittyHawkLocationBounds } from './kitty-hawk-location-bounds.model';

export class KittyHawkFavorite {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: string;
    name: string;
    center: KittyHawkLatLng;
    zoom: number;
    bounds: KittyHawkLocationBounds;

}
