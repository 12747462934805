import WeatherAverageType = ToroEnums.WeatherAverageType;
import WeatherAverageSources = ToroEnums.WeatherAverageSources;

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { filter } from 'rxjs/operators';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherAveragesLegendItem } from '../models/weather-averages-legend-item.model';
import { WeatherSourceData } from '../models/weather-source-data.model';
import { WeatherSourceForecast } from '../models/weather-source-forecast.model';

@UntilDestroy()
@Component({
    selector: 'toro-weather-averages-chart',
    templateUrl: './weather-averages-chart.component.html',
    styleUrls: ['./weather-averages-chart.component.less']
})
export class WeatherAveragesChartComponent implements OnInit {
    @HostBinding('class') class = 'toro-weather-graph-chart';
    @ViewChild('wgChartContainer') wgChartContainer: ElementRef;

    @Output() chartLoaded = new EventEmitter();
    @Output() chartClick = new EventEmitter<WeatherAveragesLegendItem[]>();

    private readonly chartWidth = 850;
    private readonly chartHeight = 500;
    private readonly seriesColors: string[] = ['grey', 'black', '#7cb5ec', 'goldenrod', 'green', 'orange']

    private noChartDataTimerRef: any;

    @Output() clearChartChange = new EventEmitter<boolean>();

    private _clearChart = false;
    @Input() set clearChart(value: boolean) {
        this._clearChart = value;
        if (this._clearChart) {
            clearTimeout(this.noChartDataTimerRef);
            this.setNoChartData();
            this.clearChart = false;
            setTimeout(() => this.clearChartChange.emit(false));
        }
    }

    get clearChart(): boolean {
        return this._clearChart;
    }

    private _forecastWeather: WeatherSourceForecast[] = [];
    @Input() set forecastWeather(value: WeatherSourceForecast[]) {
        if (value == null || value.length < 1) {
            this.noChartDataTimerRef = setTimeout(() => {
                this.setNoChartData();
            }, 500)
            return;
        }

        this.showNoDataNotice = false;
        clearTimeout(this.noChartDataTimerRef);

        this._forecastWeather = this.addAverageForecast(value);
        this.updateChart();
    }

    get forecastWeather(): WeatherSourceForecast[] {
        return this._forecastWeather;
    }

    private _weatherType: WeatherAverageType;
    @Input() set weatherType(value: WeatherAverageType) {
        this._weatherType = value;

        if (this.forecastWeather == null || this.forecastWeather.length < 1) return;
        this.updateChart();
    }

    get weatherType(): WeatherAverageType {
        return this._weatherType;
    }

    private _isModal = false;
    @Input() set isModal(value: boolean) {
        this._isModal = value;
    }

    get isModal(): boolean {
        return this._isModal;
    }

    @Input() isBusy = false;

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;
    dateTooltipString: string;
    valueTooltipString: string;
    unitsTooltipString: string;
    yExtentMin: number;
    yExtentMax: number;
    xAxisLabels: string[];
    resizeTimerRef: any;
    isResizingChart = false;
    showNoDataNotice = false;

    private chartSetupTimer: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private deviceManager: DeviceManagerService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        this.translateService.instant('STRINGS.HELLO');
    }

    ngOnInit(): void {
        this.dateTooltipString = this.translateService.instant('STRINGS.DATE').toTitleCase();
        this.valueTooltipString = this.translateService.instant('STRINGS.VALUE').toTitleCase();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateChart());

        this.deviceManager.windowResize
            .pipe(
                untilDestroyed(this),
                filter(() => this.isModal)
            )
            .subscribe(() => {
                if ((window.innerWidth > this.chartWidth + 75 && window.innerHeight > this.chartHeight)) return;

                if (this.chartOptions) this.chartOptions = null;

                this.isResizingChart = true;
                clearTimeout(this.resizeTimerRef);
                this.resizeTimerRef = setTimeout(() => {
                    this.chart = null;
                    this.updateChart();
                    this.isResizingChart = false;
                }, 100);
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setSizeOfChartContainer() {
        if (!this.wgChartContainer) { return; }
        this.chartContainerWidth = this.wgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.wgChartContainer.nativeElement.offsetHeight - 2;
    }

    private updateChart() {
        this.setXAxisLabels();
        this.getSeriesData()

        if (!this.chart) {
            clearTimeout(this.chartSetupTimer);
            this.chartSetupTimer = setTimeout(() => this.setupChart(), !this.isModal ? 100 : 250);
        } else {
            this.chart.update({
                chart: {
                    type: 'spline',
                },
                series: this.getSeriesData(),
                xAxis: {
                    categories: this.xAxisLabels,
                },
                yAxis: {
                    min: this.yExtentMin ?? undefined,
                    max: this.yExtentMax ?? undefined,
                }
            }, true);
        }
    }

    private setupChart() {
        const self = this;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) {self.chart = event.target; },
                    click() { self.chartClick.emit(self.getLegendItems()); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [6, 2, 3, 2],
                marginTop: this.isModal ? 10 : undefined
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                tickLength: 7,
                tickWidth: 1,
                tickmarkPlacement: "on",
                type: "categories",
                categories: self.xAxisLabels,
                labels: {
                    formatter() { return '<span class="weather-graph-x-axis-label">' + this.value + '</span>'; },
                },
                gridLineWidth: 1,
            },
            yAxis: {
                tickAmount: 4,
                min: self.yExtentMin ?? undefined,
                max: self.yExtentMax ?? undefined,
                allowDecimals: false,
                title: { enabled: false },
                labels: {
                    formatter() { return '<span class="weather-graph-y-axis-label">' + self.userFormatService.toUserNumber(this.value, 0, 4) + '</span>'; }
                }
            },
            tooltip: {
                enabled: true,
                followPointer: true,
                formatter() {
                    return '<sapn>' + this.series.name + '</sapn><br><span class="weather-graph-data-tooltip">' + self.dateTooltipString + ': ' + this.x + '</span><br>' +
                        '<span class="weather-graph-data-tooltip">' + self.valueTooltipString + ': ' + self.userFormatService.toUserNumber(this.y) + ' <span>' + self.unitsTooltipString + '</span></span>';
                },
            },
            plotOptions: {
                spline: {
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                    enableMouseTracking: true,
                },
                series: {
                    states: { inactive: { enabled: true } },
                    events: {
                        click() { self.chartClick.emit(self.getLegendItems()); }
                    },
                    marker: {
                        enabled: false
                    }
                },
            },
            series: self.getSeriesData()
        };
    }

    private getSeriesData(): any[] {
        const data: any[] = [];
        let combinedData: number[] = [];

        this.forecastWeather.forEach(s => {
            const seriesData = this.getSeriesValues(s.data);

            data.push({
                name: this.getSeriesName(s.source),
                marker: {
                    enabled: this.isModal,
                    radius: !this.isModal ? 3 : 4
                },
                color: this.seriesColors[s.source],
                dashStyle: s.source === WeatherAverageSources.Average ? 'ShortDot' : 'Solid',
                data: seriesData
            })
            combinedData = combinedData.concat(seriesData);
        })

        this.yExtentMin = Math.min(...combinedData);
        if (this.yExtentMin !== 0) this.yExtentMin -= 1;

        this.yExtentMax = Math.max(...combinedData) + 1;

        return data;
    }

    private getSeriesName(source: WeatherAverageSources) {
        switch (source) {
            case WeatherAverageSources.ToroDtnWeather:
                return 'Toro Weather';
            case WeatherAverageSources.PerryWeather:
                return 'Perry Weather';
            case WeatherAverageSources.Lynx:
                return 'Lynx Weather';
            case WeatherAverageSources.Average:
                return 'Average';
            default:
                this.translateService.instant('STRINGS.UNKNOWN');
        }
    }

    private getSeriesValues(data: WeatherSourceData[]): number[] {
        let dataSeries: number[];

        switch (this.weatherType) {
            case WeatherAverageType.Temperature:
                dataSeries = data.map(d => d.tempInFahrenheit != null ? +this.userFormatService.temperature(d.tempInFahrenheit) : null);
                this.unitsTooltipString = this.userFormatService.temperatureUnits;
                break;
            case WeatherAverageType.Et:
                dataSeries = data.map(d => d.et != null ? Number(this.userFormatService.evapotranspiration(d.et, true)) : null);
                this.unitsTooltipString = this.userFormatService.evapotranspirationUnits;
                break;
            case WeatherAverageType.Humidity:
                dataSeries = data.map(d => d.humidity != null ? this.userFormatService.humidity(d.humidity) : null);
                this.unitsTooltipString = '%';
                break;
            case WeatherAverageType.Precipitation:
                dataSeries = data.map(d => d.precipInInches != null ? +this.userFormatService.rainfall(d.precipInInches) : null);
                this.unitsTooltipString = this.userFormatService.precipitationUnits;
                break;
            default:
                return [];
        }

        return dataSeries;
    }

    private setXAxisLabels() {
        const today = moment();
        const nextSeventDays = [];

        for (let i = 0; i < 7; i++) {
            const date = today.clone().add(i, 'days');
            nextSeventDays.push(date.format('M/D'));
        }

        this.xAxisLabels = nextSeventDays;
    }

    private getLegendItems(): WeatherAveragesLegendItem[] {
        const legendItems: WeatherAveragesLegendItem[] = [];

        this.forecastWeather.forEach(s => {
            if (s.data.length < 1) return;

            switch (this.weatherType) {
                case WeatherAverageType.Temperature:
                    if (s.data[0].tempInFahrenheit != null) legendItems.push(new WeatherAveragesLegendItem(s.source, this.seriesColors[s.source]))
                    break;
                case WeatherAverageType.Et:
                    if (s.data[0].et != null) legendItems.push(new WeatherAveragesLegendItem(s.source, this.seriesColors[s.source]))
                    break;
                case WeatherAverageType.Humidity:
                    if (s.data[0].humidity != null) legendItems.push(new WeatherAveragesLegendItem(s.source, this.seriesColors[s.source]))
                    break;
                case WeatherAverageType.Precipitation:
                    if (s.data[0].precipInInches != null) legendItems.push(new WeatherAveragesLegendItem(s.source, this.seriesColors[s.source]))
                    break;
            }
        })

        return legendItems;
    }

    private setNoChartData() {
        this.chart = null;
        this.chartOptions = null;
        this.showNoDataNotice = true;
    }

    private addAverageForecast(forecasts: WeatherSourceForecast[]): WeatherSourceForecast[] {
        const VALUES_COUNT = 7;
        const avgDataSeries: WeatherSourceData[] = [];
        const sumDataSeries: WeatherSourceData[] = [];
        let tempCount = 0;
        let precipCount = 0;
        let humidityCount = 0;
        let etCount = 0;

        if (forecasts.length <= 1) return forecasts;

        for (let i = 0; i < forecasts.length; i++) {
            if (forecasts[i].data[0].tempInFahrenheit != null) tempCount++;
            if (forecasts[i].data[0].precipInInches != null) precipCount++;
            if (forecasts[i].data[0].humidity != null) humidityCount++;
            if (forecasts[i].data[0].et != null) etCount++;

            for (let p = 0; p < VALUES_COUNT; p++) {
                if (i == 0) {
                    sumDataSeries.push(new WeatherSourceData());
                    avgDataSeries.push(new WeatherSourceData());
                }

                if (forecasts[i].data[p].tempInFahrenheit != null) sumDataSeries[p].tempInFahrenheit += forecasts[i].data[p].tempInFahrenheit;
                if (forecasts[i].data[p].precipInInches != null) sumDataSeries[p].precipInInches += forecasts[i].data[p].precipInInches;
                if (forecasts[i].data[p].humidity != null) sumDataSeries[p].humidity += forecasts[i].data[p].humidity;
                if (forecasts[i].data[p].et != null) sumDataSeries[p].et += forecasts[i].data[p].et;

                if (i === forecasts.length - 1) {
                    if (sumDataSeries[p].tempInFahrenheit != null && tempCount > 1) avgDataSeries[p].tempInFahrenheit = sumDataSeries[p].tempInFahrenheit / tempCount
                    if (sumDataSeries[p].precipInInches != null && precipCount > 1) avgDataSeries[p].precipInInches = sumDataSeries[p].precipInInches / precipCount
                    if (sumDataSeries[p].humidity != null && humidityCount > 1) avgDataSeries[p].humidity = sumDataSeries[p].humidity / humidityCount
                    if (sumDataSeries[p].et != null && etCount > 1) avgDataSeries[p].et = sumDataSeries[p].et / etCount
                }
            }
        }

        const ForecastsWithAverage = forecasts.slice();
        ForecastsWithAverage.push(new WeatherSourceForecast({
            source: WeatherAverageSources.Average,
            data: avgDataSeries
        }));

        return ForecastsWithAverage;
    }
}
