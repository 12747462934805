import { Observable, Subject, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { AnalyticsService } from '../../common/services/analytics.service';
import { AuthApiService } from './auth-api.service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DashApiLoginInfo } from './models/dash-api-login-info.model';
import { DashApiRefreshTokenLoginInfo } from './models/dash-api-refresh-token-login-info.model';
import { DashAuthenticatedUser } from './models/dash-authenticated-user.model';
import { DashUserInfo } from '../dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../dash-user/models/dash-user-preferences.model';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { LoginStateChange } from './models/login-state-change';
import { MyTurfCodeLoginInfo } from './models/my-turf-code-login-info.model';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TokenKeyModel } from './models/token-key.model';
import { ToroAnalyticsEnums } from '../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import NsnError = ToroEnums.NsnError;

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AuthManagerService {
    // Subjects
    dashLoginStateChange = new Subject<LoginStateChange>();

    private readonly _nsnDashAccessError = 'NSN User is not authorized to access IntelliDash.';
    private readonly _nsnLoginError = 'Unable to retrieve NSN data.';

    toroSsoUser: User;

    private userManager: UserManager;
    private _dashAuthenticatedUser: DashAuthenticatedUser;
    private _isDemoMode = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private authApiService: AuthApiService,
                private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private localCacheService: LocalCacheService,
                private router: Router,
                private translateService: TranslateService,
    ) {

        // Subscribe to DashUser Preferences changes. The DashAuthenticatedUser is cached in this service.
        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe((dashUserPrefs: DashUserPreferences) => {
                this.updateAndStoreDashUserPrefs(dashUserPrefs);
                this.broadcastService.userPreferencesChange.next(null);
            });
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    init() {
        if (environment.useMockNsnData) {
            this.dashApiLogin('');
            return;
        }

        this.userManager = new UserManager(AuthManagerService.userManagerSettings);

        // Retrieve the Authorization Endpoint from the SSO server and set it along with the
        // end_session_endpoint in the MetData object. The Oidc-Client requires the authorization_endpoint
        // to be set when the end_session_endpoint is set.
        this.userManager.metadataService.getAuthorizationEndpoint()
            .then(authEndpoint => {
                this.userManager.settings.metadata = {
                    authorization_endpoint: authEndpoint,
                    end_session_endpoint: `${environment.toroSsoServerUrl}/idp/startSLO.ping`
                };
            });

        this.userManager.getUser()
            .then(ssoUser => {
                this.toroSsoUser = ssoUser;

                // If we don't have a valid ssoUser, start sso login process.
                if (ssoUser == null) {
                    // Clear any cached DashApi user if initiating a new SSO Login.
                    this.localCacheService.dashAuthenticatedUser = null;

                    this.startOauthSignIn();
                    return;
                }

                // Login to Dash API Server
                this.dashApiLogin(ssoUser.access_token);
            })
            .catch(() => {
                this.toroSsoUser = null;
            });

        this.userManager.events.addUserLoaded(user => {
            this.toroSsoUser = user;
        });

        this.userManager.events.addUserUnloaded(() => {
            this.toroSsoUser = null;

            // Delete cached DashApi user when SSO user is logged out.
            this.localCacheService.dashAuthenticatedUser = null;
        });
    }

    public get dashAuthenticatedUser(): DashAuthenticatedUser {
        return this._dashAuthenticatedUser;
    }

    public set dashAuthenticatedUser(value: DashAuthenticatedUser) {
        this._dashAuthenticatedUser = value;
    }

    public get isDashAuthenticatedUserLoggedIn(): boolean {
        return this.dashAuthenticatedUser != null;
    }

    public get isSsoUserLoggedIn(): boolean {
        if (environment.useMockNsnData || this.isDemoMode) {
            return true;
        }

        return this.toroSsoUser != null && this.toroSsoUser.expired === false;
    }

    public startOauthSignIn() {
        this.userManager.signinRedirect();
    }

    public startOauthSignOut() {
        if (this.isDemoMode) {
            window.location.href = document.location.origin;
            return;
        }

        this.userManager.signoutRedirect();

        if (this.dashAuthenticatedUser != null) {
            this.analyticsService.event(AnalyticsEvent.UserLogout, AnalyticsCategory.User);
        }
    }

    dashApiLogInWithRefreshToken(): Observable<any> {
        if (!this.localCacheService.dashApiRefreshToken) {
            return throwError(new HttpErrorResponse({ status: 400, statusText: 'no refresh token found' }));
        }

        return this.authApiService.dashApiLoginWithRefreshToken(new DashApiRefreshTokenLoginInfo(this.localCacheService.dashApiRefreshToken))
            .pipe(
                tap((response: DashAuthenticatedUser) => {
                    this.dashAuthenticatedUser = new DashAuthenticatedUser(response);
                    this.localCacheService.dashApiAccessToken = response.token;
                    this.localCacheService.dashApiRefreshToken = response.refreshToken;
                })
            );
    }

    dashApiLogOut() {
        this.localCacheService.dashAuthenticatedUser = null;
        this.localCacheService.dashApiAccessToken = null;
        this.localCacheService.dashApiRefreshToken = null;
        this.localCacheService.gsaApiAccessToken = null;
        this.localCacheService.assetTrackingApiAccessToken = null;
        this.localCacheService.assetTrackingApiRefreshToken = null;
        this.localCacheService.soilScoutAccessToken = null;
        this.localCacheService.soilScoutRefreshToken = null;

        this.startOauthSignOut();
    }

    loginWithMyTurfCode(myTurfAuthCode: string): Observable<any> {
        return this.authApiService.loginWithMyTurfCode(new MyTurfCodeLoginInfo(myTurfAuthCode, this.localCacheService.dashApiRefreshToken))
            .pipe(tap(() => this.broadcastService.myTurfUserSignedIn.next(null)));
    }

    setDashAuthenticatedUser(dashAuthenticatedUser: DashAuthenticatedUser) {
        this.dashAuthenticatedUser = dashAuthenticatedUser;
        this.localCacheService.dashApiAccessToken = this.dashAuthenticatedUser.token;
        this.localCacheService.gsaApiAccessToken = this.dashAuthenticatedUser.gsaToken;
        this.getDashUserInfo();
    }

    getTrackingToken(): Observable<TokenKeyModel> {
        return this.authApiService.getTrackingToken();
    }

    get nsnDashAccessError(): string {
        return this._nsnDashAccessError;
    }

    get nsnLoginError(): string {
        return this._nsnLoginError;
    }

    getNsnErrorNo(error: string): NsnError {
        switch (error) {
            case this._nsnDashAccessError:
                return NsnError.NsnDashAccessError;
            case this._nsnLoginError:
                return NsnError.NsnLoginError;
            default:
                return NsnError.Unknown;
        }
    }

    get isDemoMode(): boolean {
        return this._isDemoMode;
    }

    set isDemoMode(value: boolean) {
        this._isDemoMode = value;
        this.setDashAuthenticatedUser(new DashAuthenticatedUser());
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private static get userManagerSettings(): UserManagerSettings {
        const dashSiteUrl = `${environment.dashSiteUrl}`;
        return {
            client_id: `${environment.clientId}`,
            authority: `${environment.toroSsoServerUrl}`,
            client_secret: `${environment.clientSecret}`,
            scope: 'openid profile offline_access lynxdash',

            loadUserInfo: true,
            redirect_uri: `${dashSiteUrl}/auth.html`,
            response_type: 'token',
            silent_redirect_uri: `${dashSiteUrl}/silent-renew.html`,
            automaticSilentRenew: true,
        };
    }

    // Method to login to IntelliDash API Server.
    private dashApiLogin(ssoToken: string) {
        if (this.localCacheService.dashAuthenticatedUser) {
            this.dashAuthenticatedUser = new DashAuthenticatedUser(this.localCacheService.dashAuthenticatedUser);

            // Handle Demo Mode Page refresh. sid will be '' if we are refreshing the page from demo mode.
            if (this.dashAuthenticatedUser.sid !== '') {
                if (this.dashAuthenticatedUser.lastDashApiUrl === environment.dashApiUrl) {
                    this.getDashUserInfo();

                    // TODO: TEMPORARY?
                    this.localCacheService.gsaApiAccessToken = this.dashAuthenticatedUser.gsaToken;

                    setTimeout(() => this.trackUserLogin());
                    return;
                }
            } else {
                // this will cause a normal dash login flow.
                this.dashAuthenticatedUser = null;
            }
        }

        // ===========================================================================================================
        // If flag is set to true, we will bypass the server side call to 'GetNSNData' and use mock NSN Data instead.
        if (environment.useMockNsnData) {
            ssoToken = 'UseMockData';
        }
        // ===========================================================================================================

        this.authApiService.dashApiLogin(new DashApiLoginInfo(ssoToken))
            .subscribe((response: DashAuthenticatedUser) => {
                this.setDashAuthenticatedUser(new DashAuthenticatedUser(response));
                setTimeout(() => this.trackUserLogin());
            }, error => {
                let reason = '';
                if (error?.error?.Error_1 && error?.error?.Error_1[0]) {
                    reason = error?.error?.Error_1[0];
                    // console.log(`>> Log-in Error: ${reason}`);
                }
                this.dashLoginStateChange.next(new LoginStateChange(false, reason));
            });
    }

    // Method to grab Dash User (specifically the Dash User Preferences (e.g., language)
    private getDashUserInfo() {
        this.dashUserManager.getDashUserInfo()
            .subscribe((dashUserInfo: DashUserInfo) => {
                const userPreferences = dashUserInfo.preferences ? new DashUserPreferences(dashUserInfo.preferences) : new DashUserPreferences();
                this.dashAuthenticatedUser.lastDashApiUrl = environment.dashApiUrl;
                this.updateAndStoreDashUserPrefs(userPreferences);

                if (!environment.isDemoMode) {
                    // TODO: TEMPORARY? Set AlertParams if they don't already exist for user.
                    this.dashUserManager.setDefaultAlertParamsIfRequired();
                }

                this.dashLoginStateChange.next(new LoginStateChange(true));
            }, error => {
                this.dashLoginStateChange.next(new LoginStateChange(false));

                if (error.status === 400) {
                    this.router.navigate(['/ah-snap']);
                }
            });
    }

    private updateAndStoreDashUserPrefs(userPreferences: DashUserPreferences) {
        this.dashAuthenticatedUser.userPreferences = userPreferences;
        this.translateService.use(userPreferences.language);
        this.localCacheService.language = userPreferences.language;
        this.localCacheService.dashAuthenticatedUser = this.dashAuthenticatedUser;
    }

    private trackUserLogin() {
        this.analyticsService.initEvent(AnalyticsEvent.UserLogin, AnalyticsCategory.User, this.dashAuthenticatedUser.email);
        this.analyticsService.initEvent(AnalyticsEvent.SiteLoaded, AnalyticsCategory.Site, this.dashAuthenticatedUser.email, this.dashAuthenticatedUser.siteName);
    }
}
