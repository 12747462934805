import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import WidgetType = ToroEnums.WidgetType;

export class EnableWidget {

    constructor(type: WidgetType, name: string, isEnabled: boolean) {
        this.type = type;
        this.name = name;
        this.isEnabled = isEnabled;
    }

    type: WidgetType;
    name: string;
    isEnabled = false;
}
