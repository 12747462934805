import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { SoilScoutApiService } from './soil-scout-api.service';
import { SoilScoutConfig } from './models/soil-scout-config.model';
import { SoilScoutCsvMeasurement } from './models/soil-scout-csv-measurement.model';
import { SoilScoutDevice } from './models/soil-scout-device.model';
import { SoilScoutGroup } from './models/soil-scout-group.model';
import { SoilScoutLogin } from './models/soil-scout-login.model';
import { SoilScoutLoginResponse } from './models/soil-scout-login-response.model';
import { SoilScoutNotification } from './models/soil-scout-notification.model';
import { SoilScoutRule } from './models/soil-scout-rule.model';
import { SoilScoutSite } from './models/soil-scout-site.model';
import { SoilScoutTokenRefresh } from './models/soil-scout-token-refresh.model';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class SoilScoutManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private localCacheService: LocalCacheService,
                private soilScoutApiService: SoilScoutApiService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getSoilScoutConfig(toroGridsterWidget: ToroGridsterWidget, bypassCache = false): Observable<SoilScoutConfig> {
        // if (environment.isDemoMode) {
        //     // Fetch the demo config for map center point. This occurs if the demo widget is removed and re-added.
        //     return of(this.demoModeMockDataService.soilScoutConfig);
        // }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isSoilScoutWidget')) {
            return of(<SoilScoutConfig>toroGridsterWidget.config);
        }

        const config = new SoilScoutConfig(toroGridsterWidget.config);
        return of(config);
    }

    login(username: string, password: string): Observable<SoilScoutLoginResponse> {
        this.localCacheService.soilScoutAccessToken = null;
        this.localCacheService.soilScoutRefreshToken = null;

        return this.soilScoutApiService.login(new SoilScoutLogin(username, password))
            .pipe(tap((response: SoilScoutLoginResponse) => {
                this.localCacheService.soilScoutAccessToken = response.access;
                this.localCacheService.soilScoutRefreshToken = response.refresh;
            }));
    }

    authTokenRefresh(): Observable<SoilScoutLoginResponse> {
        return this.soilScoutApiService.authTokenRefresh(new SoilScoutTokenRefresh(this.localCacheService.soilScoutRefreshToken))
            .pipe(tap((response: SoilScoutLoginResponse) => {
                this.localCacheService.soilScoutAccessToken = response.access;
                this.localCacheService.soilScoutRefreshToken = response.refresh;
            }));
    }

    // Method to acquire new Soil Scout API Access token. Token will be cached when acquired
    refreshAccessToken(): Observable<string> {
        if (this.localCacheService.soilScoutRefreshToken == null || this.localCacheService.soilScoutRefreshToken == '') {
            return throwError(() => new Error('Not Logged In.'));
        }

        return this.authTokenRefresh()
            .pipe(
                take(1),
                catchError((err: any) => {
                    return throwError(() => err);
                }),
                switchMap((response: SoilScoutLoginResponse) => of(response.access))
            );
    }

    getSites(): Observable<SoilScoutSite[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutSites); }

        return this.soilScoutApiService.getSites();
    }

    getDevices(siteId: number): Observable<SoilScoutDevice[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutDevices); }

        return this.soilScoutApiService.getDevices(siteId);
    }

    getGroups(siteId: number): Observable<SoilScoutGroup[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutGroups); }

        return this.soilScoutApiService.getGroups(siteId);
    }

    getNotifications(siteId: number): Observable<SoilScoutNotification[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutNotifications); }

        return this.soilScoutApiService.getNotifications(siteId);
    }

    getRules(siteId: number): Observable<SoilScoutRule[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutRules); }

        return this.soilScoutApiService.getRules(siteId);
    }

    // getMeasurements(siteId: number, deviceIds: number[], cursor: string = null): Observable<SoilScoutMeasurementResponse> {
    //     // if (environment.isDemoMode) { return of(this.demoModeMockDataService.soilScoutMeasurements); }
    //
    //     return this.soilScoutApiService.getMeasurements(siteId, deviceIds, cursor);
    // }

    getMeasurementsCSV(siteId: number, deviceIds: number[]): Observable<SoilScoutCsvMeasurement[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getSoilScoutMeasurements(deviceIds.toString())); }

        return this.soilScoutApiService.getMeasurementsCSV(siteId, deviceIds);
    }
}
