<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{minWidth: '260px', maxWidth: '95%'}">
    <p-header>{{'STRINGS.DATA_SETTINGS' | translate | titlecase}}</p-header>

    <div id="toro-data-settings-container">

        <div *ngIf="isLoaded" class="tds-content-pane">

            <div class="p-field-checkbox">
                <p-checkbox [(ngModel)]="showStaleDataAlert" binary="true" inputId="showAlert"></p-checkbox>
                <div class="toro-label-with-info">
                    <label class="toro-checkbox-label" for="showAlert">{{'STRINGS.SHOW_STALE_DATA_ALERT' | translate | titlecase}}</label>
                    <sup><fa-icon icon="info-circle" [pTooltip]="'HELP.SHOW_STALE_DATA_ALERT' | translate"></fa-icon></sup>
                </div>
            </div>

            <div class="toro-form-field">
                <div class="toro-label-with-info">
                    <span class="toro-form-field-label">{{'STRINGS.DATA_REFRESH_INTERVAL_MINUTES' | translate}}</span>
                    <sup><fa-icon icon="info-circle" [pTooltip]="'HELP.DATA_REFRESH_INTERVAL' | translate"></fa-icon></sup>
                </div>
                <p-inputNumber [(ngModel)]="dataRefreshInterval" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [step]="1"
                               incrementButtonClass="ui-button-secondaryX" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                               [min]="minRefreshInterval" [max]="maxRefreshInterval" [size]="5" [maxlength]="2"
                ></p-inputNumber>
            </div>

            <div class="toro-form-field">
                <div class="toro-label-with-info">
                    <span class="toro-form-field-label">{{'STRINGS.STALE_DATA_THRESHOLD_MINUTES' | translate}}</span>
                    <sup><fa-icon icon="info-circle" [pTooltip]="'HELP.STALE_DATA_THRESHOLD' | translate"></fa-icon></sup>
                </div>
                <p-inputNumber [(ngModel)]="staleDataThreshold" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [step]="1"
                               incrementButtonClass="ui-button-secondaryX" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                               [min]="minStaleInterval" [max]="maxStaleInterval" [size]="5" [maxlength]="2"
                ></p-inputNumber>
            </div>

        </div>

<!--        <div class="tup-content-pane" id="tup-left-pane">-->
<!--            <div class="toro-form-field">-->
<!--                <span class="toro-form-field-label">{{'STRINGS.LANGUAGE' | translate}}</span>-->
<!--                <p-dropdown [options]="languagesList" [(ngModel)]="dashUserPrefs && dashUserPrefs.language" appendTo="body" ></p-dropdown>-->
<!--            </div>-->
<!--            <div class="toro-form-field">-->
<!--                <span class="toro-form-field-label">{{'STRINGS.UNITS_OF_MEASURE' | translate}}</span>-->
<!--                <p-dropdown [options]="unitsSystemsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.unitsSystem" appendTo="body" ></p-dropdown>-->
<!--            </div>-->
<!--            <div class="toro-form-field">-->
<!--                <span class="toro-form-field-label">{{'STRINGS.GROUP_AND_DECIMAL_SEPARATOR' | translate}}</span>-->
<!--                <p-dropdown [options]="groupAndDecimalFormatsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.groupAndDecimalSeparator" appendTo="body" ></p-dropdown>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="tup-content-pane" id="tup-right-pane">-->
<!--            <div class="toro-form-field">-->
<!--                <span class="toro-form-field-label">{{'STRINGS.DATE_FORMAT' | translate | titlecase}}</span>-->
<!--                <p-dropdown [options]="dateFormatsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.dateFormat" appendTo="body" ></p-dropdown>-->
<!--            </div>-->
<!--            <div class="toro-form-field">-->
<!--                <span class="toro-form-field-label">{{'STRINGS.TEMPERATURE_SCALE' | translate | titlecase}}</span>-->
<!--                <p-dropdown [options]="temperatureScalesList" [(ngModel)]="dashUserPrefs && dashUserPrefs.temperatureUnits" appendTo="body" ></p-dropdown>-->
<!--            </div>-->
<!--        </div>-->

    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSave()"></p-button>
    </p-footer>
</p-dialog>
