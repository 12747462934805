import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoilScoutLogin } from '../models/soil-scout-login.model';

@Component({
    selector: 'toro-soil-scout-login-dlg',
    templateUrl: './soil-scout-login-dlg.component.html',
    styleUrls: ['./soil-scout-login-dlg.component.less']
})
export class SoilScoutLoginDlgComponent implements OnInit {
    @Output() cancelClick = new EventEmitter();
    @Output() loginClick = new EventEmitter<SoilScoutLogin>();

    protected username: string;
    protected password: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit() {}

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onLogin() {
        this.loginClick.emit(new SoilScoutLogin(this.username, this.password));
    }

    onCancelDialog() {
        this.cancelClick.emit();
    }

}
