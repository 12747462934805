import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { YouTubeVideoInfo } from './models/you-tube-video-info.model';

@Component({
    selector: 'toro-video-panel',
    templateUrl: './video-panel.component.html',
    styleUrls: ['./video-panel.component.less']
})
export class VideoPanelComponent implements OnInit {
    @HostBinding('class') class = 'toro-notification-panel';

    @Output() closePanel = new EventEmitter();

    protected showDesktopModal = false;
    protected videoCards: YouTubeVideoInfo[] = [];

    constructor() { }

    ngOnInit(): void {
        this.setupVideoCards();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClosePanel() {
        this.closePanel.emit();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setupVideoCards() {

        this.videoCards.push(new YouTubeVideoInfo({
            videoId: '-nV_CS63KF0',
            title: 'HOW_TO_VIDEOS.VIDEO_1_TITLE',
            description: 'HOW_TO_VIDEOS.VIDEO_1_DESCRIPTION'
        }));

        this.videoCards.push(new YouTubeVideoInfo({
            videoId: 'kz8jOUlM8Wc',
            title: 'Setting up Lynx® for IntelliDash®',
            description: 'How to add Lynx to IntelliDash and set-up Lynx widgets.'
        }));

        this.videoCards.push(new YouTubeVideoInfo({
            videoId: 'F-J7Q_GCt2U',
            title: 'Setting up IntelliDash® Notifications for Lynx®',
            description: 'How to customize Lynx alerts in IntelliDash.'
        }));

        this.videoCards.push(new YouTubeVideoInfo({
            videoId: 'KFYhZZb2AFE',
            title: 'Setting up Alerts for IntelliDash® Notifications',
            description: 'How to customize and set-up email and text alerts in IntelliDash.'
        }));

    }

}
