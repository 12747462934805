/* eslint-disable @typescript-eslint/member-ordering */
import * as moment from 'moment';
import { ApiService, HttpMethod } from '../_common/api.service';
import { AuthenticationModel } from './models/authentication.model';
import { CalAmpAvlEvent } from '../cal-amp/models/cal-amp-avl-event.model';
import { CalAmpDevice } from '../cal-amp/models/cal-amp-device.model';
import { CalAmpMotionLogEvent } from '../cal-amp/models/cal-amp-motion-log-event.model';
import { DashApiLoginInfo } from '../auth/models/dash-api-login-info.model';
import { DashApiRefreshTokenLoginInfo } from '../auth/models/dash-api-refresh-token-login-info.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AssetTrackingApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    apiLogin(apiLoginInfo: DashApiLoginInfo): Observable<AuthenticationModel> {
        return this.apiService.apiRequest<any>(AssetTrackingApiService.loginWithSsoInfoUrl, HttpMethod.Post, apiLoginInfo)
            .pipe(map((response: AuthenticationModel) => new AuthenticationModel(response)));
    }

    apiLoginWithRefreshToken(apiRefreshTokenLoginInfo: DashApiRefreshTokenLoginInfo): Observable<AuthenticationModel> {
        return this.apiService.apiRequest<any>(AssetTrackingApiService.loginWithSsoInfoUrl, HttpMethod.Post, apiRefreshTokenLoginInfo)
            .pipe(map((response: AuthenticationModel) => new AuthenticationModel(response)));
    }

    getDeviceList(accountId: number, bypassCache: boolean): Observable<CalAmpDevice[]> {
        return this.apiService.apiRequest<any>(AssetTrackingApiService.getDeviceListUrl(accountId, bypassCache), HttpMethod.Get)
            .pipe(map((response: CalAmpDevice[]) => response.map((device: CalAmpDevice) => new CalAmpDevice(device))));
    }

    getLatestAvlEventsForDevice(deviceEsn: number): Observable<CalAmpAvlEvent[]> {
        // NOTE: All time are converted to the user's Site time on the server. No timezone information should be passed in.
        // For initial POC, all times are converted to Central Time for Bear Path.

        return this.apiService.apiRequest<any>(AssetTrackingApiService.getLatestAvlEventsForDeviceUrl(deviceEsn), HttpMethod.Get)
            .pipe(map((response: CalAmpAvlEvent[]) => response.map((event: CalAmpAvlEvent) => new CalAmpAvlEvent(event))));
    }

    getMotionLogEvents(deviceEsn: number, startDate: Date, endDate: Date): Observable<CalAmpMotionLogEvent[]> {
        // NOTE: All time are converted to the user's Site time on the server. No timezone information should be passed in.
        // For initial POC, all times are converted to Central Time for Bear Path.

        return this.apiService.apiRequest<any>(AssetTrackingApiService.getMotionLogEventsUrl(deviceEsn, startDate, endDate), HttpMethod.Get)
            .pipe(map((response: CalAmpMotionLogEvent[]) => {
                return response.map((event: CalAmpMotionLogEvent) => new CalAmpMotionLogEvent(event));
            }));
    }

    getMotionLogEventsForToday(deviceEsn: number): Observable<CalAmpMotionLogEvent[]> {
        const startTime = moment().startOf('day').toDate();
        const endTime = moment().endOf('day').toDate();

        return this.getMotionLogEvents(deviceEsn, startTime, endTime);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${environment.assetTrackingApiUrl}/api/`; }

    private static getDeviceListUrl(accountId: number, bypassCache: boolean): string {
        return `${AssetTrackingApiService.baseUrl}device/list?accountId=${accountId}&bypassCache=${bypassCache}`;
    }

    private static getLatestAvlEventsForDeviceUrl(deviceEsn: number): string {
        return `${AssetTrackingApiService.baseUrl}device/avlData?deviceESN=${deviceEsn}`;
    }

    private static getMotionLogEventsUrl(deviceEsn: number, startTime: Date, endTime: Date): string {
        // NOTE: All time are converted to the user's Site time on the server. No timezone information should be passed in.
        const startDate = moment(startTime).format('Y-MM-DDTHH:mm');
        const endDate = moment(endTime).format('Y-MM-DDTHH:mm');

        return `${AssetTrackingApiService.baseUrl}device/motionLog?deviceESN=${deviceEsn}&startTime=${startDate}&endTime=${endDate}`;
    }

    private static get loginWithSsoInfoUrl(): string { return `${AssetTrackingApiService.baseUrl}auth/token`; }
}
