import SiteDataCategoryType = ToroEnums.SiteDataCategoryType;

import { ToroEnums } from '../../../common/enumerations/toro.enums';

export class AlertParameter {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.setCategoryName();
        }
    }

    public id: number;
    public userId: string;
    public siteId: number;
    public categoryType: SiteDataCategoryType;
    public enableEmail = true;
    public enableSms = true;

    categoryName: string;

    private setCategoryName() {
        let name = 'STRINGS.UNKNOWN';

        switch (this.categoryType) {
            case SiteDataCategoryType.MyTurf:
                name = 'WIDGET.MY_TURF';
                break;
            case SiteDataCategoryType.Lynx:
                name = 'WIDGET.LYNX';
                break;
            case SiteDataCategoryType.TurfCloud:
                name = 'WIDGET.TURF_CLOUD';
                break;
            case SiteDataCategoryType.TurfGuard:
                name = 'WIDGET.TURF_GUARD';
                break;
            case SiteDataCategoryType.Weather:
                name = 'WIDGET.WEATHER';
                break;
            case SiteDataCategoryType.AssetTracking:
                name = 'WIDGET.ASSET_TRACKING';
                break;
            case SiteDataCategoryType.Playbooks:
                name = 'WIDGET.PLAYBOOKS';
                break;
            case SiteDataCategoryType.Geofence:
                name = "WIDGET.GEO_FENCING";
                break;
            default:
                break;
        }

        this.categoryName = name;
    }
}
