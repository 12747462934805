import * as moment from 'moment';

export class MyTurfTokenInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.expiration = moment.utc(json.expiration).toDate();
            this.refreshTokenExpiration = moment.utc(json.refreshTokenExpiration).toDate();
        }
    }

    token: string;
    expiration: Date;
    refreshToken: string;
    refreshTokenExpiration: Date;

}
