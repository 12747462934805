import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import WeatherAverageSources = ToroEnums.WeatherAverageSources;
import WeatherAverageType = ToroEnums.WeatherAverageType;
import WeatherGraphType = ToroEnums.WeatherGraphType;

import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { ConversionUtil } from '../../../../../common/utils/conversion.util';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxWeatherStation } from '../../../../../api/lynx/models/lynx-weather-station.model';
import { PerryWeatherManagerService } from '../../../../../api/perry-weather/perry-weather-manager.service';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherAveragesConfig } from '../models/weather-averages-config.model';
import { WeatherAveragesDatum } from '../models/weather-averages-datum.model';
import { WeatherAveragesLegendItem } from '../models/weather-averages-legend-item.model';
import { WeatherAveragesManagerService } from '../../../../../api/weather-averages/weather-averages-manager.service';
import { WeatherManagerService } from '../../../../../api/weather/weather-manager.service';
import { WeatherSourceData } from '../models/weather-source-data.model';
import { WeatherSourceForecast } from '../models/weather-source-forecast.model';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-weather-averages',
    templateUrl: './widget-weather-averages.component.html',
    styleUrls: ['./widget-weather-averages.component.less']
})
export class WidgetWeatherAveragesComponent extends ToroDashboardWidget implements OnInit {
    private readonly ONE_ROW_GRAPH_HEIGHT = 132;
    private readonly TWO_ROW_GRAPH_HEIGHT = 150;

    WeatherGraphType = WeatherGraphType;

    iconColor = 'black';
    title = 'WIDGET.WEATHER_AVERAGES';

    private pwLocationId: number;

    sourcesList: SelectItem[] = [];
    selectedSourcesIds: number[] = [];
    weatherAveragesData: WeatherAveragesDatum[] = [];
    sourceCount = 0;
    sourceRetrievedCount = 0;
    currentAverages: WeatherSourceData;
    currentWeather: WeatherSourceData[] = [];
    widgetConfig: WeatherAveragesConfig;
    forecastWeather: WeatherSourceForecast[];
    graphHeight = this.ONE_ROW_GRAPH_HEIGHT;
    isDataRetrievalPending = false;
    showModalGraph = false;
    legendItems: WeatherAveragesLegendItem[] = [];
    clearChart = false;

    private _selectedWeatherType = WeatherAverageType.Temperature;
    set selectedWeatherType(value: WeatherAverageType) {
        this._selectedWeatherType = value;
        this.showSelectedWeatherGraph();
    }

    get selectedWeatherType(): WeatherAverageType {
        return this._selectedWeatherType;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                private perryWeatherManager: PerryWeatherManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService,
                private weatherAveragesManager: WeatherAveragesManagerService,
                private widgetManager: WidgetManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setComponentData());

        this.setSourcesList();

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe((dashUserPreferences: DashUserPreferences) => {
                if (this.pwLocationId != dashUserPreferences.pwLocationId) {
                    this.pwLocationId = dashUserPreferences.pwLocationId;
                    this.onSourcesSelectionChange(false);
                }
            })

        this.weatherAveragesManager.getWeatherAveragesConfig(this.associatedWidget)
            .pipe(take(1))
            .subscribe({
                next: (config: WeatherAveragesConfig) => {
                    this.widgetConfig = config;
                    this.selectedSourcesIds = this.widgetConfig.selectedSourceIds;
                },
                error: err => {
                    this.isUnableToFetchData = true;
                    if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
                }
            })

        this.graphHeight = (this.displayRows === 1 && !this.isGridsterInMobileMode) ? this.ONE_ROW_GRAPH_HEIGHT : this.TWO_ROW_GRAPH_HEIGHT;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.WeatherAveragesWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (!this.isWidgetInitialized) {
            this.getCurrentPerryWeatherLocation();
            return;
        }

        this.onSourcesSelectionChange(isManualRefresh);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSourcesSelectionChange(isManualRefresh = false) {
        if (this.isDataRetrievalPending) return;

        this.isDataRetrievalPending = this.selectedSourcesIds.length > 0;
        this.currentAverages = null;
        this.sourceRetrievedCount = 0;
        this.currentWeather = [];
        this.forecastWeather = [];
        this.legendItems = [];
        this.sourceCount = this.selectedSourcesIds.length;
        this.widgetConfig.selectedSourceIds = this.selectedSourcesIds;
        this.updateWeatherAveragesConfig();

        if (this.sourceCount < 1) {
            this.setComponentData();
            this.clearChart = true;
            return;
        }

        this.isBusy = !this.isWidgetInitialized || isManualRefresh;

        if (this.selectedSourcesIds.includes(WeatherAverageSources.ToroDtnWeather)) {
            this.getToroWeather();
        }

        if (this.selectedSourcesIds.includes(WeatherAverageSources.PerryWeather)) {
            this.getPerryWeather();
        }

        if (this.selectedSourcesIds.includes(WeatherAverageSources.Lynx)) {
            this.getLynxWeather();
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onChartClick(legendItems: WeatherAveragesLegendItem[]) {
        if (this.showModalGraph) { return; }

        this.legendItems = legendItems.slice();

        this.showModalGraph = true;
    }

    onMobileClick() {
        this.showMiniModeModal = true;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        const isMetric = this.userFormatService.isMetric;

        // Set Current Averages
        const temperature = this.currentAverages?.tempInFahrenheit != null ? <string>this.userFormatService.temperature(this.currentAverages.tempInFahrenheit, true) : '--';
        const et = this.currentAverages?.et != null ? this.userFormatService.evapotranspiration(this.currentAverages.et) : '--';
        const humidity = this.currentAverages?.humidity != null ? this.userFormatService.humidity(this.currentAverages.humidity).toString() : '--';
        const precipitation = this.currentAverages?.precipInInches != null ? <string>this.userFormatService.rainfall(this.currentAverages.precipInInches, true) : '--';

        this.weatherAveragesData = [
            new WeatherAveragesDatum(WeatherAverageType.Temperature, temperature, `°${this.userFormatService.temperatureUnits}`, '#7F1786'),
            new WeatherAveragesDatum(WeatherAverageType.Et, et, this.userFormatService.evapotranspirationUnits, null, isMetric),
            new WeatherAveragesDatum(WeatherAverageType.Humidity, humidity, '%', '#C46F33'),
            new WeatherAveragesDatum(WeatherAverageType.Precipitation, precipitation, this.userFormatService.rainfallUnits, '#458EF7', isMetric)
        ]

        this.isWidgetInitialized = true;
        this.isBusy = false;
    }

    private setSourcesList() {
        this.sourcesList.push({ value: WeatherAverageSources.ToroDtnWeather, label: this.translateService.instant("CASE_SENSITIVE.WEATHER_AVG_SOURCE_TORO") })
        this.sourcesList.push({ value: WeatherAverageSources.PerryWeather, label: this.translateService.instant("CASE_SENSITIVE.WEATHER_AVG_SOURCE_PERRY") })
        this.sourcesList.push({ value: WeatherAverageSources.Lynx, label: this.translateService.instant("CASE_SENSITIVE.WEATHER_AVG_SOURCE_LYNX") })
    }

    private getToroWeather() {
        const sources: Observable<any>[] = [
            this.weatherManager.getWeatherForecast().pipe(take(1)),
            this.weatherManager.getEvapotranspiration().pipe(take(1)),
            this.weatherManager.getWeatherGraphsData().pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(
                finalize(() => {
                    ++this.sourceRetrievedCount;
                    this.setSourceRetrievalStatus()
                })
            )
            .subscribe({
                next: ([forecast, etForecast, weatherGraphsData]) => {
                    this.lastUpdateTimestamp = forecast.lastUpdated;

                    // Current Weather
                    this.currentWeather.push(new WeatherSourceData({
                        tempInFahrenheit: forecast.currentWeather.temperature,
                        precipInInches: forecast.currentWeather.rainfall,
                        humidity: forecast.currentWeather.relHumidity,
                        et: etForecast?.results[0]?.value
                    }))

                    // Forecast Weather (seven day: today + 6)
                    const forecastData: WeatherSourceData[] = [];
                    for (let i = 0; i < 7; i++) {
                        forecastData.push(new WeatherSourceData({
                            tempInFahrenheit: forecast?.tenDayForecast[i]?.averageTemp,
                            precipInInches: forecast?.tenDayForecast[i]?.precipTotal,
                            humidity: forecast?.tenDayForecast[i]?.relHumidity,
                            et: etForecast?.results[i]?.value
                        }));
                    }
                    this.forecastWeather.push(new WeatherSourceForecast({
                        source: WeatherAverageSources.ToroDtnWeather,
                        data: [...forecastData]
                    }));
                },
                error: err => {
                    console.log();
                    // this.isUnableToFetchData = true;
                    // if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
                }
            });
    }

    private getCurrentPerryWeatherLocation() {
        this.dashUserManager.getDashUserInfo()
            .pipe(
                take(1),
                finalize(() => {
                    this.onSourcesSelectionChange(false);
                    this.isWidgetInitialized = true;
                })
            )
            .subscribe((dashUserInfo: DashUserInfo) => {
                const dashUserPrefs = dashUserInfo.preferences;
                if (dashUserPrefs.pwLocationId != null) {
                    this.pwLocationId = dashUserPrefs.pwLocationId;
                }
            })
    }

    private getPerryWeather() {
        const sources: Observable<any>[] = [
            this.perryWeatherManager.getObservation(this.pwLocationId).pipe(take(1)),
            this.perryWeatherManager.getForecast(this.pwLocationId, moment.utc().toDate(), 5).pipe(take(1)),
            this.perryWeatherManager.getForecast(this.pwLocationId, moment.utc().add(5, 'days').toDate(), 2).pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(
                finalize(() => {
                    ++this.sourceRetrievedCount;
                    this.setSourceRetrievalStatus()
                })
            )
            .subscribe({
                next: ([pwObservation, pwForecastD1_5, pwForecastD6_7]) => {
                    // this.lastUpdateTimestamp = forecast.lastUpdated;
                    this.currentWeather.push(new WeatherSourceData({
                        tempInFahrenheit: ConversionUtil.celsiusToFahrenheit(pwObservation.data.ambientTemperature.value),
                        precipInInches: ConversionUtil.centimetersToInches(pwObservation.data.precipitation.fromMidnight),
                        humidity: pwObservation.data.humidity.value,
                        et: null
                    }))

                    // Forecast Weather (seven day: today + 6)
                    const forecastData: WeatherSourceData[] = [];

                    // Today + 4 (5 days)
                    for (let i = 0; i < 5; i++) {
                        const forecast = pwForecastD1_5.dailyForecast[i];

                        forecastData.push(new WeatherSourceData({
                            tempInFahrenheit: ConversionUtil.celsiusToFahrenheit((forecast?.ambientTemperatureMin.value + forecast?.ambientTemperatureMax.value) / 2),
                            precipInInches: ConversionUtil.centimetersToInches((forecast?.dayTime.precipitation.accumulation + forecast?.nightTime.precipitation.accumulation) / 2),
                            humidity: (forecast?.dayTime.humidity.value + forecast?.nightTime.humidity.value) / 2,
                            et: null
                        }));
                    }

                    // // Days 6 - 7
                    for (let i = 0; i < 2; i++) {
                        const forecast = pwForecastD6_7.dailyForecast[i];

                        forecastData.push(new WeatherSourceData({
                            tempInFahrenheit: ConversionUtil.celsiusToFahrenheit((forecast?.ambientTemperatureMin.value + forecast?.ambientTemperatureMax.value) / 2),
                            precipInInches: forecast?.nightTime?.precipitation != null
                                ? ConversionUtil.centimetersToInches((forecast?.dayTime.precipitation.accumulation + forecast?.nightTime?.precipitation?.accumulation) / 2)
                                : ConversionUtil.centimetersToInches((forecast?.dayTime.precipitation.accumulation)),
                            humidity: forecast?.nightTime?.humidity != null
                                ? (forecast?.dayTime.humidity.value + forecast?.nightTime.humidity.value) / 2
                                : forecast?.dayTime.humidity.value,
                            et: null
                        }));
                    }

                    this.forecastWeather.push(new WeatherSourceForecast({
                        source: WeatherAverageSources.PerryWeather,
                        data: [...forecastData]
                    }));
                },
                error: err => {
                    console.log();
                }
            });
    }

    private getLynxWeather() {
        this.lynxManager.getWeatherStations()
            .pipe(
                take(1),
                finalize(() => {
                    ++this.sourceRetrievedCount;
                    this.setSourceRetrievalStatus()
                })
            )
            .subscribe({
                next: (weatherStations: LynxWeatherStation[]) => {
                    weatherStations.forEach(ws => {
                        this.currentWeather.push(new WeatherSourceData({
                            tempInFahrenheit: ws.statuses[0]?.temperature,
                            precipInInches: ws.statuses[0]?.precipitation,
                            humidity: ws.statuses[0]?.relativeHumidity,
                            et: ws.statuses[0]?.et
                        }))
                    })
                },
                error: err => {
                    console.log();
                }
            });
    }

    private setSourceRetrievalStatus() {
        if (this.sourceRetrievedCount === this.sourceCount) {
            this.isBusy = false;
            this.validateOrUpdateTimestamp();
            this.clearIsUnableToFetchData();
            this.calculateAverages();
            this.setComponentData();
            this.isDataRetrievalPending = false;
        }
    }

    private calculateAverages() {
        // Remove null values
        const tempValues = this.currentWeather.filter(t => t.tempInFahrenheit != null);
        const precipValues = this.currentWeather.filter(t => t.precipInInches != null);
        const humidityValues = this.currentWeather.filter(t => t.humidity != null);
        const etValues = this.currentWeather.filter(t => t.et != null);

        this.currentAverages = new WeatherSourceData({
            tempInFahrenheit: tempValues.length != 0 ? tempValues.reduce((sum, data) => sum + data.tempInFahrenheit, 0) / tempValues.length : null,
            precipInInches: precipValues.length != 0 ? precipValues.reduce((sum, data) => sum + data.precipInInches, 0) / precipValues.length : null,
            humidity: humidityValues.length != 0 ? humidityValues.reduce((sum, data) => sum + data.humidity, 0) / humidityValues.length : null,
            et: etValues.length != 0 ? etValues.reduce((sum, data) => sum + data.et, 0) / etValues.length : null
        })
    }

    private updateWeatherAveragesConfig() {
        if (environment.isDemoMode) return;

        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.widgetConfig)
            .pipe(take(1))
            .subscribe({
                error: () => {
                    this.dashMessageService.showGenericSaveErrorMessage();
                }
            });
    }

    private showSelectedWeatherGraph() {
        // this.selectedWeatherType;
    }

    private validateOrUpdateTimestamp() {
        const timeDifffMs = Date.now() - this.lastUpdateTimestamp.getTime();
        const timeDiffMins = Math.floor(timeDifffMs / (1000 * 60));

        if (timeDiffMins > 15) this.lastUpdateTimestamp = new Date();
    }
}
