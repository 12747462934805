/* eslint-disable @angular-eslint/directive-class-suffix */
import '../../../../common/extension-methods/extensions.strings';
import * as Highcharts from 'highcharts';
import { Directive, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardHoleLimit } from '../../../../api/turf-guard/models/turf-guard-hole-limit.model';
import { TurfGuardManagerService } from '../../../../api/turf-guard/turf-guard-manager.service';
import { TurfGuardWidgetData } from '../../../../api/turf-guard/models/turf-guard-widget-data.model';
import { UserFormatService } from '../../../../common/services/user-format.service';

import GaugeColor = ToroEnums.GaugeColor;
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;
// import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
// import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
// import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

const More = require('highcharts/highcharts-more');
const Annotations = require('highcharts/modules/annotations');
More(Highcharts);
Annotations(Highcharts);

@UntilDestroy()
@Directive()
export abstract class TurfGuardWidgetBase extends ToroDashboardWidget implements OnInit {
    @ViewChild('tgChartContainer') tgChartContainer: ElementRef;

    TurfGuardSensorType = TurfGuardSensorType;

    // private isTopologyMenuDisabled = false;

    iconColor = '#46a657';
    title = '--';

    Highcharts = Highcharts;
    gaugeOptions: any = null;
    gauge: Highcharts.Chart;
    chartOptions: any = null;
    chart: Highcharts.Chart;
    isGaugeLoaded = false;
    turfGuardWidgetData: TurfGuardWidgetData;
    averageCourseValueUnits = '';
    averageCourseValueLabel = '';
    alertSensorType = '';
    showChartDialog = false;
    showSensorsDialog = false;
    selectedHoleIndex = 0;
    showAllAlerts = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    protected constructor(protected analyticsService: AnalyticsService,
                          protected broadcastService: BroadcastService,
                          protected dashMessageService: DashMessageService,
                          protected dashUserManager: DashUserManagerService,
                          protected deviceManager: DeviceManagerService,
                          protected translateService: TranslateService,
                          protected turfGuardManager: TurfGuardManagerService,
                          protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isBusy = true;
        this.language = this.dashUserManager.language;

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onUserPreferencesChange());

        // this.broadcastService.turfGuardTopologyUpdatePending
        //     .pipe(untilDestroyed(this))
        //     .subscribe((isPending: boolean) => {
        //         this.isTopologyMenuDisabled = isPending;
        //         this.setWidgetMenu();
        //     });

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                // Hide modals during resize so we don't have to deal with re-layout of charts.
                this.showChartDialog = false;
                this.showSensorsDialog = false;
            });

        this.setupGauge();

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Abstract Methods
    // =========================================================================================================================================================

    protected abstract setDataLabel(): void;

    protected abstract getWidgetData(): void;

    // =========================================================================================================================================================
    // Protected Methods
    // =========================================================================================================================================================

    protected get axisValueUnits(): string {
        return '';
    }

    protected onUserPreferencesChange(): void {
        this.language = this.dashUserManager.language;
        this.setDataLabel();
    }

    protected setGaugeValues() {
        if (!this.gauge || !this.gauge.series) { return; }

        // Gauge Value
        this.gauge.series[0].points[0].update(this.turfGuardWidgetData.averageCourseValue);

        // Gauge Range
        this.gauge.yAxis[0].update({ min: this.turfGuardWidgetData.minValue, max: this.turfGuardWidgetData.maxValue });

        // Plot Bands
        this.updatePlotBand(this.gauge, 'low', this.turfGuardWidgetData.minValue, this.turfGuardWidgetData.lowAlertLevel, GaugeColor.Low);
        this.updatePlotBand(this.gauge, 'mid', this.turfGuardWidgetData.lowAlertLevel, this.turfGuardWidgetData.highAlertLevel, GaugeColor.Mid);
        this.updatePlotBand(this.gauge, 'high', this.turfGuardWidgetData.highAlertLevel, this.turfGuardWidgetData.maxValue, GaugeColor.High);

        this.gauge.reflow();
        this.isGaugeLoaded = true;
    }

    protected setComponentData() {
        this.setDataLabel();
        if (this.displayCols === 1) { this.setGaugeValues(); }
    }

    protected updatePlotBand(chart: Highcharts.Chart, id: string, from: number, to: number, color: string) {
        chart.yAxis[0].removePlotBand(id);
        chart.yAxis[0].addPlotBand({ id, borderColor: '#666', borderWidth: 1, thickness: 40, from, to, color });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowAlerts() {
        this.showAllAlerts = true;
        this.showSensorsDialog = true;
    }

    onViewHoles() {
        this.showChartDialog = true;
    }

    onViewSensors(holeIndex: any) {
        this.selectedHoleIndex = holeIndex;
        this.showChartDialog = false;
        this.showSensorsDialog = true;
    }

    onChartLoaded() {
        setTimeout(() => this.isBusy = false);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get highAlertsString() {
        if (this.turfGuardWidgetData.highAlertsCount < 1) { return ''; }

        if (this.turfGuardWidgetData.highAlertsCount === 1) {
            return this.translateService.instant('STRINGS.SENSOR_ALERT_HIGH_VALUE', { sensorType: this.alertSensorType });
        } else {
            return this.translateService.instant('STRINGS.SENSORS_ALERT_HIGH_VALUE', {
                count: this.turfGuardWidgetData.highAlertsCount, sensorType: this.alertSensorType
            });
        }
    }

    get lowAlertsString() {
        if (this.turfGuardWidgetData.lowAlertsCount < 1) { return ''; }

        if (this.turfGuardWidgetData.lowAlertsCount === 1) {
            return this.translateService.instant('STRINGS.SENSOR_ALERT_LOW_VALUE', { sensorType: this.alertSensorType });
        } else {
            return this.translateService.instant('STRINGS.SENSORS_ALERT_LOW_VALUE', {
                count: this.turfGuardWidgetData.lowAlertsCount, sensorType: this.alertSensorType
            });
        }
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        // Give the chart container div a chance to be *ngIf'd into the page before attempting to access it.
        setTimeout(() => {
            if (this.displayCols > 1) { this.isGaugeLoaded = false; }

            if (this.displayCols === 1) {
                // Wipe out the chart and chartOptions objects when displaying the gauge. There are
                // some weird side effects drawing the yAxis range after switching min/max if we don't.
                this.chart = null;
                this.chartOptions = null;
            }
        });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        // this.widgetMenuItems.unshift(
        //     {
        //         label: this.translateService.instant('STRINGS.UPDATE_TOPOLOGY').toTitleCase(),
        //         icon: 'pi pi-fw pi-sitemap',
        //         command: this.updateTopology.bind(this),
        //         disabled: this.isTopologyMenuDisabled
        //     }
        // );
    }

    get chartDataValues(): number[] {
        return this.turfGuardWidgetData.chartDataValues;
    }

    get chartMinValue(): number {
        return this.turfGuardWidgetData.minValue;
    }

    get chartMaxValue(): number {
        return this.turfGuardWidgetData.maxValue;
    }

    get chartDataLimits(): TurfGuardHoleLimit[] {
        return this.turfGuardWidgetData.chartDataLimits;
    }

    get chartValueUnits(): string {
        return '';
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    // private updateTopology() {
    //     // TODO: Request Turf Guard Topology update.
    //
    //     this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetUpdateTopology, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    //
    //     // Disable the Update Topology menu item when an update is pending.
    //     this.broadcastService.turfGuardTopologyUpdatePending.next(true);
    //
    //     // TODO: This is temporary to see the effect. Remove when actually fetching the data.
    //     setTimeout(() => {
    //         this.broadcastService.turfGuardTopologyUpdatePending.next(false);
    //     }, 10000);
    // }

    private setupGauge() {
        const self = this;

        this.gaugeOptions = {
            chart: {
                type: 'gauge',
                spacing: [25, 5, -85, 5],
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                events: {
                    load(event) {
                        self.gauge = event.target;
                        setTimeout(() => {
                            if (self.turfGuardWidgetData) self.setGaugeValues();
                            // event.target.reflow();
                        });
                    }
                }
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            pane: {
                startAngle: -90,
                endAngle: 90,
                size: '100%',
                background: [{
                    backgroundColor: null,
                    borderWidth: 0,
                    outerRadius: '100%',
                }]
            },
            yAxis: [{						// Outer label Axis
                min: 0,     // This will changed in setGaugeValues()
                max: 100,   // This will changed in setGaugeValues()

                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 5,
                minorTickPosition: 'inside',
                minorTickColor: '#000',

                tickPixelInterval: 30,
                tickWidth: 1,
                tickPosition: 'inside',
                tickLength: 12,
                tickColor: '#000',

                labels: {
                    formatter() {
                        return this.value + self.axisValueUnits;
                    },
                    distance: 14,
                },
                title: { enabled: false },
            }],
            series: [{
                name: 'moisture',
                data: [0],
                dataLabels: { enabled: false },
                dial: {
                    radius: '90%',
                },
            }],

            tooltip: {
                enabled: false
            }
        };

    }

}
