import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { environment } from '../../../../../environments/environment';
import { GcsaaChannel } from '../../../../api/gcsaa-newsfeed/models/gcsaa-channel.model';
import { GcsaaChannelItem } from '../../../../api/gcsaa-newsfeed/models/gcsaa-channel-item.model';
import { GcsaaRssManagerService } from '../../../../api/gcsaa-newsfeed/gcsaa-rss-manager.service';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'toro-widget-gcsaa',
    templateUrl: './widget-gcsaa.component.html',
    styleUrls: ['./widget-gcsaa.component.less']
})
export class WidgetGcsaaComponent extends ToroDashboardWidget implements OnInit {

    iconColor = 'black';
    title = 'WIDGET.GCSAA';

    readonly rssUrl = 'https://www.gcsaa.org/feeds/news-rss';

    rssFeed: GcsaaChannel;
    showArticleModal = false;
    selectedArticle: GcsaaChannelItem;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private gcsaaRssManager: GcsaaRssManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDisplayValues());
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.GcsaaWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (isManualRefresh) this.isBusy = true;

        this.gcsaaRssManager.getFeed()
            .pipe(
                take(1),
                finalize(() => this.isBusy = false)
            )
            .subscribe({
                next: (feed: GcsaaChannel) => {
                    this.rssFeed = feed;
                    this.clearIsUnableToFetchData();
                    this.lastUpdateTimestamp = new Date();
                },
                error: err => {
                    this.isUnableToFetchData = true;
                    if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
                }
            })
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowArticleModal(article: GcsaaChannelItem) {
        this.selectedArticle = article;
        this.showArticleModal = true;
    }

    onNavigateToSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_GCSAA_SITE' });
        setTimeout(() => window.open(environment.gcsaaUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {

    }

}
