import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-overlay-datum',
    templateUrl: './soil-scout-overlay-datum.component.html',
    styleUrls: ['./soil-scout-overlay-datum.component.less']
})
export class SoilScoutOverlayDatumComponent implements OnInit {
    @HostBinding('class') class = 'toro-soil-scout-overlay-datum';

    @Output() showSensorChart = new EventEmitter<SoilScoutSensorType>();

    private _value: number;
    @Input() set value(val: number) {
        this._value = val
        if (!this.isWidgetInitialized) return;

        this.setDisplayValues();
    }

    get value(): number {
        return this._value;
    }

    private _minValue: number;
    @Input() set minValue(value: number) {
        this._minValue = value
        if (!this.isWidgetInitialized) return;

        this.setDisplayValues();
    }

    get minValue(): number {
        return this._minValue;
    }

    private _maxValue: number;
    @Input() set maxValue(value: number) {
        this._maxValue = value;
        if (!this.isWidgetInitialized) return;

        this.setDisplayValues();
    }

    get maxValue(): number {
        return this._maxValue;
    }

    @Input() sensorType: SoilScoutSensorType;

    protected datumTitle: string;
    protected datumIcon: string;
    protected barColor = 'white';
    protected barWidth = 0;
    protected displayValue: string;

    private isWidgetInitialized = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDisplayValues());

        this.setDisplayValues();
        this.isWidgetInitialized = true;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected sensorClicked() {
        this.showSensorChart.emit(this.sensorType);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.datumTitle = 'STRINGS.MOISTURE';
                this.datumIcon = 'soil-scout-moisture.png';
                this.barColor = 'linear-gradient(270deg,#365df5,#9aaaff)';
                this.barWidth = this.value * 100 / this.maxValue * 100;
                this.displayValue = `${this.userFormatService.toUserNumber(this.value * 100, 0, 2)}%`
                break;
            case ToroEnums.SoilScoutSensorType.Temperature: {
                const temp = +this.userFormatService.temperatureFromCelsius(this.value, false, 2);
                const minTemp = +this.userFormatService.temperature(this.minValue, false, 2);
                const maxTemp = +this.userFormatService.temperature(this.maxValue, false, 2);

                this.datumTitle = 'STRINGS.TEMPERATURE';
                this.datumIcon = 'soil-scout-temperature.png';
                this.barColor = 'linear-gradient(270deg,#ff50a2,#ffc881)';
                this.barWidth = Math.abs(temp) / (maxTemp - minTemp) * 100;
                this.displayValue = `${this.userFormatService.temperatureFromCelsius(this.value, true)} °${this.userFormatService.temperatureUnits}`
                break;
            }
            case ToroEnums.SoilScoutSensorType.Salinity:
                this.datumTitle = 'STRINGS.SALINITY';
                this.datumIcon = 'soil-scout-salinity.png';
                this.barColor = 'linear-gradient(270deg,#8037ff,#f192e4)';
                this.barWidth = this.value / this.maxValue * 100;
                this.displayValue = `${this.userFormatService.salinity(this.value, true, 2)} ${this.userFormatService.salinityUnits}`
                break;
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                this.datumTitle = 'CASE_SENSITIVE.WATER_BALANCE';
                this.datumIcon = 'soil-scout-water-balance.png';
                this.barColor = 'linear-gradient(270deg,#67FF9B,#81E8FF)';
                this.barWidth = this.value / this.maxValue * 100;
                this.displayValue = `${this.userFormatService.toUserNumber(this.value, 2)}`
                break;
            default:
                this.datumTitle = '';
                break;
        }

        if (this.barWidth < 0) {
            this.barWidth = 0;
        } else if (this.barWidth > 100) {
            this.barWidth = 100;
        }
    }

    // private getWaterBalanceBarColor(val: number) {
    //     let color = {
    //         linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    //         stops: [[0, '#0bd465'], [1, '#0ac92a']]
    //     }
    //
    //     if (val <= .22) { color.stops = [[0, '#fa4f2d'], [1, '#d44326']] } else if (val <= .45) { color.stops = [[0, '#fcdc0d'], [1, '#f2bc0c']] }
    //
    //     return color;
    // }
}
