/* eslint-disable @typescript-eslint/dot-notation */
export class TurfGuardHoleLimit {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });
        }
    }

    low: number;
    high: number;

}
