<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-weather-graph-widget-content-main tmm-carousel-host">
            <div class="tmm-carousel-container" [ngClass]="{'single-item': weatherStations?.length <= 1}">
                <p-carousel [value]="weatherStations" [numVisible]="1" [numScroll]="1" contentClass="toro-carousel-content" dotsContainerClass="toro-dots-container">
                    <ng-template let-station pTemplate="item">

                        <div id="twg-station-data" class="toro-widget-content-pane toro-component-shield-host">

                            <div class="toro-mini-mode-content-panel">
                                <div class="twg-mm-ws-name" style="margin-top: -3px; margin-bottom: 4px;">{{station.name}}</div>

                                <div id="twg-data-container">
                                    <div id="twg-wind-data" class="twg-datum-container">
                                        <i class="toro-id-wind twg-datum-icon"></i>
                                        <div class="twg-datum-value"><span class="tmm-iv-value">{{wind[station.index]}}</span><span class="tmm-iv-value-label">{{windUnits}}</span></div>
                                        <div class="tmm-bot-left-container">
                                            <div class="tmm-ellipsis-text">{{'STRINGS.WIND' | translate}}</div>
                                        </div>
                                    </div>
                                    <div id="twg-dew-point-data" class="twg-datum-container" [ngClass]="language">
                                        <i class="toro-id-dew-point twg-datum-icon"></i>
                                        <div class="twg-datum-value"><span class="tmm-iv-value">{{dewPoint[station.index]}}</span><span class="tmm-iv-value-label">°{{dewPointUnits}}</span></div>
                                        <div class="tmm-bot-left-container">
                                            <div class="tmm-ellipsis-text">{{'STRINGS.DEW_POINT' | translate}}</div>
                                        </div>
                                    </div>
                                    <div id="twg-precip-data" class="twg-datum-container" [ngClass]="language">
                                        <fa-icon icon="tint"></fa-icon>
                                        <div class="twg-datum-value" [ngClass]="{'is-metric': isMetric}">
                                            <span class="tmm-iv-value">{{precipitation[station.index]}}</span>
                                            <span class="tmm-iv-value-label">{{precipitationUnits}}</span>
                                        </div>
                                        <div class="tmm-bot-left-container">
                                            <div class="tmm-ellipsis-text">{{'STRINGS.PRECIPITATION' | translate}}</div>
                                        </div>
                                    </div>
                                    <div id="twg-humidity-data" class="twg-datum-container" [ngClass]="language">
                                        <i class="toro-id-humidity twg-datum-icon"></i>
                                        <div class="twg-datum-value"><span class="tmm-iv-value">{{humidity[station.index]}}</span><span class="tmm-iv-value-label">{{'%'}}</span></div>
                                        <div class="tmm-bot-left-container">
                                            <div class="tmm-ellipsis-text">{{'STRINGS.HUMIDITY' | translate}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                        </div>

                    </ng-template>
                </p-carousel>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-weather-graph-widget-content-main">
            <div class="toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing}">

                <div *ngIf="!hasNoData && !isResizing" class="toro-absolute-child lynx-weather-stations-container">
                    <ng-container *ngIf="weatherStations && weatherStations[0]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[0], graphData: weatherGraphsData[0]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[1]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[1], graphData: weatherGraphsData[1]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[2]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[2], graphData: weatherGraphsData[2]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[3]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[3], graphData: weatherGraphsData[3]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[4]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[4], graphData: weatherGraphsData[4]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[5]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[5], graphData: weatherGraphsData[5]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[6]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[6], graphData: weatherGraphsData[6]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[7]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[7], graphData: weatherGraphsData[7]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[8]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[8], graphData: weatherGraphsData[8]}"></ng-container>
                    <ng-container *ngIf="weatherStations && weatherStations[9]" [ngTemplateOutlet]="lynxWeatherStation" [ngTemplateOutletContext]="{weatherStation: weatherStations[9], graphData: weatherGraphsData[9]}"></ng-container>
                </div>

            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #lynxWeatherStation let-weatherStation='weatherStation' let-graphData='graphData'>
    <div class="lynx-weather-station-container">
        <div class="lynx-weather-station-name-container">
            <span class="lws-name">{{weatherStation?.name}}</span>
            <div class="lws-last-update">
                <toro-last-updated
                        [lastUpdateTimestamp]="weatherStation['lastUpdatedTimestamp']"
                        [staleDataThresholdInMinutes]="staleDataThresholdInMinutes"
                        [hideAlertBanner]="hideWidgetAlertBanner"
                ></toro-last-updated>
            </div>
        </div>
        <div style="position: relative; flex: 1;">
            <div class="toro-absolute-child lynx-weather-graphs-container">
                <toro-weather-graph *ngFor="let g of weatherGraphs" [height]="graphHeight" [graphType]="g" [data]="graphData"></toro-weather-graph>
            </div>
        </div>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
