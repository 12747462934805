import { Directive, OnDestroy } from '@angular/core';

@Directive()
export abstract class StepIntegrationBase implements OnDestroy {
    private readonly maxConnectionChecks = 5;
    protected connectionCheckCount = 0;
    protected isConnected = false;
    private timerRef: NodeJS.Timeout

    ngOnDestroy() {
        clearTimeout(this.timerRef);
    }

    protected abstract validateConnectionInternal();

    protected validateConnection() {
        if (this.isConnected || ++this.connectionCheckCount >= this.maxConnectionChecks) return;

        if (this.connectionCheckCount == 1)
            this.validateConnectionInternal();
        else
            this.timerRef = setTimeout(() => this.validateConnectionInternal(), 60 * 1000);
    }
}
