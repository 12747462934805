/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { DashboardWidgetInfo } from './models/dashboard-widget-info.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UpdateDashboardWidgetInfo } from './models/update-dashboard-widget-info.model';
import { UpdateWidgetModel } from './models/update-widget.model';

@Injectable({
    providedIn: 'root'
})
export class WidgetApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDashboardWidgets(userId: string, siteId: number, deviceId: string): Observable<DashboardWidgetInfo[]> {
        return this.apiService.apiRequest<any>(WidgetApiService.getWidgetsUrl(userId, siteId, deviceId), HttpMethod.Get)
            .pipe(map((response: DashboardWidgetInfo[]) => {
                return response.map(w => new DashboardWidgetInfo(w));
            }));
    }

    saveDashboardWidgets(updateDashboardWidgetInfo: UpdateDashboardWidgetInfo) {
        return this.apiService.apiRequest<any>(WidgetApiService.baseUrl, HttpMethod.Post, updateDashboardWidgetInfo);
    }

    updateWidgetConfig(updateWidgetModel: UpdateWidgetModel): Observable<void> {
        return this.apiService.apiRequest<any>(WidgetApiService.updateWidgetConfigUrl, HttpMethod.Put, updateWidgetModel);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}widget`; }

    private static getWidgetsUrl(userId: string, siteId: number, deviceId: string): string {
        return deviceId != null
            ? `${WidgetApiService.baseUrl}?userId=${userId}&siteId=${siteId}&deviceId=${deviceId}`
            : `${WidgetApiService.baseUrl}?userId=${userId}&siteId=${siteId}`
    }

    private static get updateWidgetConfigUrl(): string { return `${WidgetApiService.baseUrl}/config`; }
}
