<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            (gotoSite)="onDashboardClick()"
    >

        <div class="toro-playbooks-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane">
                <div class="toro-mini-mode-content-panel toro-component-shield-host">

                    <div id="tpw-gdd-selector-container" [ngClass]="language">
                        <p-dropdown
                                [appendTo]="'body'"
                                [disabled]="gddAreasList == null || gddAreasList.length < 1"
                                [options]="gddAreasList"
                                [tooltip]="'STRINGS.SELECT_AREA' | translate | titlecase"
                                [styleClass]="'tmm-p-dropdown tpw-dropdown'"
                                [panelStyleClass]="'tmm-p-dropdown'"
                                [(ngModel)]="playbooksConfig && playbooksConfig.selectedGddAreaId"
                                (onChange)="onGddAreaChange($event)"
                        ></p-dropdown>
                        <span class="tmm-ellipsis-text">{{'STRINGS.GDD_SINCE_LAST_APP' | translate}}</span>
                    </div>

                    <div id="gdd-values-container">
                        <div class="gdd-datum">
                            <span class="gdd-value-label">0</span>
                            <span id="gdd-value-0" class="gdd-value">{{gddGraphData?.gdd0c.toFixed(0)}}</span>
                        </div>
                        <div class="gdd-datum">
                            <span class="gdd-value-label">10</span>
                            <span id="gdd-value-10" class="gdd-value">{{gddGraphData?.gdd10c.toFixed(0)}}</span>
                        </div>
                        <div class="gdd-datum">
                            <span class="gdd-value-label">32</span>
                            <span id="gdd-value-32" class="gdd-value">{{gddGraphData?.gdd32.toFixed(0)}}</span>
                        </div>
                        <div class="gdd-datum">
                            <span class="gdd-value-label">50</span>
                            <span id="gdd-value-50" class="gdd-value">{{gddGraphData?.gdd50.toFixed(0)}}</span>
                        </div>
                    </div>

                    <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                </div>

            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-playbooks-widget-content">

            <div *ngIf="playbooksCourseId && monthlyReport.length > 0 && displayRows === 1 && !isGridsterInMobileMode" class="toro-widget-content-pane tpw-size-one" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container [ngTemplateOutlet]="tpwTopToolbar"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwGDDGraph"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwBottomToolbar"></ng-container>
            </div>

            <div *ngIf="playbooksCourseId && monthlyReport.length > 0 && (displayRows === 2 || isGridsterInMobileMode)" class="toro-widget-content-pane tpw-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container [ngTemplateOutlet]="tpwTopToolbar"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwCostData"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwNutrientsGraph"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwGDDGraph"></ng-container>

                <ng-container [ngTemplateOutlet]="tpwBottomToolbar"></ng-container>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #tpwTopToolbar>
    <div id="tpw-top-toolbar" [ngClass]="{'is-mobile': isMobile}">
        <div id="tpw-top-toolbar-left">
            <div class="twp-button twp-with-logo" [ngClass]="language" (click)="onDashboardClick()">
                <span class="twp-logo-container"><img src="../../../../../assets/images/playbooksLogo.png" alt=""></span>
                <span>{{'STRINGS.DASHBOARD' | translate}}</span>
            </div>
            <div class="twp-button log" [ngClass]="language" (click)="onNewLogClick()">
                <span>{{'STRINGS.NEW_LOG' | translate | titlecase}}</span>
            </div>
<!--            <div class="twp-logo-button" (click)="onDashboardClick()">-->
<!--                <span><img src="../../../../../assets/images/playbooksLogo.png" alt=""></span>-->
<!--            </div>-->
        </div>
        <div id="tpw-top-toolbar-right">
            <div class="twp-icon-button" (click)="onPrintClick()" [ngClass]="{'btn-disabled': logsToPrint.length < 1}">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>
                <div *ngIf="logsToPrint.length > 0" class="toro-notification-count">{{logsToPrint.length}}</div>
            </div>
<!--            <div class="twp-icon-button" (click)="onAlertsClick()" [ngClass]="{'btn-disabled': !alertCount}">-->
<!--                <fa-icon [icon]="['far', 'bell']"></fa-icon>-->
<!--                <div *ngIf="alertCount > 0" class="toro-notification-count">{{alertCount}}</div>-->
<!--            </div>-->
        </div>
    </div>
</ng-template>

<ng-template #tpwCostData>
    <div id="tpw-cost-data-content" class="toro-component-shield-host">
        <div id="tpw-tc-row-one">
            {{'CASE_SENSITIVE.CHEM_FERT_COST' | translate}}
            <div id="tpw-tc-chem-fert">
                <div class="tpw-tc-cf-value-container">
                    <div id="mtd-lbl" [ngClass]="language">{{'STRINGS.MONTH_TO_DATE_ABBR' | translate | uppercase}}</div>
                    <div class="tpw-tc-cf-value">{{costMtd}}</div>
                </div>
                <div class="tpw-tc-cf-value-container">
                    <div id="ytd-lbl" [ngClass]="language">{{'STRINGS.YEAR_TO_DATE_ABBR' | translate | uppercase}}</div>
                    <div class="tpw-tc-cf-value">{{costYtd}}</div>
                </div>
            </div>
        </div>
        <toro-component-shield></toro-component-shield>
    </div>
</ng-template>

<ng-template #tpwNutrientsGraph>
    <div class="tpw-graph-container tpw-nutrients-graph">
        <div class="tpw-graph-header">
            <div class="tpw-graph-title">{{'STRINGS.NUTRIENTS' | translate}}</div>
            <div>
                <!--                [disabled]="lynxCourses.length < 2"-->
                <p-dropdown
                        [disabled]="nutrientsAreasList == null || nutrientsAreasList.length < 1"
                        [options]="nutrientsAreasList"
                        [tooltip]="'STRINGS.SELECT_AREA' | translate | titlecase"
                        [(ngModel)]="playbooksConfig && playbooksConfig.selectedNutrientAreaId"
                        (onChange)="onNutrientAreaChange($event)"
                ></p-dropdown>
            </div>
        </div>

        <div class="toro-absolute-child tpw-graph-wrapper" [ngClass]="{'disable-graph': nutrientGraphData == null}">
            <toro-playbooks-chart-wrapper [height]="graphHeight" [data]="nutrientGraphData" [graphType]="PlaybooksChartType.Nutrient">
            </toro-playbooks-chart-wrapper>
        </div>

    </div>
</ng-template>

<ng-template #tpwGDDGraph>
    <div class="tpw-graph-container" [ngClass]="{'is-mobile': isMobile}">
        <div class="tpw-graph-header">
            <div class="tpw-graph-title" [ngClass]="language">{{'STRINGS.GDD_SINCE_LAST_APP' | translate}}</div>
            <div>
                <!--                [disabled]="lynxCourses.length < 2"-->
                <p-dropdown
                        [disabled]="gddAreasList == null || gddAreasList.length < 1"
                        [options]="gddAreasList"
                        [tooltip]="'STRINGS.SELECT_AREA' | translate | titlecase"
                        [(ngModel)]="playbooksConfig && playbooksConfig.selectedGddAreaId"
                        (onChange)="onGddAreaChange($event)"
                ></p-dropdown>
            </div>
        </div>

        <div class="toro-absolute-child tpw-graph-wrapper" [ngClass]="{'disable-graph': gddGraphData == null}">
            <toro-playbooks-chart-wrapper [height]="graphHeight" [data]="gddGraphData" [graphType]="PlaybooksChartType.Gdd">
            </toro-playbooks-chart-wrapper>
        </div>
    </div>
</ng-template>

<ng-template #tpwBottomToolbar>
    <div id="tpw-bottom-toolbar-container">
        <div id="tpw-bottom-toolbar">
            <div class="twp-button" (click)="onReportsClick()">
                <span>{{'STRINGS.REPORTS' | translate}}</span>
            </div>
            <div class="twp-button labels" [ngClass]="language" (click)="onLabelsSdsClick()">
                <span>{{'STRINGS.LABELS_SDS' | translate}}</span>
            </div>
            <div class="twp-button" (click)="onInventoryClick()">
                <span>{{'STRINGS.INVENTORY' | translate}}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<p-dialog [visible]="isCourseIdDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.SET_PLAYBOOKS_COURSE_ID' | translate | titlecase}}</p-header>

    <div id="twp-course-dialog-container">
        <span>{{'STRINGS.PLAYBOOKS_COURSE_ID' | translate | titlecase}}</span>
        <p-inputMask
                [(ngModel)]="newCourseId"
                mask="********-****-****-****-************"
                characterPattern="[a-fA-F]"
                [autoFocus]="true"
                [size]="40"
                [placeholder]="'STRINGS.ENTER_PLAYBOOKS_COURSE_ID' | translate | titlecase">
        </p-inputMask>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelSetCourseId()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetCourseId()"></p-button>
    </p-footer>
</p-dialog>

<p-dialog [visible]="isPrintLinksDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '80%'}">
    <p-header>{{'STRINGS.PLAYBOOKS_APPLICATION_LOGS_PRINT_LINKS' | translate | titlecase}}</p-header>

    <div id="twp-print-links-dialog-container">
        <p-table *ngIf="true" [value]="logsToPrint" [scrollable]="true" scrollHeight="flex" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th class="twp-pl-area-col" [pSortableColumn]="'applicationAreaLog'">{{'STRINGS.AREA' | translate | titlecase}}<p-sortIcon [field]="'applicationAreaLog'"></p-sortIcon></th>
                    <th class="twp-pl-attached-course-col" [pSortableColumn]="'applicationLogAttachedCourse'">{{'STRINGS.ATTACHED_COURSE' | translate | titlecase}}<p-sortIcon [field]="'applicationLogAttachedCourse'"></p-sortIcon></th>
                    <th class="twp-pl-log-course-col" [pSortableColumn]="'applicationLogCourse'">{{'STRINGS.COURSE' | translate | titlecase}}<p-sortIcon [field]="'applicationLogCourse'"></p-sortIcon></th>
                    <th class="twp-pl-log-date-col" [pSortableColumn]="'applicationLogDate'">{{'STRINGS.LOG_DATE' | translate | titlecase}}<p-sortIcon [field]="'applicationLogDate'"></p-sortIcon></th>
                    <th class="twp-pl-validation-col" [pSortableColumn]="'validation'">{{'STRINGS.VALIDATION' | translate | titlecase}}<p-sortIcon [field]="'validation'"></p-sortIcon></th>
                    <th class="twp-pl-print-link-col">{{'STRINGS.PRINT_LINK' | translate | titlecase}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
                <tr>
                    <td class="twp-pl-area-col">{{log.applicationAreaLog}}</td>
                    <td class="twp-pl-attached-course-col">{{log.applicationLogAttachedCourse}}</td>
                    <td class="twp-pl-log-course-col">{{log.applicationLogCourse}}</td>
                    <td class="twp-pl-log-date-col">{{formatDate(log.applicationLogDate)}}</td>
                    <td class="twp-pl-validation-col">{{log.validation}}</td>
                    <td class="twp-pl-print-link-col"><a href="{{log.url}}" target="_blank" (click)="logPrintClick()">{{'STRINGS.PRINT_LINK' | translate | titlecase}}</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-footer>
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="isPrintLinksDialogDisplayed = false"></p-button>
    </p-footer>
</p-dialog>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
