import { PogoZone } from './pogo-zone.model';

export class PogoProperty {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.zones) this.zones = json.zones.map(z => new PogoZone(z));
        }
    }

    property_name: string;
    property_link: string;
    partner_setup_link: string;
    map_link: string;
    zones: PogoZone[];
}
