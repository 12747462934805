import { Component, Input } from '@angular/core';

@Component({
    selector: 'toro-widget-link-button',
    templateUrl: './widget-link-button.component.html',
    styleUrls: ['./widget-link-button.component.less']
})
export class WidgetLinkButtonComponent {
    @Input() href: string;
    @Input() imageSrc: string;
    @Input() altText = '';
    @Input() tooltipText: string;
    @Input() tooltipPosition = 'top';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

}
