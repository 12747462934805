<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [lastUpdated]="lastUpdated"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [analyticsWidgetName]="analyticsWidgetName"
        [(alertText)]="alertText"
        [(isUnableToFetchData)]="isUnableToFetchData"
>

    <div id="toro-lynx-pump-stations-widget-content">
        <div *ngIf="displayCols === 1 && pumpStationsRaw?.length !== 1" class="toro-widget-content-pane toro-size-one toro-component-shield-host" [ngClass]="{'fade-on-resize': isResizing}">

            <div *ngIf="pumpStationsRaw?.length === 0" class="lps-no-data">
                {{'STRINGS.NO_PUMP_STATION_DATA' | translate }}
            </div>

            <div id="lps-table-container" *ngIf="pumpStationsRaw?.length > 0">
                <p-table *ngIf="this.pumpStationsConverted && this.pumpStationsRaw.length > 0"
                         [value]="pumpStationsConverted"
                         [scrollable]="true"
                         scrollHeight="flex"
                         rowHover="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'Pump' | translate | titlecase}}</th>
                            <th class="lps-table-pressure-col">{{'Pressure' | translate | titlecase}}<br><span class="lps-table-cell-units">({{pressureUnits}})</span></th>
                            <th class="lps-table-flow-col">{{'Flow' | translate | titlecase}}<br><span class="lps-table-cell-units">({{flowUnits}})</span></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pump>
                        <tr [pSelectableRow]="pump" (click)="onRowSelect($event, pump)" >
                            <td class="lps-table-name-cell">{{pump.name}}</td>
                            <td class="lps-table-pressure-col lps-table-num-cell" [ngClass]="pump.pressureAlertClass" [pTooltip]="pump.pressureAlertTooltip">
                                <div class="lps-table-value-container" [ngClass]="{'has-alert': pump.pressureAlertClass != null}">
                                    <fa-icon *ngIf="pump.pressureAlertClass" icon="exclamation"></fa-icon>
                                    <span>{{pump.pressure}}</span>
                                </div>
                            </td>
                            <td class="lps-table-flow-col lps-table-num-cell" [ngClass]="pump.flowAlertClass" [pTooltip]="pump.flowAlertTooltip">
                                <div class="lps-table-value-container" [ngClass]="{'has-alert': pump.flowAlertClass != null}">
                                    <fa-icon *ngIf="pump.flowAlertClass" icon="exclamation"></fa-icon>
                                    <span>{{pump.flow}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <div *ngIf="displayCols > 1 || pumpStationsRaw?.length === 1" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
            <div id="toro-lps-gauge-list-container">
                <div id="toro-lps-gauge-list" class="toro-component-shield-host">
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[0]" [pumpStation]="pumpStationsRaw[0]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[1]" [pumpStation]="pumpStationsRaw[1]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[2]" [pumpStation]="pumpStationsRaw[2]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[3]" [pumpStation]="pumpStationsRaw[3]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[4]" [pumpStation]="pumpStationsRaw[4]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[5]" [pumpStation]="pumpStationsRaw[5]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[6]" [pumpStation]="pumpStationsRaw[6]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[7]" [pumpStation]="pumpStationsRaw[7]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[8]" [pumpStation]="pumpStationsRaw[8]"></toro-lynx-pump-station-gauge>
                    <toro-lynx-pump-station-gauge *ngIf="pumpStationsRaw && pumpStationsRaw[9]" [pumpStation]="pumpStationsRaw[9]"></toro-lynx-pump-station-gauge>
                </div>
            </div>
        </div>

    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<!-- Dialogs -->
<toro-pump-station-details-dlg *ngIf="showInfoDialog" [pumpStation]="selectedPump" (closeDialog)="showInfoDialog = false"></toro-pump-station-details-dlg>



