import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;
import SoilScoutThresholdTab = ToroEnums.SoilScoutThresholdTab;
import TemperatureUnits = ToroEnums.TemperatureUnits;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { SoilScoutGaugeRange } from '../models/soil-scout-gauge-range.model';
import { SoilScoutGaugeRangeConfig } from '../models/soil-scout-gauge-range-config.model';
import { SoilScoutThresholdChange } from '../models/soil-scout-threshold-change.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-thresholds-dlg',
    templateUrl: './soil-scout-thresholds-dlg.component.html',
    styleUrls: ['./soil-scout-thresholds-dlg.component.less']
})
export class SoilScoutThresholdsDlgComponent implements OnInit {
    @Output() cancelClick = new EventEmitter();
    @Output() updateRanges = new EventEmitter<SoilScoutThresholdChange>();

    @Input() defaultMoistureRange: SoilScoutGaugeRange;
    @Input() defaultSalinityRange: SoilScoutGaugeRange;
    @Input() defaultTemperatureRange: SoilScoutGaugeRange;
    @Input() userPreferences: DashUserPreferences;
    @Input() selectedTab = SoilScoutThresholdTab.Moisture;

    // In Fahrenheit
    private readonly TEMP_MIN = -40;
    private readonly TEMP_MAX = 120;

    private _moistureRange: SoilScoutGaugeRange;
    set moistureRange(value: SoilScoutGaugeRange) {
        this._moistureRange = value;
        if (this.isComponentInitialized) { this.moistureRangeChanged = true; }
    }

    get moistureRange(): SoilScoutGaugeRange {
        return this._moistureRange;
    }

    private _salinityRange: SoilScoutGaugeRange;
    set salinityRange(value: SoilScoutGaugeRange) {
        this._salinityRange = value;
        if (this.isComponentInitialized) { this.salinityRangeChanged = true; }
    }

    get salinityRange(): SoilScoutGaugeRange {
        return this._salinityRange;
    }

    private _temperatureRange: SoilScoutGaugeRange;
    set temperatureRange(value: SoilScoutGaugeRange) {
        this._temperatureRange = value;
        if (this.isComponentInitialized) { this.temperatureRangeChanged = true; }
    }

    get temperatureRange(): SoilScoutGaugeRange {
        return this._temperatureRange;
    }

    protected salinitySuffix: string;
    protected temperatureSuffix: string;
    protected tempRangeMin = this.TEMP_MIN;
    protected tempRangeMax = this.TEMP_MAX;
    protected isTempCelsius = false;

    private isComponentInitialized = false;
    private moistureRangeChanged = false;
    private salinityRangeChanged = false;
    private temperatureRangeChanged = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.moistureRange = this.updateRangesFromPreferences(SoilScoutSensorType.Moisture, this.defaultMoistureRange);
        this.salinityRange = this.updateRangesFromPreferences(SoilScoutSensorType.Salinity, this.defaultSalinityRange);
        this.temperatureRange = this.updateRangesFromPreferences(SoilScoutSensorType.Temperature, this.defaultTemperatureRange);

        if (this.userPreferences.temperatureUnits === TemperatureUnits.Celsius) {
            this.isTempCelsius = true;
            this.temperatureRange = this.convertRangeToCelsius(this.temperatureRange);
        }

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.salinitySuffix = this.userFormatService.salinityUnits;
                this.temperatureSuffix = this.userFormatService.temperatureUnits;
                this.tempRangeMin = <number>this.userFormatService.temperature(this.TEMP_MIN);
                this.tempRangeMax = <number>this.userFormatService.temperature(this.TEMP_MAX);
            });

        this.salinitySuffix = ` ${this.userFormatService.salinityUnits}`;
        this.temperatureSuffix = ` °${this.userFormatService.temperatureUnits}`;
        this.tempRangeMin = <number>this.userFormatService.temperature(this.TEMP_MIN);
        this.tempRangeMax = <number>this.userFormatService.temperature(this.TEMP_MAX);

        setTimeout(() => this.isComponentInitialized = true, 500);
    }

    private updateRangesFromPreferences(sensorType: SoilScoutSensorType, defaultRange: SoilScoutGaugeRange): SoilScoutGaugeRange {
        let rangeConfig: SoilScoutGaugeRangeConfig;
        let updatedRange = new SoilScoutGaugeRange(defaultRange);

        switch (sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                rangeConfig = this.userPreferences.ssMoistureRange;
                break;
            case ToroEnums.SoilScoutSensorType.Salinity:
                rangeConfig = this.userPreferences.ssSalinityRange;
                break;
            case ToroEnums.SoilScoutSensorType.Temperature:
                rangeConfig = this.userPreferences.ssTemperatureRange;
                break
        }

        if (rangeConfig != null) {
            updatedRange.rangeMin = rangeConfig.rangeMin;
            updatedRange.range1UpperBoundary = rangeConfig.range1UpperBoundary;
            updatedRange.range2UpperBoundary = rangeConfig.range2UpperBoundary
            updatedRange.range3UpperBoundary = rangeConfig.range3UpperBoundary
            updatedRange.rangeMax = rangeConfig.rangeMax;
        }

        return updatedRange;
    }

    private convertRangeToCelsius(fahrenheitRange: SoilScoutGaugeRange) {
        return new SoilScoutGaugeRange({
            ...fahrenheitRange,
            rangeMin: this.userFormatService.temperature(fahrenheitRange.rangeMin),
            range1UpperBoundary: this.userFormatService.temperature(fahrenheitRange.range1UpperBoundary),
            range2UpperBoundary: this.userFormatService.temperature(fahrenheitRange.range2UpperBoundary),
            range3UpperBoundary: this.userFormatService.temperature(fahrenheitRange.range3UpperBoundary),
            rangeMax: this.userFormatService.temperature(fahrenheitRange.rangeMax)
        })
    }

    private convertRangeToFahrenheit(celsiusRange: SoilScoutGaugeRange) {
        return new SoilScoutGaugeRange({
            ...celsiusRange,
            rangeMin: this.userFormatService.convertCelsiusToFahrenheit(celsiusRange.rangeMin),
            range1UpperBoundary: this.userFormatService.convertCelsiusToFahrenheit(celsiusRange.range1UpperBoundary),
            range2UpperBoundary: this.userFormatService.convertCelsiusToFahrenheit(celsiusRange.range2UpperBoundary),
            range3UpperBoundary: this.userFormatService.convertCelsiusToFahrenheit(celsiusRange.range3UpperBoundary),
            rangeMax: this.userFormatService.convertCelsiusToFahrenheit(celsiusRange.rangeMax)
        })
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onUpdateThresholds() {
        if (!this.moistureRangeChanged && !this.salinityRangeChanged && !this.temperatureRangeChanged) {
            this.onCancelDialog();
            return;
        }

        const change = new SoilScoutThresholdChange({
            moistureGaugeRange: this.moistureRangeChanged ? this.moistureRange : null,
            salinityGaugeRange: this.salinityRangeChanged ? this.salinityRange : null,
            temperatureGaugeRange: this.temperatureRangeChanged ? ( !this.isTempCelsius ? this.temperatureRange : this.convertRangeToFahrenheit(this.temperatureRange)) : null
        })

        this.updateRanges.emit(change);
    }

    onCancelDialog() {
        this.cancelClick.emit();
    }
}
