import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { PerryWeatherApiService } from './perry-weather-api.service';
import { PerryWeatherConfig } from './models/perry-weather-config.model';
import { PwForecast } from './models/pw-forecast.model';
import { PwHistorical } from './models/pw-historical.model';
import { PwHistoricalEt } from './models/pw-historical-et.model';
import { PwLocation } from './models/pw-location.model';
import { PwObservation } from './models/pw-observation.model';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class PerryWeatherManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private perryWeatherApiService: PerryWeatherApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getPerryWeatherConfig(toroGridsterWidget: ToroGridsterWidget): Observable<PerryWeatherConfig> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.PerryWeatherConfig); }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isPerryWeatherConfig')) {
            return of(<PerryWeatherConfig>toroGridsterWidget.config);
        }

        const config = new PerryWeatherConfig(toroGridsterWidget.config);
        return of(config);
    }

    getLocation(locationId: number): Observable<PwLocation> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.perryWeatherLocation); }

        return this.perryWeatherApiService.getLocation(locationId);
    }

    createLocation(location: PwLocation): Observable<number> {
        return this.perryWeatherApiService.createLocation(location);
    }

    deleteLocation(locationId: number): Observable<void> {
        return this.perryWeatherApiService.deleteLocation(locationId);
    }

    getObservation(locationId: number): Observable<PwObservation> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.perryWeatherObservation); }

        return this.perryWeatherApiService.getObservation(locationId);
    }

    // Date is expected to be UTC
    getForecast(locationId: number, date: Date, days = 0, hours = 0): Observable<PwForecast> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getPerryWeatherForecast(days)); }

        if ((hours == null || hours < 0) && (days == null || days <= 0)) throw new Error('Hours and Days parameters cannot both be less than 1')

        return this.perryWeatherApiService.getForecast(locationId, date, days, hours);
    }

    getHistorical(locationId: number, startDate: Date, days = 0, hours = 0): Observable<PwHistorical> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.perryWeatherHistorical); }

        if ((hours == null || hours < 0) && (days == null || days <= 0)) throw new Error('Hours and Days parameters cannot both be less than 1')

        return this.perryWeatherApiService.getHistorical(locationId, startDate, days, hours);
    }

    getHistoricalEt(locationId: number, fromDate: Date, toDate: Date): Observable<PwHistoricalEt> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.perryWeatherHistoricalEt); }

        return this.perryWeatherApiService.getHistoricalEt(locationId, fromDate, toDate);
    }

}
