import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { TurfGuardHole } from './turf-guard-hole.model';
import { TurfGuardHoleLimit } from './turf-guard-hole-limit.model';

import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

enum TurfGuardHoleField {
    avgMoisture = 'avgMoisture',
    avgTemperature = 'avgTemperature',
    avgSalinity = 'avgSalinity',
    moisture = 'moisture',
    temperature = 'temperature',
    salinity = 'salinity'
}

export class TurfGuardWidgetData {
    private readonly SALINITY_LOWER_ALERT_LEVEL = 0.4;
    private readonly SALINITY_UPPER_ALERT_LEVEL = 1.4;

    private readonly sensorType: TurfGuardSensorType;
    private turfGuardHoles: TurfGuardHole[];
    private _averageCourseValue: number;
    private _minValue: number;
    private _maxValue: number;
    private _highAlertsCount: number;
    private _lowAlertsCount: number;
    private _lowAlertLevel: number;
    private _highAlertLevel: number;

    private averageField = '';
    private sensorTypeField = '';

    lastUpdated: Date;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(sensorType: TurfGuardSensorType, turfGuardHoles: TurfGuardHole[]) {
        this.sensorType = sensorType;
        this.turfGuardHoles = turfGuardHoles;

        if (turfGuardHoles && turfGuardHoles.length > 0) this.lastUpdated = turfGuardHoles[0].lastUpdated;

        this.setPropertyFieldNames(sensorType);
    }

    get averageCourseValue(): number {
        if (!this._averageCourseValue) {
            if (!this.turfGuardHoles || this.turfGuardHoles.length < 1) { return -1; }

            this._averageCourseValue = +(this.turfGuardHoles.reduce((sum, h) => sum += h[this.averageField], 0) / this.turfGuardHoles.length).toFixed(2);
        }
        return this._averageCourseValue;
    }

    get minValue(): number {
        if (!this._minValue) {
            if (!this.turfGuardHoles || this.turfGuardHoles.length < 1) { return -1; }

            this._minValue = this.turfGuardHoles.reduce((prev, curr) => {
                return prev.limits[this.sensorTypeField].low < curr.limits[this.sensorTypeField].low ? prev : curr;
            }).limits[this.sensorTypeField].low;

            if (this._minValue < 0 && this.sensorType !== TurfGuardSensorType.Temperature) { this._minValue = 0; }
        }
        return this._minValue;
    }

    get maxValue(): number {
        if (!this._maxValue) {
            if (!this.turfGuardHoles || this.turfGuardHoles.length < 1) { return -1; }

            this._maxValue = this.turfGuardHoles.reduce((prev, curr) => {
                return prev.limits[this.sensorTypeField].high > curr.limits[this.sensorTypeField].high ? prev : curr;
            }).limits[this.sensorTypeField].high;
        }
        return this._maxValue;
    }

    get highAlertsCount(): number {
        if (!this._highAlertsCount) {
            if (!this.turfGuardHoles || this.turfGuardHoles.length < 1) { return -1; }

            this._highAlertsCount = +this.turfGuardHoles.reduce((sum, h) => sum += h.highAlerts[this.sensorTypeField], 0).toFixed(2);
        }
        return this._highAlertsCount;
    }

    get lowAlertsCount(): number {
        if (!this._lowAlertsCount) {
            if (!this.turfGuardHoles || this.turfGuardHoles.length < 1) { return -1; }

            this._lowAlertsCount = +this.turfGuardHoles.reduce((sum, h) => sum += h.lowAlerts[this.sensorTypeField], 0).toFixed(2);
        }
        return this._lowAlertsCount;
    }

    get lowAlertLevel(): number {
        if (this._lowAlertLevel == null) {
            switch (this.sensorType) {
                case TurfGuardSensorType.Salinity:
                    this._lowAlertLevel = this.SALINITY_LOWER_ALERT_LEVEL;
                    break;
                case TurfGuardSensorType.Moisture:
                case TurfGuardSensorType.Temperature:
                default:
                    this._lowAlertLevel = this._minValue + ((this._maxValue - this._minValue + 1) * .25);
                    break;
            }
        }
        return this._lowAlertLevel;
    }

    get highAlertLevel(): number {
        if (this._highAlertLevel == null) {
            switch (this.sensorType) {
                case TurfGuardSensorType.Salinity:
                    this._highAlertLevel = this.SALINITY_UPPER_ALERT_LEVEL;
                    break;
                case TurfGuardSensorType.Moisture:
                case TurfGuardSensorType.Temperature:
                default:
                    this._highAlertLevel = this._maxValue - ((this._maxValue - this._minValue + 1) * .25);
                    break;
            }
        }
        return this._highAlertLevel;
    }

    getZoneNumber(holeIndex: number) {
        if (!this.turfGuardHoles || holeIndex < 0 || holeIndex > this.turfGuardHoles.length - 1) { return null; }
        return this.turfGuardHoles[holeIndex].number;
    }

    getHoleByIndex(holeIndex: number) {
        if (!this.turfGuardHoles || holeIndex < 0 || holeIndex > this.turfGuardHoles.length - 1) { return null; }
        return this.turfGuardHoles[holeIndex];
    }

    get holesByName(): TurfGuardHole[] {
        return this.turfGuardHoles.sort((a, b) => a.name.localeCompare(b.name));
    }

    getHolesWithAlerts(sensorType: TurfGuardSensorType): TurfGuardHole[] {
        return this.turfGuardHoles.filter(h => h.hasAlert(sensorType));
    }

    get holesByZone(): TurfGuardHole[] {
        return this.turfGuardHoles.sort((a, b) => a.number - b.number);
    }

    get holesCount(): number {
        return this.turfGuardHoles.length;
    }

    get holeNames(): string[] {
        return this.sortedTurfGuardHoles.map(h => h.name);
    }

    get chartDataValues(): number[] {
        // By default, sort values by zone#.
        return this.sortedTurfGuardHoles.map(h => h[this.averageField]);
    }

    get chartDataLimits(): TurfGuardHoleLimit[] {
        return this.sortedTurfGuardHoles.map(h => h.limits[this.sensorTypeField]);
    }

    get chartDataHighAlerts(): number[] {
        return this.sortedTurfGuardHoles.map(h => h.highAlerts[this.sensorTypeField]);
    }

    get chartDataLowAlerts(): number[] {
        return this.sortedTurfGuardHoles.map(h => h.lowAlerts[this.sensorTypeField]);
    }

    get holeIds(): number[] {
        return this.sortedTurfGuardHoles.map(h => h.id);
    }

    get zoneNumbers(): number[] {
        return this.sortedTurfGuardHoles.map(h => h.number);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get sortedTurfGuardHoles(): TurfGuardHole[] {
        return this.turfGuardHoles.sort((a, b) => a.number - b.number);
    }

    private setPropertyFieldNames(sensorType: TurfGuardSensorType) {
        switch (sensorType) {
            case TurfGuardSensorType.Moisture:
                this.averageField = TurfGuardHoleField.avgMoisture;
                this.sensorTypeField = TurfGuardHoleField.moisture;
                break;
            case TurfGuardSensorType.Temperature:
                this.averageField = TurfGuardHoleField.avgTemperature;
                this.sensorTypeField = TurfGuardHoleField.temperature;
                break;
            case TurfGuardSensorType.Salinity:
                this.averageField = TurfGuardHoleField.avgSalinity;
                this.sensorTypeField = TurfGuardHoleField.salinity;
                break;
        }
    }

}
