import * as moment from 'moment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import GreenSightViewer = ToroEnums.GreenSightViewer;
import { KittyHawkFavorite } from './kitty-hawk-favorite.model';
import { KittyHawkLatLng } from './kitty-hawk-lat-lng.model';

export class KittyHawkConfig {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.date) this.date = moment.utc(json.date).toDate();
            if (json.center) this.center = new KittyHawkLatLng(json.center);
            if (json.favoriteLocations) this.favoriteLocations = json.favoriteLocations.map(l => new KittyHawkFavorite(l));
        }
    }

    date = new Date();
    center: KittyHawkLatLng;
    zoom = 16;
    layer = 'None';
    view = GreenSightViewer.Course;
    favoriteLocations: KittyHawkFavorite[] = [];

}
