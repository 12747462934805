import { TaskTrackerCompanyDepartment } from './task-tracker-company-department.model';

export class TaskTrackerCompany {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.expires) { this.expires = new Date(json.expires); }
            if (json.departments) { this.departments = json.departments.map(d => new TaskTrackerCompanyDepartment(d)); }
        }
    }

    name: string;
    id: number;
    expires: Date;
    uuid: string;
    departments: any[] = [];

}
