import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AhSnapComponent } from './root-components/ah-snap/ah-snap.component';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './root-components/app-footer/app-footer.component';
import { AppHeaderComponent } from './root-components/app-header/app-header.component';
import { AppInitializerService } from './common/services/app-initializer.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './api/auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { DatePipe } from '@angular/common';
import { DemoComponent } from './root-components/demo/demo.component';
import { DemoModule } from './demo/demo.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { NotificationCardComponent } from './root-components/notification-panel/notification-card/notification-card.component';
import { NotificationPanelComponent } from './root-components/notification-panel/notification-panel.component';
import { NsnErrorComponent } from './root-components/nsn-error/nsn-error.component';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserFeedbackDlgComponent } from './root-components/user-feedback-dlg/user-feedback-dlg.component';
import { UserPreferencesDlgComponent } from './root-components/user-preferences-dlg/user-preferences-dlg.component';
import { VideoPanelCardComponent } from './root-components/video-panel/video-panel-card/video-panel-card.component';
import { VideoPanelComponent } from './root-components/video-panel/video-panel.component';
import { VideoPanelModalComponent } from './root-components/video-panel/video-panel-modal/video-panel-modal.component';
import { WelcomeComponent } from './root-components/welcome/welcome.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', `${environment.i18nCacheBuster}.json`);
}

export function appInitializerFactory(provider: AppInitializerService) {
    return () => provider.load();
}

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: environment.lynxCloudHost,
    port: environment.lynxCloudPort,
    protocol: 'wss',
    connectOnCreate: false
    // path: environment.lynxCloudPath
};

@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        WelcomeComponent,
        AhSnapComponent,
        UserPreferencesDlgComponent,
        NotificationPanelComponent,
        NotificationCardComponent,
        UserFeedbackDlgComponent,
        NsnErrorComponent,
        DemoComponent,
        VideoPanelComponent,
        VideoPanelModalComponent,
        VideoPanelCardComponent,
    ],
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        SharedModule,
        DemoModule,
        DeviceDetectorModule.forRoot(),
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        CoreModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [AppInitializerService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
