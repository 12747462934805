<p-dialog [visible]="true" appendTo="body" [modal]="true" [resizable]="false" [draggable]="false"
          [showHeader]="false" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="toro-setup-wizard"
>

    <div id="toro-wizard-container">

        <div id="wiz-left-col" class="wiz-col" [ngClass]="{'is-do-setup': isDoSetup}">
            <div id="wiz-left-header">
                <img src="/assets/images/toro-intellidash-logo_r.png" alt="Toro">
                <div id="wiz-title">
                    <img src="/assets/images/wizard/sparkle.png" alt="sparkle">
                    <span>{{'STRINGS.SETUP_WIZARD' | translate | titlecase}}</span>
                </div>
            </div>
            <div id="wiz-left-body">
                <img src="/assets/images/wizard/widgets.png" alt="widgets">
            </div>
            <div id="wiz-left-footer">
                <div id="wiz-step">{{'STRINGS.STEP' | translate | titlecase}} {{stepIndex + 1}}</div>
                <div id="wiz-step-title">{{steps[stepIndex].sidebarTitle | translate}}</div>
                <div id="wiz-step-description">{{steps[stepIndex].sidebarDescription | translate}}</div>
                <div id="wiz-step-tracker">
                    <div class="wiz-step-indicator" *ngFor="let s of steps; let i = index" [ngClass]="{'has-stepped': stepIndex >= i}"></div>
                </div>
            </div>
        </div>

        <div id="wiz-right-col" class="wiz-col">
            <div id="wiz-right-header">
                <div id="wiz-step-detail-title">
                    {{steps[stepIndex].contentTitle | translate}}
                </div>
                <div id="wiz-step-detail-description">
                    {{steps[stepIndex].contentDescription | translate}}
                </div>
            </div>
            <div id="wiz-right-body">
                <div *ngIf="steps.length > 0" id="wiz-step-content-container">
                    <ng-container *ngIf="stepIndex === 0" [ngTemplateOutlet]="stepIntegrations"></ng-container>
                    <ng-container *ngIf="stepIndex === 1" [ngTemplateOutlet]="stepEnableWidgets"></ng-container>
                    <ng-container *ngIf="stepIndex === 2" [ngTemplateOutlet]="stepCompleteSetup"></ng-container>
                </div>
            </div>

            <div *ngIf="!isDoSetup" class="wiz-right-footer">
                <div>
                    <div *ngIf="stepIndex > 0" class="wiz-button wiz-button-secondary" (click)="onPreviousStep()" [ngClass]="{'is-disabled': stepIndex <= 0}">
                        <fa-icon icon="arrow-left"></fa-icon>{{'STRINGS.PREVIOUS_STEP' | translate | uppercase}}
                    </div>
                </div>
                <div *ngIf="stepIndex < steps.length - 1" class="wiz-button-group">
                    <div class="wiz-button wiz-button-primary" (click)="onNextStep()" [ngClass]="{'is-disabled': !isConnectionCheckComplete || stepIndex === steps.length - 1}">
                        {{'STRINGS.NEXT_STEP' | translate | uppercase}}<fa-icon icon="arrow-right"></fa-icon>
                    </div>
                    <div class="wiz-button wiz-text-button" (click)="onSkip()">{{'STRINGS.CANCEL' | translate | uppercase}}</div>
                </div>
                <div *ngIf="stepIndex === steps.length - 1" class="wiz-button wiz-button-primary" (click)="onFinish()">{{'STRINGS.FINISH_SETUP' | translate | uppercase}}</div>
            </div>

            <div *ngIf="isDoSetup" class="wiz-right-footer">
                <div>
                    <div class="wiz-button wiz-button-secondary" (click)="onBackClick()">
                        <fa-icon icon="arrow-left"></fa-icon>{{'STRINGS.BACK' | translate | uppercase}}
                    </div>
                </div>
                <div class="wiz-button wiz-button-primary" (click)="onConnect()" [ngClass]="{'is-disabled': !canConnect}">{{'STRINGS.CONNECT' | translate | uppercase}}</div>
            </div>
        </div>

    </div>

</p-dialog>


<ng-template #stepIntegrations>
    <toro-step-integrations
            [isConnectionCheckComplete]="isConnectionCheckComplete"
            [(selectedWidgets)]="selectedWidgets"
            [(isDoSetup)]="isDoSetup"
            (canConnect)="onCanConnect($event)"
            (connectionCheckComplete)="onConnectionCheckComplete($event)"
    ></toro-step-integrations>
</ng-template>

<ng-template #stepEnableWidgets>
    <toro-step-enabling [(selectedWidgets)]="selectedWidgets"></toro-step-enabling>
</ng-template>

<ng-template #stepCompleteSetup>
    <toro-step-faq></toro-step-faq>
</ng-template>


<p-dialog *ngIf="showCancelPrompt" [visible]="true" appendTo="body" [modal]="true" [resizable]="false" [draggable]="false"
          [showHeader]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}" styleClass="wiz-cancel-dlg"
>
    <div id="wiz-cancel-dlg-container">
        <div id="wiz-cancel-header">
            {{'SPECIAL_MSG.WIZARD_CANCEL_SETUP_HEADER' | translate}}
        </div>

        <div id="wiz-cancel-body">
            {{'SPECIAL_MSG.WIZARD_CANCEL_SETUP_PROMPT' | translate}}
        </div>

        <div id="wiz-cancel-footer">
            <div class="wiz-button wiz-button-secondary" (click)="showCancelPrompt = !showCancelPrompt">{{'STRINGS.CANCEL' | translate | uppercase}}</div>
            <div class="wiz-button wiz-button-primary" (click)="onCancelWizard()">{{'STRINGS.CONFIRM' | translate | uppercase}}</div>
        </div>
    </div>
</p-dialog>
