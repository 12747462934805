import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EnableWidgetGroup } from '../../models/enable-widget-group.model';

@Component({
  selector: 'toro-enable-widget-group',
  templateUrl: './enable-widget-group.component.html',
  styleUrls: ['./enable-widget-group.component.less']
})
export class EnableWidgetGroupComponent implements OnInit {
  @HostBinding('class') class = 'toro-enable-widget-group';

  @Input() widgetGroup: EnableWidgetGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
