import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { ClubListEntry } from './models/club-list-entry.model';
import { CourseListEntry } from './models/course-list-entry.model';
import { DailyPinSheet } from './models/daily-pin-sheet.model';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { EzLocatorAccessId } from './models/ez-locator-access-id.model';
import { EzLocatorApiService } from './ez-locator-api.service';
import { EzLocatorConfig } from './models/ez-locator-config.model';
import { GreenImageEntry } from './models/green-image-entry.model';
import { Injectable } from '@angular/core';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class EzLocatorManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private ezLocatorApiService: EzLocatorApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getAccessId(userName: string): Observable<EzLocatorAccessId> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.ezLocatorAccessId); }

        return this.ezLocatorApiService.getAccessId(userName);
    }

    getEzLocatorConfig(toroGridsterWidget: ToroGridsterWidget): Observable<EzLocatorConfig> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.ezLocatorConfig); }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isEzLocatorConfig')) {
            return of(<EzLocatorConfig>toroGridsterWidget.config);
        }

        const config = new EzLocatorConfig(toroGridsterWidget.config);
        return of(config);
    }

    getPinSheetData(courseAccessId: string, imageSize: number): Observable<DailyPinSheet[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.ezLocatorPinSheetData); }

        return this.ezLocatorApiService.getPinSheetData(courseAccessId, imageSize);
    }

    getGreenImages(courseAccessId: string, imageSize: number): Observable<GreenImageEntry[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.ezLocatorGreenImages); }

        return this.ezLocatorApiService.getGreenImages(courseAccessId, imageSize);
    }

    getClubList(): Observable<ClubListEntry[]> {
        return this.ezLocatorApiService.getClubList();
    }

    getCourseList(clientAccessId: string): Observable<CourseListEntry[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.ezLocatorCourseList); }

        return this.ezLocatorApiService.getCourseList(clientAccessId);
    }

}
