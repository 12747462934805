<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [disableWidgetAlertBanner]="true"
        [analyticsWidgetName]="analyticsWidgetName"
        [(alertText)]="alertText"
        [(isUnableToFetchData)]="isUnableToFetchData"
>

    <div id="green-sight-widget-container" class="toro-flex-child">
        <div *ngIf="hasActiveGsaAccount" id="gsw-toolbar">
            <div id="gsw-toolbar-group">
                <p-dropdown id="gsw-viewer_selector" [options]="viewerOptions" [(ngModel)]="selectedView"></p-dropdown>
                <p-calendar id="gsw-date-picker" [(ngModel)]="selectedDate" [minDate]="minDate" [maxDate]="maxDate"
                            [disabledDates]="invalidDates" [readonlyInput]="true" [showIcon]="true" [ngClass]="{'isMobile': displayCols === 1}">
                </p-calendar>
                <p-dropdown *ngIf="selectedView !== GreenSightViewer.Course && layerOptions?.length > 0" id="gsw-layer_selector"
                            [placeholder]="'STRINGS.SELECT_OVERLAY' | translate | titlecase"
                            [options]="layerOptions"
                            [(ngModel)]="selectedLayerName"
                ></p-dropdown>
            </div>
        </div>

        <div *ngIf="hasActiveGsaAccount && selectedView === GreenSightViewer.Course && leafletOptions" id="gsw-map-container" leaflet
             [leafletOptions]="leafletOptions"
             [leafletLayersControl]="layersControl"
             [leafletLayers]="layers"
             (leafletMapReady)="onMapReady($event)"
             (leafletMapMoveEnd)="onMapMoveEnd($event)"
             (leafletMapZoomEnd)="onMapZoomEnd($event)">
        </div>
        <leaflet-fullscreen-control #fullscreenControl [map]="fullScreenMap" [options]="fullscreenOptions"></leaflet-fullscreen-control>

        <div *ngIf="hasActiveGsaAccount && selectedView === GreenSightViewer.Greens" class="gsw-mini-map-container">
            <div class="gsw-mini-map-scroll-container">
                <toro-green-site-mini-map
                        *ngFor="let green of kittyHawkGreens"
                        [attribution]="green.name"
                        [latitude]="green.latitude"
                        [longitude]="green.longitude"
                        [zoom]="green.zoom"
                        [selectedDate]="selectedDate"
                        [missions]="kittyHawkMissions"
                        [layerName]="kittyHawkConfig.layer"
                        (mapClick)="onMiniMapClick($event, GreenSightViewer.Greens)"
                ></toro-green-site-mini-map>
            </div>
        </div>

        <div *ngIf="hasActiveGsaAccount && selectedView === GreenSightViewer.Favorites" class="gsw-mini-map-container">
            <div class="gsw-mini-map-scroll-container">
                <toro-green-site-mini-map
                        *ngFor="let fav of kittyHawkConfig?.favoriteLocations"
                        [mapId]="fav.id"
                        [attribution]="fav.name"
                        [latitude]="fav.center.lat"
                        [longitude]="fav.center.lng"
                        [zoom]="fav.zoom"
                        [selectedDate]="selectedDate"
                        [missions]="kittyHawkMissions"
                        [layerName]="kittyHawkConfig.layer"
                        [canDeleteMap]="true"
                        (mapClick)="onMiniMapClick($event, GreenSightViewer.Favorites)"
                        (deleteMap)="onDeleteFavorite($event)"
                ></toro-green-site-mini-map>
            </div>
        </div>

        <toro-add-favorite-location-dlg
                *ngIf="showFavoritesDialog" style="z-index: 400;"
                (cancel)="onCancelAddFavorite()"
                (save)="onSaveFavorite($event)">
        </toro-add-favorite-location-dlg>

        <p-confirmDialog #cd [header]="'STRINGS.DELETE_CONFIRMATION_TITLE' | translate | titlecase" icon="pi pi-exclamation-triangle">
            <p-footer>
                <button type="button" pButton [label]="'STRINGS.NO' | translate | titlecase" (click)="cd.reject()"></button>
                <button type="button" pButton [label]="'STRINGS.YES' | translate | titlecase" (click)="cd.accept()" class="ui-button-secondary"></button>
            </p-footer>
        </p-confirmDialog>

        <toro-account-issue *ngIf="!hasActiveGsaAccount" [helpLine]="'TORO.HELP_LINE'" [accountName]="'ACCOUNT.GREEN_SIGHT'"></toro-account-issue>
    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>
