
export class SpecSubCollectionSummary {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    avgMoisture: number;
    highMoisture: number;
    lowMoisture: number;
    rangeIndex: number;
    lowColor: string;
    showTooltip = false;
    showPopup = false;
}
