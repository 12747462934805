<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onGotoSite();"
    >

        <div class="toro-task-tracker-measurements-widget-content mini-mode-widget-container">
            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tmm-tt-course-info" class="toro-mini-mode-content">
                        <i class="tmm-ul-icon toro-id-ruler"></i>
                        <div class="tmm-bot-left-container">
                            <div class="tmm-ellipsis-text">{{companyName}}</div>
                        </div>
                    </div>
                </div>
                <div id="tmm-tt-dm-value-container" class="toro-mini-mode-content-panel ">
                    <div id="tmm-tt-metric-container" class="toro-mini-mode-content">
                        <div class="tmm-large-value-label">{{miniModeMetric}}</div>
                        <div class="tmm-large-value">{{miniModeUserCurrentAvg}}</div>
                        <div class="tmm-large-value-label">{{miniModeMetricUnits}}</div>
                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [spinnerText]="spinnerText"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
    >

        <div class="toro-task-tracker-measurements-widget-content">
            <div class="toro-widget-content-pane ttl-size-one" [ngClass]="{'fade-on-resize': isResizing}">

                <div class="ttl-border-container ttl-so-top-container">
                    <toro-widget-link-button imageSrc="../../../../../assets/images/taskTrackerIcon_color.png" (click)="onTaskTrackerLinkClick()">
                    </toro-widget-link-button>

                    <div class="ttl-so-top-label toro-component-shield-host">
                        <span [ngClass]="language">{{'STRINGS.DAILY_MEASUREMENTS' | translate | uppercase}}</span>
                        <span *ngIf="showCompany" class="tt-st-summary-company">{{'STRINGS.COURSE' | translate}}: {{companyName}}</span>
                        <toro-component-shield></toro-component-shield>
                    </div>

                    <div class="ttl-dept-select-container">
                        <p-multiSelect
                                [options]="departmentsList"
                                [(ngModel)]="selectedDepartmentIds"
                                [styleClass]="'department-selector'"
                                [displaySelectedLabel]="true"
                                [selectedItemsLabel]="'STRINGS.N_DEPARTMENTS_SELECTED' | translate"
                                [appendTo]="'body'"
                                [defaultLabel]="'STRINGS.SELECT_DEPARTMENTS' | translate | titlecase"
                                (onPanelHide)="onDepartmentSelectionChange()"
                        ></p-multiSelect>
                    </div>

                    <div class="ttl-so-top-content">
                        <div class="tt-dm-list-container">
                            <toro-task-tracker-daily-measurement-item *ngFor="let dm of dailyMeasurements" [dailyMeasurement]="dm">
                            </toro-task-tracker-daily-measurement-item>
                        </div>

                        <div class="tt-dm-no-data" *ngIf="!dailyMeasurements || dailyMeasurements.length < 1">{{'STRINGS.NO_DAILY_MEASUREMENTS' | translate}}</div>
                    </div>
                </div>

            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-task-tracker-api-key-dialog
        *ngIf="isApiKeyDialogDisplayed"
        [apiKey]="newApiKey"
        (setApiKey)="onSetApiKey($event)"
        (cancelClick)="isApiKeyDialogDisplayed = false"
></toro-task-tracker-api-key-dialog>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" title="Mini Mode Modal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
