import { StationStatusItem } from '../../api/lynx-cloud/models/station-status/station-status-item.model';
import { StationStatusStation } from '../../api/lynx-cloud/models/station-status/station-status-station.model';

export class MockLynxCloudStationStatusItem extends StationStatusItem{
    constructor(json: any = null) {
        super(null, null, null);

        if (json) {
            Object.assign(this, json);

            if (json.station) this.station = new StationStatusStation(json.station);
        }
    }
}
