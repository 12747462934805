import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { environment } from '../../../../../../environments/environment';
import { EzLocatorManagerService } from '../../../../../api/ez-locator/ez-locator-manager.service';
import { StepIntegrationBase } from '../_step-integration-base';
import { take } from 'rxjs/operators';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@UntilDestroy()
@Component({
    selector: 'toro-ez-locator-integration',
    templateUrl: './ez-locator-integration.component.html',
    styleUrls: ['./ez-locator-integration.component.less']
})
export class EzLocatorIntegrationComponent extends StepIntegrationBase implements OnInit, OnDestroy {
    @HostBinding('class') class = 'wiz-integration-component';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();

    readonly stepsCount = 2;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private ezLocatorManager: EzLocatorManagerService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onNavigateToSite());

        this.canConnect.emit(true);
    }

    ngOnDestroy() {
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    onNavigateToSite() {
        // this.broadcastService.setupToggleWidgetState.next({type: WidgetType.EzLocator, isEnabled: true});
        // this.configured.next(WizardIntegration.EzLocator);

        const link = `/externalauth?username=${this.dashUserManager.userEmail}&apikey=${environment.ezLocatorApiKey}`
        this.navigateToEzLocatorUrl(`${environment.ezLocatorLinkUrl}${link}`)

        this.validateConnection();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private navigateToEzLocatorUrl(url: string): void {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_EZ_LOCATOR_SITE' });
        setTimeout(() => window.open(url, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    protected validateConnectionInternal() {
        this.ezLocatorManager.getAccessId(this.dashUserManager.userEmail)
            .pipe(take(1),)
            .subscribe({
                next: () => {
                    this.isConnected = true;
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.EzLocator, isEnabled: true });
                    this.configured.next(WizardIntegration.EzLocator);
                },
                error: () => this.validateConnection()
            })
    }

}
