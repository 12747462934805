import { environment } from '../../../../environments/environment';
import { TurfGuardSensorDetail } from './turf-guard-sensor-detail.model';
import { TurfGuardSensorLimits } from './turf-guard-sensor-limits.model';

export class TurfGuardSensor {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.limits) this.limits = new TurfGuardSensorLimits(!environment.isDemoMode ? JSON.parse(json.limits) : json.limits);
            if (json.moisture) this.moisture = new TurfGuardSensorDetail(!environment.isDemoMode ? JSON.parse(json.moisture) : json.moisture);
            if (json.salinity) this.salinity = new TurfGuardSensorDetail(!environment.isDemoMode ? JSON.parse(json.salinity) : json.salinity);
            if (json.temperature) this.temperature = new TurfGuardSensorDetail(!environment.isDemoMode ? JSON.parse(json.temperature) : json.temperature);
        }
    }

    id: any;
    holeNumber: number;
    limits: TurfGuardSensorLimits;
    moisture: TurfGuardSensorDetail;
    salinity: TurfGuardSensorDetail;
    sensorNumber: number;
    temperature: any;
}
