import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpecRangeCount } from '../models/spec-range-count.model';

@Component({
    selector: 'toro-spec-range-selector',
    templateUrl: './spec-range-selector.component.html',
    styleUrls: ['./spec-range-selector.component.less']
})
export class SpecRangeSelectorComponent implements OnInit {

    @Output() shieldClick = new EventEmitter();
    @Output() selectedIndexChange = new EventEmitter<number>();

    private _selectedIndex: number;
    @Input() set selectedIndex(value: number) {
        if (!this.allowSelection || value == this._selectedIndex) return;

        setTimeout(() => {
            this._selectedIndex = this.validateSelectedIndex(value);
            this.selectedIndexChange.emit(this._selectedIndex);
        });
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    @Input() showAll = false;
    @Input() allowSelection = false;

    private _rangeCounts: SpecRangeCount[] = [];
    @Input() set rangeCounts(value: SpecRangeCount[]) {
        this._rangeCounts = value;
        this.buttonFlexBasis = Math.floor(100 / (this.showAll ? this.rangeCounts.length + 1 : this.rangeCounts.length));

        // if (this.allowSelection && this.selectedIndex != null) {
        if (this.selectedIndex != null) {
            this.selectedIndex = this.validateSelectedIndex(this.selectedIndex);
        }
    }

    get rangeCounts(): SpecRangeCount[] {
        return this._rangeCounts;
    }

    protected buttonFlexBasis = 16;

    private validateSelectedIndex(index: number): number {
        if (this.allowSelection && (this.rangeCounts == null || index == null || this.rangeCounts[index]?.count > 0)) { return index }

        // Select first non-zero range.
        return this._rangeCounts.findIndex(rc => rc.count != 0);
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        if (!this.allowSelection) this.selectedIndex = -1;
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    onClick() {
        this.shieldClick.emit();
    }
}
