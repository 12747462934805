
export class LynxCloudCourseItem {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    installationId: string;
    courseId: number;
}
