import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { EnableWidget } from '../../models/enable-widget.model';

@Component({
    selector: 'toro-enable-widget-toggle',
    templateUrl: './enable-widget-toggle.component.html',
    styleUrls: ['./enable-widget-toggle.component.less']
})
export class EnableWidgetToggleComponent implements OnInit {
    @HostBinding('class') class = 'toro-enable-widget-toggle';

    @Input() widget: EnableWidget;

    private _isEnabled: boolean = undefined;
    set isEnabled(value: boolean) {
        this._isEnabled = value;
        this.broadcastService.setupToggleWidgetState.next({type: this.widget.type, isEnabled: value});
    }

    get isEnabled(): boolean {
        return this._isEnabled;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService) { }

    ngOnInit() {
        this._isEnabled = this.widget.isEnabled;
    }
}
