/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PlaybooksGddSinceAppRecord } from './models/playbooks-gdd-since-app-record.model';
import { PlaybooksLinkUrl } from './models/playbooks-link-url.model';
import { PlaybooksMonthlyReportMonth } from './models/playbooks-monthly-report-month.model';
import { PlaybooksPrintLogUrl } from './models/playbooks-print-log-url.model';
import { PlaybooksYearlyReport } from './models/playbooks-yearly-report.model';

@Injectable({
    providedIn: 'root'
})
export class PlaybooksApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getGDDSinceApp(): Observable<PlaybooksGddSinceAppRecord[]> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.gddInfoUrl, HttpMethod.Get)
            .pipe(map((result: PlaybooksGddSinceAppRecord[]) => result.map(r => new PlaybooksGddSinceAppRecord(r))));
    }

    getLinkUrls(): Observable<PlaybooksLinkUrl[]> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.linkUrlsUrl, HttpMethod.Get)
            .pipe(map((result: PlaybooksLinkUrl[]) => result.map(u => new PlaybooksLinkUrl(u))));
    }

    getLogsToPrint(): Observable<PlaybooksPrintLogUrl[]> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.printUrls, HttpMethod.Get)
            .pipe(map((result: PlaybooksPrintLogUrl[]) => result.map(r => new PlaybooksPrintLogUrl(r))));
    }

    getMonthlyReport(): Observable<PlaybooksMonthlyReportMonth[]> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.monthlyDataUrl, HttpMethod.Get)
            .pipe(map((result: PlaybooksMonthlyReportMonth[]) => result.map(r => new PlaybooksMonthlyReportMonth(r))));
    }

    getYearlyReport(): Observable<PlaybooksYearlyReport> {
        // Shape changed quite dramatically. Missing Alerts counts
        return this.apiService.apiRequest<any>(PlaybooksApiService.yearlyReportUrl, HttpMethod.Get)
            .pipe(map((result: PlaybooksYearlyReport) => new PlaybooksYearlyReport(result)));
    }

    processPlaybooksData(): Observable<any> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.processDataUrl, HttpMethod.Get);
    }

    setCourseId(courseId: string): Observable<any> {
        return this.apiService.apiRequest<any>(PlaybooksApiService.setCourseIdUrl, HttpMethod.Post, { courseId });
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}playbooks`; }

    private static get gddInfoUrl(): string { return `${PlaybooksApiService.baseUrl}/gddInfo`; }

    private static get linkUrlsUrl(): string { return `${PlaybooksApiService.baseUrl}/urls`; }

    private static get monthlyDataUrl(): string { return `${PlaybooksApiService.baseUrl}/monthlyData`; }

    private static get printUrls(): string { return `${PlaybooksApiService.baseUrl}/printUrls`; }

    private static get processDataUrl(): string { return `${PlaybooksApiService.baseUrl}/processDataFromClient`; }

    private static get setCourseIdUrl(): string { return `${PlaybooksApiService.baseUrl}/setCourseId`; }

    private static get yearlyReportUrl(): string { return `${PlaybooksApiService.baseUrl}/yearlyData`; }

}
