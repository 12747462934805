import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { AssetTrackingApiService } from './asset-tracking-api.service';
import { AuthenticationModel } from './models/authentication.model';
import { AuthManagerService } from '../auth/auth-manager.service';
import { CalAmpAvlEvent } from '../cal-amp/models/cal-amp-avl-event.model';
import { CalAmpDevice } from '../cal-amp/models/cal-amp-device.model';
import { CalAmpMotionLogEvent } from '../cal-amp/models/cal-amp-motion-log-event.model';
import { DashApiLoginInfo } from '../auth/models/dash-api-login-info.model';
import { DashApiRefreshTokenLoginInfo } from '../auth/models/dash-api-refresh-token-login-info.model';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AssetTrackingManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private assetTrackingApiService: AssetTrackingApiService,
                private authManager: AuthManagerService,
                private localCacheService: LocalCacheService,
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    apiLogin(): Observable<AuthenticationModel> {
        return this.assetTrackingApiService.apiLogin(new DashApiLoginInfo(this.authManager.toroSsoUser.access_token))
            .pipe(tap((response: AuthenticationModel) => {
                this.localCacheService.assetTrackingApiAccessToken = response.token;
                this.localCacheService.assetTrackingApiRefreshToken = response.refreshToken;
            }));
    }

    apiLoginWithRefreshToken(): Observable<AuthenticationModel> {
        return this.assetTrackingApiService.apiLoginWithRefreshToken(new DashApiRefreshTokenLoginInfo(this.localCacheService.assetTrackingApiRefreshToken))
            .pipe(tap((response: AuthenticationModel) => {
                this.localCacheService.assetTrackingApiAccessToken = response.token;
                this.localCacheService.assetTrackingApiRefreshToken = response.refreshToken;
            }));
    }

    getDeviceList(accountId: number, bypassCache = false): Observable<CalAmpDevice[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.assetTrackingDeviceList); }

        return this.assetTrackingApiService.getDeviceList(accountId, bypassCache);
    }

    getLatestAvlEventsForDevice(deviceEsn: number): Observable<CalAmpAvlEvent[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getLatestAvlEventsForDevice(deviceEsn)); }

        return this.assetTrackingApiService.getLatestAvlEventsForDevice(deviceEsn);
    }

    getMotionLogEventsForToday(deviceEsn: number): Observable<CalAmpMotionLogEvent[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getMotionLogEventsForToday(deviceEsn)); }

        return this.assetTrackingApiService.getMotionLogEventsForToday(deviceEsn);
    }

}
