/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { environment } from '../../../../../environments/environment';
import { take } from 'rxjs/operators';
import { TaskTrackerManagerService } from '../../../../api/task-tracker/task-tracker-manager.service';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroUtils } from '../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@UntilDestroy()
@Directive()
export abstract class TaskTrackerWidgetBase extends ToroDashboardWidget implements OnInit {

    protected dashUserPrefs: DashUserPreferences;

    private _taskTrackerApiKey = null;
    set taskTrackerApiKey(value: string) {
        this._taskTrackerApiKey = (value != null && value !== '') ? value : null;
    }

    get taskTrackerApiKey(): string {
        return this._taskTrackerApiKey;
    }

    isApiKeyDialogDisplayed = false;
    newApiKey: string;
    spinnerText = '';
    isValidCompanyApiKeySet = false;
    companyId: number;
    companyName: string;
    showCompany = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    protected constructor(
        protected analyticsService: AnalyticsService,
        protected broadcastService: BroadcastService,
        protected dashMessageService: DashMessageService,
        protected dashUserManager: DashUserManagerService,
        protected deviceManager: DeviceManagerService,
        protected taskTrackerManager: TaskTrackerManagerService,
        protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
                this.setComponentData();
            });

        this.broadcastService.setupTaskTrackerApiKeyChanged
            .pipe(untilDestroyed(this))
            .subscribe((apiKey: string) => {
                this.onSetApiKey(apiKey);
            });

        this.language = this.dashUserManager.language;
    }

    // =========================================================================================================================================================
    // Protected Methods
    // =========================================================================================================================================================

    protected abstract setComponentData();

    protected abstract getWidgetDataExtended(isManualRefresh: boolean);

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.ENTER_API_NUMBER'),
                icon: 'pi pi-fw pi-key',
                command: this.showApiKeyDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Protected Methods
    // =========================================================================================================================================================

    protected getWidgetData(isManualRefresh = false) {
        // Since we may need to login to taskTracker and we can end up in a race condition with all three widgets attempting to call the api
        // we'll use the call to getActiveCompanies to gate access. The first request in will make the api request, the other two widgets
        // will either be handed back a shared observable for that request or a cached collection.
        const sources: Observable<any>[] = [
            this.taskTrackerManager.getActiveCompanies(isManualRefresh).pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
        ];

        forkJoin(sources)
            .subscribe(([companies, dashUserInfo]) => {
                this.dashUserPrefs = dashUserInfo.preferences;
                if (!this.dashUserPrefs || this.dashUserPrefs.taskTrackerApiKey == null || !companies || companies.length < 1) {
                    this.setNoAccountDisplay();
                    return;
                }

                const company = companies.find(c => c.uuid === this.dashUserPrefs.taskTrackerApiKey);
                this.companyId = company?.id;
                if (this.companyId == null) {
                    this.setNoAccountDisplay();
                    return;
                }

                this.companyName = company.name;
                this.taskTrackerApiKey = this.dashUserPrefs.taskTrackerApiKey;
                this.isValidCompanyApiKeySet = true;
                this.getWidgetDataExtended(isManualRefresh);
            }, () => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSetApiKey(apiKey: string) {
        this.isApiKeyDialogDisplayed = false;

        // TODO: Temporary?
        if (apiKey != null) {
            this.newApiKey = apiKey;
        }

        // // If the Course Id was not changed, don't do anything.
        if (this.taskTrackerApiKey === this.newApiKey) { return; }

        this.taskTrackerApiKey = this.newApiKey;
        this.dashUserPrefs.taskTrackerApiKey = this.taskTrackerApiKey;

        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs)
            .subscribe(() => {
                this.taskTrackerManager.companyIdChange.next(this.newApiKey);

                const eventDetails = `API Key: ${this.newApiKey}`
                this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerWidgetSaveApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
            }, () => {
                this.resetBusy();
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    onCancelSetApiKey() {
        this.isApiKeyDialogDisplayed = false

        const eventDetails = `API Key: ${this.taskTrackerApiKey}`
        this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerWidgetCancelApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onTaskTrackerLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_TASK_TRACKER_SITE' });
        setTimeout(() => window.open(environment.taskTrackerUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onGotoSite() {
        window.open(environment.taskTrackerUrl, '_blank');
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private showApiKeyDialog() {
        this.newApiKey = this.taskTrackerApiKey;
        this.isApiKeyDialogDisplayed = true;

        const eventDetails = `API Key: ${this.taskTrackerApiKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerWidgetShowApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    protected resetBusy() {
        this.isBusy = false;
        this.spinnerText = null;
    }

    private setNoAccountDisplay() {
        this.isValidCompanyApiKeySet = false;
        const linkHtml = `${this.translateService.instant('STRINGS.ACQUIRE_TASK_TRACKER_API_NUMBER')} <a href="https://www.advancedscoreboard.com/tracker/setup.php?box=api-setup" target="_blank">${this.translateService.instant('STRINGS.HERE')}</a>`;
        this.setIsUnableToFetchData('STRINGS.PROVIDE_TASK_TRACKER_API_NUMBER', linkHtml);
        this.resetBusy();
    }
}
