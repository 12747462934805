export class GreenImageEntry {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    greenId: number;
    greenName: string;
    greenDepth: number;
    greenImage: string;
}
