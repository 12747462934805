import { Component, Input } from '@angular/core';

@Component({
    selector: 'toro-course-trakk-datum',
    templateUrl: './course-trakk-datum.component.html',
    styleUrls: ['./course-trakk-datum.component.less']
})
export class CourseTrakkDatumComponent {
    @Input() value: string;
    @Input() label: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================
}
