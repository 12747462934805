import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxWeatherStation } from '../../../../../api/lynx/models/lynx-weather-station.model';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@Component({
    selector: 'toro-widget-lynx-local-current-weather',
    templateUrl: './widget-lynx-local-current-weather.component.html',
    styleUrls: ['./widget-lynx-local-current-weather.component.less']
})
export class WidgetLynxLocalCurrentWeatherComponent extends LynxWidgetBase implements OnInit {
    private readonly baseTitle = 'WIDGET.LYNX_LOCAL_CURRENT_WEATHER';

    iconColor = 'goldenrod';
    title = this.baseTitle;

    private readonly NO_VALUE = '--';

    weatherStations: LynxWeatherStation[];

    // Mini-mode vars
    tempUnits = '-';
    etUnits = '-';
    precipitationUnits = '-';
    temperature: string[];
    humidity: string[];
    dailyHighTemperature: string[];
    dailyLowTemperature: string[];
    et: string[];
    precipitation: string[];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isBusy = true;

        if (this.isGridsterInMobileMode) {
            this.broadcastService.userPreferencesChange
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.setMiniModeComponentData();
                });
        }

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LynxLocalCurrentWeatherWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.lynxManager.getWeatherStations()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((weatherStations: LynxWeatherStation[]) => {
                this.clearIsUnableToFetchData();

                /** Begin - Handle incomplete or 'bogus' lynx data ***************************************************************************************** */

                // NOTE: If any one of our weather stations has a single status entry, that is bogus data. We should receive 26. If we receive 'bogus' data
                // show the previous data, if it exists, or display 'no data available' on the widget.
                this.hasNoData = weatherStations == null || weatherStations.length < 1 || !weatherStations.some(s => s.statuses && s.statuses.length >= 1);
                if (this.hasNoData) {
                    this.hasNoData = !this.hasSuccessfullyRetrievedData;
                    return;
                }

                // From time to time, we get partial or no data returned from the api for Lynx Local Weather Station data. In these situations, if we
                // have previously fetched the data successfully, denote that so we can use that data until we receive new, valid data.
                this.hasSuccessfullyRetrievedData = true;

                /** End - Handle incomplete ore 'bogus' lynx data ******************************************************************************************* */

                // TODO: TEST
                // weatherStations?.push(...weatherStations);
                // weatherStations?.push(...weatherStations);

                // Add an index property to the collection elements to be used for converted units array lookup by UI.
                this.weatherStations = weatherStations?.map((s, index) => { return new LynxWeatherStation({...s, index})});

                // Set max width (rows) of widget based on number of lynx pump stations.
                this.setWidgetConstraints(this.weatherStations.length);

                // Include count of stations in widget title.
                if (this.weatherStations.length > 2) { this.title = `${this.translateService.instant(this.baseTitle)} (${this.weatherStations.length})`; }

                // Get the time stamp from the first stations statuses collection if we can.
                if (weatherStations != null && weatherStations.length > 0) {
                    // Find the first weather station that has current weather info.
                    const stationWithCurrentWeatherInfo = weatherStations.find(ws => ws.statuses.find(s => s.time === null));
                    if (!stationWithCurrentWeatherInfo) { return; }

                    // Get last updated timestamp.
                    const currentWeatherInfo = stationWithCurrentWeatherInfo.statuses.find(s => s.time === null);
                    if (currentWeatherInfo) { this.lastUpdateTimestamp = currentWeatherInfo.lastUpdated; }
                }

                if (this.isGridsterInMobileMode) {
                    this.setMiniModeComponentData();
                }
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setWidgetConstraints(stationsCount: number) {
        let maxRows = 1;

        if (stationsCount >= 9) {
            maxRows = 5;
        } else if (stationsCount >= 6) {
            maxRows = 4;
        } else if (stationsCount >= 4) {
            maxRows = 3;
        } else if (stationsCount >= 2) {
            maxRows = 2;
        }

        this.broadcastService.changeWidgetConstraint.next({ id: this.associatedWidget.id, maxRows, maxCols: null });
    }

    setMiniModeComponentData() {
        if (this.weatherStations == null || this.weatherStations.length < 1) { return; }

        this.tempUnits = this.userFormatService.temperatureUnits;
        this.etUnits = this.userFormatService.evapotranspirationUnits;
        this.precipitationUnits = this.userFormatService.rainfallUnits;

        this.temperature = [];
        this.humidity = [];
        this.dailyHighTemperature = [];
        this.dailyLowTemperature = [];
        this.et = [];
        this.precipitation = [];

        this.weatherStations.forEach(ws => {
            const currentWeatherInfo = ws.statuses.find(s => s.time == null);

            this.temperature.push(currentWeatherInfo && currentWeatherInfo.temperature != null
                ? <string>this.userFormatService.temperature(currentWeatherInfo.temperature, true) : this.NO_VALUE);
            this.humidity.push(currentWeatherInfo && currentWeatherInfo.relativeHumidity != null
                ? currentWeatherInfo.relativeHumidity.toString() : this.NO_VALUE);
            this.et.push(currentWeatherInfo && currentWeatherInfo.et != null
                ? this.userFormatService.evapotranspiration(currentWeatherInfo.et) : this.NO_VALUE);

            this.dailyHighTemperature.push(<string>this.userFormatService.temperature(Math.max(...ws.statuses.map(s => s.temperature)), true));
            this.dailyLowTemperature.push(<string>this.userFormatService.temperature(Math.min(...ws.statuses.map(s => s.temperature)), true));
            this.precipitation.push(<string>this.userFormatService.rainfall(ws.statuses.slice(1).reduce((sum, s) => sum += s.precipitation, 0), true));
        })
    }

}
