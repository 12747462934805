import * as moment from 'moment';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { DashMessageService } from '../../../common/services/dash-message.service';
import { NotificationManagerService } from '../../../api/notifications/notification-manager.service';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { ToroNotification } from '../../../api/notifications/models/toro-notification.model';
import { ToroUtils } from '../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../common/services/user-format.service';
import AlertType = ToroEnums.AlertType;
import SiteDataCategoryType = ToroEnums.SiteDataCategoryType;

@UntilDestroy()
@Component({
    selector: 'toro-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.less']
})
export class NotificationCardComponent implements OnInit {
    @HostBinding('class') class = 'toro-notification-card';

    private _notification: ToroNotification;
    @Input() set notification(value: ToroNotification) {
        this._notification = value;
        this.setComponentData();
    }

    get notification(): ToroNotification {
        return this._notification;
    }

    @Input() isNoNotificationsCard = false;

    cardTitle = '';
    cardDate = '';
    severityIcon: string = null;
    severityIconColor: string;
    severityTooltip: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                private notificationManager: NotificationManagerService,
                private userFormatService: UserFormatService,
                private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setCardDate();
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onDeleteNotification() {
        this.notificationManager.deleteNotification(this.notification.id)
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericRequestErrorMessage();
            });
    }

    onMarkRead() {
        this.notificationManager.markNotificationAsRead(this.notification.id)
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericRequestErrorMessage();
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        this.setCardTitle();
        this.setCardDate();
        this.setCardIcon();
    }

    setCardTitle() {
        let title = '';

        switch (this.notification.categoryType) {
            case SiteDataCategoryType.MyTurf:
                title = ToroUtils.Translate.instant('WIDGET.MY_TURF');
                break;
            case SiteDataCategoryType.Lynx:
                title = ToroUtils.Translate.instant('WIDGET.LYNX');
                switch (this.notification.alertType) {
                    case AlertType.Pressure:
                    case AlertType.Flow:
                        title += `: ${ToroUtils.Translate.instant('WIDGET.PUMP_STATION')}`;
                        break;
                }
                break;
            case SiteDataCategoryType.TurfCloud:
                title = ToroUtils.Translate.instant('WIDGET.TURF_CLOUD');
                break;
            case SiteDataCategoryType.TurfGuard:
                title = ToroUtils.Translate.instant('WIDGET.TURF_GUARD');
                switch (this.notification.alertType) {
                    case AlertType.Temperature:
                        title += `: ${ToroUtils.Translate.instant('WIDGET.TEMPERATURE')}`;
                        break;
                    case AlertType.Moisture:
                        title += `: ${ToroUtils.Translate.instant('WIDGET.MOISTURE')}`;
                        break;
                    case AlertType.Salinity:
                        title += `: ${ToroUtils.Translate.instant('WIDGET.SALINITY')}`;
                        break;
                }
                break;
            case SiteDataCategoryType.Weather:
                title = ToroUtils.Translate.instant('WIDGET.WEATHER');
                break;
            case SiteDataCategoryType.AssetTracking:
                title = ToroUtils.Translate.instant('WIDGET.ASSET_TRACKING');
                break;
            case SiteDataCategoryType.Playbooks:
                title = ToroUtils.Translate.instant('WIDGET.PLAYBOOKS');
                break;
            case SiteDataCategoryType.Geofence:
                title = ToroUtils.Translate.instant('WIDGET.GEO_FENCING')
                break;
            default:
                title = ToroUtils.Translate.instant('STRINGS.SITE');
                break;
        }

        this.cardTitle = title;
    }

    setCardDate() {
        if (!this.notification) { return; }

        const dateString = this.userFormatService.toUserDateString(this.notification.triggeredTimeForCourse);
        const timeString = moment(this.notification.triggeredTimeForCourse).format('h:mm A');
        this.cardDate = `${dateString}, ${timeString}`;
    }

    setCardIcon() {
        switch (this.notification.severity) {
            case ToroEnums.AlertSeverity.Severe:
                this.severityIcon = 'exclamation-triangle';
                this.severityIconColor = 'orangered';
                this.severityTooltip = 'STRINGS.SEVERITY_SEVERE';
                break;
            case ToroEnums.AlertSeverity.High:
                this.severityIcon = 'arrow-circle-up';
                this.severityIconColor = 'darkorange';
                this.severityTooltip = 'STRINGS.SEVERITY_HIGH';
                break;
            case ToroEnums.AlertSeverity.Moderate:
                this.severityIcon = 'arrow-circle-right';
                this.severityIconColor = 'gold';
                this.severityTooltip = 'STRINGS.SEVERITY_MODERATE';
                break;
            case ToroEnums.AlertSeverity.Information:
            default:
                this.severityIcon = 'info-circle';
                this.severityIconColor = 'cornflowerblue';
                this.severityTooltip = 'STRINGS.SEVERITY_INFORMATION';
                break;
        }

        this.severityTooltip = this.translateService.instant(this.severityTooltip);
    }
}
