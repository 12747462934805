import WizardFaq = ToroEnums.WizardFaq;

import { ToroEnums } from '../../../../common/enumerations/toro.enums';

export class FaqModel {

    constructor(id: WizardFaq, question: string, answer: string, image = null, imageHeight = null) {
        this.id = id,
        this.question = question;
        this.answer = answer;
        this.image = image;
        this.imageHeight = imageHeight;
    }

    id: WizardFaq;
    question: string;
    answer: string;
    image: string;                  // relative path to image.
    imageHeight: string;            // in pixels. E.g., '40px'
}
