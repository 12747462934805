import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-weather-graph-chart-dlg',
    templateUrl: './weather-graph-chart-dlg.component.html',
    styleUrls: ['./weather-graph-chart-dlg.component.less']
})
export class WeatherGraphChartDlgComponent implements OnInit {
    @Output() cancel = new EventEmitter();

    @Input() title = '';
    @Input() isTitleCase = true;

    language = 'en-us';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService) { }

    ngOnInit() {
        this.language = this.dashUserManager.language;

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.cancel.emit();
    }

}
