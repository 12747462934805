import { Observable, of, Subject } from 'rxjs';
import { AlertSettingsAndParameters } from './models/alert-settings-and-parameters.model';
import { AppInjector } from '../../demo/demo.module';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { NotificationApiService } from './notification-api.service';
import { tap } from 'rxjs/operators';
import { ToroNotification } from './models/toro-notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // Subjects
    notificationsListChange = new Subject<ToroNotification[]>();

    // Local Cache
    _notifications: ToroNotification[];

    // Constants
    private readonly MAX_NOTIFICATIONS = 500;

    private _notificationsPollIntervalRef: NodeJS.Timeout;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private notificationApiService: NotificationApiService
    ) {
        if (environment.isDemoMode) { this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService); }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    initiateNotificationPolling() {
        setTimeout(() => this.startPollingForNotifications());
    }

    getAllNotifications(bypassCache = false): Observable<ToroNotification[]> {
        if (this._notifications && this._notifications.length > 0 && !bypassCache) {
            return of(this._notifications);
        }

        if (environment.isDemoMode) {
            this._notifications = this.demoModeMockDataService.notifications;
            this.sendNotificationsListChangeEvent();
            this.broadcastService.notificationsRetrieved.next(this._notifications);
            return of(this._notifications);
        }

        return this.notificationApiService.getNotifications()
            .pipe(tap((notifications: ToroNotification[]) => {
                // Only show last 500 notifications
                this._notifications = notifications.length < this.MAX_NOTIFICATIONS ? notifications : notifications.slice(0, this.MAX_NOTIFICATIONS);
                this.sendNotificationsListChangeEvent();
                this.broadcastService.notificationsRetrieved.next(this._notifications);
            }));
    }

    markNotificationAsRead(notificationId: number): Observable<void> {
        if (environment.isDemoMode) {
            this.markNotificationAsReadInternal(notificationId);
            return of();
        }

        return this.notificationApiService.markNotificationAsRead(notificationId)
            .pipe(tap(() => this.markNotificationAsReadInternal(notificationId)));
    }

    private markNotificationAsReadInternal(notificationId: number) {
        const notification = this._notifications.find(n => n.id === notificationId);
        notification.read = true;
        this.sendNotificationsListChangeEvent();
    }

    markAllNotificationsAsRead(): Observable<void> {
        if (environment.isDemoMode) {
            this.markAllNotificationsAsReadInternal();
            return of();
        }

        return this.notificationApiService.markAllNotificationsAsRead()
            .pipe(tap(() => this.markAllNotificationsAsReadInternal()));
    }

    private markAllNotificationsAsReadInternal()  {
        this._notifications.forEach(n => n.read = true);
        this.sendNotificationsListChangeEvent();
    }

    deleteNotification(notificationId: number): Observable<any> {
        if (environment.isDemoMode) {
            this.deleteNotificationInternal(notificationId);
            return of();
        }

        return this.notificationApiService.deleteNotification(notificationId)
            .pipe(tap(() => this.deleteNotificationInternal(notificationId)));
    }

    private deleteNotificationInternal(notificationId: number) {
        this._notifications = this._notifications.filter(n => n.id !== notificationId);
        this.sendNotificationsListChangeEvent();
    }

    deleteAllNotifications(): Observable<any> {
        if (environment.isDemoMode) {
            this.deleteAllNotificationsInternal();
            return of();
        }

        return this.notificationApiService.deleteAllNotifications()
            .pipe(tap(() => this.deleteAllNotificationsInternal()));
    }

    private deleteAllNotificationsInternal() {
        this._notifications = [];
        this.sendNotificationsListChangeEvent();
    }

    getAlertSettingsAndParameters(): Observable<AlertSettingsAndParameters> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.alertSettingsAndParameters); }
        return this.notificationApiService.getAlertSettingsAndParameters();
    }

    setAlertSettingsAndParameters(settings: AlertSettingsAndParameters): Observable<any> {
        if (environment.isDemoMode) { return of(true); }

        return this.notificationApiService.setAlertSettingsAndParameters(settings);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private startPollingForNotifications() {
        if (!environment.notificationPollingIntervalInMinutes
            || environment.notificationPollingIntervalInMinutes <= 0
            || this._notificationsPollIntervalRef != null) { return; }

        this._notificationsPollIntervalRef = setInterval(() => {
            this.getAllNotifications(true).subscribe();
        }, environment.notificationPollingIntervalInMinutes * 60 * 1000);
    }

    private sendNotificationsListChangeEvent() {
        this.notificationsListChange.next(this._notifications.slice());
    }

}


