<div class="toro-dashboard-widget-container">
    <toro-dashboard-widget-header
            #widgetHeader
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="showTitleIcon"
            [middleContent]="middleContent"
            [customToolbar]="customToolbar"
            [widgetId]="associatedWidget?.uniqueId"
            [widgetTypeId]="associatedWidget?.type"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="menuStyleClass"
            [showInfoButton]="showInfoButton"
            [showLinkButton]="showLinkButton"
            (showInfo)="onShowInfo()"
            (gotoSite)="onGotoSite()"
    ></toro-dashboard-widget-header>

    <div *ngIf="!isUnableToFetchData && !hasNoData" class="toro-dashboard-widget-content widget-non-draggable" [ngClass]="{'fade-in-on-load': !isBusy, 'toro-mini-mode': isMiniModeWidget}">
        <ng-content></ng-content>
    </div>
    <div *ngIf="isUnableToFetchData || hasNoData" class="toro-widget-data-retrieval-failed-content widget-non-draggable toro-component-shield-host" [ngClass]="{'fade-in-on-load': !isBusy}">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        <span>{{'STRINGS.CANT_RETRIEVE_WIDGET_DATA' | translate}}</span>
        <span *ngIf="unableToFetchDataReason" class="tdw-no-data-reason" [ngClass]="{'link': unableToFetchDataLinkHtml}">
            {{unableToFetchDataReason | translate}}<br *ngIf="unableToFetchDataLinkHtml" /><span *ngIf="unableToFetchDataLinkHtml" class="tdw-no-data-reason" [innerHTML]="unableToFetchDataLinkHtml"></span>
        </span>
        <toro-component-shield></toro-component-shield>
    </div>

<!--    <div *ngIf="hasNoData" class="toro-widget-no-data-content widget-non-draggable toro-component-shield-host" [ngClass]="{'fade-in-on-load': !isBusy}">-->
<!--        <fa-icon icon="exclamation-triangle"></fa-icon>-->
<!--        <span>{{'STRINGS.NO_DATA_AVAILABLE' | translate}}</span>-->
<!--        <toro-component-shield></toro-component-shield>-->
<!--    </div>-->

    <div *ngIf="!showEmptyFooter"
         class="toro-dashboard-widget-footer widget-non-draggable toro-component-shield-host prevent-select"
         [ngClass]="language"
    >
        <fa-icon *ngIf="!disableWidgetAlertBanner && !hideWidgetAlertBanner && alertText" icon="exclamation-triangle" [pTooltip]="alertText" tooltipStyleClass="widget-alert-tooltip"></fa-icon>
        <div *ngIf="lastUpdated">{{'STRINGS.WIDGET_LAST_UPDATED_LABEL' | translate: {duration: lastUpdated.duration, scale: lastUpdated.scale} }}</div>
        <toro-component-shield></toro-component-shield>
    </div>
    <div *ngIf="showEmptyFooter" class="toro-dashboard-widget-footer widget-non-draggable toro-component-shield-host prevent-select"></div>

    <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" [text]="spinnerText"></toro-waiting-indicator>

   <toro-drag-overlay
           *ngIf="isWidgetDraggable"
           [disableMoveLeft]="associatedWidget.x === 0"
           [disableMoveUp]="associatedWidget.y === 0"
   ></toro-drag-overlay>
</div>

<toro-data-settings-dlg
        *ngIf="isSettingsDialogVisible"
        [widgetConfig]="associatedWidget?.config"
        [analyticsWidgetName]="analyticsWidgetName"
        (cancel)="isSettingsDialogVisible = false"
        (save)="isSettingsDialogVisible = false">
<</toro-data-settings-dlg>
