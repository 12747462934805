/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import * as moment from 'moment';
import { KittyHawkGeoJson } from './kitty-hawk-geo-json.model';

export class KittyHawkGreen {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.created_on) this.created_on = moment.utc(json.created_on).toDate();
            if (json.geo_json) this.geo_json = new KittyHawkGeoJson(JSON.parse(json.geo_json));
        }
    }

    uuid: string;
    location_uuid: string;
    type_id: number;
    name: string;
    latitude: number;
    longitude: number;
    geo_json: KittyHawkGeoJson;
    zoom: number;
    measurement: number;
    rotation: number;
    at_hole: number;
    created_by: string;
    created_on: Date;
    mission_uuid: string;
    comment_count: number;
}
