import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SpecFsSessionData } from '../../../../../api/spectrum/models/spec-fs-session-data.model';
import { SpecPaletteDefinitionItem } from '../../../../../api/spectrum/models/spec-fs-profile.model';

@Component({
    selector: 'toro-spec-grid-card',
    templateUrl: './spec-grid-card.component.html',
    styleUrls: ['./spec-grid-card.component.less']
})
export class SpecGridCardComponent {
    @HostBinding('class') class = 'toro-spec-grid-card';

    @Input() width: number;
    @Input() height: number;
    @Input() item: SpecFsSessionData;

    @Output() selectedItemChange = new EventEmitter<SpecFsSessionData>();

    protected _selectedItem: SpecFsSessionData;
    @Input() set selectedItem(value: SpecFsSessionData) {
        this._selectedItem = value;

        this.isSelected = value != null && value == this.item;
    }

    get selectedItem(): SpecFsSessionData {
        return this._selectedItem;
    }

    private _colorPalette: SpecPaletteDefinitionItem[] = []
    @Input() set colorPalette(value: SpecPaletteDefinitionItem[]) {
        this._colorPalette = value;
        this.setBackgroundColor(this.item.decimalDataValue)
    }

    get colorPalette(): SpecPaletteDefinitionItem[] {
        return this._colorPalette;
    }

    protected backGroundColor = '#A2ADB2';
    protected isSelected = false;


    // =========================================================================================================================================================
    // C'tor and lifecycle hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClick() {
        if (this.item.decimalDataValue == null) return;
        this.selectedItemChange.next(!this.isSelected ? this.item : null);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setBackgroundColor(value: number) {
        if (this._colorPalette == null || this.item.decimalDataValue == null) { return; }

        this.backGroundColor = this._colorPalette.find(p => p.lowValue <= value && p.highValue >= value)?.color || 'white';
    }
}
