import PwChartDataType = ToroEnums.PwChartDataType;
import PwChartPeriod = ToroEnums.PwChartPeriod;
import PwChartType = ToroEnums.PwChartType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PerryWeatherConfig } from '../../../../../api/perry-weather/models/perry-weather-config.model';
import { PwChartSeries } from '../../../../../api/perry-weather/models/pw-chart-series.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@Component({
    selector: 'toro-perry-weather-tabbed-chart',
    templateUrl: './perry-weather-tabbed-chart.component.html',
    styleUrls: ['./perry-weather-tabbed-chart.component.less']
})
export class PerryWeatherTabbedChartComponent implements OnInit {
    @HostBinding('class') class = 'toro-perry-weather-tabbed-chart';

    protected readonly ChartType = PwChartType;
    protected readonly ChartPeriod = PwChartPeriod;

    @Output() configChange = new EventEmitter<PerryWeatherConfig>();

    @Input() chartData: PwChartSeries[];
    @Input() title: string;
    @Input() perryWeatherConfig: PerryWeatherConfig;

    private _type: PwChartType
    @Input() set type(value: PwChartType) {
        this._type = value;
        if (value == PwChartType.Et) {
            this.selectedTab = PwChartPeriod.Historic;
            this.selectedChart = PwChartType.Et;
        }

        this.setSelectedChartSeries();
    }

    get type(): PwChartType {
        return this._type;
    }

    @Input() isBusy = false;

    selectedTab = PwChartPeriod.Historic                          // The next three variables are related.
    selectedChart = PwChartType.Precip
    selectedChartSeries = PwChartDataType.PrecipHistorical
    chartLegend: string;
    showModalGraph = false;
    isComponentInitialized = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit() {
        this.setUserChart();

        this.isComponentInitialized = true;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTabClick(tab: PwChartPeriod) {
        this.selectedTab = tab;

        if (this.selectedTab == PwChartPeriod.Forecast) {
            this.selectedChart = PwChartType.Precip;
        }

        this.setSelectedChartSeries();
    }

    onChartSelect(chartType: PwChartType) {
        this.selectedChart = chartType;
        this.setSelectedChartSeries();
    }

    onChartClick(chartDataType: PwChartDataType) {
        this.setChartLegend(chartDataType);
        this.showModalGraph = true;
    }

// =========================================================================================================================================================
// Helper Methods
// =========================================================================================================================================================

    private setSelectedChartSeries() {
        switch (this.selectedTab) {
            case PwChartPeriod.Historic:
                this.selectedChartSeries = this.selectedChart == PwChartType.Precip ? PwChartDataType.PrecipHistorical : PwChartDataType.EtHistorical;
                break;
            case PwChartPeriod.Forecast:
                this.selectedChartSeries = PwChartDataType.PrecipForecast;
                break;
        }

        this.setChartLegend(this.selectedChartSeries);
        this.broadcastConfigChange();
    }

    private broadcastConfigChange() {
        // No need to broadcast during component initialization phase.
        if (!this.isComponentInitialized) { return; }

        switch (this.type) {
            case PwChartType.Precip:
                this.perryWeatherConfig.precipChartType = this.selectedChartSeries;
                break;

            case PwChartType.Et:
                // Only one chart type (for now), so no action necessary. Including case to ensure we don't fall through to default:
                break;

            default:
                this.perryWeatherConfig.mainChartType = this.selectedChartSeries;
                break;
        }

        this.configChange.next(this.perryWeatherConfig);
    }

    private setChartLegend(chartDataType: PwChartDataType) {
        switch (chartDataType) {
            case PwChartDataType.PrecipForecast:
                this.chartLegend = 'CASE_SENSITIVE.PRECIPITATION_FORECAST'
                break;
            case PwChartDataType.PrecipHistorical:
                this.chartLegend = 'CASE_SENSITIVE.HISTORICAL_PRECIPITATION'
                break;
            case PwChartDataType.EtHistorical:
                this.chartLegend = 'CASE_SENSITIVE.HISTORICAL_ET'
                break;
        }
    }

    private setUserChart() {
        switch (this.type) {
            case ToroEnums.PwChartType.Precip:
                this.selectedChart = PwChartType.Precip;
                this.selectedTab = this.perryWeatherConfig.precipChartType == PwChartDataType.PrecipForecast ? PwChartPeriod.Forecast : PwChartPeriod.Historic;
                break;

            case ToroEnums.PwChartType.Et:
                this.selectedTab = PwChartPeriod.Historic;
                this.selectedChart = PwChartType.Et;
                break;

            default:
                // Main Chart
                switch (this.perryWeatherConfig.mainChartType) {
                    case PwChartDataType.PrecipForecast:
                        this.selectedTab = PwChartPeriod.Forecast;
                        this.selectedChart = PwChartType.Precip;
                        break;
                    case PwChartDataType.PrecipHistorical:
                        this.selectedTab = PwChartPeriod.Historic;
                        this.selectedChart = PwChartType.Precip;
                        break;
                    case PwChartDataType.EtHistorical:
                        this.selectedTab = PwChartPeriod.Historic;
                        this.selectedChart = PwChartType.Et;
                        break;
                }
        }

        this.setSelectedChartSeries();
    }

}
