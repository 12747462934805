import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { SpecSubCollectionSummary } from '../models/spec-sub-collection-summary.model';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-spec-table-card',
    templateUrl: './spec-table-card.component.html',
    styleUrls: ['./spec-table-card.component.less']
})
export class SpecTableCardComponent implements OnInit {

    @Output() selectedItemChange = new EventEmitter<SpecSubCollectionSummary>();
    @Output() showTooltip = new EventEmitter<SpecSubCollectionSummary>();
    @Output() cardClicked = new EventEmitter<SpecSubCollectionSummary>();

    @Input() item: SpecSubCollectionSummary;
    @Input() displayCols: number;

    private _selectedItem: SpecSubCollectionSummary;
    @Input() set selectedItem(value: SpecSubCollectionSummary) {
        if (value == null || value == this._selectedItem) { return; }

        this._selectedItem = value;
        this.isSelected = this.item.name == value.name;
        this.isShowingTooltip = value.showTooltip;

        if (this.isSelected) { this.selectedItemChange.emit(value); }
    }

    get selectedItem(): SpecSubCollectionSummary {
        return this._selectedItem;
    }

    @Input() set modalClosed(closed: boolean) {
        if (this.displayCols != 1 || !closed || !this.isShowingTooltip) return;

        this.isShowingTooltip = false;
    }

    protected name = '--';
    protected avg = '--';
    protected low = '--';
    protected isSelected = false;

    private isShowingTooltip = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService,
                private translateService: TranslateService) { }

    ngOnInit(): void {
        this.setDisplayValues();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setDisplayValues());
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClick(showTooltip = false) {
        if (showTooltip && this.isSelected) {
            this.isShowingTooltip = !this.isShowingTooltip;
            this.showTooltip.emit(this.isShowingTooltip ? this.item : null);
        }

        this.item.showTooltip = showTooltip;

        if (!this.isSelected) {
            this.item.showPopup = this.displayCols == 1;
            this.selectedItemChange.emit(this.item);
            return;
        }

        this.cardClicked.emit(this.item);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        this.name = isNaN(+this.item.name) ? this.item.name : `${this.translateService.instant('STRINGS.HOLE')} ${this.item.name}`;
        this.avg = this.userFormatService.toUserNumber(this.item.avgMoisture, 0, 1);
        this.low = this.userFormatService.toUserNumber(this.item.lowMoisture, 0, 1);
    }

}
