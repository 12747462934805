import * as moment from 'moment';
import { CalAmpAvlDeviceData } from './cal-amp-avl-device-data.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;

export class CalAmpAvlEvent {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(avlEvent: any) {
        if (avlEvent == null) { return; }

        this.deviceEsn = +avlEvent.deviceEsn;
        this.deviceId = avlEvent.deviceId;
        this.deviceName = avlEvent.deviceName;
        this.eventCode = avlEvent.eventCode;

        // Asset Tracking API passes dates back in course local time. We'll treat all dates as local, which will not modify the date/time value.
        this.eventTime = moment(avlEvent.eventTime).toDate();

        this.eventType = avlEvent.eventType;
        this.deviceDataConverted = new CalAmpAvlDeviceData(avlEvent.deviceDataConverted);
        this.heading = avlEvent.heading;
        this.latitude = avlEvent.latitude;
        this.longitude = avlEvent.longitude;
    }

    deviceEsn: number;
    deviceId: string;
    deviceName: string;
    eventCode: number;
    eventTime: Date;
    eventType: string;
    deviceDataConverted: CalAmpAvlDeviceData;
    heading: number;
    latitude: number;
    longitude: number;

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get isStatusEvent(): boolean {
        return this.deviceDataConverted != null && this.deviceDataConverted.isStatusEvent;
    }

    get status(): CalAmpDeviceStatus {
        if (!this.isStatusEvent) { return null; }

        return this.deviceDataConverted.status;
    }

    // Returns a Date with the time component set to the start of the day (i.e., 00:00);
    get eventDate(): moment.Moment {
        return moment(this.eventTime).clone().startOf('day');
    }

}
