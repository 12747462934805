import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LynxArea } from '../../../../../../api/lynx/models/lynx-area.model';
import { TimeStampedCollection } from '../../../../../../api/_common/time-stamped-collection.model';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@Component({
    selector: 'toro-running-stations-details-dlg',
    templateUrl: './running-stations-details-dlg.component.html',
    styleUrls: ['./running-stations-details-dlg.component.less']
})
export class RunningStationsDetailsDlgComponent {
    @Output() closeDialog = new EventEmitter();
    @Output() dataRefresh = new EventEmitter();

    @Input() set runningStationsDetails(value: TimeStampedCollection<LynxArea[]>) {
        if (this.stationDetailsInternal != null && !this.isManualRefresh) { return; }

        this.isManualRefresh = false;
        this.currentAsOfTimestamp = `${this.userFormatService.toUserDateString(value.lastUpdated)} ${this.userFormatService.toUserTimeString(value.lastUpdated)}`;
        setTimeout(() => this.stationDetailsInternal = value.items);
    }

    stationDetailsInternal: LynxArea[];
    currentAsOfTimestamp: string;

    private isManualRefresh = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private userFormatService: UserFormatService) { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.closeDialog.emit();
    }

    onRefresh() {
        this.isManualRefresh = true;
        this.dataRefresh.emit();
    }
}
