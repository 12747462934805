import { EnableWidget } from './enable-widget.model';

export class EnableWidgetGroup {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    image: string;
    widgets: EnableWidget[] = [];
}
