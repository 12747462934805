<a *ngIf="href" [href]="href" target="_blank">
    <ng-container [ngTemplateOutlet]="buttonContents"></ng-container>
</a>

<ng-container *ngIf="!href" [ngTemplateOutlet]="buttonContents"></ng-container>

<ng-template #buttonContents>
    <div class="toro-link-button-container" [pTooltip]="tooltipText" [tooltipPosition]="tooltipPosition">
        <img [src]="imageSrc" [alt]="altText"/>
    </div>
</ng-template>
