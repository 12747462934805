import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'toro-account-issue',
    templateUrl: './account-issue.component.html',
    styleUrls: ['./account-issue.component.less']
})
export class AccountIssueComponent {
    @HostBinding('class') class = 'toro-account-issue-component';

    @Input() accountName = 'TORO.TORO';
    @Input() helpLine: 'TORO.HELP_LINE';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

}
