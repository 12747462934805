import SoilScoutMapView = ToroEnums.SoilScoutMapView;
import WidgetType = ToroEnums.WidgetType;

import { GoogleMapLocation } from '../../_common/models/google-map-location.model';
import { IWidgetDataSettings } from '../../_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

export class SoilScoutConfig implements IWidgetDataSettings{

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    isSoilScoutConfig = true;
    origin: GoogleMapLocation;
    center: GoogleMapLocation;
    zoom = 17;
    mapView: SoilScoutMapView = SoilScoutMapView.Moisture;

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;

}
