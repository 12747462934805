export class MixpanelEvent {

    constructor(init?: Partial<MixpanelEvent>) {
        Object.assign(this, init);
    }

    public distinct_id: string = null;

    public id_source: string;
    public id_category: string;
    public id_site: string;
    public id_user?: string = undefined;
    public id_widget?: string = undefined;
    public id_details?: string = undefined;
}
