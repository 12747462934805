import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TurfGuardHoleLimit } from '../../../../../api/turf-guard/models/turf-guard-hole-limit.model';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import TurfGuardChartColor = ToroEnums.TurfGuardChartColor;

const More = require('highcharts/highcharts-more');
More(Highcharts);

@UntilDestroy()
@Component({
    selector: 'toro-turf-guard-chart',
    templateUrl: './turf-guard-chart.component.html',
    styleUrls: ['./turf-guard-chart.component.less']
})
export class TurfGuardChartComponent implements OnInit {
    @HostBinding('class') class = 'toro-turf-guard-chart';
    @ViewChild('tgChartContainer') tgChartContainer: ElementRef;

    @Output() pointClick = new EventEmitter<number>();
    @Output() chartLoaded = new EventEmitter();

    private readonly CHART_BAR_WIDTH = 36;
    private readonly CHART_BAR_PADDING = 19;    // DO NOT CHANGE THIS. This value is derived by the chart itself.
    private readonly CHART_COLUMN_WIDTH = this.CHART_BAR_WIDTH + this.CHART_BAR_PADDING;
    private readonly chartAnnotationWidth = this.CHART_BAR_WIDTH - .2;

    private chartContainerHeight = 0;
    private chartContainerWidth = 0;

    private _turfGuardWidgetData: TurfGuardWidgetData;
    @Input() set turfGuardWidgetData(value: TurfGuardWidgetData) {
        // NOTE: We use this field being updated as the trigger for updating the chart
        //       by call setChartValues.
        this._turfGuardWidgetData = value;
        this.setChartValues();
    }

    get turfGuardWidgetData(): TurfGuardWidgetData {
        return this._turfGuardWidgetData;
    }

    @Input() chartValueUnits: string;
    @Input() chartDataValues: number[];
    @Input() chartMinValue: number;
    @Input() chartMaxValue: number;
    @Input() chartDataLimits: TurfGuardHoleLimit[];
    @Input() isDialogContent = false;

    private _displayCols = 0;
    @Input() set displayCols(value: number) {
        if (value === this._displayCols) return;

        this._displayCols = value;

        if (this.chart) this.setupChart();
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService,
    ) {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                setTimeout(() => {
                    this.setChartValues();
                });
            });
    }

    ngOnInit(): void {
        this.setWidthOfChartContainer();

        setTimeout(() => this.setupChart());
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setChartValues() {
        if (!this.chart || !this.chart.yAxis) { return; }

        // NOTE: These values cannot be updated as part of the chart.update (below). The scrollablePlotArea
        // overlay mask no longer functions properly. The direct axis update resolves that issue.
        this.chart.yAxis[0].update({ min: this.chartMinValue, max: this.chartMaxValue });

        this.chart.update({
            chart: {
                width: this.getChartWidth(),
                scrollablePlotArea: {
                    minWidth: this.CHART_COLUMN_WIDTH * this.turfGuardWidgetData.holesCount,
                }
            },
            xAxis: {
                categories: this.turfGuardWidgetData.holeNames,
                plotBands: this.chartPlotBands,
            },
            series: [{
                type: 'column',
                data: this.chartDataValues,
            }],
            annotations: [{
                shapes: this.getAnnotations(this.chartAnnotationWidth),
                draggable: ''
            }]
        });

    }

    private setWidthOfChartContainer() {
        if (!this.tgChartContainer) return;

        this.chartContainerWidth = this.tgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.tgChartContainer.nativeElement.offsetHeight - (!this.isDialogContent ? 2 : 4);
    }

    private setupChart() {
        const self = this;

        this.setWidthOfChartContainer();

        this.chartOptions = {
            chart: {
                renderTo: 'tg3205',
                type: 'column',
                height: self.chartContainerHeight,
                width: self.getChartWidth(),
                marginTop: 20,
                marginRight: 10,
                marginBottom: 25,
                events: {
                    load(event) {
                        self.chart = event.target;
                        self.chartLoaded.emit();
                    },
                    click(event) { self.pointClick.emit(Math.round(event.xAxis[0].value)); }
                },
                scrollablePlotArea: {
                    minWidth: this.CHART_COLUMN_WIDTH * self.turfGuardWidgetData.holesCount
                }
            },
            title: { text: '' },
            legend: { enabled: false },
            credits: { enabled: false },
            xAxis: {
                categories: self.turfGuardWidgetData.holeNames,
                labels: {
                    reserveSpace: false,
                    rotation: -90,
                    align: 'left',
                    y: -8,
                    useHTML: true,
                    style: {
                        color: TurfGuardChartColor.BarText,
                    },
                    formatter() {
                        return '<span style="font-size: 14px;"><b>' + this.value + '</b></span>';
                    },
                },
                tickLength: 0,
                plotBands: self.chartPlotBands,
            },
            yAxis: {
                min: self.chartMinValue,
                max: self.chartMaxValue,
                title: { enabled: false },

                // startOnTick: false,     // If true, min will be rounded down to next tick. True is the default.
                // endOnTick: false,       // If true, max will be rounded up to next tick. True is the default.
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    groupPadding: 0,
                    pointPadding: 0,
                    pointWidth: self.CHART_BAR_WIDTH,
                    borderWidth: 0,
                },
            },
            series: [{
                name: '',
                data: self.chartDataValues,
                color: TurfGuardChartColor.Bar,
                events: { click(event) { self.pointClick.emit(event.point.x); } }
            }],
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const userValue = self.userFormatService.toUserNumber(this.y);

                    if (!self.isDialogContent) {
                        return '<span>' + userValue + ' ' + self.chartValueUnits + '</span>';
                    } else {
                        return '<div style="font-size: 17px;">' + userValue + ' ' + self.chartValueUnits + '</div>';
                    }
                },
                useHTML: true,
                followPointer: self.isDialogContent,
                followTouchMove: true,
            },
            annotations: [{
                shapes: self.getAnnotations(self.chartAnnotationWidth),
                draggable: ''
            }]

        };
    }

    private getChartWidth() {
        const chartWidth = this.chartContainerWidth;
        const columnWidth = this.CHART_BAR_WIDTH + this.CHART_BAR_PADDING;
        const holes = this.turfGuardWidgetData.holesCount;

        return chartWidth > (columnWidth * holes) ? columnWidth * holes : chartWidth;
    }

    private get chartPlotBands(): { from: number, to: number }[] {
        const bandWidth = 0.7;
        const bandGap = 0.3;
        let from = -0.35;
        let to = .35;
        const bands = [];

        for (let i = 0; i < this.turfGuardWidgetData.holesCount; i++) {
            bands.push({ from, to });
            from = to + bandGap;
            to = from + bandWidth;
        }

        return bands;
    }

    private getAnnotations(annotationWidth: number): {}[] {
        const annotations: {}[] = [];
        const dataValues = this.chartDataValues;
        const dataLimits = this.chartDataLimits;
        const dataHighAlerts = this.turfGuardWidgetData.chartDataHighAlerts;
        const dataLowAlerts = this.turfGuardWidgetData.chartDataLowAlerts;

        // Limit Annotations
        for (let i = 0; i < dataLimits.length; i++) {
            annotations.push(this.getLimitAnnotation(i, dataLimits[i].high, false, annotationWidth));
            annotations.push(this.getLimitAnnotation(i, dataLimits[i].low, true, annotationWidth));

            // Add any high alerts for the given data point.
            if (dataHighAlerts[i] > 0) { annotations.push(this.getAlertAnnotation(i, dataValues[i], annotationWidth)); }

            // Add any low alerts
            if (dataLowAlerts[i] > 0) {
                annotations.push(this.getAlertAnnotation(i, dataValues[i], annotationWidth));
            }
        }

        return annotations;
    }

    private getLimitAnnotation(x: number, y: number, isLowLimit: boolean, annotationWidth: number) {
        return {
            point: {
                x,
                y,
                xAxis: 0,
                yAxis: 0
            },
            type: 'rect',
            width: annotationWidth,
            height: .1,
            stroke: isLowLimit ? TurfGuardChartColor.LowLimit : TurfGuardChartColor.HighLimit,
            strokeWidth: 1,
            fill: 'rgba(0,0,0,0)',
            dashStyle: 'Dot',
            x: -annotationWidth / 2,
        };
    }

    private getAlertAnnotation(x: number, y: number, annotationWidth: number) {
        return {
            point: {
                x,
                y: y + .1,  // Place it just above the actual value to accommodate values on the axis.
                xAxis: 0,
                yAxis: 0
            },
            type: 'rect',
            width: annotationWidth,
            height: 3,
            stroke: TurfGuardChartColor.Alert,
            fill: TurfGuardChartColor.Alert,
            strokeWidth: 1,
            x: -annotationWidth / 2,
        };
    }

}
