import { PwForecast } from '../../api/perry-weather/models/pw-forecast.model';
import { PwHistorical } from '../../api/perry-weather/models/pw-historical.model';
import { PwHistoricalEt } from '../../api/perry-weather/models/pw-historical-et.model';
import { PwLocation } from '../../api/perry-weather/models/pw-location.model';
import { PwObservation } from '../../api/perry-weather/models/pw-observation.model';

export class PerryWeatherDemoData {

    static get Location(): PwLocation {
        return new PwLocation({
            id: 2006,
            name: 'Demo Site',
            point: {
                lat: 0.17042431,
                lng: 0.17042431
            },
            pwLink: true
        });
    }

    static get Observation(): PwObservation {
        return new PwObservation({
            data: {
                observationTime: '2024-01-04T23:05:35.5Z',
                ambientTemperature: {
                    value: 14.444444444444457,
                    unit: 'C'
                },
                heatIndex: {
                    value: 13.432778252495666,
                    unit: 'C'
                },
                wetBulbGlobalTemp: {
                    value: 13.033787211791832,
                    unit: 'C'
                },
                humidity: {
                    value: 51,
                    unit: '%'
                },
                dewPoint: {
                    value: 3.9,
                    unit: 'C'
                },
                wind: {
                    speed: 3.9786558294677734,
                    gust: 6.526784170532227,
                    direction: 105,
                    unit: 'm/s'
                },
                precipitation: {
                    unit: 'cm',
                    oneHour: 0,
                    fromMidnight: 0,
                    sevenDays: 0.635,
                    thirtyDays: 10.388600122630596
                },
                lightning: {
                    delay: 0,
                    allClearMinutes: 20,
                    radii: 8
                }
            },
            meta: {
                stationName: 'Clubhouse',
                stationType: 1
            }
        });
    }

    static get Historical(): PwHistorical {
        return new PwHistorical({
            dailyHistorical: [
                {
                    date: '2023-12-27T00:00:00Z',
                    avgAmbientTemperature: 46.50138885003549,
                    minAmbientTemperature: 39.29999923706055,
                    maxAmbientTemperature: 50.400001525878906,
                    avgRelativeHumidity: 59.52777777777778,
                    avgWindSpeed: 5.640277773038381,
                    maxWindSpeed: 12.699999809265137,
                    avgWindGust: 16.537499984105427,
                    maxWindGust: 21.399999618530273,
                    avgWindDirection: 285.22685185185185,
                    avgWetBulbGlobalTemp: 50.36678446151271,
                    minWetBulbGlobalTemp: 48.68292637945913,
                    maxWetBulbGlobalTemp: 51.648998287065915,
                    avgWindChill: 44.13413179134894,
                    minWindChill: 39.20000076293945,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2023-12-28T00:00:00Z',
                    avgAmbientTemperature: 43.01157409173471,
                    minAmbientTemperature: 35.5,
                    maxAmbientTemperature: 48.099998474121094,
                    avgRelativeHumidity: 60.40277777777778,
                    avgWindSpeed: 5.437037036892165,
                    maxWindSpeed: 11.600000381469727,
                    avgWindGust: 15.32528746813193,
                    maxWindGust: 18,
                    avgWindDirection: 296.6666666666667,
                    avgWetBulbGlobalTemp: 48.71903811022773,
                    minWetBulbGlobalTemp: 46.544876149605386,
                    maxWetBulbGlobalTemp: 50.44769209371557,
                    avgWindChill: 40.434444491068525,
                    minWindChill: 34.099998474121094,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2023-12-29T00:00:00Z',
                    avgAmbientTemperature: 46.44953701231215,
                    minAmbientTemperature: 34.599998474121094,
                    maxAmbientTemperature: 56.400001525878906,
                    avgRelativeHumidity: 60.263888888888886,
                    avgWindSpeed: 6.1055555564385875,
                    maxWindSpeed: 12.300000190734863,
                    avgWindGust: 16.574418544769287,
                    maxWindGust: 21.399999618530273,
                    avgWindDirection: 269.86574074074076,
                    avgWetBulbGlobalTemp: 50.186114760653666,
                    minWetBulbGlobalTemp: 46.647483526872904,
                    maxWetBulbGlobalTemp: 53.150040854765294,
                    avgWindChill: 36.814414462527715,
                    minWindChill: 29.5,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2023-12-30T00:00:00Z',
                    avgAmbientTemperature: 51.904629707336426,
                    minAmbientTemperature: 34.5,
                    maxAmbientTemperature: 66.0999984741211,
                    avgRelativeHumidity: 54.7037037037037,
                    avgWindSpeed: 2.382870381353078,
                    maxWindSpeed: 6.800000190734863,
                    avgWindDirection: 229.06944444444446,
                    avgWetBulbGlobalTemp: 53.171819777804664,
                    minWetBulbGlobalTemp: 47.25608588964338,
                    maxWetBulbGlobalTemp: 59.939306631501424,
                    avgWindChill: 45.8999989827474,
                    minWindChill: 42.79999923706055,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2023-12-31T00:00:00Z',
                    avgAmbientTemperature: 53.08009276566682,
                    minAmbientTemperature: 37.79999923706055,
                    maxAmbientTemperature: 67.4000015258789,
                    avgRelativeHumidity: 58.80555555555556,
                    avgWindSpeed: 2.6365740762816534,
                    maxWindSpeed: 10.800000190734863,
                    avgWindGust: 14.640000152587891,
                    maxWindGust: 19.100000381469727,
                    avgWindDirection: 146.1712962962963,
                    avgWetBulbGlobalTemp: 53.32454315315613,
                    minWetBulbGlobalTemp: 47.24162734092264,
                    maxWetBulbGlobalTemp: 61.354237483672634,
                    avgWindChill: 48,
                    minWindChill: 48,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2024-01-01T00:00:00Z',
                    avgAmbientTemperature: 40.88981495963203,
                    minAmbientTemperature: 33.5,
                    maxAmbientTemperature: 50,
                    avgRelativeHumidity: 71.5324074074074,
                    avgWindSpeed: 5.267129651546754,
                    maxWindSpeed: 11.300000190734863,
                    avgWindGust: 14.998718090546436,
                    maxWindGust: 19.100000381469727,
                    avgWindDirection: 55.03703703703704,
                    avgWetBulbGlobalTemp: 48.66650288079049,
                    minWetBulbGlobalTemp: 46.0771976848437,
                    maxWetBulbGlobalTemp: 51.33159350401718,
                    avgWindChill: 35.452469060450426,
                    minWindChill: 26,
                    avgRain1Hr: 0,
                    maxRain1Hr: 0,
                    rainTotal: 0
                },
                {
                    date: '2024-01-02T00:00:00Z',
                    avgAmbientTemperature: 42.27314809516624,
                    minAmbientTemperature: 32.79999923706055,
                    maxAmbientTemperature: 49.29999923706055,
                    avgRelativeHumidity: 76.3425925925926,
                    avgWindSpeed: 2.1930555577079454,
                    maxWindSpeed: 6.800000190734863,
                    avgWindGust: 11.849999904632568,
                    maxWindGust: 12.399999618530273,
                    avgWindDirection: 81.94907407407408,
                    avgWetBulbGlobalTemp: 48.52369399611608,
                    minWetBulbGlobalTemp: 45.747698492303975,
                    maxWetBulbGlobalTemp: 50.696809892454056,
                    avgWindChill: 44.5782052553617,
                    minWindChill: 35.70000076293945,
                    avgRain1Hr: 0.013796296375976116,
                    maxRain1Hr: 0.10999999940395355,
                    rainTotal: 0.25
                }
            ]
        });
    }

    static get HistoricalEt(): PwHistoricalEt {
        return new PwHistoricalEt({
            data: [
                {
                    date: '2023-12-27T00:00:00Z',
                    value: 0.07131922950167593
                },
                {
                    date: '2023-12-28T00:00:00Z',
                    value: 0.0578473957526498
                },
                {
                    date: '2023-12-29T00:00:00Z',
                    value: 0.08048001743686291
                },
                {
                    date: '2023-12-30T00:00:00Z',
                    value: 0.0697636309668539
                },
                {
                    date: '2023-12-31T00:00:00Z',
                    value: 0.05898260250049452
                },
                {
                    date: '2024-01-01T00:00:00Z',
                    value: 0.09553707010815782
                },
                {
                    date: '2024-01-02T00:00:00Z',
                    value: 0.026945410831808642
                }
            ]
        });
    }

    static get Forecast_1to5(): PwForecast {
        return new PwForecast({
            dailyForecast: [
                {
                    date: '2024-01-04T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 13.35,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 3.35,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0,
                            precipType: 'Liquid',
                            unit: 'cm'
                        },
                        observationTime: '2024-01-04T12:00:00Z',
                        weatherConditions: 'Fog',
                        humidity: {
                            value: 72,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 4.95,
                            unit: 'C'
                        },
                        wind: {
                            speed: 4.02,
                            gust: 4.02,
                            direction: 100,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.8,
                            accumulation: 0.07,
                            precipType: 'Liquid',
                            unit: 'cm'
                        },
                        observationTime: '2024-01-05T00:00:00Z',
                        weatherConditions: 'Rain Showers',
                        humidity: {
                            value: 76,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 4.95,
                            unit: 'C'
                        },
                        wind: {
                            speed: 4.68,
                            gust: 4.68,
                            direction: 110,
                            unit: 'm/s'
                        }
                    }
                },
                {
                    date: '2024-01-05T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 12.24,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 5.55,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.4,
                            accumulation: 0.91,
                            precipType: 'Liquid',
                            unit: 'cm'
                        },
                        observationTime: '2024-01-05T12:00:00Z',
                        weatherConditions: 'Chance of Rain Showers',
                        humidity: {
                            value: 83,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 6.15,
                            unit: 'C'
                        },
                        wind: {
                            speed: 3.72,
                            gust: 3.72,
                            direction: 120,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0.04,
                            precipType: 'Liquid',
                            unit: 'cm'
                        },
                        observationTime: '2024-01-06T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 83,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 6.65,
                            unit: 'C'
                        },
                        wind: {
                            speed: 2.6,
                            gust: 2.6,
                            direction: 300,
                            unit: 'm/s'
                        }
                    }
                },
                {
                    date: '2024-01-06T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 12.74,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 3.85,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0,
                            precipType: 'Liquid',
                            unit: 'cm'
                        },
                        observationTime: '2024-01-06T12:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 80,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 3.6,
                            gust: 3.6,
                            direction: 310,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0
                        },
                        observationTime: '2024-01-07T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 72,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 3.6,
                            gust: 3.6,
                            direction: 300,
                            unit: 'm/s'
                        }
                    }
                },
                {
                    date: '2024-01-07T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 14.95,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 2.24,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0
                        },
                        observationTime: '2024-01-07T12:00:00Z',
                        weatherConditions: 'Mostly Clear',
                        humidity: {
                            value: 76,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 3.6,
                            gust: 3.6,
                            direction: 220,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.1,
                            accumulation: 0
                        },
                        observationTime: '2024-01-08T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 67,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 6.45,
                            gust: 6.45,
                            direction: 160,
                            unit: 'm/s'
                        }
                    }
                },
                {
                    date: '2024-01-08T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 16.65,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 6.65,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.7,
                            accumulation: 0
                        },
                        observationTime: '2024-01-08T12:00:00Z',
                        weatherConditions: 'Showers and Thunderstorms',
                        humidity: {
                            value: 80,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 9.45,
                            unit: 'C'
                        },
                        wind: {
                            speed: 8.45,
                            gust: 8.45,
                            direction: 160,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.2,
                            accumulation: 0
                        },
                        observationTime: '2024-01-09T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 56,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 4.95,
                            unit: 'C'
                        },
                        wind: {
                            speed: 7.95,
                            gust: 7.95,
                            direction: 270,
                            unit: 'm/s'
                        }
                    }
                }
            ]
        });
    }

    static get Forecast_6to7(): PwForecast {
        return new PwForecast({
            dailyForecast: [
                {
                    date: '2024-01-09T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 14.95,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 2.24,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0,
                            accumulation: 0
                        },
                        observationTime: '2024-01-09T12:00:00Z',
                        weatherConditions: 'Mostly Clear',
                        humidity: {
                            value: 76,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 3.6,
                            gust: 3.6,
                            direction: 220,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.1,
                            accumulation: 0
                        },
                        observationTime: '2024-01-10T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 67,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 3.85,
                            unit: 'C'
                        },
                        wind: {
                            speed: 6.45,
                            gust: 6.45,
                            direction: 160,
                            unit: 'm/s'
                        }
                    }
                },
                {
                    date: '2024-01-10T12:00:00Z',
                    ambientTemperatureMax: {
                        value: 16.65,
                        unit: 'C'
                    },
                    ambientTemperatureMin: {
                        value: 6.65,
                        unit: 'C'
                    },
                    dayTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.7,
                            accumulation: 0
                        },
                        observationTime: '2024-01-10T12:00:00Z',
                        weatherConditions: 'Showers and Thunderstorms',
                        humidity: {
                            value: 80,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 9.45,
                            unit: 'C'
                        },
                        wind: {
                            speed: 8.45,
                            gust: 8.45,
                            direction: 160,
                            unit: 'm/s'
                        }
                    },
                    nightTime: {
                        precipitation: {
                            observationTime: '0001-01-01T00:00:00Z',
                            probability: 0.2,
                            accumulation: 0
                        },
                        observationTime: '2024-01-11T00:00:00Z',
                        weatherConditions: 'Partly Cloudy',
                        humidity: {
                            value: 56,
                            unit: '%'
                        },
                        dewPoint: {
                            value: 4.95,
                            unit: 'C'
                        },
                        wind: {
                            speed: 7.95,
                            gust: 7.95,
                            direction: 270,
                            unit: 'm/s'
                        }
                    }
                }
            ]
        });
    }
}
