/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import * as moment from 'moment';

export class KittyHawkLocation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.created) this.created = moment.utc(json.created).toDate();
        }
    }

    id: number;
    created: Date;
    uuid: string;
    name: string;
    nickname: string;
    google_place_id: string;
    latitude: number;
    longitude: number;
    zoom: number;
    min_zoom: number;
    max_zoom: number;
    flights_to_cover_location: number;
    street: string;
    city: string;
    region: string;
    postal_code: string;
    country: string;
    device_uuid: any;                           // TODO: Change to proper type.
    is_deleted: number;
    ndvi_bounds: number;
    thermal_bounds: number;
    ndvi_offset: number;
    thermal_offset: number;
    is_test_site: number;
    change_detection_enabled: number;
    spectrum_api_key: string;
    thermal_calibration_enabled: number;

}
