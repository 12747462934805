<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="lynx-pump-stations-widget-content toro-flex-child tmm-carousel-host">

            <div class="tmm-carousel-container" [ngClass]="{'single-item': pumpStations?.length <= 2}">
                <p-carousel [value]="pumpStations" [numVisible]="2" [numScroll]="2" contentClass="toro-carousel-content" dotsContainerClass="toro-dots-container">
                    <ng-template let-station pTemplate="item">

                        <div class="toro-widget-content-pane toro-component-shield-host">
                            <div class="toro-mini-mode-content-panel">

                                <div class="tmm-extremes-rows-container">
                                    <div class="tmm-extremes-row tmm-pump-info">
                                        <i class="toro-id-flow"></i>
                                        <span class="tmm-label-small tmm-ellipsis-text">{{station.name}}</span>
                                    </div>
                                    <div id="lps-mm-values" class="tmm-extremes-row" [ngClass]="{'is-metric': isMetric}">
                                        <div class="tmm-small-value tmm-lps-pressure">{{pressure[station.index]}}
                                            <span class="tmm-small-value-units">{{pressureUnits}}</span></div>
                                        <div class="tmm-small-value tmm-lps-flow">{{flow[station.index]}}
                                            <span class="tmm-small-value-units">{{flowUnits}}</span></div>
                                    </div>
                                </div>

                            </div>

                            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                        </div>

                    </ng-template>
                </p-carousel>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="lynx-pump-stations-widget-content toro-flex-child">
            <div id="lps-content-pane">
                <div id="lps-station-list">
                    <toro-lynx-pump-station-card
                            *ngFor="let station of pumpStations"
                            [pumpStation]="station"
                            [language]="language"
                    ></toro-lynx-pump-station-card>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
