export class CalAmpAvlAccumulatorData {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.value = +json.value;
            this.index = +json.index;
        }
    }

    label: string;
    value: number;
    index: number;
    type: string;
}
