<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="toro-dlg-title" class="toro-flex-child">{{title | translate | titlecase}}</div>
            <div class="toro-flex-child" id="chart-dlg-header-alerts">
                <toro-turf-guard-alerts-header [turfGuardWidgetData]="turfGuardWidgetData"></toro-turf-guard-alerts-header>
            </div>
        </div>
    </p-header>

    <div id="toro-turf-guard-chart-dlg-container" style="width: 850px; height: 500px;">
            <ng-content></ng-content>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>

