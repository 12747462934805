import { GcsaaChannelItem } from './gcsaa-channel-item.model';

export class GcsaaChannel {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.item) {
                this.items = json.item.map(i => new GcsaaChannelItem(i));

                delete this['item'];
                delete this['a10:contributor'];
                delete this['a10:link'];
            }
        }
    }

    title: string;
    link: string;
    description: string;
    items: GcsaaChannelItem[] = [];
}
