export class NsnConnectDevice {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    computerName: string;
    isOnline = false;
    connectUrl: string;
}
