import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { TurfCloudCourseTrakk } from '../../../../../api/turf-cloud/models/turf-cloud-course-trakk.model';
import { TurfCloudManagerService } from '../../../../../api/turf-cloud/turf-cloud-manager.service';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@UntilDestroy()
@Component({
    selector: 'toro-widget-turf-cloud-course-trakk',
    templateUrl: './widget-turf-cloud-course-trakk.component.html',
    styleUrls: ['./widget-turf-cloud-course-trakk.component.less']
})
export class WidgetTurfCloudCourseTrakkComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#9ACA3C';
    title = 'WIDGET.TURF_CLOUD_COURSE_TRAKK';

    courseTrakkData: TurfCloudCourseTrakk;
    currentMonth: string;
    currentYear: string;
    investedInToday: string;
    investedInMonth: string;
    investedInYear: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                private turfCloudManager: TurfCloudManagerService,
                private userFormatService: UserFormatService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        const m = moment();
        this.currentMonth = m.format('MMMM');
        this.currentYear = m.format('YYYY');

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TurfCloudCourseTrakkWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.turfCloudManager.getCourseTrakk()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((courseTrakk: TurfCloudCourseTrakk) => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = courseTrakk.lastUpdated;
                this.courseTrakkData = courseTrakk;
                this.setComponentData();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTurfCloudLinkClick() {
        this.analyticsService.event(AnalyticsEvent.TurfCloudCourseTrakkWidgetGoToTurfCloud, AnalyticsCategory.Interaction);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        this.investedInToday = this.userFormatService.toUserCurrency(this.courseTrakkData.investedInCurrentDay);
        this.investedInMonth = this.userFormatService.toUserCurrency(this.courseTrakkData.investedInCurrentMonth);
        this.investedInYear = this.userFormatService.toUserCurrency(this.courseTrakkData.investedInCurrentYear);
    }
}
