import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export let AppInjector: Injector;

// NOTE: This module exists solely to bring in another instance of AppInjector for use by the DemoModeMockData Service.

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class DemoModule {
    constructor(injector: Injector) {
        AppInjector = injector;
    }
}
