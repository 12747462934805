import { SpecFsSession } from './spec-fs-Session.model';

export class SpecFsCollection {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.sessions != null) this.sessions = json.sessions.map(s => new SpecFsSession(s));
        }
    }

    collectionName: string;
    sessions: SpecFsSession[] = []
}
