import { SoilScoutDevice } from '../../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGroup } from '../../../../../api/soil-scout/models/soil-scout-group.model';

export class SoilScoutChartData {

    constructor(groups: SoilScoutGroup[], devices: SoilScoutDevice[]) {
        this.groups = groups;
        this.devices = devices;
    }

    groups: SoilScoutGroup[] = [];
    devices: SoilScoutDevice[] = [];
}
