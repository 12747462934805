export class SoilScoutGaugeRange {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.range4Color == null) this.range4Color = this.range3Color;
        }
    }

    rangeMin = 0;
    rangeMax = 100;

    range1UpperBoundary: number;
    range2UpperBoundary: number;
    range3UpperBoundary: number;

    range1Color = 'darkgray';
    range2Color = 'darkgray';
    range3Color = 'darkgray';
    range4Color = 'darkgray';
}
