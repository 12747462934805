import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';
import { WeatherAveragesConfig } from '../../core/dashboard/widgets/weather-averages/models/weather-averages-config.model';

@Injectable({
    providedIn: 'root'
})
export class WeatherAveragesManagerService {

    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor() {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getWeatherAveragesConfig(toroGridsterWidget: ToroGridsterWidget): Observable<WeatherAveragesConfig> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.WeatherAveragesConfig); }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isWeatherAveragesConfig')) {
            return of(<WeatherAveragesConfig>toroGridsterWidget.config);
        }

        const config = new WeatherAveragesConfig(toroGridsterWidget.config);
        return of(config);
    }
}
