export class PwLightning {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    delay: number;              // 0 = All Clear, !=0 = Active Delay
    allClearMinutes: number;
    radii: number;
}
