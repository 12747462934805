import * as Highcharts from 'highcharts';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { GddGraphData } from '../../models/gdd-graph-data.model';
import { NutrientGraphData } from '../../models/nutrient-graph-data.model';
import { ToroEnums } from '../../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import PlaybooksChartType = ToroEnums.PlaybooksChartType;

@UntilDestroy()
@Component({
    selector: 'toro-playbooks-chart-wrapper',
    templateUrl: './playbooks-chart-wrapper.component.html',
    styleUrls: ['./playbooks-chart-wrapper.component.less']
})
export class PlaybooksChartWrapperComponent implements OnInit {
    readonly seriesLegendInfo: { name: string, color: string }[] = [
        { name: 'N', color: ToroEnums.PlaybooksNutrientColor.N },
        { name: 'P', color: ToroEnums.PlaybooksNutrientColor.P },
        { name: 'K', color: ToroEnums.PlaybooksNutrientColor.K },
        { name: 'Fe', color: ToroEnums.PlaybooksNutrientColor.Fe },
        { name: 'Mn', color: ToroEnums.PlaybooksNutrientColor.Mn }
    ];

    PlaybooksChartType = ToroEnums.PlaybooksChartType;

    @Input() height = 134;
    @Input() graphType: PlaybooksChartType;

    private _data: NutrientGraphData | GddGraphData;
    @Input() set data(value: NutrientGraphData | GddGraphData) {
        if (!value) { return; }

        this._data = value;
    }

    get data(): NutrientGraphData | GddGraphData {
        return this._data;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;

    showModalGraph = false;
    modalTitle: string;
    showChart = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.setComponentData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onChartClick() {
        if (this.showModalGraph) { return; }
        this.toggleChartDisplay(true);
    }

    // Method to delay dispaly of chart in modal dialog to enable HighChart to draw after modal is displayed. This
    // enables the mouse tracking of the HighChart to function properly on a center position pDialog.
    toggleChartDisplay(show = false) {
        this.showModalGraph = show;
        setTimeout(() => this.showChart = show, 100);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        // this.setSeriesNames();

        this.modalTitle = `${this.translateService.instant('WIDGET.PLAYBOOKS')} - `;

        switch (this.graphType) {
            case PlaybooksChartType.Nutrient:
                this.modalTitle += `${this.translateService.instant('STRINGS.NUTRIENTS')}`;
                break;
            case PlaybooksChartType.Gdd:
                this.modalTitle += `${this.translateService.instant('STRINGS.GDD_SINCE_LAST_APP')}`;
                break;
        }
    }

    // private setSeriesNames() {
    //     switch (this.graphType) {
    //         case WeatherGraphType.Wind:
    //             this.series1Name = 'STRINGS.WIND';
    //             this.series1Units = this.userFormatService.windSpeedUnits;
    //             this.series1Color = WeatherGraphChartColor.Wind;
    //             break;
    //         case WeatherGraphType.Temperature:
    //             this.series1Name = 'STRINGS.DEW_POINT';
    //             this.series1Units = `˚${this.userFormatService.temperatureUnits}`;
    //             this.series1Color = WeatherGraphChartColor.DewPoint;
    //             this.series2Name = 'STRINGS.TEMPERATURE';
    //             this.series2Units = `˚${this.userFormatService.temperatureUnits}`;
    //             this.series2Color = WeatherGraphChartColor.Temperature;
    //             break;
    //         case WeatherGraphType.Precipitation:
    //             this.series1Name = 'STRINGS.PRECIPITATION';
    //             this.series1Units = this.userFormatService.precipitationUnits;
    //             this.series1Color = WeatherGraphChartColor.Precipitation;
    //             break;
    //         case WeatherGraphType.SolarRadiation:
    //             this.series1Name = 'STRINGS.SOLAR_RADIATION';
    //             this.series1Units = this.userFormatService.solarRadiationUnits;
    //             this.series1Color = WeatherGraphChartColor.SolarRadiation;
    //             break;
    //         case WeatherGraphType.RelativeHumidity:
    //             this.series1Name = 'STRINGS.RELATIVE_HUMIDITY';
    //             this.series1Units = '%';
    //             this.series1Color = WeatherGraphChartColor.RelativeHumidity;
    //     }
    // }

}
