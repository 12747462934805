/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LynxArea } from './models/lynx-area.model';
import { LynxCourse } from './models/lynx-course.model';
import { LynxPumpStation } from './models/lynx-pump-station.model';
import { LynxPumpStatus } from './models/lynx-pump-status.model';
import { LynxWeatherStation } from './models/lynx-weather-station.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RunningStationsSummary } from './models/running-stations-summary.model';
import { TimeStampedCollection } from '../_common/time-stamped-collection.model';

@Injectable({
    providedIn: 'root'
})
export class LynxApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCourses(includeHierarchy = false): Observable<LynxCourse[]> {
        return this.apiService.apiRequest<any>(LynxApiService.getCoursesUrl(includeHierarchy), HttpMethod.Get)
            .pipe(map((response: LynxCourse[]) => response.map(item => new LynxCourse(item))));
    }

    // TODO: To be deprecated.
    getPumpStation(): Observable<LynxPumpStation> {
        return this.apiService.apiRequest<any>(LynxApiService.pumpStationDataUrl, HttpMethod.Get)
            .pipe(map((response: LynxPumpStation) => new LynxPumpStation(response)));
    }

    getPumps(includeConfig: boolean): Observable<LynxPumpStation[]> {
        return this.apiService.apiRequest<any>(LynxApiService.getPumpsUrl(includeConfig), HttpMethod.Get)
            .pipe(map((response: LynxPumpStation[]) => response.map(p => new LynxPumpStation(p))));
    }

    // TODO: To be deprecated.
    getPumpStatuses(siteId: number): Observable<LynxPumpStatus[]> {
        return this.apiService.apiRequest<any>(LynxApiService.getPumpStatusesUrl(siteId), HttpMethod.Get)
            .pipe(map((response: LynxPumpStatus[]) => response.map(ps => new LynxPumpStatus(ps))));
    }

    getRunningStationsDetails(courseId: number): Observable<TimeStampedCollection<LynxArea[]>> {
        return this.apiService.apiRequest<any>(LynxApiService.getRunningStationsDetailsUrl(courseId), HttpMethod.Get)
            .pipe(map(response =>
                new TimeStampedCollection<LynxArea[]>(response.lastUpdated, response.areas.map(item => new LynxArea(item))))
            );
    }

    getRunningStationsSummary(courseId: number): Observable<TimeStampedCollection<RunningStationsSummary[]>> {
        return this.apiService.apiRequest<any>(LynxApiService.getRunningStationsSummaryUrl(courseId), HttpMethod.Get)
            .pipe(map(response =>
                new TimeStampedCollection<RunningStationsSummary[]>(response.lastUpdated, response.items.map(item => new RunningStationsSummary(item))))
            );
    }

    getWeatherStations(includeStatus: boolean): Observable<LynxWeatherStation[]> {
        return this.apiService.apiRequest<any>(LynxApiService.getWeatherStationsUrl(includeStatus), HttpMethod.Get)
            .pipe(map((response: LynxWeatherStation[]) => response.map(ws => new LynxWeatherStation(ws)) ));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}lynx`; }

    private static getCoursesUrl(includeHierarchy = false): string { return `${LynxApiService.baseUrl}/courses?includeHierarchy=${includeHierarchy}`; }

    // To be deprecated
    private static get pumpStationDataUrl(): string { return `${LynxApiService.baseUrl}/pump`; }

    private static getPumpsUrl(includeConfig: boolean): string { return `${LynxApiService.baseUrl}/pumps?includeConfig=${includeConfig}`; }

    private static getPumpStatusesUrl(siteId: number): string { return `${LynxApiService.baseUrl}/pumps/status/${siteId}`; }

    private static getRunningStationsDetailsUrl(courseId: number): string { return `${LynxApiService.baseUrl}/activeStations/details?courseId=${courseId}`; }

    private static getRunningStationsSummaryUrl(courseId: number): string { return `${LynxApiService.baseUrl}/activeStations/summary?courseId=${courseId}`; }

    private static getWeatherStationsUrl(includeStatus: boolean): string { return `${LynxApiService.baseUrl}/weatherStations?includeStatus=${includeStatus}`; }

}
