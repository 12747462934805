import WidgetType = ToroEnums.WidgetType;

import { IWidgetDataSettings } from '../../_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import PwChartDataType = ToroEnums.PwChartDataType;

export class PerryWeatherConfig implements IWidgetDataSettings {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    isPerryWeatherConfig = true;
    showCharts = true;
    mainChartType = PwChartDataType.PrecipHistorical;
    precipChartType = PwChartDataType.PrecipHistorical;

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;
}
