export class LoginStateChange {

    constructor(isLoggedIn: boolean, reason = '') {
        this.isLoggedIn = isLoggedIn;
        this.reason = reason;
    }

    isLoggedIn: boolean;
    reason = '';
}
