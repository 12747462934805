/* eslint-disable max-len, @typescript-eslint/dot-notation, @angular-eslint/directive-class-suffix */
import * as moment from 'moment';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../common/services/analytics.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
import { IWidgetDataSettings } from '../../../api/_common/interfaces/widget-data-settings.interface';
import { MenuItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from './toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { WidgetDataSettings } from './_models/widget-data-settings.model';

import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import WidgetType = ToroEnums.WidgetType;

@UntilDestroy()
@Directive()
export abstract class ToroDashboardWidget implements OnInit, OnDestroy {
    @Input() associatedWidget: ToroGridsterWidget;

    public readonly WidgetSingleRowHeight = 330;
    public readonly WidgetDoubleRowHeight = 670;

    hasInitialSizeBeenSet = false;
    isBusy = false;
    isWidgetInitialized = false;
    lastUpdated: { duration: string, scale: string } = null;
    isGridsterInMobileMode = false;                             // If in mobile mode, we will 'temporarily' display the single column mode for each widget.
    itemCols = 1;                                               // The actual columns (width) of the widget as seen by Gridster
    displayCols = 0;                                            // The widget content display mode (e.g., TG col=1 shows a gauge while col>1 shows a graph).
    displayRows = 0;
    isResizing = false;
    updateTimerRef: NodeJS.Timer;
    // Interval on which the app calls out to the api. API reaches out to 3rd party data providers on its own, separate interval.
    updateIntervalInMinutes = 5;
    staleDataThresholdInMinutes = 15;
    alertBannerDismissed = false;
    widgetMenuItems: MenuItem[] = [];
    isUnableToFetchData = false;
    unableToFetchDataReason: string;
    unableToFetchDataLinkHtml: string;
    hasNoData = false;
    hasSuccessfullyRetrievedData = false;
    xLoc = 0;
    yLoc = 0;
    showMiniModeModal = false;
    language = 'en-us';

    private _alertText: string;
    private _lastUpdateTimestamp;
    private widgetElapsedTimeInMinutes = 0;

    abstract iconColor: string;
    abstract title: string;

    protected abstract getWidgetData(isManualRefresh);

    // Gets friendly name displayed in GA for widget events.
    protected abstract get analyticsWidgetName(): string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    protected constructor(protected analyticsService: AnalyticsService,
                          protected broadcastService: BroadcastService,
                          protected dashUserManager: DashUserManagerService,
                          protected deviceManager: DeviceManagerService,
                          protected translateService: TranslateService,
    ) {
        this.language = this.dashUserManager.language;

        // Monitor any changes to the Gridster's mobile mode.
        this.deviceManager.gridsterMobileModeChange
            .pipe(untilDestroyed(this))
            .subscribe((state: { isMobileMode: boolean }) => {
                this.isGridsterInMobileMode = state.isMobileMode;
                this.displayCols = !this.isGridsterInMobileMode ? this.itemCols : 1;
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);

        this.broadcastService.appReactivate
            .pipe(untilDestroyed(this))
            .subscribe(() => this.getWidgetData(false))

        // Get the state of Gridster's mobile mode on component load.
        this.isGridsterInMobileMode = this.deviceManager.isGridsterInMobileMode;

    }

    ngOnInit(): void {
        this.isBusy = true;

        this.setUpdateIntervalForWidget(this.associatedWidget.type);
        this.lastUpdateTimestamp = new Date();

        this.setWidgetMenu();

        if (this.associatedWidget != null) {
            this.associatedWidget.resizeCallback = item => this.widgetResized(item);
            this.associatedWidget.resizeStartCallback = item => this.widgetResizeStart(item);
            this.associatedWidget.resizeStopCallback = item => this.widgetResizeStop(item);
        }

        if (this.updateIntervalInMinutes && this.updateIntervalInMinutes > 0) {
            this.updateTimerRef = setInterval(() => {
                if (++this.widgetElapsedTimeInMinutes % this.updateIntervalInMinutes === 0) {
                    this.widgetElapsedTimeInMinutes = 0;
                    this.getWidgetData(false);
                }
                this.setLastUpdatedInfo();
            }, 1000 * 60);
        }

        this.setLastUpdatedInfo();

        // Monitor any changes to data settings (e.g., data refresh interval).
        this.broadcastService.widgetDataSettingsChange
            .pipe(
                untilDestroyed(this),
                filter((settings: WidgetDataSettings) => settings.widgetType === this.associatedWidget.type)
            )
            .subscribe((settings: WidgetDataSettings) => {
                this.updateIntervalInMinutes = settings.dataRefreshIntervalMinutes;
                this.staleDataThresholdInMinutes = settings.staleDataThresholdMinutes;

                // Recalculate stale data alert.
                this.setLastUpdatedInfo();
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setLastUpdatedInfo();
                this.setWidgetMenu();
            });

        // Store initial Location
        this.xLoc = this.associatedWidget.x;
        this.yLoc = this.associatedWidget.y;
        const eventDetails = `Location: (${this.xLoc}, ${this.yLoc})`;
        this.analyticsService.widgetEvent(`${AnalyticsEvent.WidgetLoaded}`, AnalyticsCategory.Widgets, `${this.analyticsWidgetName}`, eventDetails);
    }

    ngOnDestroy(): void {
        clearInterval(this.updateTimerRef);
    }

    // =========================================================================================================================================================
    // Public
    // =========================================================================================================================================================

    get alertText() {
        return this._alertText;
    }

    set alertText(value: string) {
        if (this._alertText === value) return;

        this._alertText = value;
        if (value === null) this.alertBannerDismissed = true;
    }

    // =========================================================================================================================================================
    // Protected Methods
    // =========================================================================================================================================================

    protected widgetResized(item: ToroGridsterWidget) {
        if (item.cols === this.displayCols && item.rows === this.displayRows) {
            if (this.xLoc !== item.x || this.yLoc !== item.y) {
                const eventDetails = `From: (${this.xLoc}, ${this.yLoc}), To: (${item.x}, ${item.y})`;
                this.analyticsService.widgetEvent(`${AnalyticsEvent.WidgetLocationChanged}`, AnalyticsCategory.Widgets, `${this.analyticsWidgetName}`, eventDetails);
                this.xLoc = item.x;
                this.yLoc = item.y;
            }
            return;
        }

        this.itemCols = item.cols;
        this.displayCols = this.isGridsterInMobileMode ? 1 : item.cols;
        this.displayRows = this.isGridsterInMobileMode ? 1 : item.rows;

        if (!this.hasInitialSizeBeenSet) {
            this.hasInitialSizeBeenSet = true;
            this.getWidgetData(false);
        }

        this.logResizeEvent();

        /** Override in derived classes if action required on widget resize. */
    }

    protected set lastUpdateTimestamp(value: Date) {
        this._lastUpdateTimestamp = value;
        this.setLastUpdatedInfo();
    }

    protected get lastUpdateTimestamp(): Date {
        return this._lastUpdateTimestamp;
    }

    protected widgetResizeStart(item: ToroGridsterWidget) {
        this.isResizing = true;
    }

    protected widgetResizeStop(item: ToroGridsterWidget) {
        this.isResizing = false;
    }

    protected showStaleDataWarning(duration: number, scale: string) {
        const localizedString = this.translateService.instant(scale).toLowerCase();
        this.alertText = this.translateService.instant('ERR_MSG.STALE_WIDGET_DATA_WARNING', { timeValue: duration, timeUnits: localizedString });
    }

    protected clearAlertBanner() {
        this._alertText = null;
    }

    protected setWidgetMenu() {
        this.widgetMenuItems = [
            { label: this.translateService.instant('STRINGS.REFRESH_DATA'), icon: 'pi pi-fw pi-refresh', command: this.manualDataRefresh.bind(this) },
            { label: `${this.translateService.instant('STRINGS.DATA_SETTINGS')}...`, icon: 'pi pi-fw pi-cog', command: this.showDataSettingsDialog.bind(this) },
        ];
    }

    protected setIsUnableToFetchData(reason?: string, linkHtml?: string) {
        this.isBusy = false;
        if (reason) {
            this.unableToFetchDataReason = reason;
            this.unableToFetchDataLinkHtml = linkHtml;
        }
        this.isUnableToFetchData = true;
    }

    protected clearIsUnableToFetchData() {
        this.isUnableToFetchData = false;
        this.unableToFetchDataReason = null;
        this.unableToFetchDataLinkHtml = null;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected showDataSettingsDialog() {
        this.broadcastService.showDataSettingsDialog.next(this.associatedWidget.config);

        const config = this.associatedWidget.config;
        const eventDetails = `ShowStaleDataAlert: ${config.showStaleDataAlert}, RefreshInterval: ${config.dataRefreshIntervalMinutes}, StaticThreshold: ${config.staleDataThresholdMinutes}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.DataSettingsShowDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    protected onLaunchModalWidget() {
        this.showMiniModeModal = true;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    protected setLastUpdatedInfo() {
        const dataAgeDuration = moment.duration(moment().diff(this.lastUpdateTimestamp));
        let duration: number;
        let scale: string;

        // Special case for seconds. Only update the last updated footer and bail.
        if (dataAgeDuration.asSeconds() < 60) {
            this.setLastUpdatedLabel('STRINGS.DURATION_A_FEW', 'STRINGS.SECONDS');
            this.clearAlertBanner();
            return;
        }

        if (dataAgeDuration.asMinutes() < 60) {
            duration = dataAgeDuration.asMinutes();
            scale = duration < 2 ? 'STRINGS.MINUTE' : 'STRINGS.MINUTES';
        } else if (dataAgeDuration.asHours() < 24) {
            duration = dataAgeDuration.asHours();
            scale = duration < 2 ? 'STRINGS.HOUR' : 'STRINGS.HOURS';
        } else if (dataAgeDuration.asDays() < 7) {
            duration = dataAgeDuration.asDays();
            scale = duration < 2 ? 'STRINGS.DAY' : 'STRINGS.DAYS';
        } else if (dataAgeDuration.asWeeks()) {
            duration = dataAgeDuration.asWeeks();
            scale = duration < 2 ? 'STRINGS.WEEK' : 'STRINGS.WEEKS';
        } else {
            return;
        }

        this.setLastUpdatedLabel(Math.floor(duration).toString(), scale);

        if (!this.alertBannerDismissed && dataAgeDuration.asMinutes() > this.staleDataThresholdInMinutes) {
            this.showStaleDataWarning(Math.floor(duration), scale);
        } else if (this.alertText !== null) {
            this.clearAlertBanner();
        }
    }

    private setLastUpdatedLabel(duration: string, scale: string) {
        this.lastUpdated = { duration: this.translateService.instant(duration).toLowerCase(), scale: this.translateService.instant(scale).toLowerCase() };
    }

    private setUpdateIntervalForWidget(widgetType: WidgetType) {
        if (widgetType === WidgetType.GreenSight) { return; }

        if (!environment.isDemoMode) {
            this.updateIntervalInMinutes = (<IWidgetDataSettings>this.associatedWidget.config).dataRefreshIntervalMinutes;
            this.staleDataThresholdInMinutes = (<IWidgetDataSettings>this.associatedWidget.config).staleDataThresholdMinutes;
        } else {
            this.updateIntervalInMinutes = 5;
            this.staleDataThresholdInMinutes = 15;
        }
    }

    private manualDataRefresh() {
        this.getWidgetData(true);

        this.analyticsService.widgetEvent(`${AnalyticsEvent.ManualDataRefresh}`, AnalyticsCategory.Interaction, `${this.analyticsWidgetName}`);
    }

    private logResizeEvent() {
        // Don't log resize event on initial load.
        if (this.isBusy) { return; }

        const eventDetails = `Size: (${this.displayCols}, ${this.displayRows})`;
        this.analyticsService.widgetEvent(`${AnalyticsEvent.WidgetResized}`, AnalyticsCategory.Widgets, `${this.analyticsWidgetName}`, eventDetails);
    }

}
