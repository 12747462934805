<div id="dashboard-header-{{widgetId}}" class="toro-dashboard-widget-header" [ngClass]="{'is-locked': !isMovable}">
    <div id="header-title-{{widgetId}}" class="toro-widget-header-icon-n-title toro-component-shield-host" [ngStyle]="{'max-width': maxHeaderTextWidth}">
        <div *ngIf="titleIcon && showTitleIcon" class="toro-widget-header-icon">
            <ng-container *ngIf="isTitleIconTemplate" [ngTemplateOutlet]="titleIcon"></ng-container>
            <div *ngIf="isTitleIconString">{{titleIcon}}a</div>
        </div>
        <div class="toro-widget-header-title" [ngClass]="language">{{title | translate}}</div>
        <toro-component-shield></toro-component-shield>
    </div>

    <div id="header-middle-{{widgetId}}" class="toro-widget-header-middle-section toro-component-shield-host" [ngClass]="{'widget-non-draggable': hasMiddleContentClickSubscribers}">
        <ng-container *ngIf="middleContent" [ngTemplateOutlet]="middleContent"></ng-container>
        <toro-component-shield #middleContentShield></toro-component-shield>
    </div>

    <div id="header-toolbar-{{widgetId}}" class="toro-widget-header-toolbar widget-non-draggable">
        <div *ngIf="showLinkButton" class="toro-widget-toolbar-button" (click)="onGotoSite()"><fa-icon icon="external-link-alt"></fa-icon></div>
        <div *ngIf="showInfoButton" class="toro-widget-toolbar-button" (click)="onShowInfo()"><fa-icon icon="info-circle"></fa-icon></div>
        <ng-container *ngIf="customToolbar" [ngTemplateOutlet]="customToolbar"></ng-container>
        <div class="toro-widget-header-toolbar-menu">
            <p-menu #menu [popup]="true" [model]="menuItems" [appendTo]="'body'" [styleClass]="menuStyleClass"></p-menu>
            <div class="toro-widget-toolbar-button" (click)="menu.toggle($event)"><fa-icon icon="ellipsis-v"></fa-icon></div>
        </div>
    </div>
</div>
