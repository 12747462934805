import { StationStatusItem } from './station-status-item.model';

export class StationStatuses {
    constructor(runningStations: StationStatusItem[], ranUnderStations: StationStatusItem[]) {
        this.runningStations = runningStations;
        this.ranUnderStations = ranUnderStations;

        this.runningCount = this.runningStations?.length ?? 0;
        this.ranUnderCount = this.ranUnderStations?.length ?? 0;
    }

    runningStations: StationStatusItem[];
    ranUnderStations: StationStatusItem[];
    runningCount: number;
    ranUnderCount: number;
}
