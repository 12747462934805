import * as moment from 'moment';
import { PwLightning } from './pw-lightning.model';
import { PwObsPrecipitation } from './pw-obs-precipitation.model';
import { PwValueUnit } from './pw-value-unit.model';
import { PwWind } from './pw-wind.model';

export class PwObservationData {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.precipitation) this.precipitation = new PwObsPrecipitation(json.precipitation);
            if (json.observationTime) this.observationTime = moment.utc(json.observationTime).toDate();
            if (json.ambientTemperature) this.ambientTemperature = new PwValueUnit(json.ambientTemperature);
            if (json.wetBulbGlobalTemp) this.wetBulbGlobalTemp = new PwValueUnit(json.wetBulbGlobalTemp);
            if (json.humidity) this.humidity = new PwValueUnit(json.humidity);
            if (json.dewPoint) this.dewPoint = new PwValueUnit(json.dewPoint);
            if (json.wind) this.wind = new PwWind(json.wind);
            if (json.heatIndex) this.heatIndex = new PwValueUnit(json.heatIndex)
            if (json.lightning) this.lightning = new PwLightning(json.lightning)
        }
    }

    precipitation: PwObsPrecipitation;
    observationTime: Date;
    ambientTemperature: PwValueUnit;
    wetBulbGlobalTemp: PwValueUnit;
    humidity: PwValueUnit;
    dewPoint: PwValueUnit;
    wind: PwWind;
    heatIndex: PwValueUnit;
    lightning: PwLightning;
}
