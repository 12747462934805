/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WidgetDataSettings } from '../../_models/widget-data-settings.model';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import WidgetType = ToroEnums.WidgetType;

@Component({
    selector: 'toro-data-settings-dlg',
    templateUrl: './data-settings-dlg.component.html',
    styleUrls: ['./data-settings-dlg.component.less']
})
export class DataSettingsDlgComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();

    @Input() analyticsWidgetName: string;
    @Input() widgetConfig: any;

    isLoaded = false;
    showStaleDataAlert = true;
    staleDataThreshold = 15;
    dataRefreshInterval = 5;

    minRefreshInterval = 1;
    maxRefreshInterval = 30;
    minStaleInterval = 1;
    maxStaleInterval = 30;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                private widgetManager: WidgetManagerService
    ) { }

    ngOnInit(): void {
        switch (this.widgetConfig.widgetType) {
            case WidgetType.TaskTrackerLaborSummary:
            case WidgetType.TaskTrackerDailyMeasurements:
            case WidgetType.TaskTrackerSafety:
            case WidgetType.EzLocator:
                this.minRefreshInterval = 1;
                this.maxRefreshInterval = 90;
                this.minStaleInterval = 1;
                this.maxStaleInterval = 99;
        }

        // This is to allow dialog to set focus to cancel button before other buttons are loaded.
        setTimeout(() => {
            this.isLoaded = true;
            this.showStaleDataAlert = this.widgetConfig.showStaleDataAlert;
            this.dataRefreshInterval = this.widgetConfig.dataRefreshIntervalMinutes;
            this.staleDataThreshold = this.widgetConfig.staleDataThresholdMinutes;
        });

    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCancel() {
        this.analyticsService.widgetEvent(AnalyticsEvent.DataSettingsCancelDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        this.cancel.emit();
    }

    onSave() {
        this.widgetConfig.showStaleDataAlert = this.showStaleDataAlert;
        this.widgetConfig.dataRefreshIntervalMinutes = this.dataRefreshInterval;
        this.widgetConfig.staleDataThresholdMinutes = this.staleDataThreshold;

        const sources: Observable<any>[] = [
            this.widgetManager.updateWidgetConfig(this.widgetConfig.widgetType, this.widgetConfig)
        ];

        // TODO: Remove if we decide not to go down this path.
        // const dataRefreshUpdateInfo = DataSettingsDlgComponent.getDataRefreshUpdateInfo(this.widgetConfig.widgetType, this.dataRefreshInterval);
        // if (dataRefreshUpdateInfo) { sources.push(this.siteDataManager.updateDataRefreshInterval(dataRefreshUpdateInfo)); }

        forkJoin(sources)
            .subscribe(() => {
                const settings: WidgetDataSettings = {
                    widgetType: this.widgetConfig.widgetType,
                    showStaleDataAlert: this.widgetConfig.showStaleDataAlert,
                    dataRefreshIntervalMinutes: this.widgetConfig.dataRefreshIntervalMinutes,
                    staleDataThresholdMinutes: this.widgetConfig.staleDataThresholdMinutes
                };
                this.broadcastService.widgetDataSettingsChange.next(settings);
                this.save.emit();

                const eventDetails = `ShowStaleDataAlert: ${this.widgetConfig.showStaleDataAlert}, RefreshInterval: ${this.widgetConfig.dataRefreshIntervalMinutes}, StaticThreshold: ${this.widgetConfig.staleDataThresholdMinutes}`;
                this.analyticsService.widgetEvent(AnalyticsEvent.DataSettingsUpdate, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    // TODO: Remove if we decide not to go down this path.
    // private static getDataRefreshUpdateInfo(widgetType: WidgetType, refreshIntervalInMinutes: number): SiteDataUpdateInfo {
    //     let siteDataCategory: SiteDataCategoryType;
    //
    //     switch (widgetType) {
    //         case WidgetType.EquipmentTracking:
    //             siteDataCategory = SiteDataCategoryType.AssetTracking;
    //             break;
    //         case WidgetType.Evapotranspiration:
    //         case WidgetType.FrostWarning:
    //         case WidgetType.LocalWeatherForecast:
    //         case WidgetType.WeatherGraphs:
    //             siteDataCategory = SiteDataCategoryType.Weather;
    //             break;
    //         case WidgetType.LynxPumpStation:
    //         case WidgetType.LynxRunningStations:
    //             siteDataCategory = SiteDataCategoryType.Lynx;
    //             break;
    //         case WidgetType.MyTurfMaintenance:
    //         case WidgetType.MyTurfOrders:
    //             siteDataCategory = SiteDataCategoryType.MyTurf;
    //             break;
    //         case WidgetType.TurfGuardMoisture:
    //         case WidgetType.TurfGuardTemperature:
    //         case WidgetType.TurfGuardSalinity:
    //             siteDataCategory = SiteDataCategoryType.TurfGuard;
    //             break;
    //         case WidgetType.TurfCloudCourseTrakk:
    //         case WidgetType.TurfCloudLaborStats:
    //             siteDataCategory = SiteDataCategoryType.TurfCloud;
    //             break;
    //         default:
    //             return null;
    //     }
    //
    //     return new SiteDataUpdateInfo(siteDataCategory, refreshIntervalInMinutes);
    // }

}
