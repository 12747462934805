
export class FrostForecastDatum {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.time) { this.time = new Date(json.time); }
        }
    }

    time: Date;
    risk: number;
    avgTemp: number;
    minTemp: number;
    maxTemp: number;
}
