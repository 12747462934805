export class SoilScoutDeviceModemStatus {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    connection_type: string
    signal_strength: number;
}
