import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { Injectable } from '@angular/core';
import { LoginStateChange } from '../../api/auth/models/login-state-change';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

    constructor(private authManager: AuthManagerService,
                private router: Router) { }

    // @ts-ignore
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        // Because authManager.init is called in the AppInitializer service, if we have a valid, cached (local storage) DashAuthenticatedUser, authManager will
        // likely have loaded the user and fired the dashLoginStateChange event before *this* service loads. Checking authManager for both a logged in SSO
        // and DashApi user allows us to return immediately if they exist and avoid any timing issues waiting for the dashLoginStateChange event.
        if (this.authManager.isSsoUserLoggedIn && this.authManager.isDashAuthenticatedUserLoggedIn) {
            return of(true);
        }

        return new Observable(observer => {
            this.authManager.dashLoginStateChange
                .pipe(untilDestroyed(this))
                .subscribe((change: LoginStateChange) => {
                    if (change.isLoggedIn) {
                        observer.next(true);
                        observer.complete();
                        return;
                    }

                    if (this.authManager.toroSsoUser) {
                        // Special handling for NSN errors. We will show a NSN prompt for NSN errors instead of directly logging out user.
                        if (change.reason !== this.authManager.nsnDashAccessError && change.reason !== this.authManager.nsnLoginError) {
                            this.authManager.startOauthSignOut();
                            observer.next(false);
                        } else {
                            observer.next(true);
                        }
                    } else {
                        this.router.navigate(['/welcome']);
                        observer.next(false);
                    }
                    observer.complete();
                });
        });
    }

}
