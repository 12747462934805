import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-widget-task-tracker-turf-rad',
    templateUrl: './widget-task-tracker-turf-rad.component.html',
    styleUrls: ['./widget-task-tracker-turf-rad.component.less']
})
export class WidgetTaskTrackerTurfRadComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#065f53';
    title = 'WIDGET.TASK_TRACKER_TURF_RAD';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        setTimeout(() => this.isBusy = false, 1000);
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TaskTrackerTurfRad;
    }

    protected getWidgetData(isManualRefresh = false) {

        this.lastUpdateTimestamp = new Date();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onGotoSite() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_TASK_TRACKER_SITE' });
        setTimeout(() => window.open(environment.taskTrackerUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }
}
