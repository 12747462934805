import WeatherAverageSources = ToroEnums.WeatherAverageSources;

import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WeatherSourceData } from './weather-source-data.model';

export class WeatherSourceForecast {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    source: WeatherAverageSources;
    data: WeatherSourceData[] = [];
}
