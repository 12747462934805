import { PwDailyHistoricalEt } from './pw-daily-historical-et.model';

export class PwHistoricalEt {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.data) { this.data = json.data.map(de => new PwDailyHistoricalEt(de)); }
        }
    }

    data: PwDailyHistoricalEt[];
}
