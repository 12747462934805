import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import PlaybooksLinkType = ToroEnums.PlaybooksLinkType;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashAuthenticatedUser } from '../../../../api/auth/models/dash-authenticated-user.model';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { environment } from '../../../../../environments/environment';
import { GddGraphData } from './models/gdd-graph-data.model';
import { NutrientGraphData } from './models/nutrient-graph-data.model';
import { PlaybooksConfig } from '../../../../api/playbooks/models/playbooks-config.model';
import { PlaybooksGddSinceAppRecord } from '../../../../api/playbooks/models/playbooks-gdd-since-app-record.model';
import { PlaybooksLinkUrl } from '../../../../api/playbooks/models/playbooks-link-url.model';
import { PlaybooksManagerService } from '../../../../api/playbooks/playbooks-manager.service';
import { PlaybooksMonthlyReportMonth } from '../../../../api/playbooks/models/playbooks-monthly-report-month.model';
import { PlaybooksPrintLogUrl } from '../../../../api/playbooks/models/playbooks-print-log-url.model';
import { PlaybooksYearlyReport } from '../../../../api/playbooks/models/playbooks-yearly-report.model';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { ToroUtils } from '../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../common/services/user-format.service';
import { WidgetManagerService } from '../../../../api/widgets/widget-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-playbooks',
    templateUrl: './widget-playbooks.component.html',
    styleUrls: ['./widget-playbooks.component.less']
})
export class WidgetPlaybooksComponent extends ToroDashboardWidget implements OnInit {
    iconColor = '#16c1f3';
    title = 'WIDGET.PLAYBOOKS';

    PlaybooksChartType = ToroEnums.PlaybooksChartType;

    private readonly NO_VALUE = '--';
    private readonly ONE_ROW_GRAPH_HEIGHT = 119;
    private readonly TWO_ROW_GRAPH_HEIGHT = 149;

    graphHeight = this.ONE_ROW_GRAPH_HEIGHT;
    costMtd = this.NO_VALUE;
    costYtd = this.NO_VALUE;
    alertCount = 0;
    playbooksLinks: PlaybooksLinkUrl[] = [];
    playbooksPRM: string;
    logsToPrint: PlaybooksPrintLogUrl[] = [];
    monthlyReport: PlaybooksMonthlyReportMonth[] = [];
    yearlyReport: PlaybooksYearlyReport;
    gddSinceAppRecords: PlaybooksGddSinceAppRecord[] = [];
    playbooksConfig: PlaybooksConfig;
    nutrientsAreasList: SelectItem[];
    nutrientGraphData: NutrientGraphData;
    gddAreasList: SelectItem[];
    gddGraphData: GddGraphData;
    isCourseIdDialogDisplayed = false;
    newCourseId: string;
    isPrintLinksDialogDisplayed = false;
    isMobile = false;
    spinnerText: string;
    currentMonth = 0;

    private _playbooksCourseId = null;
    set playbooksCourseId(value: string) {
        this._playbooksCourseId = (value != null && value !== '') ? value : null;
    }

    get playbooksCourseId(): string {
        return this._playbooksCourseId;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private playbooksManager: PlaybooksManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private widgetManager: WidgetManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.playbooksCourseId = this.authManager.dashAuthenticatedUser.playbooksCourseId;
        if (!this.playbooksCourseId) {
            this.setIsUnableToFetchData('STRINGS.PROVIDE_PLAYBOOKS_COURSE_ID');
            return;
        }

        // Subscribe to user preference changes to affect UI data changes (e.g., decimal separator)
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
                this.setComponentData();
            });

        this.dashUserManager.unitsSystemChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isBusy = true;
                this.initPlaybooksBackgroundProcess();
            });

        this.broadcastService.setupPlaybooksCourseIdChanged
            .pipe(untilDestroyed(this))
            .subscribe((courseId: string) => {
                this.newCourseId = courseId;
                this.onSetCourseId();
            });

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isMobile = this.deviceManager.isMobile);

        this.isMobile = this.deviceManager.isMobile;
        this.language = this.dashUserManager.language;
        this.getStaticWidgetData();
        this.isBusy = true;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.PlaybooksWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        this.graphHeight = this.displayRows === 1 ? this.ONE_ROW_GRAPH_HEIGHT : this.TWO_ROW_GRAPH_HEIGHT;
    }

    protected getWidgetData(isManualRefresh = false) {
        if (isManualRefresh) { this.isBusy = true; }

        if (this.playbooksCourseId == null || this.playbooksCourseId === '') {
            this.logsToPrint = [];
            this.yearlyReport = null;
            this.monthlyReport = [];
            this.gddSinceAppRecords = [];
            this.setIsUnableToFetchData('STRINGS.PROVIDE_PLAYBOOKS_COURSE_ID');
            return;
        }

        const sources: Observable<any>[] = [
            this.playbooksManager.getPlaybooksConfig(this.associatedWidget),
            this.playbooksManager.getMonthlyReport().pipe(take(1)),
            this.playbooksManager.getYearlyReport().pipe(take(1)),
            this.playbooksManager.getGDDSinceApp().pipe(take(1)),
            this.playbooksManager.getLogsToPrint().pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                this.isWidgetInitialized = true;
                this.resetBusy();
            }))
            .subscribe(([playbooksConfig, monthlyReport, yearlyReport, gddSinceAppRecords, logsToPrint]) => {
                const monthlyReportTemp = monthlyReport;

                this.playbooksConfig = playbooksConfig;
                this.logsToPrint = logsToPrint ?? [];
                this.yearlyReport = yearlyReport;
                this.gddSinceAppRecords = gddSinceAppRecords ?? [];

                // The monthly reports returned by the Playbooks API, shows actuals for values for the previous complete month and estimates for current month
                // and beyond. We only want to show actuals, so we slice off any value for the current month and beyond.
                this.currentMonth = new Date().getMonth();     // Value is zero based (i.e., January = 0), so we can pass as is to our slice method.

                // NOTE: Special case January. We have no actuals to display, but this will allow the graphs to draw.
                this.monthlyReport = monthlyReportTemp ? (<[]>monthlyReportTemp).slice(0, this.currentMonth > 0 ? this.currentMonth : 1) : [];

                if (monthlyReportTemp.length < 1) {
                    this.setIsUnableToFetchData('STRINGS.UNABLE_TO_FETCH_PLAYBOOKS_DATA_FOR_COURSE_ID');
                    return;
                }

                if (this.monthlyReport.length > 0) {
                    this.nutrientsAreasList = this.monthlyReport[0].monthlyData.map((d, index) => ({ label: d.areaName, value: d.areaName.toLowerCase() }));

                    if (this.nutrientsAreasList.length > 0 && this.playbooksConfig.selectedNutrientAreaId === '') {
                        this.playbooksConfig.selectedNutrientAreaId = this.nutrientsAreasList[0].value;
                        this.updatePlaybooksConfig();
                    }
                }

                if (this.gddSinceAppRecords.length > 0) {
                    this.gddAreasList = this.gddSinceAppRecords[0].gddSinceApp
                        .map((areaItem, index) => ({ label: areaItem.areaName, value: areaItem.areaName.toLowerCase() }));

                    if (this.gddAreasList.length > 0 && this.playbooksConfig.selectedGddAreaId === '') {
                        this.playbooksConfig.selectedGddAreaId = this.gddAreasList[0].value;
                        this.updatePlaybooksConfig();
                    }
                }

                this.setNutrientGraphData();
                this.setGddGraphData();
                this.setComponentData();
                this.clearIsUnableToFetchData();

                // TODO: Determine the appropriate value to show since we only fetch the data from Playbooks every four hours or so.
                this.lastUpdateTimestamp = new Date();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.SET_COURSE_ID'),
                icon: 'pi pi-fw pi-sign-in',
                command: this.showCourseIdDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    formatDate(date: Date): string {
        return this.userFormatService.toUserDateString(date);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onPrintClick() {
        if (this.logsToPrint.length < 1) { return; }

        this.isPrintLinksDialogDisplayed = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetShowPrintDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    // onAlertsClick() {
    //     if (this.yearlyReport.alertBadgeCount < 1) { return; }
    //
    //     this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.NotificationsCenter));
    // }

    onNewLogClick() {
        this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.ApplicationLog));
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetGoToNewLog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onDashboardClick() {
        this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.Dashboard));
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetGoToDashboard, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onReportsClick() {
        this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.Reports));
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetGoToReports, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onLabelsSdsClick() {
        this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.Documents));
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetGoToLabels, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onInventoryClick() {
        this.navigateToPlaybooksUrl(this.getPlaybooksLinkUrl(PlaybooksLinkType.Inventory));
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetGoToInventory, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onNutrientAreaChange(eventInfo: { originalEvent: MouseEvent, value: string }) {
        this.setNutrientGraphData();

        // NOTE: playbooksConfig.selectedNutrientAreaId is bound to select list and will be updated when selection is changed.
        this.updatePlaybooksConfig();

        const eventDetails = `Area: ${eventInfo.value}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetFilterNutrients, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onGddAreaChange(eventInfo: { originalEvent: MouseEvent, value: string }) {
        this.setGddGraphData();

        // NOTE: playbooksConfig.selectedGddAreaId is bound to select list and will be updated when selection is changed.
        this.updatePlaybooksConfig();

        const eventDetails = `Area: ${eventInfo.value}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetFilterGdd, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onSetCourseId() {
        this.isCourseIdDialogDisplayed = false;

        // If the Course Id was not change, don't do anything.
        if (this.playbooksCourseId === this.newCourseId) { return; }

        this.playbooksCourseId = this.newCourseId;
        this.isBusy = true;
        this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_COURSE_ID')}...`;

        this.playbooksManager.setCourseId(this.playbooksCourseId)
            .subscribe(result => {
                this.authManager.setDashAuthenticatedUser(new DashAuthenticatedUser(result));

                this.playbooksCourseId = this.authManager.dashAuthenticatedUser.playbooksCourseId;
                this.initPlaybooksBackgroundProcess();

                const eventDetails = `CourseId: ${this.playbooksCourseId}`;
                this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetSetCourseId, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
            }, error => {
                this.resetBusy();
                this.dashMessageService.showGenericRequestErrorMessage();
            });
    }

    onCancelSetCourseId() {
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetCancelCourseIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        this.isCourseIdDialogDisplayed = false;
    }

    logPrintClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetClickPrintLink, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getPlaybooksLinkUrl(linkName: string): string {
        const link = this.playbooksLinks.find(l => l.name === linkName);
        return (link != null) ? `${environment.playbooksBaseLinkUrl}${link.url}` : '';
    }

    private getStaticWidgetData() {
        this.playbooksManager.getLinkUrls()
            .pipe(take(1))
            .subscribe((result: PlaybooksLinkUrl[]) => {
                this.playbooksLinks = result;

                const dashboardLink = this.playbooksLinks.find(l => l.name === 'DASHBOARD');
                if (dashboardLink != null) {
                    this.playbooksPRM = dashboardLink.url.split('PRM=')[1];
                }
            }, error => {
                console.log();
            });
    }

    private initPlaybooksBackgroundProcess() {
        this.playbooksManager.processPlaybooksData()
            .pipe(take(1))
            .subscribe(() => {
                this.getStaticWidgetData();
                this.getWidgetData();
            }, error => {
                this.resetBusy();
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    private setComponentData() {
        if (this.monthlyReport && this.monthlyReport.length > 0) {

            // We now only show data up through the last complete month. We no longer show the projected values provided by Playbooks.
            // We remove projected values in getWidgetData(). As such the following block of code is no longer required. If we change that logic,
            // we may want to incorporate the following block.

            // const currentMonthIndex = new Date().getMonth();
            // // NOTE: Date().getMonth uses zero based months where 0 = January.
            // //       monthlyReport identifies months using one based months, where 1 = January.
            // if (currentMonthIndex + 1 > this.monthlyReport.length) {
            //     // We don't have any monthly totals for the current month. This can happen at the beginning of the month.
            //     return;
            // }

            const currentMonthIndex = this.monthlyReport.length - 1;

            this.costMtd = this.currentMonth > 0
                ? this.userFormatService.toUserCurrency(this.monthlyReport[currentMonthIndex].applicationTotalCost.monthlyTotal, 0, 0)
                : '--';
            this.costYtd = this.currentMonth > 0
                ? this.userFormatService.toUserCurrency(this.monthlyReport[currentMonthIndex].applicationTotalCost.ytd, 0, 0)
                : '--';
        }

        if (this.yearlyReport) {
            this.alertCount = this.yearlyReport.alertBadgeCount;
        }
    }

    private setNutrientGraphData() {
        const graphData = new NutrientGraphData();

        if (this.monthlyReport) {
            this.monthlyReport.map(m => {
                const areaData = m.monthlyData.find(md => md.areaName.toLowerCase() === this.playbooksConfig.selectedNutrientAreaId);
                graphData.N.push(areaData ? areaData.ingredients.find(i => i.name === 'N')?.ytd ?? 0 : 0);
                graphData.P.push(areaData ? areaData.ingredients.find(i => i.name === 'P')?.ytd ?? 0 : 0);
                graphData.K.push(areaData ? areaData.ingredients.find(i => i.name === 'K')?.ytd ?? 0 : 0);
                graphData.Fe.push(areaData ? areaData.ingredients.find(i => i.name === 'Fe')?.ytd ?? 0 : 0);
                graphData.Mn.push(areaData ? areaData.ingredients.find(i => i.name === 'Mn')?.ytd ?? 0 : 0);
            });
        }

        this.nutrientGraphData = graphData;
    }

    private setGddGraphData() {
        const graphData = new GddGraphData();

        if (this.gddSinceAppRecords) {
            const areaData = this.gddSinceAppRecords[0].gddSinceApp.find(r => r.areaName.toLowerCase() === this.playbooksConfig.selectedGddAreaId);
            graphData.gdd0c = areaData ? areaData.gddSinceApp.gdd0c.sinceApp : 0;
            graphData.gdd10c = areaData ? areaData.gddSinceApp.gdd10c.sinceApp : 0;
            graphData.gdd32 = areaData ? areaData.gddSinceApp.gdd32.sinceApp : 0;
            graphData.gdd50 = areaData ? areaData.gddSinceApp.gdd50.sinceApp : 0;
        }

        this.gddGraphData = graphData;
    }

    private navigateToPlaybooksUrl(url: string): void {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_PLAYBOOKS_SITE' });

        setTimeout(() => window.open(url, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    private showCourseIdDialog() {
        this.newCourseId = this.playbooksCourseId;
        this.isCourseIdDialogDisplayed = true;

        const eventDetails = `Course Id: ${this.playbooksCourseId}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PlaybooksWidgetShowCourseIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    private updatePlaybooksConfig() {
        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.playbooksConfig)
            .pipe(take(1))
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    private resetBusy() {
        this.isBusy = false;
        this.spinnerText = null;
    }

}
