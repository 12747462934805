import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashUserManagerService } from '../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { environment } from '../../../environments/environment';
import { MyTurfManagerService } from '../../api/my-turf/my-turf-manager.service';
import { NsnManagerService } from '../../api/nsn/nsn-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.less']
})
export class AppFooterComponent implements OnInit, AfterViewInit {
    @HostBinding('class') class = 'toro-flex-child';

    showApiEndpoint = false;
    apiEndpoint: string;
    nsnApiEndpoint: string;
    myTurfApiEndpoint: string;
    isMobile = false;
    appVersion: string;
    buildConfiguration: string;
    language = 'en-us';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService,
                private deviceManager: DeviceManagerService,
                private nsnManager: NsnManagerService,
                private myTurfManager: MyTurfManagerService,
    ) { }

    ngOnInit(): void {
        this.appVersion = environment.appVersion;
        this.buildConfiguration = !environment.production ? environment.name : null;
        this.isMobile = this.deviceManager.isMobile;

        this.deviceManager.windowResize.subscribe(() => {
            this.isMobile = this.deviceManager.isMobile;
        });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);

        if (!environment.production) {
            this.showApiEndpoint = environment.showApiEndpointInFooter;
            this.apiEndpoint = environment.dashApiUrl;
            this.get3rdPartyServicesApiEndpoints();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.language = this.dashUserManager.language;
        }, 100)
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get3rdPartyServicesApiEndpoints() {
        this.nsnManager.getApiEndpoint().subscribe(endpoint => this.nsnApiEndpoint = endpoint);
        this.myTurfManager.getApiEndpoint().subscribe(endpoint => this.myTurfApiEndpoint = endpoint);
    }
}
