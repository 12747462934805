import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'toro-add-favorite-location-dlg',
    templateUrl: './add-favorite-location-dlg.component.html',
    styleUrls: ['./add-favorite-location-dlg.component.less']
})
export class AddFavoriteLocationDlgComponent {
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter<string>();

    name: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSave() {
        this.save.emit(this.name);
    }

    onCancel() {
        this.cancel.emit();
    }

}
