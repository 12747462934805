import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { CachedCollection } from '../_common/cached-collection';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { LynxApiService } from './lynx-api.service';
import { LynxArea } from './models/lynx-area.model';
import { LynxCourse } from './models/lynx-course.model';
import { LynxPumpStation } from './models/lynx-pump-station.model';
import { LynxPumpStatus } from './models/lynx-pump-status.model';
import { LynxWeatherStation } from './models/lynx-weather-station.model';
import { RunningStationsSummary } from './models/running-stations-summary.model';
import { tap } from 'rxjs/operators';
import { TimeStampedCollection } from '../_common/time-stamped-collection.model';

@Injectable({
    providedIn: 'root'
})
export class LynxManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // Cache Containers
    private _lynxCourses: CachedCollection<LynxCourse>;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private lynxApiService: LynxApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCourses(bypassCache = false, includeHierarchy = false): Observable<LynxCourse[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.lynxCourses); }

        if (!bypassCache && this._lynxCourses && this._lynxCourses.collection.length > 0 && !this._lynxCourses.isExpired) {
            return of(this._lynxCourses.collection.slice());
        }

        return this.lynxApiService.getCourses(includeHierarchy)
            .pipe(tap(courses => {
                return this._lynxCourses = new CachedCollection(courses);
            }));
    }

    // TODO: To be deprecated.
    getPumpStation(): Observable<LynxPumpStation> {
        return this.lynxApiService.getPumpStation();
    }

    getPumps(includeConfig = true): Observable<LynxPumpStation[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.lynxPumpStations); }
        return this.lynxApiService.getPumps(includeConfig);
    }

    // TODO: To be deprecated.
    getPumpStatuses(siteId: number): Observable<LynxPumpStatus[]> {
        return this.lynxApiService.getPumpStatuses(siteId);
    }

    getRunningStationsDetails(courseId: number): Observable<TimeStampedCollection<LynxArea[]>> {
        // // TODO: TEMPORARY
        // return of(this.demoModeMockDataService.lynxRunningStationsDetails);

        if (environment.isDemoMode) { return of(this.demoModeMockDataService.lynxRunningStationsDetails); }
        return this.lynxApiService.getRunningStationsDetails(courseId);
    }

    // TODO: DEPRECATED? - DELETE?
    // getRunningStationsDetailsForUser(): Observable<TimeStampedCollection<LynxArea[]>> {
    //     const userSiteId = this.authManager.dashAuthenticatedUser.siteId;
    //
    //     return this.getCourses()
    //         .pipe(
    //             map(courses => courses.find(c => c.siteId === userSiteId).id),
    //             switchMap(courseId => this.getRunningStationsDetails(courseId))
    //         );
    // }

    getRunningStationsSummary(courseId: number): Observable<TimeStampedCollection<RunningStationsSummary[]>> {
        // // TODO: TEMPORARY
        // return of(this.demoModeMockDataService.lynxRunningStationsSummary);

        if (environment.isDemoMode) { return of(this.demoModeMockDataService.lynxRunningStationsSummary); }
        return this.lynxApiService.getRunningStationsSummary(courseId);
    }

    // TODO: DEPRECATED? - DELETE?
    // getRunningStationsSummaryForUser(): Observable<TimeStampedCollection<RunningStationsSummary[]>> {
    //     const userSiteId = this.authManager.dashAuthenticatedUser.siteId;
    //
    //     return this.getCourses()
    //         .pipe(
    //             map(courses => courses.find(c => c.siteId === userSiteId).id),
    //             switchMap(courseId => this.getRunningStationsSummary(courseId))
    //         );
    // }

    getWeatherStations(includeStatus = true): Observable<LynxWeatherStation[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.lynxWeatherStations); }
        return this.lynxApiService.getWeatherStations(includeStatus);
    }

}
