<p-dialog appendTo="body" [visible]="true" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'CASE_SENSITIVE.ENTER_LOGIN_INFORMATION' | translate }}</p-header>

    <div id="ss-login-dlg-container">
        <span class="field-lbl">{{'STRINGS.USERNAME' | translate }}</span>
        <input type="text" pInputText
                [(ngModel)]="username"
                [size]="30"
                [placeholder]="'STRINGS.USERNAME' | translate" />

        <span class="field-lbl">{{'STRINGS.PASSWORD' | translate }}</span>
        <input type="password" pPassword
               [(ngModel)]="password"
               [promptLabel]="'STRINGS.PASSWORD' | translate"
               [feedback]="false"
        />
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.LOGIN' | translate" (onClick)="onLogin()"></p-button>
    </p-footer>
</p-dialog>
