import { LynxArea } from './lynx-area.model';

export class LynxCourse {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.area) this.area = json.area.map(area => new LynxArea(area));
        }
    }

    id: number;
    siteId: number;
    name: string;
    number: number;
    area: LynxArea[];

}
