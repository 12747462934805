<div class="dt-datum-row-container" [ngStyle]="{'border': '1px solid ' + borderColor}" [ngClass]="containerClass">
    <div class="dtdr-icon toro-component-shield-host" [ngStyle]="{'color': iconColor, 'backgroundColor': iconBackgroundColor}">
        <i [class]="icon" [ngStyle]="{'font-size': iconSize}"></i>
        <toro-component-shield></toro-component-shield>
    </div>
    <div class="dtdr-text toro-component-shield-host" [ngStyle]="{'color': textColor, 'background-color': textBackgroundColor, 'justify-content': textJustify}">
        <span>{{text | translate}}</span>
        <toro-component-shield></toro-component-shield>
    </div>
    <div class="dtdr-editor-container">
        <div [ngSwitch]="editorType" class="dtdr-editor" [ngStyle]="{'background-color': textBackgroundColor}" [ngClass]="{'hide-border': valueUnits != null && valueUnits != '' && editorType == DatumRowEditorType.None}">
            <div *ngSwitchCase="DatumRowEditorType.None" class="dtdr-editor-valuez-label toro-component-shield-host" [ngStyle]="{'color': textColor}">
                <span class="dtdr-editor-value">{{value != null ? displayValue : '--'}}</span>
                <toro-component-shield></toro-component-shield>
            </div>
            <div *ngSwitchCase="DatumRowEditorType.TextBox" class="dtdr-editor-value-number" [ngStyle]="{'color': textColor}">
                <p-inputNumber class="p-inputNumber" [id]="inputId" *ngIf="decimalPlaces === 0" [(ngModel)]="value" [showButtons]="true" inputId="stacked" size="5" [min]="minValue" [max]="maxValue" [step]="step" [suffix]="valueUnits"></p-inputNumber>
                <p-inputNumber class="p-inputNumber"
                               [id]="inputId"
                               *ngIf="decimalPlaces > 0"
                               [(ngModel)]="value"
                               [showButtons]="true" inputId="stacked" size="5"
                               [min]="minValue"
                               [max]="maxValue"
                               [locale]="locale"
                               [minFractionDigits]="decimalPlaces"
                               [maxFractionDigits]="decimalPlaces"
                               [step]="step"
                               [suffix]="valueUnits">
                </p-inputNumber>
            </div>
        </div>
        <div *ngIf="valueUnits != null && valueUnits != '' && editorType == DatumRowEditorType.None" class="dtdr-editor-units toro-component-shield-host" [ngStyle]="{'color': textColor, 'background-color': textBackgroundColor}">
            {{valueUnits}}
            <toro-component-shield></toro-component-shield>
        </div>
    </div>
</div>
