import { LynxHole } from './lynx-hole.model';

export class LynxArea {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.holes) {
                this.holes = json.holes.map(hole => new LynxHole(hole))
                    .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            }
        }
    }

    id: number;
    courseId: number;
    name: string;
    number: number;
    holes: LynxHole[];

    get areaStationsCount(): number {
        return this.holes.reduce((cnt, h) => cnt + h.holeStationsCount, 0);
    }

}
