import { SoilScoutNotification } from './soil-scout-notification.model';
import { SoilScoutRule } from './soil-scout-rule.model';

export class SoilScoutAlert {

    constructor(notification: SoilScoutNotification, rule: SoilScoutRule) {
        this.notification = notification;
        this.rule = rule;
    }

    notification: SoilScoutNotification;
    rule: SoilScoutRule;

    get name(): string {
        return this.rule.name;
    }

    get activeSensorAlertsCount(): number {
        return this.notification.measurements.filter(m => m.valid_timestamp == null)?.length ?? 0;
    }

    get isMoistureAlert(): boolean {
        return this.rule.conditions.filter(c => c.measurement_type == 'MOISTURE')?.length > 0 ?? false;
    }

    get isTemperatureAlert(): boolean {
        return this.rule.conditions.filter(c => c.measurement_type == 'TEMPERATURE')?.length > 0 ?? false;
    }

    get isSalinityAlert(): boolean {
        return this.rule.conditions.filter(c => c.measurement_type == 'SALINITY')?.length > 0 ?? false;
    }
}
