
<div class="toro-notification-panel-container">

    <div class="tnp-header">
        <div class="tnp-header-row">
            <div class="tnp-header-title">{{'CASE_SENSITIVE.HOW_TO_VIDEOS' | translate}}</div>
            <div class="tnp-close-button" (click)="onClosePanel()"><fa-icon icon="times"></fa-icon></div>
        </div>
    </div>

    <div class="tnp-scroll-container">
        <div class="tnp-card-container">
            <toro-video-panel-card
                    *ngFor="let vc of videoCards"
                    [videoInfo]="vc"
            ></toro-video-panel-card>
        </div>
    </div>

</div>

