import * as moment from 'moment';
import { AppInjector } from '../../../shared/shared.module';
import { CalAmpAvlEvent } from './cal-amp-avl-event.model';
import { CalAmpMapInfo } from './cal-amp-map-info.model';
import { CalAmpMotionLogEvent } from './cal-amp-motion-log-event.model';
import { CalAmpMotionLogInfo } from './cal-amp-motion-log-info.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../common/services/user-format.service';

import AssetTrackingDeviceType = ToroEnums.AssetTrackingDeviceType;
import AssetTrackingDeviceStatus = ToroEnums.AssetTrackingDeviceStatus;
import CalAmpAccumulators = ToroEnums.CalAmpAccumulators;
import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;
import CalAmpDeviceType = ToroEnums.CalAmpDeviceType;

export class CalAmpDevice {

    private readonly _userFormatService: UserFormatService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(calAmpDevice: any) {
        if (calAmpDevice) {
            Object.assign(this, calAmpDevice);
        }

        this.esn = +calAmpDevice.esn;
        this.name = calAmpDevice.name;
        this.hours = calAmpDevice.hours;
        this.deviceType = calAmpDevice.deviceType;
        this.calAmpDeviceType = calAmpDevice.calAmpDeviceType;
        this.deviceStatus = calAmpDevice.deviceStatus;

        // Correct for Bogus Device Hours (FB-11406)
        if (this.hours > 24) {
            this.isBogusHours = true;
            this.hours = 0;
        }

        this._userFormatService = AppInjector.get(UserFormatService);
    }

    esn: number;
    name: string;
    hours: number;
    deviceStatus = AssetTrackingDeviceStatus.Unknown;
    deviceType = AssetTrackingDeviceType.Unknown;
    calAmpDeviceType = CalAmpDeviceType.Unknown;
    hasNoLocation = true;

    private readonly isBogusHours: boolean = false;
    private _calAmpAvlEvents: CalAmpAvlEvent[];
    private _motionLogEntries: CalAmpMotionLogInfo[];
    private _motionLogEvents: CalAmpMotionLogEvent[];

    set calAmpAvlEvents(value: CalAmpAvlEvent[]) {
        this._calAmpAvlEvents = value;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.currentLocation;
    }

    get calAmpAvlEvents(): CalAmpAvlEvent[] {
        return this._calAmpAvlEvents;
    }

    set motionLogEvents(events: CalAmpMotionLogEvent[]) {
        this._motionLogEvents = events;

        this._motionLogEntries = [];
        const infos = this._motionLogEvents.map(e => e.motionLogInfos);
        infos.forEach(el => {
            this._motionLogEntries.push(...el.map(se => se).filter(mli => mli.latitude !== 0 || mli.longitude !== 0));
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.currentLocation;
    }

    get motionLogEvents(): CalAmpMotionLogEvent[] {
        return this._motionLogEvents;
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get currentLocation(): CalAmpMapInfo {
        this.hasNoLocation = false;

        if (this.latestMotionLogFix != null) {
            return new CalAmpMapInfo(this.latestMotionLogFix.latitude, this.latestMotionLogFix.longitude);
        }

        if (this.calAmpAvlEvents == null || this.calAmpAvlEvents.length < 1) {
            this.hasNoLocation = true;
            return null;
        }

        return new CalAmpMapInfo(this.calAmpAvlEvents[0].latitude, this.calAmpAvlEvents[0].longitude);
    }

    get latestAvlEventDate() {
        if (this.calAmpAvlEvents == null || this.calAmpAvlEvents.length < 1) { return null; }

        return this.calAmpAvlEvents[0].eventTime;
    }

    get latestMotionLogFix(): CalAmpMotionLogInfo {
        if (this.motionLogEvents == null || this.motionLogEvents.length < 1) { return null; }

        try {
            return this.motionLogEvents.find(e => e.motionLogInfos.find(i => i.latitude !== 0)).motionLogInfos.find(mli => mli.latitude !== 0);
        }
        catch {
            return null;
        }
    }

    get earliestMotionLogFix(): CalAmpMotionLogInfo {
        if (this.motionLogEvents == null || this.motionLogEvents.length < 1) { return null; }

        try {
            const reverseList = this.motionLogEvents.slice().reverse();
            return reverseList.find(e => e.motionLogInfos.find(i => i.latitude !== 0)).motionLogInfos.find(mli => mli.latitude !== 0);
        }
        catch {
            return null;
        }
    }

    get currentStatus(): CalAmpDeviceStatus {
        if (this.calAmpAvlEvents == null) { return CalAmpDeviceStatus.Off; }

        const latestStatusEvent = this.calAmpAvlEvents.find(e => e.isStatusEvent);
        if (latestStatusEvent == null) { return CalAmpDeviceStatus.Off; }

        return latestStatusEvent.status;
    }

    // Returns user formatted number of hours.
    get totalDailyHoursString(): string {
        if (this.calAmpDeviceType === CalAmpDeviceType.TTU2830 || (this.hours === 0 && this._calAmpAvlEvents && !this.hasHoursEvent) || this.isBogusHours) {
            return 'STRINGS.N/A';
        }

        return this._userFormatService.toUserNumber(this.hours, 2, 2);
    }

    getPositionAtDateTime(dt: Date) {
        if (this._motionLogEntries == null || this._motionLogEntries.length < 1) { return null; }

        const mdt = moment(dt);
        return this._motionLogEntries.find(e => {
            const tof = moment(e.timeOfFix);
            return tof <= mdt;
        });
    }

    getStatusAtDateTime(dt: Date): CalAmpDeviceStatus {
        if (this._calAmpAvlEvents == null || this._calAmpAvlEvents.length < 1) { return CalAmpDeviceStatus.Off; }

        const mdt = moment(dt);
        const requestedStatusEvent = this._calAmpAvlEvents.find(e => {
            return e.isStatusEvent && (moment(e.eventTime) <= mdt);
        });

        if (requestedStatusEvent == null) { return CalAmpDeviceStatus.Off; }

        return requestedStatusEvent.status;

    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get hasHoursEvent(): boolean {
        if (!this._calAmpAvlEvents || this._calAmpAvlEvents.length < 1) { return false; }

        const hoursEvent = this._calAmpAvlEvents
            .find(e => e.deviceDataConverted?.accumulators && e.deviceDataConverted.accumulators
                .find(a => a.index === CalAmpAccumulators.VB_Key_On_Timer && a.value !== 0));

        return hoursEvent != null;
    }

}
