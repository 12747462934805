<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            (gotoSite)="onPogoLinkClick()"
    >

        <div class="toro-flex-child mini-mode-widget-container toro-component-shield-host">

            <div class="toro-widget-content-pane">
                <ng-container [ngTemplateOutlet]="topNav" [ngTemplateOutletContext]="{isMiniMode: true}"></ng-container>
                <ng-container [ngTemplateOutlet]="zonesNav" [ngTemplateOutletContext]="{isMiniMode: true}"></ng-container>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onPogoLinkClick()"
    >

        <div class="toro-flex-child" id="pogo-widget-content">

            <div *ngIf="displayCols == 1" class="toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <ng-container [ngTemplateOutlet]="topNav"></ng-container>
                <ng-container [ngTemplateOutlet]="zonesNav"></ng-container>
                <ng-container [ngTemplateOutlet]="zonesList"></ng-container>
            </div>

            <div *ngIf="displayCols > 1" class="toro-widget-content-pane" id="pw-two-col" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <div class="toro-flex-child" id="pw-left-pane">
                    <ng-container [ngTemplateOutlet]="topNav"></ng-container>
                    <ng-container [ngTemplateOutlet]="zonesNav"></ng-container>
                    <ng-container [ngTemplateOutlet]="zonesList"></ng-container>
                </div>
                <div class="toro-flex-child" id="pw-right-pane">
                    <toro-pogo-green-viewer
                            [property]="property"
                            [selectedZone]="selectedZone"
                            (viewerClicked)="onShowDesktopModal($event)"
                    ></toro-pogo-green-viewer>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="pogo-title-icon" src="../../../../../../assets/images/pogo/pogo-ico.png" alt="">
</ng-template>

<ng-template #topNav let-isMiniMode="isMiniMode">
    <div class="pw-toolbar-container" id="pw-top-nav" [ngClass]="{'is-mini-mode': isMiniMode}">
        <div class="pw-top-nav-btn" [ngClass]="{'is-selected' : topNavSelection == PogoTopNav.Moisture}" (click)="onTopNavClick(PogoTopNav.Moisture)">
            <span class="prevent-select">{{ 'STRINGS.MOISTURE' | translate }}</span>
        </div>
        <div *ngIf="!isMiniMode" class="pw-top-nav-btn" [ngClass]="{'is-selected' : topNavSelection == PogoTopNav.DistributionUniformity}" (click)="onTopNavClick(PogoTopNav.DistributionUniformity)">
            <span class="prevent-select">{{ 'STRINGS.DISTRIBUTION_UNIFORMITY_ABBR' | translate }}</span>
        </div>
        <div *ngIf="!isMiniMode" class="pw-top-nav-btn" [ngClass]="{'is-selected' : topNavSelection == PogoTopNav.TurfPerformance}" (click)="onTopNavClick(PogoTopNav.TurfPerformance)">
            <span class="prevent-select">{{ 'STRINGS.TURF_PERFORMANCE_ABBR' | translate }}</span>
        </div>
    </div>
</ng-template>

<ng-template #zonesNav let-isMiniMode="isMiniMode">
    <div class="pw-toolbar-container" id="pw-zones-nav" [ngClass]="{'is-mini-mode': isMiniMode}">
        <div class="pw-zones-btn"
             [ngClass]="{'is-selected' : zoneRatingSelection == PogoMoistureRating.CriticallyLow, 'is-disabled': zonesCriticallyLow?.length < 1, 'is-mini-mode': isMiniMode}"
             [pTooltip]="'CASE_SENSITIVE.CRITICALLY_LOW' | translate"
             tooltipPosition="top"
             (click)="zonesCriticallyLow?.length > 0 ? onZoneNavCick(PogoMoistureRating.CriticallyLow): null"
        >
            <toro-pogo-zone-tab-card
                    [rating]="PogoMoistureRating.CriticallyLow"
                    [count]="zonesCriticallyLow?.length"
                    [isMiniMode]="isMiniMode"
            ></toro-pogo-zone-tab-card>
        </div>
        <div class="pw-zones-btn"
             [ngClass]="{'is-selected' : zoneRatingSelection == PogoMoistureRating.Low, 'is-disabled': zonesLow?.length < 1, 'is-mini-mode': isMiniMode}"
             [pTooltip]="'STRINGS.LOW' | translate | titlecase"
             tooltipPosition="top"
             (click)="onZoneNavCick(PogoMoistureRating.Low)"
        >
            <toro-pogo-zone-tab-card
                    [rating]="PogoMoistureRating.Low"
                    [count]="zonesLow?.length"
                    [isMiniMode]="isMiniMode"
            ></toro-pogo-zone-tab-card>
        </div>
        <div class="pw-zones-btn"
             [ngClass]="{'is-selected' : zoneRatingSelection == PogoMoistureRating.Good, 'is-disabled': zonesGood?.length < 1, 'is-mini-mode': isMiniMode}"
             [pTooltip]="'STRINGS.OPTIMAL' | translate | titlecase"
             tooltipPosition="top"
             (click)="onZoneNavCick(PogoMoistureRating.Good)"
        >
            <toro-pogo-zone-tab-card
                    [rating]="PogoMoistureRating.Good"
                    [count]="zonesGood?.length"
                    [isMiniMode]="isMiniMode"
            ></toro-pogo-zone-tab-card>
        </div>
        <div class="pw-zones-btn"
             [ngClass]="{'is-selected' : zoneRatingSelection == PogoMoistureRating.High, 'is-disabled': zonesHigh?.length < 1, 'is-mini-mode': isMiniMode}"
             [pTooltip]="'STRINGS.HIGH' | translate | titlecase"
             tooltipPosition="top"
             (click)="onZoneNavCick(PogoMoistureRating.High)"
        >
            <toro-pogo-zone-tab-card
                    [rating]="PogoMoistureRating.High"
                    [count]="zonesHigh?.length"
                    [isMiniMode]="isMiniMode"
            ></toro-pogo-zone-tab-card>
        </div>
        <div class="pw-zones-btn"
             [ngClass]="{'is-selected' : zoneRatingSelection == PogoMoistureRating.CriticallyHigh, 'is-disabled': zonesCriticallyHigh?.length < 1, 'is-mini-mode': isMiniMode}"
             [pTooltip]="'CASE_SENSITIVE.CRITICALLY_HIGH' | translate"
             tooltipPosition="top"
             (click)="onZoneNavCick(PogoMoistureRating.CriticallyHigh)"
        >
            <toro-pogo-zone-tab-card
                    [rating]="PogoMoistureRating.CriticallyHigh"
                    [count]="zonesCriticallyHigh?.length"
                    [isMiniMode]="isMiniMode"
            ></toro-pogo-zone-tab-card>
        </div>
    </div>
</ng-template>

<ng-template #zonesList>
    <div class="toro-flex-child toro-relative-parent" id="zones-list-wrapper">
        <div #zoneCardList *ngIf="selectedZones?.length > 0" class="toro-absolute-child" id="zones-scroll-container" style="border: 1px solid #f6f6f6;">
            <toro-pogo-zone-card
                    #zoneCard
                    *ngFor="let zone of selectedZones; let i = index;"
                    [isMobile]="isGridsterInMobileMode"
                    [zone]="zone"
                    [dataType]="topNavSelection"
                    [selectedZone]="selectedZone"
                    (zoneSelectionChange)="onZoneSelectionChange($event)"
            ></toro-pogo-zone-card>
        </div>
        <div *ngIf="selectedZones?.length < 1" style="flex: 1; display: flex; justify-content: center; align-items: center;">
            No data to display.
        </div>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<p-dialog [visible]="isApiKeyDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{ 'CASE_SENSITIVE.SET_API_KEY' | translate | titlecase }}</p-header>

    <div id="api-key-dialog-container">
        <span>{{ 'CASE_SENSITIVE.POGO_API_KEY' | translate | titlecase }}</span>
        <input type="text" pInputText
               [(ngModel)]="newApiKey"
               [size]="30"
               [placeholder]="'CASE_SENSITIVE.ENTER_API_KEY' | translate | titlecase"
        />
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelApiKeyDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetApiKey()"></p-button>
    </p-footer>
</p-dialog>

<toro-pogo-modal
        *ngIf="showDesktopModal"
        [property]="property"
        [selectedZone]="selectedZone"
        [selectedZones]="selectedZones"
        [isMiniModal]="isGridsterInMobileMode"
        (cancel)="showDesktopModal = false;"
        (gotoSite)="onPogoLinkClick()"
        (zoneSelectionChange)="onZoneSelectionChange($event)"
>
    <div class="toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
        <ng-container [ngTemplateOutlet]="topNav"></ng-container>
        <ng-container [ngTemplateOutlet]="zonesNav"></ng-container>
        <ng-container [ngTemplateOutlet]="zonesList"></ng-container>
    </div>
</toro-pogo-modal>
