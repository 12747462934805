import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { GraphAxisExtentModel } from '../../../../../common/services/models/graph-axis-extent.model';
import { Site } from '../../../../../api/site/models/site.model';
import { SiteManagerService } from '../../../../../api/site/site-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherGraphsData } from '../../../../../api/weather/models/weather-graphs-data.model';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import WeatherGraphType = ToroEnums.WeatherGraphType;
import WeatherGraphChartColor = ToroEnums.WeatherGraphChartColor;

@UntilDestroy()
@Component({
    selector: 'toro-weather-graph-chart',
    templateUrl: './weather-graph-chart.component.html',
    styleUrls: ['./weather-graph-chart.component.less']
})
export class WeatherGraphChartComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'toro-weather-graph-chart';
    @ViewChild('wgChartContainer') wgChartContainer: ElementRef;

    @Output() chartLoaded = new EventEmitter();
    @Output() chartClick = new EventEmitter();

    @Input() graphType: WeatherGraphType;
    @Input() site: Site;

    private _isModal = false;
    @Input() set isModal(value: boolean) {
        this._isModal = value;
    }

    get isModal(): boolean {
        return this._isModal;
    }

    private _data: WeatherGraphsData;
    @Input() set data(value: WeatherGraphsData) {
        if (value == null) { return; }
        this._data = value;
        setTimeout(() => this.updateChart());
    }

    get data(): WeatherGraphsData {
        return this._data;
    }

    @Input() minY: number = null;

    private _yAxisExtent: GraphAxisExtentModel;
    @Input() set yAxisExtent(value: GraphAxisExtentModel) {
        this._yAxisExtent = value;
        this.updateChart();
    }

    get yAxisExtent(): GraphAxisExtentModel {
        return this._yAxisExtent;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;
    timeTooltipString: string;
    valueTooltipString: string;

    private chartSetupTimer: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private broadcastService: BroadcastService,
                private siteManager: SiteManagerService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                // Slight delay added to allow Highcharts to render properly w/o throwing error.
                setTimeout(() => this.updateChart(), 250);
            });
    }

    ngOnInit(): void {
        this.setTooltips();
    }

    ngOnDestroy() {
        this.chart = null
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setTooltips() {
        this.timeTooltipString = this.translateService.instant('STRINGS.TIME').toTitleCase();
        this.valueTooltipString = this.translateService.instant('STRINGS.VALUE').toTitleCase();
    }

    private updateChart() {
        this.setTooltips();

        // Adjust graph Y extents to accommodate user selected units.
        const data: number[] = [];
        this.sensorSeriesData.forEach(d => d.data.forEach(datum => data.push(datum)));
        const min = Math.min(...data);
        const max = Math.max(...data);
        this._yAxisExtent = new GraphAxisExtentModel(this.graphType, min, max);

        if (!this.chart) {
            clearTimeout(this.chartSetupTimer);
            this.chartSetupTimer = setTimeout(() => this.setupChart(), !this.isModal ? 100 : 250);
        } else {
            this.chart.update({
                chart: {
                    type: 'spline',
                },
                series: this.sensorSeriesData,
                xAxis: {
                    title: {
                        text: `(${this.translateService.instant('STRINGS.LOCAL_COURSE_TIME')})`,
                    },
                    categories: this.sensorSeriesCategories,
                },
                yAxis: {
                    min: this.yAxisExtent.min,
                    max: this.yAxisExtent.max,
                }
            }, true);
        }
    }

    private setSizeOfChartContainer() {
        if (!this.wgChartContainer) { return; }
        this.chartContainerWidth = this.wgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.wgChartContainer.nativeElement.offsetHeight - 2;
    }

    private setupChart() {
        const self = this;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) {self.chart = event.target; },
                    click() { self.chartClick.emit(); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [8, 0, 0, 5],
                marginRight: 20
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                // width: '95%',
                // allowOverlap: true,
                tickLength: 7,
                tickWidth: 1,
                tickmarkPlacement: "on",
                tickPositions: !self.isModal ? [0, 5, 11, 17, 23] : [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
                type: "categories",
                categories: self.sensorSeriesCategories,
                labels: {
                    formatter() { return '<span class="weather-graph-x-axis-label">' + this.value + '</span>'; },
                },
                title: {
                    text: `(${self.translateService.instant('STRINGS.LOCAL_COURSE_TIME')})`,
                    style: {
                        fontSize: !self.isModal ? '10px' : '12px;',
                    },
                    offset: !self.isModal ? 22 : 35,
                    x: !self.isModal ? -8 : -20,
                },
                gridLineWidth: 1
            },
            yAxis: {
                // endOnTick: false,
                // tickPixelInterval: 40,
                tickAmount: 4,
                min: self.yAxisExtent?.min ?? undefined,
                max: self.yAxisExtent?.max ?? undefined,
                title: { enabled: false },
                labels: {
                    formatter() { return '<span class="weather-graph-y-axis-label">' + self.userFormatService.toUserNumber(this.value, 0, 4) + '</span>'; }
                }
            },
            tooltip: {
                enabled: true,
                followPointer: true,
                formatter() {
                    return '<span class="weather-graph-data-tooltip">' + self.timeTooltipString + ': ' + this.x + '</span><br>' +
                        '<span class="weather-graph-data-tooltip">' + self.valueTooltipString + ': ' + self.userFormatService.toUserNumber(this.y) + '</span>';
                },
            },
            plotOptions: {
                spline: {
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                    enableMouseTracking: true,
                },
                series: {
                    // enableMouseTracking: false,
                    // stickyTracking: false,
                    states: { inactive: { enabled: true } },
                    events: {
                        click() { self.chartClick.emit(); }
                    },
                    marker: {
                        enabled: false
                    },
                },
            },
            series: self.sensorSeriesData,
        };
    }

    get sensorSeriesData(): any[] {
        const dataSeries = [];

        switch (this.graphType) {
            case WeatherGraphType.Wind:
                dataSeries.push(this.getDataSeries(this.graphType, WeatherGraphChartColor.Wind));
                break;
            case WeatherGraphType.Temperature:
                dataSeries.push(this.getDataSeries(WeatherGraphType.DewPoint, WeatherGraphChartColor.DewPoint));
                dataSeries.push(this.getDataSeries(this.graphType, WeatherGraphChartColor.Temperature));
                break;
            case WeatherGraphType.Precipitation:
                dataSeries.push(this.getDataSeries(this.graphType, WeatherGraphChartColor.Precipitation));
                break;
            case WeatherGraphType.SolarRadiation:
                dataSeries.push(this.getDataSeries(this.graphType, WeatherGraphChartColor.SolarRadiation));
                break;
            case WeatherGraphType.RelativeHumidity:
                dataSeries.push(this.getDataSeries(this.graphType, WeatherGraphChartColor.RelativeHumidity));
                break;
        }

        return dataSeries;
    }

    private getDataSeries(graphType: WeatherGraphType, seriesColor: string) {
        let data = this.data[WeatherGraphType[graphType].pascalCaseToCamelCase()].values;

        switch (graphType) {
            case WeatherGraphType.DewPoint:
            case WeatherGraphType.Temperature:
                data = data.map(d => <number>this.userFormatService.temperature(d));
                break;
            case WeatherGraphType.Wind:
                data = data.map(d => <number>this.userFormatService.windSpeed(d));
                data = this.sanitizeDataSeries(data, graphType);
                break;
            case WeatherGraphType.Precipitation:
                data = data.map(d => <number>this.userFormatService.rainfall(d));
                data = this.sanitizeDataSeries(data, graphType);
                break;
            case WeatherGraphType.SolarRadiation:
                data = data.map(d => <number>this.userFormatService.solarRadiation(d));
                data = this.sanitizeDataSeries(data, graphType);
                break;
            case WeatherGraphType.RelativeHumidity:
                // data = data.map(d => d);
                break;
        }

        return {
            type: 'spline',
            marker: {
                enabled: this.isModal,
                radius: !this.isModal ? 3 : 4
            },
            color: seriesColor,
            data
        };
    }

    // Method to remove negative values from data series that should not dip below zero.
    // Data that falls below zero will use the previous data point value instead.
    private sanitizeDataSeries(data: number[], graphType: WeatherGraphType): number[] {
        let badDataReceived = false;
        let previousDataPoint = data[0] >= 0 ? data[0] : 0;

        for (let i = 0; i < data.length; i++) {
            if (data[i] < 0) {
                data[i] = previousDataPoint;
                badDataReceived = true;
                continue;
            }

            previousDataPoint = data[i];
        }

        if (badDataReceived) {
            const eventDetails = `Error: ${WeatherGraphType[graphType]} data contained negative values.`;
            this.analyticsService.widgetEvent(AnalyticsEvent.DataBadDataReceived, AnalyticsCategory.Error, AnalyticsEvent.WeatherGraphsWidgetName, eventDetails);
        }
        return data;
    }

    private get sensorSeriesCategories(): string[] {
        if (this.data == null) { return []; }

        const seriesData = this.data[WeatherGraphType[this.graphType].pascalCaseToCamelCase()];
        const categories = [];
        // const startTime = seriesData.startingTime;
        const dateFormat = this.userFormatService.dateFormat.substr(0, 2) === 'DD' ? 'D/M' : 'M/D';

        // The graph data we receive is historical. The last element in the values array is the current time (GMT). The first element
        // in the array is the oldest value, (current time) - (seriesData.values - length - 1).

        // The series start time is in UTC. We first adjust to course time based on the UTC offsite associated with the site.
        const courseTime = this.siteManager.getSiteMomentFromLocalDate(seriesData.startingTime.time);

        // Then we subtract (23) hours from that time to get the oldest time associated with our data.
        const oldestMoment = courseTime.clone();
        oldestMoment.subtract(seriesData.values.length - 1, "hours");

        // This allows us to lay out the data from left to right (oldest to newest) and apply the appropriate label.
        for (let i = 0; i < seriesData.values.length; i++) {
            let label = oldestMoment.add(i > 0 ? 1 : 0, 'hours').format('h A');

            // Add date to first and last day of range.
            if ((!this.isModal && i == 0) || (this.isModal && i == 1) || i == seriesData.values.length - 1) {
                label += `<br>(${oldestMoment.format(dateFormat)})`;
            }

            categories.push(label);
        }

        return categories;
    }
}
