import { Component, Input } from '@angular/core';
import { LynxStationStatusModel } from '../models/lynx-station-status.model';
import { ToroEnums } from '../../../../../../common/enumerations/toro.enums';
import LynxStationStatus = ToroEnums.LynxStationStatus;

@Component({
    selector: 'toro-lynx-station-status-card',
    templateUrl: './lynx-station-status-card.component.html',
    styleUrls: ['./lynx-station-status-card.component.less']
})
export class LynxStationStatusCardComponent {
    StationStatus = LynxStationStatus

    @Input() stationInfo: LynxStationStatusModel;
    @Input() useFilteredLayout = false;

    constructor() { }

}
