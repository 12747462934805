import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'toro-geo-fencing-dlg',
    templateUrl: './geo-fencing-dlg.component.html',
    styleUrls: ['./geo-fencing-dlg.component.less']
})
export class GeoFencingDlgComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() refresh = new EventEmitter();

    @Input() title = '';
    @Input() lastUpdateTimeString: string;
    @Input() showRefreshButton = false;
    @Input() disableRefreshButton = false;

    showTable = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        // Give a chance for the dialog to render before showing/sizing the p-table.
        setTimeout(() => this.showTable = true);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    close() {
        this.cancel.emit();
    }

    onRefresh() {
        this.refresh.emit();
    }

}
