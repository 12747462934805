import PwChartDataType = ToroEnums.PwChartDataType;

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { filter } from 'rxjs/operators';
import { PwChartSeries } from '../../../../../api/perry-weather/models/pw-chart-series.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-perry-weather-chart',
    templateUrl: './perry-weather-chart.component.html',
    styleUrls: ['./perry-weather-chart.component.less']
})
export class PerryWeatherChartComponent implements OnInit {
    @HostBinding('class') class = 'toro-weather-graph-chart';
    @ViewChild('wgChartContainer') wgChartContainer: ElementRef;

    @Output() chartLoaded = new EventEmitter();
    @Output() chartClick = new EventEmitter<PwChartDataType>();

    private readonly chartWidth = 850;
    private readonly chartHeight = 500;

    private noChartDataTimerRef: any;

    @Output() clearChartChange = new EventEmitter<boolean>();

    private _clearChart = false;
    @Input() set clearChart(value: boolean) {
        this._clearChart = value;
        if (this._clearChart) {
            clearTimeout(this.noChartDataTimerRef);
            this.setNoChartData();
            this.clearChart = false;
            setTimeout(() => this.clearChartChange.emit(false));
        }
    }

    get clearChart(): boolean {
        return this._clearChart;
    }

    private _chartData: PwChartSeries[] = [];
    @Input() set chartData(value: PwChartSeries[]) {
        if (value == null || value.length < 1) {
            this.noChartDataTimerRef = setTimeout(() => {
                this.setNoChartData();
            }, 500)
            return;
        }

        this._chartData = value;
        this.showNoDataNotice = false;
        clearTimeout(this.noChartDataTimerRef);

        this.updateChart();
    }

    get chartData(): PwChartSeries[] {
        return this._chartData;
    }

    private _chartDataType: PwChartDataType;
    @Input() set chartDataType(value: PwChartDataType) {
        this._chartDataType = value;

        if (this.chartData == null || this.chartData.length < 1) return;
        this.updateChart();
    }

    get chartDataType(): PwChartDataType {
        return this._chartDataType;
    }

    private _isModal = false;
    @Input() set isModal(value: boolean) {
        this._isModal = value;
    }

    get isModal(): boolean {
        return this._isModal;
    }

    @Input() isBusy = false;

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;
    dateTooltipString: string;
    valueTooltipString: string;
    unitsTooltipString: string;
    yExtentMin: number;
    yExtentMax: number;
    xAxisLabels: string[];
    resizeTimerRef: any;
    isResizingChart = false;
    showNoDataNotice = false;

    private chartSetupTimer: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private deviceManager: DeviceManagerService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        this.translateService.instant('STRINGS.HELLO');
    }

    ngOnInit(): void {
        this.dateTooltipString = this.translateService.instant('STRINGS.DATE').toTitleCase();
        this.valueTooltipString = this.translateService.instant('STRINGS.VALUE').toTitleCase();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateChart());

        this.deviceManager.windowResize
            .pipe(
                untilDestroyed(this),
                filter(() => this.isModal)
            )
            .subscribe(() => {
                if ((window.innerWidth > this.chartWidth + 75 && window.innerHeight > this.chartHeight)) return;

                if (this.chartOptions) this.chartOptions = null;

                this.isResizingChart = true;
                clearTimeout(this.resizeTimerRef);
                this.resizeTimerRef = setTimeout(() => {
                    this.chart = null;
                    this.updateChart();
                    this.isResizingChart = false;
                }, 100);
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setSizeOfChartContainer() {
        if (!this.wgChartContainer) { return; }
        this.chartContainerWidth = this.wgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.wgChartContainer.nativeElement.offsetHeight - 2;
    }

    private updateChart() {
        const seriesData = this.getSeriesData()
        if (seriesData == null) { return;}

        this.setXAxisLabels();

        if (!this.chart) {
            clearTimeout(this.chartSetupTimer);
            this.chartSetupTimer = setTimeout(() => this.setupChart(), !this.isModal ? 100 : 250);
        } else {
            this.chart.update({
                chart: {
                    type: 'spline',
                },
                series: this.getSeriesData(),
                xAxis: {
                    categories: this.xAxisLabels,
                },
                yAxis: {
                    min: this.yExtentMin ?? undefined,
                    max: this.yExtentMax ?? undefined,
                }
            }, true);
        }
    }

    private setupChart() {
        const self = this;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) { self.chart = event.target; },
                    click() { self.chartClick.emit(self.chartDataType); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [6, 2, 3, 2],
                marginTop: this.isModal ? 10 : undefined
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                tickLength: 7,
                tickWidth: 1,
                tickmarkPlacement: "on",
                type: "categories",
                categories: self.xAxisLabels,
                labels: {
                    formatter() { return '<span class="weather-graph-x-axis-label">' + this.value + '</span>'; },
                },
                gridLineWidth: 1,
            },
            yAxis: {
                tickAmount: 4,
                min: self.yExtentMin ?? undefined,
                max: self.yExtentMax ?? undefined,
                allowDecimals: true,
                title: { enabled: false },
                labels: {
                    formatter() { return '<span class="weather-graph-y-axis-label">' + self.userFormatService.toUserNumber(this.value, 0, 4) + '</span>'; }
                }
            },
            tooltip: {
                enabled: true,
                followPointer: true,
                formatter() {
                    return '<span class="weather-graph-data-tooltip">' + self.dateTooltipString + ': ' + this.x + '</span><br>' +
                        '<span class="weather-graph-data-tooltip">' + self.valueTooltipString + ': ' + self.userFormatService.toUserNumber(this.y) + ' <span>' + self.unitsTooltipString + '</span></span>';
                },
            },
            plotOptions: {
                spline: {
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                    enableMouseTracking: true,
                },
                series: {
                    states: { inactive: { enabled: true } },
                    // events: {
                    //     click() { self.chartClick.emit(self.getLegendItems()); }
                    // },
                    marker: {
                        enabled: false
                    }
                },
            },
            series: self.getSeriesData()
        };
    }

    private getSeriesData(): any[] {
        const data: any[] = [];
        this.showNoDataNotice = false;

        const seriesData = this.getSeriesValues(this.chartData.find(d => d.dataType === this.chartDataType)?.values);
        if (seriesData == null) {
            this.setNoChartData();
            return null;
        }

        data.push({
            marker: {
                enabled: this.isModal,
                radius: !this.isModal ? 3 : 4
            },
            data: seriesData
        })

        this.yExtentMin = Math.min(...seriesData);
        if (this.yExtentMin !== 0) this.yExtentMin;

        this.yExtentMax = Math.max(...seriesData) + 1;

        return data;
    }

    private getSeriesValues(data: number[]): number[] {
        if (data == null) { return null; }

        let dataSeries: number[];

        switch (this.chartDataType) {
            case PwChartDataType.PrecipForecast:
                dataSeries = data.map(d => <number>this.userFormatService.precipitationFromCm(d))
                this.unitsTooltipString = this.userFormatService.precipitationFromCmUnits;
                break;
            case PwChartDataType.PrecipHistorical:
                dataSeries = data.map(d => <number>this.userFormatService.precipitationFromCm(d))
                this.unitsTooltipString = this.userFormatService.precipitationFromCmUnits;
                break;
            case PwChartDataType.EtHistorical:
                // NOTE: Et data is provided in inches (imperial! Not metric like the other values)
                dataSeries = data.map(d => +this.userFormatService.evapotranspiration(d, true))
                this.unitsTooltipString = this.userFormatService.evapotranspirationUnits;
                break;
            default:
                return [];
        }

        return dataSeries;
    }

    private setXAxisLabels() {
        const days = [];
        const startDate = moment(this.chartData.find(d => d.dataType === this.chartDataType)?.startDate);

        for (let i = 0; i < 7; i++) {
            const date = startDate.clone().add(i, 'days');
            days.push(date.utc().format('M/D'));
        }

        this.xAxisLabels = days;
    }

    private setNoChartData() {
        this.chart = null;
        this.chartOptions = null;
        this.showNoDataNotice = true;
    }

}
