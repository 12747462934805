import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SensorThresholdsModel } from './models/sensor-thresholds.model';
import { SensorThresholdsUpdateModel } from './models/sensor-thresholds-update.model';

@Injectable({
    providedIn: 'root'
})
export class DecisionTreeApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getSensorThresholds(holeNumber?: number, sensorNumber?: number): Observable<SensorThresholdsModel[]> {
        return this.apiService.apiRequest<any>(DecisionTreeApiService.getSensorThresholdsUrl(holeNumber, sensorNumber), HttpMethod.Get)
            .pipe(map((result: SensorThresholdsModel[]) => result.map(r => new SensorThresholdsModel(r))));
    }

    addUpdateSensorThresholds(sensorThresholds: SensorThresholdsUpdateModel): Observable<void> {
        return this.apiService.apiRequest<any>(DecisionTreeApiService.updateSensorThresholdsUrl, HttpMethod.Put, sensorThresholds);
    }

    deleteSensorThresholds(thresholdIds: number[]): Observable<void> {
        return this.apiService.apiRequest<any>(DecisionTreeApiService.deleteSensorThresholdsUrl(thresholdIds), HttpMethod.Delete);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}decisionTree`; }

    private static getSensorThresholdsUrl(holeNumber?: number, sensorNumber?: number): string {
        let queryString = '';
        const hasParams = holeNumber != null || sensorNumber != null;

        if (hasParams) {
            queryString += '?';
            queryString += holeNumber != null ? `holeNumber=${holeNumber}` : '';
            queryString += sensorNumber != null ? `${holeNumber != null ? '&' : ''}sensorNumber=${sensorNumber}` : '';
        }

        return `${DecisionTreeApiService.baseUrl}/sensorThresholds${queryString}`;
    }

    private static get updateSensorThresholdsUrl(): string {
        return `${DecisionTreeApiService.baseUrl}/sensorThresholds`;
    }

    private static deleteSensorThresholdsUrl(thresholdsIds: number[]): string {
        const queryParams = thresholdsIds.reduce((list, id) => `${list ? list + '&' : ''}ids=${id}`, '');
        return `${DecisionTreeApiService.baseUrl}/sensorThresholds?${queryParams}`;
    }

}
