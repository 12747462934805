<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="true" [dismissableMask]="true" [closable]="false" position="center" [style]="{width: '1100px', maxWidth: '95%', maxHeight: '85%', minWidth: '250px', minHeight: '300px'}" styleClass="tid-ezl-dialog">
    <p-header>
        <div id="toro-dlg-header-container">
            <div id="td-header-left">
                <img id="tid-dlg-title-icon" src="../../../../../../assets/images/gcsaa/gcsaa-logo-of.png" alt="">
                <div class="toro-flex-child">{{'STRINGS.NEWSFEED' | translate}}</div>
            </div>
            <div id="td-header-right">
                <div class="toro-widget-toolbar-button" (click)="onGotoSite()"><fa-icon icon="external-link-alt"></fa-icon></div>
            </div>
        </div>
    </p-header>

    <div id="tid-rss-article-container">
        <div id="tid-rss-article-title">
            {{article?.title}}
        </div>
        <div [innerHTML]="articleHtml"></div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>
