import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToroConstants } from '../constants/toro.constants';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class DeviceManagerService {

    // Subjects
    windowResize = new Subject();						                    // Fired when application window is resized.
    gridsterMobileModeChange = new Subject<{ isMobileMode: boolean }>();    // Fired when Gridster enters/exists mobile mode.

    // Constants

    // NOTE: These two value are related. If the 'breakpoint' is
    // changed the other will need to be adjusted accordingly.
    readonly GRIDSTER_MOBILE_BREAKPOINT = 50;
    readonly WIDTH_TO_FORCE_GRID_TO_REDRAW = 521;

    private _isGridsterInMobileMode = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceDetector: DeviceDetectorService) {

        this.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isGridsterInMobileMode = window.innerWidth <= this.WIDTH_TO_FORCE_GRID_TO_REDRAW;
            });

        this.isGridsterInMobileMode = window.innerWidth <= this.WIDTH_TO_FORCE_GRID_TO_REDRAW;
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get isGridsterInMobileMode(): boolean {
        return this._isGridsterInMobileMode;
    }

    set isGridsterInMobileMode(value: boolean) {
        if (this._isGridsterInMobileMode === value) return;

        this._isGridsterInMobileMode = value;
        this.gridsterMobileModeChange.next({ isMobileMode: value });
    }

    get isMobile(): boolean {
        return this.deviceDetector.isMobile() || window.innerWidth < ToroConstants.MaxMobileWindowWidth;
    }

}
