import { GenericMqttMessageContent } from '../generic-mqtt-message-content.model';
import { GetStationsStation } from './get-stations-station.model';

export class GetStationsContent extends GenericMqttMessageContent {

    constructor(json: any = null) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.result) this.result = json.result.map(c => new GetStationsStation(c));
        }
    }

    result: GetStationsStation[];
}
