<div class="wiz-integration-content">
    <div id="left-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.CONNECT_PLAYBOOKS_TO_APP' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
<!--            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step2"></ng-container>-->
        </toro-step-group>
        <toro-step-action>
            <div>
                <img src="assets/images/wizard/playbooks.png" alt="Playbooks image">
            </div>
            <div class="wic-playbooks-info">
                <div>{{'CASE_SENSITIVE.ENTER_URL' | translate}}</div>
                <input pInputText
                       class="playbooks-url"
                       [(ngModel)]="playbooksUrl"
                       [size]="50"
                       [placeholder]="'Playbooks URL'"
                       (paste)="onParseUrl($event)"
                       (cut)="onParseUrl($event)"
                />

                <div *ngIf="courseId != null && courseId != ''" id="wic-playbooks-course-id">Course ID: {{courseId}}</div>
                <div *ngIf="showError" id="wic-playbooks-error">{{'SPECIAL_MSG.PLAYBOOKS_DATA_COULD_NOT_BE_RETRIEVED' | translate}}.</div>
                <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)"></toro-waiting-indicator>
            </div>
        </toro-step-action>
    </div>
    <div id="right-content" class="wiz-integration-group">
        <img src="assets/images/wizard/playbooksSite_redact.png" alt="">
    </div>
</div>

<ng-template #step1>
    <div>
<!--        {{'STRINGS.SIGN_INTO' | translate}} <a href="javascript:void(0);" (click)="onNavigateToSite()">Playbooks</a>.-->
        {{'CASE_SENSITIVE.COPY_PLAYBOOKS_URL' | translate}}
    </div>
</ng-template>

<!--<ng-template #step2>-->
<!--    {{'CASE_SENSITIVE.COPY_URL' | translate}}.-->
<!--</ng-template>-->
