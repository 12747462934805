<div class="lynx-pump-stations-card-container toro-component-shield-host">

    <div class="lps-pump-name">
        {{ pumpStation?.name }}
        <fa-icon class="lps-info-icon" icon="info-circle" (click)="onShowInfoDialog()"></fa-icon>
    </div>

    <div class="lps-pump-detail" [ngClass]="language">
        <div class="lps-pump-detail-col">
            <div class="lps-pump-detail-row lps-pump-property-label">{{'STRINGS.PRESSURE' | translate}} <span class="lps-pump-property-label-units">({{pressureUnits}})</span></div>
            <div class="lps-pump-detail-row lps-pump-property-value" [ngClass]="pressureValueRangeClass">{{pressure}}</div>
            <div class="lps-pump-detail-row lps-pump-target">({{'STRINGS.SET_POINT' | translate}}: {{pressureSetPoint}})</div>
        </div>

        <div class="lps-pump-detail-col">
            <div class="lps-pump-detail-row lps-pump-property-label">{{'STRINGS.FLOW' | translate}} <span class="lps-pump-property-label-units">({{flowUnits}})</span></div>
            <div class="lps-pump-detail-row lps-pump-property-value" [ngClass]="flowValueRangeClass">{{flow}}</div>
            <div class="lps-pump-detail-row lps-pump-target">({{'STRINGS.CAPACITY' | translate}}: {{flowCapacity}})</div>
        </div>
    </div>

    <toro-component-shield></toro-component-shield>
</div>

<!-- Dialogs -->
<toro-pump-station-details-dlg *ngIf="showInfoDialog" [pumpStation]="pumpStation" (closeDialog)="showInfoDialog = false"></toro-pump-station-details-dlg>
