import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { FrostForecastDatum } from './frost-forecast-datum.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import FrostRisk = ToroEnums.FrostRisk;

export class FrostRiskData {

    private _ranges: number[][];
    private _averages: number[][];

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.forecastTemperatures) this.forecastTemperatures = !environment.isDemoMode
                ? JSON.parse(json.forecastTemperatures).map(f => new FrostForecastDatum(f))
                : json.forecastTemperatures.map(f => new FrostForecastDatum(f));
        }
    }

    lastUpdated: Date;
    forecastTemperatures: FrostForecastDatum[];

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get ranges(): number[][] {
        if (!this._ranges) {
            let index = 0;
            this._ranges = [];
            this.forecastTemperatures.forEach(f => {
                this._ranges.push([index++, f.minTemp, f.maxTemp]);
            });
        }

        return this._ranges;
    }

    get averages(): number[][] {
        if (!this._averages) {
            let index = 0;
            this._averages = [];
            this.forecastTemperatures.forEach(f => {
                this._averages.push([index++, f.avgTemp]);
            });
        }

        return this._averages;
    }

    get isFrosty(): boolean {
        return this.forecastTemperatures[0].risk === FrostRisk.High;
    }

    get frostRiskEndDate(): Date {
        const firstNoFrostForecast = this.forecastTemperatures.find(f => f.risk === FrostRisk.None);
        return firstNoFrostForecast ? firstNoFrostForecast.time : null;
    }
}
