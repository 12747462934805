import * as moment from 'moment';

export class TaskTrackerMeasurementDetail {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.date) { this.date = moment(json.date).toDate(); }
        }
    }

    units: string;
    measurement: number;
    date: Date;
    sectionName: string;
    measurementByZone: number;
}
