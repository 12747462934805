import WizardIntegration = ToroEnums.WizardIntegration;
import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { PerryWeatherManagerService } from '../../../../../api/perry-weather/perry-weather-manager.service';
import { PwLocation } from '../../../../../api/perry-weather/models/pw-location.model';
import { PwObservation } from '../../../../../api/perry-weather/models/pw-observation.model';
import { PwPoint } from '../../../../../api/perry-weather/models/pw-point.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../../models/wizard-settings.model';

@UntilDestroy()
@Component({
    selector: 'toro-perry-weather-integration',
    templateUrl: './perry-weather-integration.component.html',
    styleUrls: ['./perry-weather-integration.component.less']
})
export class PerryWeatherIntegrationComponent implements OnInit {
    @HostBinding('class') class = 'wiz-integration-component perry-weather-integration';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();
    @Output() settingsChange = new EventEmitter<WizardSettings>();

    @Input() settings: WizardSettings;

    readonly stepsCount = 1;
    private elements: any;

    isBusy = false;
    showError = false;
    spinnerText = 'CASE_SENSITIVE.VERIFYING_INTEGRATION_KEY'
    integrationKey: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private perryWeatherManager: PerryWeatherManagerService,
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.verifyConfiguration();
            });

        // We want to modify the behavior of the p-inputNumber component to allow us to assess the controls
        // value after each key press. To do that, we will search the dom for our unique container component
        // and then find out "common" p-inputNumber class element. There will only be one in our container.
        // Once we have our element, we will assign the keyup event handler.
        const container = document.getElementsByClassName('wic-perry-weather-info');
        this.elements = container[0].getElementsByClassName('perry-weather-integration-key');

        if (this.elements.length != 1) throw new Error('There should be one and only one input element here.');
        this.elements[0].addEventListener('keyup', this.keyUp.bind(this))

        if (this.settings && this.settings.perryWeatherIntegrationKey != null) {
            this.integrationKey = this.settings.perryWeatherIntegrationKey;
            this.canConnect.next(true);
        }
    }

    ngOnDestroy() {
        this.elements[0].removeEventListener('keyup', this.keyUp.bind(this))
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    private keyUp(event) {
        this.canConnect.emit(event.target.value.length == 36 && !event.target.value.includes('_'));
    }

    onPasteApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    onCutApiKey(event: ClipboardEvent) {
        const pastedText = event?.clipboardData?.getData('text');
        this.canConnect.emit(pastedText && pastedText.length == 36 && !pastedText.includes('_'));
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private verifyConfiguration() {
        this.showError = false;
        this.isBusy = true;
        let locId: number;
        let dashUser: DashUserInfo;

        const location = new PwLocation({
            name: 'Integration Key Validation',
            point: new PwPoint({
                lat: `0.${new Date().getTime()}`,
                lng: `0.${new Date().getTime()}`,
            }),
            integrationKey: `${this.integrationKey}`
        });

        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.perryWeatherManager.createLocation(location)
        ];

        forkJoin(sources)
            .pipe(
                take(1),
                switchMap(([user, locationId]) => {
                    dashUser = user;
                    locId = locationId;
                    return this.perryWeatherManager.getObservation(locationId)
                        .pipe(finalize(() => {
                            this.perryWeatherManager.deleteLocation(locId).subscribe();
                            this.isBusy = false;
                        }));
                })
            )
            .subscribe({
                next: (observation: PwObservation) => {

                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.PerryWeather, isEnabled: true });

                    const userPrefs = dashUser.preferences;
                    userPrefs.pwIntegrationKey = this.integrationKey;
                    this.dashUserManager.updateDashUserPreferences(userPrefs)
                        .subscribe(() => {
                            this.settings.perryWeatherIntegrationKey = this.integrationKey;
                            this.settingsChange.next(this.settings);
                            this.configured.next(WizardIntegration.PerryWeather);

                            // Notify Perry Weather Widget. This will only matter if the Perry Weather widget is already being displayed.
                            this.broadcastService.setupPerryWeatherIntegrationKeyChanged.next(this.integrationKey);
                        })
                },
                error: err => {
                    const error = err?.error?.Error_1[0];
                    if (error && error.toLowerCase().includes('integration key not found')) {
                        this.showError = true;
                    }
                }
            })

    }
}
