import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'toro-main-content-container',
    templateUrl: './main-content-container.component.html',
    styleUrls: ['./main-content-container.component.less']
})
export class MainContentContainerComponent {
    @HostBinding('class') class = 'toro-main-content-container';

    constructor() {
    }

}
