export class PwObservationMeta {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    stationName: string;
    stationType: number;        // Enum
}
