import { TaskTrackerMeasurement } from './task-tracker-measurement.model';

export class TaskTrackerMeasurementSummary {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.measurements) { this.measurements = json.measurements.map(m => new TaskTrackerMeasurement(m)); }
        }
    }

    departmentId: number;
    departmentName: string;
    measurements: TaskTrackerMeasurement[];

}
