import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;
import SoilScoutThresholdTab = ToroEnums.SoilScoutThresholdTab;

import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { filter } from 'rxjs/operators';
import { LocalCacheService } from '../../../../../common/services/local-cache.service';
import { SoilScoutGaugeRange } from '../models/soil-scout-gauge-range.model';
import { SoilScoutManagerService } from '../../../../../api/soil-scout/soil-scout-manager.service';
import { SoilScoutThresholdChange } from '../models/soil-scout-threshold-change.model';
import { SoilScoutWidgetBase } from '../_soil-scout-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-soil-scout-salinity',
    templateUrl: './widget-soil-scout-salinity.component.html',
    styleUrls: ['./widget-soil-scout-salinity.component.less']
})
export class WidgetSoilScoutSalinityComponent extends SoilScoutWidgetBase implements OnInit {

    iconColor = 'black';
    title = 'WIDGET.SOIL_SCOUT_SALINITY';

    protected readonly SoilScoutWidgetBase = SoilScoutWidgetBase;
    protected readonly SoilScoutThresholdTab = SoilScoutThresholdTab;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected localCacheService: LocalCacheService,
                protected soilScoutManager: SoilScoutManagerService,
                protected translateService: TranslateService,
                protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, localCacheService, soilScoutManager, translateService, userFormatService);
    }

    ngOnInit(): void {
        this.sensorType = SoilScoutSensorType.Salinity;
        super.ngOnInit();

        // Set Gauge Ranges
        this.gaugeRange = this.defaultSalinityRange;

        SoilScoutWidgetBase.commonDataUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (SoilScoutWidgetBase.userPreferences.ssSalinityRange != null) {
                    const range = SoilScoutWidgetBase.userPreferences.ssSalinityRange;
                    this.gaugeRange.rangeMin = range.rangeMin;
                    this.gaugeRange.rangeMax = range.rangeMax;
                    this.gaugeRange.range1UpperBoundary = range.range1UpperBoundary;
                    this.gaugeRange.range2UpperBoundary = range.range2UpperBoundary;
                    this.gaugeRange.range3UpperBoundary = range.range3UpperBoundary;
                }

                setTimeout(() => {
                    this.setupGauge()
                    setTimeout(() => {
                        this.setGaugeValues();
                        this.lastUpdateTimestamp = new Date();
                    });
                });
            })

        SoilScoutWidgetBase.unableToRetrieveData
            .pipe(untilDestroyed(this))
            .subscribe((reason) => this.setIsUnableToFetchData(reason, null, false))

        SoilScoutWidgetBase.attemptingLogin
            .pipe(
                untilDestroyed(this),
                filter((sensorType: SoilScoutSensorType) => this.sensorType != sensorType)
            )
            .subscribe(() => this.isBusy = true)

        SoilScoutWidgetBase.successfulLogin
            .pipe(
                untilDestroyed(this),
                filter((sensorType: SoilScoutSensorType) => this.sensorType != sensorType)
            )
            .subscribe((reason) => {
                this.clearIsUnableToFetchData();
                this.isBusy = true;
                this.getWidgetData(true)
            })

        SoilScoutWidgetBase.gaugeRangeUpdate
            .pipe(untilDestroyed(this))
            .subscribe((change: SoilScoutThresholdChange) => this.updateGaugeRanges(change));

        this.broadcastService.setupSoilScoutLoginChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.clearIsUnableToFetchData();
                this.isBusy = true;
                this.getWidgetData(true)
            })
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.SoilScoutSalinityWidgetName;
    }

    protected get axisValueUnits(): string {
        return '';
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateGaugeRanges(change: SoilScoutThresholdChange) {
        if (change.salinityGaugeRange != null) {
            this.isGaugeLoaded = false;
            this.isBusy = true;

            setTimeout(() => {
                this.gaugeRange = new SoilScoutGaugeRange({ ...change.salinityGaugeRange });

                this.setGaugeValues();
                setTimeout(() => {
                    this.isGaugeLoaded = true;
                    this.isBusy = false;
                }, 500);
            }, 500)
        }
    }
}
