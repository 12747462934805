import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { YouTubeVideoInfo } from '../models/you-tube-video-info.model';

declare var YT;

@Component({
    selector: 'toro-video-panel-modal',
    templateUrl: './video-panel-modal.component.html',
    styleUrls: ['./video-panel-modal.component.less']
})
export class VideoPanelModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('player') player: ElementRef;

    @Output() cancel = new EventEmitter();

    @Input() videoInfo: YouTubeVideoInfo;

    protected isPlayerReady = false;
    protected safeUrl: SafeResourceUrl;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.safeUrl = this.getSafeUrl(this.videoInfo.videoId)
    }

    ngAfterViewInit() {
        this.initializeYouTubePlayer();
    }

    ngOnDestroy() {
        // this.destroyYouTubePlayer();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onPlayerReady(event) {
        this.isPlayerReady = true;
    }

    onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING) {
            // The video is playing
        }
    }

    onClose() {
        this.cancel.next(null);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private initializeYouTubePlayer() {
        // Use existing iFrame
        const onYouTubeIframeAPIReady = () => {
            new YT.Player(this.player.nativeElement, {
                videoId: this.videoInfo.videoId,
                events: {
                    'onReady': this.onPlayerReady.bind(this),
                    'onStateChange': this.onPlayerStateChange.bind(this)
                }
            });
        };

        // Load the IFrame Player API code asynchronously if it hasn't been loaded yet
        if (!window['YT']) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window['onYouTubeIframeAPIReady'] = onYouTubeIframeAPIReady;
        } else {
            onYouTubeIframeAPIReady();
        }
    }

    // private destroyYouTubePlayer() {
    //     if (!window['YT']) return;
    //
    //     window['onYouTubeIframeAPIReady'] = null; // or () => {}
    //
    //     const ytScriptTag = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
    //     if (ytScriptTag) {
    //         ytScriptTag.parentNode.removeChild(ytScriptTag);
    //     }
    //
    //     window['YT'].Player = null;
    //     window['YT'] = null;
    // }

    private getSafeUrl(videoId: string) {
        let videoUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }

}
