import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { CourseListEntry } from '../../../../../api/ez-locator/models/course-list-entry.model';
import { DailyPinSheet } from '../../../../../api/ez-locator/models/daily-pin-sheet.model';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { EzLocatorAccessId } from '../../../../../api/ez-locator/models/ez-locator-access-id.model';
import { EzLocatorConfig } from '../../../../../api/ez-locator/models/ez-locator-config.model';
import { EzLocatorManagerService } from '../../../../../api/ez-locator/ez-locator-manager.service';
import { EzLocatorPinSheetSummary } from '../models/ez-locator-pin-sheet-summary.model';
import { GreenImageEntry } from '../../../../../api/ez-locator/models/green-image-entry.model';
import { PinSheetEntry } from '../../../../../api/ez-locator/models/pin-sheet-entry.model';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';


enum  EzTab {
    Holes,
    Placement
}

@UntilDestroy()
@Component({
    selector: 'toro-widget-ez-locator',
    templateUrl: './widget-ez-locator.component.html',
    styleUrls: ['./widget-ez-locator.component.less']
})
export class WidgetEzLocatorComponent extends ToroDashboardWidget implements OnInit {
    iconColor = 'black';
    title = 'WIDGET.EZ_LOCATOR';

    // private readonly ClientAccessId = 'd70a3628-4a51-4b1a-81da-1dd7d6896b88'    // Single Course - Toro Demo
    // private readonly ClientAccessId ='78bc75e5-ab0e-490f-bd26-d4030d92c880'  // Multi Course - Dallas Athletic Club

    EzTab = EzTab;

    readonly ImageSize = 160;                                             // Size of each greens image in pixels, square.
    readonly holeDiameter = 10;                                            // In pixels (font size of hole icon)
    readonly holeXOffset = this.holeDiameter / 2;
    private readonly holeIconTopPad = 1.5;                                // Icon whitespace above circle image. This is not an exact offset and will only work at small font sizes.
    readonly holeYOffset = this.holeDiameter / 2 + this.holeIconTopPad;
    readonly MiniModalImageSize = 250;
    private acctLinkTimerRef: any;
    private readonly maxAccessIdPollingAttempts = 12;
    private accessIdPollingAttempts = 0;
    private isLinkingAccount = false;

    language='en-us';
    clientAccessId: string;
    coursesSelectList: SelectItem[] = [];
    greensSelectList: SelectItem[] = [];
    courseList: CourseListEntry[];
    dailyPinSheets: DailyPinSheet[];
    greenImages: GreenImageEntry[];
    selectedCourseEntry: CourseListEntry;
    desktopPinDetails: PinSheetEntry[] = [];
    miniModalPinDetails: PinSheetEntry[] = [];
    selectedPinStimpString = '';
    selectedGreen: GreenImageEntry;
    pinSheetSummary = new EzLocatorPinSheetSummary();
    widgetConfig: EzLocatorConfig;
    isRetrievingCourseData = false;
    isPrevButtonDisabled = true;
    isNextButtonDisabled = true;
    showDesktopModal = false;
    selectedTab = EzTab.Holes;
    pinLegendStrings = {
        abbreviated: ['STRINGS.EZ_LOCATOR_YESTERDAY_ABBR', 'STRINGS.EZ_LOCATOR_TODAY_ABBR', 'STRINGS.EZ_LOCATOR_TOMORROW_ABBR'],
        full: ['STRINGS.YESTERDAY', 'STRINGS.TODAY', 'STRINGS.TOMORROW']
    }

    private lastDataRetrievalDate: Date;

    private _selectedCourseId: string;
    set selectedCourseId(value: string) {
        this._selectedCourseId = value;
        this.getCourseData();

        this.widgetConfig.selectedCourseAccessId = value;
        this.updateEzLocatorConfig();
    }

    get selectedCourseId(): string {
        return this._selectedCourseId;
    }

    private _selectedGreenId: number;
    set selectedGreenId(value: number) {
        this._selectedGreenId = value;
        this.getGreenData();

        this.widgetConfig.selectedGreenId = value;
        this.updateEzLocatorConfig();
    }

    get selectedGreenId(): number {
        return this._selectedGreenId;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private ezLocatorManager: EzLocatorManagerService,
                protected translateService: TranslateService,
                protected widgetManager: WidgetManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setGreensSelectList());

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
            });

        this.language = this.dashUserManager.language;

        this.getAccessId();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.EzLocatorWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (this.clientAccessId == null && !this.isLinkingAccount) this.getAccessId();

        // If the initial data retrieval has not occurred, don't attempt to update the data.
        if (this.lastDataRetrievalDate == null) return;

        this.getCourseData(!isManualRefresh);
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onExpandPinSheet() {
        this.onToggleDesktopModal();
    }

    onGotoSite() {
        this.onNavigateToEzl('');
    }

    onNavigateToEzl(page: string) {
        let link = '/';

        switch (page) {
            case 'pinsheet':
                link += 'pinsheet';
                break;
            case 'events':
                link += 'events';
                break;
            case 'greens':
                link += 'manage';
                break;
            case 'linkAccount':
                this.isLinkingAccount = true;
                link += `/externalauth?username=${this.dashUserManager.userEmail}&apikey=${environment.ezLocatorApiKey}`
                setTimeout(() => this.getAccessId());
        }

        this.navigateToEzLocatorUrl(`${environment.ezLocatorLinkUrl}${link}`)
    }

    onToggleDesktopModal() {
        this.showDesktopModal = !this.showDesktopModal;
    }

    onToggleTab() {
        this.selectedTab = this.selectedTab === EzTab.Holes ? EzTab.Placement : EzTab.Holes;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateEzLocatorConfig() {
        if (environment.isDemoMode) return;

        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.widgetConfig)
            .pipe(take(1))
            .subscribe({
                next: () => {},
                error: () => {
                    this.dashMessageService.showGenericSaveErrorMessage();
                }
            });
    }

    private getAccessId() {
        const userEmail = this.dashUserManager.userEmail;

        this.ezLocatorManager.getAccessId(userEmail)
            .subscribe({
                next: (clientAccessId: EzLocatorAccessId) => {
                    this.isLinkingAccount = false;
                    if (this.acctLinkTimerRef) clearTimeout(this.acctLinkTimerRef);
                    this.clientAccessId = clientAccessId.clientAccessId;
                    this.getInitialData();
                },
                error: err => {
                    this.isBusy = false;
                    if (!this.isLinkingAccount) return;

                    if (++this.accessIdPollingAttempts > this.maxAccessIdPollingAttempts) {
                        this.accessIdPollingAttempts = 0;
                        this.isLinkingAccount = false;
                        return;
                    }

                    if (this.acctLinkTimerRef) clearTimeout(this.acctLinkTimerRef);
                    this.acctLinkTimerRef = setTimeout(() => {
                        this.getAccessId();
                    }, 10 * 1000)
                }
            })
    }

    private getInitialData() {
        const sources: Observable<any>[] = [
            this.ezLocatorManager.getEzLocatorConfig(this.associatedWidget),
            this.ezLocatorManager.getCourseList(this.clientAccessId).pipe(take(1))
        ];

        forkJoin(sources)
            .subscribe({
                next: ([config, courseList]) => {
                    this.widgetConfig = config;

                    if (!environment.isDemoMode) {
                        this.updateIntervalInMinutes = config.dataRefreshIntervalMinutes;
                        this.staleDataThresholdInMinutes = config.staleDataThresholdMinutes;
                    }

                    this.courseList = courseList;
                    this.coursesSelectList = [];
                    this.courseList.forEach(c => this.coursesSelectList.push({ label: c.courseName, value: c.courseAccessId }));

                    // Set selectedCourseId to previously selected or first in list.
                    const previouslySelectedCourse = this.courseList.find(c => c.courseAccessId == this.widgetConfig.selectedCourseAccessId)
                    this.selectedCourseId = previouslySelectedCourse != null ? previouslySelectedCourse.courseAccessId : this.courseList[0].courseAccessId;
                }
            })
    }

    private getCourseData(isAutoUpdate = false) {
        if (this.selectedCourseEntry != null && !isAutoUpdate) this.isRetrievingCourseData = true;

        this.selectedCourseEntry = this.courseList.find(c => c.courseAccessId == this.selectedCourseId);
        if (this.selectedCourseEntry == null) {
            this.isRetrievingCourseData = false;
            return;
        }

        const sources: Observable<any>[] = [
            this.ezLocatorManager.getPinSheetData(this.selectedCourseEntry.courseAccessId, this.ImageSize).pipe(take(1)),
            this.ezLocatorManager.getGreenImages(this.selectedCourseEntry.courseAccessId, this.ImageSize).pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.resetBusy()))
            .subscribe({
                next: ([dailyPinSheets, greenImages]) => {
                    this.lastDataRetrievalDate = new Date();
                    this.lastUpdateTimestamp = new Date();

                    this.dailyPinSheets = dailyPinSheets;
                    this.greenImages = greenImages;

                    this.setGreensSelectList();

                    // Set Summary Info
                    const todayPinSheet = this.dailyPinSheets[1];
                    this.pinSheetSummary.placementLeftCount = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += e.holeSide.includes('L') ? 1 : 0, 0);
                    this.pinSheetSummary.placementRightCount = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += e.holeSide.includes('R') ? 1 : 0, 0);
                    this.pinSheetSummary.lowStimpCount = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += e.stimp.startsWith('0') ? 1 : 0, 0);
                    this.pinSheetSummary.midStimpCount = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += e.stimp.startsWith('2') ? 1 : 0, 0);
                    this.pinSheetSummary.highStimpCount = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += e.stimp.startsWith('3') ? 1 : 0, 0);
                    this.pinSheetSummary.variance = todayPinSheet.pinSheetEntries.reduce((sum, e) => sum += +e.holeCenter ? 1 : 0, 0);

                    // Set selectedGreenId to previously selected or first in list.
                    const previouslySelectedGreen = this.greenImages.find(g => g.greenId == this.widgetConfig.selectedGreenId);
                    this.selectedGreenId = previouslySelectedGreen != null ? previouslySelectedGreen.greenId : this.greenImages[0]?.greenId;

                    this.getGreenData();
                },
                error: err => {
                    console.log();
                }
            })
    }

    private setGreensSelectList() {
        this.greensSelectList = [];
        this.greenImages?.forEach(g => {
            const name = `${this.translateService.instant('STRINGS.HOLE')} ${g.greenName}`;
            this.greensSelectList.push({ label: name, value: g.greenId })
        });
    }

    private getGreenData() {
        if (this.selectedGreenId == null) return;

        // Set the correct green image for the selected green.
        const greenData = this.greenImages.find(g => g.greenId === this.selectedGreenId);
        if (greenData != null) this.selectedGreen = greenData;
        this.setGreensNavigation();

        this.setPinInformation();
    }

    private setPinInformation() {
        this.desktopPinDetails = [];
        this.miniModalPinDetails = [];

        // Ratio of desired Mini Modal Image Size to the image size we fetched from the api (i.e., the scaling factor to avoid refetching the images).
        const ratio = this.MiniModalImageSize / this.ImageSize;

        // Get Pin details for yesterday, today and tomorrow.
        const yesterday = this.dailyPinSheets[0].pinSheetEntries.find(ps => ps.greenId === this.selectedGreenId)
        if (yesterday != null) {
            this.desktopPinDetails.push(<PinSheetEntry>{ ...yesterday, holeXPixels: yesterday.holeXPixels - this.holeXOffset, holeYPixels: yesterday.holeYPixels - this.holeYOffset});
            this.miniModalPinDetails.push(<PinSheetEntry>{ ...yesterday, holeXPixels: (yesterday.holeXPixels * ratio) - this.holeXOffset, holeYPixels: (yesterday.holeYPixels * ratio) - this.holeYOffset});
        } else {
            this.desktopPinDetails.push(null);
            this.miniModalPinDetails.push(null);
        }

        const today = this.dailyPinSheets[1].pinSheetEntries.find(ps => ps.greenId === this.selectedGreenId)
        if (today != null) {
            this.desktopPinDetails.push(<PinSheetEntry>{ ...today, holeXPixels: today.holeXPixels - this.holeXOffset, holeYPixels: today.holeYPixels - this.holeYOffset});
            this.miniModalPinDetails.push(<PinSheetEntry>{ ...today, holeXPixels: (today.holeXPixels * ratio) - this.holeXOffset, holeYPixels: (today.holeYPixels * ratio) - this.holeYOffset});
        } else {
            this.desktopPinDetails.push(null);
            this.miniModalPinDetails.push(null);
        }

        const tomorrow = this.dailyPinSheets[2].pinSheetEntries.find(ps => ps.greenId === this.selectedGreenId)
        if (tomorrow != null) {
            this.desktopPinDetails.push(<PinSheetEntry>{ ...tomorrow, holeXPixels: tomorrow.holeXPixels - this.holeXOffset, holeYPixels: tomorrow.holeYPixels - this.holeYOffset});
            this.miniModalPinDetails.push(<PinSheetEntry>{ ...tomorrow, holeXPixels: (tomorrow.holeXPixels * ratio) - this.holeXOffset, holeYPixels: (tomorrow.holeYPixels * ratio) - this.holeYOffset});
        } else {
            this.desktopPinDetails.push(null);
            this.miniModalPinDetails.push(null);
        }

        // Properly format Stimp values for PinSheet display.
        this.selectedPinStimpString = this.desktopPinDetails[1] && this.desktopPinDetails[1].stimp
            ? this.desktopPinDetails[1].stimp.replace(/\s/g, '')
            : '--';
    }

    private setGreensNavigation() {
        const currentGreenIndex = this.greenImages.indexOf(this.selectedGreen);

        this.isPrevButtonDisabled = false;
        this.isNextButtonDisabled = false;

        if (this.greenImages == null || this.greenImages.length < 2) {
            this.isPrevButtonDisabled = true;
            this.isNextButtonDisabled = true;
            return;
        }

        this.isPrevButtonDisabled = currentGreenIndex === 0;
        this.isNextButtonDisabled = currentGreenIndex === this.greenImages.length - 1;
    }

    private navigateToEzLocatorUrl(url: string): void {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_EZ_LOCATOR_SITE' });

        setTimeout(() => window.open(url, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    protected resetBusy() {
        this.isBusy = false;
        this.isRetrievingCourseData = false;
    }
}
