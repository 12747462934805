<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onGotoSite()"
    >

        <div class="toro-task-tracker-labor-widget-content mini-mode-widget-container">

            <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex;">
                <img style="width: 100%;" src="../../../../../../assets/images/task-tracker/turf-rad-mini-placeholder.png" alt="">
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            (gotoSite)="onGotoSite()"
    >

        <div class="toro-task-tracker-labor-widget-content" style="flex: 1; display: flex;">

            <div *ngIf="displayRows === 2 || isGridsterInMobileMode" class="toro-widget-content-pane ttl-size-two" style="flex: 1; position: relative;" [ngClass]="{'fade-on-resize': isResizing}">

                <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex;">
                    <img style="width: 100%;" src="../../../../../../assets/images/task-tracker/turf-rad-placeholder.png" alt="">
                </div>

            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="turf-rad-title-icon" src="../../../../../../assets/images/task-tracker/turf-rad-logo.png" alt="">
</ng-template>

<!--<ng-template #costByAreaChart>-->
<!--    <toro-task-tracker-labor-summary-chart [data]="dailySummary" [departmentIdsFilter]="selectedDepartmentIdsForChart" (chartClick)="onChartClick()"></toro-task-tracker-labor-summary-chart>-->
<!--</ng-template>-->

<!--<ng-template #costByAreaLegend>-->
<!--    <div class="ttl-chart-legend">-->
<!--        <div class="ttl-chart-legend-item">-->
<!--            <div class="ttl-chart-legend-color-swatch ttl-chart-legend-reg-pay"></div>-->
<!--            <span class="ttl-chart-legend-label">{{'STRINGS.REG_PAY' | translate}}</span>-->
<!--        </div>-->
<!--        <div class="ttl-chart-legend-item">-->
<!--            <div class="ttl-chart-legend-color-swatch ttl-chart-legend-ot-pay"></div>-->
<!--            <span class="ttl-chart-legend-label">{{'STRINGS.OVERTIME' | translate}}</span>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-template>-->

<!--<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="modalTitle" [isTitleCase]="false" (cancel)="toggleChartDisplay(false)">-->
<!--    <div id="task-tracker-chart-dlg-container">-->
<!--        <ng-container *ngIf="showChart" [ngTemplateOutlet]="costByAreaChart"></ng-container>-->
<!--    </div>-->
<!--    <ng-container [ngTemplateOutlet]="costByAreaLegend"></ng-container>-->
<!--</toro-weather-graph-chart-dlg>-->

<!--<toro-task-tracker-api-key-dialog-->
<!--        *ngIf="isApiKeyDialogDisplayed"-->
<!--        [apiKey]="newApiKey"-->
<!--        (setApiKey)="onSetApiKey($event)"-->
<!--        (cancelClick)="onCancelSetApiKey()"-->
<!--&gt;</toro-task-tracker-api-key-dialog>-->

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" title="Mini Mode Modal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
