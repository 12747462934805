import { PlaybooksMonthlyApplicationTotalCost } from './playbooks-monthly-application-total-cost.model';
import { PlaybooksMonthlyData } from './playbooks-monthly-data.model';
import { PlaybooksMonthlyTotal } from './playbooks-monthly-total.model';

export class PlaybooksMonthlyReportMonth {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.monthlyData) {
                this.monthlyData = json.monthlyData
                    .sort((a, b) => a.areaName.localeCompare(b.areaName))
                    .map((md) => new PlaybooksMonthlyData(md));
            }
            if (json.totals) { this.totals = json.totals.map(t => new PlaybooksMonthlyTotal(t)); }
            if (json.applicationTotalCost) { this.applicationTotalCost = new PlaybooksMonthlyApplicationTotalCost(json.applicationTotalCost); }
        }
    }

    month: number;
    monthlyData: PlaybooksMonthlyData[];
    totals: PlaybooksMonthlyTotal[];
    applicationTotalCost: PlaybooksMonthlyApplicationTotalCost;

}
