<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu pw-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onPerryWeatherLinkClick()"
    >

        <div class="toro-perry-weather-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div *ngIf="!hideLightingInfo" class="toro-mini-mode-content-panel left-panel">

                    <div id="mm-lightning-container" [ngClass]="language">
                        <div id="mm-lightning-lbl">{{'CASE_SENSITIVE.LIGHTNING_STATUS' | translate}}</div>
                        <div id="mm-lightning-status">
                            <div id="mm-lightning-ico-status">
                                <fa-icon *ngIf="!isLightningDelay" icon="check-circle"></fa-icon>
                                <img *ngIf="isLightningDelay" src="../../../../../../assets/images/perry-weather/in-delay.png" alt="Lightning Delay">
                                <span id="mm-lightning-status-lbl">{{lightningStatus}}</span>
                            </div>
                            <span id="mm-lightning-distance">{{lightningDistance}}</span>
                        </div>
                    </div>

                </div>

                <div class="toro-mini-mode-content-panel right-panel">
                    <ng-container [ngTemplateOutlet]="dataGridMini"></ng-container>
                </div>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [customToolbar]="!showMiniModeModal ? toggleGraphsBtn : null"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu pw-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onPerryWeatherLinkClick()"
    >

        <div class="toro-perry-weather-widget-content">
            <div *ngIf="displayCols === 1" class="toro-widget-content-pane widget-left-pane" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container *ngIf="!hideLightingInfo && (showCharts || showMiniModeModal)" [ngTemplateOutlet]="lightningPane"></ng-container>
                <ng-container [ngTemplateOutlet]="dataGrid"></ng-container>
                <ng-container *ngIf="showCharts || showMiniModeModal" [ngTemplateOutlet]="graphs"></ng-container>
            </div>

            <div *ngIf="displayCols === 2" class="toro-widget-content-pane widget-right-pane" [ngClass]="{'fade-on-resize': isResizing}">

                <div *ngIf="!showCharts" id="lightning-n-grid">
                    <div id="lng-left" *ngIf="location?.pwLink">
                        <ng-container [ngTemplateOutlet]="lightningPane"></ng-container>
                    </div>
                    <div id="lng-right">
                        <ng-container [ngTemplateOutlet]="dataGrid"></ng-container>
                    </div>
                </div>

                <div *ngIf="showCharts" id="lightning-grid-charts">
                    <ng-container *ngIf="!hideLightingInfo && showCharts" [ngTemplateOutlet]="lightningPane"></ng-container>
                    <ng-container [ngTemplateOutlet]="dataGrid"></ng-container>
                    <ng-container *ngIf="showCharts" [ngTemplateOutlet]="graphs"></ng-container>
                </div>

            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="pw-title-icon" src="../../../../../../assets/images/perryWeather-logo.png" alt="">
</ng-template>

<ng-template #lightningPane>
    <div id="pw-lightning-container" class="prevent-select">
        <div id="lightning-lbl">{{'CASE_SENSITIVE.LIGHTNING_STATUS' | translate}}</div>
        <div id="lightning-status">
            <fa-icon *ngIf="!isLightningDelay" icon="check-circle"></fa-icon>
            <img *ngIf="isLightningDelay" src="../../../../../../assets/images/perry-weather/in-delay.png" alt="Lightning Delay">
            <span id="lightning-status-lbl">{{lightningStatus}}</span>
            <div *ngIf="displayCols === 2 && showCharts" id="lightning-sep"></div>
            <span id="lightning-status-distance">{{lightningDistance}}</span>
        </div>
    </div>
</ng-template>

<ng-template #dataGrid>
    <div class="data-grid-container">
        <toro-pw-data-card
                *ngFor="let d of (!isGridsterInMobileMode || showMiniModeModal ? gridData : miniModeGridData)"
                [title]="d.title | translate | titlecase"
                [value]="d.value"
                [headerIcon1]="d.headerIcon1"
                [headerIcon2]="d.headerIcon2"
                [headerClass]="d.headerClass"
                [isShowingCharts]="showCharts"
        ></toro-pw-data-card>
    </div>
</ng-template>

<ng-template #dataGridMini>
    <div class="data-grid-container">
        <toro-pw-data-card
                *ngFor="let d of miniModeGridData"
                [title]="d.title | translate | titlecase"
                [value]="d.value"
                [headerIcon1]="d.headerIcon1"
                [headerIcon2]="d.headerIcon2"
                [headerClass]="d.headerClass"
                [isShowingCharts]="showCharts"
        ></toro-pw-data-card>
    </div>
</ng-template>

<ng-template #graphs>
    <div id="pw-graphs-container">
        <div *ngIf="displayCols === 1" id="pw-graphs-tabs" >
            <toro-perry-weather-tabbed-chart
                    [chartData]="chartData"
                    [title]="title"
                    [perryWeatherConfig]="perryWeatherConfig"
                    (configChange)="onConfigChange($event)"
            ></toro-perry-weather-tabbed-chart>
        </div>

        <div *ngIf="displayCols == 2" id="pw-dual-graphs">
            <div class="pw-dual-graph-container">
                <toro-perry-weather-tabbed-chart
                        [chartData]="chartData"
                        [title]="title"
                        [perryWeatherConfig]="perryWeatherConfig"
                        [type]="ChartType.Precip"
                        (configChange)="onConfigChange($event)"
                ></toro-perry-weather-tabbed-chart>
            </div>
            <div class="pw-dual-graph-container">
                <toro-perry-weather-tabbed-chart
                        [chartData]="chartData"
                        [title]="title"
                        [perryWeatherConfig]="perryWeatherConfig"
                        [type]="ChartType.Et"
                ></toro-perry-weather-tabbed-chart>
            </div>
        </div>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<ng-template #toggleGraphsBtn>
    <div class="toro-widget-toolbar-button" [pTooltip]="'CASE_SENSITIVE.SHOW_HIDE_GRAPHS' | translate" tooltipPosition="top" (click)="onToggleGraphs()">
        <fa-icon id="pw-chart-ico" icon="chart-line"></fa-icon>
    </div>
</ng-template>

<p-dialog [visible]="isIntegrationKeyDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.SET_INTEGRATION_KEY' | translate | titlecase}}</p-header>

    <div id="pw-key-dialog-container">
        <span>{{'CASE_SENSITIVE.INTEGRATION_KEY' | translate | titlecase}}</span>
        <p-inputMask
                [(ngModel)]="newIntegrationKey"
                mask="********-****-****-****-************"
                characterPattern="[a-fA-F]"
                [autoFocus]="true"
                [size]="40"
                [placeholder]="'CASE_SENSITIVE.ENTER_INTEGRATION_KEY' | translate | titlecase">
        </p-inputMask>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelSetIntegrationKey()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetIntegrationKey()"></p-button>
    </p-footer>
</p-dialog>
