import { duration } from 'moment';
import { GetCourseReportStation } from '../mqtt-get-course-report/get-course-report-station.model';

export class StationStatusStation extends GetCourseReportStation {

    constructor(json: any = null) {
        super(json)

        if (json) {
            this.name = json.name;
        }

        this.isRanUnder = this.totalDelta < duration('00:00:00');
    }

    name: string;
    isRanUnder = false;

}
