import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;
import SoilScoutThresholdTab = ToroEnums.SoilScoutThresholdTab;

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LocalCacheService } from '../../../../../common/services/local-cache.service';
import { SoilScoutGaugeRange } from '../models/soil-scout-gauge-range.model';
import { SoilScoutManagerService } from '../../../../../api/soil-scout/soil-scout-manager.service';
import { SoilScoutThresholdChange } from '../models/soil-scout-threshold-change.model';
import { SoilScoutWidgetBase } from '../_soil-scout-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-soil-scout-mobile',
    templateUrl: './widget-soil-scout-mobile.component.html',
    styleUrls: ['./widget-soil-scout-mobile.component.less']
})
export class WidgetSoilScoutMobileComponent extends SoilScoutWidgetBase implements OnInit {
    @ViewChild('gaugeContainer') gaugeContainer: ElementRef

    iconColor = 'black';
    title = 'WIDGET.SOIL_SCOUT';

    protected readonly SoilScoutWidgetBase = SoilScoutWidgetBase;
    protected readonly SoilScoutSensorType = SoilScoutSensorType;

    protected isUpdating = false;
    protected moistureValueColor = 'gray';
    protected temperatureValueColor = 'gray';
    protected salinityValueColor = 'gray';
    protected sensorAlertsCount = 0;
    protected thresholdTab = SoilScoutThresholdTab.Moisture;

    private moistureSensorGaugeRange: SoilScoutGaugeRange;
    private temperatureSensorGaugeRange: SoilScoutGaugeRange;
    private salinitySensorGaugeRange: SoilScoutGaugeRange;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected localCacheService: LocalCacheService,
                protected soilScoutManager: SoilScoutManagerService,
                protected translateService: TranslateService,
                protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, localCacheService, soilScoutManager, translateService, userFormatService);
    }

    ngOnInit(): void {
        this.sensorType = SoilScoutSensorType.Moisture
        super.ngOnInit();

        // Set Gauge Ranges
        this.moistureSensorGaugeRange = this.defaultMoistureRange;
        this.temperatureSensorGaugeRange = this.defaultTemperatureRange;
        this.salinitySensorGaugeRange = this.defaultSalinityRange;

        SoilScoutWidgetBase.commonDataUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (SoilScoutWidgetBase.userPreferences.ssMoistureRange != null) {
                    const range = SoilScoutWidgetBase.userPreferences.ssMoistureRange;
                    this.moistureSensorGaugeRange.rangeMin = range.rangeMin;
                    this.moistureSensorGaugeRange.rangeMax = range.rangeMax;
                    this.moistureSensorGaugeRange.range1UpperBoundary = range.range1UpperBoundary;
                    this.moistureSensorGaugeRange.range2UpperBoundary = range.range2UpperBoundary;
                    this.moistureSensorGaugeRange.range3UpperBoundary = range.range3UpperBoundary;
                }

                if (SoilScoutWidgetBase.userPreferences.ssSalinityRange != null) {
                    const range = SoilScoutWidgetBase.userPreferences.ssSalinityRange;
                    this.salinitySensorGaugeRange.rangeMin = range.rangeMin;
                    this.salinitySensorGaugeRange.rangeMax = range.rangeMax;
                    this.salinitySensorGaugeRange.range1UpperBoundary = range.range1UpperBoundary;
                    this.salinitySensorGaugeRange.range2UpperBoundary = range.range2UpperBoundary;
                    this.salinitySensorGaugeRange.range3UpperBoundary = range.range3UpperBoundary;
                }

                if (SoilScoutWidgetBase.userPreferences.ssTemperatureRange != null) {
                    const range = SoilScoutWidgetBase.userPreferences.ssTemperatureRange;
                    this.temperatureSensorGaugeRange.rangeMin = range.rangeMin;
                    this.temperatureSensorGaugeRange.rangeMax = range.rangeMax;
                    this.temperatureSensorGaugeRange.range1UpperBoundary = range.range1UpperBoundary;
                    this.temperatureSensorGaugeRange.range2UpperBoundary = range.range2UpperBoundary;
                    this.temperatureSensorGaugeRange.range3UpperBoundary = range.range3UpperBoundary;
                }

                this.setGaugeRange();

                this.moistureValueColor = this.getDataValueColor(SoilScoutSensorType.Moisture);
                this.temperatureValueColor = this.getDataValueColor(SoilScoutSensorType.Temperature);
                this.salinityValueColor = this.getDataValueColor(SoilScoutSensorType.Salinity);
            })

        SoilScoutWidgetBase.gaugeRangeUpdate
            .pipe(untilDestroyed(this))
            .subscribe((change: SoilScoutThresholdChange) => this.updateGaugeRanges(change));
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.SoilScoutMobileWidgetName;
    }

    protected get axisValueUnits(): string {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                return '%';
            case ToroEnums.SoilScoutSensorType.Temperature:
                return '°';
            default:
                return '';
        }
    }

    getDataValueColor(sensorType: SoilScoutSensorType): string {
        let gaugeValue: number;
        let gaugeRange: SoilScoutGaugeRange

        switch (sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                gaugeValue = SoilScoutWidgetBase.siteAverageMoisture * 100;
                gaugeRange = this.moistureSensorGaugeRange;
                break;
            case ToroEnums.SoilScoutSensorType.Temperature:
                gaugeValue = SoilScoutWidgetBase.siteAverageTemperature;
                gaugeRange = this.temperatureSensorGaugeRange;
                break;
            case ToroEnums.SoilScoutSensorType.Salinity:
                gaugeValue = SoilScoutWidgetBase.siteAverageSalinity;
                gaugeRange = this.salinitySensorGaugeRange;
                break;
        }

        if (gaugeValue < gaugeRange.range1UpperBoundary) {
            return gaugeRange.range1Color;
        } else if (gaugeValue < gaugeRange.range2UpperBoundary) {
            return gaugeRange.range2Color;
        } else if (gaugeValue < (gaugeRange.range3UpperBoundary ?? gaugeRange.rangeMax)) {
            return gaugeRange.range3Color
        } else {
            return gaugeRange.range4Color;
        }
    }

    get gaugeContainerWidth(): number {
        return this.gaugeContainer?.nativeElement?.offsetWidth;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSensorSelection(sensorType: SoilScoutSensorType) {
        if (this.sensorType == sensorType || sensorType == SoilScoutSensorType.WaterBalance) { return; }

        this.sensorType = sensorType;
        this.isUpdating = true;

        setTimeout(() => {

            this.clearGauge()

            setTimeout(() => {
                // this.sensorType = sensorType;
                this.setGaugeRange();
                this.setupGauge();
                this.setSensorAlertsCount();

                setTimeout(() => {
                    this.setGaugeValues();
                    this.isUpdating = false;
                });
            })

        }, 250)
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setGaugeRange() {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.gaugeRange = this.moistureSensorGaugeRange;
                this.thresholdTab = SoilScoutThresholdTab.Moisture;
                break;

            case ToroEnums.SoilScoutSensorType.Temperature:
                this.gaugeRange = this.temperatureSensorGaugeRange;
                this.thresholdTab = SoilScoutThresholdTab.Temperature;
                break;

            case ToroEnums.SoilScoutSensorType.Salinity:
                this.gaugeRange = this.salinitySensorGaugeRange;
                this.thresholdTab = SoilScoutThresholdTab.Salinity;
                break;

            default:
                this.sensorType = SoilScoutSensorType.Moisture;
                this.setGaugeRange();
                break;
        }
    }

    private setSensorAlertsCount() {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.sensorAlertsCount = SoilScoutWidgetBase.moistureSensorAlertsCount;
                break;
            case ToroEnums.SoilScoutSensorType.Temperature:
                this.sensorAlertsCount = SoilScoutWidgetBase.temperatureSensorAlertsCount;
                break;
            case ToroEnums.SoilScoutSensorType.Salinity:
                this.sensorAlertsCount = SoilScoutWidgetBase.salinitySensorAlertsCount;
                break;
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateGaugeRanges(change: SoilScoutThresholdChange) {
        if (change.moistureGaugeRange != null) { this.moistureSensorGaugeRange = new SoilScoutGaugeRange({ ...change.moistureGaugeRange }); }
        if (change.salinityGaugeRange != null) { this.salinitySensorGaugeRange = new SoilScoutGaugeRange({ ...change.salinityGaugeRange }); }
        if (change.temperatureGaugeRange != null) { this.temperatureSensorGaugeRange = new SoilScoutGaugeRange({ ...change.temperatureGaugeRange }); }

        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.gaugeRange = this.moistureSensorGaugeRange;
                break;
            case ToroEnums.SoilScoutSensorType.Salinity:
                this.gaugeRange = this.salinitySensorGaugeRange;
                break;
            case ToroEnums.SoilScoutSensorType.Temperature:
                this.gaugeRange = this.temperatureSensorGaugeRange;
                break;
        }

        this.isGaugeLoaded = false;
        this.isBusy = true;

        setTimeout(() => {
            this.setGaugeValues();

            setTimeout(() => {
                this.isGaugeLoaded = true;
                this.isBusy = false;
            }, 500);
        }, 500)

    }
}
