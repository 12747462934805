import { LynxPumpConfig } from './_lynx-pump-config.model';

export class LynxPressureConfig extends LynxPumpConfig{

    constructor(json: any = null) {
        super();

        if (json) {
            if (json.minimum && json.minimum > 0) this.minimum = json.minimum;
            if (json.maximum && json.maximum > 0) this.maximum = json.maximum;
            if (json.expected && json.expected > 0) this.expected = json.expected;
            if (json.minimumAlert && json.minimumAlert > 0) this.minimumAlert = json.minimumAlert;
            if (json.maximumAlert && json.maximumAlert > 0) this.maximumAlert = json.maximumAlert;
        }
    }

    static DEFAULT_MINIMUM = 0;
    static DEFAULT_MAXIMUM = 200;

    /**
     * The initial values set for the various properties of this object will be used as the default values.
     * If the API/Db contains a value for a specific property that is greater than zero, the API/Db value
     * will be used (overwriting the default value).
     */

    valueUnits = 'gpm';
    minimum = 0;
    maximum = 120;
    expected = 80;
    minimumAlert = 0;
    maximumAlert = 110;

}
