import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { IntegrationStep } from '../../models/integration-step.model';

@Component({
    selector: 'toro-integration-card',
    templateUrl: './integration-card.component.html',
    styleUrls: ['./integration-card.component.less']
})
export class IntegrationCardComponent implements OnInit {
    @Output() doSetup = new EventEmitter();

    @Input() integration: IntegrationStep;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService) { }

    ngOnInit(): void {
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSetup() {
        this.doSetup.emit();
    }

    onLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: '' });
        setTimeout(() => window.open(this.integration.linkUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

}
