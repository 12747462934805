import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import WidgetType = ToroEnums.WidgetType;

import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../common/services/analytics.service';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DashMessageService } from '../../common/services/dash-message.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { LoginStateChange } from '../../api/auth/models/login-state-change';
import { NotificationManagerService } from '../../api/notifications/notification-manager.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { ToroNotification } from '../../api/notifications/models/toro-notification.model';
import { WidgetSelectItemValue } from '../../api/widgets/models/widget-select-item-value.model';

declare var Beamer: any;

@UntilDestroy()
@Component({
    selector: 'toro-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.less']
})
export class AppHeaderComponent implements OnInit {
    @HostBinding('class') class = 'toro-flex-child';

    @ViewChild('op') overlayPanel: OverlayPanel;

    _widgetsLocked = false;

    siteName: string = null;
    widgetList: any[];
    selectedWidgets: any[];
    showPreferencesDialog = false;
    showNotificationPanel = false;
    showUserFeedbackDialog = false;
    showVideoPanel = false;
    notifications: ToroNotification[];
    unreadNotificationsCount = 0;
    isLoading = false;
    isAndroid = false;
    lockIconTooltip = 'STRINGS.WIDGETS_UNLOCKED';
    setupWizardIconTooltip = 'STRINGS.SETUP_WIZARD';
    lockIcon: IconProp = 'lock-open';
    showSetupWizard = false;
    isMobile = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private deviceDetector: DeviceDetectorService,
                private deviceManager: DeviceManagerService,
                private dashMessageService: DashMessageService,
                private localCacheService: LocalCacheService,
                private notificationManager: NotificationManagerService,
                private router: Router
    ) { }

    ngOnInit(): void {
        this.isAndroid = this.deviceDetector.getDeviceInfo().os.toLocaleLowerCase() === 'android';
        this.widgetsLocked = this.localCacheService.widgetsLocked;

        this.broadcastService.showWidgetSelector
            .subscribe((widgetList: SelectItem[]) => {
                setTimeout(() => {
                    // Use list order.
                    this.widgetList = widgetList;

                    // If Alphabetical order is desired.
                    // this.widgetList = widgetList.sort((a, b) => a.label.localeCompare(b.label));

                    // Select the currently displayed widgets in the multi-select input.
                    this.selectedWidgets = [];
                    if (this.widgetList && this.widgetList.length > 0) {
                        this.widgetList.filter(w => w.value.isSelected).forEach(w => this.selectedWidgets.push(w.value));
                    }
                });
            });

        // Occurs when user clicks on a widget close button.
        this.broadcastService.removeWidget
            .pipe(untilDestroyed(this))
            .subscribe((widgetInfo: { widgetId: number, widgetTypeId: WidgetType }) => {
                this.selectedWidgets = this.selectedWidgets.filter(w => w.type !== widgetInfo.widgetTypeId);
            });

        this.authManager.dashLoginStateChange
            .pipe(untilDestroyed(this))
            .subscribe((state: LoginStateChange) => {
                if (state.isLoggedIn) this.siteName = this.authManager.dashAuthenticatedUser.siteName;
            });

        if (this.authManager.dashAuthenticatedUser) {
            this.siteName = this.authManager.dashAuthenticatedUser.siteName;
        }

        this.notificationManager.notificationsListChange
            .pipe(untilDestroyed(this))
            .subscribe((notifications: ToroNotification[]) => {
                // Sort the list in descending chronological order.
                this.notifications = notifications.sort((a, b) => b.triggeredTimeForCourse.getTime() - a.triggeredTimeForCourse.getTime());
                this.unreadNotificationsCount = this.notifications.filter(n => n.read === false).length;
            });

        this.broadcastService.widgetSelectionChange
            .pipe(untilDestroyed(this))
            .subscribe((selectedWidgets: WidgetSelectItemValue[]) => {
                this.selectedWidgets = selectedWidgets;
                this.widgetList.forEach(item => {
                    const widget = this.selectedWidgets.find(w => w.type === item.value.type);
                    item.value.isSelected = widget != null;
                })
            });

        this.broadcastService.showSetupWizard
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onShowSetupWizard());

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isMobile = this.deviceManager.isMobile;
            });

        this.isMobile = this.deviceManager.isMobile;

        this.getNotifications(true);

        if (this.localCacheService.wizardActive && this.deviceDetector.isDesktop()) {
            this.showSetupWizard = true;
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onLogoClick() {
        this.router.navigate(['/']);
    }

    onWidgetSelectionChange() {
        this.broadcastService.widgetSelectionChange.next(this.selectedWidgets.slice());
    }

    logOut() {
        this.authManager.startOauthSignOut();
    }

    onFeedbackClick() {
        this.analyticsService.event(AnalyticsEvent.FeedbackButtonClicked, AnalyticsCategory.Feedback);
        this.showUserFeedbackDialog = true;
    }

    onShowUserPreferences() {
        this.overlayPanel.hide();

        // Place in setTimeout to give the overlay panel a chance to close.
        setTimeout(() => {
            this.showPreferencesDialog = true;
            this.analyticsService.event(AnalyticsEvent.PreferencesDialogOpened, AnalyticsCategory.Preferences);
        }, 250);
    }

    onToggleNotificationPanel() {
        this.analyticsService.event(
            this.showNotificationPanel ? AnalyticsEvent.NotificationPanelClosed : AnalyticsEvent.NotificationPanelOpened, AnalyticsCategory.Notifications);

        if (!this.showNotificationPanel) {
            this.overlayPanel.hide();

            // Place in setTimeout to give the overlay panel a chance to close.
            setTimeout(() => this.showNotificationPanel = !this.showNotificationPanel);
            return;
        }

        this.showNotificationPanel = !this.showNotificationPanel;
    }

    onShowAnnouncements() {
        if (typeof(Beamer) !== 'undefined') {
            this.overlayPanel.hide();
            Beamer.show();

            // // Update language of Beamer Notifications
            // setTimeout(() => {
            //     Beamer.update({
            //         product_id: "BdzEgfNP57917",
            //         selector: "toro-announce-btn",
            //         callback: (newPosts) => {
            //             console.log();
            //         }
            //     });
            //     Beamer.init();
            // }, 3000)
        }
    }

    toggleWidgetDrag() {
        this.widgetsLocked = !this.widgetsLocked;
    }

    onShowSetupWizard() {
        this.showSetupWizard = !this.showSetupWizard;
    }

    onCloseSetupWizard() {
        this.showSetupWizard = false;
    }

    onToggleVideoPanel() {
        this.showVideoPanel = !this.showVideoPanel;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    set widgetsLocked(isLocked: boolean) {
        this.localCacheService.widgetsLocked = isLocked;
        this.broadcastService.toggleWidgetDrag.next({ isLocked: isLocked });

        this.lockIconTooltip = isLocked ? 'STRINGS.WIDGETS_LOCKED' : 'STRINGS.WIDGETS_UNLOCKED'
        this.lockIcon = isLocked ? 'lock' : 'lock-open';

        const event = isLocked ? AnalyticsEvent.DashboardLocked : AnalyticsEvent.DashboardUnlocked;
        this.analyticsService.event(event, AnalyticsCategory.Interaction);
    }

    get widgetsLocked(): boolean {
        return this.localCacheService.widgetsLocked;
    }

    getNotifications(bypassCache = false) {
        this.isLoading = true;

        this.notificationManager.getAllNotifications(bypassCache)
            .pipe(
                take(1),
                finalize(() => this.isLoading = false)
            )
            .subscribe((notifications: ToroNotification[]) => {
                // We handle the result of this call in the notificationsListChange event handler subscribed to in ngOnInit (above).
            }, error => {
                this.dashMessageService.showGenericFetchErrorMessage();
            });
    }

}
