import * as moment from 'moment';

export class TimeStampedCollection<T> {

    constructor(timeStamp: string, items: T) {
        this.lastUpdated = moment.utc(timeStamp).toDate();
        this.items = items;
    }

    items: T;
    lastUpdated: Date;
}
