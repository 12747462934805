<div class="wiz-integration-content">
    <div id="left-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.CONNECT_MY_TURF_TO_APP' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step2"></ng-container>
        </toro-step-group>
    </div>
    <div id="right-content" class="wiz-integration-group">
        <img src="assets/images/wizard/myTurfLogin.png" alt="">
    </div>
</div>

<ng-template #step1>
    <div>
        {{'STRINGS.SIGN_INTO' | translate}} <a href="javascript:void(0);" (click)="onNavigateToSite()">myTurf.toro.com</a>.
    </div>
</ng-template>

<ng-template #step2>
        {{'SPECIAL_MSG.ONCE_SIGNED_IN_AUTO_REDIRECT_TO_APP' | translate}}.
</ng-template>
