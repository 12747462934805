import WidgetType = ToroEnums.WidgetType;

import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { GridsterComponent, GridsterConfig, GridsterItemComponentInterface } from 'angular-gridster2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DashboardWidgetInfo } from '../../api/widgets/models/dashboard-widget-info.model';
import { DashMessageService } from '../../common/services/dash-message.service';
import { DashUserManagerService } from '../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { SelectItem } from 'primeng/api';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from './widgets/toro-gridster-widget';
import { ToroUtils } from '../../common/utils/_toro.utils';
import { WidgetManagerService } from '../../api/widgets/widget-manager.service';


@UntilDestroy()
@Component({
    selector: 'toro-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
    @HostBinding('class') class = 'toro-main-content-child';

    @ViewChild('theGrid') theGrid: GridsterComponent;

    // Constants
    ToroWidgetType = ToroEnums.WidgetType;

    private readonly DEFAULT_GRIDSTER_ROW_HEIGHT_DESKTOP = 330;
    private readonly DEFAULT_GRIDSTER_ROW_HEIGHT_MOBILE = 165;
    private readonly ANDROID_DYNAMIC_FOOTER_PAD = 2;

    gridsterOptions: GridsterConfig;
    items: ToroGridsterWidget[] = [];
    activeItem: ToroGridsterWidget;
    isResizing = false;
    hideGrid = false;
    widgetReorgTimer;
    showSystemWaitingIndicator = false;
    systemWaitingIndicatorText = '';
    isDashboardInitialized = false;
    isWidgetsDraggable = true;
    isGridResizing = false;
    doWindowResizeTimer: NodeJS.Timeout;
    debugMessages = [];
    showDebugPanel = false;

    private gridHasVertScrollbar = false;
    private prevScrollTop = 0;
    private isShowingFooter = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                private dashUserManager: DashUserManagerService,
                private deviceManager: DeviceManagerService,
                private localCacheService: LocalCacheService,
                public widgetManager: WidgetManagerService,
    ) {
    }

    ngOnInit(): void {
        this.configureGridster();

        this.widgetManager.widgetCollectionChange
            .pipe(untilDestroyed(this))
            .subscribe((change: { addedWidgetTypes: WidgetType[], removedWidgetTypes: WidgetType[] }) => {
                // Remove any widgets that have been, well, removed.
                change.removedWidgetTypes.forEach(type => {
                    const itemIndex = this.items.findIndex(item => item.type === type);
                    this.items.splice(itemIndex, 1);
                });

                // Add any new widgets
                change.addedWidgetTypes.forEach(type => this.addWidget(type));

                setTimeout(() => this.checkForGridScrollbar(), 1000);
            });

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if ((!this.widgetManager.isMobileGridDisplay && window.innerWidth <= this.deviceManager.WIDTH_TO_FORCE_GRID_TO_REDRAW)
                    || (window.innerWidth > this.deviceManager.WIDTH_TO_FORCE_GRID_TO_REDRAW && this.widgetManager.isMobileGridDisplay)) {
                    this.isGridResizing = true;
                    this.items = [];
                }

                if (this.doWindowResizeTimer) { clearTimeout(this.doWindowResizeTimer); }
                this.doWindowResizeTimer = setTimeout(() => this.checkGridSize(), 500);
            });

        this.broadcastService.toggleSystemOverlay
            .pipe(untilDestroyed(this))
            .subscribe((params: { show: boolean, text: string }) => {
                this.showSystemWaitingIndicator = params.show;
                this.systemWaitingIndicatorText = params.show && params.text ? `${ToroUtils.Translate.instant(params.text)}...` : '';
            });

        this.broadcastService.changeWidgetConstraint
            .pipe(untilDestroyed(this))
            .subscribe(({ id, maxRows, maxCols }) => {
                // Dynamically update widget size constraints.
                const widget = this.items.find(w => w.id === id);

                if (widget) {
                    const item = <GridsterItemComponentInterface>this.theGrid.findItemWithItem(widget);
                    if (!item) { return; }

                    if (maxCols != null) { item.item.maxItemCols = maxCols; }
                    if (maxRows != null) { item.item.maxItemRows = maxRows; }
                    item.updateOptions();
                }
            });

        this.broadcastService.toggleWidgetDrag
            .pipe(untilDestroyed(this))
            .subscribe((state: { isLocked: boolean }) => {
                this.toggleDraggable(this.isWidgetsDraggable = !state.isLocked);
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.getComponentData(true));

        // Get the lock state of the widget dashboard.
        setTimeout(() => this.toggleDraggable(!this.localCacheService.widgetsLocked), 500);

        // // Set the isMobileGridDisplay semaphore if the page is loaded at or below our mobile threshold.
        if (window.innerWidth <= this.deviceManager.WIDTH_TO_FORCE_GRID_TO_REDRAW) this.widgetManager.isMobileGridDisplay = true;

        this.toggleMobileDrag(this.widgetManager.isMobileGridDisplay);
        this.showMobileDebugDisplay(this.widgetManager.isMobileGridDisplay);
    }

    onGridScroll(e: any) {
        this.toggleMobileFooter(e.target);
    }

    toggleMobileFooter(el: HTMLElement) {
        // if (!this.deviceManager.isGridsterInMobileMode || !this.gridHasVertScrollbar) {
        if (!this.gridHasVertScrollbar) {
            this.logDebugMessage('No Vert Scrollbar');
            this.isShowingFooter = true;
        } else {
            // If the footer is already showing, and we've scrolled farther down the page, no need to reevaluate.
            if (this.isShowingFooter && this.prevScrollTop < el.scrollTop) {
                this.logDebugMessage('Footer already visible.');
                return;
            }

            this.logDebugMessage('Checking conditions for mobile footer:');
            this.isShowingFooter = el.scrollTop + el.clientHeight + this.ANDROID_DYNAMIC_FOOTER_PAD >= el.scrollHeight;
            this.logDebugMessage(`scrollTop: ${el.scrollTop}, clientHeight: ${el.clientHeight}, scrollHeight: ${el.scrollHeight}`);
            this.logDebugMessage(`show mobile footer: ${this.isShowingFooter}`);
            this.prevScrollTop = el.scrollTop;
        }

        this.broadcastService.showFooter.next(this.isShowingFooter);
    }

    private logDebugMessage(msg: string) {
        if (!this.showDebugPanel) { return; }

        this.debugMessages.push(msg);

        const el = document.getElementById('debug-panel');
        if (el == null) { return; }

        setTimeout(() => el.scrollTop = el.scrollHeight);
    }

    // =========================================================================================================================================================
    // Gridster Event Handlers
    // =========================================================================================================================================================

    // Occurs when dashboard widgets are moved or resized.
    itemChange(item: ToroGridsterWidget, itemComponent) {
        if (this.isGridResizing) { return; }

        this.items = this.items.sort((item1, item2) => item1.y !== item2.y ? item1.y - item2.y : item1.x - item2.x);
        this.activeItem = <ToroGridsterWidget>item;
        if (item.resizeCallback != null) item.resizeCallback(item);

        if (this.widgetReorgTimer) clearTimeout(this.widgetReorgTimer);

        this.widgetReorgTimer = setTimeout(() => {
            const widgets = this.items.map(widget => {
                return new DashboardWidgetInfo({ widgetType: widget.type, x: widget.x, y: widget.y, width: widget.cols, height: widget.rows });
            });
            this.widgetManager.saveDashboardWidgets(widgets, this.widgetManager.isMobileGridDisplay ? 'mobile' : 'desktop')
                .subscribe({
                    next: () => this.checkForGridScrollbar(),
                    error: () => this.dashMessageService.showMessage('ERR_MSG.GENERIC_RETRIEVE_ERROR_SUMMARY', 'ERR_MSG.FAILED_TO_SAVE_WIDGET_LAYOUT')
                });
        }, 200);
    }

    itemResize(item, itemComponent) {
        if (item.resizeCallback != null) item.resizeCallback(item);
    }

    itemResizeStart(item, gridsterItem, event) {
        this.isResizing = true;
        if (item.resizeStartCallback != null) item.resizeStartCallback(item);
    }

    itemResizeStop(item, gridsterItem, event) {
        this.isResizing = false;
        if (item.resizeStopCallback != null) item.resizeStopCallback(item);
    }

    itemValidate(item) {
        if (!this.isResizing && this.activeItem && this.activeItem.isSquare) { return item.rows === item.cols; }

        return true;
    }

    changedOptions() {
        if (!this.gridsterOptions.api) { return; }

        this.gridsterOptions.api.optionsChanged();
    }

    toggleDraggable(isDraggable: boolean) {
        this.gridsterOptions.draggable.enabled = isDraggable;
        this.changedOptions();
    }

    // =========================================================================================================================================================
    // Toolbar Event Handlers
    // =========================================================================================================================================================

    getComponentData(isLanguageChange = false) {
        if (!isLanguageChange) this.items = [];

        const dashUser = this.authManager.dashAuthenticatedUser;
        const sources: Observable<any>[] = [
            this.widgetManager.getDashboardWidgets(dashUser.sid, dashUser.siteId, (this.widgetManager.isMobileGridDisplay ? 'mobile' : 'desktop')).pipe(take(1)),
            this.widgetManager.getDashboardWidgets(dashUser.sid, dashUser.siteId, (!this.widgetManager.isMobileGridDisplay ? 'mobile' : 'desktop')).pipe(take(1)),
            this.widgetManager.getDashboardWidgets(dashUser.sid, dashUser.siteId).pipe(take(1)),
            of(this.widgetManager.getWidgetSelectList())
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                this.isDashboardInitialized = true;
                this.isGridResizing = false;
                setTimeout(() => this.checkForGridScrollbar(), 1000);

                if (!this.deviceManager.isMobile && this.isDashboardInitialized && (!this.items || this.items?.length < 1) && !this.isGridResizing) {
                    if (!this.localCacheService.wizardActive) this.broadcastService.showSetupWizard.next(null);
                }
            }))
            .subscribe({
                next: ([activeWidgets, otherDeviceWidgets, originalWidgets, widgetsList]) => {
                    // activeWidgets represents the widgets selected for the requested device form factor. otherDeviceWidgets represents the opposite
                    // selection (i.e., desktop versus mobile). The purpose of this logic is to default to a previously selected set of widgets for a secondary
                    // form factor that does not already have a widget select list.

                    // If we don't have widgets for the current deviceId (i.e., desktop or mobile)...
                    if (activeWidgets.length === 0) {
                        // If we're on the desktop...
                        if (!this.widgetManager.isMobileGridDisplay) {
                            // Set to the set of widgets w/o a deviceId. These would have been the widgets the user configured prior to us tracking deviceId.
                            activeWidgets = originalWidgets;
                        } else {
                            // If we're mobile, set to 'desktop' widgets if they exist or original widgets if they don't.

                            // filter out widgets that are meant only for desktop.
                            otherDeviceWidgets = otherDeviceWidgets.filter(w => ![WidgetType.SoilScoutMoisture, WidgetType.SoilScoutTemperature, WidgetType.SoilScoutSalinity].includes(w.widgetType))

                            activeWidgets = otherDeviceWidgets.length === 0 ? originalWidgets : otherDeviceWidgets;

                            // Save the set of mobile widgets.
                            this.widgetManager.saveDashboardWidgets(activeWidgets, 'mobile').subscribe();
                        }
                    }

                    if (activeWidgets) {
                        activeWidgets.forEach(widget => {
                            if (!isLanguageChange) this.addWidget(widget.widgetType, widget);

                            // Set the isSelected value property for any displayed. This will result in the widget being selected in the multi-select input.
                            const listItem = (<SelectItem[]>widgetsList).find(item => item.value.type === widget.widgetType);
                            if (listItem) listItem.value.isSelected = true;
                        });
                    }

                    this.broadcastService.showWidgetSelector.next(widgetsList);
                },
                error: err => {
                    this.dashMessageService.showMessage('ERR_MSG.GENERIC_RETRIEVE_ERROR_SUMMARY', 'ERR_MSG.FAILED_TO_RETRIEVE_WIDGET_DATA');
                }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private toggleMobileDrag(isMobile) {
        this.isGridResizing = true;
        this.items = [];

        this.gridsterOptions.disableScrollHorizontal = isMobile;
        this.gridsterOptions.displayGrid = isMobile ? 'none' : 'onDrag&Resize';
        this.gridsterOptions.maxCols = isMobile ? 1 : 100;
        this.gridsterOptions.pushItems = !isMobile;
        this.gridsterOptions.swap = isMobile;
        this.gridsterOptions.compactType = isMobile ? 'compactUp' : 'none';
        this.gridsterOptions.fixedRowHeight = isMobile ? this.DEFAULT_GRIDSTER_ROW_HEIGHT_MOBILE : this.DEFAULT_GRIDSTER_ROW_HEIGHT_DESKTOP;

        setTimeout(() => {
            if (this.gridsterOptions.api) { this.gridsterOptions.api.optionsChanged(); }
            this.getComponentData();
        }, 100)
    }

    private checkGridSize() {
        this.checkForGridScrollbar();

        // When manually resizing the browser, Gridster will not automatically layout the widgets at the specified mobile
        // breakpoint. To account for this we will force a redraw of the grid when the browser is resized at or below our threshold.
        // NOTE: Gridster does handle the mobile layout properly on initial load, page refresh and on a real mobile device.
        if (!this.widgetManager.isMobileGridDisplay && window.innerWidth <= this.deviceManager.WIDTH_TO_FORCE_GRID_TO_REDRAW) {
            this.widgetManager.isMobileGridDisplay = true;
            this.prevScrollTop = 0;
            this.toggleMobileDrag(true);

            this.hideGrid = true;
            setTimeout(() => this.hideGrid = false);
        } else if (window.innerWidth > this.deviceManager.WIDTH_TO_FORCE_GRID_TO_REDRAW && this.widgetManager.isMobileGridDisplay) {
            this.widgetManager.isMobileGridDisplay = false;
            this.toggleMobileDrag(false);
        }

        this.showMobileDebugDisplay(this.widgetManager.isMobileGridDisplay);
    }

    private showMobileDebugDisplay(show: boolean) {
        return;
        // this.showDebugPanel = show;
    }

    private checkForGridScrollbar() {
        this.logDebugMessage('check for scrollbar');
        const theGrid = document.getElementById('the-grid');
        this.gridHasVertScrollbar = theGrid != null && (theGrid.scrollHeight > theGrid.clientHeight);
        this.logDebugMessage(`gridsterHasVertScrollbar: ${this.gridHasVertScrollbar}`);
        this.toggleMobileFooter(theGrid);
    }

    private addWidget(widgetType: ToroEnums.WidgetType, dashboardWidgetInfo = null) {
        this.items.push(this.widgetManager.makeWidget(widgetType, dashboardWidgetInfo));
    }

    private configureGridster() {
        this.gridsterOptions = {
            disableWarnings: true,
            itemChangeCallback: this.itemChange.bind(this),
            itemResizeCallback: this.itemResize,
            itemValidateCallback: this.itemValidate.bind(this),
            fixedColWidth: 350,     // 400,
            fixedRowHeight: this.DEFAULT_GRIDSTER_ROW_HEIGHT_DESKTOP,
            keepFixedHeightInMobile: true,
            margin: 12,
            gridType: 'fixed',
            outerMargin: false,
            draggable: {
                enabled: true,
                ignoreContentClass: 'widget-non-draggable'
            },
            resizable: {
                enabled: true,
                start: this.itemResizeStart.bind(this),
                stop: this.itemResizeStop.bind(this),
            },
            setGridSize: false,
            pushItems: true,
            mobileBreakpoint: this.deviceManager.GRIDSTER_MOBILE_BREAKPOINT
        };
    }

}
