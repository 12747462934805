import { ToroEnums } from '../../../../../../common/enumerations/toro.enums';
import LynxStationStatus = ToroEnums.LynxStationStatus;

export class LynxStationStatusModel {

    constructor(area: string, hole: string, station: string, status: LynxStationStatus) {
        this.area = area;
        this.hole = hole;
        this.station = station?.replace('Station', '').trim();
        this.status = status;
    }

    area = '--';
    hole = '--';
    station = '--';
    status: LynxStationStatus = LynxStationStatus.Unknown;
}
