import { GenericMqttMessage } from '../generic-mqtt-message.model';
import { GetStationsContent } from './get-stations-content.model';

export class GetStationsMessage extends GenericMqttMessage {

    constructor(json: any = null) {
        super(null);

        if (json) {
            Object.assign(this, json);

            if (json.content) this.content = new GetStationsContent(json.content);
        }
    }

    content: GetStationsContent;

}
