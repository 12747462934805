export class PlaybooksIngredient {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    monthlyTotal: number;
    ytd: number;

}
