/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { MyTurfManagerService } from '../../../../api/my-turf/my-turf-manager.service';
import { take } from 'rxjs/operators';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Directive()
export abstract class MyTurfWidgetBase extends ToroDashboardWidget implements OnInit {

    isMyTurfUserLoggedIn = true;
    myTurfUserName: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected myTurfManager: MyTurfManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.language = this.dashUserManager.language;

        this.broadcastService.myTurfUserSignedIn
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isMyTurfUserLoggedIn = true;
                this.setMyTurfUserName();
                this.getWidgetData(false);
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);

        this.setMyTurfUserName();
    }

    // =========================================================================================================================================================
    // Protected Members
    // =========================================================================================================================================================

    protected setMyTurfUserName() {
        this.myTurfManager.getMyTurfUserName()
            .pipe(take(1))
            .subscribe(myTurfUserName => this.myTurfUserName = myTurfUserName);
    }

}
