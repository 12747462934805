import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxPumpStation } from '../../../../../api/lynx/models/lynx-pump-station.model';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@Component({
    selector: 'toro-widget-lynx-pump-stations',
    templateUrl: './widget-lynx-pump-stations.component.html',
    styleUrls: ['./widget-lynx-pump-stations.component.less']
})
export class WidgetLynxPumpStationsComponent extends LynxWidgetBase implements OnInit {
    private readonly baseTitle = 'WIDGET.LYNX_PUMP_STATIONS';

    iconColor = 'goldenrod';
    title = this.baseTitle;

    private readonly NO_VALUE = '--';

    pumpStations: LynxPumpStation[];

    // Mini-mode Vars
    pressureUnits: string;
    flowUnits: string;
    pressure: string[] = [];
    flow: string[] = [];
    isMetric = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isBusy = true;

        if (this.isGridsterInMobileMode) {
            this.broadcastService.userPreferencesChange
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.setMiniModeComponentData();
                });
        }

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LynxPumpStationsWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.lynxManager.getPumps()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((pumps: LynxPumpStation[]) => {
                this.clearIsUnableToFetchData();

                // TODO: TEST
                // pumps.push(...pumps);
                // pumps.splice(3, 1);

                // Add an index property to the collection elements to be used for converted units array lookup by UI.
                this.pumpStations = pumps.map((p, index) => { return {...p, index}});
                this.hasNoData = pumps.length < 1;

                // Set max width (rows) of widget based on number of lynx pump stations.
                this.setWidgetConstraints(this.pumpStations.length);

                // Include count of stations in widget title.
                if (this.pumpStations.length > 2) { this.title = `${this.translateService.instant(this.baseTitle)} (${this.pumpStations.length})`; }

                // Get the time stamp from the first station if we can.
                if (pumps.length > 0) {
                    this.lastUpdateTimestamp = pumps[0].lastUpdated;
                }

                if (this.isGridsterInMobileMode) {
                    this.setMiniModeComponentData();
                }

            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setWidgetConstraints(stationsCount: number) {
        let maxRows = 1;

        if (stationsCount >= 8) {
            maxRows = 4;
        } else if (stationsCount >= 5) {
            maxRows = 3;
        } else if (stationsCount >= 3) {
            maxRows = 2;
        }

        this.broadcastService.changeWidgetConstraint.next({ id: this.associatedWidget.id, maxRows, maxCols: null });
    }

    setMiniModeComponentData() {
        this.isMetric = this.userFormatService.isMetric;

        // Get units
        this.pressureUnits = this.userFormatService.pressureUnits;
        this.flowUnits = this.userFormatService.flowUnits;

        this.pressure = [];
        this.flow = [];

        // Get Values
        this.pumpStations.forEach(ps => {
            this.pressure.push(ps.pressure ? <string>this.userFormatService.pressure(ps.pressure, true) : this.NO_VALUE);
            this.flow.push(ps.flow ? <string>this.userFormatService.flow(ps.flow, true) : this.NO_VALUE);
        })
    }

}
