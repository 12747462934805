import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { LynxPumpStation } from '../../../../../../api/lynx/models/lynx-pump-station.model';
import { ToroAnalyticsEnums } from '../../../../../../common/enumerations/analytics.enums';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@UntilDestroy()
@Component({
    selector: 'toro-lynx-pump-station-card',
    templateUrl: './lynx-pump-station-card.component.html',
    styleUrls: ['./lynx-pump-station-card.component.less']
})
export class LynxPumpStationCardComponent implements OnInit {
    private readonly NO_VALUE = '--';

    @Input() pumpStation: LynxPumpStation;
    @Input() language: string;

    pressureUnits: string;
    flowUnits: string;
    pressure: string;
    flowCapacity: string;
    pressureSetPoint: string;
    flow: string;
    pressureValueRangeClass = '';
    flowValueRangeClass = '';
    showInfoDialog = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private broadcastService: BroadcastService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.setComponentData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });

    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowInfoDialog() {
        this.showInfoDialog = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.LynxPumpStationsWidgetSettingsDisplayed, AnalyticsCategory.Interaction, AnalyticsEvent.LynxPumpStationsWidgetName);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    setComponentData() {
        // Get units
        this.pressureUnits = this.userFormatService.pressureUnits;
        this.flowUnits = this.userFormatService.flowUnits;

        // Get Values
        this.pressure = this.pumpStation.pressure ? <string>this.userFormatService.pressure(this.pumpStation.pressure, true) : this.NO_VALUE;
        this.flow = this.pumpStation.flow ? <string>this.userFormatService.flow(this.pumpStation.flow, true) : this.NO_VALUE;

        // Get set point and capacity
        this.pressureSetPoint = this.pumpStation.pressureConfig.expected
            ? <string>this.userFormatService.pressure(this.pumpStation.pressureConfig.expected, true) : this.NO_VALUE;

        this.flowCapacity = this.pumpStation.flowConfig.maximum
            ? <string>this.userFormatService.flow(this.pumpStation.flowConfig.maximum, true) : this.NO_VALUE;
    }

}
