export class GddGraphData {

    constructor(gdd0c?: number, gdd10c?: number, gdd32?: number, gdd50?: number) {
        this.gdd0c = gdd0c;
        this.gdd10c = gdd10c;
        this.gdd32 = gdd32;
        this.gdd50 = gdd50;
    }

    gdd0c: number;
    gdd10c: number;
    gdd32: number;
    gdd50: number;

    get gridValues(): number[] {
        return [ this.gdd0c, this.gdd10c, this.gdd32, this.gdd50 ];
    }

    get gridLabels(): string[] {
        return [ '0', '10', '32', '50' ];
    }
}
