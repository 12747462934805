import { ApiService, HttpMethod } from '../_common/api.service';
import { ClubListEntry } from './models/club-list-entry.model';
import { CourseListEntry } from './models/course-list-entry.model';
import { DailyPinSheet } from './models/daily-pin-sheet.model';
import { DatePipe } from '@angular/common';
import { EzLocatorAccessId } from './models/ez-locator-access-id.model';
import { GreenImageEntry } from './models/green-image-entry.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EzLocatorApiService {

    // TODO: TEMPORARY
    // private static readonly apiUrl = "https://v3.ezlocator-online.com/webservice/mobile.asmx";
    // private static readonly apiKey = "74A11900-BDEC-40FC-9EB5-4E8A8A8F3FD7"
    // private readonly imageSize = 200;

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private datePipe: DatePipe,
                http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getAccessId(userName: string): Observable<EzLocatorAccessId> {
        return this.apiService.apiRequest<any>(this.getAccessIdUrl(userName), HttpMethod.Get)
            .pipe(map((accessId: EzLocatorAccessId) => new EzLocatorAccessId(accessId)))
    }

    getPinSheetData(courseAccessId: string, imageSize: number): Observable<DailyPinSheet[]> {
        return this.apiService.apiRequest<any>(this.getPinSheetDataUrl(courseAccessId, imageSize), HttpMethod.Get)
            .pipe(map((sheets: DailyPinSheet[]) => sheets.map(s => new DailyPinSheet(s))));
    }

    getGreenImages(courseAccessId: string, imageSize: number): Observable<GreenImageEntry[]> {
        return this.apiService.apiRequest<any>(this.getGreenImagesUrl(courseAccessId, imageSize), HttpMethod.Get)
            .pipe(map((images: GreenImageEntry[]) => images.map(s => new GreenImageEntry(s))));
    }

    getClubList(): Observable<ClubListEntry[]> {
        return this.apiService.apiRequest<any>(this.clubListUrl, HttpMethod.Get)
            .pipe(map((clubs: ClubListEntry[]) => clubs.map(c => new ClubListEntry(c))));
    }

    getCourseList(clientAccessId: string): Observable<CourseListEntry[]> {
        return this.apiService.apiRequest<any>(this.getCourseListUrl(clientAccessId), HttpMethod.Get)
            .pipe(map((courses: CourseListEntry[]) => courses.map(c => new CourseListEntry(c))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}ezLocator`; }

    private getAccessIdUrl(userName: string) { return `${EzLocatorApiService.baseUrl}/accessId?userName=${userName}`; }

    private getPinSheetDataUrl(courseAccessId: string, imageSize: number): string {
        const today = new Date();
        const yesterday = new Date(today);
        const tomorrow = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        tomorrow.setDate(today.getDate() + 1);

        let params = `courseAccessId=${courseAccessId}&startDate=${this.getDateString(yesterday)}&endDate=${this.getDateString(tomorrow)}&imageSize=${imageSize}`
        return `${EzLocatorApiService.baseUrl}/PinSheetData?${params}`;
    }

    private getGreenImagesUrl(courseAccessId: string, imageSize: number): string {
        return `${EzLocatorApiService.baseUrl}/GreenImages?courseAccessId=${courseAccessId}&imageSize=${imageSize}`;
    }

    private get clubListUrl(): string { return `${EzLocatorApiService.baseUrl}/ClubList`; }

    private getCourseListUrl(clientAccessId: string): string { return `${EzLocatorApiService.baseUrl}/CourseList?clientAccessId=${clientAccessId}`; }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getDateString(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
}
