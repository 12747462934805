export class SoilScoutConstants {

    static readonly map_marker_hydra = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0z" fill="currentColor"/></svg>'

    static readonly map_marker_base_alt = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16.125c-3.383 0-6.125-2.742-6.125-6.125s2.742-6.125 6.125-6.125 6.125 2.742 6.125 6.125-2.742 6.125-6.125 6.125zM12.125 10c0-2.14 1.735-3.875 3.875-3.875s3.875 1.735 3.875 3.875c0 2.14-1.735 3.875-3.875 3.875s-3.875-1.735-3.875-3.875z" fill="currentColor"></path></svg>'

    static readonly map_marker_echo = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.335 263.335"><path d="M40.479,159.021c21.032,39.992,49.879,74.22,85.732,101.756c0.656,0.747,1.473,1.382,2.394,1.839
			c0.913,0.457,1.907,0.682,2.905,0.709c0.126,0,0.255,0.01,0.378,0.01c1.123,0,2.261-0.262,3.311-0.761
			c0.838-0.396,1.57-0.962,2.178-1.647c80.218-61.433,95.861-125.824,96.44-128.34c2.366-9.017,3.57-18.055,3.57-26.864
			C237.389,47.429,189.957,0,131.665,0C73.369,0,25.946,47.424,25.946,105.723c0,8.636,1.148,17.469,3.412,26.28
			c0.058,0.598,0.187,1.195,0.392,1.773C30.42,135.625,36.366,152.071,40.479,159.021z M131.014,89.313
			c-14.594,0-27.708,8.114-34.226,21.184c-1.764,3.542-6.079,4.975-9.617,3.211c-3.54-1.762-4.98-6.072-3.211-9.614
			c8.961-17.959,26.987-29.118,47.053-29.118c20.323,0,39.024,11.898,47.645,30.317c1.685,3.584,0.126,7.857-3.459,9.53
			c-0.979,0.462-2.016,0.677-3.033,0.677c-2.693,0-5.273-1.524-6.492-4.128C159.406,97.966,145.797,89.313,131.014,89.313z
			 M152.723,131.611c0,12.013-9.73,21.749-21.746,21.749c-12.011,0-21.744-9.736-21.744-21.749c0-12.008,9.733-21.744,21.744-21.744
			C142.992,109.867,152.723,119.603,152.723,131.611z M130.886,52.814c-23.69,0-44.986,13.175-55.576,34.385
			c-1.769,3.547-6.074,4.98-9.617,3.211c-3.54-1.766-4.979-6.072-3.211-9.614c13.033-26.103,39.241-42.318,68.398-42.318
			c29.543,0,56.724,17.296,69.245,44.069c1.681,3.584,0.126,7.854-3.458,9.528c-0.98,0.464-2.016,0.679-3.033,0.679
			c-2.698,0-5.274-1.524-6.497-4.128C176.973,66.87,154.889,52.814,130.886,52.814z" fill="currentColor"/></svg>`

    static readonly map_marker_base = `<svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	                                x="0px" y="0px" viewBox="0 0 384 512" style="enable-background:new 0 0 384 512;" xml:space="preserve">
                                    <style type="text/css">
	                                    .st0{fill:currentColor;stroke:#000000;stroke-miterlimit:10;}
	                                    .st1{fill:#888;stroke:#000000;stroke-miterlimit:10;}
                                    </style>
                                    <path class="st0" d="M172.3,501.7C27,291,0,269.4,0,192C0,86,86,0,192,0s192,86,192,192c0,77.4-27,99-172.3,309.7
	                                    C202.2,515.4,181.8,515.4,172.3,501.7L172.3,501.7z M192,272c44.2,0,80-35.8,80-80s-35.8-80-80-80s-80,35.8-80,80S147.8,272,192,272z"
                                    />
                                    <circle class="st1" cx="192.5" cy="191.5" r="77.5"/>
                                </svg>`

}

