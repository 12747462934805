<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
    >

        <div class="toro-flex-child mini-mode-widget-container">
            <div style="flex: 1;" (click)="showMiniModeModal = true">
                <p-editor [(ngModel)]="notesText" [style]="{'height': '120px'}" [readonly]="true" class="is-mini-mode">
                </p-editor>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
    >

        <div id="id-notes-container" class="toro-flex-child">
                <p-editor
                        [(ngModel)]="notesText"
                        [style]="{'height': !isGridsterInMobileMode ? '527px' : '503px'}"
                        [placeholder]="'SPECIAL_MSG.NOTES_PLACEHOLDER_TEXT' | translate"
                        [ngClass]="{'is-mobile': isGridsterInMobileMode}"
                        (onTextChange)="onTextChange($event)"
                >
                    <p-header>
                        <span class="ql-formats">
                            <select class="ql-size">
                                <option value="small"></option>
                                <option selected></option>
                                <option value="large"></option>
                                <option value="huge"></option>
                            </select>
                            <select class="ql-font">
                                <option selected></option>
                                <option value="serif"></option>
                                <option value="monospace"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-color"></select>
                            <select class="ql-background"></select>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <select class="ql-align">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-link"></button>
                            <button class="ql-image"></button>
                            <button class="ql-code-block"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-clean"></button>
                        </span>
                    </p-header>
                </p-editor>

            <toro-waiting-indicator *ngIf="isSavingData"></toro-waiting-indicator>
        </div>

        <div id="id-note-bottom-tb">
            <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
            <p-button type="button" [label]="'STRINGS.SAVE' | translate" [disabled]="isBusy || !isDirty" (onClick)="onSave()"></p-button>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon [icon]="['far', 'list-alt']" ></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
