export class PinSheetEntry {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.pinSheetDate) this.pinSheetDate = new Date(json.pinSheetDate);
            if (json.stimp) this.setStimpBackgroundColor();
        }
    }

    greenId: number;
    greenName: string;
    greenDepth: number;
    holeId: number;
    holeFront: string;
    holeSide: string;
    holeCenter: string;
    holeXPixels: number;
    holeYPixels: number;
    stimp: string;
    pinSheetDate: Date;

    stimpBackgroundColor: string;

    private setStimpBackgroundColor() {
        if (this.stimp == null) return;

        switch (this.stimp) {
            case '0 - 2':
                this.stimpBackgroundColor = '#056739';
                break;
            case '2 - 2.5':
                this.stimpBackgroundColor = '#38b549';
                break;
            case '2.5 - 3':
                this.stimpBackgroundColor = '#8dc53f';
                break;
            case '3 - 3.5':
                this.stimpBackgroundColor = '#fcb040';
                break;
            case '3.5 - 4':
                this.stimpBackgroundColor = '#f15929';
                break;
            case '>4':
                this.stimpBackgroundColor = '#ed1d24';
                break;
            default:
                this.stimpBackgroundColor = 'white';
                break;
        }
    }

}
