import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { CachedCollection } from '../_common/cached-collection';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { TaskTrackerApiService } from './task-tracker-api.service';
import { TaskTrackerCompany } from './models/task-tracker-company.model';
import { TaskTrackerConfig } from './models/task-tracker-config.model';
import { TaskTrackerDailySummary } from './models/task-tracker-daily-summary.model';
import { TaskTrackerMeasurementSummary } from './models/task-tracker-measurement-summary.model';
import { TaskTrackerSafety } from './models/task-tracker-safety.model';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class TaskTrackerManagerService {
    private readonly cacheDurationInMinutes = 60;

    companyIdChange = new EventEmitter<string>();

    // Cache Containers
    private _dailySummaryDict: {[companyId: number]: TaskTrackerDailySummary} = {};
    private _measurementSummaryDict: {[companyId: number]: CachedCollection<TaskTrackerMeasurementSummary>} = {};
    private _safetiesDict: {[companyId: number]: CachedCollection<TaskTrackerSafety>} = {};
    private _activeCompanies: CachedCollection<TaskTrackerCompany>;

    private _getCompaniesObservable: Observable<TaskTrackerCompany[]>;
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private taskTrackerApiService: TaskTrackerApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getTaskTrackerConfig(toroGridsterWidget: ToroGridsterWidget): Observable<TaskTrackerConfig> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.taskTrackerConfig); }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('isTaskTrackerConfig')) {
            return of(<TaskTrackerConfig>toroGridsterWidget.config);
        }

        const config = new TaskTrackerConfig(toroGridsterWidget.config);
        return of(config);
    }

    getActiveCompanies(bypassCache = false): Observable<TaskTrackerCompany[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.taskTrackerActiveCompanies); }

        if (!bypassCache && this._activeCompanies && !this._activeCompanies.isExpired) {
            return of(this._activeCompanies.collection.slice());
        }

        if (this._getCompaniesObservable == null) {
            this._getCompaniesObservable = this.taskTrackerApiService.getActiveCompanies()
                .pipe(tap(result => this._activeCompanies = new CachedCollection(result)));
        }

        return this._getCompaniesObservable;
    }

    getDailySummary(companyId: number, bypassCache = false): Observable<TaskTrackerDailySummary> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.taskTrackerDailySummary); }

        if (!bypassCache && this._dailySummaryDict && this._dailySummaryDict[companyId] && !this._dailySummaryDict[companyId].isExpired) {
            return of(this._dailySummaryDict[companyId]);
        }

        return this.taskTrackerApiService.getDailySummary(companyId)
            .pipe(tap((response: TaskTrackerDailySummary) => this._dailySummaryDict[companyId] = response));
    }

    getMeasurementSummary(companyId: number, bypassCache = false): Observable<TaskTrackerMeasurementSummary[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.taskTrackerMeasurementSummary); }

        if (!bypassCache && this._measurementSummaryDict && this._measurementSummaryDict[companyId] && !this._measurementSummaryDict[companyId].isExpired) {
            return of(this._measurementSummaryDict[companyId].collection);
        }

        return this.taskTrackerApiService.getMeasurementSummary(companyId)
            .pipe(tap((response: TaskTrackerMeasurementSummary[]) =>
                this._measurementSummaryDict[companyId] = new CachedCollection<TaskTrackerMeasurementSummary>(response, this.cacheDurationInMinutes)));
    }

    getSafeties(companyId: number, bypassCache = false): Observable<TaskTrackerSafety[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.taskTrackerSafeties); }

        if (!bypassCache && this._safetiesDict && this._safetiesDict[companyId] && !this._safetiesDict[companyId].isExpired) {
            return of(this._safetiesDict[companyId].collection);
        }

        return this.taskTrackerApiService.getSafeties(companyId)
            .pipe(tap((response: TaskTrackerSafety[]) =>
                this._safetiesDict[companyId] = new CachedCollection<TaskTrackerSafety>(response, this.cacheDurationInMinutes)));
    }

}
