import PogoTopNav = ToroEnums.PogoTopNav;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import PogoMoistureRating = ToroEnums.PogoMoistureRating;

import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { PogoManagerService } from '../../../../../api/pogo/pogo-manager.service';
import { PogoProperty } from '../../../../../api/pogo/models/pogo-property.model';
import { PogoZone } from '../../../../../api/pogo/models/pogo-zone.model';
import { PogoZoneCardComponent } from '../pogo-zone-card/pogo-zone-card.component';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-pogo',
    templateUrl: './widget-pogo.component.html',
    styleUrls: ['./widget-pogo.component.less']
})
export class WidgetPogoComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('greenImage') greenImage: ElementRef;
    @ViewChild('zoneCardList') zoneCardList: ElementRef;
    @ViewChildren('zoneCard') zoneCards: QueryList<PogoZoneCardComponent>;

    iconColor = 'black';
    title = 'WIDGET.POGO';

    // TODO: Temporary
    // private readonly pogoPropertyKey = 'thisismykey';

    PogoTopNav = PogoTopNav;
    PogoMoistureRating = PogoMoistureRating;

    private dashUserPrefs: DashUserPreferences;

    selectedZone: PogoZone;
    property: PogoProperty;
    zonesCriticallyLow: PogoZone[];
    zonesLow: PogoZone[];
    zonesGood: PogoZone[];
    zonesHigh: PogoZone[];
    zonesCriticallyHigh: PogoZone[];
    selectedZones: PogoZone[];
    topNavSelection = PogoTopNav.Moisture;
    zoneRatingSelection = PogoMoistureRating.CriticallyLow;
    imageLoadTmrRef: any;
    imageUnavailable = false;
    hasMinLocation = false;
    hasMaxLocation = false;
    hasApproachMarker = false;
    hasPinMarker = false;
    showGreenImage = false;
    pogoApiKey: string;
    newApiKey: string;
    isApiKeyDialogDisplayed = false;
    moistureSummaryIcon: string;
    zoneMoistureAvg: string;
    zoneMoistureDu: string;
    zoneMoistureMin: string;
    zoneMoistureMax: string;
    showDesktopModal = false;

    // Used for development
    // holeMarkerLeft: string;
    // holeMarkerTop: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private pogoManager: PogoManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setZoneSummaryInformation());
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.PogoWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        if (isManualRefresh) { this.isBusy = true; }

        // Try to get Pogo API Key (stored in user preferences)
        this.dashUserManager.getDashUserInfo()
            .pipe(take(1))
            .subscribe({
                next: (dashUserInfo: DashUserInfo) => {
                    this.dashUserPrefs = dashUserInfo.preferences;
                    if (this.dashUserPrefs.pogoApiKey != null || environment.isDemoMode) {
                        this.pogoApiKey = this.dashUserPrefs.pogoApiKey;
                        this.getPogoProperty(isManualRefresh);
                        return;
                    }

                    if (this.pogoApiKey == null) {
                        this.isBusy = false;
                        this.setIsUnableToFetchData('STRINGS.PROVIDE_POGO_API_KEY');
                        return;
                    }

                },
                error: () => {
                    this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);
                }
            });
    }

    private getPogoProperty(isManualRefresh) {
        if (isManualRefresh) { this.isBusy = true; }

        this.pogoManager.getProperty(this.pogoApiKey)
            .pipe(finalize(() => {
                this.isBusy = false;
                this.lastUpdateTimestamp = new Date();
            }))
            .subscribe({
                next: (property: PogoProperty) => {
                    if (property == null) {
                        this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.title, 'No Pogo Property was returned for the provided API Key.');
                        return;
                    }

                    this.property = property;

                    this.zonesCriticallyLow = property.zones.filter(z => z.conditions?.moistureRating == PogoMoistureRating.CriticallyLow) || [];
                    this.zonesLow = property.zones.filter(z => z.conditions?.moistureRating == PogoMoistureRating.Low);
                    this.zonesGood = property.zones.filter(z => z.conditions?.moistureRating == PogoMoistureRating.Good);
                    this.zonesHigh = property.zones.filter(z => z.conditions?.moistureRating == PogoMoistureRating.High);
                    this.zonesCriticallyHigh = property.zones.filter(z => z.conditions?.moistureRating == PogoMoistureRating.CriticallyHigh);

                    // Set initial selection
                    if (this.isBusy) { this.setInitialZones(); }

                    this.clearIsUnableToFetchData();
                    this.setDisplayValues();
                },
                error: err => {
                    this.dashMessageService.showGenericFetchErrorMessage();
                    this.unableToFetchDataReason = err?.error?.message?.key == 'property for key not found' ? 'STRINGS.PROPERTY_FOR_KEY_NOT_FOUND' : null;
                    this.isUnableToFetchData = true;
                }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.SET_POGO_API_KEY'),
                icon: 'pi pi-fw pi-sign-in',
                command: this.showApiKeyDialog.bind(this)
            },
            { separator: true }
        );
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        // Reselect existing zone to force reload of green image. This is necessary when a user selects a new zone on the 1x1 widget
        // and then expands to the 2x1 widget (because the green <img> was hidden.
        if (this.property != null && this.displayCols == 2) {
            this.onZoneSelectionChange(this.selectedZone, true);
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onPogoLinkClick() {
        const pogoSiteLink = this.property?.property_link ? this.property.property_link : environment.pogoSiteUrl;

        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_POGO' });
        setTimeout(() => window.open(pogoSiteLink, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onTopNavClick(selection: PogoTopNav) {
        this.topNavSelection = selection;
    }

    onZoneNavCick(selection: PogoMoistureRating) {
        this.zoneRatingSelection = selection;
        this.setDisplayValues();
    }

    onZoneSelectionChange(selectedZone: PogoZone, forceSelection = false) {
        if (!forceSelection && this.selectedZone == selectedZone) {
            if (this.isGridsterInMobileMode) { this.showDesktopModal = true; }
            return;
        }

        this.resetMarkers();
        this.selectedZone = selectedZone
        this.setZoneSummaryInformation();

        // Bring the selected zone into view if necessary. Can happen when selection is changed from desktop modal.
        setTimeout(() => {
            const childArray = this.zoneCards.toArray();
            const zoneIndex = this.selectedZones.indexOf(selectedZone);
            childArray[zoneIndex].getNativeElement().scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        })

        clearTimeout(this.imageLoadTmrRef);
        this.imageLoadTmrRef = setTimeout(() => {
            this.imageUnavailable = true;
        }, 2000)

        if (this.isGridsterInMobileMode) { this.showDesktopModal = true; }
    }

    onSetApiKey() {
        this.isApiKeyDialogDisplayed = false;

        // Ignore changes to the account id when in demo mode.
        if (environment.isDemoMode) { return; }

        // If the Course Id was not changed, don't do anything.
        if (this.pogoApiKey === this.newApiKey || this.newApiKey == null) { return; }

        this.pogoApiKey = this.newApiKey;
        this.isBusy = true;
        // this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_ACCOUNT_ID')}...`;

        // Store the new Account Id in the user preferences.
        this.dashUserPrefs.pogoApiKey = this.newApiKey;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

        // this.assetTrackingData = null;
        this.getPogoProperty(true);

        const eventDetails = `API Key: ${this.newApiKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PogoWidgetSavedApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onCancelApiKeyDialog() {
        this.isApiKeyDialogDisplayed = false
        this.analyticsService.widgetEvent(AnalyticsEvent.PogoWidgetCancelApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onShowDesktopModal(topNav: PogoTopNav) {
        if (topNav != null) {
            this.topNavSelection = topNav;
        }

        this.showDesktopModal = true;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        this.selectedZones = [];

        switch (this.zoneRatingSelection) {
            case PogoMoistureRating.CriticallyLow:
                this.selectedZones = this.zonesCriticallyLow
                break;
            case PogoMoistureRating.Low:
                this.selectedZones = this.zonesLow
                break;
            case PogoMoistureRating.Good:
                this.selectedZones = this.zonesGood
                break;
            case PogoMoistureRating.High:
                this.selectedZones = this.zonesHigh
                break;
            case PogoMoistureRating.CriticallyHigh:
                this.selectedZones = this.zonesCriticallyHigh
                break;
        }

        this.selectedZone = this.selectedZones.length > 0 ? this.selectedZones[0] : null;

        this.setZoneSummaryInformation();
    }

    private setZoneSummaryInformation() {
        if (this.selectedZone == null) return;

        this.zoneMoistureAvg = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_avg, 1, 1) || '--';
        this.zoneMoistureDu = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_du, 1, 1) || '--';
        this.zoneMoistureMin = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_min, 1, 1) || '--';
        this.zoneMoistureMax = this.userFormatService.toUserNumber(this.selectedZone?.conditions?.moisture_max, 1, 1) || '--';

        this.setSummaryIcon();
    }

    private setInitialZones() {
        if (this.zonesCriticallyLow.length > 0) {
            this.zoneRatingSelection = PogoMoistureRating.CriticallyLow;
            return;
        }

        if (this.zonesLow.length > 0) {
            this.zoneRatingSelection = PogoMoistureRating.Low;
            return;
        }

        if (this.zonesGood.length > 0) {
            this.zoneRatingSelection = PogoMoistureRating.Good;
            return;
        }

        if (this.zonesHigh.length > 0) {
            this.zoneRatingSelection = PogoMoistureRating.High;
            return;
        }

        if (this.zonesCriticallyHigh.length > 0) {
            this.zoneRatingSelection = PogoMoistureRating.CriticallyHigh;
            return;
        }

        this.zoneRatingSelection = PogoMoistureRating.CriticallyLow;
    }

    private resetMarkers() {
        this.imageUnavailable = false;
        this.showGreenImage = false;
        this.hasApproachMarker = false;
        this.hasPinMarker = false;
        this.hasMinLocation = false;
        this.hasMaxLocation = false;
    }

    private showApiKeyDialog() {
        this.newApiKey = this.pogoApiKey;
        this.isApiKeyDialogDisplayed = true;

        const eventDetails = `API Key: ${this.pogoApiKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PogoWidgetShowApkKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    private setSummaryIcon() {
        switch (this.selectedZone?.conditions?.moistureRating) {
            case PogoMoistureRating.CriticallyLow:
                this.moistureSummaryIcon = 'mdi mdi-arrow-down-thin';
                break;
            case PogoMoistureRating.Low:
                this.moistureSummaryIcon = 'mdi mdi-arrow-bottom-left-thin';
                break;
            case PogoMoistureRating.Good:
                this.moistureSummaryIcon = 'mdi mdi-minus';
                break;
            case PogoMoistureRating.High:
                this.moistureSummaryIcon = 'mdi mdi-arrow-top-right-thin';
                break;
            case PogoMoistureRating.CriticallyHigh:
                this.moistureSummaryIcon = 'mdi mdi-arrow-up-thin';
                break;
        }
    }
}
