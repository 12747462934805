import { SpecFsCollection } from './spec-fs-collection.model';

export class SpecFieldScoutDataResponse {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.collections != null) this.collections = json.collections.map(c => new SpecFsCollection(c));
        }
    }

    collections: SpecFsCollection[] = [];
}
