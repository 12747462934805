export class SoilScoutLoginResponse {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    access: string;
    refresh: string;

}
