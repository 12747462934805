<div class="toro-course-trakk-datum-container toro-flex-child">

    <div class="course-trakk-datum-value">
        {{ value != null ? value : '--' | translate }}
    </div>

    <div class="course-trakk-datum-label">
        {{ label != null ? label : '--' | translate }}
    </div>

</div>
