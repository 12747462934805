import WeatherGraphType = ToroEnums.WeatherGraphType;

import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { NutrientGraphData } from '../../models/nutrient-graph-data.model';
import { ToroEnums } from '../../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../../common/services/user-format.service';


@UntilDestroy()
@Component({
    selector: 'toro-playbooks-nutrient-chart',
    templateUrl: './playbooks-nutrient-chart.component.html',
    styleUrls: ['./playbooks-nutrient-chart.component.less']
})
export class PlaybooksNutrientChartComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'toro-weather-graph-chart';
    @ViewChild('wgChartContainer') wgChartContainer: ElementRef;

    @Output() chartLoaded = new EventEmitter();
    @Output() chartClick = new EventEmitter();

    @Input() graphType: WeatherGraphType;

    private _data: NutrientGraphData;
    @Input() set data(value: NutrientGraphData) {
        if (value == null) { return; }
        this._data = value;
        this.updateChart();
    }

    get data(): NutrientGraphData {
        return this._data;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;

    private months: string[];
    private readonly dataSeriesNames = ['N', 'P', 'K', 'Fe', 'Mn'];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                // Slight delay added to allow Highcharts to render properly w/o throwing error.
                setTimeout(() => this.updateChart(), 250);
            });
    }

    ngOnInit(): void {
        this.months = this.translateService.instant('CALENDAR.MONTH_NAMES_SHORT');
    }

    ngOnDestroy() {
        this.chart = null;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateChart() {
        this.months = this.translateService.instant('CALENDAR.MONTH_NAMES_SHORT');

        if (!this.chart) {
            setTimeout(() => this.setupChart());
        } else {
            this.chart.update({
                chart: {
                    type: 'spline',
                },
                series: this.sensorSeriesData,
            });
        }
    }

    private setSizeOfChartContainer() {
        if (!this.wgChartContainer) { return; }
        this.chartContainerWidth = this.wgChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.wgChartContainer.nativeElement.offsetHeight - 2;
    }

    private setupChart() {
        const self = this;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) {self.chart = event.target; },
                    click() { self.chartClick.emit(); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [8, 5, 0, 5],
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                tickInterval: 1,
                tickLength: 7,
                tickWidth: 1,
                // categories: self.sensorSeriesCategories,
                // labels: {
                //     useHTML: true,
                //     formatter() { return '<span class="weather-graph-x-axis-label">' + this.value + '</span>'; }
                // }
                labels: { formatter() { return this.value + 1; } },
                // labels: { formatter() { return self.getMonthPrefix(this.value); } },
                // title: { text: 'Month' },
            },
            yAxis: {
                title: { enabled: false },
                // labels: {
                //     // formatter() { return '<span class="weather-graph-y-axis-label">' + self.userFormatService.toUserNumber(this.value) + '</span>'; }
                //     formatter() { return this.value.toFixed(2); }
                // },
                allowDecimals: true,
            },
            tooltip: {
                enabled: true,
                headerFormat: '',
                useHTML: true,
                pointFormatter() {
                    return '<div style="font-size: 10px; margin-bottom: 4px;">' + self.months[this.x] + '</div>' +
                        '<div style="font-size: 13px;"></div><span>' + self.getDataSeriesName(this.series.name) + '</span>' +
                        '<span  style="padding-left: 10px;">' + self.userFormatService.toUserNumber(this.y) + '</span></div>';
                },
                shared: false,
            },
            plotOptions: {
                spline: {
                    cursor: 'pointer',
                    marker: {
                        enabled: true,
                        // states: { hover: { enabled: false, } },
                        radius: 2,
                        symbol: 'circle'
                    },
                },
                series: {
                    enableMouseTracking: true,
                    stickyTracking: false,
                    states: { inactive: { enabled: false } },
                    events: {
                        click() { self.chartClick.emit(); }
                    },
                },
            },
            series: self.sensorSeriesData,
        };
    }

    get sensorSeriesData(): any[] {
        const dataSeries = [];

        dataSeries.push(this.getDataSeries(this.data.N, ToroEnums.PlaybooksNutrientColor.N));
        dataSeries.push(this.getDataSeries(this.data.P, ToroEnums.PlaybooksNutrientColor.P));
        dataSeries.push(this.getDataSeries(this.data.K, ToroEnums.PlaybooksNutrientColor.K));
        dataSeries.push(this.getDataSeries(this.data.Fe, ToroEnums.PlaybooksNutrientColor.Fe));
        dataSeries.push(this.getDataSeries(this.data.Mn, ToroEnums.PlaybooksNutrientColor.Mn));

        return dataSeries;
    }

    private getDataSeries(data: number[], seriesColor: string) {
        return {
            type: 'spline',
            marker: { enabled: false },
            color: seriesColor,
            data
        };
    }

    private getDataSeriesName(seriesName: string) {
        const index = +seriesName.split(' ')[1];
        return this.dataSeriesNames[index - 1];
    }

}
