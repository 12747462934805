
export class NsnStatus {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.expirationDate) this.expirationDate = new Date(json.expirationDate);
        }
    }

    status: string;
    expirationDate: Date;
}
