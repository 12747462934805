export class SoilScoutGaugeRangeConfig {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.range1Color) delete this['range1Color'];
            if (json.range2Color) delete this['range2Color'];
            if (json.range3Color) delete this['range3Color'];
            if (json.range4Color) delete this['range4Color'];
        }
    }

    rangeMin = 0;
    rangeMax = 100;
    range1UpperBoundary: number;
    range2UpperBoundary: number;
    range3UpperBoundary: number;
}
