export class SpecRangeCount {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    count: number;
    color: string;

}
