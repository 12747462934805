<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-lynx-running-stations-widget-content toro-flex-child tmm-carousel-host">

            <div class="tmm-carousel-container" [ngClass]="{'single-item': lynxCourses?.length <= 1}">
                <p-carousel [value]="lynxCourses" [numVisible]="1" [numScroll]="1" contentClass="toro-carousel-content" dotsContainerClass="toro-dots-container">
                    <ng-template let-course pTemplate="item">

                        <div class="toro-widget-content-pane toro-component-shield-host">
                            <div class="toro-mini-mode-content-panel host-content-panel">

                                <div class="toro-widget-content-pane toro-component-shield-host">
                                    <div class="toro-mini-mode-content-panel">
                                        <div id="tmm-lrs-course-info" class="toro-mini-mode-content">
                                            <i class="tmm-ul-icon toro-id-stations"></i>
                                            <div class="tmm-bot-left-container">
                                                <div class="tmm-ellipsis-text">{{course.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tmm-lrs-dm-value-container" class="toro-mini-mode-content-panel ">
                                        <div id="tmm-lrs-station-count-container" class="toro-mini-mode-content">
                                            <div class="tmm-large-value-label">{{'STRINGS.ACTIVE' | translate | titlecase}}</div>
                                            <div class="tmm-large-value">{{stationsCount}}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                        </div>

                    </ng-template>
                </p-carousel>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-lynx-running-stations-widget-content">
            <ng-container [ngTemplateOutlet]="courseName"></ng-container>

            <div *ngIf="displayCols === 1" class="toro-widget-content-pane toro-size-one toro-component-shield-host" [ngClass]="{'fade-on-resize': isResizing, 'no-click': !stationsCount}">
                <div id="toro-lrs-content-container">
                    <div id="toro-lrs-icon-container" [ngClass]="{'lrs-no-stations': !stationsCount}">
                        <fa-icon *ngIf="stationsCount" icon="tint"></fa-icon>
                        <toro-no-stations-running *ngIf="!stationsCount"></toro-no-stations-running>
                    </div>
                    <div id="toro-lrs-area-summary-container">
                        <div *ngIf="stationsCount" id="toro-lrs-area-count">{{stationsCount}}</div>
                        <div id="toro-lrs-area-list">
                            <div *ngFor="let item of runningStationsSummaries?.slice(0,maxAreasToDisplay)" class="toro-lrs-area-list-item">{{ item.areaName }}</div>
                            <div *ngIf="runningStationsSummaries?.length > maxAreasToDisplay" class="toro-lrs-area-list-item">...</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="stationsCount" class="toro-widget-link"><a (click)="onViewAll()">{{ 'STRINGS.VIEW_STATIONS' | translate | titlecase }}</a></div>
                <toro-component-shield></toro-component-shield>
            </div>

            <div *ngIf="displayCols === 2" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container [ngTemplateOutlet]="courseName"></ng-container>

                <div id="toro-lrs-area-list-container">
                    <div id="toro-lrs-area-details-list" class="toro-component-shield-host" [ngClass]="{'lrs-justify-list': runningStationsSummaries?.length <= areaCountThresholdForListJustification }">
                        <div *ngFor="let item of runningStationsSummaries" class="toro-lrs-area-details-list-item">
                            <div class="area-details-list-item-icon-container">
                                <div class="area-details-icon-count" [ngClass]="{'double-digits': item.count >= 10}">{{ item.count }}</div>
                                <div class="area-details-icon-icon">
                                    <fa-icon icon="tint"></fa-icon>
                                </div>
                            </div>
                            <div class="area-details-list-item-name">{{ item.areaName }}</div>
                        </div>

                        <toro-no-stations-running *ngIf="!stationsCount"></toro-no-stations-running>
                        <toro-component-shield></toro-component-shield>
                    </div>
                </div>
                <div *ngIf="stationsCount" id="toro-lrs-details-toolbar"><a (click)="onViewAll()">{{ 'STRINGS.VIEW_ALL' | translate }}</a></div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #courseName>
    <div class="tlrs-course-selector-container" *ngIf="lynxCourses && lynxCourses.length > 0" [ngClass]="{'fade-on-resize': isResizing}">
        <span id="tlrs-course-label">{{'STRINGS.COURSE' | translate}}: </span>
        <p-dropdown
                *ngIf="lynxCourses.length > 1"
                [options]="lynxCoursesList"
                [disabled]="lynxCourses.length < 2"
                [tooltip]="'Select Course'"
                [(ngModel)]="selectedCourseId"
                (onChange)="onCourseChange($event)"
        ></p-dropdown>
        <span *ngIf="lynxCourses.length === 1">{{lynxCourses[0].name}}</span>
    </div>
</ng-template>

<!-- Dialogs -->
<toro-running-stations-details-dlg
        *ngIf="showDetailsDialog"
        [runningStationsDetails]="runningStationsDetails"
        (closeDialog)="showDetailsDialog = false"
        (dataRefresh)="onDataRefresh()">
</toro-running-stations-details-dlg>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
