<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            (gotoSite)="onGotoSite()"
    >

        <div class="toro-flex-child mini-mode-widget-container">
            <ng-container *ngIf="clientAccessId == null" [ngTemplateOutlet]="accountLinkingInfo"></ng-container>

            <div *ngIf="clientAccessId != null" class="toro-widget-content-pane toro-component-shield-host">
                <div id="tid-ez-mm-content" class="toro-mini-mode-content-panel tid-flex-col">
                    <div id="tid-ez-mm-course">
                        {{ selectedCourseEntry?.courseName }}
                    </div>
                    <div id="tid-ez-mm-values">
                        <div id="tid-ez-mm-lr-panel" class="tid-flex-col">
                            <div id="tid-ez-mm-lr-left">{{'STRINGS.LEFT' | translate | titlecase}} {{pinSheetSummary?.placementLeftCount ?? '--'}}</div>
                            <div id="tid-ez-mm-lr-right">{{'STRINGS.RIGHT' | translate | titlecase}} {{pinSheetSummary?.placementRightCount ?? '--'}}</div>
                        </div>
                        <div id="tid-ez-mm-variance" class="tid-flex-col">
                            <div id="tid-ez-mm-variance-val" [ngClass]="{'variance-neg': pinSheetSummary?.variance < 0, 'variance-pos': pinSheetSummary?.variance > 0}">
                                {{pinSheetSummary?.variance ?? '--'}}
                            </div>
                            <div id="tid-ez-mm-variance-lbl">{{'STRINGS.VARIANCE' | translate | titlecase}}</div>
                        </div>
                    </div>
                    <div id="tid-ez-mm-btn-container">
                        <div class="tid-ez-footer-container left">
                            <span class="prevent-select">{{'STRINGS.PINSHEET' | translate | titlecase}}</span>
                        </div>
                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="clientAccessId != null"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [unableToFetchDataLinkHtml]="unableToFetchDataLinkHtml"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            (gotoSite)="onGotoSite()"
    >

        <div class="toro-flex-child toro-component-shield-host">
            <ng-container *ngIf="clientAccessId == null" [ngTemplateOutlet]="accountLinkingInfo"></ng-container>

            <div *ngIf="clientAccessId != null" id="tid-ezl-content-container" class="tid-flex-col">

                <div class="tid-ez-fixed-container" [ngClass]="language">
                    <p-dropdown [disabled]="courseList?.length < 2"
                                [options]="coursesSelectList"
                                [(ngModel)]="selectedCourseId"
                                styleClass="tid-ezl-dropdown"
                                panelStyleClass="tid-ezl-dropdown"
                    ></p-dropdown>
                    <ng-container [ngTemplateOutlet]="holeSelector"></ng-container>
                </div>
                <div id="tid-ezl-middle-content-container">

                    <div class="tid-ezl-middle-content tid-flex-col prevent-select">
                        <div id="tid-ezl-placement-label"></div>
                        <ng-container [ngTemplateOutlet]="pinLeftRightCounts"></ng-container>
                        <ng-container [ngTemplateOutlet]="pinStimpCounts"></ng-container>
                        <ng-container [ngTemplateOutlet]="pinVariance"></ng-container>
                    </div>

                    <div class="tid-ezl-middle-content tid-flex-col">
                        <ng-container [ngTemplateOutlet]="pinLegend"></ng-container>
                        <ng-container [ngTemplateOutlet]="pinPlacement"></ng-container>
                    </div>

                </div>
                <div class="tid-ez-fixed-container">
                    <div class="tid-ez-footer-container left" (click)="onExpandPinSheet()">
                        <span class="prevent-select">{{'STRINGS.PINSHEET' | translate | titlecase}}</span>
                    </div>
                    <ng-container [ngTemplateOutlet]="pinDetails"></ng-container>
                </div>

                <toro-waiting-indicator *ngIf="isRetrievingCourseData" [isVisible]="isRetrievingCourseData" backgroundColor="rgba(0,0,0,0.1)"></toro-waiting-indicator>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="tid-ez-title-icon" src="../../../../../../assets/images/ezLocator-logo.png" alt="">
</ng-template>

<ng-template #holeSelector let-usage="usage">
    <p-dropdown [options]="greensSelectList"
                [(ngModel)]="selectedGreenId"
                [styleClass]="(usage != 'modal' ? 'tid-ezl-dropdown' : 'tid-ezl-dropdown full-width')"
                panelStyleClass="tid-ezl-dropdown"
    ></p-dropdown>
</ng-template>

<ng-template #pinLegend let-usage="usage">
    <div id="tid-ezl-middle-header" class="prevent-select" [ngClass]="{'ez-modal': usage === 'modal', 'ez-desktop': usage === 'desktop'}">
        <div class="tid-ezl-legend-item">
            <fa-icon icon="circle" class="pin-yes"></fa-icon>
            {{(usage === 'modal' ? pinLegendStrings?.full[0] : pinLegendStrings?.abbreviated[0]) | translate | uppercase}}</div>
        <div class="tid-ezl-legend-item">
            <fa-icon icon="circle" class="pin-tod"></fa-icon>
            {{(usage === 'modal' ? pinLegendStrings?.full[1] : pinLegendStrings?.abbreviated[1]) | translate | uppercase}}</div>
        <div class="tid-ezl-legend-item">
            <fa-icon icon="circle" class="pin-tom"></fa-icon>
            {{(usage === 'modal' ? pinLegendStrings?.full[2] : pinLegendStrings?.abbreviated[2]) | translate | uppercase}}</div>
    </div>
</ng-template>

<ng-template #pinPlacement let-usage="usage">
    <div id="tid-ezl-middle-body">
        <div id="tid-ezl-green-container"
             [ngStyle]="{'height': (usage != 'modal' ? ImageSize : MiniModalImageSize) + 'px', 'width': (usage != 'modal' ? ImageSize : MiniModalImageSize) + 'px'}"
        >
            <img *ngIf="selectedGreen?.greenImage" class="prevent-select" src="{{'data:image/jpg;base64,' + selectedGreen?.greenImage}}" alt=""
                 [ngStyle]="{'height': (usage != 'modal' ? ImageSize : MiniModalImageSize) + 'px', 'width': (usage != 'modal' ? ImageSize : MiniModalImageSize) + 'px'}"
            >
            <fa-icon class="tid-ezl-pin pin-yes" icon="circle"
                     *ngIf="desktopPinDetails[0] != null"
                     [ngStyle]="{
                        'top': (usage != 'modal' ? desktopPinDetails[0].holeYPixels : miniModalPinDetails[0].holeYPixels) + 'px',
                        'left': (usage != 'modal' ? desktopPinDetails[0].holeXPixels : miniModalPinDetails[0].holeXPixels) + 'px', 'font-size': holeDiameter + 'px'}"
            ></fa-icon>
            <fa-icon class="tid-ezl-pin pin-tod" icon="circle"
                     *ngIf="desktopPinDetails[1] != null"
                     [ngStyle]="{
                        'top': (usage != 'modal' ? desktopPinDetails[1].holeYPixels : miniModalPinDetails[1].holeYPixels) + 'px',
                        'left': (usage != 'modal' ? desktopPinDetails[1].holeXPixels : miniModalPinDetails[1].holeXPixels) + 'px', 'font-size': holeDiameter + 'px'}"
            ></fa-icon>
            <fa-icon class="tid-ezl-pin pin-tom" icon="circle"
                     *ngIf="desktopPinDetails[2] != null"
                     [ngStyle]="{
                        'top': (usage != 'modal' ? desktopPinDetails[2].holeYPixels : miniModalPinDetails[2].holeYPixels) + 'px',
                        'left': (usage != 'modal' ? desktopPinDetails[2].holeXPixels : miniModalPinDetails[2].holeXPixels) + 'px', 'font-size': holeDiameter + 'px'}"
            ></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #pinDetails let-usage="usage">
    <div class="tid-ez-footer-container right prevent-select" [ngClass]="{'is-modal': usage === 'modal'}">
        <div class="tid-ez-green-stats">{{desktopPinDetails[1]?.holeCenter ?? '--'}}</div>
        <div class="tid-ez-green-stats">{{desktopPinDetails[1]?.holeFront ?? '--'}}, {{desktopPinDetails[1]?.holeSide ?? '--'}}</div>
        <div class="tid-ez-green-stats" [ngStyle]="{'background-color': desktopPinDetails[1]?.stimpBackgroundColor}">{{selectedPinStimpString ?? '--'}}{{desktopPinDetails[1] ? '%' : '--'}}</div>
    </div>
</ng-template>

<ng-template #tabHoles>
    <div id="ez-tab-holes-container" class="ez-tab-container">
        <ng-container [ngTemplateOutlet]="holeSelector" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
        <ng-container [ngTemplateOutlet]="pinLegend" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
        <ng-container [ngTemplateOutlet]="pinPlacement" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>

        <div id="ez-thc-pin-details-container">
            <div id="ez-thc-pin-details">
                <ng-container [ngTemplateOutlet]="pinDetails" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pinLeftRightCounts>
    <div id="tid-ezl-left-right">
        <div class="tid-ezl-lr-content">
            <div class="tid-ezl-lr-lbl">{{'STRINGS.LEFT' | translate | titlecase}}</div>
            <div class="tid-ezl-lr_value">{{pinSheetSummary?.placementLeftCount ?? '--'}}</div>
        </div>
        <div class="tid-ezl-lr-content">
            <div class="tid-ezl-lr-lbl">{{'STRINGS.RIGHT' | translate | titlecase}}</div>
            <div class="tid-ezl-lr_value">{{pinSheetSummary?.placementRightCount ?? '--'}}</div>
        </div>
    </div>
</ng-template>

<ng-template #pinStimpCounts>
    <div id="tid-ezl-stimp">
        <div class="tid-ezl-stimp-reading">
            <div class="tid-ezl-stimp-label">0-2<span>%</span></div>
            <div class="tid-ezl-stimp-count">{{pinSheetSummary?.lowStimpCount ?? '--'}}</div>
        </div>
        <div class="tid-ezl-stimp-reading">
            <div class="tid-ezl-stimp-label">2-3<span>%</span></div>
            <div class="tid-ezl-stimp-count">{{pinSheetSummary?.midStimpCount ?? '--'}}</div>
        </div>
        <div class="tid-ezl-stimp-reading">
            <div class="tid-ezl-stimp-label">>3<span>%</span></div>
            <div class="tid-ezl-stimp-count">{{pinSheetSummary?.highStimpCount ?? '--'}}</div>
        </div>
    </div>
</ng-template>

<ng-template #pinVariance>
    <div id="tid-ezl-variance">
        <span id="tid-ezl-variance-lbl">{{'STRINGS.VARIANCE' | translate | titlecase}}</span>
        <span id="tid-ezl-variance-value" [ngClass]="{'variance-neg': pinSheetSummary?.variance < 0, 'variance-pos': pinSheetSummary?.variance > 0}">
            {{pinSheetSummary?.variance ?? '--'}}
        </span>
    </div>
</ng-template>

<ng-template #tabPlacement>
    <div id="ez-tab-placement-container" class="ez-tab-container">
        <div id="ez-tab-placement-counts">
            <div id="ez-tab-placement-top">
                <ng-container [ngTemplateOutlet]="pinLeftRightCounts"></ng-container>
                <ng-container [ngTemplateOutlet]="pinStimpCounts"></ng-container>
            </div>
            <div id="ez-tab-placement-bottom">
                <ng-container [ngTemplateOutlet]="pinVariance"></ng-container>
            </div>
        </div>

        <div id="ez-tab-placement-summary">
            <toro-ez-locator-pin-placement-summary [pinSheet]="dailyPinSheets[1]"></toro-ez-locator-pin-placement-summary>
        </div>
    </div>
</ng-template>

<ng-template #accountLinkingInfo>
    <div id="ez-acct-link-info" class="prevent-select" [ngClass]="language">
        <div id="ez-al-icon-title">
            <fa-icon icon="exclamation-triangle"></fa-icon>
            <div id="ez-acct-link-title">{{'SPECIAL_MSG.ACCOUNT_LINKING_REQUIRED' | translate}}</div>
        </div>
        <div id="ez-acct-link-details">{{'SPECIAL_MSG.EZ_LOCATOR_ACCOUNT_NOT_LINKED' | translate}}</div>
        <div id="ez-acct-link-btn" class="toro-component-shield-host">
            {{'SPECIAL_MSG.LOG_INTO_EZ_LOCATOR' | translate}}
            <toro-component-shield (shieldClick)="onNavigateToEzl('linkAccount')"></toro-component-shield>
        </div>
        <div id="ez-al-note">{{'SPECIAL_MSG.EZ_LOCATOR_SIGN_IN_NOTE' | translate}}</div>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <div id="tid-ez-mm-modal-content">
            <div id="tid-ez-modal-header">
                <div id="tid-ez-tab-container">
                    <div class="tid-ez-tab" [ngClass]="{'tid-selected': selectedTab == EzTab.Holes}" (click)="onToggleTab()">
                        {{'STRINGS.HOLES' | translate | titlecase}}
                    </div>
                    <div class="tid-ez-tab" [ngClass]="{'tid-selected': selectedTab == EzTab.Placement}" (click)="onToggleTab()">
                        {{'STRINGS.PIN_PLACEMENT' | translate | titlecase}}
                    </div>
                </div>
            </div>

            <div id="tid-ez-modal-body">
                <div id="tid-ez-modal-body-content">
                    <ng-container *ngIf="selectedTab === EzTab.Holes" [ngTemplateOutlet]="tabHoles"></ng-container>
                    <ng-container *ngIf="selectedTab === EzTab.Placement" [ngTemplateOutlet]="tabPlacement"></ng-container>
                </div>
            </div>
        </div>

    </div>
</toro-mini-mode-widget-modal>

<toro-ez-locator-desktop-modal
        *ngIf="showDesktopModal"
        [title]="title"
        [isTitleCase]="false"
        [pinSheet]="dailyPinSheets[1]"
        [greenImages]="greenImages"
        [imageSize]="ImageSize"
        (cancel)="onToggleDesktopModal()"
        (gotoSite)="onGotoSite()"
></toro-ez-locator-desktop-modal>
