export class AlertUserSettings {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    emailAddresses: string;
    smsNumbers: string;
    duplicateDelay: number;
}
