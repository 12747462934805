<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{width: '400px', maxWidth: '95%'}">
    <p-header class="rsd-header">
        {{'STRINGS.RUNNING_STATIONS' | translate | titlecase}}
        <span class="rsd-header-timestamp">{{'STRINGS.CURRENT_AS_OF' | translate}} {{currentAsOfTimestamp}}</span>
    </p-header>

    <toro-running-stations-expando-panel [runningStationsDetails]="stationDetailsInternal"></toro-running-stations-expando-panel>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" class="ui-button-secondary" (onClick)="onClose()"></p-button>
        <p-button type="button" [label]="'STRINGS.REFRESH' | translate" (onClick)="onRefresh()"></p-button>

    </p-footer>
<!--    <p-footer class="rsd-footer-timestamp">-->
<!--        <span>{{'STRINGS.CURRENT_AS_OF' | translate}} {{currentAsOfTimestamp}}</span>-->
<!--    </p-footer>-->
</p-dialog>

