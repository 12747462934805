/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TaskTrackerCompany } from './models/task-tracker-company.model';
import { TaskTrackerDailySummary } from './models/task-tracker-daily-summary.model';
import { TaskTrackerMeasurementSummary } from './models/task-tracker-measurement-summary.model';
import { TaskTrackerSafety } from './models/task-tracker-safety.model';

@Injectable({
    providedIn: 'root'
})
export class TaskTrackerApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getActiveCompanies(): Observable<TaskTrackerCompany[]> {
        return this.apiService.apiRequest<any>(TaskTrackerApiService.activeCompaniesUrl, HttpMethod.Get)
            .pipe(map((response: TaskTrackerCompany[]) => response.map(c => new TaskTrackerCompany(c))));
    }

    getDailySummary(companyId: number): Observable<TaskTrackerDailySummary> {
        return this.apiService.apiRequest<any>(TaskTrackerApiService.dailySummaryUrl(companyId), HttpMethod.Get)
            .pipe(map((response: TaskTrackerDailySummary) => new TaskTrackerDailySummary(response)));
    }

    getMeasurementSummary(companyId: number): Observable<TaskTrackerMeasurementSummary[]> {
        return this.apiService.apiRequest<any>(TaskTrackerApiService.measurementSummaryUrl(companyId), HttpMethod.Get)
            .pipe(map((response: TaskTrackerMeasurementSummary[]) =>  response.map(s => new TaskTrackerMeasurementSummary(s))));
    }

    getSafeties(companyId: number): Observable<TaskTrackerSafety[]> {
        return this.apiService.apiRequest<any>(TaskTrackerApiService.safetiesUrl(companyId), HttpMethod.Get)
            .pipe(map((response: TaskTrackerSafety[]) =>  response.map(s => new TaskTrackerSafety(s))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}taskTracker`; }

    private static get activeCompaniesUrl(): string { return `${TaskTrackerApiService.baseUrl}/activeCompanies`; }

    private static dailySummaryUrl(companyId: number): string {
        return `${TaskTrackerApiService.baseUrl}/dailySummary?companyId=${companyId}`;
    }

    private static measurementSummaryUrl(companyId: number): string {
        return `${TaskTrackerApiService.baseUrl}/measurementSummary?companyId=${companyId}`;
    }

    private static safetiesUrl(companyId: number): string {
        return `${TaskTrackerApiService.baseUrl}/safeties?companyId=${companyId}`;
    }

}
