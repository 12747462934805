
export class PlaybooksNutrients {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            // if (json.ingredients) { this.ingredients = new PlaybooksIngredients(json.ingredients); }
        }
    }

    areaName: string;
    // ingredients: PlaybooksIngredients;
}
