import * as moment from 'moment';

export class GcsaaChannelItem {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.pubDate) this.pubDate = moment.utc(json.pubDate).toDate();
            if (json.description) this.descriptionHtml = json.description;

            delete this["description"];
            delete this["guid"];
            delete this["a10:author"];
        }
    }

    title: string;
    descriptionHtml: string;
    pubDate: Date;
}
