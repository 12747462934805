/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., ToroUtils.Translate.instant
 * 		 See: _toro.utils.ts
 */

export namespace XXUseToroUtilsNamespace {

    export abstract class DateTime {

        static toTimeZoneAbbreviation(date: Date): string {
            const longTimeZone = date.toString().match(/\(([A-Za-z\s].*)\)/)[1];
            const words = longTimeZone.split(' ');

            let abbr = '';
            words.forEach(w => abbr += w.slice(0, 1));

            return abbr;
        }

    }
}
