export class GreensListEntry {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    greenId: number;
    isPrimary: boolean;
    isGenerated: boolean;
    positionList: number;
}
