import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DatePipe } from '@angular/common';
import { DtnDailyWeatherDay } from '../../../../../api/clear-ag/models/dtn-forecast.model';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherIconInfo } from '../../../../../api/weather/models/weather-icon-info.model';

@UntilDestroy()
@Component({
    selector: 'toro-dtn-daily-forecast-card',
    templateUrl: './dtn-daily-forecast-card.component.html',
    styleUrls: ['./dtn-daily-forecast-card.component.less']
})
export class DtnDailyForecastCardComponent  implements OnInit {
    @HostBinding('class') class = 'toro-daily-forecast-card';

    @Input() dateString: string;

    private _isTomorrow = false;
    @Input() set isTomorrow(value: boolean) {
        if (this._isTomorrow === value) return;

        this._isTomorrow = value;
        this.setCardData();
    }

    get isTomorrow(): boolean {
        return this._isTomorrow;
    }

    private _dailyForecast: DtnDailyWeatherDay;
    @Input() set dailyForecast(value: DtnDailyWeatherDay) {
        if (this._dailyForecast === value) return;

        this._dailyForecast = value;
        this.setCardData();
    }

    get dailyForecast(): DtnDailyWeatherDay {
        return this._dailyForecast;
    }

    day: string = '--'
    tempUnits: string = '--';
    highTemp: string = '--';
    averageTemp: string = '--';
    lowTemp: string = '--';
    precipTotal: string = '--'
    windSpeed: string = '--';
    weatherIcon: WeatherIconInfo;
    weatherImageUrl: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) { }

    ngOnInit(): void {
        this.setCardData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setCardData();
            });

    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setCardData(): void {
        if (!this.dailyForecast) return;

        // Although the data is correct, when displaying the day (e.g., Sat) on the card, we need to adjust
        // for the day at the site. We can't simply use the local date.
        // const dayAtSite = this.siteManager.getDayAtSiteFromUtcDate(this.dailyForecast.date);
        const dayAtSite = this.datePipe.transform(this.dateString, 'EEE');
        this.day = this.isTomorrow ? this.translateService.instant('STRINGS.TOMORROW') : dayAtSite;

        this.tempUnits = this.userFormatService.temperatureUnits;
        this.highTemp = <string>this.userFormatService.temperature(this.dailyForecast.overall.air_temp_max.value, true);
        this.averageTemp = <string>this.userFormatService.temperature(this.dailyForecast.overall.air_temp_avg.value, true);
        this.lowTemp = <string>this.userFormatService.temperature(this.dailyForecast.overall.air_temp_min.value, true);

        // this.weatherIcon = this.weatherManager.getWeatherIcon(this.dailyForecast.conditions, this.dailyForecast.cloudCover);
        this.weatherImageUrl = this.dailyForecast.overall.descriptors.weather_descriptor.icon;

        const userPrecip = this.userFormatService.rainfall(this.dailyForecast.overall.precip_acc_period.value, true);
        this.precipTotal = `${userPrecip}${!this.userFormatService.isMetric ? '' : ' '}${this.userFormatService.rainfallUnits}`;
        const userWindSpeed = this.userFormatService.windSpeed(this.dailyForecast.overall.wind_speed_avg.value);
        this.windSpeed = `${userWindSpeed} ${this.userFormatService.windSpeedUnits}`;

    }
}

