import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DailyPinSheet } from '../../../../../api/ez-locator/models/daily-pin-sheet.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { GreenImageEntry } from '../../../../../api/ez-locator/models/green-image-entry.model';
import { PinSheetEntry } from '../../../../../api/ez-locator/models/pin-sheet-entry.model';

@UntilDestroy()
@Component({
    selector: 'toro-ez-locator-desktop-modal',
    templateUrl: './ez-locator-desktop-modal.component.html',
    styleUrls: ['./ez-locator-desktop-modal.component.less']
})
export class EzLocatorDesktopModalComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() gotoSite = new EventEmitter();

    @Input() title = '';
    @Input() isTitleCase = true;
    @Input() showLinkButton = false;
    @Input() pinSheet: DailyPinSheet;
    @Input() greenImages: GreenImageEntry[] = [];
    @Input() imageSize: number;

    modalImageSize = 0;                                                     // Height and Width of image in pixels
    holeDiameter = 0;                                                       // Diameter of hole in pixels. This is the font size.
    private imageSizeRatio = 0;                                             // Ratio of image size displayed on modal relative to image size retrieved from API. Used for proper pin placement.
    private readonly holeIconTopPad = 1.5;                                  // Icon whitespace above circle image. This is not an exact offset and will only work at small font sizes.
    holeXOffset = 0;
    holeYOffset = 0;

    adjustedPinSheetEntries: PinSheetEntry[] = [];

    private timerRef: any;

    // TODO: TEMPORARY until we're happy with the size of things.
    private readonly greenHeaderHeight = 18;
    private readonly greenFooterHeight = 18;
    private readonly wrapperPadding = 4;
    wrapperHeight = this.modalImageSize + this.greenHeaderHeight + this.greenFooterHeight + this.wrapperPadding;

    private _isMobile = false;
    set isMobile(value: boolean) {
        this._isMobile = value;

        this.modalImageSize = !this._isMobile ? 120 : 120;
        this.wrapperHeight = this.modalImageSize + this.greenHeaderHeight + this.greenFooterHeight + this.wrapperPadding;
        this.holeDiameter = !this._isMobile ? 8 : 6;
        this.holeXOffset = this.holeDiameter / 2;
        this.holeYOffset = this.holeDiameter / 2 + this.holeIconTopPad;

        this.adjustPinSheetData();
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService) { }

    ngOnInit() {
        this.isMobile = this.deviceManager.isMobile;

        this.adjustPinSheetData();

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.timerRef) clearTimeout(this.timerRef);

                this.timerRef = setTimeout(() => {
                    this.isMobile = this.deviceManager.isMobile;
                }, 250);
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.cancel.emit();
    }

    onGotoSite() {
        this.gotoSite.emit();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    adjustPinSheetData() {
        this.adjustedPinSheetEntries = [];
        this.imageSizeRatio = this.modalImageSize / this.imageSize;

        // Sometimes, the number of greenImages differs from the number of PinSheetEntries that we get. To ensure we associate the proper pin placement
        // with the correct green image, we iterate through the Green images, find a related pin placement (if it exists) and then add the proper entry
        // to the adjustedPinSheetEntries array. If we don't find a related pinSheetEntry, we add a placeholder in the adjustedPinSheetEntries array to
        // ensure we are placing the correct pin information ont he correct green.

        this.greenImages.forEach(g => {
            const relatedPinSheetEntry = this.pinSheet.pinSheetEntries.find(e => e.greenId === g.greenId);
            if (relatedPinSheetEntry == null) {
                this.adjustedPinSheetEntries.push(new PinSheetEntry(null));
                return;
            }

            const clonedEntry = new PinSheetEntry({ ...relatedPinSheetEntry });
            clonedEntry.holeXPixels = clonedEntry.holeXPixels * this.imageSizeRatio;
            clonedEntry.holeYPixels = clonedEntry.holeYPixels * this.imageSizeRatio;
            this.adjustedPinSheetEntries.push(clonedEntry);
        })

    }

}
