import * as moment from 'moment';
import { PwValueUnit } from './pw-value-unit.model';
import { PwWind } from './pw-wind.model';

export class PwHourlyForecast {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.observationTime) this.observationTime = moment.utc(json.observationTime).toDate();
            if (json.ambientTemperature) this.ambientTemperature = new PwValueUnit(this.ambientTemperature);
            if (json.humidity) this.humidity = new PwValueUnit(this.humidity);
            if (json.wind) this.wind = new PwWind(this.wind);
        }
    }

    observationTime: Date;
    ambientTemperature: PwValueUnit;
    humidity: PwValueUnit;
    wind: PwWind;
}
