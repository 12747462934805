import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AhSnapComponent } from './root-components/ah-snap/ah-snap.component';
import { DemoComponent } from './root-components/demo/demo.component';
import { DemoGuardService } from './demo/demo-guard.service';
import { NgModule } from '@angular/core';
import { NsnErrorComponent } from './root-components/nsn-error/nsn-error.component';
import { WelcomeComponent } from './root-components/welcome/welcome.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full', loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
    },
    { path: 'welcome', component: WelcomeComponent, },
    { path: 'ah-snap', component: AhSnapComponent },
    { path: 'nsn-error', component: NsnErrorComponent },
    { path: 'demo', component: DemoComponent, canActivate: [DemoGuardService] },
    // {
    //     path: 'spatialadjust',
    //     loadChildren: () => import('./spatial-adjust/spatial-adjust.module').then(m => m.SpatialAdjustModule)
    // },

    { path: '**', redirectTo: '/ah-snap' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
