import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';

@Component({
    selector: 'toro-turf-guard-alerts-header',
    templateUrl: './turf-guard-alerts-header.component.html',
    styleUrls: ['./turf-guard-alerts-header.component.less']
})
export class TurfGuardAlertsHeaderComponent {

    private _turfGuardWidgetData: TurfGuardWidgetData;
    @Input() set turfGuardWidgetData(value: TurfGuardWidgetData) {
        this._turfGuardWidgetData = value;
        this.setHeaderAlertsInfo();
    }

    get turfGuardWidgetData(): TurfGuardWidgetData {
        return this._turfGuardWidgetData;
    }

    @Input() isResizing = false;

    headerAlertsInfo: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private translateService: TranslateService) { }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setHeaderAlertsInfo() {
        this.headerAlertsInfo = null;
        if (!this.turfGuardWidgetData) return;

        if (this.turfGuardWidgetData.highAlertsCount > 0) {
            this.headerAlertsInfo = `${this.turfGuardWidgetData.highAlertsCount} ${this.translateService.instant('STRINGS.HIGH').toLowerCase()}`;
        }

        if (this.turfGuardWidgetData.lowAlertsCount > 0) {
            this.headerAlertsInfo = (this.headerAlertsInfo != null) ? `${this.headerAlertsInfo}, ` : '';
            this.headerAlertsInfo += `${this.turfGuardWidgetData.lowAlertsCount} ${this.translateService.instant('STRINGS.LOW').toLowerCase()}`;
        }
    }
}
