import { PogoProperty } from '../../api/pogo/models/pogo-property.model';

export class PogoDemoData {

    static get pogoProperty(): PogoProperty {
        return new PogoProperty(
            {
                "property_name": "Dubai Creek Golf and Yacht Club",
                "property_link": "https://app.pogoturfpro.com/app/course/84",
                "partner_setup_link": "https://app.pogoturfpro.com/app/course/84/setup?page=partner",
                "map_link": "https://app.pogoturfpro.com/app/course/84/map",
                "zones": [
                    {
                        "zone_name": "01 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 40.64,
                        "conditions": {
                            "timestamp": "2023-12-21 06:28:28",
                            "moisture_avg": 20.95,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 84.5,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 15.8,
                            "moisture_max": 28.1,
                            "min_location": {
                                "lat": 25.240613,
                                "lng": 55.337826
                            },
                            "max_location": {
                                "lat": 25.240487,
                                "lng": 55.337697
                            },
                            "moisture_tpi": "↑55",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_01.png",
                            "img_extent": {
                                "min_lng": 55.337469,
                                "max_lng": 55.337847,
                                "min_lat": 25.24046,
                                "max_lat": 25.24066
                            },
                            "approach_marker": {
                                "lat": 25.240559,
                                "lng": 55.337462
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.240596,
                                "longitude": 55.337526,
                                "timestamp": "2023-11-16"
                            },
                            {
                                "latitude": 25.240618,
                                "longitude": 55.337748,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.240554,
                                "longitude": 55.337778,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.240574,
                                "longitude": 55.337662,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.240586,
                                "longitude": 55.337558,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.240508,
                                "longitude": 55.337646,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.240536,
                                "longitude": 55.337546,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.240609,
                                "longitude": 55.337577,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.240533,
                                "longitude": 55.337731,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.240628,
                                "longitude": 55.337644,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.240565,
                                "longitude": 55.337609,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.240604,
                                "longitude": 55.337742,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.240573,
                                "longitude": 55.337516,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.240526,
                                "longitude": 55.337635,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.240529,
                                "longitude": 55.337547,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.240516,
                                "longitude": 55.337704,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.240583,
                                "longitude": 55.337681,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.240611,
                                "longitude": 55.33758,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "02 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 28.7,
                        "conditions": {
                            "timestamp": "2023-12-21 04:21:24",
                            "moisture_avg": 20.17,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 81.4,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 15.1,
                            "moisture_max": 26.5,
                            "min_location": {
                                "lat": 25.245428,
                                "lng": 55.337302
                            },
                            "max_location": {
                                "lat": 25.245541,
                                "lng": 55.337089
                            },
                            "moisture_tpi": "↑58",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_02.png",
                            "img_extent": {
                                "min_lng": 55.337002,
                                "max_lng": 55.337343,
                                "min_lat": 25.245391,
                                "max_lat": 25.245568
                            },
                            "approach_marker": {
                                "lat": 25.24543,
                                "lng": 55.337324
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.245533,
                                "longitude": 55.337108,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.245438,
                                "longitude": 55.337297,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.245476,
                                "longitude": 55.33708,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.245482,
                                "longitude": 55.337176,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.245436,
                                "longitude": 55.337156,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.245423,
                                "longitude": 55.337249,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.245446,
                                "longitude": 55.33709,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.245525,
                                "longitude": 55.337151,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.245517,
                                "longitude": 55.337123,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.245419,
                                "longitude": 55.337213,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.245493,
                                "longitude": 55.337222,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.245467,
                                "longitude": 55.337272,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.245484,
                                "longitude": 55.337136,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.24548,
                                "longitude": 55.337067,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.245438,
                                "longitude": 55.337146,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.245431,
                                "longitude": 55.337279,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.245452,
                                "longitude": 55.337086,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.245476,
                                "longitude": 55.337171,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.245436,
                                "longitude": 55.337261,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "03 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 29.59,
                        "conditions": {
                            "timestamp": "2023-12-21 04:11:39",
                            "moisture_avg": 18.53,
                            "moisture_avg_color": "#4EC3DA",
                            "moisture_avg_rating": "High",
                            "moisture_du": 88.13,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 15.3,
                            "moisture_max": 22.9,
                            "min_location": {
                                "lat": 25.247911,
                                "lng": 55.335361
                            },
                            "max_location": {
                                "lat": 25.247813,
                                "lng": 55.335426
                            },
                            "moisture_tpi": "↑73",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_03.png",
                            "img_extent": {
                                "min_lng": 55.335254,
                                "max_lng": 55.335504,
                                "min_lat": 25.247665,
                                "max_lat": 25.247971
                            },
                            "approach_marker": {
                                "lat": 25.247655,
                                "lng": 55.335325
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.24789,
                                "longitude": 55.33535,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.247821,
                                "longitude": 55.335364,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.247725,
                                "longitude": 55.335376,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.247866,
                                "longitude": 55.335447,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.24777,
                                "longitude": 55.335291,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.247811,
                                "longitude": 55.33542,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.247851,
                                "longitude": 55.335309,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.247719,
                                "longitude": 55.335319,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.247938,
                                "longitude": 55.335359,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.247778,
                                "longitude": 55.335385,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.247888,
                                "longitude": 55.335389,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.247848,
                                "longitude": 55.335346,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.247705,
                                "longitude": 55.335345,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.247871,
                                "longitude": 55.335366,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.247785,
                                "longitude": 55.335419,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.247738,
                                "longitude": 55.335322,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.247858,
                                "longitude": 55.335305,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.24783,
                                "longitude": 55.335356,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "04 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 26.52,
                        "conditions": {
                            "timestamp": "2023-12-21 04:31:56",
                            "moisture_avg": 22.1,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 78.45,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 12.7,
                            "moisture_max": 27.8,
                            "min_location": {
                                "lat": 25.249937,
                                "lng": 55.330844
                            },
                            "max_location": {
                                "lat": 25.249904,
                                "lng": 55.331029
                            },
                            "moisture_tpi": "↑44",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_04.png",
                            "img_extent": {
                                "min_lng": 55.330811,
                                "max_lng": 55.331092,
                                "min_lat": 25.249768,
                                "max_lat": 25.249985
                            },
                            "approach_marker": {
                                "lat": 25.24982,
                                "lng": 55.331063
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.249949,
                                "longitude": 55.330864,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.249826,
                                "longitude": 55.331013,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.249888,
                                "longitude": 55.330928,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.249898,
                                "longitude": 55.331003,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.249903,
                                "longitude": 55.330859,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.249805,
                                "longitude": 55.330949,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.249854,
                                "longitude": 55.330883,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.24994,
                                "longitude": 55.330942,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.249942,
                                "longitude": 55.330887,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.249865,
                                "longitude": 55.330971,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.249827,
                                "longitude": 55.330906,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.24985,
                                "longitude": 55.331018,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.249947,
                                "longitude": 55.330842,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.249915,
                                "longitude": 55.33088,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.249836,
                                "longitude": 55.330994,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.249848,
                                "longitude": 55.330907,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.24992,
                                "longitude": 55.33096,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.249899,
                                "longitude": 55.330849,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "05 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 40.82,
                        "conditions": {
                            "timestamp": "2023-12-21 04:50:54",
                            "moisture_avg": 19.47,
                            "moisture_avg_color": "#4EC3DA",
                            "moisture_avg_rating": "High",
                            "moisture_du": 79.31,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 12.2,
                            "moisture_max": 25.7,
                            "min_location": {
                                "lat": 25.249437,
                                "lng": 55.328331
                            },
                            "max_location": {
                                "lat": 25.249618,
                                "lng": 55.328404
                            },
                            "moisture_tpi": "↑61",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_05.png",
                            "img_extent": {
                                "min_lng": 55.328229,
                                "max_lng": 55.328515,
                                "min_lat": 25.249294,
                                "max_lat": 25.249651
                            },
                            "approach_marker": {
                                "lat": 25.24964,
                                "lng": 55.328369
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.249587,
                                "longitude": 55.328327,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.24934,
                                "longitude": 55.328408,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.249562,
                                "longitude": 55.328273,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.249472,
                                "longitude": 55.328414,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.249508,
                                "longitude": 55.32831,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.249422,
                                "longitude": 55.328378,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.249533,
                                "longitude": 55.328265,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.249408,
                                "longitude": 55.328454,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.249588,
                                "longitude": 55.32836,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.249527,
                                "longitude": 55.328375,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.249471,
                                "longitude": 55.328336,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.249352,
                                "longitude": 55.328465,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.249383,
                                "longitude": 55.328405,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.249575,
                                "longitude": 55.328342,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.249365,
                                "longitude": 55.328425,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.249533,
                                "longitude": 55.328288,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.249477,
                                "longitude": 55.32839,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.249472,
                                "longitude": 55.328324,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "06 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 27.22,
                        "conditions": {
                            "timestamp": "2023-12-21 04:59:51",
                            "moisture_avg": 20.12,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 79.79,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 14.8,
                            "moisture_max": 27.1,
                            "min_location": {
                                "lat": 25.247288,
                                "lng": 55.331944
                            },
                            "max_location": {
                                "lat": 25.247258,
                                "lng": 55.331909
                            },
                            "moisture_tpi": "↑57",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_06.png",
                            "img_extent": {
                                "min_lng": 55.331666,
                                "max_lng": 55.331995,
                                "min_lat": 25.247066,
                                "max_lat": 25.24733
                            },
                            "approach_marker": {
                                "lat": 25.247125,
                                "lng": 55.331653
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.247284,
                                "longitude": 55.331884,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.247175,
                                "longitude": 55.331727,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.247104,
                                "longitude": 55.331831,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.247226,
                                "longitude": 55.33194,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.247217,
                                "longitude": 55.331858,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.247145,
                                "longitude": 55.331906,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.247218,
                                "longitude": 55.331807,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.247182,
                                "longitude": 55.331823,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.247126,
                                "longitude": 55.331754,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.247291,
                                "longitude": 55.3319,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.247121,
                                "longitude": 55.331888,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.247186,
                                "longitude": 55.331752,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.247109,
                                "longitude": 55.331768,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.247252,
                                "longitude": 55.331892,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.247138,
                                "longitude": 55.331749,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.247156,
                                "longitude": 55.331905,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.247236,
                                "longitude": 55.331823,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.247131,
                                "longitude": 55.33183,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "07 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 34.16,
                        "conditions": {
                            "timestamp": "2023-12-21 05:07:44",
                            "moisture_avg": 21.38,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 82.74,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 14.9,
                            "moisture_max": 27.2,
                            "min_location": {
                                "lat": 25.244415,
                                "lng": 55.337003
                            },
                            "max_location": {
                                "lat": 25.244418,
                                "lng": 55.33669
                            },
                            "moisture_tpi": "↑51",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_07.png",
                            "img_extent": {
                                "min_lng": 55.336658,
                                "max_lng": 55.337002,
                                "min_lat": 25.24431,
                                "max_lat": 25.244506
                            },
                            "approach_marker": {
                                "lat": 25.244456,
                                "lng": 55.336645
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.244436,
                                "longitude": 55.336721,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.244453,
                                "longitude": 55.336916,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.244372,
                                "longitude": 55.336735,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.244422,
                                "longitude": 55.33684,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.244392,
                                "longitude": 55.336917,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.244348,
                                "longitude": 55.33684,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.244472,
                                "longitude": 55.336804,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.244411,
                                "longitude": 55.336783,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.244435,
                                "longitude": 55.336715,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.244329,
                                "longitude": 55.33679,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.244466,
                                "longitude": 55.336874,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.244437,
                                "longitude": 55.336953,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.244455,
                                "longitude": 55.336775,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.244425,
                                "longitude": 55.33693,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.244398,
                                "longitude": 55.336737,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.244334,
                                "longitude": 55.336803,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.244422,
                                "longitude": 55.336859,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.24438,
                                "longitude": 55.336902,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "08 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 27.93,
                        "conditions": {
                            "timestamp": "2023-12-21 05:50:03",
                            "moisture_avg": 19.48,
                            "moisture_avg_color": "#4EC3DA",
                            "moisture_avg_rating": "High",
                            "moisture_du": 76.41,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 11.7,
                            "moisture_max": 27.3,
                            "min_location": {
                                "lat": 25.241793,
                                "lng": 55.338312
                            },
                            "max_location": {
                                "lat": 25.241823,
                                "lng": 55.33825
                            },
                            "moisture_tpi": "↑59",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_08.png",
                            "img_extent": {
                                "min_lng": 55.338135,
                                "max_lng": 55.338476,
                                "min_lat": 25.241594,
                                "max_lat": 25.241853
                            },
                            "approach_marker": {
                                "lat": 25.241833,
                                "lng": 55.338132
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.241796,
                                "longitude": 55.338191,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.241627,
                                "longitude": 55.338357,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.241715,
                                "longitude": 55.338386,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.241747,
                                "longitude": 55.338306,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.241739,
                                "longitude": 55.338206,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.241673,
                                "longitude": 55.338258,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.241687,
                                "longitude": 55.338341,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.241793,
                                "longitude": 55.33825,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.241805,
                                "longitude": 55.338192,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.241638,
                                "longitude": 55.338276,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.241731,
                                "longitude": 55.338272,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.241654,
                                "longitude": 55.338391,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.241637,
                                "longitude": 55.338337,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.241789,
                                "longitude": 55.338215,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.24163,
                                "longitude": 55.338393,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.241712,
                                "longitude": 55.338228,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.241745,
                                "longitude": 55.338338,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.24166,
                                "longitude": 55.338317,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "09 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 39.28,
                        "conditions": {
                            "timestamp": "2023-12-21 05:34:38",
                            "moisture_avg": 22.38,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 82.21,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 16,
                            "moisture_max": 28.8,
                            "min_location": {
                                "lat": 25.241504,
                                "lng": 55.334527
                            },
                            "max_location": {
                                "lat": 25.24151,
                                "lng": 55.334778
                            },
                            "moisture_tpi": "↑44",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_09.png",
                            "img_extent": {
                                "min_lng": 55.334417,
                                "max_lng": 55.334771,
                                "min_lat": 25.24144,
                                "max_lat": 25.241631
                            },
                            "approach_marker": {
                                "lat": 25.241486,
                                "lng": 55.334758
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.241556,
                                "longitude": 55.33448,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.241574,
                                "longitude": 55.334681,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.241476,
                                "longitude": 55.334613,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.24155,
                                "longitude": 55.334614,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.24151,
                                "longitude": 55.334709,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.241506,
                                "longitude": 55.334502,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.241583,
                                "longitude": 55.334558,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.241525,
                                "longitude": 55.33456,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.24156,
                                "longitude": 55.334507,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.241526,
                                "longitude": 55.334654,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.241603,
                                "longitude": 55.334625,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.24159,
                                "longitude": 55.33468,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.241492,
                                "longitude": 55.334548,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.241559,
                                "longitude": 55.334494,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.241494,
                                "longitude": 55.334681,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.241582,
                                "longitude": 55.334629,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.24151,
                                "longitude": 55.334568,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.241548,
                                "longitude": 55.33465,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "10 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 33.91,
                        "conditions": {
                            "timestamp": "2023-12-21 07:08:18",
                            "moisture_avg": 22.46,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 74.81,
                            "moisture_du_color": "#AF4440",
                            "moisture_min": 15.3,
                            "moisture_max": 29.1,
                            "min_location": {
                                "lat": 25.237446,
                                "lng": 55.336638
                            },
                            "max_location": {
                                "lat": 25.237612,
                                "lng": 55.33673
                            },
                            "moisture_tpi": "↑40",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_10.png",
                            "img_extent": {
                                "min_lng": 55.336531,
                                "max_lng": 55.336789,
                                "min_lat": 25.237353,
                                "max_lat": 25.237658
                            },
                            "approach_marker": {
                                "lat": 25.23765,
                                "lng": 55.336669
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.237567,
                                "longitude": 55.336602,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.237382,
                                "longitude": 55.336718,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.237477,
                                "longitude": 55.336586,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.237569,
                                "longitude": 55.3367,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.237431,
                                "longitude": 55.336628,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.237513,
                                "longitude": 55.336713,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.237557,
                                "longitude": 55.336658,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.237507,
                                "longitude": 55.33667,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.237544,
                                "longitude": 55.336617,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.237454,
                                "longitude": 55.336715,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.237403,
                                "longitude": 55.336621,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.23745,
                                "longitude": 55.336583,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.237521,
                                "longitude": 55.336651,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.237585,
                                "longitude": 55.336672,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.237416,
                                "longitude": 55.3367,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.2375,
                                "longitude": 55.336617,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.237534,
                                "longitude": 55.336707,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.237434,
                                "longitude": 55.336621,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "11 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 29.16,
                        "conditions": {
                            "timestamp": "2023-12-21 06:23:39",
                            "moisture_avg": 21.24,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 77.92,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 15.8,
                            "moisture_max": 29.3,
                            "min_location": {
                                "lat": 25.240029,
                                "lng": 55.337743
                            },
                            "max_location": {
                                "lat": 25.240071,
                                "lng": 55.33783
                            },
                            "moisture_tpi": "↑49",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_11.png",
                            "img_extent": {
                                "min_lng": 55.337597,
                                "max_lng": 55.337841,
                                "min_lat": 25.239864,
                                "max_lat": 25.240149
                            },
                            "approach_marker": {
                                "lat": 25.239914,
                                "lng": 55.337615
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.2401,
                                "longitude": 55.337727,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.239932,
                                "longitude": 55.33765,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.239997,
                                "longitude": 55.337789,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.240059,
                                "longitude": 55.337666,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.240012,
                                "longitude": 55.337723,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.240078,
                                "longitude": 55.337796,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.239945,
                                "longitude": 55.337735,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.240003,
                                "longitude": 55.33766,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.240102,
                                "longitude": 55.337715,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.239914,
                                "longitude": 55.337699,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.239998,
                                "longitude": 55.33774,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.239961,
                                "longitude": 55.337666,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.239972,
                                "longitude": 55.337651,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.240088,
                                "longitude": 55.337712,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.239931,
                                "longitude": 55.337739,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.240005,
                                "longitude": 55.337654,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.240066,
                                "longitude": 55.337776,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.240001,
                                "longitude": 55.337725,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "12 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 32.36,
                        "conditions": {
                            "timestamp": "2023-12-21 06:35:00",
                            "moisture_avg": 24.21,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 81.93,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 18.8,
                            "moisture_max": 30.9,
                            "min_location": {
                                "lat": 25.237088,
                                "lng": 55.337921
                            },
                            "max_location": {
                                "lat": 25.237248,
                                "lng": 55.337885
                            },
                            "moisture_tpi": "↑32",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_12.png",
                            "img_extent": {
                                "min_lng": 55.337763,
                                "max_lng": 55.337997,
                                "min_lat": 25.23698,
                                "max_lat": 25.237271
                            },
                            "approach_marker": {
                                "lat": 25.237266,
                                "lng": 55.337895
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.237227,
                                "longitude": 55.337876,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.237062,
                                "longitude": 55.337813,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.237066,
                                "longitude": 55.337939,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.237178,
                                "longitude": 55.337835,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.237152,
                                "longitude": 55.337929,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.237123,
                                "longitude": 55.337889,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.23701,
                                "longitude": 55.337896,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.237136,
                                "longitude": 55.337823,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.237225,
                                "longitude": 55.337903,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.237074,
                                "longitude": 55.337878,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.237181,
                                "longitude": 55.337878,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.237107,
                                "longitude": 55.337812,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.23707,
                                "longitude": 55.337834,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.237213,
                                "longitude": 55.337889,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.237091,
                                "longitude": 55.337825,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.237066,
                                "longitude": 55.33793,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.237163,
                                "longitude": 55.337922,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.237159,
                                "longitude": 55.337842,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "13 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 31.53,
                        "conditions": {
                            "timestamp": "2023-12-21 06:11:43",
                            "moisture_avg": 21.19,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 81.79,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 13.6,
                            "moisture_max": 26.9,
                            "min_location": {
                                "lat": 25.240581,
                                "lng": 55.339278
                            },
                            "max_location": {
                                "lat": 25.240716,
                                "lng": 55.339322
                            },
                            "moisture_tpi": "↑52",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_13.png",
                            "img_extent": {
                                "min_lng": 55.339176,
                                "max_lng": 55.339378,
                                "min_lat": 25.240445,
                                "max_lat": 25.240745
                            },
                            "approach_marker": {
                                "lat": 25.240442,
                                "lng": 55.339209
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.240693,
                                "longitude": 55.339253,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.240504,
                                "longitude": 55.33922,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.240579,
                                "longitude": 55.339323,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.24062,
                                "longitude": 55.339222,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.240503,
                                "longitude": 55.339318,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.24068,
                                "longitude": 55.339341,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.240647,
                                "longitude": 55.33931,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.240606,
                                "longitude": 55.339273,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.240705,
                                "longitude": 55.339262,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.240548,
                                "longitude": 55.339286,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.240586,
                                "longitude": 55.339206,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.2405,
                                "longitude": 55.339231,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.240567,
                                "longitude": 55.339255,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.240677,
                                "longitude": 55.339251,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.24053,
                                "longitude": 55.339294,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.240595,
                                "longitude": 55.33923,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.240625,
                                "longitude": 55.339322,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.240517,
                                "longitude": 55.339249,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "14 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 24.38,
                        "conditions": {
                            "timestamp": "2023-12-21 05:56:44",
                            "moisture_avg": 20.69,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 84.9,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 14,
                            "moisture_max": 27.6,
                            "min_location": {
                                "lat": 25.241423,
                                "lng": 55.338986
                            },
                            "max_location": {
                                "lat": 25.241317,
                                "lng": 55.339
                            },
                            "moisture_tpi": "↑57",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_14.png",
                            "img_extent": {
                                "min_lng": 55.338793,
                                "max_lng": 55.339075,
                                "min_lat": 25.241274,
                                "max_lat": 25.241492
                            },
                            "approach_marker": {
                                "lat": 25.241295,
                                "lng": 55.338831
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.241455,
                                "longitude": 55.338969,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.241366,
                                "longitude": 55.338818,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.241417,
                                "longitude": 55.3389,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.241381,
                                "longitude": 55.338924,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.24141,
                                "longitude": 55.339033,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.241306,
                                "longitude": 55.338888,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.241386,
                                "longitude": 55.339023,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.241346,
                                "longitude": 55.338978,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.241462,
                                "longitude": 55.338964,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.241361,
                                "longitude": 55.338866,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.241419,
                                "longitude": 55.338966,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.241408,
                                "longitude": 55.338872,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.241354,
                                "longitude": 55.338922,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.241456,
                                "longitude": 55.338974,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.241343,
                                "longitude": 55.338874,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.241392,
                                "longitude": 55.338994,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.241414,
                                "longitude": 55.33888,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.24135,
                                "longitude": 55.338948,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "15 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 28.79,
                        "conditions": {
                            "timestamp": "2023-12-21 06:52:54",
                            "moisture_avg": 21.81,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 82.92,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 17.2,
                            "moisture_max": 27.2,
                            "min_location": {
                                "lat": 25.237251,
                                "lng": 55.338965
                            },
                            "max_location": {
                                "lat": 25.23739,
                                "lng": 55.339115
                            },
                            "moisture_tpi": "↑48",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_15.png",
                            "img_extent": {
                                "min_lng": 55.338898,
                                "max_lng": 55.339211,
                                "min_lat": 25.237177,
                                "max_lat": 25.237412
                            },
                            "approach_marker": {
                                "lat": 25.237389,
                                "lng": 55.339154
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.237371,
                                "longitude": 55.339121,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.23725,
                                "longitude": 55.33895,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.237284,
                                "longitude": 55.339147,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.237337,
                                "longitude": 55.339064,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.237288,
                                "longitude": 55.339056,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.237221,
                                "longitude": 55.339058,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.237261,
                                "longitude": 55.339134,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.237303,
                                "longitude": 55.339007,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.23736,
                                "longitude": 55.339145,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.23726,
                                "longitude": 55.339009,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.23732,
                                "longitude": 55.339117,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.237268,
                                "longitude": 55.338956,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.237227,
                                "longitude": 55.338956,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.237257,
                                "longitude": 55.338962,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.237339,
                                "longitude": 55.339115,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.237309,
                                "longitude": 55.339043,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.23727,
                                "longitude": 55.339125,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.23722,
                                "longitude": 55.339001,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "16 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 30.61,
                        "conditions": {
                            "timestamp": "2023-12-21 06:44:41",
                            "moisture_avg": 21.02,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 87.39,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 17.5,
                            "moisture_max": 26.5,
                            "min_location": {
                                "lat": 25.235936,
                                "lng": 55.338195
                            },
                            "max_location": {
                                "lat": 25.235927,
                                "lng": 55.338349
                            },
                            "moisture_tpi": "↑56",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_16.png",
                            "img_extent": {
                                "min_lng": 55.338114,
                                "max_lng": 55.33844,
                                "min_lat": 25.23584,
                                "max_lat": 25.236027
                            },
                            "approach_marker": {
                                "lat": 25.236029,
                                "lng": 55.338266
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.235977,
                                "longitude": 55.338192,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.235987,
                                "longitude": 55.338354,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.235908,
                                "longitude": 55.338174,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.235947,
                                "longitude": 55.338261,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.235929,
                                "longitude": 55.338371,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.235873,
                                "longitude": 55.338254,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.23596,
                                "longitude": 55.338153,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.23599,
                                "longitude": 55.338282,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.235984,
                                "longitude": 55.338212,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.235914,
                                "longitude": 55.338314,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.235879,
                                "longitude": 55.338214,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.235954,
                                "longitude": 55.338209,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.235988,
                                "longitude": 55.338379,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.235972,
                                "longitude": 55.338187,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.235974,
                                "longitude": 55.338312,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.23593,
                                "longitude": 55.338175,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.23595,
                                "longitude": 55.338261,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.235947,
                                "longitude": 55.338368,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "17 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 21.79,
                        "conditions": {
                            "timestamp": "2023-12-21 07:13:34",
                            "moisture_avg": 21.02,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 80.56,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 12.8,
                            "moisture_max": 26.6,
                            "min_location": {
                                "lat": 25.237398,
                                "lng": 55.336093
                            },
                            "max_location": {
                                "lat": 25.237377,
                                "lng": 55.336028
                            },
                            "moisture_tpi": "↑52",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_17.png",
                            "img_extent": {
                                "min_lng": 55.335855,
                                "max_lng": 55.336146,
                                "min_lat": 25.237327,
                                "max_lat": 25.237523
                            },
                            "approach_marker": {
                                "lat": 25.237371,
                                "lng": 55.336086
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.237487,
                                "longitude": 55.335932,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.237417,
                                "longitude": 55.336072,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.237394,
                                "longitude": 55.335913,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.237442,
                                "longitude": 55.336007,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.237388,
                                "longitude": 55.336006,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.237464,
                                "longitude": 55.335931,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.237379,
                                "longitude": 55.336066,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.237493,
                                "longitude": 55.336012,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.237354,
                                "longitude": 55.335951,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.237438,
                                "longitude": 55.335967,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.237465,
                                "longitude": 55.336066,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.237423,
                                "longitude": 55.335961,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.237416,
                                "longitude": 55.335987,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.237419,
                                "longitude": 55.336074,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.237498,
                                "longitude": 55.335958,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.237424,
                                "longitude": 55.3359,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.237391,
                                "longitude": 55.336038,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.237473,
                                "longitude": 55.336005,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "18 Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 35.5,
                        "conditions": {
                            "timestamp": "2023-12-21 07:20:08",
                            "moisture_avg": 19.36,
                            "moisture_avg_color": "#4EC3DA",
                            "moisture_avg_rating": "High",
                            "moisture_du": 76.88,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 11.3,
                            "moisture_max": 26.4,
                            "min_location": {
                                "lat": 25.24053,
                                "lng": 55.333434
                            },
                            "max_location": {
                                "lat": 25.240664,
                                "lng": 55.333587
                            },
                            "moisture_tpi": "↑60",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_18.png",
                            "img_extent": {
                                "min_lng": 55.333418,
                                "max_lng": 55.333666,
                                "min_lat": 25.240377,
                                "max_lat": 25.240695
                            },
                            "approach_marker": {
                                "lat": 25.240382,
                                "lng": 55.333577
                            }
                        },
                        "pins": [
                            {
                                "latitude": 25.240612,
                                "longitude": 55.3335,
                                "timestamp": "2023-11-27"
                            },
                            {
                                "latitude": 25.240457,
                                "longitude": 55.333586,
                                "timestamp": "2023-11-28"
                            },
                            {
                                "latitude": 25.240503,
                                "longitude": 55.33346,
                                "timestamp": "2023-11-29"
                            },
                            {
                                "latitude": 25.240575,
                                "longitude": 55.333567,
                                "timestamp": "2023-11-30"
                            },
                            {
                                "latitude": 25.240539,
                                "longitude": 55.333544,
                                "timestamp": "2023-12-01"
                            },
                            {
                                "latitude": 25.240586,
                                "longitude": 55.333458,
                                "timestamp": "2023-12-02"
                            },
                            {
                                "latitude": 25.24043,
                                "longitude": 55.333516,
                                "timestamp": "2023-12-03"
                            },
                            {
                                "latitude": 25.240514,
                                "longitude": 55.333517,
                                "timestamp": "2023-12-04"
                            },
                            {
                                "latitude": 25.240636,
                                "longitude": 55.333533,
                                "timestamp": "2023-12-05"
                            },
                            {
                                "latitude": 25.240431,
                                "longitude": 55.333601,
                                "timestamp": "2023-12-06"
                            },
                            {
                                "latitude": 25.240575,
                                "longitude": 55.333511,
                                "timestamp": "2023-12-07"
                            },
                            {
                                "latitude": 25.240536,
                                "longitude": 55.333584,
                                "timestamp": "2023-12-08"
                            },
                            {
                                "latitude": 25.240486,
                                "longitude": 55.333567,
                                "timestamp": "2023-12-09"
                            },
                            {
                                "latitude": 25.240527,
                                "longitude": 55.333489,
                                "timestamp": "2023-12-10"
                            },
                            {
                                "latitude": 25.240467,
                                "longitude": 55.333499,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.240424,
                                "longitude": 55.333508,
                                "timestamp": "2023-12-11"
                            },
                            {
                                "latitude": 25.2406,
                                "longitude": 55.333556,
                                "timestamp": "2023-12-12"
                            },
                            {
                                "latitude": 25.240539,
                                "longitude": 55.333542,
                                "timestamp": "2023-12-13"
                            },
                            {
                                "latitude": 25.24043,
                                "longitude": 55.333559,
                                "timestamp": "2023-12-14"
                            }
                        ]
                    },
                    {
                        "zone_name": "Nursery Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 34.14,
                        "conditions": {
                            "timestamp": "2023-11-10 09:44:49",
                            "moisture_avg": 59.24,
                            "moisture_avg_color": "#1C5195",
                            "moisture_avg_rating": "Critically High",
                            "moisture_du": 96.73,
                            "moisture_du_color": "#2D882D",
                            "moisture_min": 57.3,
                            "moisture_max": 62.6,
                            "min_location": {
                                "lat": 0,
                                "lng": 0
                            },
                            "max_location": {
                                "lat": 0,
                                "lng": 0
                            },
                            "moisture_tpi": "↑0",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_nursery.png",
                            "img_extent": {
                                "min_lng": 55.336969,
                                "max_lng": 55.337275,
                                "min_lat": 25.243454,
                                "max_lat": 25.243761
                            },
                            "approach_marker": null
                        },
                        "pins": []
                    },
                    {
                        "zone_name": "Putting Green",
                        "zone_type_name": "Green",
                        "zone_depth_meters": 45.15,
                        "conditions": {
                            "timestamp": "2023-12-21 05:22:26",
                            "moisture_avg": 18.51,
                            "moisture_avg_color": "#4EC3DA",
                            "moisture_avg_rating": "High",
                            "moisture_du": 83.26,
                            "moisture_du_color": "#C2AE96",
                            "moisture_min": 14.7,
                            "moisture_max": 23.9,
                            "min_location": {
                                "lat": 25.242947,
                                "lng": 55.334021
                            },
                            "max_location": {
                                "lat": 25.242899,
                                "lng": 55.334145
                            },
                            "moisture_tpi": "↑69",
                            "moisture_tpi_color": "#AF4440",
                            "moisture_img": "../../../assets/images/pogo/green_putting.png",
                            "img_extent": {
                                "min_lng": 55.333985,
                                "max_lng": 55.334204,
                                "min_lat": 25.242608,
                                "max_lat": 25.243014
                            },
                            "approach_marker": null
                        },
                        "pins": []
                    }
                ]
            }
        )
    }

}
