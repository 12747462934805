import { GenericMqttMessage } from '../generic-mqtt-message.model';
import { GetCoursesContent } from './get-courses-content.model';

export class GetCoursesMessage extends GenericMqttMessage {

    constructor(json: any = null) {
        super(null);

        if (json) {
            Object.assign(this, json);

            if (json.content) this.content = new GetCoursesContent({...json.content, installationId: json.installationId});
        }
    }

    content: GetCoursesContent;

}
