import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.less']
})
export class DatePickerComponent implements OnInit {
    @ViewChild('tdpCalendar') tdpCalendar;

    @Output() dateChange = new EventEmitter<Date>();

    private _date: Date;
    @Input() set date(value: Date) {
        if (this._date === value) { return; }

        this._date = value;
        this.setDateLabel();
        this.dateChange.emit(value);
    }

    get date(): Date {
        return this._date;
    }

    dateString = '';
    languageSettings: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService,
                private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.setComponentData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onPreviousDay() {
        this.date = moment(this.date).subtract('1', 'days').toDate();
    }

    onNextDay() {
        this.date = moment(this.date).add('1', 'days').toDate();
    }

    onShowCalendar() {
        this.tdpCalendar.toggle();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDateLabel() {
        this.dateString = moment(this.date || new Date(), null, this.userFormatService.language).format('dddd, MMMM D, YYYY');
    }

    private setComponentData() {
        this.languageSettings = {
            firstDayOfWeek: 0,
            dayNames: this.translateService.instant('CALENDAR.DAY_NAMES'),
            dayNamesShort: this.translateService.instant('CALENDAR.DAY_NAMES_SHORT'),
            dayNamesMin: this.translateService.instant('CALENDAR.DAY_NAMES_MIN'),
            monthNames: this.translateService.instant('CALENDAR.MONTH_NAMES'),
            monthNamesShort: this.translateService.instant('CALENDAR.MONTH_NAMES_SHORT'),
            today: this.translateService.instant('STRINGS.TODAY'),
            clear: this.translateService.instant('STRINGS.CLEAR'),
            dateFormat: this.userFormatService.dateFormat,
            weekHeader: this.translateService.instant('CALENDAR.WEAK_HEADER')
        };

        this.setDateLabel();
    }
}
