export class MyTurfCodeLoginInfo {

    constructor(myTurfAuthCode: string, intelliDashRefreshToken: string) {
        this.refreshToken = intelliDashRefreshToken;
        this.myTurfCode = myTurfAuthCode;
    }

    grantType = 'myturf_code';
    refreshToken: string;
    myTurfCode: string;

}
