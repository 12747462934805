
export class SoilScoutRuleCondition {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    measurement_type: string;
    low_limit: number;
    high_limit: number;
    inside_limits: boolean

}
