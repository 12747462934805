
export class YouTubeVideoInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    videoId: string;
    title: string;
    description: string;
}
