import { LynxStation } from './lynx-station.model';

export class LynxHole {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.stations) {
                this.stations = json.stations.map(station => new LynxStation(station)).sort((a, b) => +a.name - +b.name);
            }
        }
    }

    id: number;
    areaId: number;
    name: string;
    number: number;
    stations: LynxStation[];

    get holeStationsCount(): number {
        return this.stations.length;
    }

}
