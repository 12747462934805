import * as moment from 'moment';

export class TurfCloudCourseTrakk {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
        }
    }

    lastUpdated: Date;
    employees: number;
    investedInCurrentDay: number;
    investedInCurrentMonth: number;
    investedInCurrentYear: number;

}
