export class DashApiRefreshTokenLoginInfo {

    constructor(refreshToken: string) {
        this.refreshToken = refreshToken;
    }

    grantType = 'refresh_token';
    refreshToken: string;

}
