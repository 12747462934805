export class PogoMarkerPixelLocation {
    // 15px Font-size
    // private readonly iconOffsetLeft = 7.7;
    // private readonly iconOffsetTop = 9.5;

    // 17px Font-size
    private readonly iconOffsetLeft = 8.7;
    private readonly iconOffsetTop = 11;



    constructor(left: number, top: number) {
        this.left = `${left}px`;
        this.top = `${top}px`;

        this.leftIndicator = `${left - this.iconOffsetLeft}px`;
        this.topIndicator = `${top - this.iconOffsetTop}px`;
    }

    left: string;
    top: string;

    // Used to show Min/Max indicator on Map.
    leftIndicator: string;
    topIndicator: string;
}
