
export class GoogleMapLocation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    lat: number;
    lng: number;

}
