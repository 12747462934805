export class MyTurfUnavailableAssets {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    assetCode: string;
    description: string;
    locationName: string;
    locationId: number;

}
