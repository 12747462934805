import * as moment from 'moment';
import { TurfCloudLaborStat } from './turf-cloud-labor-stat.model';

export class TurfCloudLaborStatistics {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.items) this.items = json.items.map(r => new TurfCloudLaborStat(r));
            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
        }
    }

    lastUpdated: Date;
    items: TurfCloudLaborStat[];
}
