import { CalAmpAvlEvent } from '../api/cal-amp/models/cal-amp-avl-event.model';
import { CalAmpMotionLogEvent } from '../api/cal-amp/models/cal-amp-motion-log-event.model';
import { DemoDevice4676020927 } from './demo-devices/demo-device-4676020927';
import { DemoDevice4676020931 } from './demo-devices/demo-device-4676020931';
import { DemoDevice4676020933 } from './demo-devices/demo-device-4676020933';
import { DemoDevice4676020934 } from './demo-devices/demo-device-4676020934';
import { DemoDevice4676020935 } from './demo-devices/demo-device-4676020935';
import { DemoDevice4676020937 } from './demo-devices/demo-device-4676020937';
import { DemoDevice4676020939 } from './demo-devices/demo-device-4676020939';
import { DemoDevice4676020940 } from './demo-devices/demo-device-4676020940';
import { DemoDevice4676020943 } from './demo-devices/demo-device-4676020943';
import { DemoDevice4676020953 } from './demo-devices/demo-device-4676020953';
import { DemoDevice4676020954 } from './demo-devices/demo-device-4676020954';
import { DemoDevice4676020956 } from './demo-devices/demo-device-4676020956';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DemoModeAssetTrackingDataService {

    public static getMotionLogEventsForToday(deviceEsn: number): CalAmpMotionLogEvent[] {
        switch (deviceEsn) {
            case 4676020939: return DemoDevice4676020939.motionLogEvents;
            case 4676020935: return DemoDevice4676020935.motionLogEvents;
            case 4676020956: return DemoDevice4676020956.motionLogEvents;
            case 4676020953: return DemoDevice4676020953.motionLogEvents;
            case 4676020933: return DemoDevice4676020933.motionLogEvents;
            case 4676020940: return DemoDevice4676020940.motionLogEvents;
            case 4676020954: return DemoDevice4676020954.motionLogEvents;
            case 4676020934: return DemoDevice4676020934.motionLogEvents;
            case 4676020927: return DemoDevice4676020927.motionLogEvents;
            case 4676020943: return DemoDevice4676020943.motionLogEvents;
            case 4676020931: return DemoDevice4676020931.motionLogEvents;
            case 4676020937: return DemoDevice4676020937.motionLogEvents;
        }
    }

    public static getLatestAvlEventsForDevice(deviceEsn: number): CalAmpAvlEvent[] {
        switch (deviceEsn) {
            case 4676020939: return DemoDevice4676020939.avlEvents;
            case 4676020935: return DemoDevice4676020935.avlEvents;
            case 4676020956: return DemoDevice4676020956.avlEvents;
            case 4676020953: return DemoDevice4676020953.avlEvents;
            case 4676020933: return DemoDevice4676020933.avlEvents;
            case 4676020940: return DemoDevice4676020940.avlEvents;
            case 4676020954: return DemoDevice4676020954.avlEvents;
            case 4676020934: return DemoDevice4676020934.avlEvents;
            case 4676020927: return DemoDevice4676020927.avlEvents;
            case 4676020943: return DemoDevice4676020943.avlEvents;
            case 4676020931: return DemoDevice4676020931.avlEvents;
            case 4676020937: return DemoDevice4676020937.avlEvents;
        }
    }

}
