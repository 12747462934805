import * as Highcharts from 'highcharts';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxPressureConfig } from '../../../../../api/lynx/models/lynx-pressure-config.model';
import { LynxPumpStation } from '../../../../../api/lynx/models/lynx-pump-station.model';
import { LynxWidgetBase } from './_lynx-widget-base';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import GaugeColor = ToroEnums.GaugeColor;

const More = require('highcharts/highcharts-more');
More(Highcharts);

enum PumpGauge {
    Pressure,
    Flow
}

@UntilDestroy()
@Component({
    selector: 'toro-widget-lynx-pump-station',
    templateUrl: './widget-lynx-pump-station.component.html',
    styleUrls: ['./widget-lynx-pump-station.component.less']
})
export class WidgetLynxPumpStationComponent extends LynxWidgetBase implements OnInit {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    iconColor = 'goldenrod';
    title = 'WIDGET.LYNX_PUMP_STATION';

    pumpStation: LynxPumpStation;

    Highcharts = Highcharts;
    pressureChartOptions: any = null;
    pressureChart: Highcharts.Chart;
    flowChartOptions: any = null;
    flowChart: Highcharts.Chart;
    isPressureChartLoaded = false;
    isFlowChartLoaded = false;
    showInfoDialog = false;

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setPumpGaugeValues();
                this.setFlowGaugeValues();
            });

        this.setupChart();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowInfo() {
        this.showInfoDialog = true;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        throw new Error('Not Implemented');
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.lynxManager.getPumpStation()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((pumpStationData: LynxPumpStation) => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = pumpStationData.lastUpdated;
                this.pumpStation = pumpStationData;
                this.setPumpGaugeValues();
                this.setFlowGaugeValues();

            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setPumpGaugeValues() {
        // Gauge Value
        this.pressureChart.series[0].points[0].update(this.userFormatService.pressure(this.pumpStation.pressure));

        // Calculate plot bands.
        const min = <number>this.userFormatService.pressure(this.pumpStation.pressureConfig.pumpGaugeRanges.min);
        const midMin = <number>this.userFormatService.pressure(this.pumpStation.pressureConfig.pumpGaugeRanges.midMin);
        const midMax = <number>this.userFormatService.pressure(this.pumpStation.pressureConfig.pumpGaugeRanges.midMax);
        const max = <number>this.userFormatService.pressure(this.pumpStation.pressureConfig.pumpGaugeRanges.max);
        const title = {
            useHTML: true,
            text: '<span style="font-size: 14px;" >' + this.userFormatService.pressureUnits + '</span>',
            y: 17
        };

        // Gauge Range
        this.pressureChart.yAxis[0].update({ min, max, title });

        this.updatePlotBand(this.pressureChart, 'low', min, midMin, GaugeColor.Low);
        this.updatePlotBand(this.pressureChart, 'mid', midMin, midMax, GaugeColor.Mid);
        this.updatePlotBand(this.pressureChart, 'high', midMax, max, GaugeColor.High);

        this.pressureChart.reflow();
        this.isPressureChartLoaded = true;
    }

    private setFlowGaugeValues() {
        // Gauge Value
        this.flowChart.series[0].points[0].update(this.userFormatService.flow(this.pumpStation.flow));

        // Calculate plot bands.
        const min = <number>this.userFormatService.flow(this.pumpStation.flowConfig.minimum);
        const midMax = <number>this.userFormatService.flow(this.pumpStation.flowConfig.pumpGaugeRanges.midMax);
        const max = <number>this.userFormatService.flow(this.pumpStation.flowConfig.pumpGaugeRanges.max);
        const title = {
            useHTML: true,
            text: '<span style="font-size: 14px;" >' + this.userFormatService.flowUnits + '</span>',
            y: 17
        };

        // Gauge Range
        this.flowChart.yAxis[0].update({ min, max, title });

        this.updatePlotBand(this.flowChart, 'low', min, midMax, GaugeColor.Low);
        this.updatePlotBand(this.flowChart, 'high', midMax, max, GaugeColor.High);

        this.flowChart.reflow();
        this.isFlowChartLoaded = true;
    }

    private updatePlotBand(chart: Highcharts.Chart, id: string, from: number, to: number, color: string) {
        chart.yAxis[0].removePlotBand(id);
        chart.yAxis[0].addPlotBand({ id, borderColor: '#666', borderWidth: 1, thickness: 27, from, to, color });
    }

    private setupChart() {
        this.pressureChartOptions = this.getChartOptions(PumpGauge.Pressure);
        this.flowChartOptions = this.getChartOptions(PumpGauge.Flow);
    }

    private getChartOptions(pumpGauge: PumpGauge) {
        const self = this;
        const seriesName = pumpGauge === PumpGauge.Pressure ? 'pressure' : 'flow';
        const units = pumpGauge === PumpGauge.Pressure ? this.userFormatService.pressureUnits : this.userFormatService.flowUnits;

        return {
            chart: {
                type: 'gauge',
                spacing: [0, 5, 20, 5],
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                events: {
                    load(event) {
                        if (pumpGauge === PumpGauge.Pressure) {
                            self.pressureChart = event.target;
                        } else {
                            self.flowChart = event.target;
                        }
                        setTimeout(() => {
                            event.target.reflow();
                        });
                    }
                }
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            pane: {
                startAngle: -130,
                endAngle: 130,
                size: '100%',
                background: [{
                    backgroundColor: null,
                    borderWidth: 0,
                    outerRadius: '100%',
                }]
            },
            yAxis: [{						// Outer label Axis
                min: LynxPressureConfig.DEFAULT_MINIMUM,
                max: LynxPressureConfig.DEFAULT_MAXIMUM,

                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 5,
                minorTickPosition: 'inside',
                minorTickColor: '#000',

                tickPixelInterval: 30,
                tickWidth: 1,
                tickPosition: 'inside',
                tickLength: 12,
                tickColor: '#000',

                labels: {
                    enabled: false,
                },
                title: {
                    useHTML: true,
                    text: '<span style="font-size: 14px;" >' + units + '</span>',
                    y: 17
                }
            }],
            series: [{
                name: seriesName,
                data: [10],
                dataLabels: {
                    formatter() {
                        return '<span style="font-size: 14px;" >' + self.userFormatService.toUserNumber(this.y) + '</span>';
                    },
                    borderWidth: 0,
                    y: 15,
                },
                dial: {
                    radius: '90%',
                },
            }],

            tooltip: {
                enabled: false
            }
        };

    }

}
