export class PwObsPrecipitation {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    unit: string;
    oneHour = 0;
    fromMidnight = 0;
    sevenDays = 0;
    thirtyDays = 0;
}
