<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-frost-warning-widget-content toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tfw-content" class="toro-mini-mode-content">

                        <div id="tfw-icon-content" class="tfw-content-row">
                            <i class="tmm-ul-icon toro-id-tg-temperature"></i>
                        </div>

                        <div id="tfw-value-content" class="tfw-content-row">
                            <div id="tfw-value-content-container">
                                <div id="tfw-current-value-label">{{currentCourseForecastTime | uppercase}}</div>
                                <div id="tfw-current-value"><span>{{this.currentTemp || '-'}}</span><span>°{{this.chartValueUnits}}</span></div>
                            </div>
                        </div>

                        <div id="tfw-warning-content" class="tfw-content-row">
                            <div class="tmm-bot-left-container tmm-ellipsis-text">
                                <fa-icon icon="info-circle"></fa-icon>
                                <span>{{(isFrosty ? 'STRINGS.FROST_WARNING_IMAGE' : 'STRINGS.NO_FROST_RISK') | translate}}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-frost-warning-widget-content toro-flex-child toro-component-shield-host">
            <div *ngIf="displayCols === 1" class="toro-widget-content-pane toro-size-one" [ngClass]="{'fade-on-resize': isResizing}">
                <div id="tfw-top-pane" class="toro-flex-child">
                    <img id="frost-image" [src]="riskImage" alt="{{'STRINGS.FROST_WARNING_IMAGE' | translate}}" >
                </div>
                <div id="tfw-bottom-pane" class="toro-flex-child" [ngClass]="language">
                    <div *ngIf="!isFrosty" id="tfw-no-frost-pane">{{ 'STRINGS.NO_FROST_RISK' | translate | titlecase }}</div>
                    <div *ngIf="isFrosty" id="tfw-frost-pane">
                        <span id="tfw-frost-end-time">{{frostRiskEndTime}}</span>
                        <span>{{'STRINGS.FROST_RISK_ENDS' | translate | titlecase}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="displayCols === 2" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                <ng-container [ngTemplateOutlet]="frostRiskChart"></ng-container>
            </div>

            <toro-component-shield *ngIf="displayCols === 1"></toro-component-shield>
        </div>
        <div *ngIf="isGridsterInMobileMode && displayCols === 1" class="toro-widget-link"><a (click)="showDetails()">{{ 'STRINGS.VIEW_CHART' | translate | titlecase }}</a></div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #frostRiskChart>
    <div #chartContainer id="frost-warning-chart-container" class="toro-flex-child">
        <highcharts-chart *ngIf="chartOptions"  [Highcharts]="Highcharts" [options]="chartOptions">
        </highcharts-chart>
    </div>
</ng-template>

<toro-frost-risk-chart-dlg *ngIf="frostRiskData && showChartDialog" [title]="title" (cancel)="showChartDialog = false;" (dialogReady)="onChartDialogReady()">
    <ng-container [ngTemplateOutlet]="frostRiskChart"></ng-container>
</toro-frost-risk-chart-dlg>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
