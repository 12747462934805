<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [showLinkButton]="true"
            (gotoSite)="onLynxCloudLinkClick();"
    >

        <div class="toro-lynx-station-statuses-widget-content toro-flex-child tmm-carousel-host">
            <div class="tmm-carousel-container" [ngClass]="{'single-item': lynxCourses?.length <= 1}">
                <p-carousel [value]="lynxCourses" [numVisible]="1" [numScroll]="1" contentClass="toro-carousel-content" dotsContainerClass="toro-dots-container" (onPage)="setCarouselPage($event)">
                    <ng-template let-course pTemplate="item">

                        <div class="toro-widget-content-pane toro-component-shield-host">
                            <ng-container *ngTemplateOutlet="miniModePanel; context: { courseName: course.name }"></ng-container>
                            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                        </div>

                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [showLinkButton]="true"
            (gotoSite)="onLynxCloudLinkClick();"
    >
        <div class="toro-lynx-station-statuses-widget-content">
            <ng-container [ngTemplateOutlet]="courseName"></ng-container>

            <div class="toro-widget-content-pane toro-size-one" [ngClass]="{'fade-on-resize': isResizing, 'no-click': !stationsCount}" style="flex-direction: column;">
                <div class="flex-host">
                    <ng-container [ngTemplateOutlet]="miniModePanel"></ng-container>
                    <ng-container *ngTemplateOutlet="statusesGrid; context: { stationStatusesList: stationStatusList }"></ng-container>

                    <div id="lss-see-more-btm" class="toro-component-shield-host" [ngClass]="{'is-disabled': stationStatusList == null || stationStatusList.length < 1}">
                        {{'STRINGS.SEE_MORE' | translate}}
                        <toro-component-shield (shieldClick)="onShowModal()" [ngClass]="{'prevent-select' : stationStatusList == null || stationStatusList.length < 1}"
                        ></toro-component-shield>
                    </div>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #courseName>
    <div class="tlrs-course-selector-container" *ngIf="lynxCourses && lynxCourses.length > 0" [ngClass]="{'fade-on-resize': isResizing}" style="width: 330px;">
        <span id="tlrs-course-label" class="prevent-select">{{'STRINGS.COURSE' | translate}}: </span>
        <p-dropdown
                *ngIf="lynxCourses.length > 1"
                [options]="lynxCoursesList"
                [disabled]="lynxCourses.length < 2"
                [tooltip]="'Select Course'"
                [(ngModel)]="selectedCourse"
                (onChange)="onCourseChange($event)"
        ></p-dropdown>
        <span *ngIf="lynxCourses.length === 1" class="prevent-select">{{lynxCourses[0].name}}</span>
    </div>
</ng-template>

<ng-template #miniModePanel let-courseName="courseName">
    <div class="toro-mini-mode-content-panel toro-component-shield-host" [ngClass]="{'two-rows': displayRows === 2}">
        <div class="toro-mini-mode-content">
            <i class="tmm-ul-icon toro-id-stations"></i>

            <div id="tlssw-mm-data-container">
                <div id="tlssw-mm-data-ran-under" class="tlssw-mm-datum-container">
                    <span class="tlssw-mm-datum-value-container"><fa-icon icon="tint"></fa-icon><div>{{ stationsRanUnderCount }}</div></span>
                    <span class="tmm-large-value-label">{{'STRINGS.RAN_UNDER' | translate}}</span>
                </div>
                <div id="tlssw-mm-data-running" class="tlssw-mm-datum-container">
                    <span class="tlssw-mm-datum-value-container"><fa-icon icon="tint"></fa-icon><div>{{ stationsRunningCount }}</div></span>
                    <span class="tmm-large-value-label">{{'STRINGS.RUNNING' | translate}}</span>
                </div>
            </div>

            <div class="tmm-bot-left-container"><div class="tmm-ellipsis-text">{{courseName}}</div></div>
        </div>

        <toro-component-shield></toro-component-shield>
    </div>
</ng-template>

<ng-template #statusesGrid let-stationStatusesList="stationStatusesList" let-useFilteredLayout="useFilteredLayout">
    <div id="lss-grid-container">
        <div id="lss-grid-wrapper">
            <toro-lynx-station-status-card
                    *ngFor="let station of stationStatusesList"
                    [stationInfo]="station"
                    [useFilteredLayout]="useFilteredLayout"
            ></toro-lynx-station-status-card>
            <toro-lynx-station-status-card
                    *ngIf="stationStatusesList == null || stationStatusesList.length < 1"
                    [stationInfo]="null"
                    [useFilteredLayout]="useFilteredLayout"
            ></toro-lynx-station-status-card>
        </div>
    </div>
</ng-template>

<ng-template #modalContent>
    <div class="lss-modal-content-container">
        <div class="lss-model-header-container">
            <div id="lss-filter">
                <div id="lss-filter-tabs" class="toro-mini-mode-content-panel">
                    <div id="lss-filter-ran-under" class="lss-filter-btn" [ngClass]="{'is-active': activeStatusFilter == LynxStationStatus.RanUnder}" (click)="onFilter(LynxStationStatus.RanUnder)">
                        <span class="prevent-select">{{'STRINGS.RAN_UNDER' | translate}} <span class="lss-btn-count">{{stationsRanUnderCount}}</span></span>
                    </div>
                    <div id="lss-filter-running" class="lss-filter-btn" [ngClass]="{'is-active': activeStatusFilter == LynxStationStatus.Running}" (click)="onFilter(LynxStationStatus.Running)">
                        <span class="prevent-select">{{'STRINGS.RUNNING' | translate}} <span class="lss-btn-count">{{stationsRunningCount }}</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div id="lss-modal-grid-header" class="prevent-select">
            <span>{{'STRINGS.AREA' | translate}}</span><span>{{'STRINGS.STATION' | translate}}</span>
        </div>
        <div id="lss-modal-grid-container">
            <ng-container *ngTemplateOutlet="statusesGrid; context: { stationStatusesList: filteredStationStatusList, useFilteredLayout: true }"></ng-container>
        </div>
    </div>
</ng-template>

<!-- Dialogs -->
<toro-station-statuses-dlg
        *ngIf="showDetailsDialog"
        [lastUpdated]="lastUpdateTimestamp"
        (closeDialog)="showDetailsDialog = false"
        (dataRefresh)="onDataRefresh()"
>
        <ng-container *ngTemplateOutlet="modalContent"></ng-container>

</toro-station-statuses-dlg>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="modalContent"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
