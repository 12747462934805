export class SensorThresholdsModel {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    siteId: number;
    userId: string;
    holeNumber: number;
    sensorNumber: number;
    soilSensorThreshold: number;
    canopyCoverThreshold?: number;
    rainThreshold?: number;
}
