/* eslint-disable @typescript-eslint/dot-notation */
import { TurfGuardSensorLimit } from './turf-guard-sensor-limit.model';

export class TurfGuardSensorLimits {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.Temperature) { this.temperature = new TurfGuardSensorLimit(json.Temperature); }
            if (json.Moisture) { this.moisture = new TurfGuardSensorLimit(json.Moisture); }
            if (json.Salinity) { this.salinity = new TurfGuardSensorLimit(json.Salinity); }
        }
    }

    temperature: TurfGuardSensorLimit;
    moisture: TurfGuardSensorLimit;
    salinity: TurfGuardSensorLimit;
}
