import { ToroEnums } from '../../../common/enumerations/toro.enums';
import PwChartDataType = ToroEnums.PwChartDataType;

export class PwChartSeries {

    constructor(dataType: PwChartDataType, startDate: Date) {
        this.dataType = dataType;
        this.startDate = startDate;
    }

    dataType: PwChartDataType;
    startDate: Date;
    values: number[] = [];
}
