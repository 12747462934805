import * as moment from 'moment';

export class LynxWeatherStationStatus {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.time) this.time = moment.utc(json.time).toDate();
        }
    }

    lastUpdated: Date;
    time: Date = null;
    dewPoint: number;
    et: number;
    precipitation: number;
    relativeHumidity: number;
    solarRadiation: number;
    temperature: number;                // Fahrenheit
    windDirection: string;
    windSpeed: number;                  // mph
}
