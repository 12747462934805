import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { LoginStateChange } from '../../api/auth/models/login-state-change';

@UntilDestroy()
@Component({
    selector: 'toro-welcome',
    templateUrl: './welcome.component.html',
})
export class WelcomeComponent implements OnInit {

    constructor(public authManager: AuthManagerService) { }

    ngOnInit(): void {
        this.authManager.dashLoginStateChange
            .pipe(untilDestroyed(this))
            .subscribe((state: LoginStateChange) => {
                if (!state.isLoggedIn) {
                    this.authManager.startOauthSignIn();
                }
            });
    }
}
