import { PlaybooksIngredient } from './playbooks.ingredient.model';

export class PlaybooksMonthlyData {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.ingredients) { this.ingredients = json.ingredients.map(i => new PlaybooksIngredient(i)); }
        }
    }

    areaName: string;
    ingredients: PlaybooksIngredient[];
}
