import * as moment from 'moment';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { Injectable } from '@angular/core';
import { ToroEnums } from '../enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import TemperatureUnits = ToroEnums.TemperatureUnits;
import UnitsSystem = ToroEnums.UnitsSystem;

@Injectable({
    providedIn: 'root'
})
export class UserFormatService {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private translateService: TranslateService,
    ) {

    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get isTempCelsius(): boolean {
        return this.userPreferences.temperatureUnits === TemperatureUnits.Celsius;
    }

    get dateFormat(): string {
        return this.userPreferences.dateFormat;
    }

    get language(): string {
        return this.userPreferences.language;
    }

    get isMetric(): boolean {
        return this.userPreferences.unitsSystem !== UnitsSystem.Imperial
    }

    temperature(degreesFahrenheit: number, toString = false, decimalPlaces = 0): string | number {
        const value = (this.userPreferences.temperatureUnits === TemperatureUnits.Fahrenheit)
            ? +degreesFahrenheit.toFixed(decimalPlaces) : +this.fahrenheitToCelsius(degreesFahrenheit).toFixed(decimalPlaces);

        return toString ? this.toUserNumber(value) : +value;
    }

    temperatureFromCelsius(degreesCelsius: number, toString = false, decimalPlaces = 0): string | number {
        const value = (this.userPreferences.temperatureUnits === TemperatureUnits.Celsius)
            ? +degreesCelsius.toFixed(decimalPlaces) : +this.CelsiusToFahrenheit(degreesCelsius).toFixed(decimalPlaces);

        return toString ? this.toUserNumber(value) : +value;
    }

    convertCelsiusToFahrenheit(degreesCelsius: number, toString = false, decimalPlaces = 0): string | number {
        const value = +this.CelsiusToFahrenheit(degreesCelsius).toFixed(decimalPlaces);

        return toString ? this.toUserNumber(value) : +value;
    }


    get temperatureUnits(): string {
        return (this.userPreferences.temperatureUnits === TemperatureUnits.Fahrenheit) ? 'F' : 'C';
    }

    dewPoint(degreesFahrenheit: number) {
        return (this.userPreferences.temperatureUnits === TemperatureUnits.Fahrenheit)
            ? +degreesFahrenheit.toFixed(0) : +this.fahrenheitToCelsius(degreesFahrenheit).toFixed(0);
    }

    get dewPointUnits(): string {
        return (this.userPreferences.temperatureUnits === TemperatureUnits.Fahrenheit) ? 'F' : 'C';
    }

    flow(gpm: number, toString = false): string | number {
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? gpm : +this.gallonsPerMinuteToLitersPerMinute(gpm).toFixed(2);
        return toString ? this.toUserNumber(value) : +value;
    }

    get flowUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.translate('UNITS.GPM') : this.translate('UNITS.LPM');
    }

    pressure(psi: number, toString = false): string | number {
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? psi : +this.psiToBar(psi).toFixed(2);
        return toString ? this.toUserNumber(value) : +value;
    }

    get pressureUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.translate('UNITS.PSI') : this.translate('UNITS.BAR');
    }

    rainfall(inches: number, toString = false): string | number {
        if (inches < 0) inches = 0;
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? +inches.toFixed(2) : +this.inchesToMillimeters(inches).toFixed(2);
        return toString ? this.toUserNumber(value) : +value;
    }

    get rainfallUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? '"' : 'mm';
    }

    get precipitationUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? 'in' : 'mm';
    }

    precipitationFromCm(cm: number, toString = false): string | number {
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? +this.cmToInches(cm).toFixed(2) : +cm.toFixed(2);
        return toString ? this.toUserNumber(value) : +value;
    }

    get precipitationFromCmUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? 'in' : 'cm';
    }

    solarRadiation(kwPerMeterSquared: number, toString = false): string | number {
        // NOTE: NOT CURRENTLY CONVERTING SOLAR RADIATION UNITS. I have no idea what the imperial equivalent is (or if it is relevant).

        // const value = (this.userPreferences.unitsSystem === UnitsSystem.Metric)
        //     ? kwPerMeterSquared
        //     : +this.kwPerMeterSquaredToBtuPerFootSquared(kwPerMeterSquared).toFixed(2);

        const value = kwPerMeterSquared;
        return toString ? this.toUserNumber(value) : +value;
    }

    get solarRadiationUnits(): string {
        // NOTE: NOT CURRENTLY CONVERTING SOLAR RADIATION UNITS. I have no idea what the imperial equivelent is (or if it is relevant).

        // return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.translate('UNITS.BTUH/F2') : this.translate('UNITS.KWH/M2');
        return this.translate('UNITS.KWH/M2');
    }

    salinity(dsm: number, toString = false, decimalPlaces = 0): string | number {
        // NOTE: dS/m -> mmho/dm is a 1:1 ratio. This method exists in case the unit conversion is other than this.
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? dsm : dsm;
        return toString ? this.toUserNumber(+value.toFixed(decimalPlaces)) : +value;
    }

    get salinityUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.translate('UNITS.DS/M') : this.translate('UNITS.MMHO/CM');
    }

    windSpeed(mph: number): number {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? +mph.toFixed(0) : +this.mphToKph(mph).toFixed(0);
    }

    windSpeedfromMps(mps: number): number {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? +this.mpsToMPH(mps).toFixed(0) : +this.mpsToKph(mps).toFixed(0);
    }

    get windSpeedUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.translate('UNITS.MPH') : this.translate('UNITS.KPH');
    }

    humidity(value: number): number {
        if (value < 0) value = 0;
        return +value.toFixed(1);
    }

    evapotranspiration(inches: number, bypassUserNumber = false): string {
        // Guard against negative values;
        if (inches < 0) { inches = 0; }

        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? inches : +this.inchesToMillimeters(inches).toFixed(2);
        return !bypassUserNumber ? this.toUserNumber(value) : value.toString();
    }

    get evapotranspirationUnits(): string {
        return (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? '"' : 'mm';
    }

    toUserDateString(date: Date) {
        return moment(date, 'LLL').format(this.userPreferences.dateFormat);
    }

    toUserTimeString(date: Date, includeSeconds = false): string {
        return !includeSeconds ? moment(date).format('h:mm a') : moment(date).format('h:mm:ss a');
    }

    toUserHourTimeString(date: Date): string {
        return moment(date).format('h a');
    }

    get unitsSystem(): ToroEnums.UnitsSystem {
        return this.userPreferences.unitsSystem;
    }

    get currencySymbol(): string {
        return '$';
    }

    toUserNumber(value: number, minFraction = 0, maxFraction = 2): string {
        if (minFraction === null) { minFraction = undefined; }
        if (maxFraction === null) { maxFraction = undefined; }

        return `${value.toLocaleString(this.userPreferences.groupAndDecimalSeparator, {
            maximumFractionDigits: maxFraction,
            minimumFractionDigits: minFraction
        })}`;
    }

    toUserCurrency(value: number, minFractionDigits = 2, maxFractionDigits = 2): string {
        // NOTE: Currency currently hard coded to USD since no monetary conversion is occurring in the system.
        // return value.toLocaleString(this.userPreferences.language, { style: 'currency', currency: 'USD', maximumFractionDigits: 2});
        return `${this.currencySymbol}${value.toLocaleString(this.userPreferences.groupAndDecimalSeparator, {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits
        })}`;
    }

    pwLightningRadii(kilometers: number): string {
        const value = (this.userPreferences.unitsSystem === UnitsSystem.Imperial) ? this.kilometersToMiles(kilometers) : kilometers;
        return this.toUserNumber(value);
    }

    pwLightingRadiiUnits() {
        return this.translateService.instant(this.userPreferences.unitsSystem === UnitsSystem.Imperial ? 'UNITS.MILES_ABBR' : 'UNITS.KILOMETERS_ABBR');
    }

    get locale(): string {
        return this.userPreferences.groupAndDecimalSeparator;
    }

    public millimetersToInches(mm: number): number {
        return +(mm / 25.4).toFixed(2);
    }

    public inchesToMillimeters(inches: number): number {
        return +(inches * 25.4).toFixed(2);
    }

    public gallonsToLitres(gallons: number): number {
        return +(gallons * 3.78541).toFixed(1);
    }

    public quartzToLitres(quartz: number): number {
        return +(quartz * 0.946353).toFixed(1);
    }

    public feetToMeters(feet: number): number {
        return +(feet * 0.3048).toFixed(1);
    }

    public kilometersToMiles(kilometers: number): number {
        return +(kilometers * .621371).toFixed(1);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private translate(str: string) {
        return this.translateService.instant(str);
    }

    private get userPreferences(): any {
        return this.authManager.dashAuthenticatedUser.userPreferences;
    }

    private fahrenheitToCelsius(degreesFahrenheit: number): number {
        return (degreesFahrenheit - 32) * 5 / 9;
    }

    private CelsiusToFahrenheit(degreesCelsius: number): number {
        return degreesCelsius * 9/5 + 32;
    }

    private gallonsPerMinuteToLitersPerMinute(gpm: number): number {
        return gpm * 3.785;
    }

    private mphToKph(mph: number): number {
        return mph * 1.60934;
    }

    private mpsToKph(mps: number): number {
        return mps * 3.6;
    }

    private mpsToMPH(mps: number): number {
        return mps * 2.23694;
    }

    private psiToBar(psi: number): number {
        return psi * .0689476;
    }

    public cmToInches(cm: number): number {
        return +(cm * .393701).toFixed(2);
    }

    // private kwPerMeterSquaredToBtuPerFootSquared(kwPerMeterSquared: number): number {
    //     return kwPerMeterSquared * 316.998;
    // }
}
