import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;

import * as Highcharts from 'highcharts';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { SoilScoutChartData } from '../models/soil-scout-chart-data.model';
import { SoilScoutDevice } from '../../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGroup } from '../../../../../api/soil-scout/models/soil-scout-group.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../../../common/services/user-format.service';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-bar-chart',
    templateUrl: './soil-scout-bar-chart.component.html',
    styleUrls: ['./soil-scout-bar-chart.component.less']
})
export class SoilScoutBarChartComponent implements OnInit, AfterViewInit {
    @HostBinding('class') class = 'toro-soil-scout-bar-chart';
    @ViewChild('ssChartContainer') ssChartContainer: ElementRef;

    @Output() sensorSelected = new EventEmitter<SoilScoutGroup | SoilScoutDevice>();

    private readonly CHART_BAR_HEIGHT = 36;
    private readonly CHART_BAR_PADDING = 10;    // DO NOT CHANGE THIS. This value is derived by the chart itself.
    private readonly CHART_COLUMN_HEIGHT = this.CHART_BAR_HEIGHT + this.CHART_BAR_PADDING;

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    isGridsterInMobileMode = false;

    private _chartData: SoilScoutChartData;
    @Input() set chartData(value: SoilScoutChartData) {
        this._chartData = value;
        this.scouts = [...value.groups.sort((a, b) => this.scoutNameSort(a.name, b.name)), ...value.devices.sort((a, b) => this.scoutNameSort(a.name, b.name))];

        this.setChartValues();
    }

    private _sensorType: SoilScoutSensorType;
    @Input() set sensorType(value: SoilScoutSensorType) {
        this._sensorType = value;
        this.setChartValues();
    }

    get sensorType(): SoilScoutSensorType {
        return this._sensorType;
    }

    get chartData(): SoilScoutChartData {
        return this._chartData;
    }

    private chartContainerHeight = 0;
    private chartContainerWidth = 0;
    private scouts: any[];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.deviceManager.gridsterMobileModeChange
            .pipe(untilDestroyed(this))
            .subscribe((state: { isMobileMode: boolean }) => {
                this.isGridsterInMobileMode = state.isMobileMode;
            });

        // Get the state of Gridster's mobile mode on component load.
        this.isGridsterInMobileMode = this.deviceManager.isGridsterInMobileMode;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.setWidthOfChartContainer();
            setTimeout(() => this.setupChart());
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected barClick(barIndex: any) {
        const scout = this.scouts[barIndex];
        if (scout != null) {
            this.sensorSelected.emit(scout);
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setChartValues() {
        if (!this.chart || !this.chart.yAxis) { return; }

        this.chart.update({
            chart: {
                scrollablePlotArea: {
                    minHeight: this.CHART_COLUMN_HEIGHT * this.scouts?.length
                }
            },
            xAxis: {
                categories: this.getScoutCategories(),
                plotBands: this.chartPlotBands,
            },
            series: [{
                type: 'bar',
                data: this.getScoutValues(),
            }]
        });
    }

    private setWidthOfChartContainer() {
        if (!this.ssChartContainer) return;

        this.chartContainerWidth = this.ssChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.ssChartContainer.nativeElement.offsetHeight - 2;
    }

    // onAlert(event: any) {
    //     alert("Yippee!");
    // }

    private setupChart() {
        const self = this;

        this.setWidthOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: self.chartContainerHeight,
                width: self.chartContainerWidth,
                events: {
                    load(event) {
                        self.chart = event.target;
                    },
                    render: function() {
                        // Add click event listeners to the xAxis labels
                        const labels = document.querySelectorAll('#ss-bar-label');

                        labels.forEach((label, index) => {
                            // Ensure we don't attach the event multiple times
                            if (!label.classList.contains('click-event-attached')) {
                                label.classList.add('click-event-attached');
                                label.addEventListener('click', () => {
                                    self.barClick(index);
                                });
                            }
                        });
                    }
                },
                scrollablePlotArea: {
                    minHeight: this.CHART_COLUMN_HEIGHT * this.scouts?.length
                }
            },
            title: { text: '' },
            legend: { enabled: false },
            credits: { enabled: false },
            xAxis: {
                categories: self.getScoutCategories(),
                labels: {
                    reserveSpace: true,
                    align: 'left',
                    useHTML: true,
                    formatter() {
                        return '<div id="ss-bar-label" class="' + (self.isGridsterInMobileMode ? 'is-mobile' : ' ') + '" >' +
                            '<i class="' + self.getDeviceIcon(this.pos) + '"></i>' +
                            '<i class="' + self.getStatusIcon(this.pos) + '"></i>' +
                            '<span id="ss-bar-text"">' + this.value + '</span>' +
                            '</div>';
                    }
                },
                tickLength: 0,
                lineColor: 'white',
                plotBands: self.chartPlotBands,
            },
            yAxis: {
                title: { enabled: false },
                labels: { enabled: false },
                tickAmount: 0,
            },
            plotOptions: {
                bar: {
                    cursor: 'pointer',
                    pointWidth: self.CHART_BAR_HEIGHT
                }
            },
            series: [{
                name: '',
                data: self.getScoutValues(),
                events: { click(event) { self.barClick(event.point.x); } },
                dataLabels: {
                    enabled: true,
                    inside: true,
                    align: 'left',
                    color: 'white',
                    formatter() {
                        return '<span id="ss-bar-value-label">' + self.getBarValueLabel(this.y) + '</span>';
                    },
                    useHTML: true,
                }
            }],
            tooltip: { enabled: false },
        };
    }

    private getDeviceIcon(index: number) {
        const scout = this.scouts[index];
        return (scout instanceof SoilScoutDevice) ? 'ss-id-soil-scout-device' : 'ss-id-soil-scout-group'
    }

    private getStatusIcon(index: number) {
        const scout = this.scouts[index];

        if (!(scout instanceof SoilScoutDevice)) return '';

        return (scout.device_status == 'OK') ? 'ss-id-soil-scout-checkmark' : 'ss-id-soil-scout-alert'
    }

    private get chartPlotBands(): { from: number, to: number, events: {} }[] {
        const bandHeight = 0.8;
        const bandGap = 0.2;
        let from = -0.4;
        let to = .4;
        const bands = [];
        let events = { click: () => this.barClick(0) }

        for (let i = 0; i < this.scouts.length; i++) {
            bands.push({ from, to, events });
            from = to + bandGap;
            to = from + bandHeight;
            events = { click: () => this.barClick(i + 1) }
        }

        return bands;
    }

    private getScoutCategories(): string[] {
        return this.scouts.map(s => s.name);
    }

    private getScoutValues(): any[] {
        let vals: any[] = [];
        let color = {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#365df5'], [1, '#9aaaff']]
        }

        switch (this._sensorType) {
            case SoilScoutSensorType.Moisture:
                vals = this.scouts.map(s => {
                    return {
                        y: s.last_measurement.moisture,
                        color: color
                    }
                });
                break;
            case SoilScoutSensorType.Temperature:
                vals = this.scouts.map(s => {
                    // NOTE: Values are provided from the API in Celsius
                    color.stops = [[0, '#ff50a2'], [1, '#ffc881']];
                    return {
                        y: <number>this.userFormatService.temperatureFromCelsius(s.last_measurement.temperature, false, 2),
                        color: color
                    }
                });
                break;
            case SoilScoutSensorType.Salinity:
                color.stops = [[0, '#8037ff'], [1, '#f192e4']];
                vals = this.scouts.map(s => {
                    return {
                        y: s.last_measurement.salinity,
                        color: color
                    }
                });
                break;
            case SoilScoutSensorType.WaterBalance:
                vals = this.scouts.map(s => {
                    return {
                        y: s.last_measurement.water_balance,
                        color: this.getWaterBalanceBarColor(s.last_measurement.water_balance)
                    }
                });
                break;
        }

        return vals;
    }

    private getWaterBalanceBarColor(val: number) {
        let color = {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#0bd465'], [1, '#0ac92a']]
        }

        if (val <= .22) { color.stops = [[0, '#fa4f2d'], [1, '#d44326']] } else if (val <= .45) { color.stops = [[0, '#fcdc0d'], [1, '#f2bc0c']] }

        return color;
    }

    private getBarValueLabel(value: number): string {
        switch (this._sensorType) {
            case SoilScoutSensorType.Moisture:
                return `${(value * 100).toFixed(2)} %`;
            case SoilScoutSensorType.Temperature:
                // NOTE: We've already converted the temperature values to Fahrenheit in getScoutValues.'value' will be in the correct units.
                return `${this.userFormatService.toUserNumber(value, 0, 2)} ${this.userFormatService.temperatureUnits}`;
            case SoilScoutSensorType.Salinity:
                if (value < 0.1) { return `<0.1 ${this.userFormatService.salinityUnits}`}
                return `${this.userFormatService.salinity(value, true, 2)} ${this.userFormatService.salinityUnits}`;
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                return `${this.userFormatService.toUserNumber(value, 2, 2)}`;
        }
    }

    private scoutNameSort(a: string, b: string): number {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    }
}
