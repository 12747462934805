import { TaskTrackerCostSummary } from './task-tracker-cost-summary.model';

export class TaskTrackerDailySummaryDepartment {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.costSummaries) { this.costSummaries = json.costSummaries.map(s => new TaskTrackerCostSummary(s)); }
        }
    }

    id: number;
    name: string;
    totalLaborDollars: number;
    totalHours: number;
    totalTaskCompleted: number;
    totalTaskOutstanding: number;
    scheduledEmployees: number;
    offEmployees: number;
    employeesOnWorkboard: number;
    costSummaries: TaskTrackerCostSummary[] = [];
}
