import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'toro-frost-risk-chart-dlg',
    templateUrl: './frost-risk-chart-dlg.component.html',
    styleUrls: ['./frost-risk-chart-dlg.component.less']
})
export class FrostRiskChartDlgComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() dialogReady = new EventEmitter();

    @Input() title = '';

    showChart = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        // Give a chance for the dialog to render before showing/sizing the p-table.
        setTimeout(() => {{
            this.showChart = true;
            this.dialogReady.emit();
        }});
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    close() {
        this.cancel.emit();
    }

}
