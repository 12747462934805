import { SoilScoutRuleAction } from './soil-scout-rule-action.model';
import { SoilScoutRuleCondition } from './soil-scout-rule-condition.model';

export class SoilScoutRule {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.conditions) this.conditions = json.conditions.map(c => new SoilScoutRuleCondition(c));
            if (json.actions) this.actions = json.actions.map(a => new SoilScoutRuleAction(a));
        }
    }

    id: number;
    name: string;
    group_id: number;
    site: number;
    enabled: boolean;
    retrigger: boolean;
    conditions: SoilScoutRuleCondition[] = [];
    actions: SoilScoutRuleAction[] = [];
}
