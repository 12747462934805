import { DashAuthenticatedUser } from '../../auth/models/dash-authenticated-user.model';
import { DashboardWidgetInfo } from './dashboard-widget-info.model';

export class UpdateDashboardWidgetInfo {

    constructor(dashUser: DashAuthenticatedUser, widgets: DashboardWidgetInfo[], deviceId = 'desktop') {
        this.userId = dashUser.sid;
        this.siteId = dashUser.siteId;
        this.deviceId = deviceId;
        this.widgets = widgets.map(w => {
            return {...w, config: JSON.stringify(w.config)};
        });
    }

    userId: string;
    siteId: number;
    deviceId: string;
    widgets: DashboardWidgetInfo[] = [];
}
