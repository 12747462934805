import { GenericMqttMessageContent } from '../generic-mqtt-message-content.model';
import { GetProgramTopoArea } from './get-program-topo-area.model';

export class GetProgramTopoContent extends GenericMqttMessageContent  {

    constructor(json: any = null) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.result) this.result = json.result.map(c => new GetProgramTopoArea(c));
        }
    }

    result: GetProgramTopoArea[];
}
