import { ToroEnums } from '../../enumerations/toro.enums';
import WeatherGraphType = ToroEnums.WeatherGraphType;

export class GraphAxisExtentModel {

    constructor(graphType: WeatherGraphType, min: number, max: number) {
        this.graphType = graphType;
        if (min != null) { this.min = min; }
        if (max != null) { this.max = max; }
    }

    graphType: WeatherGraphType;
    min: number = undefined;
    max: number = undefined;

}
