import { GreensListEntry } from './greens-list-entry.model';

export class CourseListEntry {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.greens) this.greens = json.greens.map(g => new GreensListEntry(g));
        }
    }

    courseAccessId: string;
    courseName: string;
    greens: GreensListEntry[];
}
