import * as moment from 'moment';

export class SoilScoutNotificationMeasurement {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.measurement_timestamp) this.measurement_timestamp = moment.utc(json.measurement_timestamp).toDate();
            if (json.valid_timestamp) this.valid_timestamp = moment.utc(json.valid_timestamp).toDate();
        }
    }

    measurement_timestamp: Date = null;
    device_id: number;
    valid_timestamp: Date = null;
}
