import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DailyPinSheet } from '../../../../../api/ez-locator/models/daily-pin-sheet.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-ez-locator-pin-placement-summary',
    templateUrl: './ez-locator-pin-placement-summary.component.html',
    styleUrls: ['./ez-locator-pin-placement-summary.component.less']
})
export class EzLocatorPinPlacementSummaryComponent implements OnInit {

    @Input() pinSheet: DailyPinSheet;

    leftCount = 0;
    rightCount = 0;
    frontCount = 0;
    middleCount = 0;
    backCount = 0;
    language = 'en-us';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService) { }

    ngOnInit(): void {
        this.leftCount = this.pinSheet.pinSheetEntries.reduce((sum, g) => sum += g.holeSide.includes('L') ? 1 : 0, 0);
        this.rightCount = this.pinSheet.pinSheetEntries.reduce((sum, g) => sum += g.holeSide.includes('R') ? 1 : 0, 0);
        this.frontCount = this.pinSheet.pinSheetEntries.reduce((sum, g) => sum += +g.holeCenter < 0 ? 1 : 0, 0);
        this.middleCount = this.pinSheet.pinSheetEntries.reduce((sum, g) => sum += +g.holeCenter === 0 ? 1 : 0, 0);
        this.backCount = this.pinSheet.pinSheetEntries.reduce((sum, g) => sum += +g.holeCenter > 0 ? 1 : 0, 0);

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);

        this.language = this.dashUserManager.language;
    }
}
