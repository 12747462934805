<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-weather-graph-widget-content">

            <div id="twg-station-data" class="toro-widget-content-pane toro-component-shield-host">

                <div class="toro-mini-mode-content-panel">

                    <div id="twg-data-container">
                        <div id="twg-wind-data" class="twg-datum-container">
                            <i class="toro-id-wind twg-datum-icon"></i>
                            <div class="twg-datum-value"><span class="tmm-iv-value">{{wind}}</span><span class="tmm-iv-value-label">{{windUnits}}</span></div>
                            <div class="tmm-bot-left-container">
                                <div class="tmm-ellipsis-text">{{'STRINGS.WIND' | translate}}</div>
                            </div>
                        </div>
                        <div id="twg-dew-point-data" class="twg-datum-container" [ngClass]="language">
                            <i class="toro-id-dew-point twg-datum-icon"></i>
                            <div class="twg-datum-value"><span class="tmm-iv-value">{{dewPoint}}</span><span class="tmm-iv-value-label">°{{dewPointUnits}}</span></div>
                            <div class="tmm-bot-left-container">
                                <div class="tmm-ellipsis-text">{{'STRINGS.DEW_POINT' | translate}}</div>
                            </div>
                        </div>
                        <div id="twg-precip-data" class="twg-datum-container" [ngClass]="language">
                            <fa-icon icon="tint"></fa-icon>
                            <div class="twg-datum-value"  [ngClass]="{'is-metric': isMetric}">
                                <span class="tmm-iv-value">{{precipitation}}</span>
                                <span class="tmm-iv-value-label">{{precipitationUnits}}</span>
                            </div>
                            <div class="tmm-bot-left-container">
                                <div class="tmm-ellipsis-text">{{'STRINGS.PRECIPITATION' | translate}}</div>
                            </div>
                        </div>
                        <div id="twg-humidity-data" class="twg-datum-container" [ngClass]="language">
                            <i class="toro-id-humidity twg-datum-icon"></i>
                            <div class="twg-datum-value"><span class="tmm-iv-value">{{humidity}}</span><span class="tmm-iv-value-label">{{'%'}}</span></div>
                            <div class="tmm-bot-left-container">
                                <div class="tmm-ellipsis-text">{{'STRINGS.HUMIDITY' | translate}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-weather-graph-widget-content">
            <div class="toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing}">
                <div *ngIf="weatherGraphData && !isResizing" id="twg-scroll-container" class="toro-absolute-child">
                    <toro-weather-graph
                            *ngFor="let g of weatherGraphs"
                            [height]="graphHeight"
                            [graphType]="g"
                            [site]="site"
                            [data]="weatherGraphData"
                            [language]="language"
                    ></toro-weather-graph>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
