import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;

import * as Highcharts from 'highcharts';
import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { MeasurementMinMax } from '../models/measurement-min-max.model';
import { SoilScoutCsvMeasurement } from '../../../../../api/soil-scout/models/soil-scout-csv-measurement.model';
import { SoilScoutDevice } from '../../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGroup } from '../../../../../api/soil-scout/models/soil-scout-group.model';
import { SoilScoutManagerService } from '../../../../../api/soil-scout/soil-scout-manager.service';
import { take } from 'rxjs/operators';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../../../common/services/user-format.service';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-line-chart',
    templateUrl: './soil-scout-line-chart.component.html',
    styleUrls: ['./soil-scout-line-chart.component.less']
})
export class SoilScoutLineChartComponent implements OnInit {
    @HostBinding('class') class = 'toro-soil-scout-line-chart';
    @ViewChild('ssChartContainer') ssChartContainer: ElementRef;

    @Input() devices: SoilScoutDevice[] = [];

    private _scout: SoilScoutGroup | SoilScoutDevice;
    @Input() set scout(value: SoilScoutGroup | SoilScoutDevice) {
        this._scout = value;

        this.getMeasurementsData();
    }

    get scout(): SoilScoutGroup | SoilScoutDevice {
        return this._scout;
    }

    private _selectedSensorType = SoilScoutSensorType.Moisture;
    @Input() set selectedSensorType(value: SoilScoutSensorType) {
        this._selectedSensorType = value;

        this.updateChart();
    }

    get selectedSensorType(): SoilScoutSensorType {
        return this._selectedSensorType;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    isGridsterInMobileMode = false;

    isBusy = false;
    measurements: SoilScoutCsvMeasurement[];
    measurementDictionary: { [deviceId: number]: SoilScoutCsvMeasurement[] } = {}

    private chartContainerHeight = 0;
    private chartContainerWidth = 0;
    private moistureMinMax = new MeasurementMinMax(0, 25);      // %
    private temperatureMinMax = new MeasurementMinMax(0, 5);    // Celsius
    private salinityMinMax = new MeasurementMinMax(0, 0.5);
    private waterBalanceMinMax = new MeasurementMinMax(0, 1);

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService,
                private soilScoutManager: SoilScoutManagerService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.deviceManager.gridsterMobileModeChange
            .pipe(untilDestroyed(this))
            .subscribe((state: { isMobileMode: boolean }) => {
                this.isGridsterInMobileMode = state.isMobileMode;
            });

        // Get the state of Gridster's mobile mode on component load.
        this.isGridsterInMobileMode = this.deviceManager.isGridsterInMobileMode;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getMeasurementsData(cursor: string = null) {
        const deviceIds = (this.scout instanceof SoilScoutGroup) ? this.scout.devices : [this.scout.id];

        if (cursor == null) {
            this.measurements = [];
            this.isBusy = true;
        }

        this.soilScoutManager.getMeasurementsCSV(this.scout.site, deviceIds)
            .pipe(take(1))
            .subscribe({
                next: (response: SoilScoutCsvMeasurement[]) => {
                    this.measurements = response;

                    if (this.measurements == null || this.measurements.length < 1) {
                        this.isBusy = false;
                        return;
                    }

                    // The data is received with the most current record first. We want the order to go from oldest to newest, so we reverse the array.
                    this.measurements.reverse();

                    setTimeout(() => {
                        this.buildMeasurementsDictionary();
                        this.setupChart();
                        this.isBusy = false;
                    }, !environment.isDemoMode ? 0 : 500);
                },
                error: err => {
                    console.log();
                }
            })
    }

    private buildMeasurementsDictionary() {
        this.measurementDictionary = {};

        this.measurements.map(m => {
            if (!this.measurementDictionary[m.scoutId]) this.measurementDictionary[m.scoutId] = [];
            this.measurementDictionary[m.scoutId].push(m)

            // Update min/max values for each sensor type
            this.updateMinMax(this.moistureMinMax, m.moisture * 100);
            this.updateMinMax(this.temperatureMinMax, m.temperature);
            this.updateMinMax(this.salinityMinMax, m.salinity);
            this.updateMinMax(this.waterBalanceMinMax, m.waterBalance);
        })
    }

    private updateMinMax(minMax: MeasurementMinMax, measurement: number) {
        if (measurement < minMax.min)
            minMax.min = measurement;
        else if (measurement > minMax.max)
            minMax.max = measurement;
    }

    private get yAxisMin(): number {
        switch (this.selectedSensorType) {
            case SoilScoutSensorType.Moisture:
                return this.moistureMinMax.min;
            case ToroEnums.SoilScoutSensorType.Temperature:
                return this.temperatureMinMax.min;
            case ToroEnums.SoilScoutSensorType.Salinity:
                return this.salinityMinMax.min;
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                return this.waterBalanceMinMax.min;
        }

        return null;
    }

    private get yAxisMax(): number {
        switch (this.selectedSensorType) {
            case SoilScoutSensorType.Moisture:
                return this.moistureMinMax.max;
            case ToroEnums.SoilScoutSensorType.Temperature:
                return <number>this.userFormatService.temperatureFromCelsius(this.temperatureMinMax.max);
            case ToroEnums.SoilScoutSensorType.Salinity:
                return this.salinityMinMax.max;
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                return this.waterBalanceMinMax.max;
        }

        return null;
    }

    private getYAxisLabel(value: any): string {
        return this.userFormatService.toUserNumber(value);
    }

    private get yAxisUnits(): string {
        switch (this.selectedSensorType) {
            case SoilScoutSensorType.Moisture:
                return '%';
            case ToroEnums.SoilScoutSensorType.Temperature:
                return `°${this.userFormatService.temperatureUnits}`;
            case ToroEnums.SoilScoutSensorType.Salinity:
                return this.userFormatService.salinityUnits
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                return '';
        }

        return '';
    }

    private get xAxisFormat(): string {
        switch (this.userFormatService.dateFormat) {
            case  'MM-DD-YY':
                return '{value:%m-%d}'
            case  'MM.DD.YY':
                return '{value:%m.%d}'
            case  'DD/MM/YY':
                return '{value:%d/%m}'
            case 'DD-MM-YY':
                return '{value:%d-%m}'
            case  'DD.MM.YY':
                return '{value:%d.%m}'
            case 'MM/DD/YY':
            default:
                return '{value:%m/%d}'
        }
    }

    private getTooltipTimestamp(epochDate: number): string {
        const date = new Date(epochDate);
        return `${this.userFormatService.toUserDateString(date)}   ${this.userFormatService.toUserTimeString(date)}`;
    }

    private get seriesData(): {}[] {
        let series: {}[] = [];

        for (const scoutId in this.measurementDictionary) {
            series.push({
                type: 'spline',
                name: this.devices.find(d => d.serial_number == +scoutId)?.name,
                data: this.measurementDictionary[scoutId].map(m => {
                    // this.categories.push(m.timestampUtc.toString());

                    switch (this.selectedSensorType) {
                        case SoilScoutSensorType.Moisture:
                            return [m.timestampUtc.getTime(), m.moisture * 100];
                        case ToroEnums.SoilScoutSensorType.Temperature:
                            return [m.timestampUtc.getTime(), this.userFormatService.temperatureFromCelsius(m.temperature, false, 2)];
                        case ToroEnums.SoilScoutSensorType.Salinity:
                            return [m.timestampUtc.getTime(), m.salinity];
                        case ToroEnums.SoilScoutSensorType.WaterBalance:
                            return [m.timestampUtc.getTime(), m.waterBalance];
                    }
                })
            })
        }

        return series;
    }

    private setWidthOfChartContainer() {
        if (!this.ssChartContainer) return;

        this.chartContainerWidth = this.ssChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.ssChartContainer.nativeElement.offsetHeight - 2;
    }

    private updateChart() {
        if (!this.chart || !this.chart.yAxis) { return; }

        this.chart.update({
            yAxis: {
                min: this.yAxisMin,
                max: this.yAxisMax,
                labels: {
                    format: '{value} ' + this.yAxisUnits
                },
            },
            series: <any>this.seriesData,
        });

        this.chart.redraw();
    }

    private setupChart() {
        const self = this;

        this.setWidthOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                marginTop: 20,
                events: {
                    load(event) {
                        self.chart = event.target;
                    },
                },
            },
            credits: { enabled: false },
            title: { text: '' },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: self.xAxisFormat
                },
                tickPixelInterval: !self.isGridsterInMobileMode ? 200 : 70,
                title: { enabled: false },
            },
            yAxis: {
                title: { enabled: false },
                labels: {
                    formatter() {
                        return self.getYAxisLabel(this.value) + ' ' + self.yAxisUnits;
                    }
                },
                min: self.yAxisMin,
                max: self.yAxisMax
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    return '<div class="ss-tooltip-container" >' +
                              '<div class="ss-tt-series-name">' + this.series.name + '</div>' +
                              '<div class="ss-tt-value">' + self.userFormatService.toUserNumber(this.y) + ' ' +  self.yAxisUnits + '</div>' +
                              '<div class="ss-tt-date">' + self.getTooltipTimestamp(this.x) + '</div>' +
                          '</div>';
                },
            },
            series: self.seriesData
        }
    }

}
