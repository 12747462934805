import { DashUserPreferences } from './dash-user-preferences.model';
import { environment } from '../../../../environments/environment';

export class DashUserInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (!environment.isDemoMode)
                this.preferences = json.preferencesJson ? new DashUserPreferences(JSON.parse(json.preferencesJson)) : new DashUserPreferences();
            else
                this.preferences = json.preferencesJson ? new DashUserPreferences(json.preferencesJson) : new DashUserPreferences();
        }
    }

    id: string;
    email: string;
    preferences: DashUserPreferences;
}
