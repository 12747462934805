<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.USER_PREFERENCES' | translate | titlecase}}</p-header>

    <div id="toro-user-preferences-container">
        <p-tabView>

            <p-tabPanel [header]="'STRINGS.GENERAL' | translate">
                <div class="tup-content-pane" id="tup-left-pane">
                    <div class="toro-form-field">
                        <span class="toro-form-field-label">{{'STRINGS.LANGUAGE' | translate}}</span>
                        <p-dropdown [options]="languagesList" [(ngModel)]="dashUserPrefs && dashUserPrefs.language" appendTo="body"></p-dropdown>
                    </div>
                    <div class="toro-form-field">
                        <span class="toro-form-field-label">{{'STRINGS.UNITS_OF_MEASURE' | translate}}</span>
                        <p-dropdown [options]="unitsSystemsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.unitsSystem" appendTo="body"></p-dropdown>
                    </div>
                    <div class="toro-form-field">
                        <span class="toro-form-field-label">{{'STRINGS.GROUP_AND_DECIMAL_SEPARATOR' | translate}}</span>
                        <p-dropdown [options]="groupAndDecimalFormatsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.groupAndDecimalSeparator" appendTo="body"></p-dropdown>
                    </div>
                </div>

                <div class="tup-content-pane" id="tup-right-pane">
                    <div class="toro-form-field">
                        <span class="toro-form-field-label">{{'STRINGS.DATE_FORMAT' | translate | titlecase}}</span>
                        <p-dropdown [options]="dateFormatsList" [(ngModel)]="dashUserPrefs && dashUserPrefs.dateFormat" appendTo="body"></p-dropdown>
                    </div>
                    <div class="toro-form-field">
                        <span class="toro-form-field-label">{{'STRINGS.TEMPERATURE_SCALE' | translate | titlecase}}</span>
                        <p-dropdown [options]="temperatureScalesList" [(ngModel)]="dashUserPrefs && dashUserPrefs.temperatureUnits" appendTo="body"></p-dropdown>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel [header]="'STRINGS.ALERTS' | translate">

                <div id="tup-alert-settings">
                    <div class="p-field">
                        <label for="emailAddresses">{{'STRINGS.EMAIL_ADDRESSES' | translate}} <small>({{'STRINGS.COMMA_DELIMITED_LIST' | translate | lowercase}})</small></label>
                        <input id="emailAddresses" type="text" pInputText [(ngModel)]="alertSettings && alertSettings.userSettings.emailAddresses"/>
                    </div>
                    <div class="p-field">
                        <label for="smaNumbers">{{'STRINGS.SMS_NUMBERS' | translate}} <small>({{'STRINGS.COMMA_DELIMITED_LIST' | translate | lowercase}})</small></label>
                        <input id="smaNumbers" type="text" pInputText [(ngModel)]="alertSettings && alertSettings.userSettings.smsNumbers"/>
                    </div>
                    <div class="p-field">
                        <label for="duplicateDelay">{{'STRINGS.DUPLICATE_DELAY' | translate}}
                            <small>({{'STRINGS.DUPLICATE_DELAY_RANGE' | translate | lowercase}})</small></label>
                        <p-inputNumber id="duplicateDelay" [(ngModel)]="alertSettings && alertSettings.userSettings.duplicateDelay" [min]="5" [max]="1440" [showButtons]="true" [size]="9"></p-inputNumber>
                    </div>
                </div>

                <div id="tup-alert-parameters">

                    <div class="p-field">
                        <label for="emailAddresses">{{'STRINGS.ALERT_SUBSCRIPTIONS' | translate | titlecase}}</label>

                        <p-table [value]="alertSettings?.alertParameters" [scrollable]="true" scrollHeight="flex" sortField="categoryName" [styleClass]="'alert-parameters'">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [pSortableColumn]="'categoryName'" style="text-align: left;">
                                        {{'STRINGS.ALERT_CATEGORY' | translate | titlecase}}
                                        <p-sortIcon [field]="'categoryName'"></p-sortIcon>
                                    </th>
                                    <th [pSortableColumn]="'enableEmail'" style="width: 20%;">{{'Email' | translate | titlecase}}
                                        <p-sortIcon [field]="'enableEmail'"></p-sortIcon>
                                    </th>
                                    <th [pSortableColumn]="'enableSms'" style="width: 20%;">{{'SMS' | translate | titlecase}}
                                        <p-sortIcon [field]="'enableSms'"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-asset>
                                <tr >
                                    <td>
                                        {{asset?.categoryName | translate}}
                                    </td>
                                    <td style="width: 20%; text-align: center;">
                                        <p-checkbox [(ngModel)]="asset && asset.enableEmail" binary="true"></p-checkbox>
                                    </td>
                                    <td style="width: 20%; text-align: center;">
                                        <p-checkbox [(ngModel)]="asset && asset.enableSms" binary="true"></p-checkbox>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                </div>

            </p-tabPanel>

        </p-tabView>

        <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy"  backgroundColor="rgba(255,255,255, .5)"></toro-waiting-indicator>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSave()"></p-button>
    </p-footer>
</p-dialog>
