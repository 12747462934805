import * as moment from 'moment';

export class DailyWeatherForecast {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.date = new Date(json.date);
            this.sunrise = moment.utc(json.sunrise).toDate();
            this.sunset = moment.utc(json.sunset).toDate();
        }
    }

    sitedId: number;
    date: Date;
    conditions: string;
    averageTemp: number;
    highTemp: number;
    lowTemp: number;
    cloudCover: number;
    dewPoint: number;
    precipChance: number;
    precipTotal: number;
    relHumidity: number;
    sunrise: Date;
    sunset: Date;
    windSpeed: number;
}
