/* eslint-disable @typescript-eslint/member-ordering */
import * as moment from 'moment';
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TurfCloudCourseTrakk } from './models/turf-cloud-course-trakk.model';
import { TurfCloudLaborStatistics } from './models/turf-cloud-labor-statistics.model';

@Injectable({
    providedIn: 'root'
})
export class TurfCloudApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCourseTrakk(): Observable<TurfCloudCourseTrakk> {
        return this.apiService.apiRequest<any>(TurfCloudApiService.courseTrakkUrl, HttpMethod.Get)
            .pipe(map((response: TurfCloudCourseTrakk) => new TurfCloudCourseTrakk(response)));
    }

    getLaborStats(date: Date): Observable<TurfCloudLaborStatistics> {
        const dateString = moment(date).format('YYYY-MM-DD');
        return this.apiService.apiRequest<any>(TurfCloudApiService.getLaborStatsUrl(dateString), HttpMethod.Get)
            .pipe(map((response: TurfCloudLaborStatistics) => new TurfCloudLaborStatistics(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}turfCloud`; }

    private static get courseTrakkUrl(): string { return `${TurfCloudApiService.baseUrl}/courseTrakk`; }

    private static getLaborStatsUrl(date: string): string { return `${TurfCloudApiService.baseUrl}/laborStats?date=${date}`; }

}
