export class PlaybooksMonthlyTotal {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    total: number;
}
