export class LynxPercentAdjust {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.basePercentAdjust = json.BasePercentAdjust;
            this.display = json.Display;
            this.id = json.Id;
            this.isPAD = json.IsPAD;
            this.tempDurationDays = json.TempDurationDays;
            this.tempPercentAdjust = json.TempPercentAdjust;

            delete this['BasePercentAdjust'];
            delete this['Display'];
            delete this['Id'];
            delete this['IsPAD'];
            delete this['TempDurationDays'];
            delete this['TempPercentAdjust'];
        }
    }

    basePercentAdjust: number;
    display: string;
    id: number;
    isPAD = false;
    tempDurationDays: number;
    tempPercentAdjust: number;

}
