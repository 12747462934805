import * as moment from 'moment';
import { CalAmpMotionLogInfo } from './cal-amp-motion-log-info.model';

export class CalAmpMotionLogEvent {

    constructor(motionLogEvent: any) {
        if (motionLogEvent == null) { return; }

        this.deviceId = motionLogEvent.deviceId;
        this.deviceEsn = motionLogEvent.deviceEsn;
        this.eventTime = moment(motionLogEvent.eventTime).toDate();

        const sortedMotionLogInfos = motionLogEvent.motionLogInfo.sort((a, b) => +new Date(b.timeOfFix) - +new Date(a.timeOfFix));
        this.motionLogInfos = sortedMotionLogInfos.map((info: CalAmpMotionLogInfo) =>  new CalAmpMotionLogInfo(info));
    }

    deviceId: number;
    deviceEsn: number;
    eventTime: Date;
    motionLogInfos: CalAmpMotionLogInfo[];
}
