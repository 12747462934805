import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DashMessageService } from '../../common/services/dash-message.service';
import { finalize } from 'rxjs/operators';
import { NotificationManagerService } from '../../api/notifications/notification-manager.service';
import { ToroNotification } from '../../api/notifications/models/toro-notification.model';

@Component({
    selector: 'toro-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.less']
})
export class NotificationPanelComponent {
    @HostBinding('class') class = 'toro-notification-panel';

    @Output() closePanel = new EventEmitter();
    @Output() refresh = new EventEmitter();

    @Input() notifications: ToroNotification[] = [];
    @Input() isLoading = false;

    isBusy = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashMessageService: DashMessageService,
                private notificationManager: NotificationManagerService
    ) { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onRefresh() {
        this.refresh.emit();
    }

    onClosePanel() {
        this.closePanel.emit();
    }

    onMarkAllRead() {
        this.isBusy = true;

        this.notificationManager.markAllNotificationsAsRead()
            .pipe(finalize(() => this.isBusy = false))
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericRequestErrorMessage();
            });
    }

    onDeleteAll() {
        this.isBusy = true;

        this.notificationManager.deleteAllNotifications()
            .pipe(finalize(() => this.isBusy = false))
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericRequestErrorMessage();
            });
    }
}
