import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-my-turf-details-dlg',
    templateUrl: './my-turf-details-dlg.component.html',
    styleUrls: ['./my-turf-details-dlg.component.less']
})
export class MyTurfDetailsDlgComponent {
    @Output() cancel = new EventEmitter();

    @Input() title = '';
    @Input() subtitle = '';
    @Input() recordCount: number;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    close() {
        this.cancel.emit();
    }

}
