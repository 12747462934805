import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { FrostRiskData } from '../../../../../api/weather/models/frost-risk-data.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherManagerService } from '../../../../../api/weather/weather-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@UntilDestroy()
@Component({
    selector: 'toro-widget-frost-warning',
    templateUrl: './widget-frost-warning.component.html',
    styleUrls: ['./widget-frost-warning.component.less']
})
export class WidgetFrostWarningComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('chartContainer') chartContainer: ElementRef;

    iconColor = '#3178c6';
    title = 'WIDGET.FROST_WARNING';

    frostRiskData: FrostRiskData;
    isFrosty = false;
    riskImage: string;
    frostRiskEndTime: string;

    chartContainerWidth: number;
    chartContainerHeight: number;
    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartValueUnits: string;
    showChartDialog = false;

    // Mini Mode Vars
    currentCourseForecastTime: string;
    currentTemp: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        // Monitor Gridster's mobile mode to properly redraw chart. This is only relevant when the widget
        // was being displayed in 2x1, 'chart mode', prior to switching to Gridster mobile view (i.e., 1x1).
        this.deviceManager.gridsterMobileModeChange
            .pipe(untilDestroyed(this))
            .subscribe((state: { isMobileMode: boolean }) => {
                if (!state.isMobileMode && this.itemCols > 1) { setTimeout(() => this.setupChart()); }
            });

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language
                this.setupChart();
                if (this.isGridsterInMobileMode) { this.setMiniModeComponentData(); }
            });

        this.language = this.dashUserManager.language;
        this.isBusy = true;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    showDetails() {
        this.clearChart();
        this.showChartDialog = true;
    }

    onChartDialogReady() {
        setTimeout(() => this.setupChart());
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.FrostWarningWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        // Handle proper sizing/drawing of chart after resizing widget.
        if (this.frostRiskData && this.displayCols === 2) { setTimeout(() => this.setupChart()); }
        this.clearChart();
    }

    protected getWidgetData() {
        this.weatherManager.getFrostRisk()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    setTimeout(() => this.isBusy = false, 1000);
                })
            )
            .subscribe((response: FrostRiskData) => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = response.lastUpdated;
                this.frostRiskData = response;
                this.setComponentData();

                if (this.isGridsterInMobileMode) { this.setMiniModeComponentData(); }

                this.setupChart();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private clearChart() {
        this.chart = null;
        this.chartOptions = null;
    }

    private setComponentData() {
        this.isFrosty = this.frostRiskData.isFrosty;
        this.riskImage = !this.isFrosty ? '/assets/images/frost/grass.jpg' : '/assets/images/frost/frost_animated.gif';

        if (this.isFrosty) {
            const frostEndDate = this.frostRiskData.frostRiskEndDate;
            this.frostRiskEndTime = frostEndDate ? moment(frostEndDate).format('h:mm A') : this.translateService.instant('STRINGS.UNKNOWN_TIME');
        }
    }

    get sensorSeriesCategories(): string[] {
        const categories = [];
        const dateFormat = this.userFormatService.dateFormat.substr(0, 2) === 'DD' ? 'D/M' : 'M/D';
        const halfway = Math.floor(this.frostRiskData?.forecastTemperatures.length / 2);
        let cnt = 0;

        this.frostRiskData?.forecastTemperatures.forEach(f => {
            let label = moment(f.time).format('h A');
            if (cnt++ === halfway) {
                label += ` (${moment(f.time).format(dateFormat)})`;
            }
            categories.push(label);
        });

        return categories;
    }

    private setupChart() {
        const self = this;

        this.setWidthOfChartContainer();
        this.chartValueUnits = this.userFormatService.temperatureUnits;

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                events: { load(event) {self.chart = event.target; } },
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                type: 'datetime',
                crosshair: {
                    width: 1,
                    color: '#ccc',
                },
                tickInterval: 4,        // TODO: Adjust to correct value once we have the full set of data value.
                tickLength: 7,
                tickWidth: 1,
                categories: self.sensorSeriesCategories,
                labels: {
                    useHTML: true,
                    formatter() { return '<span class="tg-sensor-dlg-axis-label">' + this.value + '</span>'; }      // TODO: Use widget specific class/style.
                }
            },
            yAxis: {
                title: { text: null },
                labels: { formatter() { return this.value + '°'; } }
            },
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const userValue = self.userFormatService.toUserNumber(this.y);
                    return '<span>' + self.sensorSeriesCategories[this.x] + '</span>' + ' - ' + '<span>' + userValue + '˚' + self.chartValueUnits + '</span>';
                },
                useHTML: true,
                followTouchMove: true,
            },

            plotOptions: {
                spline: {
                    marker: { states: { hover: { enabled: false } } },
                },
                arearange: {
                    marker: { states: { hover: { enabled: false, } } },
                    states: { hover: { enabled: false } }
                },
                series: {
                    enableMouseTracking: !self.showChartDialog,
                }
            },
            series: [{
                name: 'Temperature',
                data: self.getUserAverages(),
                zIndex: 1,
                marker: { enabled: false, },
            }, {
                name: 'Range',
                data: self.getUserRanges(),
                type: 'arearange',
                lineWidth: 0,
                linkedTo: ':previous',
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: { enabled: false }
            }]
        };
    }

    private setWidthOfChartContainer() {
        if (!this.chartContainer) { return; }
        this.chartContainerWidth = this.chartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.chartContainer.nativeElement.offsetHeight - 2;
    }

    private getUserAverages(): number[][] {
        return this.frostRiskData?.averages.map(v => [v[0], <number>this.userFormatService.temperature(v[1])]);
    }

    private getUserRanges(): number[][] {
        return this.frostRiskData?.ranges.map(v => [v[0], <number>this.userFormatService.temperature(v[1]), <number>this.userFormatService.temperature(v[2])]);
    }

    setMiniModeComponentData() {
        if (this.frostRiskData?.forecastTemperatures?.length < 1) { return; }

        this.currentCourseForecastTime = this.userFormatService.toUserHourTimeString(this.frostRiskData.forecastTemperatures[0].time);
        this.currentTemp = <string>this.userFormatService.temperature(this.frostRiskData.forecastTemperatures[0].avgTemp, true);
    }

}
