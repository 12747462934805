import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'toro-ah-snap',
    templateUrl: './ah-snap.component.html',
    styleUrls: ['./ah-snap.component.less']
})
export class AhSnapComponent {
    @HostBinding('class') class = 'toro-flex-child';

    constructor(private router: Router) { }

    onRetry() {
        this.router.navigate(['/']);
    }

}
