import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxPumpStation } from '../../../../../api/lynx/models/lynx-pump-station.model';
import { LynxPumpStationDisplay } from '../../../../../api/lynx/models/lynx-pump-station-display.model';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-lynx-pump-stations-with-gauges',
    templateUrl: './widget-lynx-pump-stations-with-gauges.component.html',
    styleUrls: ['./widget-lynx-pump-stations-with-gauges.component.less']
})
export class WidgetLynxPumpStationsWithGaugesComponent extends LynxWidgetBase implements OnInit {
    private readonly baseTitle = 'WIDGET.LYNX_PUMP_STATIONS';

    iconColor = 'goldenrod';
    title = this.baseTitle;

    private readonly lowAlertClass = 'lps-table-low-alert';
    private readonly highAlertClass = 'lps-table-high-alert';
    private highAlertString = '';
    private lowAlertString = '';

    pumpStationsRaw: LynxPumpStation[];
    pumpStationsConverted: LynxPumpStationDisplay[];
    pressureUnits: string;
    flowUnits: string;
    selectedPump: LynxPumpStationDisplay;
    showInfoDialog = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;
        this.highAlertString = this.translateService.instant('STRINGS.ALERT_HIGH');
        this.lowAlertString = this.translateService.instant('STRINGS.ALERT_LOW');

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.convertDataToProperUnits();
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowInfo() {
        // this.showInfoDialog = true;
    }

    onRowSelect(event: any, pump: LynxPumpStationDisplay) {
        this.selectedPump = pump;
        this.showInfoDialog = true;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        throw new Error('Not Implemented');
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.lynxManager.getPumps()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((pumps: LynxPumpStation[]) => {
                this.clearIsUnableToFetchData();
                this.pumpStationsRaw = pumps;
                this.setWidgetConstraints(this.pumpStationsRaw.length);
                this.convertDataToProperUnits();
                if (this.pumpStationsRaw.length > 1) { this.title = `${this.translateService.instant(this.baseTitle)} (${this.pumpStationsRaw.length})`; }
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    toUserPressure(value: number) {
        return this.userFormatService.pressure(value, true);
    }

    toUserFlow(value: number) {
        return this.userFormatService.flow(value, true);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setWidgetConstraints(pumpCount: number) {
        let maxCols = 1;

        if (pumpCount >= 2 && pumpCount <= 5) {
            maxCols = pumpCount;
        } else if (pumpCount > 5) {
            maxCols = 5;
        }

        this.broadcastService.changeWidgetConstraint.next({ id: this.associatedWidget.id, maxRows: null, maxCols });
    }

    private convertDataToProperUnits() {
        this.pressureUnits = this.userFormatService.pressureUnits;
        this.flowUnits = this.userFormatService.flowUnits;

        this.pumpStationsConverted = this.pumpStationsRaw.slice().map(ps => {
            const newPump = new LynxPumpStationDisplay(ps);
            newPump.pressure = ps.pressure !== null ? <string>this.userFormatService.pressure(ps.pressure, true) : '0';
            newPump.flow = ps.flow !== null ? <string>this.userFormatService.flow(ps.flow, true) : '0';

            // Check for alert
            if (ps.flow >= ps.flowConfig.maximumAlert) {
                newPump.flowAlertClass = this.highAlertClass;
                newPump.flowAlertTooltip = this.highAlertString;
            } else if (ps.flow <= ps.flowConfig.minimumAlert) {
                newPump.flowAlertClass = this.lowAlertClass;
                newPump.flowAlertTooltip = this.lowAlertString;
            }

            if (ps.pressure >= ps.pressureConfig.maximumAlert) {
                newPump.pressureAlertClass = this.highAlertClass;
                newPump.pressureAlertTooltip = this.highAlertString;
            } else if (ps.pressure <= ps.pressureConfig.minimumAlert) {
                newPump.pressureAlertClass = this.lowAlertClass;
                newPump.pressureAlertTooltip = this.lowAlertString;
            }

            return newPump;
        });
    }
}
