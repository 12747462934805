
export class PwDailyHistorical {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            this.date = new Date(json.date);
        }
    }

    date: Date;
    avgAmbientTemperature: number;
    minAmbientTemperature: number;
    maxAmbientTemperature: number;
    avgRelativeHumidity: number;
    avgWindSpeed: number;
    maxWindSpeed: number;
    avgWindGust: number;
    maxWindGust: number;
    avgWindDirection: number;
    avgWetBulbGlobalTemp: number;
    minWetBulbGlobalTemp: number;
    maxWetBulbGlobalTemp: number;
    avgWindChill: number;
    minWindChill: number;
    avgRain1Hr: number;
    maxRain1Hr: number;
    rainTotal: number;

}
