import { Component, Input } from '@angular/core';
import { WidgetManagerService } from '../../../api/widgets/widget-manager.service';

@Component({
    selector: 'toro-drag-overlay',
    templateUrl: './drag-overlay.component.html',
    styleUrls: ['./drag-overlay.component.less']
})
export class DragOverlayComponent {
    @Input() disableMoveLeft = true;
    @Input() disableMoveUp = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private widgetManager: WidgetManagerService) { }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================

    get isMobileGridDisplay(): boolean {
        return this.widgetManager.isMobileGridDisplay;
    }
}
