import { CalAmpAvlAccumulatorData } from './cal-amp-avl-accumulator-data.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;
import CalAmpAccumulators = ToroEnums.CalAmpAccumulators;

export class CalAmpAvlDeviceData {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.accumulators = json.accumulators.map(a => new CalAmpAvlAccumulatorData(a));
        }
    }

    type: string;
    accumulators: CalAmpAvlAccumulatorData[];
    altitude: {};
    gpsSpeed: {};
    pegBehavior: {};
    electedValues: {};
    systemOfUnits: {};

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================

    get isStatusEvent(): boolean {
        const currentOperatingStatus = this.accumulators.find(a => a.index === CalAmpAccumulators.VB_Operating_Status);
        return currentOperatingStatus != null;
    }

    get status(): CalAmpDeviceStatus {
        const currentOperatingStatus = this.accumulators.find(a => a.index === CalAmpAccumulators.VB_Operating_Status);
        if (currentOperatingStatus == null) { return null; }

        return currentOperatingStatus.value;
    }

}
