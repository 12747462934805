<div class="wiz-integration-content">
    <div id="left-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.CONNECT_EZ_LOCATOR_TO_APP' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step2"></ng-container>
        </toro-step-group>
    </div>
    <div id="right-content" class="wiz-integration-group">
        <img src="assets/images/wizard/ezLocatorLogin.png" alt="">
    </div>
</div>

<ng-template #step1>
    <div style="display: flex; flex-direction: column;">
        <div>
            {{'STRINGS.SIGN_INTO' | translate}}
            <a href="javascript:void(0);" (click)="onNavigateToSite()">pinsheet.com</a> {{'SPECIAL_MSG.TO_CONNECT_YOUR_ACCOUNT_TO_APP' | translate}}.
        </div>
        <div id="ez-sign-in-warning">
            <div id="ico-container"><fa-icon icon="exclamation-circle"></fa-icon></div>
            <div>
                {{'SPECIAL_MSG.IF_ALREADY_SIGNED_INTO_EZ_LOCATOR' | translate}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #step2>
    {{'SPECIAL_MSG.ONCE_SIGNED_IN_RETURN_TO_APP' | translate}}.
</ng-template>
