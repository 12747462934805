
<div class="toro-date-picker-container">
    <div class="tdp-button tdp-prev-button" (click)="onPreviousDay()"><fa-icon icon="arrow-circle-left"></fa-icon></div>
    <div #theLabel class="tdp-date-label">
        {{dateString}}
        <div class="tdp-ro-overlay"></div>
        <p-calendar #tdpCalendar [(ngModel)]="date" appendTo="body" [locale]="languageSettings"></p-calendar>
    </div>
    <div class="tdp-button tdp-next-button" (click)="onNextDay()"><fa-icon icon="arrow-circle-right"></fa-icon></div>
    <div class="tdp-button tdp-cal-button" (click)="onShowCalendar()"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon></div>

</div>
