import * as moment from 'moment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import SiteDataCategoryType = ToroEnums.SiteDataCategoryType;
import AlertType = ToroEnums.AlertType;
import AlertThresholdType = ToroEnums.AlertThresholdType;
import AlertSeverity = ToroEnums.AlertSeverity;
import AlertDataLevel = ToroEnums.AlertDataLevel;

export class ToroNotification {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.triggeredTime) { this.triggeredTime = moment.utc(json.triggeredTime).toDate(); }
            if (json.triggeredTimeForCourse) { this.triggeredTimeForCourse = moment(json.triggeredTimeForCourse).toDate(); }
        }
    }

    actualValue: number;
    alertType: AlertType = null;
    categoryType: SiteDataCategoryType;
    courseId: number = null;
    dataLevel: AlertDataLevel = null;
    id: number;
    read = false;
    severity: AlertSeverity = AlertSeverity.Information;
    text: string;
    thresholdType: AlertThresholdType = null;
    thresholdValue: number;
    title: string = null;
    triggeredTime: Date;
    triggeredTimeForCourse: Date;
}
