import { SoilScoutNotificationTrigger } from './soil-scout-notification-trigger.model';

export class SoilScoutOfflineNotification {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.triggers) this.triggers = json.triggers.map(t => new SoilScoutNotificationTrigger(t));
        }
    }

    emails: string[] = [];
    enabled = false;
    triggers: SoilScoutNotificationTrigger[] = []
}
