import { PwObservationData } from './pw-observation-data.model';
import { PwObservationMeta } from './pw-observation-meta.model';

export class PwObservation {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.data) this.data = new PwObservationData(json.data);
            if (json.meta) this.meta = new PwObservationMeta(json.meta);
        }
    }

    data: PwObservationData;
    meta: PwObservationMeta;

}
