export class ConversionUtil {

    static celsiusToFahrenheit(tempC: number): number {
        return tempC * 9/5 + 32
    }

    static centimetersToInches(cm: number): number {
        return cm / 2.54;
    }

}
