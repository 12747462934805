<div id="toro-connecting-message" *ngIf="showConnectingMessage">
    {{connectingMessage}}
</div>

<div *ngIf="loadMainAppDiv" class="toro-main-app-container" [ngClass]="{'locked-and-loaded': isMainAppVisible}">
    <div *ngIf="!isSpatialAdjust && !isLockDown" class="toro-main-top-pane">
        <toro-app-header></toro-app-header>
    </div>

    <div class="toro-main-body">
        <router-outlet></router-outlet>
    </div>

    <div *ngIf=" !isSpatialAdjust && !isLockDown" class="toro-main-bottom-pane" [ngClass]="{'hide-mobile-footer': !showMobileFooter}">
        <toro-app-footer></toro-app-footer>
    </div>

    <p-toast *ngIf="!isLockDown"></p-toast>
</div>
