import * as moment from 'moment';

export class PogoPinInfo {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.timestamp) this.timestamp = moment.utc(json.timestamp).toDate();
        }
    }

    latitude: number;
    longitude: number;
    timestamp: Date;

}
