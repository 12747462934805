<div *ngIf="!isNoNotificationsCard" class="toro-notification-card-container">
    <div class="tnc-left-pane">
        <fa-icon icon="circle" [ngClass]="{'is-read': notification?.read}"></fa-icon>
    </div>
    <div class="tnc-right-pane">
        <div class="tnc-info tnc-card-title" [ngClass]="{'is-read': notification?.read}" style="align-items: center;">
            <div class="tnc-card-icon-n-title">
                <fa-icon class="tnc-severity-icon" [icon]="severityIcon" [ngStyle]="{'color': severityIconColor}" [pTooltip]="severityTooltip"></fa-icon>
                {{cardTitle}}
            </div>
            <div class="tnc-date">{{cardDate}}</div>
        </div>
        <div class="tnc-info tnc-title">{{notification?.title}}</div>
        <div class="tnc-info tnc-description">{{notification?.text}}</div>
        <div class="tnc-info tnc-toolbar">
            <span *ngIf="!notification?.read"><a (click)="onMarkRead()">{{ 'STRINGS.MARK_READ' | translate | titlecase }}</a> | </span>
            <a (click)="onDeleteNotification()">{{ 'STRINGS.DELETE' | translate | titlecase }}</a>
        </div>
    </div>
</div>

<div *ngIf="isNoNotificationsCard" class="toro-notification-card-container">
    <div class="tnc-no-notifications-msg">{{'STRINGS.NO_NOTIFICATIONS_MSG' | translate}}</div>
</div>
