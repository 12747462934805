<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            (gotoSite)="onMyTurfLinkClick();"
    >

        <div class="toro-my-turf-orders-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tmm-mt-course-info" class="toro-mini-mode-content">
                        <i class="tmm-ul-icon toro-id-orders"></i>
                        <div class="tmm-bot-left-container">
                            <div>{{'SPECIAL_MSG.CARTS_NEEDING_APPROVAL_FOR_ALL' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div id="tmm-mt-dm-value-container" class="toro-mini-mode-content-panel ">
                    <div id="tmm-mt-metric-container" class="toro-mini-mode-content">
                        <div class="tmm-large-value">{{orders?.length || '0'}}</div>
                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-my-turf-orders-widget-content toro-flex-child toro-link-button-host toro-component-shield-host">
            <toro-widget-link-button (click)="onMyTurfLinkClick()" imageSrc="../../../../../assets/images/myTurfIcon.png"></toro-widget-link-button>
            <div *ngIf="myTurfUserName != null" class="tmt-account-info">
                <span class="tmt-account-label">{{'STRINGS.MY_TURF_ACCOUNT' | translate}}</span>
                <span class="tmt-account-name">{{myTurfUserName}}</span>
            </div>

            <div id="tmyo-top-pane">
                <div id="tmyo-icon-pane"><i class="icon-cart-approval"></i></div>
                <div id="tmyo-count-pane">{{orders?.length || '0'}}</div>
            </div>
            <div id="tmyo-bottom-pane" [ngClass]="language">
                <div>{{'STRINGS.CARTS_NEEDING_APPROVAL' | translate | titlecase }}</div>
                <div>{{'STRINGS.FOR_ALL_LOCATIONS' | translate | lowercase}}</div>
            </div>

            <toro-component-shield></toro-component-shield>
        </div>

        <div class="mt-bottom-toolbar-container" [ngClass]="language">
            <toro-widget-button
                    [text]="'STRINGS.VIEW_CARTS' | translate | titlecase"
                    [disabled]="!orders || orders?.length < 1"
                    (buttonClick)="showOrders()"
            ></toro-widget-button>
        </div>
    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-my-turf-details-dlg *ngIf="showDetailsDialog" [title]="title" [subtitle]="subtitle" [recordCount]="orders?.length" (cancel)="showDetailsDialog = false;">
    <div id="my-turf-details-table-container" class="toro-flex-child">
        <p-table [value]="userFormattedOrders" [scrollable]="true" scrollHeight="flex" [resizableColumns]="true">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="width:50%">
                    <col style="width:25%">
                    <col style="width:25%">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'cartName'" pResizableColumn>{{'STRINGS.CART_NAME' | translate | titlecase}}<p-sortIcon [field]="'cartName'"></p-sortIcon></th>
                    <th [pSortableColumn]="'numberOfParts'" pResizableColumn>{{'STRINGS.QUANTITY' | translate | titlecase}}<p-sortIcon [field]="'numberOfParts'"></p-sortIcon></th>
                    <th [pSortableColumn]="'totalCost'" pResizableColumn>{{'STRINGS.TOTAL_COST' | translate | titlecase}}<p-sortIcon [field]="'totalCost'"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr>
                    <td>{{order.cartName}}</td>
                    <td style="text-align: right;">{{order.numberOfParts}}</td>
                    <td style="text-align: right;">{{order.totalCost}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</toro-my-turf-details-dlg>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
