/* eslint-disable @typescript-eslint/dot-notation, @typescript-eslint/member-ordering */

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AssetTrackingDeviceType = ToroEnums.AssetTrackingDeviceType;
import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;
import HttpStatusCode = ToroEnums.HttpStatusCode;
import MessageSeverity = ToroEnums.MessageSeverity;

import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { AssetTrackingData } from '../../../../../api/asset-tracking/models/asset-tracking-data.model';
import { AssetTrackingManagerService } from '../../../../../api/asset-tracking/asset-tracking-manager.service';
import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { CalAmpDevice } from '../../../../../api/cal-amp/models/cal-amp-device.model';
import { CalAmpMapInfo } from '../../../../../api/cal-amp/models/cal-amp-map-info.model';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { GoogleMapLocation } from '../../../../../api/_common/models/google-map-location.model';
import { KmlZoneInfo } from '../models/kml-zone-info.model';
import { TokenKeyModel } from '../../../../../api/auth/models/token-key.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TrackingConstants } from '../../../../../common/constants/tracking.constants';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { XmlParserService } from '../../../../../common/services/xml-parser.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-geo-fencing-tracking',
    templateUrl: './widget-geo-fencing.component.html',
    styleUrls: ['./widget-geo-fencing.component.less']
})
export class WidgetGeoFencingComponent extends ToroDashboardWidget implements OnInit {
    private readonly MAP_MIN_ZOOM = 14;
    private readonly MAP_SELECTED_ASSET_ZOOM = 18;
    private readonly MAP_NO_ASSET_ZOOM = 16;

    readonly iconColor = 'purple';
    readonly title = 'WIDGET.GEO_FENCING';

    assetTrackingData: AssetTrackingData;
    totalAssetUsageHours = '0';
    assets: any[];
    showListDialog = false;
    showMapDialog = false;
    fadeInMapDialog = false;
    mapOptions: any;
    mapOverlays: any[];
    googleMap: any;
    mapZoomLevel = 18;
    animationRef: any;
    eventSliderIndex = 0;
    eventSliderMax = 0;
    latestMotionLogFixDate: moment.Moment;
    isAnimating = false;
    sliderDateTime: Date;
    userSlideDateTimeString: string;
    spinnerText = '';
    isManualMapRefresh = false;
    dayNames: string[];
    calAmpAccountNotConfigured = false;
    showAnimationControls = false;
    isManualDataRefresh = false;
    lastStatusUpdateTimeString = '';
    isAccountIdDialogDisplayed = false;
    newAccountId: number;
    linkButtonTooltipText: string;
    kmlZones: KmlZoneInfo[] = [];

    private dashUserPrefs: DashUserPreferences;

    private _selectedAsset: CalAmpDevice;
    set selectedAsset(value: CalAmpDevice) {
        this._selectedAsset = value;
        if (value == null) { return; }

        // TODO: Handle this in some friendly UX way.
        if (value.hasNoLocation) {
            this.dashMessageService.showMessage('STRINGS.NO_LOCATION_AVAILABLE', 'STRINGS.NO_LOCATION_FOR_SELECTED_DEVICE', MessageSeverity.Info);
            return;
        }

        this.onShowMap();
    }

    get selectedAsset(): CalAmpDevice {
        return this._selectedAsset;
    }

    private _gfCalAmpAccountId = null;
    set gfCalAmpAccountId(value: number) {
        this._gfCalAmpAccountId = (value != null) ? value : null;
    }

    get gfCalAmpAccountId(): number {
        return this._gfCalAmpAccountId;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private assetTrackingManager: AssetTrackingManagerService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private xmlParserService: XmlParserService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;
        this.dayNames = this.translateService.instant('CALENDAR.DAY_NAMES_SHORT');
        this.linkButtonTooltipText = !environment.isDemoMode ? 'STRINGS.TRACKING_SITE_LINK_BTN_TXT' : 'STRINGS.NAVIGATION_TO_TRACKING_SITE_DISABLED'

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (!this.assetTrackingData || !this.assetTrackingData.latestAvlEventDate) { return; }

                const now = this.assetTrackingData.latestAvlEventDate.toDate();
                this.lastStatusUpdateTimeString = `${this.userFormatService.toUserDateString(now)} ${this.userFormatService.toUserTimeString(now, true)}`;
                this.updateDeviceLocations(this.eventSliderIndex);
            });

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.

        // TODO: Logic to parse KML file to grab zone names potentially.
        const xmlUrl = 'https://intellidashstoragedev.blob.core.windows.net/kmlfiles/38417.kml';
        this.xmlParserService.parseXML(xmlUrl)
            .subscribe((data) => {

                data?.kml?.Document[0]?.Placemark?.forEach(p => {
                    this.kmlZones.push(new KmlZoneInfo(p.name[0], p.description[0]));
                });

                console.log(this.kmlZones.length); // This will display the parsed data in the console
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TrackingWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData(isManualRefresh = false) {
        // Don't update data while map is being manipulated.
        if (this.isWidgetInitialized && (this.eventSliderMax !== this.eventSliderIndex)) { return; }

        // Don't update the underlying data if the user is playing with the map.
        if (this.showMapDialog && this.eventSliderIndex !== this.eventSliderMax) { return; }

        if (isManualRefresh) { this.isManualDataRefresh = true; }

        // Try to get CalAmp Account Id (stored in user preferences)
        this.dashUserManager.getDashUserInfo()
            .pipe(take(1))
            .subscribe((dashUserInfo: DashUserInfo) => {
                this.dashUserPrefs = dashUserInfo.preferences;
                if (this.dashUserPrefs.gfCalAmpAccountId != null) {
                    this.gfCalAmpAccountId = this.dashUserPrefs.gfCalAmpAccountId;
                    this.fetchCalAmpData(isManualRefresh);
                    return;
                }

                if (this.gfCalAmpAccountId == null) {
                    this.isBusy = false;
                    this.setIsUnableToFetchData('STRINGS.PROVIDE_CAL_AMP_ACCOUNT_ID');
                    // this.showCalAmpAccountNotConfiguredDisplay();
                    return;
                }

            }, () => {
                this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.SET_CAL_AMP_ACCOUNT_ID'),
                icon: 'pi pi-fw pi-sign-in',
                command: this.showAccountIdDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onShowAssets() {
        this.showListDialog = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetViewAssets, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onShowMap(clearSelectedAsset = false) {
        if (clearSelectedAsset) { this.selectedAsset = null; }

        this.spinnerText = 'STRINGS.LOADING_MAP_DATA';
        this.eventSliderIndex = this.eventSliderMax;
        this.mapZoomLevel = this.selectedAsset == null ? this.MAP_NO_ASSET_ZOOM : this.MAP_SELECTED_ASSET_ZOOM;
        this.setupMap();
        this.showMapDialog = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetViewAssetsMap, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onMapReady(event: any) {
        this.googleMap = event.map;

        // Test ========================================

        this.googleMap.zoom = 16;

        // This works!
        const georssLayer = new google.maps.KmlLayer({
            // url: 'https://intellidash-kml-files.s3.amazonaws.com/FargoCC.kml'
            url: `https://intellidashstoragedev.blob.core.windows.net/kmlfiles/${this.gfCalAmpAccountId}.kml`
        });

        georssLayer.setMap(this.googleMap);

        // Test ========================================

        setTimeout(() => this.fadeInMapDialog = true, 1000);
    }

    onMapDragEnd(data: any) {
        console.log();
    }

    onZoomChanged() {
        this.mapZoomLevel = this.googleMap.zoom;
    }

    onTrackingSiteLinkClick() {
        if (environment.isDemoMode) {
            const summary = this.translateService.instant('STRINGS.DEMO_MODE').toTitleCase();
            const detail = this.translateService.instant('STRINGS.NAVIGATION_TO_TRACKING_SITE_DISABLED');
            this.dashMessageService.showMessage(summary, detail, MessageSeverity.Info);
            return;
        }

        this.authManager.getTrackingToken()
            .subscribe((tokenKeyModel: TokenKeyModel) => {
                this.navigateToTrackingSite(`${environment.assetTrackingSiteUrl}/intellidash?${tokenKeyModel.tokenKey}`);
            }, () => {
                this.navigateToTrackingSite(`${environment.assetTrackingSiteUrl}`);
            });
    }

    onEventSliderChange(event: any) {
        if (this.assetTrackingData == null || this.assetTrackingData.assets == null || this.assetTrackingData.assets.length < 1) { return; }

        this.updateDeviceLocations(event.value);
    }

    onAnimateDeviceLocations(toggleOff = false) {
        if (this.animationRef || toggleOff) {
            clearInterval(this.animationRef);
            this.animationRef = null;
            this.isAnimating = false;
            return;
        }

        this.isAnimating = true;
        this.animationRef = setInterval(() => {
            this.updateDeviceLocations(--this.eventSliderIndex);
            if (this.eventSliderIndex <= 0) {
                clearInterval(this.animationRef);
            }
        }, 100);
    }

    onRefreshMap() {
        this.onAnimateDeviceLocations(true);
        this.isBusy = true;
        this.spinnerText = 'STRINGS.UPDATING_MAP_DATA';
        this.isManualMapRefresh = true;

        this.getWidgetData(true);
    }

    onRefreshStatusDialog() {
        this.isBusy = true;
        this.getWidgetData(true);
    }

    onResetMap() {
        this.onAnimateDeviceLocations(true);
        this.eventSliderIndex = this.eventSliderMax;
        this.updateDeviceLocations(this.eventSliderIndex);
    }

    onShowAnimationControls(event: MouseEvent) {
        // shift + alt + command (on mac)
        if (event.shiftKey && event.altKey && event.metaKey) {
            this.showAnimationControls = !this.showAnimationControls;
            return;
        }
    }

    onSetAccountId() {
        this.isAccountIdDialogDisplayed = false;

        // Ignore changes to the account id when in demo mode.
        if (environment.isDemoMode) { return; }

        // If the Course Id was not change, don't do anything.
        if (this.gfCalAmpAccountId === this.newAccountId || this.newAccountId == null) { return; }

        this.gfCalAmpAccountId = this.newAccountId;
        this.isBusy = true;
        this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_ACCOUNT_ID')}...`;

        // Store the new Account Id in the user preferences.
        this.dashUserPrefs.gfCalAmpAccountId = this.newAccountId;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

        this.assetTrackingData = null;
        this.fetchCalAmpData(true);

        const eventDetails = `Course Id: ${this.newAccountId}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetSavedAccountIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onCancelAccountIdDialog() {
        this.isAccountIdDialogDisplayed = false
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetCancelAccountIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private navigateToTrackingSite(url: string) {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_TRACKING_SITE' });
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetGoToTrackingSite, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        setTimeout(() => window.open(url, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    cnt = 0;

    private fetchCalAmpData(bypassCache = false) {
        this.assetTrackingManager.getDeviceList(this.gfCalAmpAccountId, bypassCache)
            .pipe(take(1))
            .subscribe((devices: CalAmpDevice[]) => {
                this.calAmpAccountNotConfigured = false;

                // console.log('>>Devices Retrieved: ' + (Date.now() - startTime) + ' ms');

                if (this.assetTrackingData) {
                    this.assetTrackingData.update(devices);
                } else {
                    this.assetTrackingData = new AssetTrackingData(devices);
                }

                // Clear AssetTrackingData object if we have no devices.
                if (devices == null || devices.length < 1) {
                    this.assetTrackingData.assets = [];
                    this.assetTrackingData.clearSortedAssets();
                    this.setupMap();
                    this.eventSliderMax = 0;
                    this.eventSliderIndex = 0;
                    this.isBusy = false;
                    this.isManualDataRefresh = false;
                    return;
                }

                this.getCalAmpAvlData();

            }, error => {
                this.isBusy = false;
                this.isManualDataRefresh = false;

                if (error.status === HttpStatusCode.Forbidden) {
                    this.showCalAmpAccountNotConfiguredDisplay();
                    return;
                }

                this.dashMessageService.showGenericFetchErrorMessage();
                this.unableToFetchDataReason = null;
                this.isUnableToFetchData = true;
            });
    }

    private getCalAmpAvlData() {
        const sources: Observable<any>[] = [];
        this.assetTrackingData.assets.forEach((device: CalAmpDevice) => {
            sources.push(this.assetTrackingManager.getLatestAvlEventsForDevice(device.esn).pipe(take(1)));
        });

        forkJoin(sources)
            .pipe(finalize(() => {
                if (!this.isManualMapRefresh) {
                    this.isBusy = false;
                    this.isManualDataRefresh = false;
                }
            }))
            .subscribe(resultsArray => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = new Date();  // data.lastUpdated;

                if (this.assetTrackingData != null) {
                    this.assetTrackingData.setAvlData(resultsArray);
                    const now = this.assetTrackingData.latestAvlEventDate.toDate();
                    this.lastStatusUpdateTimeString = `${this.userFormatService.toUserDateString(now)} ${this.userFormatService.toUserTimeString(now, true)}`;
                }

                if (!this.isWidgetInitialized) {
                    this.isBusy = false;
                    this.isWidgetInitialized = true;
                }

                // console.log('>>AVL Data Retrieved: ' + (Date.now() - startTime) + ' ms');

                this.getMotionLogs();
            }, error => {
                this.isBusy = false;
                this.isManualDataRefresh = false;
                this.dashMessageService.showGenericFetchErrorMessage();
            });
    }

    private getMotionLogs() {
        if (!this.assetTrackingData.hasAssets) { return; }

        const sources: Observable<any>[] = [];
        this.assetTrackingData.assets.forEach((device: CalAmpDevice) => {
            sources.push(this.assetTrackingManager.getMotionLogEventsForToday(device.esn).pipe(take(1)));
        });

        forkJoin(sources)
            .pipe(finalize(() => {
                // Pickup any changes to the Map icons or device details. - DON'T NEED THIS HERE.
                if (this.showMapDialog && this.isManualMapRefresh) {
                    this.setupMap();
                }

                this.isBusy = false;
                this.isManualDataRefresh = false;
                this.isManualMapRefresh = false;
            }))
            .subscribe(resultsArray => {
                // console.log('>>Motion Log Data Retrieved: ' + (Date.now() - startTime) + ' ms');

                this.assetTrackingData.setMotionLogData(resultsArray);

                if (!this.isAnimating) { this.setEventsSlider(); }
            }, error => {
                this.dashMessageService.showGenericFetchErrorMessage();
            });
    }

    // TODO: TEMPORARY?
    private get fallbackMapLocation(): CalAmpMapInfo {
        return new CalAmpMapInfo(0,0);

        // if (!environment.isDemoMode && this.authManager.dashAuthenticatedUser?.email !== 'intellidash2020@toro.com') {
        //     return new CalAmpMapInfo(this.authManager.dashAuthenticatedUser?.latitude, this.authManager.dashAuthenticatedUser?.longitude);
        // }

        // **************************************************************************************
        // The KML file will properly position the map. The KML will determine map center point.
        // **************************************************************************************
        // switch (this.gfCalAmpAccountId) {
        //     case 20388: // Bear Path
        //         return new CalAmpMapInfo(44.840531, -93.509873);
        //     case 30498: // Austin CC
        //         return new CalAmpMapInfo(30.3436865, -97.79304);
        //     case 30499: // Sun City
        //         return new CalAmpMapInfo(33.779842, -116.28383);
        //     case 38417: // FargoCC
        //         return new CalAmpMapInfo(46.8406, -96.7907);
        //     case 38342: // CC of the Rockies
        //         return new CalAmpMapInfo(39.6343751,-106.5659478);
        //     case 38343: // Cordillera
        //         return new CalAmpMapInfo(34.6442548,-113.1288683);
        //     case 38087: // The Rookery
        //         break;
        //     case 26377: // Shady Canyon
        //         break;
        //     case 39402: // Mission Hills at Rancho Mirage
        //         break;
        //     case 40592: // Palm Aire
        //         break;
        //     case 40809: // Brandon Dunes
        //         break;
        //     default:
        //         return new CalAmpMapInfo(this.authManager.dashAuthenticatedUser?.latitude, this.authManager.dashAuthenticatedUser?.longitude);
        // }
    }

    private setupMap() {
        const location = new GoogleMapLocation({
            lat: this.selectedAsset ? this.selectedAsset.currentLocation.latitude : this.fallbackMapLocation.latitude,
            lng: this.selectedAsset ? this.selectedAsset.currentLocation.longitude : this.fallbackMapLocation.longitude
        });

        this.mapOptions = {
            center: location,
            // zoom: this.mapZoomLevel,
            minZoom: this.MAP_MIN_ZOOM,
            zoom: 4,
            mapTypeId: 'satellite',
            gestureHandling: 'cooperative',
            scaleControl: true,
        };

        const markerHeight = 44;
        const markerWidth = markerHeight / 1.49281713;      // divided by height:width ration
        const svgPrefix = `<svg xmlns="http://www.w3.org/2000/svg" height="${markerHeight}" width="${markerWidth}" `;

        const markers: any[] = [];
        this.assetTrackingData.assets.forEach(asset => {
            // If there aren't any calAmpAvlEvents for the asset, bail out.
            if (asset.currentLocation == null) { return; }

            // Get SVG Marker for current device.
            const svgMarker = svgPrefix + WidgetGeoFencingComponent.mapIconForAssetType(asset.deviceType);

            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(asset.currentLocation.latitude, asset.currentLocation.longitude),
                map: this.googleMap,
                icon: {
                    anchor: new google.maps.Point(markerWidth / 2, markerHeight),
                    url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgMarker),
                }
            });

            marker['esn'] = asset.esn;
            markers.push(marker);

            google.maps.event.addListener(marker, 'click', () => {
                this.mapMarkerClick(marker);
            });
            google.maps.event.addListener(marker, 'position_changed', () => {
                this.mapPositionChange(marker);
            });
        });

        // const kmlLayer = new google.maps.KmlLayer({
        //     url: "http://api.flickr.com/services/feeds/geo/?g=322338@N20&lang=en-us&format=feed-georss"
        //     // url: "http://localhost:5000/assets/kml/Sandpiper.kml"
        // });
        // kmlLayer.setMap(this.googleMap);
        //
        // this.googleMap.data.loadGeoJson('')

        this.mapOverlays = markers;

        // Show the Info Window for the selected asset if one has been selected.
        if (this.selectedAsset) {
            const selectedMarker = markers.find(m => m.esn === this.selectedAsset.esn);
            if (selectedMarker) { this.mapMarkerClick(selectedMarker); }
        }
    }

    mapMarkerClick(marker) {
        marker.infoWindow = new google.maps.InfoWindow({
            content: this.getInfoWindowContent(marker, true)
        });

        marker.infoWindow.open(this.googleMap, marker);
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetViewMapAssetDetails, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    mapPositionChange(marker) {
        if (!marker.infoWindow) { return; }

        marker.infoWindow.setContent(this.getInfoWindowContent(marker));
    }

    private getInfoWindowContent(marker, bypassOpenCheck = false) {
        if (!bypassOpenCheck && !marker.infoWindow) { return; }

        const selectedAsset = this.assetTrackingData.assets.find(a => a.esn === marker.esn);
        if (selectedAsset === null || selectedAsset === undefined) return;

        const sliderTimeStatus = selectedAsset.getStatusAtDateTime(this.sliderDateTime);
        return '<div class="asset-info" style="display: flex; flex-direction: column;">' +
            '<div class="asset-info-text">' +
            '<div class="asset-info-name">' + selectedAsset.name + '</div>' +
            '</div>' +
            '<div class="asset-icon-container">' +
            '<div class="asset-info-status">' + this.htmlForAssetStatus(sliderTimeStatus, true) +
            '</div>' +
            '</div>';
    }

    private updateDeviceLocations(sliderIndex: number) {
        const time = this.latestMotionLogFixDate.clone().subtract((this.eventSliderMax - sliderIndex) * 10, 'second').toDate();
        this.sliderDateTime = time;

        this.userSlideDateTimeString =
            `${this.dayNames[time.getDay()]} ${this.userFormatService.toUserDateString(time)} ${this.userFormatService.toUserTimeString(time, true)}`;

        this.assetTrackingData.assets.forEach(asset => {
            const motionLogInfo = asset.getPositionAtDateTime(time);
            if (motionLogInfo != null && this.mapOverlays != null) {
                const relatedMapOverlay = this.mapOverlays.find(mo => mo.esn === asset.esn);
                relatedMapOverlay?.setPosition(new google.maps.LatLng(motionLogInfo.latitude, motionLogInfo.longitude));
            }
        });
    }

    private static mapIconForAssetType(type) {
        switch (type) {
            case AssetTrackingDeviceType.GreensRide:
                return TrackingConstants.greens_ride_icon;
            case AssetTrackingDeviceType.GreensWalk:
                return TrackingConstants.greens_walk_icon;
            case AssetTrackingDeviceType.OutCross:
                return TrackingConstants.out_cross_icon;
            case AssetTrackingDeviceType.ProCore:
                return TrackingConstants.pro_core_icon;
            case AssetTrackingDeviceType.ProForce:
                return TrackingConstants.pro_force_icon;
            case AssetTrackingDeviceType.ProPass:
                return TrackingConstants.pro_pass_icon;
            case AssetTrackingDeviceType.ProSweep:
                return TrackingConstants.pro_sweep_icon;
            case AssetTrackingDeviceType.Reels:
                return TrackingConstants.reels_icon;
            case AssetTrackingDeviceType.Rotary:
                return TrackingConstants.rotary_icon;
            case AssetTrackingDeviceType.SandPro:
                return TrackingConstants.sand_pro_icon;
            case AssetTrackingDeviceType.Sprayers:
                return TrackingConstants.sprayers_icon;
            case AssetTrackingDeviceType.Utility:
                return TrackingConstants.utility_icon;
            default:
                return TrackingConstants.unknown_icon;
        }
    }

    private htmlForAssetStatus(status, showText) {
        switch (status) {
            case CalAmpDeviceStatus.Idle:
                return '<div class="toro-asset-status-icon circle" style="background-color: orange;"></div>'
                    + (showText ? `<div class="toro-asset-status-label">${this.translateService.instant('STRINGS.IDLE')}</div>` : '');
            case CalAmpDeviceStatus.Transport:
                return '<div class="toro-asset-status-icon circle" style="background-color: blue;"></div>'
                    + (showText ? `<div class="toro-asset-status-label">${this.translateService.instant('STRINGS.TRANSPORTING')}</div>` : '');
            case CalAmpDeviceStatus.Work:
                return '<div class="toro-asset-status-icon circle" style="background-color: green;"></div>'
                    + (showText ? `<div class="toro-asset-status-label">${this.translateService.instant('STRINGS.WORKING')}</div>` : '');
            case CalAmpDeviceStatus.Off:
            default:
                return '<div class="toro-asset-status-icon asset-status off"><div class="off-inner"></div></div>'
                    + (showText ? `<div class="toro-asset-status-label">${this.translateService.instant('STRINGS.OFF')}</div>` : '');
        }
    }

    private setEventsSlider() {
        this.latestMotionLogFixDate = this.assetTrackingData.latestMotionLogDate;
        this.eventSliderMax = this.latestMotionLogFixDate.diff(this.assetTrackingData.earliestMotionLogDate, 'minutes') * 6;

        this.eventSliderIndex = this.eventSliderMax;

        this.updateDeviceLocations(this.eventSliderIndex);
    }

    private showCalAmpAccountNotConfiguredDisplay() {
        this.calAmpAccountNotConfigured = true;
        this.isBusy = false;
    }

    private showAccountIdDialog() {
        this.newAccountId = this.gfCalAmpAccountId;
        this.isAccountIdDialogDisplayed = true;

        const eventDetails = `CourseId: ${this.gfCalAmpAccountId}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetShowAccountIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

}
