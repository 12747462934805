export class PwWind {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    speed = 0;
    gust = 0;
    direction: number;
    unit: string;
}
