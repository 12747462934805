import { IWidgetDataSettings } from '../../_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import WidgetType = ToroEnums.WidgetType;

export class PlaybooksConfig implements IWidgetDataSettings{

    constructor(dataSettings: IWidgetDataSettings) {
        if (dataSettings) {
            this.widgetType = dataSettings.widgetType;
            this.showStaleDataAlert = dataSettings.showStaleDataAlert;
            this.dataRefreshIntervalMinutes = dataSettings.dataRefreshIntervalMinutes;
            this.staleDataThresholdMinutes = dataSettings.staleDataThresholdMinutes;
        }
    }

    selectedNutrientAreaId = '';
    selectedGddAreaId = '';

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;
}
