
export class SoilScoutRuleAction {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    email: string;
}
