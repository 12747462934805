import * as moment from 'moment';
import { PlaybooksGddSinceAppItem } from './playbooks-gdd-since-app-item.model';

export class PlaybooksGddSinceAppArea {

    constructor(json: any = null) {
        if (json) {
            // Object.assign(this, json);

            // Handle camel casing because this one api returns Pascal Cased values.
            if (json.AreaName) { this.areaName = json.AreaName; }
            if (json.ApplicationLogDate) { this.applicationLogDate = moment(json.ApplicationLogDate, 'LLL').toDate(); }
            if (json.CreatedDate) { this.createdDate = moment(json.CreatedDate, 'LLL').toDate(); }
            if (json.GDDSinceApp) { this.gddSinceApp = new PlaybooksGddSinceAppItem(json.GDDSinceApp); }
        }
    }

    areaName: string;
    gddSinceApp: PlaybooksGddSinceAppItem;
    applicationLogDate: Date;
    createdDate: Date;
}
