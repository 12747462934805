import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { MyTurfManagerService } from '../../../../../api/my-turf/my-turf-manager.service';
import { MyTurfTokenInfo } from '../../../../../api/auth/models/my-turf-token-info.model';
import { MyTurfUnavailableAssets } from '../../../../../api/my-turf/models/my-turf-unavailable-assets.model';
import { MyTurfWidgetBase } from '../_my-turf-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import HttpStatusCode = ToroEnums.HttpStatusCode;

@Component({
    selector: 'toro-widget-my-turf-maintenance',
    templateUrl: './widget-my-turf-maintenance.component.html',
    styleUrls: ['./widget-my-turf-maintenance.component.less']
})
export class WidgetMyTurfMaintenanceComponent extends MyTurfWidgetBase implements OnInit {

    iconColor = '#bbb';
    title = 'WIDGET.MY_TURF_MAINTENANCE';
    subtitle = 'STRINGS.ASSETS_UNDER_REPAIR_OR_MAINTENANCE';

    environment = environment;

    assets: MyTurfUnavailableAssets[] = [];
    showDetailsDialog = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected myTurfManager: MyTurfManagerService,
                protected translateService: TranslateService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, myTurfManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.MyTurfMaintenanceWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        if (!this.isMyTurfUserLoggedIn) { return; }
        if (this.isUnableToFetchData) { this.isBusy = true; }

        this.myTurfManager.getUnavailableAssets()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((response: MyTurfUnavailableAssets[]) => {
                this.assets = response;
                this.lastUpdateTimestamp = new Date();
                this.clearIsUnableToFetchData();
            }, error => {
                this.isMyTurfUserLoggedIn = error.status !== HttpStatusCode.FailedDependency;
                if (!this.isMyTurfUserLoggedIn) { this.myTurfUserName = ''; }

                this.setIsUnableToFetchData(!this.isMyTurfUserLoggedIn ? 'ERR_MSG.MY_TURF_USER_NOT_LOGGED_IN' : 'ERR_MSG.SERVER_ERROR');
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            { label: ToroUtils.Translate.instant('STRINGS.SIGN_IN_TO_MY_TURF'), icon: 'pi pi-fw pi-sign-in', command: this.doMyTurfLogin.bind(this) },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    showAssets() {
        this.showDetailsDialog = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.MyTurfMaintenanceWidgetViewAssets, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    doMyTurfLogin() {
        window.location.href = this.myTurfManager.myTurfLoginUrl;
        this.analyticsService.widgetEvent(AnalyticsEvent.MyTurfWidgetSignIn, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onMyTurfLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_MY_TURF_SITE' });

        if (environment.isDemoMode) {
            setTimeout(() => window.open(this.myTurfManager.demoSiteLink, '_blank'), 1000);
            setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
            return;
        }

        this.myTurfManager.getToken()
            .pipe(take(1))
            .subscribe((tokenInfo: MyTurfTokenInfo) => {
                this.myTurfManager.goToMyTurfSite('accounts/login', tokenInfo.token, '/assets');
                this.broadcastService.toggleSystemOverlay.next({ show: false });
                this.analyticsService.widgetEvent(AnalyticsEvent.MyTurfWidgetGoToMyTurf, AnalyticsCategory.Interaction, this.analyticsWidgetName);
            }, error => {
                this.dashMessageService.showMyTurfAuthenticationErrorMessage();
            });
    }
}

