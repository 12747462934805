import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import WeatherAverageType = ToroEnums.WeatherAverageType;

export class WeatherAveragesDatum {

    constructor(type: WeatherAverageType, value: string, units: string, color?: string, isMetric?: boolean) {
        this.type = type;
        this.value = value;
        this.units = units;
        if (color != null) this.color = color;
        if (isMetric != null) this.isMetric = isMetric
    }

    type: WeatherAverageType
    value: string;
    units: string;
    color: string = "black";
    isMetric = false;

    get label(): string {
        switch (this.type) {
            case ToroEnums.WeatherAverageType.Temperature:
                return 'STRINGS.TEMPERATURE';
            case ToroEnums.WeatherAverageType.Et:
                return 'STRINGS.EVAPOTRANSPIRATION';
            case ToroEnums.WeatherAverageType.Humidity:
                return 'STRINGS.HUMIDITY';
            case ToroEnums.WeatherAverageType.Precipitation:
                return 'STRINGS.PRECIPITATION';
            default:
                throw new Error('Unsupported WeatherAverageType')
        }
    }
}
