import { ActivityApiService } from './activity-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToroEnums } from '../../common/enumerations/toro.enums';

import TopologyCategory = ToroEnums.TopologyCategory;

@Injectable({
    providedIn: 'root'
})
export class ActivityManagerService {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private activityApiService: ActivityApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    requestTopologyUpdate(categories = [TopologyCategory.AreaTopology]): Observable<void> {
        return this.activityApiService.requestTopologyUpdate(categories);
    }

}
