<a *ngIf="!isMobile" [href]="deviceInfo?.connectUrl" target="_blank" (click)="onConnectDeviceClick()">
    <ng-container [ngTemplateOutlet]="cardBody"></ng-container>
</a>

<ng-container *ngIf="isMobile" [ngTemplateOutlet]="cardBody"></ng-container>

<ng-template #cardBody>
    <div class="nsn-connect-device-card-container toro-component-shield-host">
        <div class="ncd-status-container">
            <fa-icon icon="circle"
                     [ngClass]="onlineStatusStyle"
                     [pTooltip]="(deviceInfo?.isOnline ? 'STRINGS.ONLINE' : 'STRINGS.OFFLINE') | translate"
                     tooltipPosition="top"
            ></fa-icon>
        </div>
        <div class="ncd-device-name-container">
            {{deviceInfo?.computerName}}
        </div>

        <toro-component-shield></toro-component-shield>
    </div>
</ng-template>
