import LanguageCode = ToroEnums.LanguageCode;
import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { DashAuthenticatedUser } from '../../api/auth/models/dash-authenticated-user.model';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ToroEnums } from '../enumerations/toro.enums';
import { WizardSettings } from '../../core/dashboard/setup-wizard/models/wizard-settings.model';

enum cacheKey {
    DashAuthenticatedUser = 'dashAuthenticatedUser',
    DashAccessToken = 'dashAccessToken',
    DashRefreshToken = 'dashRefreshToken',
    AssetTrackingAccessToken = 'assetTrackingAccessToken',
    AssetTrackingRefreshToken = 'assetTrackingRefreshToken',
    GsaAccessToken = 'gsaAccessToken',
    Language = 'language',
    SoilScoutAccessToken = 'soilScoutAccessToken',
    SoilScoutRefreshToken = 'soilScoutRefreshToken',
    WidgetsLocked = 'widgetsLocked',
    WizardActive = 'wizardActive',
    WizardActiveWidgets = 'WizardActiveWidgets',
    WizardConfiguredIntegrations = 'WizardConfiguredIntegrations',
    WizardSettings = 'wizardSettings'
}

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {

    constructor() { }

    set dashAuthenticatedUser(value: DashAuthenticatedUser) {
        if (environment.isDemoMode) return;

        localStorage[cacheKey.DashAuthenticatedUser] = value ? JSON.stringify(value) : null;
        localStorage[cacheKey.DashAccessToken] = value ? value.token : null;
        localStorage[cacheKey.DashRefreshToken] = value ? value.refreshToken : null;
    }

    get dashAuthenticatedUser(): DashAuthenticatedUser {
        const cachedDashAuthenticatedUser = localStorage[cacheKey.DashAuthenticatedUser];
        return cachedDashAuthenticatedUser ? JSON.parse(cachedDashAuthenticatedUser) : null;
    }

    set dashApiAccessToken(value: string) {
        if (environment.isDemoMode) return;

        localStorage[cacheKey.DashAccessToken] = value;
    }

    get dashApiAccessToken(): string {
        return localStorage[cacheKey.DashAccessToken] || '';
    }

    set dashApiRefreshToken(value: string) {
        localStorage[cacheKey.DashRefreshToken] = value;
    }

    get dashApiRefreshToken(): string {
        return localStorage[cacheKey.DashRefreshToken] || '';
    }

    set assetTrackingApiAccessToken(value: string) {
        localStorage[cacheKey.AssetTrackingAccessToken] = value;
    }

    get assetTrackingApiAccessToken(): string {
        return localStorage[cacheKey.AssetTrackingAccessToken] || '';
    }

    set assetTrackingApiRefreshToken(value: string) {
        localStorage[cacheKey.AssetTrackingRefreshToken] = value;
    }

    get assetTrackingApiRefreshToken(): string {
        return localStorage[cacheKey.AssetTrackingRefreshToken] || '';
    }

    set soilScoutAccessToken(value: string) {
        localStorage[cacheKey.SoilScoutAccessToken] = value;
    }

    get soilScoutAccessToken(): string {
        return localStorage[cacheKey.SoilScoutAccessToken] || '';
    }

    set soilScoutRefreshToken(value: string) {
        localStorage[cacheKey.SoilScoutRefreshToken] = value;
    }

    get soilScoutRefreshToken(): string {
        return localStorage[cacheKey.SoilScoutRefreshToken] || '';
    }

    set gsaApiAccessToken(value: string) {
        if (environment.isDemoMode) return;

        localStorage[cacheKey.GsaAccessToken] = value;
    }

    get gsaApiAccessToken(): string {
        return localStorage[cacheKey.GsaAccessToken] || '';
    }

    set language(value: string) {
        localStorage[cacheKey.Language] = value;
    }

    get language(): string {
        return localStorage[cacheKey.Language] || LanguageCode.English_US;
    }

    set widgetsLocked(value: boolean) {
        localStorage[cacheKey.WidgetsLocked] = value;
    }

    get widgetsLocked(): boolean {
        return localStorage[cacheKey.WidgetsLocked] == null || localStorage[cacheKey.WidgetsLocked] === 'true'
    }

    set wizardActive(value: boolean) {
        localStorage[cacheKey.WizardActive] = value;
    }

    get wizardActive(): boolean {
        return localStorage[cacheKey.WizardActive] != null && localStorage[cacheKey.WizardActive] === 'true'
    }

    set wizardActiveWidgets(value: {type: WidgetType, isSelected: boolean}[]) {
        localStorage[cacheKey.WizardActiveWidgets] = JSON.stringify(value);
    }

    get wizardActiveWidgets(): {type: WidgetType, isSelected: boolean}[] {
        const activeWidgets = localStorage[cacheKey.WizardActiveWidgets];
        return activeWidgets ? JSON.parse(activeWidgets) : null;
    }

    set wizardConfiguredIntegrations(value: WizardIntegration[]) {
        localStorage[cacheKey.WizardConfiguredIntegrations] = JSON.stringify(value);
    }

    get wizardConfiguredIntegrations(): WizardIntegration[] {
        const integrations = localStorage[cacheKey.WizardConfiguredIntegrations];
        return integrations ? JSON.parse(integrations) : null;
    }

    set wizardSettings(value: WizardSettings) {
        localStorage[cacheKey.WizardSettings] = JSON.stringify(value);
    }

    get wizardSettings(): WizardSettings {
        const settings = localStorage[cacheKey.WizardSettings];
        return settings ? JSON.parse(settings) : null;
    }
}
