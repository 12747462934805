export class DashApiLoginInfo {

    constructor(ssoToken: string) {

        this.ssoTokenData.token = ssoToken;

    }

    grantType = 'sso_token';
    ssoTokenData = {
        username: 'IntelliDashGIS2020@toro.com',
        token: '',
        refreshToken: '',
        expires: '2020-12-31T00:00:00'
    };

}
