<div class="wiz-integration-content">
    <div id="full-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.CONNECT_ASSET_TRACKING_TO_APP' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
        </toro-step-group>

        <toro-step-action>
            <div>
                <img src="assets/images/wizard/assetTracking.png" alt="Asset Tracking image">
            </div>
            <div class="wic-cal-amp-info">
                <div>{{'CASE_SENSITIVE.ENTER_CAL_AMP_ACCOUNT_ID' | translate}}</div>
                <p-inputNumber
                        [(ngModel)]="accountId"
                        [useGrouping]="false"
                        [size]="30"
                        [placeholder]="'CASE_SENSITIVE.ACCOUNT_ID' | translate"
                        (paste)="onPastOrCutAccountId($event)"
                        (cut)="onPastOrCutAccountId($event)"
                ></p-inputNumber>
                <div *ngIf="showError" id="wic-cal-amp-error">{{'SPECIAL_MSG.CAL_AMP_DATA_COULD_NOT_BE_RETRIEVED' | translate}}</div>
                <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)"></toro-waiting-indicator>
            </div>
        </toro-step-action>
    </div>
</div>

<ng-template #step1>
    <div id="step1">
        {{'SPECIAL_MSG.ASSET_TRACKING_SW_ENTER_CODE' | translate}}
    </div>
</ng-template>

