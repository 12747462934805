<div class="wiz-integration-content">
    <div id="full-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.ADD_LOGIN_INFORMATION_FOR_SOIL_SCOUT' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
        </toro-step-group>

        <toro-step-action>
            <div>
                <img src="assets/images/wizard/soil-scout-logo-full.png" alt="Soil Scout Image">
            </div>
            <div class="wic-soil-scout-info">

                <div id="ss-login-dlg-container">
                    <span class="field-lbl">{{'STRINGS.USERNAME' | translate }}</span>
                    <input type="text" pInputText
                           [(ngModel)]="username"
                           [size]="30"
                           [placeholder]="'STRINGS.USERNAME' | translate"
                    />

                    <span class="field-lbl">{{'STRINGS.PASSWORD' | translate }}</span>
                    <input type="password" pPassword
                           [(ngModel)]="password"
                           [promptLabel]="'STRINGS.PASSWORD' | translate"
                           [feedback]="false"
                    />
                </div>

                <div *ngIf="showError" id="wic-soil-scout-error">{{'SPECIAL_MSG.SOIL_SCOUT_DATA_COULD_NOT_BE_RETRIEVED' | translate}}</div>

                <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)"></toro-waiting-indicator>
            </div>
        </toro-step-action>
    </div>
</div>

<ng-template #step1>
    <div id="step1">
        {{'SPECIAL_MSG.SOIL_SCOUT_SW_ENTER_LOGIN_INFO' | translate}}
    </div>
</ng-template>

