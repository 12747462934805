import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { GcsaaChannelItem } from '../../../../../api/gcsaa-newsfeed/models/gcsaa-channel-item.model';

@Component({
  selector: 'toro-gcsaa-feed-item-card',
  templateUrl: './gcsaa-feed-item-card.component.html',
  styleUrls: ['./gcsaa-feed-item-card.component.less']
})
export class GcsaaFeedItemCardComponent {
  @HostBinding('class') class = 'toro-gcsaa-feed-item-card';

  @Output() showModal = new EventEmitter<GcsaaChannelItem>();

  @Input() item: GcsaaChannelItem;

  // ===========================================================================================================================================================
  // C'tor and Lifecycle Hooks
  // ===========================================================================================================================================================

  constructor() { }

  // ===========================================================================================================================================================
  // Event Handlers
  // ===========================================================================================================================================================

  onSelectItem() {
    this.showModal.emit(this.item);
  }

}
