
export class TokenKeyModel {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    tokenKey: string;
}
