import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { DashboardWidgetInfo } from '../../../api/widgets/models/dashboard-widget-info.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { LocalCacheService } from '../../../common/services/local-cache.service';
import { take } from 'rxjs/operators';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { WidgetManagerService } from '../../../api/widgets/widget-manager.service';
import { WizardStep } from './models/wizard-step.model';

@UntilDestroy()
@Component({
    selector: 'toro-setup-wizard',
    templateUrl: './setup-wizard.component.html',
    styleUrls: ['./setup-wizard.component.less']
})
export class SetupWizardComponent implements OnInit {

    @Output() closeWizard = new EventEmitter()
    @Output() doConnectChange = new EventEmitter<boolean>();

    private readonly MIN_SIZE_FOR_WIZARD = 767;

    steps: WizardStep[] = [];
    stepIndex = 0;
    isDoSetup = false;
    canConnect = false;
    selectedWidgets: { type: WidgetType, isSelected }[] = [];
    showCancelPrompt = false;
    isConnectionCheckComplete = false;
    isInitialLoad = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private deviceManager: DeviceManagerService,
                private localCacheService: LocalCacheService,
                private widgetManager: WidgetManagerService
    ) { }

    ngOnInit(): void {
        this.configureWizardSteps()

        // Get the list of selected widgets.
        const dashUser = this.authManager.dashAuthenticatedUser;
        this.widgetManager.getDashboardWidgets(dashUser.sid, dashUser.siteId, (this.widgetManager.isMobileGridDisplay ? 'mobile' : 'desktop'))
            .pipe(take(1),)
            .subscribe((activeWidgets: DashboardWidgetInfo[]) => {
                activeWidgets.forEach(w => this.selectedWidgets.push({type: w.widgetType, isSelected: true}))

                // Use locally cached list of selected widgets if wizardActive
                const cachedSelectedList = this.localCacheService.wizardActiveWidgets;
                if (cachedSelectedList != null) this.selectedWidgets = cachedSelectedList;
            })

        this.broadcastService.setupToggleWidgetState
            .pipe(untilDestroyed(this))
            .subscribe((item: { type: WidgetType, isEnabled: boolean }) => {
                if (item.isEnabled) {
                    const widget = this.selectedWidgets.find(w => w.type === item.type);
                    if (widget != null) return;

                    this.selectedWidgets.push({ type: item.type, isSelected: true })
                } else {
                    this.selectedWidgets = this.selectedWidgets.filter(w => w.type !== item.type);
                }
            });

        this.broadcastService.setupCompleteIntegration
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onBackClick());

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (window.innerWidth <= this.MIN_SIZE_FOR_WIZARD) this.onCancelWizard();
            });

        this.localCacheService.wizardActive = true;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onPreviousStep() {
        if (this.stepIndex === 0) return;
        --this.stepIndex;
    }

    onNextStep() {
        if (this.stepIndex === this.steps.length - 1) return;
        ++this.stepIndex;
    }

    onSkip() {
        this.showCancelPrompt = true;

        // this.resetWizardState();
        // this.closeWizard.emit();
    }

    onCancelWizard() {
        this.resetWizardState();
        this.closeWizard.emit();
    }

    onFinish() {
        this.resetWizardState();
        this.broadcastService.widgetSelectionChange.next(this.selectedWidgets.slice());
        this.closeWizard.emit();
    }

    onBackClick() {
        this.isDoSetup = false;
    }

    onCanConnect(value: boolean) {
        this.canConnect = value;
    }

    onConnect() {
        if (!this.canConnect) return;

        // this.doConnect = true;
        this.broadcastService.setupDoConnect.next(null);
    }

    onConnectionCheckComplete(isComplete: boolean) {
        this.isConnectionCheckComplete = isComplete;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private configureWizardSteps() {
        this.steps = [
            new WizardStep({
                sidebarTitle: 'CASE_SENSITIVE.ADD_INTEGRATIONS',
                sidebarDescription: 'SPECIAL_MSG.WIZARD_STEP_ADD_INTEGRATIONS_DESC',
                contentTitle: 'CASE_SENSITIVE.INTEGRATIONS_AND_CONNECTED_APPS',
                contentDescription: 'SPECIAL_MSG.WIZARD_STEP_ADD_INTEGRATIONS_DESC',
            }),
            new WizardStep({
                sidebarTitle: 'CASE_SENSITIVE.ENABLING_WIDGETS',
                sidebarDescription: 'SPECIAL_MSG.WIZARD_STEP_ENABLING_WIDGETS_DESC',
                contentTitle: 'CASE_SENSITIVE.ENABLING_WIDGETS',
                contentDescription: 'SPECIAL_MSG.WIZARD_STEP_ENABLING_WIDGETS_DESC',
            }),
            new WizardStep({
                sidebarTitle: 'CASE_SENSITIVE.COMPLETE_SETUP',
                sidebarDescription: 'SPECIAL_MSG.WIZARD_STEP_COMPLETE_SETUP_DESC',
                contentTitle: 'CASE_SENSITIVE.COMPLETE_SETUP',
                contentDescription: 'SPECIAL_MSG.WIZARD_STEP_COMPLETE_SETUP_DESC',
            })
        ]
    }

    private resetWizardState() {
        this.localCacheService.wizardActive = false;
        this.localCacheService.wizardConfiguredIntegrations = null;
        this.localCacheService.wizardActiveWidgets = null;
        this.localCacheService.wizardSettings = null;
    }
}
