import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { SoilScoutChartData } from '../models/soil-scout-chart-data.model';
import { SoilScoutDevice } from '../../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGroup } from '../../../../../api/soil-scout/models/soil-scout-group.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-modal',
    templateUrl: './soil-scout-modal.component.html',
    styleUrls: ['./soil-scout-modal.component.less']
})
export class SoilScoutModalComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() gotoSite = new EventEmitter();
    @Output() sensorTypeChange = new EventEmitter<SoilScoutSensorType>();

    @Input() title = '';
    @Input() isMiniModal = false;
    @Input() chartData: SoilScoutChartData;
    @Input() selectedSensorType = SoilScoutSensorType.Moisture

    private readonly transitionTimeInMs = 500;
    protected readonly SoilScoutSensorType = SoilScoutSensorType;
    protected isMobile = false;

    isSensorChartPanelVisible = false;
    isSensorChartPanelDisplayed = false;
    selectedSoilScout: SoilScoutDevice | SoilScoutGroup;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService) { }

    ngOnInit(): void {

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.onClose();
            });

        this.deviceManager.gridsterMobileModeChange
            .pipe(untilDestroyed(this))
            .subscribe((state: { isMobileMode: boolean }) => {
                this.isMobile = state.isMobileMode;
            });

        this.isMobile = this.deviceManager.isGridsterInMobileMode;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onGotoSite() {
        this.gotoSite.next(null);
    }

    onClose() {
        this.cancel.next(null);
    }

    onSensorSelection(sensorType: SoilScoutSensorType) {
        this.selectedSensorType = sensorType;
        this.sensorTypeChange.emit(sensorType);
    }

    onShowSensorDetails(scout: SoilScoutGroup | SoilScoutDevice) {
        this.selectedSoilScout = scout;

        this.toggleSensorChartPanel();
    }

    toggleSensorChartPanel() {
        if (!this.isSensorChartPanelVisible) {
            this.isSensorChartPanelVisible = true;
            setTimeout(() => this.isSensorChartPanelDisplayed = true)
        } else {
            this.isSensorChartPanelDisplayed = false;
            setTimeout(() => this.isSensorChartPanelVisible = false, this.transitionTimeInMs);
        }
    }
}
