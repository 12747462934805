<div id="pogo-zone-card-container" [ngClass]="{'is-selected': isSelected}" (click)="selectZone()">
    <div id="zone-name" class="prevent-select">{{ zone?.zone_name }}</div>
    <div id="zone-count-n-status" class="prevent-select">
        <div id="zone-ico-container" [ngStyle]="{'color': ratingColor}">
            <fa-icon icon="circle"></fa-icon>
            <span *ngIf="dataType == PogoTopNav.Moisture" [class]="ratingIcon"></span>
        </div>
        <span id="zone-data">{{zoneData}}</span>
        <span *ngIf="isMobile" id="mobile-chevron"><fa-icon icon="chevron-right"></fa-icon></span>
    </div>
</div>
