export class TaskTrackerCompanyDepartment {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    id: number;
    package: string;
    includes: string[];

}
