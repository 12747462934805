<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>
        <div id="toro-dlg-header-container">
            <div class="toro-flex-child title">{{title | translate}}</div>
            <div *ngIf="lastUpdateTimeString != null" class="tetl-last-updated">
                {{'STRINGS.LAST_UPDATED' | translate | titlecase}}:
                <span class="tetl-last-updated-time">{{lastUpdateTimeString}}</span>({{'STRINGS.LOCAL_COURSE_TIME' | translate}})
            </div>
        </div>
    </p-header>

    <div id="toro-equipment-tracking-list-dlg-container">
        <div id="tetl-table-container" >
            <ng-content></ng-content>
        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="close()"></p-button>
        <p-button *ngIf="showRefreshButton" type="button" [label]="'STRINGS.REFRESH' | translate" [disabled]="disableRefreshButton" (onClick)="onRefresh()"></p-button>
    </p-footer>
</p-dialog>
