import * as moment from 'moment';
import { AppInjector } from '../../../demo/demo.module';
import { DashUserPreferences } from '../../dash-user/models/dash-user-preferences.model';
import { DemoModeMockDataService } from '../../../demo/demo-mode-mock-data.service';
import { environment } from '../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

enum tokenKey {
    Email = 'email',
    Email2 = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    GsaSiteId = 'siteId',
    MyTurfToken = 'myTurfToken',
    NsnGsaToken = 'nsnGsaToken',
    NsnFacilityId = 'nsnFacilityId',
    NsnLatitude = 'nsnLatitude',
    NsnLongitude = 'nsnLongitude',
    NsnSiteName = 'nsnSiteName',
    PlaybooksCourseId = 'playbooksCourseId',
    Sid = 'sid',
    SiteId = 'siteId',
    UniqueName = 'unique_name',
    LynxCloudInstallationIds = 'lynxCloudInstallationIds'
}

export class DashAuthenticatedUser {
    private _decodedToken: string;
    private dashUserPreferences: DashUserPreferences;
    private _gsaDecodedToken: string;
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.expiration = moment.utc(json.expiration).toDate();
            this.refreshTokenExpiration = moment.utc(json.refreshTokenExpiration).toDate();
            this.dashUserPreferences = new DashUserPreferences();
        }

        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    token: string;
    expiration: Date;
    refreshToken: string;
    refreshTokenExpiration;
    lastDashApiUrl: string;

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get cacheKey(): string {
        return `${this.sid}-${this.siteId}`;
    }

    get gsaSiteId(): string {
        return this.parseGsaTokenValue(tokenKey.GsaSiteId);
    }

    get gsaToken(): string {
        return this.parseTokenValue(tokenKey.NsnGsaToken);
    }

    get myTurfToken(): string {
        return this.parseTokenValue(tokenKey.MyTurfToken);
    }

    get nsnFacilityId(): string {
        return this.parseTokenValue(tokenKey.NsnFacilityId);
    }

    get playbooksCourseId(): string {
        if (environment.isDemoMode) { return this.demoModeMockDataService.playbooksCourseId; }
        return this.parseTokenValue(tokenKey.PlaybooksCourseId);
    }

    get sid(): string {
        return this.parseTokenValue(tokenKey.Sid);
    }

    get siteId(): number {
        return +this.parseTokenValue(tokenKey.SiteId);
    }

    get siteName(): string {
        if (environment.isDemoMode) { return this.demoModeMockDataService.locationName; }
        return this.parseTokenValue(tokenKey.NsnSiteName);
    }

    get language(): string {
        return this.dashUserPreferences.language;
    }

    get latitude(): number {
        return +this.parseTokenValue(tokenKey.NsnLatitude);
    }

    get longitude(): number {
        return +this.parseTokenValue(tokenKey.NsnLongitude);
    }

    get email(): string {
        const emailAddress = this.parseTokenValue(tokenKey.Email);
        if (emailAddress && emailAddress !== '') { return emailAddress; }

        return this.parseTokenValue(tokenKey.Email2);
    }

    set userPreferences(userPreferences: DashUserPreferences) {
        if (!(userPreferences instanceof DashUserPreferences)) return;

        this.dashUserPreferences = userPreferences;
    }

    get userPreferences(): DashUserPreferences {
        return this.dashUserPreferences;
    }

    get LynxCloudInstallationIds(): string[] {
        const idsString = this.parseTokenValue(tokenKey.LynxCloudInstallationIds);
        return idsString.split(',');
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get decodedToken(): any {
        if (!this._decodedToken) this._decodedToken = this.token ? new JwtHelperService().decodeToken(this.token) : null;

        return this._decodedToken;
    }

    private parseTokenValue(value: string): string {
        return this.decodedToken ? this.decodedToken[value] || '' : '';
    }

    private get gsaDecodedToken(): any {
        if (!this._gsaDecodedToken) this._gsaDecodedToken = this.gsaToken ? new JwtHelperService().decodeToken(this.gsaToken) : null;

        return this._gsaDecodedToken;
    }

    private parseGsaTokenValue(value: string): string {
        return this.gsaDecodedToken ? this.gsaDecodedToken[value] || '' : '';
    }
}
