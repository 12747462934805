
export class GetProgramTopoStation {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.id = json.Id;
            this.name = json.Name;
            this.stationId = json.StationId;

            delete this['Id'];
            delete this['Name'];
            delete this['StationId'];
        }
    }

    stationId: number;
    id: number;
    name: string;
}
