/* eslint-disable @angular-eslint/directive-class-suffix */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { Directive } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { IWidgetDataSettings } from '../../../../../api/_common/interfaces/widget-data-settings.interface';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import MessageSeverity = ToroEnums.MessageSeverity;

@UntilDestroy()
@Directive()
export abstract class LynxWidgetBase extends ToroDashboardWidget {
    private isTopologyMenuDisabled = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    protected constructor(protected activityManager: ActivityManagerService,
                          protected analyticsService: AnalyticsService,
                          protected broadcastService: BroadcastService,
                          protected dashMessageService: DashMessageService,
                          protected dashUserManager: DashUserManagerService,
                          protected deviceManager: DeviceManagerService,
                          protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);

        this.broadcastService.lynxTopologyUpdatePending
            .pipe(untilDestroyed(this))
            .subscribe((isPending: boolean) => {
                this.isTopologyMenuDisabled = isPending;
                this.setWidgetMenu();
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: this.translateService.instant('STRINGS.UPDATE_TOPOLOGY').toTitleCase(),
                icon: 'pi pi-fw pi-sitemap',
                command: this.updateTopology.bind(this),
                disabled: this.isTopologyMenuDisabled
            }
        );
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    get hideWidgetAlertBanner(): boolean {
        return !(<IWidgetDataSettings>this.associatedWidget.config).showStaleDataAlert;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateTopology() {
        // Disable the Update Topology menu item when an update is pending. Re-enable when request returns (below).
        this.broadcastService.lynxTopologyUpdatePending.next(true);

        this.activityManager.requestTopologyUpdate()
            .pipe(finalize(() => this.broadcastService.lynxTopologyUpdatePending.next(false)))
            .subscribe(response => {
                this.dashMessageService.showMessage('STRINGS.LYNX_TOPOLOGY_UPDATE', 'STRINGS.TOPOLOGY_UPDATE_REQUEST_SUCCESSFUL', MessageSeverity.Success);
            }, error => {
                this.dashMessageService.showGenericRequestErrorMessage();
            });

        this.analyticsService.widgetEvent(AnalyticsEvent.LynxUpdateTopologyRequest, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }
}
