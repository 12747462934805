import * as Highcharts from 'highcharts';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardManagerService } from '../../../../../api/turf-guard/turf-guard-manager.service';
import { TurfGuardWidgetBase } from '../_turf-guard-widget-base';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-widget-turf-guard-moisture',
    templateUrl: './widget-turf-guard-moisture.component.html',
    styleUrls: ['./widget-turf-guard-moisture.component.less']
})
export class WidgetTurfGuardMoistureComponent extends TurfGuardWidgetBase implements OnInit {

    iconColor = '#46a657';
    title = 'WIDGET.TURF_GUARD_MOISTURE';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                protected turfGuardManager: TurfGuardManagerService,
                protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService, turfGuardManager, userFormatService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        setTimeout(() => this.alertSensorType = this.translateService.instant('STRINGS.MOISTURE').toLowerCase());
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TurfGuardMoistureWidgetName;
    }

    onShowAlerts() {
        super.onShowAlerts();
        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewAlerts, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onViewHoles() {
        super.onViewHoles();
        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewHoles, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onViewSensors(holeIndex: any) {
        super.onViewSensors(holeIndex);
        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewSensors, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    protected setDataLabel() {
        this.averageCourseValueLabel = <string>this.userFormatService.toUserNumber(this.turfGuardWidgetData?.averageCourseValue ?? 0);
        this.averageCourseValueUnits = this.axisValueUnits;
        this.alertSensorType = this.translateService.instant('STRINGS.MOISTURE').toLowerCase()
    }

    protected get axisValueUnits(): string {
        return '%';
    }

    protected getWidgetData() {
        this.turfGuardManager.getTurGuardWidgetData(TurfGuardSensorType.Moisture)
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                })
            )
            .subscribe((response: TurfGuardWidgetData) => {
                this.clearIsUnableToFetchData();
                this.turfGuardWidgetData = response;
                this.lastUpdateTimestamp = this.turfGuardWidgetData.lastUpdated;
                this.setComponentData();

                this.hasNoData = this.turfGuardWidgetData.holesCount === 0;
                if (this.turfGuardWidgetData.holesCount === 0) { this.isBusy = false; }

                // If chart is displayed, do not set isBusy here. It will be set when the chart loads (in the base class)
                if (this.displayCols === 1) { this.isBusy = false; }
            }, error => {
                this.isUnableToFetchData = true;
                this.isBusy = false;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    get chartValueUnits(): string {
        return '%';
    }

}
