import { GenericMqttMessageContent } from '../generic-mqtt-message-content.model';
import { GetCourseReportStation } from './get-course-report-station.model';

export class GetCourseReportContent extends GenericMqttMessageContent {

    constructor(json: any = null) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.result) this.result = json.result.map(s => new GetCourseReportStation(s));
        }
    }

    result: GetCourseReportStation[];
}
