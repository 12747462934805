<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>
        <div id="toro-dlg-header-container"><div class="toro-flex-child">{{title | translate}}</div></div>
    </p-header>

    <div id="toro-my-turf-details-dlg-container">
        <div id="tmtdd-subtitle">{{subtitle | translate}}: {{recordCount || '--' }}</div>

        <div id="tmtdd-table-container">
            <ng-content></ng-content>
        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="close()"></p-button>
    </p-footer>
</p-dialog>
