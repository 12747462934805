import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SoilScoutBoundaryInfo } from '../models/soil-scout-boundary-info.model';
import { SoilScoutGaugeRange } from '../models/soil-scout-gauge-range.model';

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-thresholds',
    templateUrl: './soil-scout-thresholds.component.html',
    styleUrls: ['./soil-scout-thresholds.component.less']
})
export class SoilScoutThresholdsComponent implements OnInit {
    @HostBinding('class') class = 'toro-soil-scout-thresholds'

    @Output() gaugeRangeChange = new EventEmitter<SoilScoutGaugeRange>();

    @Input() rangeMax: number;
    @Input() rangeMin: number;
    @Input() suffix = '';

    private _gaugeRange: SoilScoutGaugeRange;
    @Input() set gaugeRange(value: SoilScoutGaugeRange) {
        if (value != null) this.gaugeRangeChange.emit(value);

        this._gaugeRange = value;
    }

    get gaugeRange(): SoilScoutGaugeRange {
        return this._gaugeRange;
    }

    locale = 'en-US';

    boundaryValues: SoilScoutBoundaryInfo[] = [];

    protected rangeCount = 5;

    private updateGaugeRangeTimer: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        let index = 0;
        this.rangeCount = this.gaugeRange.range3UpperBoundary != null ? 5 : 4;

        this.boundaryValues.push(new SoilScoutBoundaryInfo(index++, this.gaugeRange.rangeMax, this.rangeCount == 5 ? this.gaugeRange.range4Color : this.gaugeRange.range3Color));
        if (this.gaugeRange.range3UpperBoundary != null) this.boundaryValues.push(new SoilScoutBoundaryInfo(index++, this.gaugeRange.range3UpperBoundary, this.gaugeRange.range3Color));
        this.boundaryValues.push(new SoilScoutBoundaryInfo(index++, this.gaugeRange.range2UpperBoundary, this.gaugeRange.range2Color));
        this.boundaryValues.push(new SoilScoutBoundaryInfo(index++, this.gaugeRange.range1UpperBoundary, this.gaugeRange.range1Color));
        this.boundaryValues.push(new SoilScoutBoundaryInfo(index++, this.gaugeRange.rangeMin, 'black'));

        // Max
        this.boundaryValues[0].valueChange
            .pipe(untilDestroyed(this))
            .subscribe((change: { index: number, previous: number, new: number }) => this.updateSiblingRange(change));

        this.boundaryValues[1].valueChange
            .pipe(untilDestroyed(this))
            .subscribe((change: { index: number, previous: number, new: number }) => this.updateSiblingRange(change));

        this.boundaryValues[2].valueChange
            .pipe(untilDestroyed(this))
            .subscribe((change: { index: number, previous: number, new: number }) => this.updateSiblingRange(change));

        this.boundaryValues[3].valueChange
            .pipe(untilDestroyed(this))
            .subscribe((change: { index: number, previous: number, new: number }) => this.updateSiblingRange(change));

        // Min
        if (this.rangeCount > 4) {
            this.boundaryValues[4].valueChange
                .pipe(untilDestroyed(this))
                .subscribe((change: { index: number, previous: number, new: number }) => this.updateSiblingRange(change));
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onRangeInputBlur(index: number) {
        const value = this.boundaryValues[index].value
        if (value == null) { this.boundaryValues[index].value = 0; }

        this.updateGaugeRange();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    protected getThresholdClass(index: number): string {
        return `thresh-boundary thresh-pos-${index} ${this.rangeCount < 5 ? 'tri-range' : ''}`
    }

    private updateSiblingRange(change: { index: number, previous: number, new: number }) {
        if (change.new == null) { change.new = 0; }

        if (change.new < change.previous) {
            if (change.index + 1 >= this.rangeCount) {
                this.queueGaugeRangeUpdate();
                return;
            }

            const nextBoundaryValue = this.boundaryValues[change.index + 1];

            if (nextBoundaryValue.value > change.new) {
                nextBoundaryValue.value = change.new > this.rangeMin ? nextBoundaryValue.value = change.new : this.rangeMin;
            }
        } else if (change.new > change.previous) {
            if (change.index - 1 < 0) {
                this.queueGaugeRangeUpdate();
                return;
            }

            const previousBoundaryValue = this.boundaryValues[change.index - 1];

            if (previousBoundaryValue.value < change.new) {
                previousBoundaryValue.value = change.new < this.rangeMax ? previousBoundaryValue.value = change.new : this.rangeMax
            }
        }

        this.queueGaugeRangeUpdate();
    }

    private queueGaugeRangeUpdate() {
        clearTimeout(this.updateGaugeRangeTimer);
        this.updateGaugeRangeTimer = setTimeout(() => {
            this.updateGaugeRange();
        }, 250);
    }

    private updateGaugeRange() {
        const newGaugeRange = new SoilScoutGaugeRange({ ...this.gaugeRange })

        let index = 0;
        newGaugeRange.rangeMax = this.boundaryValues[index++].value;
        if (this.gaugeRange.range3UpperBoundary != null) newGaugeRange.range3UpperBoundary = this.boundaryValues[index++].value;
        newGaugeRange.range2UpperBoundary = this.boundaryValues[index++].value;
        newGaugeRange.range1UpperBoundary = this.boundaryValues[index++].value;
        newGaugeRange.rangeMin = this.boundaryValues[index++].value;

        this.gaugeRange = newGaugeRange;
    }
}
