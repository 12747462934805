/// <reference types="@types/googlemaps" />
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { environment } from '../../../../../environments/environment';
import { take } from 'rxjs/operators';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { WeatherManagerService } from '../../../../api/weather/weather-manager.service';
import { WeatherRadarConfig } from '../../../../api/weather/models/weather-radar-config.model';
import { WidgetManagerService } from '../../../../api/widgets/widget-manager.service';


@Component({
    selector: 'toro-widget-weather-radar',
    templateUrl: './widget-weather-radar.component.html',
    styleUrls: ['./widget-weather-radar.component.less']
})
export class WidgetWeatherRadarComponent extends ToroDashboardWidget implements OnInit {
    private readonly MAP_MIN_ZOOM = 7;
    private readonly MAP_MAX_ZOOM = 15;

    iconColor = '#3178c6';
    title = 'WIDGET.WEATHER_RADAR';

    mapOptions: any;
    mapOverlays: any[];
    googleMap: any;
    weatherRadarConfig: WeatherRadarConfig;
    showRadarLegend = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                private weatherManager: WeatherManagerService,
                private widgetManager: WidgetManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isBusy = true;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.WeatherRadarWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        if (this.isBusy) {
            this.setupMap();
            return;
        }

        this.getRadarOverlay();
    }

    // =========================================================================================================================================================
    // GMap Callbacks
    // =========================================================================================================================================================

    onMapReady(event: any) {
        this.googleMap = event.map;

        // Add event handler for mapTypeId change. This event is not handled by PrimeNg GMap.
        this.googleMap.addListener('maptypeid_changed', () => {
            this.updateWeatherRadarConfig();
        });

        // Give the map a little time to load to avoid errors fetching overlay tiles.
        setTimeout(() => {
            this.getRadarOverlay();

            // Add our custom toolbar to google map. Doing it this way ensures it renders
            // in full screen mode and positions relative to google rendered buttons.
            this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('twr-toolbar-container'));
        }, 500);
    }

    onMapDragEnd() {
        this.updateWeatherRadarConfig();
    }

    onZoomChanged() {
        this.updateWeatherRadarConfig();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onRecenterClick() {
        this.googleMap.setCenter(new google.maps.LatLng(this.weatherRadarConfig.origin.lat, this.weatherRadarConfig.origin.lng));
        this.updateWeatherRadarConfig();

        this.analyticsService.widgetEvent(AnalyticsEvent.WeatherRadarRecenterMap, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onShowRadar() {
        this.showRadarLegend = !this.showRadarLegend;

        const event = this.showRadarLegend ? AnalyticsEvent.WeatherRadarShowLegend : AnalyticsEvent.WeatherRadarHideLegend;
        this.analyticsService.widgetEvent(event, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onLaunchModalWidget() {
        super.onLaunchModalWidget();
        this.setupMap();
    }

    closeMiniModalWindow() {
        this.showMiniModeModal = false;
        this.setupMap();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setupMap() {
        this.googleMap = null;

        this.weatherManager.getWeatherRadarConfig(this.associatedWidget)
            .pipe(take(1))
            .subscribe((config: WeatherRadarConfig) => {
                this.weatherRadarConfig = <WeatherRadarConfig>config;

                this.mapOptions = {
                    center: config.center,
                    zoom: config.zoom,
                    minZoom: this.MAP_MIN_ZOOM,
                    maxZoom: this.MAP_MAX_ZOOM,
                    mapTypeId: config.mapTypeId,
                    gestureHandling: 'cooperative',
                    scaleControl: !this.isGridsterInMobileMode || this.showMiniModeModal,
                    fullscreenControl: !this.isGridsterInMobileMode || this.showMiniModeModal,
                    keyboardShortcuts: !this.isGridsterInMobileMode || this.showMiniModeModal,
                    fullscreenControlOptions: {
                        position: this.isGridsterInMobileMode ? google.maps.ControlPosition.RIGHT_BOTTOM : google.maps.ControlPosition.RIGHT_TOP
                    }
                };

                this.mapOverlays = [new google.maps.Marker({ position: config.origin })];

                this.getRadarOverlay();
            }, error => {
                this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.analyticsWidgetName, 'Unable to get Weather Radar Config.');
            });
    }

    private getRadarOverlay() {
        if (!this.googleMap) { return; }

        const overlay = new google.maps.ImageMapType({
            getTileUrl: (function(tileCoord: google.maps.Point, zoom: number): string {
                return `${environment.weatherRadarOverlayUrl}/${zoom}/${tileCoord.x}/${tileCoord.y}.png?api_key=${environment.weatherRadarOverlayApiKey}&guid=${this.guid}`;
            }).bind(this),
            opacity: 0.5,
            tileSize: new google.maps.Size(256, 256)
        });

        this.googleMap.overlayMapTypes.push(overlay);
        this.lastUpdateTimestamp = new Date();
        this.isBusy = false;

        if (this.googleMap.overlayMapTypes && this.googleMap.overlayMapTypes.getLength() > 1) {
            // Delay a bit to attempt a nice fade-in of new and fade-out of old layer.
            setTimeout(() => this.googleMap.overlayMapTypes.removeAt(0), 250);
        }

    }

    private updateWeatherRadarConfig() {
        this.weatherRadarConfig.center = { lat: this.googleMap.center.lat(), lng: this.googleMap.center.lng() };
        this.weatherRadarConfig.mapTypeId = this.googleMap.mapTypeId;
        this.weatherRadarConfig.zoom = this.googleMap.zoom;

        // Don't attempt to persist widget config if in demo mode.
        if (environment.isDemoMode) { return; }

        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.weatherRadarConfig)
            .pipe(take(1))
            .subscribe(() => {

            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // @ts-ignore
    // Used in GoogleMap Callback.
    private get guid(): string {
        return `${this.s4()}${this.s4()}-${this.s4()}-4${this.s4().substr(0, 3)}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`.toLowerCase();
    }

    private s4() {
        // eslint-disable-next-line no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}

