
export class PwDailyHistoricalEt {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            this.date = new Date(json.date);
        }
    }

    date: Date;
    value: number;

}
