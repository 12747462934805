import { PwPoint } from './pw-point.model';

export class PwLocation {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.point) this.point = new PwPoint(json.point);
        }
    }

    id?: number;
    name: string;
    point: PwPoint;
    pwLink? = false;
    integrationKey: string;
}
