/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DailyWeatherForecast } from '../../../../api/weather/models/daily-weather-forecast';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { LocalWeatherForecast } from '../../../../api/weather/models/local-weather-forecast.model';
import { Site } from '../../../../api/site/models/site.model';
import { SiteManagerService } from '../../../../api/site/site-manager.service';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../common/services/user-format.service';
import { WeatherIconInfo } from '../../../../api/weather/models/weather-icon-info.model';
import { WeatherManagerService } from '../../../../api/weather/weather-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@UntilDestroy()
@Component({
    selector: 'toro-widget-local-weather-forecast',
    templateUrl: './widget-local-weather-forecast.component.html',
    styleUrls: ['./widget-local-weather-forecast.component.less']
})
export class WidgetLocalWeatherForecastComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#3178c6';
    title = 'WIDGET.LOCAL_WEATHER';

    private readonly conditionsMap = {
        Sunny: 'FORECAST_CONDITIONS.SUNNY',
        PartlySunny: 'FORECAST_CONDITIONS.PARTLY_SUNNY',
        Cloudy: 'FORECAST_CONDITIONS.CLOUDY',
        PartlyCloudy: 'FORECAST_CONDITIONS.PARTLY_CLOUDY',
        Showers: 'FORECAST_CONDITIONS.SHOWERS',
        Rainfall: 'FORECAST_CONDITIONS.RAINFALL',
        Thunderstorms: 'FORECAST_CONDITIONS.THUNDERSTORMS',
        Snow: 'FORECAST_CONDITIONS.SNOW',
        Sleet: 'FORECAST_CONDITIONS.SLEET',
        FreezingRain: 'FORECAST_CONDITIONS.FREEZING_RAIN',
        Drizzle: 'FORECAST_CONDITIONS.DRIZZLE',
    };

    localWeatherForecast: LocalWeatherForecast;
    tenDayForecast: DailyWeatherForecast[];
    conditionsString: string;
    tempUnits = 'F';
    currentTemp: string;
    forecastHighTemp: string;
    forecastLowTemp: string;
    dewPoint = '--';
    rainfall = '--';
    windSpeed = '--';
    humidity = '--';
    weatherIcon: WeatherIconInfo;
    timerRef: any;
    site: Site;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private siteManager: SiteManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language
                this.setComponentData();
            });

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.

        this.language = this.dashUserManager.language;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LocalWeatherForecastWidgetName;
    }

    protected getWidgetData() {
        const sources: Observable<any>[] = [
            this.weatherManager.getWeatherForecast().pipe(take(1)),
            this.siteManager.getSite().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe({
                next: ([forecast, site]) => {
                    this.clearIsUnableToFetchData();
                    this.site = site;

                    this.lastUpdateTimestamp = forecast.lastUpdated;
                    this.localWeatherForecast = forecast;

                    this.tenDayForecast = forecast.tenDayForecast;
                    if (this.tenDayForecast.length < 1) this.forceFetchTenDayForecast();

                    this.setComponentData();
                },
                error: error => {
                    this.isUnableToFetchData = true;
                    if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
                }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private forceFetchTenDayForecast() {
        this.weatherManager.forceWeatherDataUpdate(this.authManager.dashAuthenticatedUser.siteId)
            .subscribe(() => this.startWeatherForecastRefreshTimer());
    }

    private startWeatherForecastRefreshTimer() {
        if (this.timerRef) return;

        this.timerRef = setTimeout(() => {
            this.weatherManager.getWeatherForecast()
                .pipe(
                    take(1),
                    finalize(() => clearTimeout(this.timerRef))
                )
                .subscribe((forecast: LocalWeatherForecast) => {
                    if (forecast.tenDayForecast.length > 1) {
                        this.tenDayForecast = forecast.tenDayForecast;
                        return;
                    }
                    this.startWeatherForecastRefreshTimer();
                })
        }, 2000);
    }

    private setComponentData() {
        // Update CurrentWeather values
        if (!this.localWeatherForecast || !this.localWeatherForecast.currentWeather) return;

        const currentWeather = this.localWeatherForecast.currentWeather;

        this.currentTemp = <string>this.userFormatService.temperature(currentWeather.temperature, true);
        this.tempUnits = this.userFormatService.temperatureUnits;

        const userDewPoint = this.userFormatService.dewPoint(currentWeather.dewPoint);
        this.dewPoint = `${userDewPoint}°${this.userFormatService.dewPointUnits}`;

        const userRainfall = this.userFormatService.rainfall(currentWeather.rainfall, true);
        this.rainfall = `${userRainfall}${!this.userFormatService.isMetric ? '' : ' '}${this.userFormatService.rainfallUnits}`;

        const userWindSpeed = this.userFormatService.windSpeed(currentWeather.windSpeed);
        this.windSpeed = `${currentWeather.windDirection}  ${userWindSpeed} ${this.userFormatService.windSpeedUnits}`;

        this.humidity = `${this.userFormatService.humidity(currentWeather.relHumidity)}%`;

        // Update ForecastWeather values
        if (!this.localWeatherForecast || !this.localWeatherForecast.forecastWeather) return '';
        const forecastWeather = this.localWeatherForecast.forecastWeather;

        this.setConditionsString(forecastWeather.conditionsValue);

        this.forecastHighTemp = <string>this.userFormatService.temperature(forecastWeather.highTemperature, true);
        this.forecastLowTemp = <string>this.userFormatService.temperature(forecastWeather.lowTemperature, true);

        const weatherIconInfo = this.weatherManager.getWeatherIcon(forecastWeather.conditions, forecastWeather.cloudCover);
        this.weatherIcon = weatherIconInfo;
    }

    setConditionsString(condition: ToroEnums.ForecastCondition) {
        const conditionString = this.conditionsMap[ToroEnums.ForecastCondition[condition]];
        this.conditionsString = conditionString ? this.translateService.instant(conditionString) : '--';
    }
}
