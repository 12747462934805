import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../common/guards/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainContentContainerComponent } from './main-content-container/main-content-container.component';
import { NgModule } from '@angular/core';

const coreRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        component: MainContentContainerComponent,
        children: [
            { path: '', component: DashboardComponent },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(coreRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CoreRoutingModule {
}
