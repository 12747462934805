import WeatherGraphType = ToroEnums.WeatherGraphType;
import * as moment from 'moment';
import { Duration } from 'moment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { ToroStartingTime } from '../../_common/models/toro-starting-time.model';

export class WeatherTimeSeries {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.interval) { this.interval = moment.duration(json.Interval); }
            if (json.startingTime) { this.startingTime = new ToroStartingTime(json.startingTime); }
        }
    }

    id: WeatherGraphType;
    interval: Duration;
    invalidValue: number;
    name: string;
    startingTime: ToroStartingTime;
    units: string;
    values: number[];

    get minValue(): number {
        return Math.min(...this.values);
    }

    get maxValue(): number {
        return Math.max(...this.values);
    }
}
