import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Site } from './models/site.model';

@Injectable({
    providedIn: 'root'
})
export class SiteApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods - api
    // =========================================================================================================================================================

    getSite(): Observable<Site> {
        return this.apiService.apiRequest<any>(SiteApiService.siteUrl, HttpMethod.Get)
            .pipe(map(site => new Site(site)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}site`; }

    private static get siteUrl(): string { return `${SiteApiService.baseUrl}`; }
}
