<div class="toro-lynx-pump-station-widget-container toro-component-shield-host">
    <div class="lps-name">{{pumpStation?.name}}</div>
    <fa-icon class="lps-info-icon" icon="info-circle" (click)="onShowInfoDialog()"></fa-icon>

    <div id="toro-lynx-pump-station-widget-content" >
        <div class="toro-flex-child lps-chart-container" [ngClass]="{'isMobile': isGridsterInMobileMode}">
            <div class="lps-chart-title">{{ 'STRINGS.PRESSURE' | translate }}</div>
            <highcharts-chart
                    class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isPressureChartLoaded}"
                    [Highcharts]="Highcharts"
                    [options]="pressureChartOptions"
            >
            </highcharts-chart>
        </div>
        <div class="toro-flex-child lps-chart-container" [ngClass]="{'isMobile': isGridsterInMobileMode}">
            <div class="lps-chart-title">{{ 'STRINGS.FLOW' | translate }}</div>
            <highcharts-chart
                    class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isFlowChartLoaded}"
                    [Highcharts]="Highcharts"
                    [options]="flowChartOptions"
            >
            </highcharts-chart>
        </div>
    </div>

    <toro-component-shield></toro-component-shield>
</div>

<!-- Dialogs -->
<toro-pump-station-details-dlg *ngIf="showInfoDialog" [pumpStation]="pumpStation" (closeDialog)="showInfoDialog = false"></toro-pump-station-details-dlg>
