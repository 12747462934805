<div class="daily-forecast-card-container">
    <div id="dfc-day-label" class="dfc-centered-contents-div">{{day}}</div>
    <div id="dfc-day-temp" class="dfc-centered-contents-div">{{highTemp}}<span id="dfc-day-temp-label">°{{tempUnits}}</span></div>
    <div id="dfc-day-temp-low" class="dfc-centered-contents-div">{{lowTemp}}°</div>
    <div id="dfc-icon-container" class="dfc-centered-contents-div">
        <div id="lwf-icon">
<!--            <i class="weather-icon" [class]="weatherIcon?.primaryIcon?.icon" [ngStyle]="{'color': weatherIcon?.primaryIcon?.color}"></i>-->
<!--            <i class="weather-icon" [class]="weatherIcon?.overlayIcon?.icon" [ngStyle]="{'color': weatherIcon?.overlayIcon?.color}"></i>-->
            <img style="max-width: 100%;" [src]="weatherImageUrl" alt="">
        </div>
    </div>
    <div id="dfc-precip-wind-container">
        <div id="dfc-precip-container" class="dfc-stacked-div">
            <div class="dfc-val-icon"><fa-icon icon="tint"></fa-icon></div>
            <div class="dfc-precip-wind-val">{{precipTotal}}</div>
        </div>
        <div id="dfc-wind-container" class="dfc-stacked-div">
            <div class="dfc-val-icon"><i class="toro-id-wind"></i></div>
            <div class="dfc-precip-wind-val">{{windSpeed}}</div>
        </div>
    </div>
</div>

