import TurfGuardChartColor = ToroEnums.TurfGuardChartColor;
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { SelectItem } from 'primeng/api';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardManagerService } from '../../../../../api/turf-guard/turf-guard-manager.service';
import { TurfGuardSensor } from '../../../../../api/turf-guard/models/turf-guard-sensor.model';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';
import { UserFormatService } from '../../../../../common/services/user-format.service';

enum SensorProperty {
    Temperature = 'temperature',
    Moisture = 'moisture',
    Salinity = 'salinity'
}

enum ListFilter {
    AllZones,
    AlertsOnly
}

@UntilDestroy()
@Component({
    selector: 'toro-turf-guard-sensors-dlg',
    templateUrl: './turf-guard-sensors-dlg.component.html',
    styleUrls: ['./turf-guard-sensors-dlg.component.less']
})
export class TurfGuardSensorsDlgComponent implements OnInit {
    @ViewChild('tgChartContainer') tgChartContainer: ElementRef;
    @ViewChild('closeButton') closeButton: ElementRef;
    @ViewChildren('listItem', { read: ElementRef }) listElement: QueryList<ElementRef>;
    @ViewChildren('tableRow', { read: ElementRef }) tableElement: QueryList<ElementRef>;

    private readonly MAX_VALUE_FRACTION_DIGITS = 7;
    ListFilter = ListFilter;

    @Output() cancel = new EventEmitter();

    @Input() title = '';
    @Input() sensorType: TurfGuardSensorType;
    @Input() selectedZoneIndex = 0;
    @Input() turfGuardWidgetData: TurfGuardWidgetData;
    @Input() showAllAlerts = false;

    zones: SelectItem[] = [];
    filteredZones: SelectItem[] = [];
    selectedSensorsDetails: TurfGuardSensor[] = [];
    sensorProperty: string;
    colFieldDisplayValue: string;
    colFieldLimitMin: string;
    colFieldLimitMax: string;
    colDisplayHeaderLabel: string;
    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    isDialogLoaded = false;
    listFilter = ListFilter.AllZones;
    showNoZonesWarning = false;
    mobileSelectedZonesLabel = '';
    showZoneSelectionDialog = false;
    language = 'en-us';
    isInitialized = false;

    private selectedSeries: Highcharts.Series = null;

    private _selectedSensor: TurfGuardSensor;
    set selectedSensor(value: TurfGuardSensor) {
        this._selectedSensor = value;
        this.onTableSensorSelected(value);
    }

    get selectedSensor(): TurfGuardSensor {
        return this._selectedSensor;
    }

    private _selectedZones: { number: number, hasAlert: boolean }[] = [];
    set selectedZones(value: { number: number, hasAlert: boolean }[]) {
        this._selectedZones = value;
        this.setMobileZoneSelectionLabel();

        setTimeout(() => {
            this.showNoZonesWarning = this._selectedZones && this._selectedZones.length < 1;
        });
    }

    get selectedZones(): { number: number, hasAlert: boolean }[] {
        return this._selectedZones;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashMessageService: DashMessageService,
                private dashUserManagerService: DashUserManagerService,
                private translateService: TranslateService,
                private turfGuardManager: TurfGuardManagerService,
                private userFormatService: UserFormatService,
    ) { }

    ngOnInit(): void {
        this.language = this.dashUserManagerService.language;

        this.setDynamicTableProperties();
        this.setupZoneListbox();
        this.setSelectedZones();
        this.getSensorData(this.selectedZones.map(z => z.number));

        this.dashUserManagerService.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManagerService.language);

        // This semaphore is used to slightly delay the loading of the dialog listbox until we have properly resolved the
        // user's selected language. Different languages can effect the height of the filter buttons (due to localized string length). This
        // delay allows the ngDialog p-header to properly adjust before rendering.
        this.isInitialized = true;

        // Give the list box a chance to finish loading.
        setTimeout(() => {
            this.scrollListItemIntoView();
            this.isDialogLoaded = true;
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onZoneSelection(event: any) {
        if (event.value.length === 0) { this.clearChart(); }

        this.selectedZones = event.value;
        this.getSensorData(event.value.map(v => v.number));
    }

    toggleListFilter(listFilter: ListFilter) {
        if (this.listFilter === listFilter) return;

        this.listFilter = listFilter;
        this.filteredZones = (this.listFilter === ListFilter.AlertsOnly) ? this.zones.filter(z => z.value.hasAlert) : this.zones;
    }

    onClose() {
        this.cancel.emit();
    }

    changeZoneSelection() {
        this.showZoneSelectionDialog = true;
    }

    // =========================================================================================================================================================
    // Table Helpers
    // =========================================================================================================================================================

    getSensorValue(sensor: TurfGuardSensor) {
        let value = sensor[this.sensorProperty].displayValue;
        if (this.sensorType === TurfGuardSensorType.Temperature) { value = this.userFormatService.temperature(value); }

        return this.userFormatService.toUserNumber(value, null, this.MAX_VALUE_FRACTION_DIGITS);
    }

    getSensorLowLimit(sensor: TurfGuardSensor) {
        let value = sensor.limits[this.sensorProperty].minimum;
        if (this.sensorType === TurfGuardSensorType.Temperature) { value = this.userFormatService.temperature(value); }

        return this.userFormatService.toUserNumber(value);
    }

    getSensorHighLimit(sensor: TurfGuardSensor) {
        let value = sensor.limits[this.sensorProperty].maximum;
        if (this.sensorType === TurfGuardSensorType.Temperature) { value = this.userFormatService.temperature(value); }

        return this.userFormatService.toUserNumber(value);
    }

    sensorHasAlert(sensor: TurfGuardSensor): boolean {
        return sensor[this.sensorProperty].alert.alertType !== null;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setSelectedZones() {
        if (!this.showAllAlerts) {
            const hole = this.turfGuardWidgetData.getHoleByIndex(this.selectedZoneIndex);
            this.selectedZones = [{ number: hole.number, hasAlert: hole.hasAlert(this.sensorType) }];
            return;
        }

        this.selectedZones = [];
        this.turfGuardWidgetData.getHolesWithAlerts(this.sensorType).forEach(h => {
            this.selectedZones.push({ number: h.number, hasAlert: true });
        });

        this.setMobileZoneSelectionLabel();
    }

    private getSensorData(zoneNumbers: number[]) {
        if (!zoneNumbers || zoneNumbers.length < 1) { this.clearChart(); }

        this.turfGuardManager.getTurfGuardSensors(zoneNumbers)
            .subscribe((sensors: TurfGuardSensor[]) => {
                this.selectedSensorsDetails = sensors.sort((a, b) => a.holeNumber - b.holeNumber || a.sensorNumber - b.sensorNumber);
                if (!this.chart) {
                    this.setupChart();
                } else {
                    this.chartOptions = null;
                    setTimeout(() => this.setupChart());
                }
            }, error => {
                const widgetName = `WIDGET.TURF_GUARD_${TurfGuardSensorType[this.sensorType].toUpperCase()}`;
                this.dashMessageService.showWidgetDataFetchErrorMessage(widgetName);
            });

    }

    private setupZoneListbox() {
        this.turfGuardWidgetData.holesByZone.forEach(h => {
            this.zones.push({ label: h.name, value: { number: h.number, hasAlert: h.hasAlert(this.sensorType) } });
        });

        this.filteredZones = this.zones;
    }

    private scrollListItemIntoView() {
        const el = this.listElement.find(i => i.nativeElement.getAttribute('id') === this.selectedZones[0].number.toString());
        el.nativeElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }

    private scrollTableRowIntoView() {
        const el = this.tableElement.find(i => i.nativeElement.getAttribute('id') === this.selectedSensor.sensorNumber.toString());
        el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    private setDynamicTableProperties() {
        switch (this.sensorType) {
            case TurfGuardSensorType.Temperature:
                this.sensorProperty = SensorProperty.Temperature;
                this.colDisplayHeaderLabel = 'STRINGS.TEMPERATURE';
                this.colFieldDisplayValue = 'temperature.displayValue';
                this.colFieldLimitMin = 'limits.temperature.minimum';
                this.colFieldLimitMax = 'limits.temperature.maximum';
                break;
            case TurfGuardSensorType.Moisture:
                this.sensorProperty = SensorProperty.Moisture;
                this.colDisplayHeaderLabel = 'STRINGS.MOISTURE';
                this.colFieldDisplayValue = 'moisture.displayValue';
                this.colFieldLimitMin = 'limits.moisture.minimum';
                this.colFieldLimitMax = 'limits.moisture.maximum';
                break;
            case TurfGuardSensorType.Salinity:
                this.sensorProperty = SensorProperty.Salinity;
                this.colDisplayHeaderLabel = 'STRINGS.SALINITY';
                this.colFieldDisplayValue = 'salinity.displayValue';
                this.colFieldLimitMin = 'limits.salinity.minimum';
                this.colFieldLimitMax = 'limits.salinity.maximum';
                break;
            default:
                this.sensorProperty = null;
                break;
        }
    }

    private clearChart() {
        while (this.chart.series.length > 0) { this.chart.series[0].remove(true); }
    }

    // private updateChart() {
    //     // const currentSeriesZones = [...new Set( this.chart.series.map(s => +s.name.split('|')[0])) ];
    //     // const zonesToRemove = currentSeriesZones.filter(z => !this.selectedZones.includes(z));
    //     // const zonesToAdd = this.selectedZones.filter(z => !currentSeriesZones.includes(z));
    //
    //     this.clearChart();
    //
    //     this.sensorSeriesData.forEach(s => {
    //        this.chart.addSeries(s);
    //     });
    // }

    private setupChart() {
        const self = this;

        this.setWidthOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'spline',
                width: self.chartContainerWidth,
                events: { load(event) {self.chart = event.target; } },
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: { enabled: false },
            xAxis: {
                tickInterval: 6,
                tickLength: 7,
                tickWidth: 1,
                categories: self.sensorSeriesCategories,
                labels: {
                    useHTML: true,
                    formatter() { return '<span class="tg-sensor-dlg-axis-label">' + this.value + '</span>'; }
                }
            },
            yAxis: {
                title: { enabled: false },
                labels: {
                    formatter() { return '<span class="tg-sensor-dlg-axis-label">' + this.value + '</span>'; }
                }
            },
            tooltip: { enabled: false },
            plotOptions: {
                spline: {
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                },
                series: {
                    color: TurfGuardChartColor.Line,
                    enableMouseTracking: true,
                    stickyTracking: false,
                    states: { inactive: { enabled: false } },
                    events: {
                        click(event) { self.onChartSeriesSelected(event); }
                    }
                },
            },
            series: self.sensorSeriesData,
        };
    }

    get sensorSeriesData(): any[] {
        const dataSeries = [];

        this.selectedSensorsDetails.forEach(s => {
            dataSeries.push({
                type: 'spline',
                name: `${s.holeNumber}|${s.sensorNumber}`,
                marker: { enabled: false },
                color: s[this.sensorProperty].alert.alertType === null ? TurfGuardChartColor.Line : TurfGuardChartColor.Alert,
                data: this.getAdjustedSensorSeriesData(s[this.sensorProperty].values),
            });
        });

        return dataSeries;
    }

    get sensorSeriesCategories(): string[] {
        if (!this.selectedSensorsDetails || this.selectedSensorsDetails.length < 1) return [];

        const categories = [];
        const sensorDetails = this.selectedSensorsDetails[0][this.sensorProperty];
        const startTime = sensorDetails.startingTime.time;

        for (let i = 0; i < sensorDetails.values.length; i++) {
            let label = moment(startTime).add(i, 'hours').format('h A');

            if (i === Math.floor(sensorDetails.values.length / 2)) {
                label += ` (${moment(startTime).add(i, 'hours').format('M/D')})`;
            }

            categories.push(label);
        }

        return categories;
    }

    private getAdjustedSensorSeriesData(values: number[]): number[] {
        if (this.sensorType !== TurfGuardSensorType.Temperature) { return values; }

        return values.map(v => <number>this.userFormatService.temperature(v));
    }

    private setWidthOfChartContainer() {
        if (!this.tgChartContainer) { return; }
        this.chartContainerWidth = this.tgChartContainer.nativeElement.offsetWidth - 2;
    }

    private onTableSensorSelected(sensor: TurfGuardSensor) {
        this.highlightSeries(`${sensor.holeNumber}|${sensor.sensorNumber}`);
    }

    private onChartSeriesSelected(event: any) {
        const seriesName = event.point.series.name;

        // Highlight series on chart.
        this.highlightSeries(seriesName);

        // Highlight sensor in table.
        const selectedSensor = this.selectedSensorsDetails.find(s => `${s.holeNumber}|${s.sensorNumber}` === seriesName);
        if (selectedSensor != null) {
            this._selectedSensor = selectedSensor;
            this.scrollTableRowIntoView();
        }
    }

    private highlightSeries(seriesName: string) {
        if (!this.chart) return;

        // Reset previously selected series.
        if (this.selectedSeries != null) {
            const previouslySelectedSeries = this.chart.series.find(s => s.name === this.selectedSeries.name);
            previouslySelectedSeries.update({ type: 'spline', lineWidth: 2, opacity: 1 });
        }

        // Find and highlight selected series.
        this.selectedSeries = this.chart.series.find(s => s.name === seriesName);
        this.selectedSeries.update({ type: 'spline', lineWidth: 5, opacity: 1 });

    }

    private setMobileZoneSelectionLabel() {
        this.mobileSelectedZonesLabel = this.selectedZones.length === 0
            ?  this.translateService.instant('STRINGS.NO_ZONE_IS_SELECTED') : this.selectedZones.length === 1
                ? this.translateService.instant('STRINGS.ONE_ZONE_SELECTED')
                : this.translateService.instant('STRINGS.N_ZONES_SELECTED', {zoneCount: this.selectedZones.length});
    }

}
