import { DtnCurrentWeatherForecast, DtnHourlyWeatherForecast } from './models/dtn-forecast.model';
import { ClearAgApiService } from './clear-ag-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClearAgManagerService {

    // private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private clearAgApiService: ClearAgApiService) {
        // if (environment.isDemoMode) {
        //     this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        // }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDailyForecast(lat: number, lon: number, startDay = 0, endDay = 9): Observable<any> {
        // if (environment.isDemoMode) { return of(this.demoModeMockDataService.pogoProperty) ; }

        return this.clearAgApiService.getDailyForecast(lat, lon, startDay, endDay);
    }

    getCurrentForecast(lat: number, lon: number): Observable<DtnCurrentWeatherForecast> {
        // if (environment.isDemoMode) { return of(this.demoModeMockDataService.pogoProperty) ; }

        return this.clearAgApiService.getCurrentForecast(lat, lon);
    }

    getHourlyForecast(lat: number, lon: number, start: number, end: number): Observable<DtnHourlyWeatherForecast> {
        // if (environment.isDemoMode) { return of(this.demoModeMockDataService.pogoProperty) ; }

        return this.clearAgApiService.getHourlyForecast(lat, lon, start, end);
    }
}
