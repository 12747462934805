import { GenericMqttMessage } from '../generic-mqtt-message.model';
import { GetProgramTopoContent } from './get-program-topo-content.model';

export class GetProgramTopoMessage  extends GenericMqttMessage {

    constructor(json: any = null) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.content) this.content = new GetProgramTopoContent(json.content);
        }
    }

    content: GetProgramTopoContent;
}
