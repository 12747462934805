import { Component, Input, OnInit } from '@angular/core';
import { WeatherAveragesLegendItem } from '../models/weather-averages-legend-item.model';

@Component({
    selector: 'toro-source-legend-card',
    templateUrl: './source-legend-card.component.html',
    styleUrls: ['./source-legend-card.component.less']
})
export class SourceLegendCardComponent implements OnInit {

    @Input() legendItem: WeatherAveragesLegendItem;

    constructor() { }

    ngOnInit(): void {
    }

}
