import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { parseString } from 'xml2js';

@Injectable({
    providedIn: 'root'
})
export class XmlParserService {

    constructor(private http: HttpClient) { }

    parseXML(url: string): Observable<any> {
        return this.http.get(url, { responseType: 'text' })
            .pipe(map((xmlString: string) => {
                    let parsedData;
                    parseString(xmlString, (err, result) => {
                        if (err) {
                            throw err;
                        }
                        parsedData = result;
                    });
                    return parsedData;
                }));
    }
}
