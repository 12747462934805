import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import SoilScoutMapView = ToroEnums.SoilScoutMapView;
import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;
import SoilScoutThresholdTab = ToroEnums.SoilScoutThresholdTab;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { GoogleMapLocation } from '../../../../../api/_common/models/google-map-location.model';
import { HttpClient } from '@angular/common/http';
import { LocalCacheService } from '../../../../../common/services/local-cache.service';
import { SelectItem } from 'primeng/api';
import { SoilScoutConfig } from '../../../../../api/soil-scout/models/soil-scout-config.model';
import { SoilScoutConstants } from '../../../../../common/constants/soil-scout.constants';
import { SoilScoutDevice } from '../../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGaugeRange } from '../models/soil-scout-gauge-range.model';
import { SoilScoutManagerService } from '../../../../../api/soil-scout/soil-scout-manager.service';
import { SoilScoutThresholdChange } from '../models/soil-scout-threshold-change.model';
import { SoilScoutWidgetBase } from '../_soil-scout-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';

@UntilDestroy()
@Component({
    selector: 'toro-widget-soil-scout-map',
    templateUrl: './widget-soil-scout-map.component.html',
    styleUrls: ['./widget-soil-scout-map.component.less']
})
export class WidgetSoilScoutMapComponent extends SoilScoutWidgetBase implements OnInit, OnDestroy {
    private readonly MAP_MIN_ZOOM = 14;

    readonly iconColor = 'black';
    readonly title = 'WIDGET.SOIL_SCOUT';

    protected readonly SoilScoutThresholdTab = SoilScoutThresholdTab;
    protected readonly SoilScoutWidgetBase = SoilScoutWidgetBase;
    protected readonly SoilScoutSensorType = SoilScoutSensorType;

    private readonly ConnectivityBlue = '#365df5';
    private readonly ConnectivityRed = '#f34949';
    private readonly transitionTimeInMs = 500;

    fadeInMapDialog = false;
    isManualDataRefresh = false;
    mapOptions: any;
    mapOverlays: any[];
    googleMap: any;
    mapZoomLevel = 17;

    visualizationOptions: SelectItem[] = [];
    selectedMapView: SoilScoutMapView = SoilScoutMapView.Moisture;
    moistureValueRanges: SoilScoutGaugeRange;
    temperatureValueRanges: SoilScoutGaugeRange;
    salinityValueRanges: SoilScoutGaugeRange;
    soilScoutConfig: SoilScoutConfig;

    protected showMapOverlay = false;
    protected selectedDevice: SoilScoutDevice;
    protected isSensorChartPanelVisible = false;
    protected isSensorChartPanelDisplayed = false;
    protected sensorPanelTitle: string;
    protected sensorPanelIcon: string;
    protected overlaySensorType: SoilScoutSensorType;
    protected overlayDateString: string;

    private currentInfoWindow: google.maps.InfoWindow;
    private areMapMarkersRetrieved = false;
    private mapMarkerBase: string;
    private mapMarkerEcho: string;
    private mapMarkerScout: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private httpClient: HttpClient,
                protected localCacheService: LocalCacheService,
                protected soilScoutManager: SoilScoutManagerService,
                protected translateService: TranslateService,
                protected userFormatService: UserFormatService,
                private widgetManager: WidgetManagerService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, localCacheService, soilScoutManager, translateService, userFormatService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.loadMapMarkers();

        window.addEventListener('highchartsButtonClick', this.showScoutDetails.bind(this));

        this.visualizationOptions = [
            { label: this.translateService.instant('STRINGS.MOISTURE'), value: SoilScoutMapView.Moisture },
            { label: this.translateService.instant('STRINGS.TEMPERATURE'), value: SoilScoutMapView.Temperature },
            { label: this.translateService.instant('STRINGS.SALINITY'), value: SoilScoutMapView.Salinity },
            { label: this.translateService.instant('STRINGS.CONNECTIVITY'), value: SoilScoutMapView.Connectivity },
        ];

        SoilScoutWidgetBase.commonDataUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setColorRanges();

                if (!this.isWidgetInitialized) {
                    this.soilScoutManager.getSoilScoutConfig(this.associatedWidget)
                        .pipe(
                            take(1),
                            finalize(() => {
                                this.isWidgetInitialized = true;
                                this.setupMap();
                            })
                        )
                        .subscribe((config: SoilScoutConfig) => {
                            this.soilScoutConfig = config;
                            this.selectedMapView = this.soilScoutConfig.mapView;
                        });

                    return;
                }

                this.setupMap();
            });

        SoilScoutWidgetBase.gaugeRangeUpdate
            .pipe(untilDestroyed(this))
            .subscribe((change: SoilScoutThresholdChange) => {
                this.isBusy = true;

                setTimeout(() => {
                    this.setColorRanges();

                    setTimeout(() => {
                        this.setupMap();
                        this.isBusy = false;
                    }, 500)
                }, 500)
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setupMap();
                this.updateUiPostPrefChanges();
            });

        SoilScoutWidgetBase.unableToRetrieveData
            .pipe(untilDestroyed(this))
            .subscribe((reason) => this.setIsUnableToFetchData(reason, null, false))

        SoilScoutWidgetBase.attemptingLogin
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isBusy = true)

        SoilScoutWidgetBase.successfulLogin
            .pipe(untilDestroyed(this))
            .subscribe((reason) => {
                this.clearIsUnableToFetchData();
                this.isBusy = true;
                this.getWidgetData(true)
            })

        this.broadcastService.setupSoilScoutLoginChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.clearIsUnableToFetchData();
                this.isBusy = true;
                this.getWidgetData(true)
            })
    }

    ngOnDestroy() {
        window.removeEventListener('highchartsButtonClick', this.showScoutDetails.bind(this));

        super.ngOnDestroy();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.SoilScoutMapWidgetName;
    }

    protected get axisValueUnits(): string {
        return null;
    }

    protected getWidgetData(isManualRefresh = false) {
        if (isManualRefresh) { this.isManualDataRefresh = true; }

        super.getWidgetData(isManualRefresh);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSiteScoutLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_SOIL_SCOUT' });
        setTimeout(() => window.open(environment.soilScoutHubUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    protected onMapReady(event: any) {
        this.googleMap = event.map;
        setTimeout(() => {

            this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('ssm-toolbar-container'));
            this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('ssm-overlay-container'));
            this.fadeInMapDialog = true
        }, 250);
    }

    protected onMapDragEnd() {
        this.updateSoilScoutConfig();
    }

    protected onZoomChanged() {
        this.updateSoilScoutConfig();
    }

    onRecenterClick() {
        const location = new GoogleMapLocation({
            lat: +SoilScoutWidgetBase?.selectedSite?.latitude || this.authManager.dashAuthenticatedUser?.latitude,
            lng: +SoilScoutWidgetBase?.selectedSite?.longitude || this.authManager.dashAuthenticatedUser?.longitude
        });

        this.googleMap.setCenter(new google.maps.LatLng(location.lat, location.lng));
        this.updateSoilScoutConfig();

        this.analyticsService.widgetEvent(AnalyticsEvent.SoilScoutRecenterMap, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onMapViewChange() {
        this.setupMap();
        this.updateSoilScoutConfig();
    }

    showScoutDetails(event: CustomEvent) {
        // Close any open google maps InfoWindows (i.e., tooltips)
        if (this.currentInfoWindow != null) { this.currentInfoWindow.close(); }

        // Get the Soil Scout Device associated with the marker click.
        this.selectedDevice = SoilScoutWidgetBase.siteDevices.find(s => s.id === event.detail.deviceId);
        if (this.selectedDevice === null || this.selectedDevice === undefined) return;

        this.overlayDateString = `${this.userFormatService.toUserDateString(new Date(this.selectedDevice?.last_seen))}, ${this.userFormatService.toUserTimeString(this.selectedDevice?.last_seen)}`
        this.showMapOverlay = true;
    }

    toggleSensorChartPanel(sensorType: SoilScoutSensorType) {
        this.overlaySensorType = null;

        if (!this.isSensorChartPanelVisible) {
            this.isSensorChartPanelVisible = true;
            this.overlaySensorType = sensorType;
            this.setSensorPanelHeader(sensorType);
            setTimeout(() => this.isSensorChartPanelDisplayed = true)
        } else {
            this.isSensorChartPanelDisplayed = false;
            setTimeout(() => this.isSensorChartPanelVisible = false, this.transitionTimeInMs);
        }
    }

    closeMapOverlay() {
        this.showMapOverlay = false;
        this.isSensorChartPanelVisible = false;
        this.isSensorChartPanelDisplayed = false;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private timerRef: any;

    private setupMap() {
        // In reality, we seem to always load the svg map markers way before we get here; however, if we don't, let's pause a bit before setting up the map.
        if (!this.areMapMarkersRetrieved) {
            clearTimeout(this.timerRef);
            this.timerRef = setTimeout(() => this.setupMap(), 250);
            return;
        }

        const location = new GoogleMapLocation({
            lat: +SoilScoutWidgetBase?.selectedSite?.latitude || this.authManager.dashAuthenticatedUser?.latitude,
            lng: +SoilScoutWidgetBase?.selectedSite?.longitude || this.authManager.dashAuthenticatedUser?.longitude
        });

        if (this.soilScoutConfig != null) {
            this.soilScoutConfig.origin = location;
            if (this.soilScoutConfig.center != null) {
                location.lat = this.soilScoutConfig.center.lat;
                location.lng = this.soilScoutConfig.center.lng;
            }
            this.mapZoomLevel = this.soilScoutConfig.zoom;
        }

        this.mapOptions = {
            center: location,
            zoom: this.mapZoomLevel,
            minZoom: this.MAP_MIN_ZOOM,
            mapTypeControl: false,
            mapTypeId: 'satellite',
            gestureHandling: 'cooperative',
            scaleControl: true,
            streetViewControl: false
        };

        const valueRanges = this.getValueRanges();
        const markers: any[] = [];

        SoilScoutWidgetBase.siteDevices?.forEach(device => {
            let svgMarker = this.getMapMarker(device, valueRanges);

            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(device.location.latitude, device.location.longitude),
                map: this.googleMap,
                label: {
                    text: this.getMarkerLabel(device),
                    color: 'rgba(255, 255, 255, 0.8)',
                    fontSize: '11px'
                },
                icon: {
                    url: `data:image/svg+xml;charset=UTF-8, ${encodeURIComponent(svgMarker)}`,
                    scaledSize: new google.maps.Size(25, 25),
                    labelOrigin: new google.maps.Point(10, 29),
                }
            });

            marker['id'] = device.id;
            markers.push(marker);

            google.maps.event.addListener(marker, 'click', () => {
                this.mapMarkerClick(marker);
            });
        });

        this.mapOverlays = markers;
        this.lastUpdateTimestamp = new Date();
        this.isManualDataRefresh = false;
    }

    mapMarkerClick(marker) {
        if (this.currentInfoWindow != null) { this.currentInfoWindow.close(); }
        if (this.showMapOverlay == true) {
            this.closeMapOverlay();
        }

        // Per Norma, don't show tooltip. Go directly to overlay.
        // For those devices that don't support the overlay, show the tooltip.
        const selectedAsset = SoilScoutWidgetBase.siteDevices.find(s => s.id === marker.id);
        if (selectedAsset === null || selectedAsset === undefined) return;

        if (selectedAsset.device_type == 'hydra') {
            this.showScoutDetails(new CustomEvent('', { detail: { deviceId: selectedAsset.id }}));
            return;
        }

        marker.infoWindow = new google.maps.InfoWindow({
            content: this.getInfoWindowContent(marker, true)
        });

        marker.infoWindow.open(this.googleMap, marker);
        this.currentInfoWindow = marker.infoWindow;
        this.analyticsService.widgetEvent(AnalyticsEvent.SoilScoutWidgetViewMapAssetDetails, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private getInfoWindowContent(marker, bypassOpenCheck = false) {
        if (!bypassOpenCheck && !marker.infoWindow) { return; }

        const selectedAsset = SoilScoutWidgetBase.siteDevices.find(s => s.id === marker.id);
        if (selectedAsset === null || selectedAsset === undefined) return;

        switch (selectedAsset.device_type) {
            case 'hydra':
                return '<div class="asset-info ssm prevent-selectX">' +
                    '<div class="asset-info-text">' +
                    '<div class="asset-info-name">' + selectedAsset.name + '</div>' +
                    '</div>' +
                    '<div class="ssm-tooltip-container">' +
                    '<div class="">' +
                    this.translateService.instant('STRINGS.LAST_SEEN_ON') + ' ' + this.userFormatService.toUserDateString(new Date(selectedAsset.last_seen)) + ', ' + this.userFormatService.toUserTimeString(selectedAsset.last_seen) +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl mb">' +
                    this.translateService.instant('STRINGS.LATITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.latitude + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('STRINGS.LONGITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.longitude + '</span>' +
                    '</div>' +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('STRINGS.MOISTURE') + ' (%): <span class="ssm-tt-value">' + this.userFormatService.toUserNumber(selectedAsset.last_measurement.moisture * 100, 0, 1) + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl mtb">' +
                    this.translateService.instant('STRINGS.TEMPERATURE') + ' (°' + this.userFormatService.temperatureUnits + '): <span class="ssm-tt-value">' + this.userFormatService.temperatureFromCelsius(selectedAsset.last_measurement.temperature, true, 1) + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('STRINGS.SALINITY') + ' (' + this.userFormatService.salinityUnits + '): <span class="ssm-tt-value">' + this.userFormatService.salinity(selectedAsset.last_measurement.salinity, true, 2) + '</span>' +
                    '</div>' +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('CASE_SENSITIVE.BATTERY_VOLTAGE') + ' (V):  <span class="ssm-tt-value">' + this.userFormatService.toUserNumber(selectedAsset.voltage_battery, 2) + '</span>' +
                    '</div>' +
                    '</div>' +

                    '<div class="ssm-tooltip-group details-btn">' +
                    '<div class="ssm-tt-lblX" onclick="highchartsButtonClick(' + selectedAsset.id + ')">' +
                    '<span class="prevent-select">Show Details</span>' +
                    '</div>' +
                    '</div>' +

                    '</div>';

            case 'base':
                return '<div class="asset-info ssm prevent-select">' +
                    '<div class="asset-info-text">' +
                    '<div class="asset-info-name">' + selectedAsset.name + '</div>' +
                    '</div>' +
                    '<div class="ssm-tooltip-container">' +
                    '<div class="">' +
                    this.translateService.instant('STRINGS.LAST_SEEN_ON') + ' ' + this.userFormatService.toUserDateString(new Date(selectedAsset.last_seen)) + ', ' + this.userFormatService.toUserTimeString(selectedAsset.last_seen) +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl mb">' +
                    this.translateService.instant('STRINGS.LATITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.latitude + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('STRINGS.LONGITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.longitude + '</span>' +
                    '</div>' +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('CASE_SENSITIVE.EXTERNAL_VOLTAGE') + ' (V):  <span class="ssm-tt-value">' + this.userFormatService.toUserNumber(selectedAsset.voltage_external, 2) + '</span>' +
                    '</div>' +
                    '</div>' +

                    '</div>';

            case 'echo':
                return '<div class="asset-info ssm prevent-select">' +
                    '<div class="asset-info-text">' +
                    '<div class="asset-info-name">' + selectedAsset.name + '</div>' +
                    '</div>' +
                    '<div class="ssm-tooltip-container">' +
                    '<div class="">' +
                    this.translateService.instant('STRINGS.LAST_SEEN_ON') + ' ' + this.userFormatService.toUserDateString(new Date(selectedAsset.last_seen)) + ', ' + this.userFormatService.toUserTimeString(selectedAsset.last_seen) +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl mb">' +
                    this.translateService.instant('STRINGS.LATITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.latitude + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('STRINGS.LONGITUDE') + ': <span class="ssm-tt-value">' + selectedAsset.location.longitude + '</span>' +
                    '</div>' +
                    '</div>' +

                    '<div class="ssm-tooltip-group">' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('CASE_SENSITIVE.EXTERNAL_VOLTAGE') + ' (V):  <span class="ssm-tt-value">' + (selectedAsset.voltage_external == 0 ? '' : this.userFormatService.toUserNumber(selectedAsset.voltage_external, 2)) + '</span>' +
                    '</div>' +
                    '<div class="ssm-tt-lbl">' +
                    this.translateService.instant('CASE_SENSITIVE.BATTERY_VOLTAGE') + ' (V):  <span class="ssm-tt-value">' + (selectedAsset.voltage_battery == 0 ? '' : this.userFormatService.toUserNumber(selectedAsset.voltage_battery, 2)) + '</span>' +
                    '</div>' +

                    '</div>' +

                    '</div>';

        }

    }

    private getMapMarker(device: SoilScoutDevice, valueRanges: SoilScoutGaugeRange): string {
        let svgMarker = SoilScoutConstants.map_marker_hydra;

        switch (device.device_type) {
            case 'base':
                svgMarker = this.mapMarkerBase ?? SoilScoutConstants.map_marker_base;
                break;
            case 'echo':
                svgMarker = this.mapMarkerEcho ?? SoilScoutConstants.map_marker_echo;
                break;
            default:
                svgMarker = (this.selectedMapView == SoilScoutMapView.Connectivity)
                    ? this.mapMarkerScout ?? SoilScoutConstants.map_marker_hydra
                    : SoilScoutConstants.map_marker_hydra
                break;
        }

        return svgMarker.replace('currentColor', this.getMarkerColor(device, valueRanges));
    }

    private loadMapMarkers() {
        const sources: Observable<any>[] = [
            this.httpClient.get('assets/images/soil-scout/svg/map-marker-base.svg', { responseType: 'text'}),
            this.httpClient.get('assets/images/soil-scout/svg/map-marker-echo.svg', { responseType: 'text'}),
            this.httpClient.get('assets/images/soil-scout/svg/map-marker-scout.svg', { responseType: 'text'})
        ]

        forkJoin(sources)
            .pipe(finalize(() => this.areMapMarkersRetrieved = true))
            .subscribe(([base, echo, scout]) => {
                this.mapMarkerBase = base;
                this.mapMarkerEcho = echo;
                this.mapMarkerScout = scout;
            });
    }

    private getMarkerColor(device: SoilScoutDevice, valueRanges: SoilScoutGaugeRange): string {
        let color = 'black';

        switch (device.device_type) {
            case 'base':
            case 'echo':
                color = device.device_status == 'OK' ? this.ConnectivityBlue : this.ConnectivityRed;
                break;
            case 'hydra':
                switch (this.selectedMapView) {
                    case SoilScoutMapView.Moisture:
                        color = this.getDataValueColor(device.last_measurement.moisture * 100, valueRanges);
                        break;
                    case SoilScoutMapView.Temperature:
                        color = this.getDataValueColor(+this.userFormatService.convertCelsiusToFahrenheit(device.last_measurement.temperature), valueRanges);
                        break;
                    case SoilScoutMapView.Salinity:
                        color = this.getDataValueColor(device.last_measurement.salinity, valueRanges);
                        break;
                    case SoilScoutMapView.Connectivity:
                        color = device.device_status == 'OK' ? this.ConnectivityBlue : this.ConnectivityRed;
                        break;
                }
                break;
            default:
                return 'grey';
        }

        return color;
    }

    private getMarkerLabel(device: SoilScoutDevice): string {
        let label = '';

        switch (device.device_type) {
            case 'base':
            case 'echo':
                label = `${this.translateService.instant('STRINGS.STATUS')}: ${this.translateService.instant('CASE_SENSITIVE.' + device.device_status)}`
                break;
            case 'hydra':
                switch (this.selectedMapView) {
                    case SoilScoutMapView.Moisture:
                        label = `${this.translateService.instant('STRINGS.MOISTURE')}: ${this.userFormatService.toUserNumber(device.last_measurement.moisture * 100, 0, 1)}%`
                        break;
                    case SoilScoutMapView.Salinity:
                        label = `${this.translateService.instant('STRINGS.SALINITY')}: ${this.userFormatService.salinity(device.last_measurement.salinity, true, 2)} ${this.userFormatService.salinityUnits}`
                        break;
                    case SoilScoutMapView.Temperature:
                        label = `${this.translateService.instant('STRINGS.TEMPERATURE')}: ${this.userFormatService.temperatureFromCelsius(device.last_measurement.temperature, true, 1)} °${this.userFormatService.temperatureUnits}`
                        break;
                    case ToroEnums.SoilScoutMapView.Connectivity:
                        label = `${this.translateService.instant('STRINGS.STATUS')}: ${this.translateService.instant('CASE_SENSITIVE.' + device.device_status)}`
                        break;
                }
                break;
            default:
                break;
        }

        return label;
    }

    private setColorRanges() {
        this.moistureValueRanges = this.defaultMoistureRange
        if (SoilScoutWidgetBase.userPreferences.ssMoistureRange != null) {
            const range = SoilScoutWidgetBase.userPreferences.ssMoistureRange;
            this.moistureValueRanges.rangeMin = range.rangeMin;
            this.moistureValueRanges.rangeMax = range.rangeMax;
            this.moistureValueRanges.range1UpperBoundary = range.range1UpperBoundary;
            this.moistureValueRanges.range2UpperBoundary = range.range2UpperBoundary;
            this.moistureValueRanges.range3UpperBoundary = range.range3UpperBoundary;
        }

        this.temperatureValueRanges = this.defaultTemperatureRange
        if (SoilScoutWidgetBase.userPreferences.ssTemperatureRange != null) {
            const range = SoilScoutWidgetBase.userPreferences.ssTemperatureRange;
            this.temperatureValueRanges.rangeMin = range.rangeMin;
            this.temperatureValueRanges.rangeMax = range.rangeMax;
            this.temperatureValueRanges.range1UpperBoundary = range.range1UpperBoundary;
            this.temperatureValueRanges.range2UpperBoundary = range.range2UpperBoundary;
            this.temperatureValueRanges.range3UpperBoundary = range.range3UpperBoundary;
        }

        this.salinityValueRanges = this.defaultSalinityRange
        if (SoilScoutWidgetBase.userPreferences.ssSalinityRange != null) {
            const range = SoilScoutWidgetBase.userPreferences.ssSalinityRange;
            this.salinityValueRanges.rangeMin = range.rangeMin;
            this.salinityValueRanges.rangeMax = range.rangeMax;
            this.salinityValueRanges.range1UpperBoundary = range.range1UpperBoundary;
            this.salinityValueRanges.range2UpperBoundary = range.range2UpperBoundary;
            this.salinityValueRanges.range3UpperBoundary = range.range3UpperBoundary;
        }
    }

    private getValueRanges() {
        switch (this.selectedMapView) {
            case ToroEnums.SoilScoutMapView.Moisture:
                return this.moistureValueRanges;
            case ToroEnums.SoilScoutMapView.Temperature:
                return this.temperatureValueRanges;
            case ToroEnums.SoilScoutMapView.Salinity:
                return this.salinityValueRanges;
            case ToroEnums.SoilScoutMapView.Connectivity:
                return null;
        }
    }

    private getDataValueColor(value: number, range: SoilScoutGaugeRange): string {
        if (value < range.range1UpperBoundary) {
            return range.range1Color;
        } else if (value < range.range2UpperBoundary) {
            return range.range2Color;
        } else if (value < (range.range3UpperBoundary ?? range.rangeMax)) {
            return range.range3Color
        } else {
            return range.range4Color;
        }
    }

    private updateSoilScoutConfig() {
        this.soilScoutConfig.center = { lat: this.googleMap.center.lat(), lng: this.googleMap.center.lng() };
        this.soilScoutConfig.zoom = this.googleMap.zoom;
        this.soilScoutConfig.mapView = this.selectedMapView

        // Don't attempt to persist widget config if in demo mode.
        if (environment.isDemoMode) { return; }

        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.soilScoutConfig).pipe(take(1)).subscribe();
    }

    private setSensorPanelHeader(sensorType: SoilScoutSensorType) {
        switch (sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.sensorPanelTitle = 'STRINGS.MOISTURE';
                this.sensorPanelIcon = 'soil-scout-moisture.png';
                break;
            case ToroEnums.SoilScoutSensorType.Temperature: {
                this.sensorPanelTitle = 'STRINGS.TEMPERATURE';
                this.sensorPanelIcon = 'soil-scout-temperature.png';
                break;
            }
            case ToroEnums.SoilScoutSensorType.Salinity:
                this.sensorPanelTitle = 'STRINGS.SALINITY';
                this.sensorPanelIcon = 'soil-scout-salinity.png';
                break;
            case ToroEnums.SoilScoutSensorType.WaterBalance:
                this.sensorPanelTitle = 'CASE_SENSITIVE.WATER_BALANCE';
                this.sensorPanelIcon = 'soil-scout-water-balance.png';
                break;
            default:
                this.sensorPanelTitle = '';
                break;
        }
    }

    private updateUiPostPrefChanges() {
        if (this.showMapOverlay) {
            this.overlayDateString = `${this.userFormatService.toUserDateString(new Date(this.selectedDevice?.last_seen))}, ${this.userFormatService.toUserTimeString(this.selectedDevice?.last_seen)}`
        }
    }

}
