<div class="toro-weather-graph-container" [ngStyle]="{'height': height + 'px'}">
    <div class="twg-chart-container"><ng-container [ngTemplateOutlet]="weatherGraphChart"></ng-container></div>
    <ng-container [ngTemplateOutlet]="weatherGraphLegend"></ng-container>
</div>

<ng-template #weatherGraphChart let-usage="usage">
    <toro-weather-graph-chart
            *ngIf="data"
            [graphType]="graphType"
            [site]="site"
            [data]="data"
            [isModal]="(usage === 'modal' ? true : false)"
            [yAxisExtent]="yAxisExtent"
            (chartClick)="onChartClick()"
    ></toro-weather-graph-chart>
    <div class="twgc-no-data" *ngIf="!data">{{"STRINGS.NO_DATA_AVAILABLE" | translate}}</div>
</ng-template>

<ng-template #weatherGraphLegend let-usage="usage">
    <div class="twg-legend-container" [ngClass]="{'is-modal': usage === 'modal'}">
        <div class="twg-legend" [ngClass]="language">
            <fa-icon icon="square" [ngStyle]="{'color': series1Color}"></fa-icon>
            <span class="twg-legend-label">{{ series1Name | translate | titlecase }}</span><span class="twg-legend-units">({{ series1Units }})</span>
        </div>
        <div *ngIf="series2Name" class="twg-legend" [ngClass]="language">
            <fa-icon icon="square"  [ngStyle]="{'color': series2Color}"></fa-icon>
            <span class="twg-legend-label">{{ series2Name | translate | titlecase }}</span><span class="twg-legend-units">({{ series2Units }})</span>
        </div>
    </div>
</ng-template>

<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="modalTitle" (cancel)="showModalGraph = false">
    <div id="twgd-chart-dlg-container">
        <ng-container [ngTemplateOutlet]="weatherGraphChart" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="weatherGraphLegend" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
</toro-weather-graph-chart-dlg>
