import { Component, Input, OnInit } from '@angular/core';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import ToroColor = ToroEnums.ToroColor;
import AssetTrackingDeviceType = ToroEnums.AssetTrackingDeviceType;

@Component({
    selector: 'toro-asset-type-icon',
    templateUrl: './asset-type-icon.component.html',
    styleUrls: ['./asset-type-icon.component.less']
})
export class AssetTypeIconComponent implements OnInit {

    @Input() assetType: AssetTrackingDeviceType;
    @Input() color = ToroColor.ToroRed;
    @Input() outlineColor = 'black';

    baseIconClass = '';
    outlineIconClass = '';
    typeTip = '';
    isUnknown = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
        const icons = this.getIcons();
        this.baseIconClass = icons.base;
        this.outlineIconClass = icons.outline;
        this.typeTip = this.translateService.instant(icons.type);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getIcons() {
        switch (this.assetType) {
            case AssetTrackingDeviceType.GreensWalk:
                return { base: 'toro-am-GreensWalk', outline: '', type: 'TRACKING_ASSETS.GREENS_RIDE' };
            case AssetTrackingDeviceType.GreensRide:
                return { base: 'toro-am-GreensRide', outline: '', type: 'TRACKING_ASSETS.GREENS_WALK' };
            case AssetTrackingDeviceType.OutCross:
                return { base: 'toro-am-Outcross', outline: '', type: 'TRACKING_ASSETS.OUT_CROSS' };
            case AssetTrackingDeviceType.ProCore:
                return { base: 'toro-am-ProCare', outline: '', type: 'TRACKING_ASSETS.PRO_CORE' };
            case AssetTrackingDeviceType.ProForce:
                return { base: 'toro-am-ProForce', outline: '', type: 'TRACKING_ASSETS.PRO_FORCE' };
            case AssetTrackingDeviceType.ProPass:
                return { base: 'toro-am-ProPass', outline: '', type: 'TRACKING_ASSETS.PRO_PASS' };
            case AssetTrackingDeviceType.ProSweep:
                return { base: 'toro-am-ProSweep', outline: '', type: 'TRACKING_ASSETS.PRO_SWEEP' };
            case AssetTrackingDeviceType.Reels:
                return { base: 'toro-am-Reels', outline: '', type: 'TRACKING_ASSETS.REELS' };
            case AssetTrackingDeviceType.Rotary:
                return { base: 'toro-am-Rotary', outline: '', type: 'TRACKING_ASSETS.ROTARY' };
            case AssetTrackingDeviceType.SandPro:
                return { base: 'toro-am-SandPro', outline: '', type: 'TRACKING_ASSETS.SAND_PRO' };
            case AssetTrackingDeviceType.Sprayers:
                return { base: 'toro-am-Sprayer', outline: '', type: 'TRACKING_ASSETS.SPRAYERS' };
            case AssetTrackingDeviceType.Utility:
                return { base: 'toro-am-Utility', outline: '', type: 'TRACKING_ASSETS.UTILITY' };
            default:
                this.isUnknown = true;
                return { base: '', outline: '', type: 'TRACKING_ASSETS.UNKNOWN' };
        }
    }

}
