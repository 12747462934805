<p-dialog visible="true" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.NEW_FAVORITE_LOCATION' | translate | titlecase}}</p-header>

    <div id="tgs-add-favorite-dlg-content">
        <span class="ui-float-label">
            <input id="float-input" type="text" pInputText [(ngModel)]="name">
            <label for="float-input">{{'STRINGS.LOCATION_NAME' | translate | titlecase}}</label>
        </span>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSave()" [disabled]="!name || name === ''"></p-button>
    </p-footer>
</p-dialog>
