import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PogoApiService } from './pogo-api.service';
import { PogoProperty } from './models/pogo-property.model';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { AppInjector } from '../../demo/demo.module';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PogoManagerService {

    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private pogoApiService: PogoApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getProperty(apiKey: string): Observable<PogoProperty> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.pogoProperty) ; }

        return this.pogoApiService.getProperty(apiKey);
    }

}
