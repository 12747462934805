<p-dialog appendTo="body" [visible]="true" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'CASE_SENSITIVE.SOIL_SCOUT_DATA_THRESHOLDS' | translate }}</p-header>

    <div id="ss-thresholds-dlg-container">

        <p-tabView styleClass="ss-thresholds-tab-view" [activeIndex]="selectedTab">
            <p-tabPanel [header]="'STRINGS.MOISTURE' | translate">
                <toro-soil-scout-thresholds
                        [suffix]="' %'"
                        [rangeMin]="0"
                        [rangeMax]="100"
                        [(gaugeRange)]="moistureRange"
                ></toro-soil-scout-thresholds>
            </p-tabPanel>
            <p-tabPanel [header]="'STRINGS.SALINITY' | translate">
                <toro-soil-scout-thresholds
                        [suffix]="salinitySuffix || ''"
                        [rangeMin]="0"
                        [rangeMax]="10"
                        [(gaugeRange)]="salinityRange"
                ></toro-soil-scout-thresholds>
            </p-tabPanel>
            <p-tabPanel [header]="'STRINGS.TEMPERATURE' | translate">
                <toro-soil-scout-thresholds
                        [suffix]="temperatureSuffix || ''"
                        [rangeMin]="tempRangeMin"
                        [rangeMax]="tempRangeMax"
                        [(gaugeRange)]="temperatureRange"
                ></toro-soil-scout-thresholds>
            </p-tabPanel>
        </p-tabView>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onUpdateThresholds()"></p-button>
    </p-footer>
</p-dialog>
