import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { SelectItem } from 'primeng/api';
import { TaskTrackerDailyMeasurementInfo } from '../models/task-tracker-daily-measurement-info.model';
import { TaskTrackerManagerService } from '../../../../../api/task-tracker/task-tracker-manager.service';
import { TaskTrackerMeasurementSummary } from '../../../../../api/task-tracker/models/task-tracker-measurement-summary.model';
import { TaskTrackerWidgetBase } from '../_task-tracker-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';


@UntilDestroy()
@Component({
    selector: 'toro-widget-task-tracker-measurements',
    templateUrl: './widget-task-tracker-measurements.component.html',
    styleUrls: ['./widget-task-tracker-measurements.component.less']
})
export class WidgetTaskTrackerMeasurementsComponent extends TaskTrackerWidgetBase implements OnInit {
    iconColor = '#065f53';
    title = 'WIDGET.TASK_TRACKER_DAILY_MEASUREMENTS';

    departmentsList: SelectItem[];
    measurementSummaries: TaskTrackerMeasurementSummary[] = [];
    filteredMeasurementSummaries: TaskTrackerMeasurementSummary[] = [];
    selectedDepartmentIds: number[] = [];
    dailyMeasurements: TaskTrackerDailyMeasurementInfo[];

    miniModeUserCurrentAvg = '--';
    miniModeMetric = '';
    miniModeMetricUnits = '';


    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(
        protected analyticsService: AnalyticsService,
        protected broadcastService: BroadcastService,
        protected dashMessageService: DashMessageService,
        protected dashUserManager: DashUserManagerService,
        protected deviceManager: DeviceManagerService,
        protected taskTrackerManager: TaskTrackerManagerService,
        protected translateService: TranslateService,
        private userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, taskTrackerManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.taskTrackerManager.companyIdChange
            .pipe(untilDestroyed(this))
            .subscribe((taskTrackerApiKey: string) => {
                this.taskTrackerApiKey = taskTrackerApiKey;
                this.dashUserPrefs.taskTrackerMeasurementsSelectedDepartments = null;
                this.departmentsList = null;
                this.selectedDepartmentIds = null;

                this.isBusy = true;
                this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_API_KEY')}...`;
                this.getWidgetData(true);
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TaskTrackerDailyMeasurements;
    }

    protected getWidgetDataExtended(isManualRefresh = false) {
        const sources: Observable<any>[] = [
            this.taskTrackerManager.getTaskTrackerConfig(this.associatedWidget),
            this.taskTrackerManager.getMeasurementSummary(this.companyId, isManualRefresh).pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.resetBusy()))
            .subscribe(([taskTrackerConfig, measurementSummary, dashUserInfo]) => {
                this.clearIsUnableToFetchData();

                // Override default refresh interval
                if (!environment.isDemoMode) {
                    this.updateIntervalInMinutes = taskTrackerConfig.dataRefreshIntervalMinutes;
                    this.staleDataThresholdInMinutes = taskTrackerConfig.staleDataThresholdMinutes;
                }

                this.dashUserPrefs = dashUserInfo.preferences;
                this.measurementSummaries = measurementSummary;
                this.setDepartmentsList();
                if (!this.selectedDepartmentIds || this.selectedDepartmentIds.length < 1) { this.setInitialSelectedDepartments(); }
                this.setComponentData();
                this.lastUpdateTimestamp = new Date();
            }, () => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTaskTrackerLinkClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerGoToTrackingSite, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        super.onTaskTrackerLinkClick();
    }

    onDepartmentSelectionChange() {
        this.dashUserPrefs.taskTrackerMeasurementsSelectedDepartments = this.selectedDepartmentIds;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs)
            .subscribe(() => {
                this.setComponentData();
            }, () => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected setComponentData() {
        this.dailyMeasurements = [];
        this.filteredMeasurementSummaries = this.measurementSummaries.filter(ms => this.selectedDepartmentIds?.includes(ms.departmentId));
        this.filteredMeasurementSummaries.forEach(ms => {
            ms.measurements.forEach(m => {
                this.dailyMeasurements.push(new TaskTrackerDailyMeasurementInfo({
                    id: m.id,
                    name: m.name,
                    departmentName: ms.departmentName,
                    units: m.calByZone === 0 ? m.type : m.measurementByAreaType,
                    currentAvg: m.calByZone === 0 ? m.avgMeasurementMostRecent : m.avgCalByZoneMostRecent,
                    previousAvg: m.avgMeasurementPrevious
                }));
            });
        });

        // Grab the first metric
        if (this.isGridsterInMobileMode) {
            const firstMetric = this.dailyMeasurements[0];
            this.miniModeUserCurrentAvg = firstMetric != null ? this.userFormatService.toUserNumber(firstMetric.currentAvg) : '--';
            this.miniModeMetric = firstMetric != null ? firstMetric.name : '--';
            this.miniModeMetricUnits = firstMetric != null ? firstMetric.units : '--';
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDepartmentsList() {
        if (this.measurementSummaries == null) { return; }

        if (this.departmentsList == null) {
            this.departmentsList = [];
            this.measurementSummaries.forEach(s => this.departmentsList.push({ value: s.departmentId, label: s.departmentName }));
        }
        return this.departmentsList;
    }

    private setInitialSelectedDepartments() {
        // If we have a previously saved set of selected departments, use it...
        if (this.dashUserPrefs && this.dashUserPrefs.taskTrackerMeasurementsSelectedDepartments) {
            this.departmentsList.filter(d =>
                this.dashUserPrefs.taskTrackerMeasurementsSelectedDepartments.includes(d.value)).forEach(dept => this.selectedDepartmentIds.push(dept.value));
        } else {
            // ...if not, select all departments.
            this.selectedDepartmentIds = [];
            this.departmentsList.forEach(d => this.selectedDepartmentIds.push(d.value));
            this.onDepartmentSelectionChange();
        }
    }
}
