import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../../common/services/analytics.service';
import { NsnConnectDevice } from '../../../../../../api/nsn/models/nsn-connect-device.model';
import { ToroAnalyticsEnums } from '../../../../../../common/enumerations/analytics.enums';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@Component({
    selector: 'toro-nsn-connect-device-card',
    templateUrl: './nsn-connect-device-card.component.html',
    styleUrls: ['./nsn-connect-device-card.component.less']
})
export class NsnConnectDeviceCardComponent implements OnInit {

    @Input() deviceInfo: NsnConnectDevice;

    onlineStatusStyle = '';

    // TEMPORARY (See FogBugz 11333). Temporarily prohibit navigation on mobile devices. This is a NSN issue. Once resolved, remove the *ngIf logic in the html.
    isMobile = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                // private deviceManager: DeviceManagerService,
    ) { }

    ngOnInit(): void {
        // this.isMobile = this.deviceManager.isMobile;

        this.onlineStatusStyle = this.deviceInfo.isOnline ? 'nsn-connect-device-online' : 'nsn-connect-device-offline';
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onConnectDeviceClick() {
        this.analyticsService.widgetEvent(
            AnalyticsEvent.NsnWidgetConnectDeviceClicked, AnalyticsCategory.Interaction, AnalyticsEvent.NsnWidgetName, this.deviceInfo?.connectUrl);
    }

}
