import { GetProgramTopoStation } from './get-program-topo-station.model';

export class GetProgramTopoHole {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.id = json.Id;
            this.name = json.Name;
            this.stations = json.Stations.map(station => new GetProgramTopoStation(station));

            delete this['Name'];
            delete this['Id'];
            delete this['Stations'];
        }
    }

    id: number;
    name: string;
    stations: GetProgramTopoStation[];
}
