/* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/dot-notation */
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from './common/services/analytics.service';
import { AuthManagerService } from './api/auth/auth-manager.service';
import { BroadcastService } from './common/services/broadcast.service';
import { DashMessageService } from './common/services/dash-message.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceManagerService } from './common/services/device-manager.service';
import { environment } from '../environments/environment';
import { LoginStateChange } from './api/auth/models/login-state-change';
import { NotificationManagerService } from './api/notifications/notification-manager.service';
import { ToroAnalyticsEnums } from './common/enumerations/analytics.enums';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@UntilDestroy()
@Component({
    selector: 'toro-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'toro-root';

    @HostListener('window:resize')
    onResize() {
        // Set up the host listener to listen for resize events to trigger hiding/showing
        // of hamburger menu and responsive design of navigation pane.
        this.deviceManager.windowResize.next(null);
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        // Guard against the cat sitting on the enter key while the mouse is positioned over a button.
        if (e.key === 'Enter' && e.repeat) {
            e.preventDefault();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler() {
        this.analyticsService.event(AnalyticsEvent.AppStop, AnalyticsCategory.Application);
    }

    isLockDown = false;
    isSsoUserLoggedIn = false;
    isDashUserLoggedIn = false;
    isMainAppVisible = false;
    connectingMessage: string;
    showMobileFooter = true;

    protected isSpatialAdjust = false;

    private _appActiveIntervalRef: NodeJS.Timeout;
    private _isFive9SocialWidgetLoaded = false;
    private _isFive9ChatTooltipSet = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                private deviceDetector: DeviceDetectorService,
                private deviceManager: DeviceManagerService,
                private notificationManager: NotificationManagerService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService) {}

    ngOnInit(): void {
        document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));

        setTimeout(() => {
            this.analyticsService.event(AnalyticsEvent.AppStart, AnalyticsCategory.Application);

            // Initiate App Active timer
            this.handleVisibilityChange();
        });

        this.connectingMessage = `${this.translateService.instant('STRINGS.CONNECTING_TO_DASH_SERVER', { appName: this.translateService.instant('TORO.APP_NAME') })}...`;
        this.setupRouteListener();
        this.setBrowserType();

        this.route.queryParams.subscribe(params => {
            // NOTE: This is for myTurf SSO Support. If we need to support multiple SSO providers that use code flow, we will need/want
            // to have them use unique redirect pages. We may want to do that for myTurf to.
            if (params['code'] !== undefined) {
                this.authManager.loginWithMyTurfCode(params['code'])
                    .pipe(take(1))
                    .subscribe(() => {
                        // Navigate to root to clear code query param.
                        this.router.navigate(['/']);
                    }, () => {
                        this.dashMessageService.showMyTurfAuthenticationErrorMessage();
                    });
            }
        });

        this.broadcastService.showFooter
            .pipe(untilDestroyed(this))
            .subscribe((showFooter: boolean) => this.showMobileFooter = showFooter);

        this.broadcastService.spatialAdjustLoaded
            .pipe(untilDestroyed(this))
            .subscribe((isLoaded: boolean) => {
                this.isSpatialAdjust = isLoaded;
                this.toggleFive9ChatButton();
            });

        this.authManager.dashLoginStateChange
            .pipe(untilDestroyed(this))
            .subscribe((state: LoginStateChange) => {
                this.isDashUserLoggedIn = state.isLoggedIn;

                // Special handling for NSN errors. We will show a NSN prompt for NSN errors instead of directly logging out user.
                if (state.reason === this.authManager.nsnDashAccessError || state.reason === this.authManager.nsnLoginError) {
                    this.isLockDown = true;
                    this.router.navigate(['/nsn-error', { reason: this.authManager.getNsnErrorNo(state.reason) }]);
                }

                this.setMainAppDivVisible();
            });

        this.isSsoUserLoggedIn = this.authManager.isSsoUserLoggedIn;

        if (document.location.href.endsWith('/demo')) {
            if (!environment.allowDynamicDemoMode) { return; }

            this.authManager.isDemoMode = true;
            environment.isDemoMode = true;
            environment.enableAnalytics = false;
        }

        // If Ping SSO User is not logged in, start the login process.
        // NOTE: This appears to resolve the issue where a page refresh when using the ping-stage environment results in a blank app page.
        if (!this.authManager.isSsoUserLoggedIn) {
            this.authManager.startOauthSignIn();
            return;
        }

        this.isDashUserLoggedIn = this.authManager.isDashAuthenticatedUserLoggedIn;
        this.setMainAppDivVisible();

        this.injectBeamerScript();
    }

    ngOnDestroy() {
        clearInterval(this._appActiveIntervalRef);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get showConnectingMessage(): boolean {
        return this.isSsoUserLoggedIn && !this.isDashUserLoggedIn && !this.isLockDown;
    }

    get loadMainAppDiv(): boolean {
        return (this.isSsoUserLoggedIn && this.isDashUserLoggedIn) || this.isLockDown;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setMainAppDivVisible() {
        setTimeout(() => {
            this.isMainAppVisible = this.isDashUserLoggedIn || this.isLockDown;
            this.dynamicallyLoadFive9SocialWidget();
            if (this.isDashUserLoggedIn) { this.notificationManager.initiateNotificationPolling(); }
        });
    }

    // Method to set browser type and version in HTML tag. This is used by browser specific css.
    private setBrowserType() {
        const el = document.getElementsByTagName('html')[0];

        let attr = document.createAttribute('browser-name');
        attr.value = this.deviceDetector.getDeviceInfo().browser;
        el.attributes.setNamedItem(attr);

        attr = document.createAttribute('browser-version');
        attr.value = this.deviceDetector.getDeviceInfo().browser_version;
        el.attributes.setNamedItem(attr);

        attr = document.createAttribute('os');
        attr.value = this.deviceDetector.getDeviceInfo().os;
        el.attributes.setNamedItem(attr);
    }

    private setupRouteListener() {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                const currentChildRoute = this.route.firstChild.snapshot.url[0];
                if (!currentChildRoute || (currentChildRoute.path !== 'ah-snap' && currentChildRoute.path !== 'nsn-error')) {
                    this.isLockDown = false;
                    return;
                }

                this.isLockDown = true;
            });
    }

    // Dynamically load Five9SocialWidget to avoid initial 'chat-tab drawing artifact' generated when widget is allowed to load when
    // script tag is injected into index.html before customized css has been loaded.
    private dynamicallyLoadFive9SocialWidget() {
        if (this.isSpatialAdjust || !environment.showChatWidget || this._isFive9SocialWidgetLoaded) { return; }

        setTimeout(() => {
            if (this.isSpatialAdjust) return;

            const node = document.createElement('script');
            node.text = 'Five9SocialWidget.addWidget(options);';
            document.getElementsByTagName('body')[0].appendChild(node);

            this.setFive9ChatTooltip();
            this._isFive9SocialWidgetLoaded = true;
        }, 2000);
    }

    private setFive9ChatTooltip() {
        if (this._isFive9ChatTooltipSet) { return; }
        this._isFive9ChatTooltipSet = true;

        const btn = document.getElementById('five9-maximize-button');

        btn.setAttribute('data-tooltip', 'Chat With Us');
        btn.classList.add('show');

        // Add animation to the Chat dialog display.
        btn.addEventListener('click', () => {
            const chatFrame = document.getElementById('five9-frame-full');
            chatFrame.classList.remove('zero-size-frame');
            setTimeout(() => chatFrame.classList.add('full-size-frame'));
            // this.analyticsService.widgetEvent(AnalyticsEvent.TrackingWidgetCancelAccountIdDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
            this.analyticsService.widgetEvent(AnalyticsEvent.NsnChatOpened, AnalyticsCategory.Interaction, AnalyticsEvent.NsnChat);
        });

        const minIcon = document.getElementById('five9-minimize-icon');
        minIcon.addEventListener('click', () => {
            const chatFrame = document.getElementById('five9-frame-full');
            chatFrame.classList.remove('full-size-frame');
            chatFrame.classList.add('zero-size-frame');
            this.analyticsService.widgetEvent(AnalyticsEvent.NsnChatClosed, AnalyticsCategory.Interaction, AnalyticsEvent.NsnChat);
        });
    }

    // Hide Chat for Spatial Adjust
    private toggleFive9ChatButton() {
        const div = document.getElementsByClassName('five9-frame');
        if (div != null && div.length > 0) {
            const el = div[0] as HTMLElement;
            el.style.display = this.isSpatialAdjust ? 'none' : 'block';
        }
    }

    // Beamer

// <script>
//     var beamer_config = {
//         product_id : 'BdzEgfNP57917' //DO NOT CHANGE: This is your product code on Beamer
//     };
// </script>
// <script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer="defer"></script>

    private injectBeamerScript() {
        if (this.authManager.dashAuthenticatedUser == null) {
            setTimeout(() => this.injectBeamerScript(), 1000);
            return;
        }

        let node = document.createElement('script');
        node.text = `var beamer_config = {
						product_id: "prrlWpnI58418",
						selector: "toro-announce-btn",
						button: false,

						// NOTE: Gather these values dynamically if we choose to include them.
						user_id: '${this.authManager.dashAuthenticatedUser.email}',
						user_firstname: '${this.authManager.dashAuthenticatedUser.email}',
						// user_lastname: 'Wineman',
						user_email: '${this.authManager.dashAuthenticatedUser.email}',
						filter: '${this.getBeamerFilter()}'
					};`;
        document.getElementsByTagName('body')[0].appendChild(node);

        node = document.createElement('script');
        node.type = 'text/javascript';
        node.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        node.defer = true;
        document.getElementsByTagName('body')[0].appendChild(node);

        // // Ensure that we properly set the language of the Announcements Panel after Beamer is loaded.
        // setTimeout(() => {
        //     const profile = this.authManager.getUserProfile();
        //     this.cultureSettingsManager.setLanguage(this.cultureSettingsManager.getCultureId(profile.locale));
        // }, 1000)
    }

    private getBeamerFilter() {
        let filters = `${this.authManager.dashAuthenticatedUser.email}`

        // Add environment
        filters += `, env:${environment.name}`

        // Add demo if appropriate
        if (environment.isDemoMode) {
            filters += ', demo'
        }

        return filters;
    }

    private handleVisibilityChange() {
        if (document.visibilityState === 'visible') {
            // The application is in the foreground.
            this.analyticsService.event(AnalyticsEvent.AppActive, AnalyticsCategory.Application)
            this.broadcastService.appReactivate.next(null);

            clearInterval(this._appActiveIntervalRef);
            this._appActiveIntervalRef = setInterval(() => {
                this.analyticsService.event(AnalyticsEvent.AppActive, AnalyticsCategory.Application);
                console.log(">> Sending App Active");
            }, 5 * 1000 * 60);
        } else {
            // The application is in the background.
            clearInterval(this._appActiveIntervalRef);
        }
    }
}
