<div id="toro-app-header-container" class="toro-flex-child">

    <div id="toro-app-header-logo-n-toolbar-container">
        <div class="toro-app-header-logo"><img src="/assets/images/toro-intellidash-logo_r.png" alt="Toro" (click)="onLogoClick()"></div>
        <ng-container [ngTemplateOutlet]="appHeaderToolbar"></ng-container>
    </div>
    <div id="app-header-second-row">
        <div *ngIf="siteName" id="toro-app-header-location-container">
            <fa-icon icon="map-marker-alt"></fa-icon>
            <div id="toro-app-header-location">{{siteName}}</div>
        </div>
        <div *ngIf="!siteName" id="toro-app-header-spacer"></div>

        <div style="display: flex; align-items: center">
            <ng-container [ngTemplateOutlet]="widgetMoveToggle"></ng-container>
            <ng-container [ngTemplateOutlet]="widgetSelector"></ng-container>
        </div>
    </div>

    <div id="toro-mobile-app-header-container">
        <div id="toro-app-header-hamburger-icon" (click)="op.toggle($event)">
            <fa-icon icon="bars"></fa-icon>
        </div>
        <div id="toro-mobile-app-header-logo-container">
            <div class="toro-app-header-logo"><img src="/assets/images/toro-intellidash-logo_r.png" alt="Toro" (click)="onLogoClick()"></div>
        </div>
    </div>

    <p-overlayPanel #op [style]="{width: '95%', maxWidth: '400px'}">
        <ng-template pTemplate>
            <div id="toro-dash-mobile-menu">
                <div id="toro-dash-mobile-menu-header">
                    <div *ngIf="siteName" id="toro-mobile-header-location-container">
                        <fa-icon icon="map-marker-alt"></fa-icon>
                        <div id="toro-mobile-header-location">{{siteName}}</div>
                    </div>
                </div>

                <div id="toro-dash-mobile-menu-body">
                    <div class="dash-mobile-menu-body-wrappable-element">
                        <ng-container [ngTemplateOutlet]="widgetSelector"></ng-container>
                    </div>
                    <div class="dash-mobile-menu-body-wrappable-element">
                        <ng-container [ngTemplateOutlet]="appHeaderToolbar"></ng-container>
                    </div>
                </div>

            </div>
        </ng-template>
    </p-overlayPanel>

</div>

<toro-user-preferences-dlg
        *ngIf="showPreferencesDialog"
        (cancel)="this.showPreferencesDialog = false"
        (save)="this.showPreferencesDialog = false"
></toro-user-preferences-dlg>

<toro-user-feedback-dlg
        *ngIf="showUserFeedbackDialog"
        (save)="this.showUserFeedbackDialog = false"
        (cancel)="this.showUserFeedbackDialog = false"
></toro-user-feedback-dlg>

<ng-template #appHeaderToolbar>
    <div id="toro-app-header-toolbar">
        <div *ngIf="widgetList" class="toro-toolbar-button-separator"></div>
        <a *ngIf="widgetList" (click)="toggleWidgetDrag()">
            <div class="toro-app-header-toolbar-button" [pTooltip]="lockIconTooltip | translate" tooltipPosition="bottom" (click)="op.hide()">
                <fa-icon [icon]="lockIcon" style="color: #cd2027; font-size: 20px;"></fa-icon>
                <span class="app-header-toolbar-button-text">{{ lockIconTooltip | translate }}</span>
            </div>
        </a>

        <div class="toro-toolbar-button-separator"></div>
        <a (click)="onFeedbackClick()">
            <div class="toro-app-header-toolbar-button" [pTooltip]="'STRINGS.SUBMIT_FEEDBACK' | translate | titlecase" tooltipPosition="bottom" (click)="op.hide()">
                <fa-icon [icon]="['far', 'comments']"></fa-icon>
                <span class="app-header-toolbar-button-text">{{ "STRINGS.SUBMIT_FEEDBACK" | translate }}</span>
            </div>
        </a>

        <div class="toro-toolbar-button-separator"></div>
        <div class="toro-app-header-toolbar-button" [pTooltip]="'STRINGS.NOTIFICATIONS' | translate | titlecase" tooltipPosition="bottom" (click)="onToggleNotificationPanel()">
            <fa-icon [icon]="['far', 'bell']"></fa-icon>
            <span class="app-header-toolbar-button-text">{{ "STRINGS.NOTIFICATIONS" | translate }}</span>
            <div *ngIf="unreadNotificationsCount > 0" class="toro-notification-count" [ngClass]="{'high-notification-count': unreadNotificationsCount > 99}">
                <span>{{unreadNotificationsCount < 100 ? unreadNotificationsCount : '+'}}</span>
            </div>
        </div>

        <div class="toro-toolbar-button-separator"></div>
        <div class="toro-app-header-toolbar-button" id="toro-announce-btn" [pTooltip]="'STRINGS.ANNOUNCEMENTS' | translate | titlecase" tooltipPosition="bottom" (click)="!isMobile ? op.hide() : onShowAnnouncements()">
            <fa-icon icon="bullhorn"></fa-icon>
            <span class="app-header-toolbar-button-text">{{ "STRINGS.ANNOUNCEMENTS" | translate }}</span>
        </div>

        <div class="toro-toolbar-button-separator"></div>
        <div class="toro-app-header-toolbar-button" [pTooltip]="'STRINGS.USER_PREFERENCES' | translate | titlecase" tooltipPosition="bottom"
             (click)="onShowUserPreferences()"
        >
            <fa-icon [icon]="['far', 'user-circle']"></fa-icon>
            <span class="app-header-toolbar-button-text">{{ 'STRINGS.USER_PREFERENCES' | translate }}</span>
        </div>

        <div class="toro-toolbar-button-separator"></div>
        <div class="toro-app-header-toolbar-button" [pTooltip]="'CASE_SENSITIVE.HOW_TO_VIDEOS' | translate | titlecase" tooltipPosition="bottom"
             (click)="onToggleVideoPanel()"
        >
            <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
            <span class="app-header-toolbar-button-text">{{ 'CASE_SENSITIVE.HOW_TO_VIDEOS' | translate }}</span>
        </div>

        <div class="toro-toolbar-button-separator"></div>
        <div class="toro-app-header-toolbar-button" [pTooltip]="'STRINGS.SIGN_OUT' | translate | titlecase" tooltipPosition="bottom" (click)="logOut()">
            <fa-icon icon="sign-out-alt"></fa-icon>
            <span class="app-header-toolbar-button-text">{{ "STRINGS.SIGN_OUT" | translate }}</span>
        </div>
        <div class="toro-toolbar-button-separator"></div>
    </div>
</ng-template>

<ng-template #widgetSelector>
    <div id="toro-app-header-widget-selector-container" *ngIf="widgetList">
        <p-multiSelect
                [filter]="!isAndroid"
                [options]="widgetList"
                [(ngModel)]="selectedWidgets"
                [styleClass]="'widget-selector'"
                [displaySelectedLabel]="false"
                [defaultLabel]="'STRINGS.WIDGET_SELECTOR_LABEL' | translate"
                (onPanelHide)="onWidgetSelectionChange()"
        ></p-multiSelect>
    </div>
</ng-template>

<ng-template #widgetMoveToggle>
    <a id="setup-wizard-anchor" *ngIf="widgetList" (click)="onShowSetupWizard()">
        <div class="toro-app-header-toolbar-button" [pTooltip]="setupWizardIconTooltip | translate | titlecase" tooltipPosition="bottom" (click)="op.hide()">
            <fa-icon icon="magic"></fa-icon>
        </div>
    </a>
</ng-template>

<p-sidebar [(visible)]="showNotificationPanel" position="right" styleClass="toro-notification-sidebar" [modal]="false" [showCloseIcon]="false">
    <toro-notification-panel
            [isLoading]="isLoading"
            [notifications]="notifications"
            (refresh)="getNotifications(true);"
            (closePanel)="showNotificationPanel = false;"
    ></toro-notification-panel>
</p-sidebar>

<p-sidebar [(visible)]="showVideoPanel" position="right" styleClass="toro-notification-sidebar" [modal]="false" [showCloseIcon]="false">
    <toro-video-panel
            (closePanel)="showVideoPanel = false;"
    ></toro-video-panel>
</p-sidebar>

<toro-setup-wizard *ngIf="showSetupWizard" (closeWizard)="onCloseSetupWizard()"></toro-setup-wizard>
