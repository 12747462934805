export class PlaybooksLinkUrl {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    description: string;
    url: string;
}
