export class SiteTimeZoneInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }
    offsetFromUtcInSeconds: number;
    id: string;
    name: string;
}
