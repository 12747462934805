export class LynxPumpStatus {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    pumpId: number;         // Associated LynxId
    showAlert: boolean;
    id: number;
    pressure: number;
    flow: number;
}
