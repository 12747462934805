export class GetCoursesCourse {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.OnRainHold) this.onRainHold = json.OnRainHold;
            if (json.Name) this.name = json.Name;
            if (json.Id) this.id = json.Id;
            if (json.installationId) this.installationId = json.installationId;

            delete this['OnRainHold'];
            delete this['Name'];
            delete this['Id'];
        }
    }

    onRainHold: boolean;
    name: string;
    id: number;
    installationId: string;

}
