import DateFormat = ToroEnums.DateFormat;
import CurrencyCode = ToroEnums.CurrencyCode;
import GroupAndDecimalSeparator = ToroEnums.GroupAndDecimalSeparator;
import LanguageCode = ToroEnums.LanguageCode;
import TemperatureUnits = ToroEnums.TemperatureUnits;
import UnitsSystem = ToroEnums.UnitsSystem;
import WeatherGraphType = ToroEnums.WeatherGraphType;

import { LynxCloudCourseItem } from '../../../core/dashboard/widgets/lynx/widget-lynx-cloud/models/lynx-cloud-course-item.model';
import { SoilScoutGaugeRangeConfig } from '../../../core/dashboard/widgets/soil-scout/models/soil-scout-gauge-range-config.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';


export class DashUserPreferences {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.language) this.validateAndSetLanguage(json.language);
            if (!json.lastReceivedNotificationId) this.lastReceivedNotificationId = 0;
            if (json.ssMoistureRange) this.ssMoistureRange = new SoilScoutGaugeRangeConfig(json.ssMoistureRange);
            if (json.ssSalinityRange) this.ssSalinityRange = new SoilScoutGaugeRangeConfig(json.ssSalinityRange);
            if (json.ssTemperatureRange) this.ssTemperatureRange = new SoilScoutGaugeRangeConfig(json.ssTemperatureRange);
        }
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    language = LanguageCode.English_US;
    dateFormat = DateFormat.MMhDDhYY;
    unitsSystem = UnitsSystem.Imperial;
    temperatureUnits = TemperatureUnits.Fahrenheit;
    currency = CurrencyCode.UsDollar;
    groupAndDecimalSeparator = GroupAndDecimalSeparator.CommaPeriod;
    weatherGraphs: WeatherGraphType[] = [WeatherGraphType.Wind, WeatherGraphType.Temperature, WeatherGraphType.Precipitation, WeatherGraphType.SolarRadiation];
    lastReceivedNotificationId = 0;
    lynxLocalWeatherGraphs: WeatherGraphType[] =
        [WeatherGraphType.Wind, WeatherGraphType.Temperature, WeatherGraphType.Precipitation, WeatherGraphType.RelativeHumidity];

    // This represents the IntelliDash database Id for the Lynx Course Id. This is not the LynxCourseId passed back by the api.
    lynxCourseId: number = null;
    lynxCloudCourse: LynxCloudCourseItem = null;
    calAmpAccountId: number = null;
    gfCalAmpAccountId: number = null;
    taskTrackerLaborSelectedDepartments: number[];
    taskTrackerMeasurementsSelectedDepartments: number[];
    taskTrackerSafetiesSelectedDepartments: number[];
    taskTrackerApiKey: string;
    pogoApiKey: string;
    spectrumApiKey: string;
    pwLocationId: number;
    pwIntegrationKey: string;
    ssMoistureRange: SoilScoutGaugeRangeConfig;
    ssTemperatureRange: SoilScoutGaugeRangeConfig;
    ssSalinityRange: SoilScoutGaugeRangeConfig;

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private validateAndSetLanguage(value: string) {
        const enumValue = Object.values(LanguageCode).find(v => v === value.toLowerCase());
        this.language = enumValue || LanguageCode.English_US;
    }
}
