import { LynxPumpGaugeRanges } from './lynx-pump-gauge-ranges.model';

export abstract class LynxPumpConfig {
    private _pumpGaugeRanges: LynxPumpGaugeRanges;

    valueUnits: string;
    minimum: number;
    maximum: number;
    expected: number;
    minimumAlert: number;
    maximumAlert: number;

    // Calculated ranges for Pump Station Gauge (and details dialog)
    get pumpGaugeRanges(): LynxPumpGaugeRanges {
        if (!this._pumpGaugeRanges) {
            this._pumpGaugeRanges = <LynxPumpGaugeRanges>{
                min: this.minimum,
                midMin: this.maximum * 0.7,
                midMax: this.maximum * 1.05,
                max: this.maximum * 1.15,
                setPoint: this.expected,
            };
        }

        return this._pumpGaugeRanges;
    }

}
