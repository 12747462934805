import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import SoilScoutGaugeColor = ToroEnums.SoilScoutGaugeColor;
import SoilScoutSensorType = ToroEnums.SoilScoutSensorType;

import * as Highcharts from 'highcharts';
import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { environment } from '../../../../../environments/environment';
import { LocalCacheService } from '../../../../common/services/local-cache.service';
import { SoilScoutAlert } from '../../../../api/soil-scout/models/soil-scout-alert.model';
import { SoilScoutChartData } from './models/soil-scout-chart-data.model';
import { SoilScoutDevice } from '../../../../api/soil-scout/models/soil-scout-device.model';
import { SoilScoutGaugeRange } from './models/soil-scout-gauge-range.model';
import { SoilScoutGaugeRangeConfig } from './models/soil-scout-gauge-range-config.model';
import { SoilScoutGroup } from '../../../../api/soil-scout/models/soil-scout-group.model';
import { SoilScoutLogin } from './models/soil-scout-login.model';
import { SoilScoutLoginResponse } from '../../../../api/soil-scout/models/soil-scout-login-response.model';
import { SoilScoutManagerService } from '../../../../api/soil-scout/soil-scout-manager.service';
import { SoilScoutNotification } from '../../../../api/soil-scout/models/soil-scout-notification.model';
import { SoilScoutRule } from '../../../../api/soil-scout/models/soil-scout-rule.model';
import { SoilScoutSite } from '../../../../api/soil-scout/models/soil-scout-site.model';
import { SoilScoutThresholdChange } from './models/soil-scout-threshold-change.model';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroUtils } from '../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../common/services/user-format.service';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@UntilDestroy()
@Directive()
export abstract class SoilScoutWidgetBase extends ToroDashboardWidget implements OnInit {
    @Output() static unableToRetrieveData = new EventEmitter<string>();
    @Output() static attemptingLogin = new EventEmitter<SoilScoutSensorType>();
    @Output() static successfulLogin = new EventEmitter<SoilScoutSensorType>();
    @Output() static gaugeRangeUpdate = new EventEmitter<SoilScoutThresholdChange>();

    static commonDataUpdated = new Subject();

    protected sensorType: SoilScoutSensorType;
    protected selectedSiteId: number;
    protected gaugeRange: SoilScoutGaugeRange;
    private adjustedGaugeRange: SoilScoutGaugeRange;
    protected gaugeValue: number;
    protected gaugeDisplayValue: string;
    protected showDesktopModal = false;
    protected sensorAlertsCount: number;
    protected isLoginDialogDisplayed = false;
    protected isThresholdsDialogDisplayed = false;

    static userPreferences: DashUserPreferences;
    static moistureSensorAlertsCount: number;
    static temperatureSensorAlertsCount: number;
    static salinitySensorAlertsCount: number;

    static isRetrievingData = false;
    static selectedSite: SoilScoutSite;
    static siteDevices: SoilScoutDevice[];
    static siteGroups: SoilScoutGroup[];
    static siteNotifications: SoilScoutNotification[];
    static siteActiveAlerts: SoilScoutAlert[];
    static siteChartData: SoilScoutChartData;
    static isMetric = false;

    static siteRules: SoilScoutRule[];
    static siteAverageMoisture: number;
    static siteAverageTemperature: number;
    static siteAverageSalinity: number;

    static siteMoistureDisplayValue: string;
    static siteTemperatureDisplayValue: string;
    static siteSalinityDisplayValue: string;

    // Chart
    Highcharts = Highcharts;
    gaugeOptions: any = null;
    gauge: Highcharts.Chart;
    chartOptions: any = null;
    chart: Highcharts.Chart;
    isGaugeLoaded = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected localCacheService: LocalCacheService,
                protected soilScoutManager: SoilScoutManagerService,
                protected translateService: TranslateService,
                protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit() {
        super.ngOnInit();

        SoilScoutWidgetBase.commonDataUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isBusy = false;
                this.setGaugeValues();
            });

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe((userPreferences: DashUserPreferences) => {
                SoilScoutWidgetBase.userPreferences = userPreferences;
                SoilScoutWidgetBase.isMetric = this.userFormatService.isMetric;
                this.setDisplayValues()
                this.setGaugeValues();
            });

        SoilScoutWidgetBase.isMetric = this.userFormatService.isMetric;
    }

    // =========================================================================================================================================================
    // Abstract Methods
    // =========================================================================================================================================================

    protected abstract get analyticsWidgetName(): string;

    protected abstract get axisValueUnits(): string;

    protected get gaugeContainerWidth(): number {
        return 0;
    }

    // =========================================================================================================================================================
    // Protected Methods
    // =========================================================================================================================================================

    protected getWidgetData(isManualRefresh) {
        if (SoilScoutWidgetBase.isRetrievingData) { return; }

        SoilScoutWidgetBase.isRetrievingData = true;
        this.clearIsUnableToFetchData();

        if (isManualRefresh) { this.isBusy = true; }

        if (environment.isDemoMode) {
            this.getSites();
            return;
        }

        if (this.localCacheService.soilScoutRefreshToken == null || this.localCacheService.soilScoutRefreshToken == 'null' || this.localCacheService.soilScoutRefreshToken == '') {
            this.setIsUnableToFetchData('STRINGS.PROVIDE_SOIL_SCOUT_LOGIN');
            return;
        }

        this.soilScoutManager.refreshAccessToken()
            .pipe(take(1))
            .subscribe({
                next: (token: string) => {
                    if (this.selectedSiteId != null) {
                        this.getSoilScoutData();
                    } else {
                        this.getSites();
                    }
                },
                error: err => {
                    this.setIsUnableToFetchData('STRINGS.PROVIDE_SOIL_SCOUT_LOGIN');
                }
            })
    }

    protected setIsUnableToFetchData(reason?: string, linkHtml?: string, notify = true) {
        SoilScoutWidgetBase.isRetrievingData = false;
        super.setIsUnableToFetchData(reason, linkHtml);

        if (notify) { SoilScoutWidgetBase.unableToRetrieveData.emit(reason); }
    }

    protected get dataValueColor(): string {
        if (this.gaugeValue < this.gaugeRange.range1UpperBoundary) {
            return this.gaugeRange.range1Color;
        } else if (this.gaugeValue < this.gaugeRange.range2UpperBoundary) {
            return this.gaugeRange.range2Color;
        } else if (this.gaugeValue < (this.gaugeRange.range3UpperBoundary ?? this.gaugeRange.rangeMax)) {
            return this.gaugeRange.range3Color
        } else {
            return this.gaugeRange.range4Color;
        }
    }

    protected get defaultMoistureRange(): SoilScoutGaugeRange {
        return new SoilScoutGaugeRange({
            rangeMin: 0,
            rangeMax: 25,
            range1UpperBoundary: 14,
            range2UpperBoundary: 18,
            range3UpperBoundary: 22,
            range1Color: SoilScoutGaugeColor.Red,
            range2Color: SoilScoutGaugeColor.Yellow,
            range3Color: SoilScoutGaugeColor.Green,
            range4Color: SoilScoutGaugeColor.Blue
        });
    }

    protected get defaultSalinityRange(): SoilScoutGaugeRange {
        return new SoilScoutGaugeRange({
            rangeMin: 0,
            rangeMax: 5,
            range1UpperBoundary: 1,
            range2UpperBoundary: 2,
            range1Color: SoilScoutGaugeColor.Green,
            range2Color: SoilScoutGaugeColor.Yellow,
            range3Color: SoilScoutGaugeColor.Red
        });
    }

    protected get defaultTemperatureRange(): SoilScoutGaugeRange {
        return new SoilScoutGaugeRange({
            rangeMin: 0,
            rangeMax: 100,
            range1UpperBoundary: 45,
            range2UpperBoundary: 70,
            range3UpperBoundary: 85,
            range1Color: SoilScoutGaugeColor.Blue,
            range2Color: SoilScoutGaugeColor.Green,
            range3Color: SoilScoutGaugeColor.Yellow,
            range4Color: SoilScoutGaugeColor.Red
        });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected onLaunchModalWidget() {
        super.onLaunchModalWidget();

        setTimeout(() => {
            this.setupGauge()
            setTimeout(() => this.setGaugeValues(), 0);
        }, 0);
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: `${ToroUtils.Translate.instant('CASE_SENSITIVE.LOGIN_TO_SOIL_SCOUT')}...`,
                icon: 'pi pi-fw pi-key',
                command: this.showLoginDialog.bind(this)
            },
            {
                label: `${ToroUtils.Translate.instant('STRINGS.SET_DATA_THRESHOLDS')}...`,
                icon: 'pi pi-fw pi-sliders-h',
                command: this.showThresholdsDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSiteScoutLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_SOIL_SCOUT' });
        setTimeout(() => window.open(environment.soilScoutHubUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    protected onShowDetails() {
        this.showDesktopModal = true;
    }

    protected onLogin(loginModel: SoilScoutLogin) {
        this.isLoginDialogDisplayed = false;
        this.loginToSoilScout(loginModel);
    }

    protected onUpdateRanges(change: SoilScoutThresholdChange): void {
        this.isThresholdsDialogDisplayed = false;
        SoilScoutWidgetBase.gaugeRangeUpdate.emit(change);

        // Save updated range values to user preferences (i.e., the database)
        if (change.moistureGaugeRange != null) { SoilScoutWidgetBase.userPreferences.ssMoistureRange = new SoilScoutGaugeRangeConfig(change.moistureGaugeRange); }
        if (change.temperatureGaugeRange != null) { SoilScoutWidgetBase.userPreferences.ssTemperatureRange = new SoilScoutGaugeRangeConfig(change.temperatureGaugeRange); }
        if (change.salinityGaugeRange != null) { SoilScoutWidgetBase.userPreferences.ssSalinityRange = new SoilScoutGaugeRangeConfig(change.salinityGaugeRange); }
        this.dashUserManager.updateDashUserPreferences(SoilScoutWidgetBase.userPreferences).subscribe();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getSoilScoutData() {
        if (this.selectedSiteId == null) { this.setIsUnableToFetchData(); }

        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.soilScoutManager.getDevices(this.selectedSiteId).pipe(take(1)),
            this.soilScoutManager.getGroups(this.selectedSiteId).pipe(take(1)),
            this.soilScoutManager.getNotifications(this.selectedSiteId).pipe(take(1)),
            this.soilScoutManager.getRules(this.selectedSiteId).pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(
                finalize(() => {
                    this.isBusy = false;
                    SoilScoutWidgetBase.isRetrievingData = false;

                    this.setSensorAlertCounts();
                    SoilScoutWidgetBase.commonDataUpdated.next(null);
                })
            )
            .subscribe({
                next: ([dashUser, devices, groups, notifications, rules]) => {
                    SoilScoutWidgetBase.userPreferences = dashUser.preferences;
                    SoilScoutWidgetBase.siteDevices = devices;
                    SoilScoutWidgetBase.siteGroups = groups;
                    SoilScoutWidgetBase.siteNotifications = notifications;
                    SoilScoutWidgetBase.siteRules = rules;
                    SoilScoutWidgetBase.siteChartData = new SoilScoutChartData(groups, devices.filter(d => d.device_type == 'hydra'));

                    this.calculateSiteAverages();
                    this.setActiveSiteAlerts();

                    this.lastUpdateTimestamp = new Date();
                },
                error: err => {
                    console.log();
                    this.setIsUnableToFetchData();
                }
            })
    }

    private setSensorAlertCounts() {
        const temperatureAlerts = SoilScoutWidgetBase.siteActiveAlerts.filter(a => a.isTemperatureAlert == true);
        SoilScoutWidgetBase.temperatureSensorAlertsCount = temperatureAlerts.reduce((sum, a) => sum += a.activeSensorAlertsCount, 0);

        const moistureAlerts = SoilScoutWidgetBase.siteActiveAlerts.filter(a => a.isMoistureAlert == true);
        SoilScoutWidgetBase.moistureSensorAlertsCount = moistureAlerts.reduce((sum, a) => sum += a.activeSensorAlertsCount, 0);

        const salinityAlerts = SoilScoutWidgetBase.siteActiveAlerts.filter(a => a.isSalinityAlert == true);
        SoilScoutWidgetBase.salinitySensorAlertsCount = salinityAlerts.reduce((sum, a) => sum += a.activeSensorAlertsCount, 0);
    }

    private calculateSiteAverages() {
        const hydraDevices = SoilScoutWidgetBase.siteDevices.filter(d => d.device_type == 'hydra' && d.device_status == 'OK');

        const moistureSum = hydraDevices.reduce((sum, current) => sum + current.last_measurement.moisture, 0)
        SoilScoutWidgetBase.siteAverageMoisture = moistureSum / hydraDevices.length;

        const salinitySum = hydraDevices.reduce((sum, current) => sum + current.last_measurement.salinity, 0)
        SoilScoutWidgetBase.siteAverageSalinity = salinitySum / hydraDevices.length;

        const temperatureSum = hydraDevices.reduce((sum, current) => sum + current.last_measurement.temperature, 0)
        SoilScoutWidgetBase.siteAverageTemperature = temperatureSum / hydraDevices.length;

        this.setDisplayValues();
    }

    private setActiveSiteAlerts() {
        SoilScoutWidgetBase.siteActiveAlerts = [];

        const activeNotifications = SoilScoutWidgetBase.siteNotifications.filter(n => n.resolved == null);
        SoilScoutWidgetBase.siteActiveAlerts = activeNotifications.map(n => {
            const rule = SoilScoutWidgetBase.siteRules.find(r => r.id == n.rule_id);
            if (rule != null) return new SoilScoutAlert(n, rule);
        })
    }

    private setDisplayValues(): void {
        if (SoilScoutWidgetBase.siteAverageSalinity == null) return;

        SoilScoutWidgetBase.siteSalinityDisplayValue =
            `${<string>this.userFormatService.salinity(SoilScoutWidgetBase.siteAverageSalinity, true, 2)} ${this.userFormatService.salinityUnits}`;

        SoilScoutWidgetBase.siteMoistureDisplayValue =
            `${<string>this.userFormatService.toUserNumber(SoilScoutWidgetBase.siteAverageMoisture * 100, 0, 2)}%`;

        SoilScoutWidgetBase.siteTemperatureDisplayValue =
            `${<string>this.userFormatService.temperature(<number>this.userFormatService.convertCelsiusToFahrenheit(SoilScoutWidgetBase.siteAverageTemperature, false, 1), true, 1)}˚${this.userFormatService.temperatureUnits}`;
    }

    private getSites() {
        this.soilScoutManager.getSites()
            .pipe(take(1),)
            .subscribe({
                next: (sites: SoilScoutSite[]) => {
                    if (sites != null && sites.length > 0) {
                        SoilScoutWidgetBase.selectedSite = sites[0];
                        this.selectedSiteId = sites[0].id;
                        this.getSoilScoutData()
                        return;
                    }

                    this.setIsUnableToFetchData();
                },
                error: err => {
                    this.setIsUnableToFetchData();
                }
            })
    }

    private loginToSoilScout(loginModel: SoilScoutLogin) {
        if (loginModel == null) return;

        this.isBusy = true;
        SoilScoutWidgetBase.attemptingLogin.emit(this.sensorType);

        this.soilScoutManager.login(loginModel.username, loginModel.password)
            .subscribe({
                next: (response: SoilScoutLoginResponse) => {
                    this.clearIsUnableToFetchData();
                    this.getSites()

                    SoilScoutWidgetBase.successfulLogin.emit(this.sensorType);
                },
                error: err => {
                    this.setIsUnableToFetchData('STRINGS.PROVIDE_SOIL_SCOUT_LOGIN');
                }
            })
    }

    private showLoginDialog() {
        this.isLoginDialogDisplayed = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.SoilScoutWidgetShowALoginDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private showThresholdsDialog() {
        this.isThresholdsDialogDisplayed = true;
        this.analyticsService.widgetEvent(AnalyticsEvent.SoilScoutWidgetShowAThresholdsDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    // =========================================================================================================================================================
    // Chart
    // =========================================================================================================================================================

    protected clearGauge() {
        this.gauge = null;
        this.gaugeOptions = null;
    }

    protected setupGauge() {
        const self = this;

        this.gaugeOptions = {
            caption: {
                text: '<div id="ss-datum-container">' +
                    '<div><img src="../../../../../assets/images/soil-scout/soil-scout-moisture.png" alt=""></div>' +
                    '<div id="ss-datum-value">' + self.gaugeDisplayValue + '</div>' +
                    '</div>',
                useHTML: true,
                x: !self.isGridsterInMobileMode ? 103 : ((this.gaugeContainerWidth / 2) - 63),
                y: -42,
                floating: true,
                margin: 0
            },

            chart: {
                type: 'gauge',
                // spacing: [40, 15, 0, 15],
                animation: !self.isGridsterInMobileMode,
                spacing: [10, 0, 8, 0],
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                events: {
                    load(event) {
                        self.gauge = event.target;
                    }
                }
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            pane: {
                startAngle: -90,
                endAngle: 90,
                size: '140%',
                center: ['50%', '85%'],
                background: [{
                    backgroundColor: null,
                    borderWidth: 0,
                    outerRadius: '100%',
                }]
            },
            yAxis: [{						                                    // Outer label Axis
                min: 0,                                                         // This will changed in setGaugeValues()
                max: 100,                                                       // This will changed in setGaugeValues()

                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 7,
                minorTickPosition: 'inside',
                minorTickColor: 'lightgray',

                tickInterval: self.tickInterval,
                tickPixelInterval: 30,                                         // Distance between dial legend values
                tickWidth: 1,
                tickPosition: 'inside',
                tickLength: 12,
                tickColor: '#000',

                lineWidth: 0,                                                   // Controls the outer band encompassing the tick marks

                labels: {
                    formatter() {
                        return this.value + self.axisValueUnits;
                    },
                    distance: -25,                                              // Position of dial legend values (e.g., 20%)
                },
                title: { enabled: false },
            }],
            series: [{
                name: 'moisture',
                data: [0],
                dataLabels: { enabled: false },
                dial: {
                    radius: '90%',
                    // backgroundColor: '#646e73',
                    backgroundColor: '#ccc',
                    topWidth: 3,
                    baseWidth: 15.1,
                    baseLength: 0,                                              // Length of the base - affects the taper/shape of the dial
                    rearLength: 0,                                              // Amount sticking out behind the pivot
                },
                pivot: {
                    radius: 5,
                    backgroundColor: 'white',
                    borderColor: '#ccc',
                    borderWidth: 5
                }
            }],

            tooltip: {
                enabled: false
            }

        };
    }

    private get tickInterval() {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                return 5;
            case ToroEnums.SoilScoutSensorType.Temperature:
                return 10;
            case ToroEnums.SoilScoutSensorType.Salinity:
                return 0.5;
            default:
                return 'auto';
        }
    }

    protected setGaugeValues() {
        switch (this.sensorType) {
            case ToroEnums.SoilScoutSensorType.Moisture:
                this.gaugeValue = SoilScoutWidgetBase.siteAverageMoisture * 100;
                this.gaugeDisplayValue = SoilScoutWidgetBase.siteMoistureDisplayValue;
                break;
            case ToroEnums.SoilScoutSensorType.Temperature:
                this.gaugeValue = <number>this.userFormatService.temperatureFromCelsius(SoilScoutWidgetBase.siteAverageTemperature, false, 1);
                this.gaugeDisplayValue = SoilScoutWidgetBase.siteTemperatureDisplayValue;
                break;
            case ToroEnums.SoilScoutSensorType.Salinity:
                this.gaugeValue = SoilScoutWidgetBase.siteAverageSalinity;
                this.gaugeDisplayValue = SoilScoutWidgetBase.siteSalinityDisplayValue;
                break;
            default:
                return;
        }

        if (!this.gauge || !this.gauge.series) { return; }

        // If gauge value is beyond the gauge range, let's peg the dial
        this.gaugeValue = this.getAdjustedGaugeValue();

        // Gauge Value
        this.gauge.series[0].points[0].update(this.gaugeValue);

        this.adjustedGaugeRange = this.getAdjustedGaugeRange();

        // Gauge Range
        this.gauge.yAxis[0].update({ min: this.adjustedGaugeRange.rangeMin, max: this.adjustedGaugeRange.rangeMax });

        // Plot Bands
        this.updatePlotBand(this.gauge, 'r1', this.adjustedGaugeRange.rangeMin, this.adjustedGaugeRange.range1UpperBoundary, this.adjustedGaugeRange.range1Color);
        this.updatePlotBand(this.gauge, 'r2', this.adjustedGaugeRange.range1UpperBoundary + .01, this.adjustedGaugeRange.range2UpperBoundary, this.adjustedGaugeRange.range2Color);

        if (this.adjustedGaugeRange.range3UpperBoundary != null) {
            this.updatePlotBand(this.gauge, 'r3', this.adjustedGaugeRange.range2UpperBoundary + .01, this.adjustedGaugeRange.range3UpperBoundary, this.adjustedGaugeRange.range3Color);
            this.updatePlotBand(this.gauge, 'r4', this.adjustedGaugeRange.range3UpperBoundary + .01, this.adjustedGaugeRange.rangeMax, this.adjustedGaugeRange.range4Color);
        } else {
            this.updatePlotBand(this.gauge, 'r3', this.adjustedGaugeRange.range2UpperBoundary + .01, this.adjustedGaugeRange.rangeMax, this.adjustedGaugeRange.range3Color);
        }

        // Redraw the chart to pick up changes.
        this.gauge.reflow();

        // Update caption to display current value
        this.gauge.setCaption({
            text: '<div id="ss-datum-container">' +
                '<div><img src="../../../../../assets/images/soil-scout/' + this.sensorImage + '.png" alt=""></div>' +
                '<div id="ss-datum-value" style="color: ' + this.dataValueColor + '">' + this.gaugeDisplayValue + '</div>' +
                '</div>'
        })

        this.isGaugeLoaded = true;
    }

    private getAdjustedGaugeValue() {
        // Gauge value is within range. No adjustment necessary
        if (this.gaugeValue <= this.gaugeRange.rangeMax && this.gaugeValue >= this.gaugeRange.rangeMin) { return this.gaugeValue; }

        // Gauge value is > range max
        if (this.gaugeValue > this.gaugeRange.rangeMax) {
            switch (this.sensorType) {
                case ToroEnums.SoilScoutSensorType.Moisture:
                    return this.gaugeRange.rangeMax + 1;
                case ToroEnums.SoilScoutSensorType.Temperature:
                    return this.gaugeRange.rangeMax + 4;
                case ToroEnums.SoilScoutSensorType.Salinity:
                    return this.gaugeRange.rangeMax + .15;
            }
        }

        if (this.gaugeValue < this.gaugeRange.rangeMin) {
            switch (this.sensorType) {
                case ToroEnums.SoilScoutSensorType.Moisture:
                    return this.gaugeRange.rangeMin - 1;
                case ToroEnums.SoilScoutSensorType.Temperature:
                    return this.gaugeRange.rangeMin - 4;
                case ToroEnums.SoilScoutSensorType.Salinity:
                    return this.gaugeRange.rangeMin - .15;
            }
        }
    }

    private getAdjustedGaugeRange(): SoilScoutGaugeRange {
        const tempGaugeRange = new SoilScoutGaugeRange(this.gaugeRange);

        switch (this.sensorType) {
            case SoilScoutSensorType.Temperature:
                tempGaugeRange.rangeMin = Math.round(<number>this.userFormatService.temperature(this.gaugeRange.rangeMin));
                tempGaugeRange.rangeMax = Math.round(<number>this.userFormatService.temperature(this.gaugeRange.rangeMax));
                tempGaugeRange.range1UpperBoundary = <number>this.userFormatService.temperature(this.gaugeRange.range1UpperBoundary);
                tempGaugeRange.range2UpperBoundary = <number>this.userFormatService.temperature(this.gaugeRange.range2UpperBoundary);

                if (this.gaugeRange.range3UpperBoundary != null) {
                    tempGaugeRange.range3UpperBoundary = <number>this.userFormatService.temperature(this.gaugeRange.range3UpperBoundary);
                }
                break;
            // case SoilScoutSensorType.Salinity:
            //     tempGaugeRange.rangeMin = Math.round(<number>this.userFormatService.salinity(this.gaugeRange.rangeMin));
            //     tempGaugeRange.rangeMax = Math.round(<number>this.userFormatService.salinity(this.gaugeRange.rangeMax));
            //     tempGaugeRange.range1UpperBoundary = <number>this.userFormatService.salinity(this.gaugeRange.range1UpperBoundary);
            //     tempGaugeRange.range2UpperBoundary = <number>this.userFormatService.salinity(this.gaugeRange.range2UpperBoundary);
            //     break;
            default:
                return this.gaugeRange
        }

        return tempGaugeRange;
    }

    private updatePlotBand(chart: Highcharts.Chart, id: string, from: number, to: number, color: string) {
        chart.yAxis[0].removePlotBand(id);
        chart.yAxis[0].addPlotBand({ id, borderColor: '#666', borderWidth: 0, thickness: 15, outerRadius: '120%', from, to, color });
    }

    private get sensorImage(): string {
        switch (this.sensorType) {
            case SoilScoutSensorType.Moisture:
                return 'soil-scout-moisture';
            case SoilScoutSensorType.Temperature:
                return 'soil-scout-temperature';
            case SoilScoutSensorType.Salinity:
                return 'soil-scout-salinity';
            default:
                return '';
        }
    }

}
