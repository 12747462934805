export class ToroConstants {

    static readonly MaxMobileWindowWidth = 768;

    static WeatherStandardConditionNames = {
        regular: ['sun', 'clear'],
        cloud: ['partly sunny', 'partlysunny', 'partly cloudy', 'partlycloudy', 'cloudy'],
        rain: [ 'rain', 'rainy', 'raining' ],
        snow: [ 'snow'],
        thunder: [ 'lightning', 'thunderstorm' ],
        shower: [ 'shower', 'showers' ],
        drizzle: [ 'drizzle' ],
        'freezing rain': ['freezing rain', 'freezingrain', 'hail'],
        sleet: ['sleet']
    };

    static WeatherConditionAndCloudCoverToIconMap = {
        regular: ['sun', 'cloud-sun', 'cloud-sun', 'cloud', 'cloud'],
        cloud: ['cloud-sun', 'cloud-sun', 'cloud-sun', 'cloud', 'cloud'],
        rain: ['showers-sun', 'showers-sun', 'showers-sun', 'rain', 'rain'],
        snow: ['snow-sun', 'snow-sun', 'snow-sun', 'snow', 'snow'],
        thunder: ['thunder-sun', 'thunder-sun', 'thunder-sun', 'thunder', 'thunder'],
        shower: ['showers-sun', 'showers-sun', 'showers-sun', 'showers', 'showers'],
        drizzle: ['drizzle-sun', 'drizzle-sun', 'drizzle-sun', 'drizzle', 'drizzle'],
        'freezing rain': ['hail-sun', 'hail-sun', 'hail-sun', 'hail', 'hail'],
        sleet: ['sleet-sun', 'sleet-sun', 'sleet-sun', 'sleet', 'sleet']
    };

}
