import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { AlertSettingsAndParameters } from '../../api/notifications/models/alert-settings-and-parameters.model';
import { AnalyticsService } from '../../common/services/analytics.service';
import { DashMessageService } from '../../common/services/dash-message.service';
import { DashUserManagerService } from '../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../api/dash-user/models/dash-user-preferences.model';
import { NotificationManagerService } from '../../api/notifications/notification-manager.service';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../common/enumerations/analytics.enums';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@Component({
    selector: 'toro-user-preferences-dlg',
    templateUrl: './user-preferences-dlg.component.html',
    styleUrls: ['./user-preferences-dlg.component.less']
})
export class UserPreferencesDlgComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();

    isBusy = false;
    dashUserPrefs: DashUserPreferences;
    alertSettings: AlertSettingsAndParameters;
    languagesList: SelectItem[] = [];
    temperatureScalesList: SelectItem[] = [];
    unitsSystemsList: SelectItem[] = [];
    dateFormatsList: SelectItem[] = [];
    groupAndDecimalFormatsList: SelectItem[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private dashMessageService: DashMessageService,
                private dashUserManager: DashUserManagerService,
                private notificationManager: NotificationManagerService
    ) { }

    ngOnInit(): void {
        this.languagesList = this.dashUserManager.languageSelectItems;
        this.temperatureScalesList = this.dashUserManager.temperatureScaleSelectItems;
        this.unitsSystemsList = this.dashUserManager.unitsOfMeasureSelectItems;
        this.dateFormatsList = this.dashUserManager.dateFormatsSelectItems;
        this.groupAndDecimalFormatsList = this.dashUserManager.groupAndDecimalFormatSelectItems;

        this.getComponentData();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCancel() {
        this.cancel.emit();
        this.analyticsService.event(AnalyticsEvent.PreferencesDialogCancelled, AnalyticsCategory.Preferences);
    }

    onSave() {
        const sources: Observable<any>[] = [
            this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs),
            this.notificationManager.setAlertSettingsAndParameters(this.alertSettings)
        ];

        this.isBusy = true;
        forkJoin(sources)
            .pipe(
                finalize(() => this.isBusy = false)
            )
            .subscribe({
                next: () => {
                    this.save.emit();
                    this.analyticsService.event(AnalyticsEvent.PreferencesDialogSaved, AnalyticsCategory.Preferences);
                },
                error: () => this.dashMessageService.showGenericSaveErrorMessage()
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {
        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.notificationManager.getAlertSettingsAndParameters().pipe(take(1))
        ];

        this.isBusy = true;
        forkJoin(sources)
            .pipe(
                finalize(() => this.isBusy = false)
            )
            .subscribe(([dashUserInfo, alertSettings]) => {
                this.dashUserPrefs = new DashUserPreferences(dashUserInfo.preferences);
                this.alertSettings = alertSettings;
            }, error => {
                this.dashMessageService.showGenericFetchErrorMessage();
            });
    }
}
