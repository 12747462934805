import { ToroEnums } from '../../../common/enumerations/toro.enums';

import WidgetType = ToroEnums.WidgetType;

export class DecisionTreeConfig {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    isDecisionTreeConfig = true;

    selectedZoneId: number;
    selectedSensorNumber: number;
    canopyCoverPercentage: number;
    soilSensorThreshold: number;
    canopyCoverThreshold: number;
    estimatedRainThreshold: number;
    avgSoilMoisture: number;

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;

}
