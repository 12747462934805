<div class="dt-breakdown-item-container toro-component-shield-host" [ngStyle]="{'border-color': itemColor}" [ngClass]="styleClass">
    <div class="tdtb-value-header" style="justify-content: space-between;" [ngStyle]="{'color': itemColor, 'border-color': itemColor}">
        <div class="dt-breakdown-header">
            <i id="tdtb-value-header-icon" [class]="headerIcon" [ngStyle]="{'font-size': iconSize}"></i>
            <span class="tdtb-value-header-text">{{headerText | translate | titlecase}}</span>
        </div>
        <span id="dt-breakdown-range">({{displayRangeBottom}}<span *ngIf="valueUnits != null">{{valueUnits}}</span> - {{displayRangeTop}}<span *ngIf="valueUnits != null">{{valueUnits}}</span>)</span>
    </div>

    <div class="tdtb-value-meter-container" [ngStyle]="{'background-color': gaugeBackgroundColor}">
        <div class="tdtb-value-meter" [ngStyle]="{'background-color': itemColor, 'max-height': meterPercentage + '%'}"></div>
            <div #thresholdValue class="tdtb-value-threshold" [ngStyle]="{'bottom': thresholdPercentage - thresholdValueHOffset + 2 + '%'}">
            {{displayThreshold}}
            <span *ngIf="valueUnits != null">{{valueUnits}}</span>
        </div>
        <hr class="tdtb-threshold-bar left-bar" [ngStyle]="{'bottom': thresholdPercentage - thresholdValueHOffset + '%'}" [ngClass]="{'is-metric': isMetric}">
        <div class="tdtb-value-container">
            <div class="tdtb-value" [ngStyle]="{'color': itemColor}">{{displayValue}}
                <span *ngIf="valueUnits != null" class="tdtb-value-units">{{valueUnits | translate}}</span>
            </div>
        </div>
    </div>

    <div class="toro-component-shield" [pTooltip]="tooltipText" tooltipPosition="top"></div>
</div>
