import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { LocalCacheService } from '../../../../../common/services/local-cache.service';
import { SoilScoutLoginResponse } from '../../../../../api/soil-scout/models/soil-scout-login-response.model';
import { SoilScoutManagerService } from '../../../../../api/soil-scout/soil-scout-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@UntilDestroy()
@Component({
    selector: 'toro-soil-scout-integration',
    templateUrl: './soil-scout-integration.component.html',
    styleUrls: ['./soil-scout-integration.component.less']
})
export class SoilScoutIntegrationComponent implements OnInit {
    @HostBinding('class') class = 'wiz-integration-component soil-scout-integration';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();

    readonly stepsCount = 1;

    isBusy = false;
    showError = false;
    spinnerText = 'CASE_SENSITIVE.VERIFYING_LOGIN_INFORMATION'

    private _userName: string;
    protected set username(value: string) {
        this._userName = value;
        this.checkConnectability();
    }

    protected get username(): string {
        return this._userName;
    }

    private _password: string;
    protected set password(value: string) {
        this._password = value;
        this.checkConnectability();
    }

    protected get password(): string {
        return this._password;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private localCacheService: LocalCacheService,
                private soilScoutManager: SoilScoutManagerService
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.verifyConfiguration();
            });
    }

    ngOnDestroy() {
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private checkConnectability() {
        if (this._password == null || this._password == '' || this._userName == null || this._userName == '') {
            this.canConnect.emit(false);
            return;
        }

        this.canConnect.emit(true);
    }

    private verifyConfiguration() {
        this.showError = false;
        this.isBusy = true;

        this.soilScoutManager.login(this.username, this.password)
            .pipe(
                take(1),
                finalize(() => {
                    this.isBusy = false;

                    // Notify Soil Scout Widgets. This will only matter if any of the Soil Scout widgets are already being displayed.
                    this.broadcastService.setupSoilScoutLoginChanged.next(null);
                })
            )
            .subscribe({
                next: (response: SoilScoutLoginResponse) => {
                    this.localCacheService.soilScoutAccessToken = response.access;
                    this.localCacheService.soilScoutRefreshToken = response.refresh;

                    this.configured.next(WizardIntegration.SoilScout);

                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.SoilScoutMoisture, isEnabled: true });
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.SoilScoutSalinity, isEnabled: true });
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.SoilScoutTemperature, isEnabled: true });
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.SoilScoutMap, isEnabled: true });
                },
                error: err => {
                    this.localCacheService.soilScoutRefreshToken = null;
                    this.localCacheService.soilScoutAccessToken = null;

                    this.showError = true;
                }
            })

    }
}
