import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import WeatherGraphType = ToroEnums.WeatherGraphType;

import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ActivityManagerService } from '../../../../../api/activity/activity-manager.service';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { LynxManagerService } from '../../../../../api/lynx/lynx-manager.service';
import { LynxWeatherStation } from '../../../../../api/lynx/models/lynx-weather-station.model';
import { LynxWidgetBase } from '../widget-lynx-pump-station/_lynx-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { ToroStartingTime } from '../../../../../api/_common/models/toro-starting-time.model';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherGraphNormalizationService } from '../../../../../common/services/weather-graph-normalization.service';
import { WeatherGraphsData } from '../../../../../api/weather/models/weather-graphs-data.model';
import { WeatherTimeSeries } from '../../../../../api/weather/models/weather-time-series.model';

@Component({
    selector: 'toro-widget-lynx-local-weather-graphs',
    templateUrl: './widget-lynx-local-weather-graphs.component.html',
    styleUrls: ['./widget-lynx-local-weather-graphs.component.less']
})
export class WidgetLynxLocalWeatherGraphsComponent extends LynxWidgetBase implements OnInit {
    private readonly baseTitle = 'WIDGET.LYNX_LOCAL_WEATHER_GRAPHS';

    readonly iconColor = 'goldenrod';
    title = this.baseTitle;

    private readonly ONE_ROW_GRAPH_HEIGHT = 120;
    private readonly TWO_ROW_GRAPH_HEIGHT = 141;
    private readonly NO_VALUE = '--';

    weatherStations: LynxWeatherStation[];
    weatherGraphsData: WeatherGraphsData[] = [];
    weatherGraphs: WeatherGraphType[] = [WeatherGraphType.Wind, WeatherGraphType.Temperature, WeatherGraphType.Precipitation, WeatherGraphType.SolarRadiation];
    graphHeight = this.ONE_ROW_GRAPH_HEIGHT;

    // Mini-mode vars
    windUnits = '-';
    dewPointUnits = '-';
    precipitationUnits = '-';
    wind: string[];
    dewPoint: string[];
    humidity: string[];
    precipitation: string[];
    isMetric = false;

    private dashUserPrefs: DashUserPreferences;
    private isWindGraphVisible = true;
    private isTemperatureGraphVisible = true;
    private isPrecipitationGraphVisible = true;
    private isRelativeHumidityGraphVisible = true;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected activityManager: ActivityManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private lynxManager: LynxManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherGraphNormalizationService: WeatherGraphNormalizationService
    ) {
        super(activityManager, analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isBusy = true;

        if (this.isGridsterInMobileMode) {
            this.broadcastService.userPreferencesChange
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.setMiniModeComponentData();
                });
        }

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.LynxLocalWeatherGraphsWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        this.graphHeight = (this.displayRows === 1 && !this.isGridsterInMobileMode) ? this.ONE_ROW_GRAPH_HEIGHT : this.TWO_ROW_GRAPH_HEIGHT;
    }

    protected getWidgetData() {
        const sources: Observable<any>[] = [
            this.lynxManager.getWeatherStations().pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                this.isWidgetInitialized = true;
                this.isBusy = false;
            }))
            .subscribe(([weatherStations, dashUserInfo]) => {
                this.clearIsUnableToFetchData();
                this.dashUserPrefs = dashUserInfo.preferences;

                // TODO: TEST
                // weatherStations.push(...weatherStations);

                // Add an index property to the collection elements to be used for converted units array lookup by UI.
                this.weatherStations = weatherStations?.map((s, index) => { return <LynxWeatherStation>{...s, index}});

                // Set max width (cols) of widget based on number of lynx weather stations.
                this.setWidgetConstraints(this.weatherStations.length);

                // Include count of stations in widget title.
                if (this.weatherStations.length > 1) { this.title = `${this.translateService.instant(this.baseTitle)} (${this.weatherStations.length})`; }

                /** Begin - Handle incomplete or 'bogus' lynx data ***************************************************************************************** */

                // Check to see if we have any stations and if any of those stations have status information.
                // NOTE: If any one of our weather stations has a single status entry, that is bogus data. We should receive 26. If we receive 'bogus' data
                // show the previous data, if it exists, or display 'no data available' on the widget.
                this.hasNoData = weatherStations == null || weatherStations.length < 1
                    || !(<LynxWeatherStation[]>weatherStations).some(s => s.statuses && s.statuses.length >= 1);
                if (this.hasNoData) {
                    this.hasNoData = !this.hasSuccessfullyRetrievedData;
                    return;
                }

                // From time to time, we get partial or no data returned from the api for Lynx Local Weather Graphs data. In these situations, if we
                // have previously fetched the data successfully, denote that so we can use that data until we receive new, valid data.
                this.hasSuccessfullyRetrievedData = true;

                /** End - Handle incomplete or 'bogus' lynx data ******************************************************************************************* */

                // Convert weather data to fit our existing weather graphs component.
                this.weatherGraphsData = this.coerceDataIntoGraphDataStructure(weatherStations);

                // Set visible weather graphs based on users prior selection.
                this.weatherGraphs = this.dashUserPrefs.lynxLocalWeatherGraphs;

                // TODO: Remove at some point. This is temporary to remove legacy Solar Radiation graphs.
                // After initial release, it was desired to not show solar radiation, but instead show Relative Humidity. Due to this, we will swap out
                // Solar Radiation (if it exists) for Relative Humidity.
                if (this.weatherGraphs.includes(WeatherGraphType.SolarRadiation)) {
                    this.weatherGraphs = this.weatherGraphs.filter(type => type !== WeatherGraphType.SolarRadiation);
                    if (!this.weatherGraphs.includes(WeatherGraphType.RelativeHumidity)) {
                        this.weatherGraphs.push(WeatherGraphType.RelativeHumidity);
                    }
                }

                this.weatherGraphsData.forEach(datum => this.weatherGraphNormalizationService.setYAxisExtents(datum));

                this.isWindGraphVisible = this.weatherGraphs.includes(WeatherGraphType.Wind);
                this.isTemperatureGraphVisible = this.weatherGraphs.includes(WeatherGraphType.Temperature);
                this.isPrecipitationGraphVisible = this.weatherGraphs.includes(WeatherGraphType.Precipitation);
                // this.isSolarRadiationGraphVisible = this.weatherGraphs.includes(WeatherGraphType.SolarRadiation);
                this.isRelativeHumidityGraphVisible = this.weatherGraphs.includes(WeatherGraphType.RelativeHumidity);
                this.setWidgetMenu();

                // Get the time stamp from the first stations statuses collection if we can.
                if (weatherStations != null && weatherStations.length > 0) {
                    const currentWeatherInfo = weatherStations[0].statuses.find(s => s.time === null);
                    if (currentWeatherInfo) { this.lastUpdateTimestamp = currentWeatherInfo.lastUpdated; }
                }

                if (this.isGridsterInMobileMode) {
                    this.setMiniModeComponentData();
                }
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }

            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: this.translateService.instant('STRINGS.WIND'),
                icon: this.getMenuIcon(this.isWindGraphVisible),
                command: this.toggleWindGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.TEMPERATURE'),
                icon: this.getMenuIcon(this.isTemperatureGraphVisible),
                command: this.toggleTemperatureGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.PRECIPITATION'),
                icon: this.getMenuIcon(this.isPrecipitationGraphVisible),
                command: this.togglePrecipitationGraph.bind(this)
            },
            {
                label: this.translateService.instant('STRINGS.RELATIVE_HUMIDITY'),
                icon: this.getMenuIcon(this.isRelativeHumidityGraphVisible),
                command: this.toggleRelativeHumidityGraph.bind(this)
            },

            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    getLastUpdateTimestamp(weatherStation: LynxWeatherStation) {
        const currentWeatherInfo = weatherStation.statuses.find(s => s.time === null);
        return currentWeatherInfo.lastUpdated;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private coerceDataIntoGraphDataStructure(weatherStations: LynxWeatherStation[]): WeatherGraphsData[] {
        if (weatherStations == null || weatherStations.length < 1) { return; }

        const weatherGraphsData: WeatherGraphsData[] = [];

        weatherStations.forEach(ws => {
            const mostCurrentHistoricRecord = ws.statuses.reduce((prevItem, curItem) => {
                return curItem.time > prevItem.time ? curItem : prevItem.time != null ? prevItem : curItem
            });
            if (!mostCurrentHistoricRecord) { return; }

            const dewPoint = this.createWeatherTimeSeries(WeatherGraphType.DewPoint, mostCurrentHistoricRecord.time);
            const wind = this.createWeatherTimeSeries(WeatherGraphType.Wind, mostCurrentHistoricRecord.time);
            const temperature = this.createWeatherTimeSeries(WeatherGraphType.Temperature, mostCurrentHistoricRecord.time);
            const precipitation = this.createWeatherTimeSeries(WeatherGraphType.Precipitation, mostCurrentHistoricRecord.time);
            const solarRadiation = this.createWeatherTimeSeries(WeatherGraphType.SolarRadiation, mostCurrentHistoricRecord.time);
            const relativeHumidity = this.createWeatherTimeSeries(WeatherGraphType.RelativeHumidity, mostCurrentHistoricRecord.time);

            ws.statuses.forEach(s => {
                if (s.time == null) { return; }

                dewPoint.values.push(s.dewPoint || 0);
                wind.values.push(s.windSpeed || 0);
                temperature.values.push(s.temperature || 0);
                precipitation.values.push(s.precipitation || 0);
                solarRadiation.values.push(s.solarRadiation || 0);
                relativeHumidity.values.push(s.relativeHumidity || 0);
            });

            const weatherGraphData = new WeatherGraphsData({
                lastUpdated: mostCurrentHistoricRecord.lastUpdated,
                dewPoint,
                wind,
                temperature,
                precipitation,
                solarRadiation,
                relativeHumidity
            });

            weatherGraphsData.push(weatherGraphData);
        });

        return weatherGraphsData;
    }

    private createWeatherTimeSeries(graphType: WeatherGraphType, startTime: Date) {
        const weatherTimeSeries: { id: WeatherGraphType, name?: string, startingTime?: ToroStartingTime, values?: number[] } = {
            id: graphType,
            startingTime: new ToroStartingTime({ time: startTime }),
            values: []
        };

        switch (graphType) {
            case WeatherGraphType.DewPoint:
                weatherTimeSeries.name = 'DewPoint';
                break;
            case WeatherGraphType.Wind:
                weatherTimeSeries.name = 'Wind';
                break;
            case WeatherGraphType.Temperature:
                weatherTimeSeries.name = 'Temperature';
                break;
            case WeatherGraphType.Precipitation:
                weatherTimeSeries.name = 'Precipitation';
                break;
            case WeatherGraphType.SolarRadiation:
                weatherTimeSeries.name = 'SolarRadiation';
                break;
            case WeatherGraphType.RelativeHumidity:
                weatherTimeSeries.name = 'RelativeHumidity';
        }

        return new WeatherTimeSeries(weatherTimeSeries);
    }

    private setWidgetConstraints(stationsCount: number) {
        let maxCols = 1;

        if (stationsCount >= 2 && stationsCount <= 5) {
            maxCols = stationsCount;
        } else if (stationsCount > 5) {
            maxCols = 5;
        }

        this.broadcastService.changeWidgetConstraint.next({ id: this.associatedWidget.id, maxRows: null, maxCols });
    }

    private toggleWindGraph() {
        this.isWindGraphVisible = !this.isWindGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Wind, this.isWindGraphVisible), 250);

        const analyticsEvent = this.isWindGraphVisible
            ? AnalyticsEvent.LynxLocalWeatherGraphsWidgetWindGraphDisplayed : AnalyticsEvent.LynxLocalWeatherGraphsWidgetWindGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private toggleTemperatureGraph() {
        this.isTemperatureGraphVisible = !this.isTemperatureGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Temperature, this.isTemperatureGraphVisible), 250);

        const analyticsEvent = this.isTemperatureGraphVisible
            ? AnalyticsEvent.LynxLocalWeatherGraphsWidgetTemperatureGraphDisplayed : AnalyticsEvent.LynxLocalWeatherGraphsWidgetTemperatureGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private togglePrecipitationGraph() {
        this.isPrecipitationGraphVisible = !this.isPrecipitationGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.Precipitation, this.isPrecipitationGraphVisible), 250);

        const analyticsEvent = this.isPrecipitationGraphVisible
            ? AnalyticsEvent.LynxLocalWeatherGraphsWidgetPrecipitationGraphDisplayed : AnalyticsEvent.LynxLocalWeatherGraphsWidgetPrecipitationGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private toggleRelativeHumidityGraph() {
        this.isRelativeHumidityGraphVisible = !this.isRelativeHumidityGraphVisible;
        this.setWidgetMenu();
        // Delayed for smoother transition of graph redraw and menu close.
        setTimeout(() => this.addRemoveGraph(WeatherGraphType.RelativeHumidity, this.isRelativeHumidityGraphVisible), 250);

        const analyticsEvent = this.isRelativeHumidityGraphVisible
            ? AnalyticsEvent.LynxLocalWeatherGraphsWidgetHumidityGraphDisplayed : AnalyticsEvent.LynxLocalWeatherGraphsWidgetHumidityGraphHidden;
        this.analyticsService.widgetEvent(analyticsEvent, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    private getMenuIcon(isVisible: boolean) {
        return isVisible ? 'pi pi-fw pi-check' : 'pi pi-fw';
    }

    private addRemoveGraph(graphType: WeatherGraphType, isVisible: boolean) {
        if (isVisible && !this.weatherGraphs.includes(graphType)) {
            this.weatherGraphs.push(graphType);
        } else if (!isVisible) {
            this.weatherGraphs = this.weatherGraphs.filter(g => g !== graphType);
        }

        this.dashUserPrefs.lynxLocalWeatherGraphs = this.weatherGraphs;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();
    }

    setMiniModeComponentData() {
        if (this.weatherStations == null || this.weatherStations.length < 1) { return; }

        this.isMetric = this.userFormatService.isMetric;
        this.windUnits = this.userFormatService.windSpeedUnits;
        this.dewPointUnits = this.userFormatService.dewPointUnits;
        this.precipitationUnits = this.userFormatService.rainfallUnits;

        this.wind = [];
        this.dewPoint = [];
        this.humidity = [];
        this.precipitation = [];

        this.weatherStations.forEach(ws => {
            // Instead of using what is presumed to be the 'current' weather, we will use the last historical record so that the graphed data
            // matches the mini mode single value data.
            // const currentWeatherInfo = ws.statuses.find(s => s.time == null);
            const currentWeatherInfo = ws.statuses[ws.statuses.length - 1];

            this.wind.push(currentWeatherInfo && currentWeatherInfo.windSpeed != null
                ? this.userFormatService.windSpeed(currentWeatherInfo.windSpeed).toString() : this.NO_VALUE);
            this.dewPoint.push(currentWeatherInfo && currentWeatherInfo.dewPoint != null
                ? this.userFormatService.dewPoint(currentWeatherInfo.dewPoint).toString() : this.NO_VALUE);
            this.precipitation.push(currentWeatherInfo && currentWeatherInfo.precipitation != null
                ? this.userFormatService.rainfall(currentWeatherInfo.precipitation).toString() : this.NO_VALUE);
            this.humidity.push(currentWeatherInfo && currentWeatherInfo.relativeHumidity != null
                ? this.userFormatService.humidity(currentWeatherInfo.relativeHumidity).toString() : this.NO_VALUE);
        })
    }
}
