<div class="tg-mm-gauge-container" [ngStyle]="{'width': width}">

    <div id="tg-mm-gauge-value" class="gauge-bar" [ngStyle]="{'width': valuePercent + '%'}"></div>
    <div id="gauge-range-container">
        <div class="gauge-bar" [ngStyle]="{'background-color': lowColor, 'width': lowWidth + '%'}"></div>
        <div class="gauge-bar" [ngStyle]="{'background-color': middleColor, 'width': middleWidth + '%'}"></div>
        <div class="gauge-bar" [ngStyle]="{'background-color': highColor, 'width': highWidth + '%'}"></div>
    </div>
    <div id="gauge-range-labels">
        <div class="gauge-range-label" [ngClass]="{'align-top': unitsAlignment === 'top', 'align-bottom': unitsAlignment === 'bottom'}">
            <span>{{this.rangeStart}}</span><span>{{rangeUnits}}</span>
        </div>
        <div class="gauge-range-label" [ngClass]="{'align-top': unitsAlignment === 'top', 'align-bottom': unitsAlignment === 'bottom'}">
            <span>{{this.rangeEnd}}</span><span>{{rangeUnits}}</span>
        </div>
    </div>

</div>
