import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SpecRangeCount } from '../../models/spec-range-count.model';

@Component({
    selector: 'toro-spec-range-selector-btn',
    templateUrl: './spec-range-selector-btn.component.html',
    styleUrls: ['./spec-range-selector-btn.component.less']
})
export class SpecRangeSelectorBtnComponent {
    @HostBinding('class') class = 'toro-spec-range-selector-btn';

    @Output() selectedIndexChange = new EventEmitter<number>();

    @Input() rangeCount: SpecRangeCount;
    @Input() index = -1;
    @Input() text: string;

    private _selectedIndex = 0;
    @Input() set selectedIndex(value: number) {
        if (value == null || value == this._selectedIndex) return;

        this.selectedIndexChange.emit(value);
        this._selectedIndex = value;
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClick() {
        this.selectedIndex = this.index;
    }
}
