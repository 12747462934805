import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import PwChartDataType = ToroEnums.PwChartDataType;
import PwChartType = ToroEnums.PwChartType;
import PwChartPeriod = ToroEnums.PwChartPeriod;

import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { PerryWeatherConfig } from '../../../../../api/perry-weather/models/perry-weather-config.model';
import { PerryWeatherManagerService } from '../../../../../api/perry-weather/perry-weather-manager.service';
import { PwChartSeries } from '../../../../../api/perry-weather/models/pw-chart-series.model';
import { PwForecast } from '../../../../../api/perry-weather/models/pw-forecast.model';
import { PwHistorical } from '../../../../../api/perry-weather/models/pw-historical.model';
import { PwHistoricalEt } from '../../../../../api/perry-weather/models/pw-historical-et.model';
import { PwLocation } from '../../../../../api/perry-weather/models/pw-location.model';
import { PwObservation } from '../../../../../api/perry-weather/models/pw-observation.model';
import { PwPoint } from '../../../../../api/perry-weather/models/pw-point.model';
import { PwWeatherDatum } from '../models/pw-weather-datum.model';
import { Site } from '../../../../../api/site/models/site.model';
import { SiteManagerService } from '../../../../../api/site/site-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';

@Component({
    selector: 'toro-widget-perry-weather',
    templateUrl: './widget-perry-weather.component.html',
    styleUrls: ['./widget-perry-weather.component.less']
})
export class WidgetPerryWeatherComponent extends ToroDashboardWidget implements OnInit {
    iconColor = 'black';
    title = 'WIDGET.PERRY_WEATHER';

    protected readonly ChartType = PwChartType;
    protected readonly ChartPeriod = PwChartPeriod;
    protected perryWeatherConfig: PerryWeatherConfig;

    private dashUserPrefs: DashUserPreferences;

    showCharts = true;
    location: PwLocation;
    observation: PwObservation;
    forecastD1_5: PwForecast;
    gridData: PwWeatherDatum[] = [];
    miniModeGridData: PwWeatherDatum[] = [];
    isLightningDelay = false;
    hideLightingInfo = true;
    lightningStatus = '--';
    lightningDistance = '--';
    isIntegrationKeyDialogDisplayed = false;
    newIntegrationKey: string;
    integrationKey: string;
    locationId: number;
    chartData: PwChartSeries[];
    showModalGraph = false;
    chartLegend: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private perryWeatherManager: PerryWeatherManagerService,
                private siteManager: SiteManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private widgetManager: WidgetManagerService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language
                this.setComponentData();
            });

        this.broadcastService.setupPerryWeatherIntegrationKeyChanged
            .pipe(untilDestroyed(this))
            .subscribe((integrationKey: string) => {
                this.newIntegrationKey = integrationKey;
                this.onSetIntegrationKey();
            });

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.

        this.language = this.dashUserManager.language;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.PerryWeatherWidgetName;
    }

    protected getWidgetData(isManualRefresh: boolean) {
        if (isManualRefresh) { this.isBusy = true; }

        if (this.isWidgetInitialized) {
            this.getPerryWeatherData(isManualRefresh);
            return;
        }

        const sources: Observable<any>[] = [
            this.dashUserManager.getDashUserInfo().pipe(take(1)),
            this.perryWeatherManager.getPerryWeatherConfig(this.associatedWidget).pipe(take(1))
        ];

        // Try to get Pogo API Key (stored in user preferences)
        forkJoin(sources)
            .pipe(
                switchMap(([dashUserInfo, perryWeatherConfig]) => {
                    this.perryWeatherConfig = perryWeatherConfig;
                    this.showCharts = perryWeatherConfig.showCharts;

                    this.dashUserPrefs = dashUserInfo.preferences;

                    if (this.dashUserPrefs.pwLocationId != null) { this.locationId = this.dashUserPrefs.pwLocationId; }
                    if (this.dashUserPrefs.pwIntegrationKey != null && this.dashUserPrefs.pwIntegrationKey != '') {
                        this.integrationKey = this.dashUserPrefs.pwIntegrationKey;
                    }

                    // TODO: FOR TEST
                    // this.locationId = 2945;
                    // this.integrationKey = null;

                    return this.locationId != null ? this.perryWeatherManager.getLocation(this.locationId).pipe(take(1)) : of(null);
                })
            )
            .subscribe({
                next: (location: PwLocation) => {
                    // Validate the location. If we've created it via the Setup Wizard prior to enabling the widget, we may have a Perry Weather
                    // Location without an Integration Key. Here, if we have a Perry Weather Location, a LocationId and an Integration Key, we check
                    // to ensure our Perry Weather Location has pwLink set to true (i.e., it has an integration key).
                    if (location != null && this.locationId != null && this.integrationKey != null) {
                        if (location?.pwLink == false) {
                            // If we have an invalid Location, delete the current one and create a new one.
                            this.perryWeatherManager.deleteLocation(this.locationId);
                            this.locationId = null;
                        }
                    }

                    // If we have a location Id, use it. If the location checked above was found to be invalid, we will have deleted the LocationId.
                    if (this.locationId != null) {
                        this.getPerryWeatherData(isManualRefresh);
                        return;
                    }

                    // No location, we need to create one
                    this.createLocation();
                },
                error: err => {
                    const error = err?.error?.Error_1[0];

                    if (error && error.includes('not found')) {
                        this.createLocation();
                        return;
                    }

                    // TODO: Determine if this is the right error info
                    this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);
                }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('CASE_SENSITIVE.SET_INTEGRATION_KEY'),
                icon: 'pi pi-fw pi-sign-in',
                command: this.showIntegrationKeyDialog.bind(this)
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onToggleGraphs() {
        this.showCharts = !this.showCharts;

        this.perryWeatherConfig.showCharts = this.showCharts;
        this.updatePerryWeatherConfig();
    }

    onPerryWeatherLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_PERRY_WEATHER' });
        setTimeout(() => window.open(environment.perryWeatherUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onSetIntegrationKey() {
        this.isIntegrationKeyDialogDisplayed = false;

        // Ignore changes to the account id when in demo mode.
        if (environment.isDemoMode) { return; }

        // If the Integration Key was not changed, don't do anything.
        if (this.integrationKey === this.newIntegrationKey) { return; }

        // NOTE: We now delete any old location in the call to createLocation (since duplicate lat/lng is now supported).
        //
        // // If the old location had an integration Key, delete it. Because of the way we define lat/lng for locations with integrationKeys,
        // // each users' instance of a location with the same integration key will be unique.
        // if (!this.isNullOrEmpty(this.integrationKey)) {
        //     // Fire and forget. If the location fails to delete, it's not the end of the world. At some point we
        //     // may want to have a background api process that checks for and deletes orphaned perry weather locations.
        //     this.perryWeatherManager.deleteLocation(this.locationId).subscribe()
        // }

        this.integrationKey = this.newIntegrationKey != '' ? this.newIntegrationKey : null;
        this.isBusy = true;

        // Store the new Integration Key in the user preferences.
        this.dashUserPrefs.pwIntegrationKey = this.newIntegrationKey;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

        this.createLocation()

        const eventDetails = `API Key: ${this.newIntegrationKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PwWidgetSavedApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onCancelSetIntegrationKey() {
        this.isIntegrationKeyDialogDisplayed = false
        this.analyticsService.widgetEvent(AnalyticsEvent.PwWidgetCancelApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onChartClick(chartDataType: PwChartDataType) {
        this.setChartLegend(chartDataType);
        this.showModalGraph = true;
    }

    onConfigChange(config: PerryWeatherConfig) {
        this.perryWeatherConfig = config;
        this.updatePerryWeatherConfig();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        if (this.observation == null || this.observation.data == null) {
            this.isUnableToFetchData = true;
            return;
        }

        // Lightning
        this.hideLightingInfo = !this.location.pwLink || this.observation.data?.lightning == null;
        if (!this.hideLightingInfo) {
            const lightningInfo = this.observation.data.lightning;
            this.isLightningDelay = lightningInfo.delay != 0;
            this.lightningStatus = this.translateService.instant(lightningInfo.delay == 0 ? 'STRINGS.CLEAR' : 'STRINGS.DELAY');
            this.lightningDistance = `0-${this.userFormatService.pwLightningRadii(lightningInfo.radii)} ${this.userFormatService.pwLightingRadiiUnits()}`
        }

        this.gridData = [
            new PwWeatherDatum(
                'STRINGS.TEMP',
                `${this.userFormatService.temperatureFromCelsius(this.observation.data.ambientTemperature.value).toString()} 
                ˚${this.userFormatService.temperatureUnits}`
            ),
            new PwWeatherDatum(
                'STRINGS.WIND',
                `${this.userFormatService.windSpeedfromMps(this.observation.data.wind.speed)} ${this.userFormatService.windSpeedUnits}`
            ),
            new PwWeatherDatum(
                'CASE_SENSITIVE.TODAYS_PRECIP',
                `${this.userFormatService.precipitationFromCm(this.observation.data.precipitation?.fromMidnight || 0, true)} 
                ${this.userFormatService.precipitationFromCmUnits}`
            ),
            new PwWeatherDatum(
                'STRINGS.TEMP',
                `${this.userFormatService.temperatureFromCelsius(this.forecastD1_5.dailyForecast[0].ambientTemperatureMax.value).toString()}˚/
                       ${this.userFormatService.temperatureFromCelsius(this.forecastD1_5.dailyForecast[0].ambientTemperatureMin.value).toString()}˚${this.userFormatService.temperatureUnits}`,
                'arrow-up',
                'arrow-down',
                'pw-header-icons'
            ),
            new PwWeatherDatum(
                'CASE_SENSITIVE.SEVEN_DAY_PRECIP',
                `${this.userFormatService.precipitationFromCm(this.observation.data.precipitation?.sevenDays || 0, true)} 
                ${this.userFormatService.precipitationFromCmUnits}`
            ),
            new PwWeatherDatum(
                'STRINGS.HUMIDITY',
                `${this.observation.data.humidity.value} %`,
                null, null,
                this.language != 'nl-nl' ? null : 'pw-dutch-humidity'
            )
        ]

        this.miniModeGridData = [
            // Temp
            this.gridData[0],
            // Temp H/L
            new PwWeatherDatum(
                'STRINGS.TEMP',
                `${this.userFormatService.temperatureFromCelsius(this.forecastD1_5.dailyForecast[0].ambientTemperatureMax.value).toString()}˚
                       ${this.userFormatService.temperatureFromCelsius(this.forecastD1_5.dailyForecast[0].ambientTemperatureMin.value).toString()}˚`,
                'arrow-up',
                'arrow-down',
                'pw-header-icons'
            ),
            // Wind
            this.gridData[1],
            // 7-Day Precip
            this.gridData[4]
        ]
    }

    private createLocation() {
        // Delete the old location if we have one.
        if (this.locationId != null) {
            this.perryWeatherManager.deleteLocation(this.locationId).subscribe()
        }

        // Get the IntelliDash site for the existing user. We do this to get the lat/lng for the related Perry Weather Course.
        // NOTE: If we have an Integration Key, the lat/lng are meaningless.
        this.siteManager.getSite()
            .pipe(
                take(1),
                switchMap((site: Site) => {
                    const location = new PwLocation({
                        name: site.name,
                        point: new PwPoint({
                            lat: this.integrationKey != null ? `0.${new Date().getTime()}` : site.latitude,
                            lng: this.integrationKey != null ? `0.${new Date().getTime()}` : site.longitude,
                        }),
                        integrationKey: this.integrationKey != null ? this.integrationKey : null
                    });

                    return this.perryWeatherManager.createLocation(location);
                })
            )
            .subscribe({
                next: (locationId: number) => {
                    this.locationId = locationId;

                    // Store the new Location Id (and Integration Key as appropriate) in the user preferences.
                    this.dashUserPrefs.pwLocationId = locationId;
                    if (this.integrationKey != null) this.dashUserPrefs.pwIntegrationKey = this.integrationKey;
                    this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

                    this.getPerryWeatherData();
                },
                error: err => {
                    // If the locations, defined by its lat/lng, that we tried to create, conflicts with another, let's see if we can use it or if we need
                    // to create a new location.
                    if (err?.error?.Error_1[0].includes('conflicted')) {
                        // Parse conflicting location.
                        const eString = err.error.Error_1[0];
                        const cIndex = eString.indexOf(':');
                        this.locationId = eString.substring(cIndex + 1).trim();

                        // Store the new Location Id in the user preferences.
                        this.dashUserPrefs.pwLocationId = this.locationId;
                        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

                        this.getPerryWeatherData();
                        return;
                    }

                    // TODO: Present error to user
                    console.log();
                }
            })
    }

    private getPerryWeatherData(isManualRefresh = false) {
        const yesterdayUtc = moment().startOf('day').subtract(1, 'days').utc();
        const historicStartDate = moment().startOf('day').subtract(7, 'days').utc();
        const forecastStartDate = moment().startOf('day').utc();

        const sources: Observable<any>[] = [
            this.perryWeatherManager.getLocation(this.locationId).pipe(take(1)),
            this.perryWeatherManager.getObservation(this.locationId).pipe(take(1)),
            this.perryWeatherManager.getHistorical(this.locationId, historicStartDate.toDate(), 7).pipe(take(1)),
            this.perryWeatherManager.getHistoricalEt(this.locationId, historicStartDate.toDate(), yesterdayUtc.toDate()).pipe(take(1)),
            this.perryWeatherManager.getForecast(this.locationId, forecastStartDate.toDate(), 5).pipe(take(1)),
            this.perryWeatherManager.getForecast(this.locationId, forecastStartDate.add(5, 'days').toDate(), 2).pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe({
                next: ([location, observation, historical, historicalEt, forecastD1_5, forecastD6_7]) => {
                    this.clearIsUnableToFetchData();

                    this.lastUpdateTimestamp = new Date();
                    this.location = location;
                    this.observation = observation;
                    this.forecastD1_5 = forecastD1_5;

                    this.prepareChartData(historical, historicalEt, forecastD1_5, forecastD6_7);
                    this.setComponentData();

                    this.isWidgetInitialized = true;
                },
                error: err => {
                    const error = err?.error?.Error_1[0];

                    if (error && error.toLowerCase().includes('integration key not found')) {
                        this.setIsUnableToFetchData('Invalid Perry Weather Integration Key.');
                        return;
                    } else if (error && error.includes('not found')) {
                        this.createLocation();
                        return;
                    }

                    this.isUnableToFetchData = true;
                    if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
                }
            });
    }

    private prepareChartData(historical: PwHistorical, historicalEt: PwHistoricalEt, forecastD1_5: PwForecast, forecastD6_7: PwForecast) {
        this.chartData = [];

        // Prepare Precipitation Forecast
        const forecastData = forecastD1_5.dailyForecast.concat(forecastD6_7.dailyForecast);
        const precipForecast = new PwChartSeries(PwChartDataType.PrecipForecast, forecastData[0].date);
        forecastData.forEach(f => precipForecast.values.push((f?.dayTime?.precipitation?.accumulation || 0) + (f?.nightTime?.precipitation?.accumulation || 0)));
        this.chartData.push(precipForecast);

        // Prepare Precipitation Historical
        if (historical?.dailyHistorical && historical.dailyHistorical.length > 0) {
            const precipHistorical = new PwChartSeries(PwChartDataType.PrecipHistorical, historical.dailyHistorical[0].date);
            historical.dailyHistorical.forEach(d => precipHistorical.values.push(d.rainTotal));
            this.chartData.push(precipHistorical);
        }

        // Prepare Et Historical
        if (historicalEt?.data && historicalEt.data.length > 0) {
            const etHistorical = new PwChartSeries(PwChartDataType.EtHistorical, historicalEt.data[0].date);
            historicalEt.data.forEach(d => etHistorical.values.push(d.value));
            this.chartData.push(etHistorical);
        }
    }

    private showIntegrationKeyDialog() {
        this.newIntegrationKey = this.integrationKey;
        this.isIntegrationKeyDialogDisplayed = true;

        const eventDetails = `API Key: ${this.integrationKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.PwWidgetShowApkKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    // private isNullOrEmpty(value: string) {
    //     return value == null || value == '';
    // }

    private setChartLegend(chartDataType: PwChartDataType) {
        switch (chartDataType) {
            case PwChartDataType.PrecipForecast:
                this.chartLegend = 'CASE_SENSITIVE.PRECIPITATION_FORECAST'
                break;
            case PwChartDataType.PrecipHistorical:
                this.chartLegend = 'CASE_SENSITIVE.HISTORICAL_PRECIPITATION'
                break;
            case PwChartDataType.EtHistorical:
                this.chartLegend = 'CASE_SENSITIVE.HISTORICAL_ET'
                break;
        }
    }

    private updatePerryWeatherConfig() {
        // Fire and forget. No biggie if the update fails.
        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.perryWeatherConfig).pipe(take(1)).subscribe();
    }
}
