import { GoogleMapLocation } from '../../_common/models/google-map-location.model';
import { IWidgetDataSettings } from '../../_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import WidgetType = ToroEnums.WidgetType;

export class WeatherRadarConfig implements IWidgetDataSettings{

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    origin: GoogleMapLocation;
    center: GoogleMapLocation;
    zoom = 8;
    mapTypeId = 'satellite';

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;

}
