import { PwDailyForecast } from './pw-daily-forecast.model';
import { PwHourlyForecast } from './pw-hourly-forecast.model';

export class PwForecast {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.dailyForecast) this.dailyForecast = json.dailyForecast.map(f => new PwDailyForecast(f));
            if (json.hourlyForecast) this.hourlyForecast = json.hourlyForecast.map(f => new PwHourlyForecast(f));
        }
    }

    dailyForecast: PwDailyForecast[] = [];
    hourlyForecast: PwHourlyForecast[] = [];
}
