
export class TaskTrackerDailySafetyCount {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.date) { this.date = new Date(json.date); }
        }
    }

    date: Date;
    issues: number;
}
