export class TaskTrackerDailyMeasurementInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    name: string;
    departmentName: string;
    units: string;
    currentAvg: number;
    previousAvg: number;
}
