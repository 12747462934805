<div class="wiz-integration-content">
    <div id="left-content" class="wiz-integration-group">
        <div class="step-header">
            {{'SPECIAL_MSG.CONNECT_TASK_TRACKER_TO_APP' | translate}}
        </div>
        <toro-step-group *ngFor="let step of stepsCount | fill; let i = index;" [ngSwitch]="i" [stepNumber]="i + 1">
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1"></ng-container>
            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step2"></ng-container>
            <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="step3"></ng-container>
            <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="step4"></ng-container>
        </toro-step-group>
        <toro-step-action>
            <div>
                <img src="assets/images/wizard/taskTracker.png" alt="taskTracker image">
            </div>
            <div class="wic-task-tracker-info">
                <div>{{'CASE_SENSITIVE.ENTER_API_KEY' | translate}}</div>
                <p-inputMask
                        [(ngModel)]="apiKey"
                        class="task-tracker-api-key"
                        mask="********-****-****-****-************"
                        characterPattern="[a-fA-F0-9]"
                        [size]="40"
                        [placeholder]="'CASE_SENSITIVE.API_KEY' | translate"
                        (paste)="onPasteApiKey($event)"
                        (cut)="onCutApiKey($event)"
                ></p-inputMask>
                <div *ngIf="showError" id="wic-task-tracker-error">{{'SPECIAL_MSG.TASK_TRACKER_DATA_COULD_NOT_BE_RETRIEVED' | translate}}</div>
                <toro-waiting-indicator class="widget-non-draggable" *ngIf="isBusy" [isVisible]="isBusy" text="{{spinnerText | translate}}..." backgroundColor="rgba(255,255,255, .9)"></toro-waiting-indicator>
            </div>
        </toro-step-action>
    </div>
    <div id="right-content" class="wiz-integration-group">
        <img src="assets/images/wizard/taskTracker-wiz-setup.png" alt="">
    </div>
</div>

<ng-template #step1>
    <div>
        {{'STRINGS.SIGN_INTO' | translate}} <a href="javascript:void(0);" (click)="onNavigateToSite()">taskTracker</a>.
    </div>
</ng-template>

<ng-template #step2>
    {{'SPECIAL_MSG.WIZARD_TASK_TRACKER_SELECT_PROGRAM_SETUP' | translate}}.
</ng-template>

<ng-template #step3>
    {{'SPECIAL_MSG.WIZARD_TASK_TRACKER_SELECT_GENERAL_TAB' | translate}}.
</ng-template>

<ng-template #step4>
    {{'SPECIAL_MSG.WIZARD_TASK_TRACKER_COPY_API_KEY' | translate}}.
</ng-template>
