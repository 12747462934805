import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashboardWidgetInfo } from '../../../api/widgets/models/dashboard-widget-info.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

export class ToroGridsterWidget implements GridsterItem {
    private static nextId = 1;

    constructor(type: ToroEnums.WidgetType, widgetInfo: DashboardWidgetInfo) {
        this.x = widgetInfo ? widgetInfo.x : 0;
        this.y = widgetInfo ? widgetInfo.y : 0;
        this.cols = widgetInfo ? widgetInfo.width : null;
        this.rows = widgetInfo ? widgetInfo.height : null;
        this.type = type;
        this.isSquare = false;
        this.uniqueId = ToroGridsterWidget.nextId++;

        if (widgetInfo) this.id = widgetInfo.id;
        if (widgetInfo && widgetInfo.config) this.config = widgetInfo.config;
    }

    id: number;
    x: number;
    y: number;
    rows: number;
    cols: number;
    initCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    dragEnabled?: boolean;
    resizeEnabled?: boolean;
    compactEnabled?: boolean;
    maxItemRows?: number;
    minItemRows?: number;
    maxItemCols?: number;
    minItemCols?: number;
    minItemArea?: number;
    maxItemArea?: number;

    [propName: string]: any;

    isSquare?: boolean;
    uniqueId: number;
    type: ToroEnums.WidgetType;
    config: any;

    resizeCallback?: (item: ToroGridsterWidget) => void;
    resizeStartCallback?: (item: ToroGridsterWidget) => void;
    resizeStopCallback?: (item: ToroGridsterWidget) => void;
}
