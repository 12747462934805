
<div id="ss-thresholds-container">
    <div id="ss-thresh-scale">
        <div class="ss-scale-range" [ngStyle]="{'background-color': boundaryValues[0].color}"></div>
        <div class="ss-scale-range" [ngStyle]="{'background-color': boundaryValues[1].color}"></div>
        <div class="ss-scale-range" [ngStyle]="{'background-color': boundaryValues[2].color}"></div>
        <div class="ss-scale-range" [ngStyle]="{'background-color': rangeCount > 4 ? boundaryValues[3].color : 'unset'}"></div>
    </div>

    <div id="ss-thresh-settings">
        <div class="ss-thresh-setting" *ngFor="let r of rangeCount | fill; let i = index;" [class]="getThresholdClass(i)">
            <fa-icon icon="caret-left" [ngStyle]="{'color': boundaryValues[i].color}"></fa-icon>
            <p-inputNumber
                    [(ngModel)]="boundaryValues[i].value"
                    [locale]="locale"
                    [suffix]="suffix"
                    [min]="rangeMin"
                    [max]="rangeMax"
                    [showButtons]="true"
                    (onBlur)="onRangeInputBlur(i)"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    buttonLayout="horizontal"
            ></p-inputNumber>
        </div>
    </div>

</div>
