<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [lastUpdated]="lastUpdated"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [analyticsWidgetName]="analyticsWidgetName"
        [(alertText)]="alertText"
        [(isUnableToFetchData)]="isUnableToFetchData"
>

    <div id="toro-turf-cloud-course-trakk-widget-content" class="toro-component-shield-host">
        <toro-widget-link-button href="https://app.turfcloud.com" imageSrc="../../../../../assets/images/turfCloud-cloud-small.png" (click)="onTurfCloudLinkClick()">
        </toro-widget-link-button>

        <div *ngIf="displayRows === 1" class="toro-widget-content-pane toro-size-one" [ngClass]="{'fade-on-resize': isResizing}">
            <ng-container [ngTemplateOutlet]="todayScheduledEmployees"></ng-container>
            <ng-container [ngTemplateOutlet]="todayLaborInvestment"></ng-container>
        </div>

        <div *ngIf="displayRows === 2" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
            <ng-container [ngTemplateOutlet]="todayScheduledEmployees"></ng-container>
            <ng-container [ngTemplateOutlet]="todayLaborInvestment"></ng-container>

            <div class="toro-flex-child course-trakk-datum-container">
                <toro-course-trakk-datum [value]="investedInMonth" [label]="'STRINGS.INVESTED_IN_LABOR_IN_MONTH' | translate: {month: currentMonth}"></toro-course-trakk-datum>
            </div>

            <div class="toro-flex-child course-trakk-datum-container">
                <toro-course-trakk-datum [value]="investedInYear" [label]="'STRINGS.INVESTED_IN_LABOR_IN_YEAR' | translate: {year: currentYear}"></toro-course-trakk-datum>
            </div>
        </div>

        <toro-component-shield></toro-component-shield>
    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #todayScheduledEmployees>
    <div class="toro-flex-child course-trakk-datum-container">
        <toro-course-trakk-datum [value]="courseTrakkData?.employees" [label]="'STRINGS.EMPLOYEES_SCHEDULED_TODAY' | translate"></toro-course-trakk-datum>
    </div>
</ng-template>

<ng-template #todayLaborInvestment>
    <div class="toro-flex-child course-trakk-datum-container">
        <toro-course-trakk-datum [value]="investedInToday" [label]="'STRINGS.INVESTED_IN_LABOR_TODAY' | translate"></toro-course-trakk-datum>
    </div>
</ng-template>
