import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@Component({
    selector: 'toro-station-statuses-dlg',
    templateUrl: './station-statuses-dlg.component.html',
    styleUrls: ['./station-statuses-dlg.component.less']
})
export class StationStatusesDlgComponent {
    @Output() closeDialog = new EventEmitter();
    @Output() dataRefresh = new EventEmitter();

    @Input() set lastUpdated(value: Date) {
        this.currentAsOfTimestamp = `${this.userFormatService.toUserDateString(value)} ${this.userFormatService.toUserTimeString(value)}`;
    }

    currentAsOfTimestamp: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private userFormatService: UserFormatService) {
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.closeDialog.emit();
    }

    onRefresh() {
        // this.isManualRefresh = true;
        this.dataRefresh.emit();
    }
}
