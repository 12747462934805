import { AnalyticsService } from './analytics.service';
import { DashMessageItem } from './models/dash-message-item.model';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToroAnalyticsEnums } from '../enumerations/analytics.enums';
import { ToroEnums } from '../enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import MessageSeverity = ToroEnums.MessageSeverity;

@Injectable({
    providedIn: 'root'
})
export class DashMessageService {

    constructor(private analyticsService: AnalyticsService,
                private messageService: MessageService,
                private translateService: TranslateService,
    ) { }

    showMessage(summary: string, detail: string = '', severity: MessageSeverity = MessageSeverity.Warn) {
        this.messageService.add(new DashMessageItem(this.translateService.instant(summary), this.translateService.instant(detail), severity));
    }

    showGenericSaveErrorMessage() {
        this.showMessage('ERR_MSG.GENERIC_SAVE_ERROR_SUMMARY', 'ERR_MSG.GENERIC_SAVE_ERROR_DETAIL');
    }

    showGenericFetchErrorMessage() {
        this.showMessage('ERR_MSG.GENERIC_RETRIEVE_ERROR_SUMMARY', 'ERR_MSG.GENERIC_RETRIEVE_ERROR_DETAIL');
    }

    showGenericRequestErrorMessage() {
        this.showMessage('ERR_MSG.GENERIC_REQUEST_ERROR_SUMMARY', 'ERR_MSG.GENERIC_REQUEST_ERROR_DETAIL');
    }

    showMyTurfAuthenticationErrorMessage() {
        this.showMessage('ERR_MSG.MY_TURF_AUTHENTICATION_ERROR_TITLE', 'ERR_MSG.MY_TURF_AUTHENTICATION_ERROR_DETAIL');
    }

    showWidgetDataFetchErrorMessage(widgetName: string) {
        const msg = `${this.translateService.instant(widgetName)}: ${this.translateService.instant('ERR_MSG.GENERIC_RETRIEVE_ERROR_DETAIL')}`;
        this.showMessage('ERR_MSG.GENERIC_RETRIEVE_ERROR_SUMMARY', msg);

        this.analyticsService.widgetEvent(AnalyticsEvent.DataFetchError, AnalyticsCategory.Error, `${this.translateService.instant(widgetName)}`);
    }

    showWidgetDataFetchDetailedErrorMessage(widgetName: string, errorMessage: string) {
        const msg = `${this.translateService.instant(widgetName)}: ${this.translateService.instant(errorMessage)}`;
        this.showMessage('ERR_MSG.GENERIC_RETRIEVE_ERROR_SUMMARY', msg);

        const eventDetails = `Error: ${errorMessage}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.DataFetchError, AnalyticsCategory.Error, `${this.translateService.instant(widgetName)}`, eventDetails);
    }
}
