import * as Highcharts from 'highcharts';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { filter } from 'rxjs/operators';
import { GraphAxisExtentModel } from '../../../../../common/services/models/graph-axis-extent.model';
import { Site } from '../../../../../api/site/models/site.model';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherGraphNormalizationService } from '../../../../../common/services/weather-graph-normalization.service';
import { WeatherGraphsData } from '../../../../../api/weather/models/weather-graphs-data.model';

import WeatherGraphType = ToroEnums.WeatherGraphType;
import WeatherGraphChartColor = ToroEnums.WeatherGraphChartColor;

@UntilDestroy()
@Component({
    selector: 'toro-weather-graph',
    templateUrl: './weather-graph.component.html',
    styleUrls: ['./weather-graph.component.less']
})
export class WeatherGraphComponent implements OnInit {
    @Input() height = 134;
    @Input() graphType: WeatherGraphType;
    @Input() data: WeatherGraphsData;
    @Input() site: Site;
    @Input() language: string;

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    series1Name: string;
    series2Name: string;
    series1Units: string;
    series2Units: string;
    series1Color: string;
    series2Color: string;
    showModalGraph = false;
    modalTitle: string;
    yAxisExtent: GraphAxisExtentModel;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService,
                private translateService: TranslateService,
                private weatherGraphNormalizationService: WeatherGraphNormalizationService,
    ) { }

    ngOnInit(): void {
        this.setComponentData();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });

        this.weatherGraphNormalizationService.yAxisExtentChange
            .pipe(
                untilDestroyed(this),
                filter((change: GraphAxisExtentModel) => change.graphType === this.graphType)
            )
            .subscribe((change: GraphAxisExtentModel) => this.yAxisExtent = change);

        this.yAxisExtent = this.weatherGraphNormalizationService.getYAxisExtent(this.graphType);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onChartClick() {
        if (this.showModalGraph) { return; }
        this.showModalGraph = true;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        this.setSeriesNames();

        this.modalTitle = this.translateService.instant('WIDGET.WEATHER_GRAPHS');
        switch (this.graphType) {
            case WeatherGraphType.Wind:
                this.modalTitle += `: ${this.translateService.instant('STRINGS.WIND')}`;
                break;
            case WeatherGraphType.Temperature:
                this.modalTitle += `: ${this.translateService.instant('STRINGS.DEW_POINT')} / ${this.translateService.instant('STRINGS.TEMPERATURE')}`;
                break;
            case WeatherGraphType.Precipitation:
                this.modalTitle += `: ${this.translateService.instant('STRINGS.PRECIPITATION')}`;
                break;
            case WeatherGraphType.SolarRadiation:
                this.modalTitle += `: ${this.translateService.instant('STRINGS.SOLAR_RADIATION')}`;
                break;
            case WeatherGraphType.RelativeHumidity:
                this.modalTitle += `: ${this.translateService.instant('STRINGS.RELATIVE_HUMIDITY')}`;
                break;
        }
    }

    private setSeriesNames() {
        switch (this.graphType) {
            case WeatherGraphType.Wind:
                this.series1Name = 'STRINGS.WIND';
                this.series1Units = this.userFormatService.windSpeedUnits;
                this.series1Color = WeatherGraphChartColor.Wind;
                break;
            case WeatherGraphType.Temperature:
                this.series1Name = 'STRINGS.DEW_POINT';
                this.series1Units = `˚${this.userFormatService.temperatureUnits}`;
                this.series1Color = WeatherGraphChartColor.DewPoint;
                this.series2Name = 'STRINGS.TEMPERATURE';
                this.series2Units = `˚${this.userFormatService.temperatureUnits}`;
                this.series2Color = WeatherGraphChartColor.Temperature;
                break;
            case WeatherGraphType.Precipitation:
                this.series1Name = 'STRINGS.PRECIPITATION';
                this.series1Units = this.userFormatService.precipitationUnits;
                this.series1Color = WeatherGraphChartColor.Precipitation;
                break;
            case WeatherGraphType.SolarRadiation:
                this.series1Name = 'STRINGS.SOLAR_RADIATION';
                this.series1Units = this.userFormatService.solarRadiationUnits;
                this.series1Color = WeatherGraphChartColor.SolarRadiation;
                break;
            case WeatherGraphType.RelativeHumidity:
                this.series1Name = 'STRINGS.RELATIVE_HUMIDITY';
                this.series1Units = '%';
                this.series1Color = WeatherGraphChartColor.RelativeHumidity;
        }
    }

}
