import * as moment from 'moment';

export class Note {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.date) this.date = moment.utc(json.date).toDate();
        }
    }

    userId: string;
    siteId: number;
    text: string;
    date: Date;
}
