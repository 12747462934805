import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import WidgetType = ToroEnums.WidgetType;
import { WizardSettings } from './wizard-settings.model';

export class IntegrationStep {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    widgetType: WidgetType
    headerImage: string;
    headerImageHeightInPixels = 50;
    description: string;
    linkText: string;
    linkUrl: string;
    isConfigured = false;
    settings: WizardSettings;
}
