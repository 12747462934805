export class WeatherIcon {

    constructor(icon: string, color = 'gray') {
        this.icon = icon;
        this.color = color;
    }

    icon: string;
    color: string;

}
