export class EtInfoForDisplayIcon {

    constructor(icon: string, color = '') {
        this.icon = icon;
        this.color = color;
    }

    icon: string;
    color: string;
}
