import { AlertParameter } from './alert-parameter.model';
import { AlertUserSettings } from './alert-user-settings.model';

export class AlertSettingsAndParameters {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.userSettings) { this.userSettings = new AlertUserSettings(json.userSettings); }
            if (json.alertParameters) { this.alertParameters = json.alertParameters.map(p => new AlertParameter(p)); }
        }
    }

    userSettings: AlertUserSettings;
    alertParameters: AlertParameter[] = [];
}
