import { SoilScoutOfflineNotification } from './soil-scout-offline-notification.model';

export class SoilScoutSite {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.offline_notifications) this.offline_notifications = new SoilScoutOfflineNotification(this.offline_notifications);
        }
    }

    id: number;
    name: string;
    latitude: string;
    longitude: string;
    time_zone: string;
    device_count = 0;
    group_count = 0
    unread_notification_count = 0;
    offline_notifications: SoilScoutOfflineNotification;
}
