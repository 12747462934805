import * as moment from 'moment';
import { LynxFlowConfig } from './lynx-flow-config.model';
import { LynxPressureConfig } from './lynx-pressure-config.model';

export class LynxPumpStation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.flowConfig) this.flowConfig = new LynxFlowConfig(json.flowConfig);
            if (json.pressureConfig) this.pressureConfig = new LynxPressureConfig(json.pressureConfig);
        }
    }

    capacity: number;
    flow: number;
    lastUpdated: Date;
    lynxId: number;
    name: string;
    pressure: number;
    siteId: number;
    flowConfig: LynxFlowConfig = null;
    pressureConfig: LynxPressureConfig = null;

}
