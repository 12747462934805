import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToroEnums } from '../../common/enumerations/toro.enums';

import TopologyCategory = ToroEnums.TopologyCategory;

@Injectable({
    providedIn: 'root'
})
export class ActivityApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    requestTopologyUpdate(categories: TopologyCategory[]): Observable<void> {
        return this.apiService.apiRequest<any>(ActivityApiService.requestTopologyUpdateUrl, HttpMethod.Post, categories);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}activity`; }

    private static get requestTopologyUpdateUrl(): string { return `${ActivityApiService.baseUrl}`; }

}
