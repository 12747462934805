import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DatePipe } from '@angular/common';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { SelectItem } from 'primeng/api';
import { TaskTrackerDailySafetyCount } from '../../../../../api/task-tracker/models/task-tracker-daily-safety-count.model';
import { TaskTrackerManagerService } from '../../../../../api/task-tracker/task-tracker-manager.service';
import { TaskTrackerSafety } from '../../../../../api/task-tracker/models/task-tracker-safety.model';
import { TaskTrackerWidgetBase } from '../_task-tracker-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { TranslateService } from '@ngx-translate/core';


@UntilDestroy()
@Component({
    selector: 'toro-widget-task-tracker-safety',
    templateUrl: './widget-task-tracker-safety.component.html',
    styleUrls: ['./widget-task-tracker-safety.component.less']
})
export class WidgetTaskTrackerSafetyComponent extends TaskTrackerWidgetBase implements OnInit {
    iconColor = '#065f53';
    title = 'WIDGET.TASK_TRACKER_SAFETY_AND_TRAINING';

    departmentsList: SelectItem[];
    selectedDepartmentIds: number[] = [];
    safeties: TaskTrackerSafety[] = [];
    filteredSafeties: TaskTrackerSafety[] = [];
    summarySafetyCounts: TaskTrackerDailySafetyCount[] = [];
    todayIssueCount = 0;
    issuesIcon: any = 'check-circle';
    issuesIconColor = '#4fbd60';
    todayDateString = '--';
    historyDateString = '--';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(
        protected analyticsService: AnalyticsService,
        protected broadcastService: BroadcastService,
        protected dashMessageService: DashMessageService,
        protected dashUserManager: DashUserManagerService,
        protected deviceManager: DeviceManagerService,
        protected taskTrackerManager: TaskTrackerManagerService,
        protected translateService: TranslateService,
        private datePipe: DatePipe,
    ) {
        super(analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, taskTrackerManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.taskTrackerManager.companyIdChange
            .pipe(untilDestroyed(this))
            .subscribe((taskTrackerApiKey: string) => {
                this.taskTrackerApiKey = taskTrackerApiKey;
                this.dashUserPrefs.taskTrackerSafetiesSelectedDepartments = null;
                this.departmentsList = null;
                this.selectedDepartmentIds = null;

                this.isBusy = true;
                this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_API_KEY')}...`;
                this.getWidgetData(true);
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TaskTrackerSafetyAndTraining;
    }

    protected getWidgetDataExtended(isManualRefresh = false) {
        const sources: Observable<any>[] = [
            this.taskTrackerManager.getTaskTrackerConfig(this.associatedWidget),
            this.taskTrackerManager.getSafeties(this.companyId, isManualRefresh).pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.resetBusy()))
            .subscribe(([taskTrackerConfig, safeties]) => {
                this.clearIsUnableToFetchData();

                // Override default refresh interval
                this.updateIntervalInMinutes = taskTrackerConfig.dataRefreshIntervalMinutes;
                this.staleDataThresholdInMinutes = taskTrackerConfig.staleDataThresholdMinutes;

                this.safeties = safeties;
                this.setDepartmentsList();
                if (!this.selectedDepartmentIds || this.selectedDepartmentIds.length < 1) { this.setInitialSelectedDepartments(); }
                this.setComponentData();
                this.lastUpdateTimestamp = new Date();
                this.resetBusy();
            }, () => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTaskTrackerLinkClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerGoToTrackingSite, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        super.onTaskTrackerLinkClick();
    }

    onDepartmentSelectionChange() {
        this.dashUserPrefs.taskTrackerSafetiesSelectedDepartments = this.selectedDepartmentIds;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs)
            .subscribe(() => {
                this.setComponentData();
            }, () => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected setComponentData() {
        this.todayIssueCount = 0;
        if (this.safeties && this.safeties.length > 0) {
            try {
                this.todayDateString = this.datePipe.transform(this.safeties[0].date, 'longDate', '',  this.translateService.currentLang);
            } catch (err) {
                console.log(err);
            }

            const startMonth = this.datePipe.transform(this.safeties[0].date, 'MMM');
            const endMonth = this.datePipe.transform(this.safeties[this.safeties.length - 1].date, 'MMM');
            const startYear = this.datePipe.transform(this.safeties[0].date, 'yyyy');
            const endYear = this.datePipe.transform(this.safeties[this.safeties.length - 1].date, 'yyyy');

            if (startMonth === endMonth) {
                this.historyDateString = `${startMonth} ${startYear}`;
            } else {
                this.historyDateString = startYear === endYear ? `${startMonth} - ${endMonth} ${startYear}` : `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
            }
        }

        this.filteredSafeties = this.safeties.filter(ms => this.selectedDepartmentIds?.includes(ms.departmentId));
        this.filteredSafeties.forEach(s => {
            this.todayIssueCount += s.todayIssuesCount;

            s.weeklyCount.forEach(wc => {
                const currentDayCounts = this.summarySafetyCounts.find(c => moment(c.date).diff(moment(wc.date), 'days') === 0);
                if (currentDayCounts) {
                    currentDayCounts.issues += wc.issues;
                } else {
                    this.summarySafetyCounts.push(Object.assign(new TaskTrackerDailySafetyCount(), wc));
                }
            });
        });

        this.issuesIcon = this.todayIssueCount > 0 ? 'exclamation-triangle' : 'check-circle';
        this.issuesIconColor = this.todayIssueCount > 0 ? '#f0ad4e' : '#4fbd60';
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDepartmentsList() {
        if (this.safeties == null) { return; }

        if (this.departmentsList == null) {
            this.departmentsList = [];
            this.safeties.forEach(s => this.departmentsList.push({ value: s.departmentId, label: s.departmentName }));
        }
        return this.departmentsList;
    }

    private setInitialSelectedDepartments() {
        // If we have a previously saved set of selected departments, use it...
        if (this.dashUserPrefs && this.dashUserPrefs.taskTrackerSafetiesSelectedDepartments) {
            this.departmentsList.filter(d =>
                this.dashUserPrefs.taskTrackerSafetiesSelectedDepartments.includes(d.value)).forEach(dept => this.selectedDepartmentIds.push(dept.value));
        } else {
            // ...if not, select all departments.
            this.selectedDepartmentIds = [];
            this.departmentsList.forEach(d => this.selectedDepartmentIds.push(d.value));
            this.onDepartmentSelectionChange();
        }
    }
}
