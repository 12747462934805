import * as moment from 'moment';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-last-updated',
    templateUrl: './last-updated.component.html',
    styleUrls: ['./last-updated.component.less']
})
export class LastUpdatedComponent {

    @Input() staleDataThresholdInMinutes = 15;
    @Input() hideAlertBanner = false;

    private _lastUpdateTimestamp: Date;
    @Input() set lastUpdateTimestamp(value: Date) {
        if (value == null) { value = new Date(); }

        this._lastUpdateTimestamp = value;
        this.setLastUpdatedInfo();
        this.startTimer();
    }

    get lastUpdateTimestamp(): Date {
        return this._lastUpdateTimestamp;
    }

    lastUpdated: { duration: string, scale: string };
    alertText: string;
    timerRef: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private translateService: TranslateService
    ) { }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    protected setLastUpdatedInfo() {
        const dataAgeDuration = moment.duration(moment().diff(this.lastUpdateTimestamp));
        let duration: number;
        let scale: string;

        // Special case for seconds. Only update the last updated footer and bail.
        if (dataAgeDuration.asSeconds() < 60) {
            this.setLastUpdatedLabel('STRINGS.A_FEW', 'STRINGS.SECONDS');
            this.alertText = null;
            return;
        }

        if (dataAgeDuration.asMinutes() < 60) {
            duration = dataAgeDuration.asMinutes();
            scale = duration < 2 ? 'STRINGS.MINUTE' : 'STRINGS.MINUTES';
        } else if (dataAgeDuration.asHours() < 24) {
            duration = dataAgeDuration.asHours();
            scale = duration < 2 ? 'STRINGS.HOUR' : 'STRINGS.HOURS';
        } else if (dataAgeDuration.asDays() < 7) {
            duration = dataAgeDuration.asDays();
            scale = duration < 2 ? 'STRINGS.DAY' : 'STRINGS.DAYS';
        } else if (dataAgeDuration.asWeeks()) {
            duration = dataAgeDuration.asWeeks();
            scale = duration < 2 ? 'STRINGS.WEEK' : 'STRINGS.WEEKS';
        } else {
            return;
        }

        this.setLastUpdatedLabel(Math.floor(duration).toString(), scale);

        if (dataAgeDuration.asMinutes() > this.staleDataThresholdInMinutes) {
            this.showStaleDataWarning(Math.floor(duration), scale);
        } else if (this.alertText !== null) {
            this.alertText = null;
        }
    }

    private setLastUpdatedLabel(duration: string, scale: string) {
        this.lastUpdated = { duration: this.translateService.instant(duration).toLowerCase(), scale: this.translateService.instant(scale).toLowerCase() };
    }

    private showStaleDataWarning(duration: number, scale: string) {
        const localizedString = this.translateService.instant(scale).toLowerCase();
        this.alertText = this.translateService.instant('ERR_MSG.STALE_WIDGET_DATA_WARNING', { timeValue: duration, timeUnits: localizedString });
    }

    private startTimer() {
        clearTimeout(this.timerRef);

        this.timerRef = setInterval(() => {
            this.setLastUpdatedInfo();
        }, 10000)
    }
}
