import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { CachedCollection } from '../_common/cached-collection';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { TurfGuardApiService } from './turf-guard-api.service';
import { TurfGuardHole } from './models/turf-guard-hole.model';
import { TurfGuardSensor } from './models/turf-guard-sensor.model';
import { TurfGuardWidgetData } from './models/turf-guard-widget-data.model';

import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

@Injectable({
    providedIn: 'root'
})
export class TurfGuardManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // Cache Containers
    private _turfGuardHoles: CachedCollection<TurfGuardHole>;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private turfGuardApiService: TurfGuardApiService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getTurGuardWidgetData(sensorType: TurfGuardSensorType): Observable<TurfGuardWidgetData> {
        return this.getTurfGuardHoles(true).pipe(map((tgHoles: TurfGuardHole[]) => new TurfGuardWidgetData(sensorType, tgHoles)));
    }

    getTurfGuardHoles(bypassCache = false): Observable<TurfGuardHole[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.turfGuardHoles); }

        if (!bypassCache && this._turfGuardHoles && !this._turfGuardHoles.isExpired) {
            return of(this._turfGuardHoles.collection.slice());
        }

        return this.turfGuardApiService.getTurfGuardHoles()
            .pipe(tap(tgHoles => this._turfGuardHoles = new CachedCollection(tgHoles)));
    }

    getTurfGuardSensors(holeIds: number[]): Observable<TurfGuardSensor[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.getTurfGuardSensors(holeIds)); }

        return this.turfGuardApiService.getTurfGuardSensors(holeIds);
    }

}
