import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { EtInfo } from '../../../../api/weather/models/et-info.model';
import { EtInfoForDisplay } from '../../../../api/weather/models/et-info-for-display.model';
import { EtInfoForDisplayIcon } from '../../../../api/weather/models/et-info-for-display-icon.model';
import { EtResult } from '../../../../api/weather/models/et-result.model';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../common/services/user-format.service';
import { WeatherManagerService } from '../../../../api/weather/weather-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import ToroColor = ToroEnums.ToroColor;

@UntilDestroy()
@Component({
    selector: 'toro-widget-evapotranspiration',
    templateUrl: './widget-evapotranspiration.component.html',
    styleUrls: ['./widget-evapotranspiration.component.less']
})
export class WidgetEvapotranspirationComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#3178c6';
    title = 'WIDGET.EVAPOTRANSPIRATION';

    UnitsSystem = ToroEnums.UnitsSystem;

    dailyEtForecast: EtInfo[] = [];
    dailyEtForDisplay: EtInfoForDisplay[] = [];
    unitsSystem: ToroEnums.UnitsSystem;


    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setComponentData();
            });

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.EvapotranspirationWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected getWidgetData() {
        this.weatherManager.getEvapotranspiration()
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((etForecast: EtResult) => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = etForecast.lastUpdated;
                this.dailyEtForecast = etForecast.results.length > 7 ? etForecast.results.slice(0, 7) : etForecast.results;
                this.setComponentData();
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setComponentData() {
        this.dailyEtForDisplay = [];

        this.unitsSystem = this.userFormatService.unitsSystem;

        if (!this.dailyEtForecast) return;

        let previousEtValue: number;
        this.dailyEtForecast.forEach(item => {
            const displayEt: EtInfoForDisplay = {
                dateString: this.userFormatService.toUserDateString(item.date),
                value: this.userFormatService.evapotranspiration(item.value),
                units: this.userFormatService.evapotranspirationUnits,
                icon: this.getEtForecastIcon(previousEtValue, item.value)
            };

            this.dailyEtForDisplay.push(displayEt);

            previousEtValue = item.value;
        });
    }

    private getEtForecastIcon(previousEt: number, currentIt: number): EtInfoForDisplayIcon {
        if (!previousEt) return null;

        if (previousEt < currentIt) {
            return new EtInfoForDisplayIcon('long-arrow-alt-up', ToroColor.EtTrendUp);
        } else if (previousEt > currentIt) {
            return new EtInfoForDisplayIcon('long-arrow-alt-down', ToroColor.EtTrendDown);
        }

        return new EtInfoForDisplayIcon('arrows-alt-h');
    }

}
