export class GenericMqttMessageContent {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.errorMessage = json.ErrorMessage;
            if (json.IsSuccess) this.isSuccess = json.IsSuccess;
            if (json.Result) this.result = json.Result;

            delete this['Result'];
            delete this['IsSuccess'];
            delete this['ErrorMessage'];
        }
    }

    errorMessage: string;
    isSuccess: boolean;
    result: any[];
}
