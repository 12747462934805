export class MyTurfPartsOrder {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    cartName: string;
    numberOfParts: number;
    totalCost: string;
    locationName: string;
    locationId: number;

}
