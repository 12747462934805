import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-task-tracker-api-key-dialog',
    templateUrl: './task-tracker-api-key-dialog.component.html',
    styleUrls: ['./task-tracker-api-key-dialog.component.less']
})
export class TaskTrackerApiKeyDialogComponent {
    @Output() setApiKey = new EventEmitter<string>();
    @Output() cancelClick = new EventEmitter();

    @Input() apiKey: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSetApiKey() {
        this.setApiKey.emit(this.apiKey);
    }

    onCancelDialog() {
       this.cancelClick.emit();
    }

}
