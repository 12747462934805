import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AlertSettingsAndParameters } from '../notifications/models/alert-settings-and-parameters.model';
import { AppInjector } from '../../demo/demo.module';
import { DashUserApiService } from './dash-user-api.service';
import { DashUserInfo } from './models/dash-user-info.model';
import { DashUserPreferences } from './models/dash-user-preferences.model';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { NotificationManagerService } from '../notifications/notification-manager.service';
import { SelectItem } from 'primeng/api';
import { SendEmailModel } from './models/send-email.model';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DashUserManagerService {
    // Subjects
    dashUserPreferencesChange = new Subject<DashUserPreferences>();          // NOTE: This event should only be consumed by the authManager.
    unitsSystemChange = new Subject();

    // List non-volatile caches
    private _languageSelectItems: SelectItem[];
    private _temperatureScaleSelectItems: SelectItem[];
    private _unitsOfMeasureSelectItems: SelectItem[];
    private _dateFormatsSelectItems: SelectItem[];
    private _groupAndDecimalFormatSelectItems: SelectItem[];

    // Object non-volatile caches
    private _dashUserInfo: DashUserInfo;

    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserApiService: DashUserApiService,
                private localCacheService: LocalCacheService,
                private notificationManager: NotificationManagerService,
                private translateService: TranslateService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDashUserInfo(bypassCache = false): Observable<DashUserInfo> {
        if (this._dashUserInfo && !bypassCache) { return of(this._dashUserInfo); }

        if (environment.isDemoMode) {
            this._dashUserInfo = this.demoModeMockDataService.dashUserInfo;
            return of(this._dashUserInfo);
        }

        return this.dashUserApiService.getDashUserInfo()
            .pipe(tap((dashUserInfo: DashUserInfo) => this._dashUserInfo = dashUserInfo));
    }

    sendFeedback(sem: SendEmailModel): Observable<any> {
        return this.dashUserApiService.sendFeedback(sem);
    }

    updateDashUserPreferences(userPreferences: DashUserPreferences): Observable<any> {
        if (environment.isDemoMode) {
            this.translateService.use(userPreferences.language);
            this.localCacheService.language = userPreferences.language;
            this._dashUserInfo.preferences = userPreferences;
            this.dashUserPreferencesChange.next(userPreferences);
            return of(true);
        }

        const unitsChanged = this._dashUserInfo.preferences.unitsSystem != userPreferences.unitsSystem;

        return this.dashUserApiService.updateDashUserPreferences(userPreferences)
            .pipe(
                tap(() => {
                    this.translateService.use(userPreferences.language);
                    this.localCacheService.language = userPreferences.language;
                }),
                switchMap(() => this.getDashUserInfo(true)),
                tap((dashUserInfo: DashUserInfo) => {
                    this.dashUserPreferencesChange.next(dashUserInfo.preferences);
                    if(unitsChanged) { this.unitsSystemChange.next(null); }

                    // Clear cached lists in case language has changed.
                    this._languageSelectItems = null;
                    this._temperatureScaleSelectItems = null;
                    this._unitsOfMeasureSelectItems = null;
                    this._dateFormatsSelectItems = null;
                })
            );
    }

    get userEmail(): string {
        if (!this._dashUserInfo || !this._dashUserInfo.email) { return ''; }
        return this._dashUserInfo.email;
    }

    get userId(): string {
        if (!this._dashUserInfo || !this._dashUserInfo.id) { return ''; }
        return this._dashUserInfo.id;
    }

    get language(): string {
        if (!this._dashUserInfo || !this._dashUserInfo.id) { return ''; }
        return this._dashUserInfo.preferences.language;
    }

    setDefaultAlertParamsIfRequired() {
        this.notificationManager.getAlertSettingsAndParameters()
            .pipe(take(1))
            .subscribe((settings: AlertSettingsAndParameters) => {
                if (settings.alertParameters[0].id === 0 || settings.alertParameters[0].siteId === 0) {
                    // Need to set initial settings and parameters.
                    settings.alertParameters = [];
                    settings.userSettings.emailAddresses = this.userEmail;
                    this.notificationManager.setAlertSettingsAndParameters(settings)
                        .subscribe(() => {
                        }, error => {
                            // For now, we'll just ignore. If it was a complete failure to write all of the AlertParams, we'll catch it next time.
                        });
                }
            }, error => {
                // For now, we'll just ignore. If it was a complete failure to write all of the AlertParams, we'll catch it next time.
            });
    }

    // =========================================================================================================================================================
    // Lists
    // =========================================================================================================================================================

    get languageSelectItems(): SelectItem[] {
        if (!this._languageSelectItems) {
            this._languageSelectItems = [
                // { label: this.translateService.instant('LANGUAGE.BULGARIAN'), value: ToroEnums.LanguageCode.Bulgarian },
                // { label: this.translateService.instant('LANGUAGE.CHINESE_SIMPLIFIED'), value: ToroEnums.LanguageCode.Chinese_Simplified },
                // { label: this.translateService.instant('LANGUAGE.CZECH'), value: ToroEnums.LanguageCode.Czech },
                // { label: this.translateService.instant('LANGUAGE.DANISH'), value: ToroEnums.LanguageCode.Danish },
                { label: this.translateService.instant('LANGUAGE.DUTCH'), value: ToroEnums.LanguageCode.Dutch },
                { label: this.translateService.instant('LANGUAGE.ENGLISH'), value: ToroEnums.LanguageCode.English_US },
                { label: this.translateService.instant('LANGUAGE.FRENCH'), value: ToroEnums.LanguageCode.French },
                { label: this.translateService.instant('LANGUAGE.GERMAN'), value: ToroEnums.LanguageCode.German },
                { label: this.translateService.instant('LANGUAGE.ITALIAN'), value: ToroEnums.LanguageCode.Italian },
                { label: this.translateService.instant('LANGUAGE.JAPANESE'), value: ToroEnums.LanguageCode.Japanese },
                { label: this.translateService.instant('LANGUAGE.KOREAN'), value: ToroEnums.LanguageCode.Korean },
                // { label: this.translateService.instant('LANGUAGE.NORWEGIAN'), value: ToroEnums.LanguageCode.Norwegian },
                // { label: this.translateService.instant('LANGUAGE.PORTUGUESE'), value: ToroEnums.LanguageCode.Portuguese },
                // { label: this.translateService.instant('LANGUAGE.RUSSIAN'), value: ToroEnums.LanguageCode.Russian },
                { label: this.translateService.instant('LANGUAGE.SPANISH'), value: ToroEnums.LanguageCode.Spanish },
                // { label: this.translateService.instant('LANGUAGE.SWEDISH'), value: ToroEnums.LanguageCode.Swedish },
                { label: this.translateService.instant('LANGUAGE.THAI'), value: ToroEnums.LanguageCode.Thai },
                // { label: this.translateService.instant('LANGUAGE.VIETNAMESE'), value: ToroEnums.LanguageCode.Vietnamese },
            ];
        }

        return this._languageSelectItems;
    }

    get temperatureScaleSelectItems(): SelectItem[] {
        if (!this._temperatureScaleSelectItems) {
            this._temperatureScaleSelectItems = [
                { label: this.translateService.instant('STRINGS.CELSIUS'), value: ToroEnums.TemperatureUnits.Celsius },
                { label: this.translateService.instant('STRINGS.FAHRENHEIT'), value: ToroEnums.TemperatureUnits.Fahrenheit },
            ];
        }

        return this._temperatureScaleSelectItems;
    }

    get unitsOfMeasureSelectItems(): SelectItem[] {
        if (!this._unitsOfMeasureSelectItems) {
            this._unitsOfMeasureSelectItems = [
                { label: this.translateService.instant('STRINGS.IMPERIAL'), value: ToroEnums.UnitsSystem.Imperial },
                { label: this.translateService.instant('STRINGS.METRIC'), value: ToroEnums.UnitsSystem.Metric },
            ];
        }

        return this._unitsOfMeasureSelectItems;
    }

    get dateFormatsSelectItems(): SelectItem[] {
        if (!this._dateFormatsSelectItems) {
            this._dateFormatsSelectItems = [
                { label: ToroEnums.DateFormat.MMsDDsYY, value: ToroEnums.DateFormat.MMsDDsYY },
                { label: ToroEnums.DateFormat.MMhDDhYY, value: ToroEnums.DateFormat.MMhDDhYY },
                { label: ToroEnums.DateFormat.MMdDDdYY, value: ToroEnums.DateFormat.MMdDDdYY },
                { label: ToroEnums.DateFormat.DDsMMsYY, value: ToroEnums.DateFormat.DDsMMsYY },
                { label: ToroEnums.DateFormat.DDhMMhYY, value: ToroEnums.DateFormat.DDhMMhYY },
                { label: ToroEnums.DateFormat.DDdMMdYY, value: ToroEnums.DateFormat.DDdMMdYY },
            ];
        }

        return this._dateFormatsSelectItems;
    }

    get groupAndDecimalFormatSelectItems(): SelectItem[] {
        if (!this._groupAndDecimalFormatSelectItems) {
            this._groupAndDecimalFormatSelectItems = [
                { label: '1,000.00', value: ToroEnums.GroupAndDecimalSeparator.CommaPeriod },
                { label: '1.000,00', value: ToroEnums.GroupAndDecimalSeparator.PeriodComma },
            ];
        }

        return this._groupAndDecimalFormatSelectItems;
    }

}
