<p-sidebar [visible]="isVisible" appendTo="body" [styleClass]="'toro-mini-mode-sidebar'" [style]="{width: '100%', height: sidebarHeight}" [modal]="false" [showCloseIcon]="false" [dismissible]="false">
    <p-header>
        <div id="toro-mini-mode-modal-header-container" class="toro-component-shield-host">
            <fa-icon icon="arrow-left"></fa-icon>
            <img src="/assets/images/toro-intellidash-logo_r.png" alt="Toro">
            <toro-component-shield (shieldClick)="onClose()"></toro-component-shield>
        </div>
    </p-header>

    <div id="toro-mini-mode-modal-container">
        <ng-content></ng-content>
    </div>
</p-sidebar>
