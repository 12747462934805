import { Component, Input } from '@angular/core';
import { LynxArea } from '../../../../../../../api/lynx/models/lynx-area.model';

@Component({
    selector: 'toro-running-stations-expando-panel',
    templateUrl: './running-stations-expando-panel.component.html',
    styleUrls: ['./running-stations-expando-panel.component.less']
})
export class RunningStationsExpandoPanelComponent {

    @Input() runningStationsDetails: LynxArea[];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

}
