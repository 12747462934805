import WidgetType = ToroEnums.WidgetType;
import WizardIntegration = ToroEnums.WizardIntegration;

import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { MyTurfManagerService } from '../../../../../api/my-turf/my-turf-manager.service';
import { MyTurfPartsOrder } from '../../../../../api/my-turf/models/my-turf-parts-order.model';
import { take } from 'rxjs/operators';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';

@UntilDestroy()
@Component({
    selector: 'toro-my-turf-integration',
    templateUrl: './my-turf-integration.component.html',
    styleUrls: ['./my-turf-integration.component.less']
})
export class MyTurfIntegrationComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'wiz-integration-component';

    @Output() canConnect = new EventEmitter<boolean>();
    @Output() configured = new EventEmitter<WizardIntegration>();

    private readonly maxValidationCount = 5;
    readonly stepsCount = 2;
    validationAttemptCount = 0;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private myTurfManager: MyTurfManagerService
    ) { }

    ngOnInit(): void {
        this.broadcastService.setupDoConnect
            .pipe(untilDestroyed(this))
            .subscribe(() => this.onNavigateToSite());

        this.canConnect.emit(true);

        this.validateConnection();
    }

    ngOnDestroy() {
        this.canConnect.emit(false);
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    onNavigateToSite() {
        // // Select both of the myTurf widgets. The assumption is that since we are configuring them, the user will want them.
        // // They can still disable them on the enable/disable pane.
        // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfMaintenance, isEnabled: true });
        // this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfOrders, isEnabled: true });
        // this.configured.next(WizardIntegration.MyTurf);

        // Navigate to site in the same browser so that the myTurf redirect will come back to the same window.
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_MY_TURF_SITE' });
        setTimeout(() => window.open(this.myTurfManager.myTurfLoginUrl, '_self'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    private validateConnection() {

        this.myTurfManager.getPartsOrders()
            .pipe(take(1))
            .subscribe({
                next: (response: MyTurfPartsOrder[]) => {
                    // Select both of the myTurf widgets. The assumption is that since we are configuring them, the user will want them.
                    // They can still disable them on the enable/disable pane.
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfMaintenance, isEnabled: true });
                    this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.MyTurfOrders, isEnabled: true });
                    this.configured.next(WizardIntegration.MyTurf);
                },
                error: () => {
                    if(++this.validationAttemptCount <= this.maxValidationCount) {
                        setTimeout(() => this.validateConnection(), 60 * 1000);
                    }
                }
            })

    }

}
