<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="calAmpAccountNotConfigured || isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showLinkButton]="true"
            (gotoSite)="onTrackingSiteLinkClick()"
    >

        <div class="toro-asset-tracking-widget-content toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane">
                <div class="tmm-multi-content-panel-container toro-component-shield-host">
                    <div class="toro-mini-mode-content-panel" [ngClass]="language">
                        <div id="tat-working-value" class="tat-datum-value"><span class="tmm-iv-value">{{assetTrackingData?.assetsWorking || 0}}</span></div>
                        <div class="tmm-ellipsis-text">{{'STRINGS.WORKING' | translate}}</div>
                    </div>
                    <div class="toro-mini-mode-content-panel">
                        <div id="tat-idle-value" class="tat-datum-value"><span class="tmm-iv-value">{{assetTrackingData?.assetsIdle || 0}}</span></div>
                        <div class="tmm-ellipsis-text">{{'STRINGS.IDLE' | translate}}</div>
                    </div>
                    <div class="toro-mini-mode-content-panel">
                        <div id="tat-transport-value" class="tat-datum-value"><span class="tmm-iv-value">{{assetTrackingData?.assetsTransporting || 0}}</span></div>
                        <div class="tmm-ellipsis-text">{{'STRINGS.TRANSPORT' | translate}}</div>
                    </div>
                    <div class="toro-mini-mode-content-panel">
                        <div id="tat-off-value" class="tat-datum-value"><span class="tmm-iv-value">{{assetTrackingData?.assetsOff || 0}}</span></div>
                        <div class="tmm-ellipsis-text">{{'STRINGS.OFF' | translate}}</div>
                    </div>

                    <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                </div>
                <div id="tat-mm-map-btm" class="toro-component-shield-host">
                    {{'STRINGS.MAP' | translate}}
                    <toro-component-shield (shieldClick)="onShowMap()"></toro-component-shield>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="calAmpAccountNotConfigured"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-asset-tracking-widget-content toro-flex-child toro-link-button-host">
            <toro-widget-link-button
                    *ngIf="!calAmpAccountNotConfigured"
                    id="tracking-link-btn"
                    imageSrc="../../../../../assets/images/redTracking.png"
                    tooltipText="{{linkButtonTooltipText | translate}}"
                    (click)="onTrackingSiteLinkClick()"
            ></toro-widget-link-button>

            <div *ngIf="!calAmpAccountNotConfigured" id="tat-content-pane" class="toro-flex-child toro-component-shield-host">
                <div id="tat-total-hours-row" class="tat-content-row">
                    <div id="tat-total-hours-icon">
                        <i class="toro-am-Utility"></i>
                    </div>
                    <div id="tat-total-hours-col" class="tat-content-col2">
                        <span><span class="tat-value">{{assetTrackingData?.totalHours}}</span><span class="tat-label">{{'STRINGS.TOTAL_HOURS' | translate | lowercase}}</span></span>
                    </div>
                </div>

                <div id="tat-status-row" class="tat-content-row toro-flex-child">

                    <div class="tat-content-col1 tat-status-col toro-flex-child">
                        <div class="tat-col-row">
                            <div id="tat-stats-working" class="tat-status-container">
                                <span class="tat-status-value">{{assetTrackingData?.assetsWorking || 0}}</span>
                                <span class="tat-status-label">{{'STRINGS.WORKING' | translate | titlecase}}</span>
                            </div>
                        </div>
                        <div class="tat-col-row">
                            <div id="tat-stats-transport" class="tat-status-container">
                                <span class="tat-status-value">{{assetTrackingData?.assetsTransporting || 0}}</span>
                                <span class="tat-status-label">{{'STRINGS.TRANSPORT' | translate | titlecase}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tat-content-col2 tat-status-col toro-flex-child">
                        <div class="tat-col-row">
                            <div id="tat-stats-idle" class="tat-status-container">
                                <span class="tat-status-value">{{assetTrackingData?.assetsIdle || 0}}</span>
                                <span class="tat-status-label">{{'STRINGS.IDLE' | translate | titlecase}}</span>
                            </div>
                        </div>
                        <div class="tat-col-row">
                            <div id="tat-stats-off" class="tat-status-container">
                                <span class="tat-status-value">{{assetTrackingData?.assetsOff || 0}}</span>
                                <span class="tat-status-label">{{'STRINGS.OFF' | translate | titlecase}}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <toro-component-shield></toro-component-shield>
            </div>

            <div *ngIf="calAmpAccountNotConfigured" id="tat-no-account-pane" class="toro-flex-child toro-component-shield-host">
                <div id="tat-no-account-msg" [innerHTML]="'SPECIAL_MSG.ASSET_TRACKING_NOT_CONFIGURED' | translate"></div>

                <toro-component-shield></toro-component-shield>
            </div>

            <div *ngIf="!calAmpAccountNotConfigured" id="tpw-bottom-toolbar">
                <button class="twp-button" [disabled]="!assetTrackingData?.hasMotionLogEvents"  (click)="onShowMap(true)">
                    <span>{{'STRINGS.MAP' | translate}}</span>
                </button>
                <button class="twp-button" [disabled]="assetTrackingData?.assets == null || assetTrackingData?.assets.length < 1" (click)="onShowAssets()">
                    <span>{{'STRINGS.ASSETS' | translate}}</span>
                </button>
            </div>

            <toro-waiting-indicator *ngIf="isManualDataRefresh"></toro-waiting-indicator>
        </div>
    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-equipment-tracking-dlg
        *ngIf="assetTrackingData?.assets && showListDialog"
        [title]="title"
        [lastUpdateTimeString]="lastStatusUpdateTimeString"
        [showRefreshButton]="true"
        [disableRefreshButton]="isBusy"
        (cancel)="showListDialog = false;"
        (refresh)="onRefreshStatusDialog()"
>
    <div class="etd-table-content-sizing-wrapper">
        <div id="equipment-tracking-dlg-table-container" class="toro-flex-child">
            <p-table [value]="assetTrackingData?.assets" [scrollable]="true" scrollHeight="flex" selectionMode="single" [(selection)]="selectedAsset">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'deviceType'" style="width: 12%;">{{'STRINGS.TYPE' | translate | titlecase}}
                            <p-sortIcon [field]="'deviceType'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'deviceStatus'" class="etd-status-col" [ngClass]="language">{{'STRINGS.STATUS' | translate | titlecase}}
                            <p-sortIcon [field]="'deviceStatus'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'hours'" style="width: 15%;">{{'STRINGS.HOURS' | translate | titlecase}}
                            <p-sortIcon [field]="'hours'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'name'">{{'STRINGS.NAME' | translate | titlecase}}
                            <p-sortIcon [field]="'name'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-asset>
                    <tr [pSelectableRow]="asset">
                        <td style="width: 12%; text-align: center">
                            <toro-asset-type-icon [assetType]="asset?.deviceType"></toro-asset-type-icon>
                        </td>
                        <td class="etd-status-col" style="text-align: center" [ngClass]="language">
                            <toro-asset-status-icon [assetStatus]="asset?.currentStatus"></toro-asset-status-icon>
                        </td>
                        <td style="width: 15%; text-align: center">{{asset?.totalDailyHoursString | translate}}</td>
                        <td>{{asset?.name}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <toro-waiting-indicator *ngIf="isBusy"></toro-waiting-indicator>
    </div>
</toro-equipment-tracking-dlg>

<toro-equipment-tracking-dlg
        *ngIf="assetTrackingData?.assets && showMapDialog"
        [title]="title"
        showRefreshButton="true"
        (cancel)="showMapDialog = false"
        (refresh)="onRefreshMap()"
>
    <div class="etd-map-content-sizing-wrapper">
        <div id="equipment-tracking-map-container" [ngClass]="{'fade-in-on-load': fadeInMapDialog}">
            <p-gmap [options]="mapOptions"
                    [overlays]="mapOverlays"
                    [style]="{'width':'100%', 'height':'100%'}"
                    (onMapReady)="onMapReady($event)"
                    (onMapDragEnd)="onMapDragEnd()"
                    (onZoomChanged)="onZoomChanged()"
            ></p-gmap>
        </div>
        <div id="etd-map-footer">
            <div id="etd-map-footer-slider">
                <p-slider [(ngModel)]="eventSliderIndex" [min]="0" [max]="eventSliderMax" (onChange)="onEventSliderChange($event)"></p-slider>
            </div>
            <div id="etd-map-footer-toolbar">
                <div *ngIf="showAnimationControls" id="etd-map-footer-buttons">
                    <p-button type="button" [label]="'STRINGS.TOGGLE_PLAYBACK' | translate | titlecase" (onClick)="onAnimateDeviceLocations()"></p-button>
                    <p-button type="button" [label]="'STRINGS.RESET_PLAYBACK' | translate | titlecase" (onClick)="onResetMap()"></p-button>
                </div>
                <div id="etd-map-footer-date" class="toro-component-shield-host">
                    {{userSlideDateTimeString}} <span id="etd-tz-label">({{'STRINGS.LOCAL_COURSE_TIME' | translate | titlecase}})</span>
                    <toro-component-shield (click)="onShowAnimationControls($event)"></toro-component-shield>
                </div>
            </div>
        </div>
        <toro-waiting-indicator *ngIf="isBusy || (showMapDialog && !fadeInMapDialog)"></toro-waiting-indicator>
    </div>
</toro-equipment-tracking-dlg>

<p-dialog [visible]="isAccountIdDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{'STRINGS.SET_CAL_AMP_ACCOUNT_ID' | translate | titlecase}}</p-header>

    <div id="twp-course-dialog-container">
        <span>{{'STRINGS.CAL_AMP_ACCOUNT_ID' | translate | titlecase}}</span>
        <p-inputNumber
                [(ngModel)]="newAccountId"
                [useGrouping]="false"
                [size]="30"
                [placeholder]="'STRINGS.ENTER_CAL_AMP_ACCOUNT_ID' | translate | titlecase">
        </p-inputNumber>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelAccountIdDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetAccountId()"></p-button>
    </p-footer>
</p-dialog>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
