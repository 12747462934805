export class SpecFsSessionData {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    subCollectionName: string;
    areaName: string;
    gpsLatitude: number;
    gpsLongitude: number;
    dataValue: string;
    decimalDataValue: number;
    dataNote: string;
    dataType: string;
    gridIdentifier: number = null;
    gridRow: number = null;
    gridColumn: number = null;
}
