/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DashMessageService } from '../../common/services/dash-message.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NsnAnnouncements } from './models/nsn-announcements.model';
import { NsnConnectDevice } from './models/nsn-connect-device.model';
import { NsnShipments } from './models/nsn-shipments.model';
import { NsnStatus } from './models/nsn-status.model';

@Injectable({
    providedIn: 'root'
})
export class NsnApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private dashMessageService: DashMessageService,
                http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiEndpoint(): Observable<string> {
        return this.apiService.apiRequest<any>(NsnApiService.apiEndpointUrl, HttpMethod.Get)
            .pipe(map(result => result.content));
    }

    getAnnouncements(): Observable<NsnAnnouncements> {
        return this.apiService.apiRequest<any>(NsnApiService.announcementsUrl, HttpMethod.Get)
            .pipe(map((response: NsnAnnouncements) => new NsnAnnouncements(response)),
                catchError((error) => {
                        this.dashMessageService.showWidgetDataFetchDetailedErrorMessage('WIDGET.TORO_NSN', 'ERR_MSG.FAILED_TO_RETRIEVE_NSN_ANNOUNCEMENTS');
                        throw error;
                    }
                )
            );
    }

    getStatus(): Observable<NsnStatus> {
        return this.apiService.apiRequest<any>(NsnApiService.statusUrl, HttpMethod.Get)
            .pipe(map((response: NsnStatus) => new NsnStatus(response)));
    }

    getShipments(): Observable<NsnShipments> {
        return this.apiService.apiRequest<any>(NsnApiService.shipmentsUrl, HttpMethod.Get)
            .pipe(
                map((response: NsnShipments) => new NsnShipments(response)),
                catchError((error) => {
                        this.dashMessageService.showWidgetDataFetchDetailedErrorMessage('WIDGET.TORO_NSN', 'ERR_MSG.FAILED_TO_RETRIEVE_NSN_SHIPMENTS');
                        throw error;
                    }
                )
            );
    }

    getNsnConnectDevices(): Observable<NsnConnectDevice[]> {
        return this.apiService.apiRequest<any>(NsnApiService.nsnConnectDevicesUrl, HttpMethod.Get)
            .pipe(
                map((response: NsnConnectDevice[]) => response.map(d => new NsnConnectDevice(d))),
                catchError((error) => {
                    this.dashMessageService.showWidgetDataFetchDetailedErrorMessage('WIDGET.TORO_NSN', 'ERR_MSG.FAILED_GETTING_NSN_DEVICES');
                    return of([]);
                })
            );
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}nsn`; }

    private static get apiEndpointUrl(): string { return `${NsnApiService.baseUrl}/apiEndpoint`; }

    private static get announcementsUrl(): string { return `${NsnApiService.baseUrl}/announcements`; }

    private static get statusUrl(): string { return `${NsnApiService.baseUrl}/status`; }

    private static get shipmentsUrl(): string { return `${NsnApiService.baseUrl}/shipments`; }

    private static get nsnConnectDevicesUrl(): string { return `${NsnApiService.baseUrl}/nsnConnectDevices`; }

}
