export class MeasurementMinMax {

    constructor(min: number, max: number) {
        this.min = min;
        this.max = max;
    }

    min: number;
    max: number;
}
