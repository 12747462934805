export class SoilScoutNotificationTrigger {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    device_type: string;
    trigger_time: number;
}
