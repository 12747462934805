export class GetGeoTopoHole {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            this.id = json.Id;
            this.name = json.Name;
            this.stations = json.Stations;

            delete this['Name'];
            delete this['Id'];
            delete this['Stations'];
        }
    }

    id: number;
    name: string;
    stations: number[];
}
