import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@UntilDestroy()
@Component({
    selector: 'toro-pw-data-card',
    templateUrl: './pw-data-card.component.html',
    styleUrls: ['./pw-data-card.component.less']
})
export class PwDataCardComponent implements OnInit {
    @HostBinding('class') class = 'toro-pw-data-card'

    @Input() title: string;
    @Input() value: string;
    @Input() headerIcon1: IconProp;
    @Input() headerIcon2: IconProp;
    @Input() headerClass: string;
    @Input() isShowingCharts = false;

    language = 'en-us';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService) { }

    ngOnInit(): void {
        this.language = this.dashUserManager.language;

        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.language = this.dashUserManager.language);
    }

}
