import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LynxPumpStation } from '../../../../../../api/lynx/models/lynx-pump-station.model';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@Component({
    selector: 'toro-pump-station-details-dlg',
    templateUrl: './pump-station-details-dlg.component.html',
    styleUrls: ['./pump-station-details-dlg.component.less']
})
export class PumpStationDetailsDlgComponent implements OnInit {
    @Output() closeDialog = new EventEmitter();

    @Input() pumpStation: LynxPumpStation;

    pressureMin = '-';
    pressureSetPoint = '-';
    pressureMax = '-';
    flowMin = '-';
    flowMax = '-';
    flowUnits = '';
    pressureUnits = '';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        if (this.pumpStation) { this.setDialogValues(); }
        this.pressureUnits = this.userFormatService.pressureUnits;
        this.flowUnits = this.userFormatService.flowUnits;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.closeDialog.emit();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    setDialogValues() {
        this.pressureMin = <string>this.userFormatService.pressure(this.pumpStation.pressureConfig.minimum, true);
        this.pressureSetPoint = <string>this.userFormatService.pressure(this.pumpStation.pressureConfig.expected, true);
        this.pressureMax = <string>this.userFormatService.pressure(this.pumpStation.pressureConfig.maximum, true);
        this.flowMin = <string>this.userFormatService.flow(this.pumpStation.flowConfig.minimum, true);
        this.flowMax = <string>this.userFormatService.flow(this.pumpStation.flowConfig.maximum, true);
    }

}
