import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../../common/services/broadcast.service';
import { LynxWeatherStation } from '../../../../../../api/lynx/models/lynx-weather-station.model';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-lynx-current-weather-card',
    templateUrl: './lynx-current-weather-card.component.html',
    styleUrls: ['./lynx-current-weather-card.component.less']
})
export class LynxCurrentWeatherCardComponent implements OnInit {
    private readonly NO_VALUE = '--';

    private _weatherStation: LynxWeatherStation;
    @Input() set weatherStation(value: LynxWeatherStation) {
        this._weatherStation = value;
        this.setComponentData();
    }

    get weatherStation(): LynxWeatherStation {
        return this._weatherStation;
    }

    @Input() staleDataThresholdInMinutes = 15;
    @Input() hideAlertBanner = false;

    tempUnits: string;
    etUnits: string;
    precipitationUnits: string;
    temperature: string;
    humidity: string;
    et: string;
    precipitation: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit(): void {
        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setComponentData());
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    setComponentData() {
        const currentWeatherInfo = this.weatherStation.statuses.find(s => s.time == null);

        this.tempUnits = this.userFormatService.temperatureUnits;
        this.etUnits = this.userFormatService.evapotranspirationUnits;
        this.precipitationUnits = this.userFormatService.rainfallUnits;

        this.temperature = currentWeatherInfo && currentWeatherInfo.temperature != null
            ? <string>this.userFormatService.temperature(currentWeatherInfo.temperature, true) : this.NO_VALUE;
        this.humidity = currentWeatherInfo && currentWeatherInfo.relativeHumidity != null
            ? this.humidity = `${this.userFormatService.humidity(currentWeatherInfo.relativeHumidity)}` : this.NO_VALUE;
        this.et = currentWeatherInfo && currentWeatherInfo.et != null
            ? this.userFormatService.evapotranspiration(currentWeatherInfo.et) : this.NO_VALUE;
        this.precipitation = currentWeatherInfo && currentWeatherInfo.precipitation != null
            ? <string>this.userFormatService.rainfall(currentWeatherInfo.precipitation, true) : this.NO_VALUE;
    }

}
