import { SpecFsSessionData } from './spec-fs-session-data.model';

export class SpecFsSession {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.sessionData != null) this.sessionData = json.sessionData.map(d => new SpecFsSessionData(d));
            if (json.sessionStartTime) this.sessionStartTime = new Date(json.sessionStartTime);
        }
    }

    sessionName: string;
    sessionStartTime: Date;
    timeZoneOffset: string;
    sessionNote: string;
    deviceType: string;
    sessionData: SpecFsSessionData[] = []

}
