import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashAuthenticatedUser } from '../../../../api/auth/models/dash-authenticated-user.model';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { finalize } from 'rxjs/operators';
import { Note } from '../../../../api/note/models/note.model';
import { NoteManagerService } from '../../../../api/note/note-manager.service';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-widget-notes',
    templateUrl: './widget-notes.component.html',
    styleUrls: ['./widget-notes.component.less']
})
export class WidgetNotesComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#3178c6';
    title = 'WIDGET.NOTES';

    protected notesText: string;
    protected isDirty = false;
    protected isSavingData = false;

    private previousNotesText: string;
    // private textChangeTimerRef: any;
    private dashUser: DashAuthenticatedUser;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private noteManager: NoteManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.dashUser = this.authManager.dashAuthenticatedUser;
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.NotesWidgetName;
    }

    protected getWidgetData(isManualRefresh = false) {
        // Don't get new data if we have changes pending.
        // NOTE: If they user is making changes in two places at the same time, the changes made last will win and changes made on "the other device"
        // will be lost.
        if (!isManualRefresh && this.isDirty) return;

        if (isManualRefresh) { this.isBusy = true; }

        this.noteManager.getNote(this.dashUser.sid, this.dashUser.siteId)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe({
                next: (note: Note) => {
                    this.notesText = note.text;
                    this.previousNotesText = this.notesText;
                    this.isDirty = false;
                },
                error: err => {
                    console.log();
                }
            })
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTextChange(event: any) {
        setTimeout(() => this.isDirty = this.previousNotesText != this.notesText);
    }

    onCancel() {
        this.getWidgetData();
    }

    onSave() {
        this.saveText();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private saveText() {
        this.isSavingData = true;

        // Place in setTimeout to give waiting indicator a chance to display; providing feedback to the user on save action.
        setTimeout(() => {
            const note = new Note({
                userId: this.dashUser.sid,
                siteId: this.dashUser.siteId,
                text: this.getCleanedUpText(this.notesText)
            });

            this.noteManager.updateNote(note)
                .pipe(finalize(() => this.isSavingData = false))
                .subscribe({
                    next: () => {
                        this.previousNotesText = this.notesText;
                        this.isDirty = false;
                    },
                    error: err => {
                        console.log();
                    }
                })
        }, 500)
    }

    private getCleanedUpText(text: string): string {
        return text;
        // return text.replace(new RegExp('<p><br></p>', 'g'), "");
    }
}
