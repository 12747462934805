/* eslint-disable @typescript-eslint/dot-notation */
import * as moment from 'moment';
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;
import { environment } from '../../../../environments/environment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { TurfGuardHoleAlert } from './turf-guard-hole-alert.model';
import { TurfGuardHoleLimits } from './turf-guard-hole-limits.model';

export class TurfGuardHole {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(this.lastUpdated).toDate();

            if (json.highAlertsSerialized) {
                this.highAlerts = new TurfGuardHoleAlert(!environment.isDemoMode ? JSON.parse(json.highAlertsSerialized) : json.highAlertsSerialized);
                delete this['highAlertsSerialized'];
            }
            if (json.lowAlertsSerialized) {
                this.lowAlerts = new TurfGuardHoleAlert(!environment.isDemoMode ? JSON.parse(json.lowAlertsSerialized) : json.lowAlertsSerialized);
                delete this['lowAlertsSerialized'];
            }
            if (json.limitsSerialized) {
                this.limits = new TurfGuardHoleLimits(!environment.isDemoMode ? JSON.parse(json.limitsSerialized) : json.limitsSerialized);
                delete this['limitsSerialized'];
            }
        }
    }

    id: number;
    number: number;
    lastUpdated: Date;
    avgTemperature: number;
    avgMoisture: number;
    avgSalinity: number;
    highAlerts: TurfGuardHoleAlert;
    lowAlerts: TurfGuardHoleAlert;
    limits: TurfGuardHoleLimits;
    name: string;

    hasAlert(sensorType: TurfGuardSensorType) {
        let sensorProperty: string;

        switch (sensorType) {
            case ToroEnums.TurfGuardSensorType.Temperature:
                sensorProperty = 'temperature';
                break;
            case TurfGuardSensorType.Moisture:
                sensorProperty = 'moisture';
                break;
            case TurfGuardSensorType.Salinity:
                sensorProperty = 'salinity';
                break;
        }

        return this.highAlerts[sensorProperty] > 0 || this.lowAlerts[sensorProperty] > 0;
    }

}
