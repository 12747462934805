import { Directive, EventEmitter, Output } from '@angular/core';

@Directive()
export class SoilScoutBoundaryInfo {
    @Output() valueChange = new EventEmitter<{ index: number, previous: number, new: number }>();

    constructor(index: number, value: number, color: string) {
        this.index = index;
        this.value = value;
        this.color = color;
    }

    index: number;
    color: string;

    private _value: number;
    public set value(val: number) {
        this.valueChange.emit({ index: this.index, previous: this._value, new: val })

        this._value = val;
    }

    public get value(): number {
        return this._value;
    }

}
