import * as moment from 'moment';
import { LynxFlowConfig } from './lynx-flow-config.model';
import { LynxPressureConfig } from './lynx-pressure-config.model';

export class LynxPumpStationDisplay {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.flowConfig) this.flowConfig = new LynxFlowConfig(json.flowConfig);
            if (json.pressureConfig) this.pressureConfig = new LynxPressureConfig(json.pressureConfig);
        }
    }

    capacity: number;
    flow: string;
    lastUpdated: Date;
    lynxId: number;
    name: string;
    pressure: string;
    siteId: number;
    flowConfig: LynxFlowConfig = null;
    pressureConfig: LynxPressureConfig = null;

    pressureAlertClass: string;
    pressureAlertTooltip: string;
    flowAlertClass: string;
    flowAlertTooltip: string;
}
