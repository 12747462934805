export class SpecFsGridData {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.gridDefinition != null) this.gridDefinition = new SpecGridDefinition(json.gridDefinition);
        }
    }

    gridDefinition: SpecGridDefinition;
}

export class SpecGridDefinition {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.gridCorners != null) this.gridCorners = json.gridCorners.map(c => new SpecGridCorner(c));
        }
    }

    collectionName: string;
    subCollectionName: string;
    areaName: string;
    gridCorners: SpecGridCorner[];
    rows: number;
    columns: number;
    flags: string;
    profileId: number;
}

export class SpecGridCorner {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    latitude: number;
    longitude: number;
}
