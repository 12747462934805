<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [lastUpdated]="lastUpdated"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [analyticsWidgetName]="analyticsWidgetName"
        [(alertText)]="alertText"
        [(isUnableToFetchData)]="isUnableToFetchData"
>

    <div id="toro-turf-cloud-labor-stats-widget-content">
        <toro-widget-link-button href="https://app.turfcloud.com" imageSrc="../../../../../assets/images/turfCloud-cloud-small.png" (click)="onTurfCloudLinkClick()">
        </toro-widget-link-button>

        <div class="toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing}">
            <div id="tcls-toolbar-container">
                <toro-date-picker [(date)]="selectedDate"></toro-date-picker>
            </div>

            <div id="tcls-table-container">
                <p-table *ngIf="!hideTableOnResize && (this.laborStats && this.laborStats.length > 0)" [value]="laborStats" [scrollable]="true" scrollHeight="flex" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'name'">{{'STRINGS.NAME' | translate | titlecase}}<p-sortIcon [field]="'name'"></p-sortIcon></th>
                            <th [pSortableColumn]="'job1'">{{'STRINGS.JOB1' | translate | titlecase}}<p-sortIcon [field]="'job1'"></p-sortIcon></th>
                            <th [pSortableColumn]="'job2'">{{'STRINGS.JOB2' | translate | titlecase}}<p-sortIcon [field]="'job2'"></p-sortIcon></th>
                            <th [pSortableColumn]="'job3'">{{'STRINGS.JOB3' | translate | titlecase}}<p-sortIcon [field]="'job3'"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-stat>
                        <tr>
                            <td>{{stat.name}}</td>
                            <td>{{stat.job1}}</td>
                            <td>{{stat.job2}}</td>
                            <td>{{stat.job3}}</td>
                        </tr>
                    </ng-template>
                </p-table>

                <toro-waiting-indicator *ngIf="isFetchingDateOnDateChange"></toro-waiting-indicator>
                <div *ngIf="!isFetchingDateOnDateChange && (!laborStats || laborStats.length < 1)" id="tcls-no-jobs">
                    <fa-icon [icon]="['far', 'calendar-times']"></fa-icon>{{'STRINGS.NO_JOBS_SCHEDULED_TODAY' | translate}}.
                </div>
            </div>

        </div>
    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>
