import { IconProp } from '@fortawesome/fontawesome-svg-core';

export class PwWeatherDatum {

    constructor(title: string, value: string, icon1?: IconProp, icon2?: IconProp, headerClass?: string) {
        this.title = title;
        this.value = value;
        if (icon1 != null) this.headerIcon1 = icon1;
        if (icon2 != null) this.headerIcon2 = icon2;
        if (headerClass != null) this.headerClass = headerClass;
    }

    title: string;
    value: string;
    headerIcon1: IconProp;
    headerIcon2: IconProp;
    headerClass: string;
}
