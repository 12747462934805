import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { SelectItem } from 'primeng/api';
import { TaskTrackerDailySummary } from '../../../../../api/task-tracker/models/task-tracker-daily-summary.model';
import { TaskTrackerManagerService } from '../../../../../api/task-tracker/task-tracker-manager.service';
import { TaskTrackerWidgetBase } from '../_task-tracker-widget-base';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

@UntilDestroy()
@Component({
    selector: 'toro-widget-task-tracker-labor',
    templateUrl: './widget-task-tracker-labor.component.html',
    styleUrls: ['./widget-task-tracker-labor.component.less']
})
export class WidgetTaskTrackerLaborComponent extends TaskTrackerWidgetBase implements OnInit {
    @ViewChild('lsChartContainer') lsChartContainer: ElementRef;

    iconColor = '#065f53';
    title = 'WIDGET.TASK_TRACKER_LABOR_SUMMARY';

    @Output() chartClick = new EventEmitter();

    dailySummary: TaskTrackerDailySummary;
    totalLaborDollars = '--';
    totalLaborHours = '--';
    tasksOutstanding = '--';
    tasksCompleted = '--';
    employeesScheduled = '--';
    employeesOff = '--';
    taskAssignmentsHasValues = false;
    employeeSummaryHasValues = false;
    taskAssignmentsProgressValue = 0;
    employeeSummaryProgressValue = 0;
    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;
    showModalGraph = false;
    modalTitle: string;
    showChart = false;
    departmentsList: SelectItem[];
    selectedDepartmentIds: number[] = [];
    selectedDepartmentIdsForChart: number[] = [];
    hideChartOnChange = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected taskTrackerManager: TaskTrackerManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) {
        super(analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, taskTrackerManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.modalTitle = `${this.translateService.instant('WIDGET.TASK_TRACKER_LABOR_SUMMARY')} - ${this.translateService.instant('STRINGS.COST_BY_AREA')}`;

        this.taskTrackerManager.companyIdChange
            .pipe(untilDestroyed(this))
            .subscribe((taskTrackerApiKey: string) => {
                this.taskTrackerApiKey = taskTrackerApiKey;
                this.dashUserPrefs.taskTrackerLaborSelectedDepartments = null;
                this.departmentsList = null;
                this.selectedDepartmentIds = null;

                this.isBusy = true;
                this.spinnerText = `${this.translateService.instant('STRINGS.RETRIEVING_DATA_FOR_NEW_API_KEY')}...`;
                this.getWidgetData(true);
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TaskTrackerLaborSummary;
    }

    protected getWidgetDataExtended(isManualRefresh = false) {
        const sources: Observable<any>[] = [
            this.taskTrackerManager.getTaskTrackerConfig(this.associatedWidget),
            this.taskTrackerManager.getDailySummary(this.companyId, isManualRefresh).pipe(take(1)),
            this.dashUserManager.getDashUserInfo().pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => this.resetBusy()))
            .subscribe(([taskTrackerConfig, dailySummary, dashUserInfo]) => {
                this.clearIsUnableToFetchData();
                this.hideChartOnChange = true;

                this.dashUserPrefs = dashUserInfo.preferences;
                this.dailySummary = dailySummary;
                if (!this.selectedDepartmentIds || this.selectedDepartmentIds.length < 1) { this.setInitialSelectedDepartments(); }
                this.setComponentData();
                this.lastUpdateTimestamp = new Date();
            }, () => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTaskTrackerLinkClick() {
        this.analyticsService.widgetEvent(AnalyticsEvent.TaskTrackerGoToTrackingSite, AnalyticsCategory.Interaction, this.analyticsWidgetName);
        super.onTaskTrackerLinkClick();
    }

    onChartClick() {
        if (this.showModalGraph) { return; }
        this.toggleChartDisplay(true);
    }

    // Method to delay display of chart in modal dialog to enable HighChart to draw after modal is displayed. This
    // enables the mouse tracking of the HighChart to function properly on a center position pDialog.
    toggleChartDisplay(show = false) {
        this.showModalGraph = show;
        setTimeout(() => this.showChart = show, 100);
    }

    onDepartmentSelectionChange() {
        if (this.arraysEqual(this.dashUserPrefs.taskTrackerLaborSelectedDepartments, this.selectedDepartmentIds)) return;

        this.showModalGraph = false;
        this.hideChartOnChange = true;

        this.dashUserPrefs.taskTrackerLaborSelectedDepartments = this.selectedDepartmentIds;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs)
            .subscribe(() => {
                this.setComponentData();
            }, () => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    protected setComponentData() {
        if (!this.dailySummary) { return; }

        this.hideChartOnChange = false;

        this.selectedDepartmentIdsForChart = this.selectedDepartmentIds;
        this.dailySummary.setDepartmentsFilter(this.selectedDepartmentIds);

        // Set individual values
        this.totalLaborDollars = this.userFormatService.toUserCurrency(this.dailySummary.totalCompanyLaborDollars);
        this.totalLaborHours = this.userFormatService.toUserNumber(this.dailySummary.totalCompanyLaborHours, 2, 2);
        this.tasksOutstanding = this.dailySummary.totalCompanyTasksOutstanding.toString();
        this.tasksCompleted = this.dailySummary.totalCompanyTasksCompleted.toString();
        this.employeesScheduled = this.dailySummary.totalCompanyScheduledEmployees.toString();
        this.employeesOff = this.dailySummary.totalCompanyOffEmployees.toString();

        // Set Progress Bar
        this.employeeSummaryHasValues = +this.employeesScheduled + +this.employeesOff > 0;
        this.employeeSummaryProgressValue = Math.floor((+this.employeesScheduled / (+this.employeesScheduled + +this.employeesOff)) * 100);
        this.taskAssignmentsHasValues = +this.tasksOutstanding + +this.tasksCompleted > 0;
        this.taskAssignmentsProgressValue = Math.floor((+this.tasksCompleted / (+this.tasksOutstanding + +this.tasksCompleted)) * 100);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setInitialSelectedDepartments() {
        this.departmentsList = this.dailySummary.departmentsList;

        // If we have a previously saved set of selected departments, use it...
        if (this.dashUserPrefs && this.dashUserPrefs.taskTrackerLaborSelectedDepartments) {
            this.departmentsList.filter(d =>
                this.dashUserPrefs.taskTrackerLaborSelectedDepartments.includes(d.value)).forEach(dept => this.selectedDepartmentIds.push(dept.value));
        } else {
            // ...if not, select all departments.
            this.selectedDepartmentIds = [];
            this.departmentsList.forEach(d => this.selectedDepartmentIds.push(d.value));
        }
    }

    private arraysEqual(arr1: any[], arr2: any[]): boolean {
        // Check if the arrays have the same length
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Sort the arrays
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        // Compare each element at the corresponding index
        for (let i = 0; i < arr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        // If all elements are equal, the arrays are equal
        return true;
    }

}
