import { DashAuthenticatedUser } from '../../auth/models/dash-authenticated-user.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';

import WidgetType = ToroEnums.WidgetType;

export class UpdateWidgetModel {

    constructor(widgetType: WidgetType, config: any, dashUser: DashAuthenticatedUser, deviceId: string) {
        this.userId = dashUser.sid;
        this.siteId = dashUser.siteId;
        this.deviceId = deviceId;
        this.widgetType = widgetType;
        this.config = JSON.stringify(config);
    }

    userId: string;
    siteId: number;
    deviceId: string;
    widgetType: WidgetType;
    config: string;

}
