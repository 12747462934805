
export class PogoImageExtent {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    min_lng: number;
    max_lng: number;
    min_lat: number;
    max_lat: number;
}
