import LanguageCode = ToroEnums.LanguageCode;

import { AppInjector } from '../../shared/shared.module';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localeTh from '@angular/common/locales/th';
import { registerLocaleData } from '@angular/common';
import { ToroEnums } from '../enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {
    private authManager: AuthManagerService;

    constructor(private translateService: TranslateService,
    ) {
    }

    load() {
        return new Promise((resolve, reject) => {
            this.translateService.addLangs([
                LanguageCode.English_US, LanguageCode.Spanish, LanguageCode.French, LanguageCode.German, LanguageCode.Italian, LanguageCode.Dutch,
                LanguageCode.Korean, LanguageCode.Thai, LanguageCode.Japanese
                // LanguageCode.Portuguese, LanguageCode.Swedish, LanguageCode.Chinese_Simplified,
                // LanguageCode.Vietnamese, LanguageCode.Russian, LanguageCode.Bulgarian, LanguageCode.Danish, LanguageCode.Norwegian, LanguageCode.Czech
            ]);
            this.translateService.setDefaultLang(LanguageCode.English_US);

            // This allows for proper usage of datepipe to transform dateformat in supported locales.
            this.registerLocales();

            // We need to wait for the strings to be loaded, too. Since we don't yet know which of the supported languages
            // will be used, we load them all.
            const todo = this.translateService.langs.map((lang) => this.translateService.getTranslation(lang));

            // Do all the retrievals and resolve our promise when complete.
            forkJoin(todo)
                .subscribe(() => {
                    if (!environment.allowDynamicDemoMode || !document.location.href.endsWith('/demo')) {
                        this.authManager = AppInjector.get(AuthManagerService);
                        this.authManager.init();
                    } else {
                        environment.isDemoMode = true;
                        environment.enableAnalytics = false;

                        this.authManager = AppInjector.get(AuthManagerService);
                        this.authManager.isDemoMode = true;
                    }

                    resolve(true);
                });
        });
    }

    // Method to register locales. Will be used by Datepipe.
    registerLocales() {
        registerLocaleData(localeEs);
        registerLocaleData(localeFr);
        registerLocaleData(localeDe);
        registerLocaleData(localeIt);
        registerLocaleData(localeNl);
        registerLocaleData(localeKo);
        registerLocaleData(localeTh);
        registerLocaleData(localeJa);
    }

}
