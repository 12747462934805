import { SiteTimeZoneInfo } from './site-time-zone-Info.model';

export class Site {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.timeZoneInfo) this.timeZoneInfo = new SiteTimeZoneInfo(json.timeZoneInfo);
        }
    }

    id: number;
    facilityId: string;
    latitude: number;
    longitude: number;
    name: string;
    timeZoneInfo: SiteTimeZoneInfo;
}
