import * as moment from 'moment';
import { EtInfo } from './et-info.model';

export class EtResult {
    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();
            if (json.results) this.results = json.results.map(i => new EtInfo(i));
        }
    }

    lastUpdated: Date;
    results: EtInfo[] = [];
}
