<div class="toro-notification-panel-container">

    <div class="tnp-header">
        <div class="tnp-header-row">
            <div class="tnp-refresh-button" (click)="onRefresh()">
                <fa-icon icon="sync" class="fa-spin" [ngClass]="{'fas fa-spin': isLoading}"></fa-icon>
            </div>
            <div class="tnp-header-title">{{'STRINGS.NOTIFICATIONS' | translate}}</div>
            <div class="tnp-close-button" (click)="onClosePanel()"><fa-icon icon="times"></fa-icon></div>
        </div>
        <div class="tnp-header-row tnp-link-row" [ngClass]="{'disabled': notifications?.length < 1}">
            <a (click)="onDeleteAll()">{{ 'STRINGS.DELETE_ALL' | translate | titlecase }}</a>
            <span>{{'STRINGS.COUNT' | translate | titlecase}}: {{notifications?.length}}</span>
            <a (click)="onMarkAllRead()">{{ 'STRINGS.MARK_ALL_READ' | translate | titlecase }}</a>
        </div>
    </div>

    <div class="tnp-scroll-container">
        <div class="tnp-card-container">
            <toro-notification-card *ngFor="let notification of notifications" [notification]="notification"></toro-notification-card>
            <toro-notification-card *ngIf="!notifications || notifications?.length < 1" [isNoNotificationsCard]="true"></toro-notification-card>
        </div>
    </div>

    <toro-waiting-indicator *ngIf="isBusy"
            [isVisible]="isBusy"
            backgroundColor="rgba(0,0,0,.2)"
    ></toro-waiting-indicator>

</div>
