export class CalAmpMapInfo {

    constructor(lat: number, lon: number) {
        this.latitude = lat;
        this.longitude = lon;
    }

    latitude: number;
    longitude: number;
}
