import { ToroEnums } from '../../enumerations/toro.enums';
import MessageSeverity = ToroEnums.MessageSeverity;

export class DashMessageItem {

    constructor(summary: string, detail: string, severity = MessageSeverity.Warn) {
        this.severity = severity;
        this.summary = summary;
        this.detail = detail;
    }

    severity: string;
    summary: string;
    detail: string;

    life = 5000;
    sticky = false;
}
