<div id="ss-gauge-container">
    <div id="ss-gauge-wrapper" class="prevent-select">
        <highcharts-chart
                *ngIf="gaugeOptions"
                class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isGaugeLoaded}"
                [Highcharts]="Highcharts"
                [options]="gaugeOptions"
        ></highcharts-chart>
    </div>
</div>

<div id="ss-gauge-btn-container">
    <div id="ss-alert-btn" class="ss-button prevent-select" *ngIf="sensorAlertsCount != null && sensorAlertsCount > 0">
        <div id="ss-alert-img"><img src="../../../../../../assets/images/soil-scout/ss-alert.png" alt=""></div>
        <div id="ss-alert-info">
            <div>{{ 'CASE_SENSITIVE.ONGOING_EVENTS' | translate}}</div>
            <div id="ss-sensor-count">{{sensorAlertsCount}} {{ (sensorAlertsCount == 1 ? 'STRINGS.SENSOR' : 'STRINGS.SENSORS') | translate }}</div>
        </div>
    </div>
    <div id="ss-details-btn" class="ss-button" (click)="onShowDetails()">
        <span class="prevent-select">{{'CASE_SENSITIVE.VIEW_DETAILS' | translate}}</span>
    </div>
</div>
