import { Component, Input, OnInit } from '@angular/core';
import { PinSheetEntry } from '../../../../../../api/ez-locator/models/pin-sheet-entry.model';

@Component({
    selector: 'toro-ez-locator-fmb-placement',
    templateUrl: './ez-locator-fmb-placement.component.html',
    styleUrls: ['./ez-locator-fmb-placement.component.less']
})
export class EzLocatorFmbPlacementComponent implements OnInit {

    @Input() pinSheet: PinSheetEntry;

    isFrontPlacement = false;
    isMiddlePlacement = false;
    isBackPlacement = false;

    constructor() { }

    ngOnInit(): void {
        const placement = +this.pinSheet.holeCenter
        this.isFrontPlacement = placement < 0;
        this.isMiddlePlacement = placement === 0;
        this.isBackPlacement = placement > 0;
    }

}
