import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemoGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable(observer => {
      if (environment.allowDynamicDemoMode && environment.isDemoMode) {
        observer.next(true);
        observer.complete();
      } else {
        observer.next(false);
        observer.complete();
        this.router.navigate(['/ah-snap']);
      }
    });
  }
}
