import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { TurfCloudLaborStat } from '../../../../../api/turf-cloud/models/turf-cloud-labor-stat.model';
import { TurfCloudLaborStatistics } from '../../../../../api/turf-cloud/models/turf-cloud-labor-statistics.model';
import { TurfCloudManagerService } from '../../../../../api/turf-cloud/turf-cloud-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;

@Component({
    selector: 'toro-widget-turf-cloud-labor-stats',
    templateUrl: './widget-turf-cloud-labor-stats.component.html',
    styleUrls: ['./widget-turf-cloud-labor-stats.component.less']
})
export class WidgetTurfCloudLaborStatsComponent extends ToroDashboardWidget implements OnInit {

    iconColor = '#9ACA3C';
    title = 'WIDGET.TURF_CLOUD_LABOR_STATS';

    laborStats: TurfCloudLaborStat[] = [];
    hideTableOnResize = false;
    isFetchingDateOnDateChange = false;

    private _selectedDate = new Date();
    set selectedDate(value: Date) {
        if (value === this._selectedDate) { return; }

        this._selectedDate = value;
        this.isFetchingDateOnDateChange = true;
        this.getWidgetData();

        this.analyticsService.event(AnalyticsEvent.TurfCloudLaborStatsWidgetDateChanged, AnalyticsCategory.Interaction);
    }

    get selectedDate(): Date {
        return this._selectedDate;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private turfCloudManager: TurfCloudManagerService,
                protected translateService: TranslateService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.isBusy = true;

        // NOTE: Initial data is fetched in the base class call to super.widgetResized. This is to ensure we don't attempt to load
        // any widget content until the widget has been properly sized (i.e., displayCols/displayRows has been properly set.
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TurfCloudLaborStatsWidgetName;
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);
    }

    protected widgetResizeStart(item: ToroGridsterWidget) {
        super.widgetResizeStart(item);
        setTimeout(() => this.hideTableOnResize = true, 500);
    }

    protected widgetResizeStop(item: ToroGridsterWidget) {
        super.widgetResizeStop(item);
        this.hideTableOnResize = false;
    }

    protected getWidgetData() {
        this.turfCloudManager.getLaborStats(this.selectedDate)
            .pipe(
                take(1),
                finalize(() => {
                    this.isWidgetInitialized = true;
                    this.isBusy = false;
                })
            )
            .subscribe((laborStats: TurfCloudLaborStatistics) => {
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = laborStats.lastUpdated;
                this.laborStats = laborStats.items;
                this.isFetchingDateOnDateChange = false;
            }, error => {
                this.isUnableToFetchData = true;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onTurfCloudLinkClick() {
        this.analyticsService.event(AnalyticsEvent.TurfCloudLaborStatsWidgetGoToTurfCloud, AnalyticsCategory.Interaction);
    }

}
