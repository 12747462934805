import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { GcsaaChannel } from './models/gcsaa-channel.model';
import { GcsaaRssApiService } from './gcsaa-rss-api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GcsaaRssManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private gcsaaRssApiService: GcsaaRssApiService) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getFeed(): Observable<GcsaaChannel> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.gcsaaRssFeed); }

        return this.gcsaaRssApiService.getFeed();
    }
}
