import { ApiService, HttpMethod } from '../_common/api.service';
import { GcsaaChannel } from './models/gcsaa-channel.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GcsaaRssApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getFeed(): Observable<GcsaaChannel> {
        return this.apiService.apiRequest<any>(this.feedUrl, HttpMethod.Get)
            .pipe(map(response => new GcsaaChannel(response.rss.channel)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}gcsaa`; }

    private get feedUrl(): string { return `${GcsaaRssApiService.baseUrl}/getFeed`; }

}
