import { PlaybooksNutrients } from './playbooks-nutrients.model';
import { PlaybooksYtdValue } from './playbooks-ytd-value.model';

export class PlaybooksYearlyReport {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.nutrients) { this.nutrients = json.nutrients.map(n => new PlaybooksNutrients(n)); }
            if (json.applicationTotalCost) { this.applicationTotalCost = new PlaybooksYtdValue(json.applicationTotalCost); }
            if (json.gdd0) { this.gdd0 = new PlaybooksYtdValue(json.gdd0); }
            if (json.gdd10) { this.gdd10 = new PlaybooksYtdValue(json.gdd10); }
            if (json.gdd32) { this.gdd32 = new PlaybooksYtdValue(json.gdd32); }
            if (json.gdd50) { this.gdd50 = new PlaybooksYtdValue(json.gdd50); }
            if (json.eiq) { this.eiq = new PlaybooksYtdValue(json.eiq); }
        }
    }

    nutrients: PlaybooksNutrients[] = [];
    applicationTotalCost: PlaybooksYtdValue;
    gdd0: PlaybooksYtdValue;
    gdd10: PlaybooksYtdValue;
    gdd32: PlaybooksYtdValue;
    gdd50: PlaybooksYtdValue;
    eiq: PlaybooksYtdValue;
    alertBadgeCount: number;
}
