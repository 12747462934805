import * as Highcharts from 'highcharts';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardManagerService } from '../../../../../api/turf-guard/turf-guard-manager.service';
import { TurfGuardWidgetBase } from '../_turf-guard-widget-base';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';
import { UserFormatService } from '../../../../../common/services/user-format.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import GaugeColor = ToroEnums.GaugeColor;
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-widget-turf-guard-temperature',
    templateUrl: './widget-turf-guard-temperature.component.html',
    styleUrls: ['./widget-turf-guard-temperature.component.less']
})
export class WidgetTurfGuardTemperatureComponent extends TurfGuardWidgetBase implements OnInit {

    iconColor = '#46a657';
    title = 'WIDGET.TURF_GUARD_TEMPERATURE';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                protected dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                protected translateService: TranslateService,
                protected turfGuardManager: TurfGuardManagerService,
                protected userFormatService: UserFormatService
    ) {
        super(analyticsService, broadcastService, dashMessageService, dashUserManager, deviceManager, translateService, turfGuardManager, userFormatService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        setTimeout(() => this.alertSensorType = this.translateService.instant('STRINGS.TEMPERATURE').toLowerCase());
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.TurfGuardTemperatureWidgetName;
    }

    protected onUserPreferencesChange(): void {
        super.onUserPreferencesChange();
        this.setGaugeValues();
    }

    onShowAlerts() {
        super.onShowAlerts();
        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewAlerts, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onViewHoles() {
        super.onViewHoles();

        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewHoles, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onViewSensors(holeIndex: any) {
        super.onViewSensors(holeIndex);
        this.analyticsService.widgetEvent(AnalyticsEvent.TurfGuardWidgetViewSensors, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    protected setDataLabel() {
        this.averageCourseValueLabel = <string>this.userFormatService.temperature(this.turfGuardWidgetData?.averageCourseValue ?? 0, true);
        this.averageCourseValueUnits = this.userFormatService.temperatureUnits;
        this.alertSensorType = this.translateService.instant('STRINGS.TEMPERATURE').toLowerCase()
    }

    protected get axisValueUnits(): string {
        return `˚`;
    }

    protected getWidgetData() {
        this.turfGuardManager.getTurGuardWidgetData(TurfGuardSensorType.Temperature)
            .pipe(
                take(1),
                finalize(() => this.isWidgetInitialized = true)
            )
            .subscribe((response: TurfGuardWidgetData) => {
                this.clearIsUnableToFetchData();
                this.turfGuardWidgetData = response;
                this.lastUpdateTimestamp = this.turfGuardWidgetData.lastUpdated;
                this.setComponentData();

                this.hasNoData = this.turfGuardWidgetData.holesCount === 0;
                if (this.turfGuardWidgetData.holesCount === 0) { this.isBusy = false; }

                // If chart is displayed, do not set isBusy here. It will be set when the chart loads (in the base class)
                if (this.displayCols === 1) { this.isBusy = false; }
            }, error => {
                this.isUnableToFetchData = true;
                this.isBusy = false;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setGaugeValues() {
        if (!this.gauge || !this.gauge.series) { return; }

        // Gauge Value
        this.gauge.series[0].points[0].update(<number>this.userFormatService.temperature(this.turfGuardWidgetData.averageCourseValue));

        const minValue = <number>this.userFormatService.temperature(this.turfGuardWidgetData.minValue);
        const maxValue = <number>this.userFormatService.temperature(this.turfGuardWidgetData.maxValue);
        const lowAlertLevel = <number>this.userFormatService.temperature(this.turfGuardWidgetData.lowAlertLevel);
        const highAlertLevel = <number>this.userFormatService.temperature(this.turfGuardWidgetData.highAlertLevel);

        // Gauge Range
        this.gauge.yAxis[0].update({ min: minValue, max: maxValue });

        // Plot Bands
        this.updatePlotBand(this.gauge, 'low', minValue, lowAlertLevel, GaugeColor.Low);
        this.updatePlotBand(this.gauge, 'mid', lowAlertLevel, highAlertLevel, GaugeColor.Mid);
        this.updatePlotBand(this.gauge, 'high', highAlertLevel, maxValue, GaugeColor.High);

        this.gauge.reflow();
        this.isGaugeLoaded = true;
    }

    get chartDataValues(): number[] {
        return this.turfGuardWidgetData.chartDataValues.map(v => <number>this.userFormatService.temperature(v));
    }

    get chartMinValue(): number {
        return <number>this.userFormatService.temperature(this.turfGuardWidgetData.minValue);
    }

    get chartMaxValue(): number {
        return <number>this.userFormatService.temperature(this.turfGuardWidgetData.maxValue);
    }

    get chartDataLimits(): any {
        return this.turfGuardWidgetData.chartDataLimits.map(l => {
            return { low: this.userFormatService.temperature(l.low), high: this.userFormatService.temperature(l.high) };
        });
    }

    get chartValueUnits(): string {
        return `˚${this.userFormatService.temperatureUnits}`;
    }
}
