import { ApiService, HttpMethod } from '../_common/api.service';
import { DashApiLoginInfo } from './models/dash-api-login-info.model';
import { DashApiRefreshTokenLoginInfo } from './models/dash-api-refresh-token-login-info.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MyTurfCodeLoginInfo } from './models/my-turf-code-login-info.model';
import { Observable } from 'rxjs';
import { TokenKeyModel } from './models/token-key.model';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    dashApiLogin(apiLoginInfo: DashApiLoginInfo): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.loginWithSsoInfoUrl, HttpMethod.Post, apiLoginInfo);
    }

    dashApiLoginWithRefreshToken(apiRefreshTokenLoginInfo: DashApiRefreshTokenLoginInfo): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.loginWithSsoInfoUrl, HttpMethod.Post, apiRefreshTokenLoginInfo);
    }

    loginWithMyTurfCode(myTurfCodeLoginInfo: MyTurfCodeLoginInfo) {
        return this.apiService.apiRequest<any>(AuthApiService.loginWithMyTurfCodeUrl, HttpMethod.Post, myTurfCodeLoginInfo);
    }

    getTrackingToken(): Observable<TokenKeyModel> {
        return this.apiService.apiRequest<any>(AuthApiService.trackingTokenUrl, HttpMethod.Get)
            .pipe(map(result => new TokenKeyModel(result)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}auth`; }

    private static get loginWithSsoInfoUrl(): string { return `${AuthApiService.baseUrl}/token`; }

    private static get loginWithMyTurfCodeUrl(): string { return `${AuthApiService.baseUrl}/token`; }

    private static get trackingTokenUrl(): string { return `${AuthApiService.baseUrl}/trackingToken`; }

}
