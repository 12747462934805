import { IWidgetDataSettings } from '../../../../../api/_common/interfaces/widget-data-settings.interface';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import WidgetType = ToroEnums.WidgetType;
import WeatherAverageSources = ToroEnums.WeatherAverageSources;

export class WeatherAveragesConfig implements IWidgetDataSettings {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (!json.selectedSourcesIds) {
                this.selectedSourceIds.push(WeatherAverageSources.ToroDtnWeather);
            }
        }
    }

    isWeatherAveragesConfig = true;

    widgetType: WidgetType;
    showStaleDataAlert: boolean;
    dataRefreshIntervalMinutes: number;
    staleDataThresholdMinutes: number;
    selectedSourceIds: number[] = [];
}
