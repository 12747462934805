import { Observable, of } from 'rxjs';
import { AppInjector } from '../../demo/demo.module';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { PlaybooksApiService } from './playbooks-api.service';
import { PlaybooksConfig } from './models/playbooks-config.model';
import { PlaybooksGddSinceAppRecord } from './models/playbooks-gdd-since-app-record.model';
import { PlaybooksLinkUrl } from './models/playbooks-link-url.model';
import { PlaybooksMonthlyReportMonth } from './models/playbooks-monthly-report-month.model';
import { PlaybooksPrintLogUrl } from './models/playbooks-print-log-url.model';
import { PlaybooksYearlyReport } from './models/playbooks-yearly-report.model';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';

@Injectable({
    providedIn: 'root'
})
export class PlaybooksManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private playbooksApiService: PlaybooksApiService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
        }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getPlaybooksConfig(toroGridsterWidget: ToroGridsterWidget): Observable<PlaybooksConfig> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.playbooksConfig); }

        if (toroGridsterWidget && toroGridsterWidget.config.hasOwnProperty('selectedNutrientAreaId')) {
            return of(<PlaybooksConfig>toroGridsterWidget.config);
        }

        const config = new PlaybooksConfig(toroGridsterWidget.config);
        return of(config);
    }

    getGDDSinceApp(): Observable<PlaybooksGddSinceAppRecord[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.playbooksGDDSinceApp); }
        return this.playbooksApiService.getGDDSinceApp();
    }

    getLinkUrls(): Observable<PlaybooksLinkUrl[]> {
        if (environment.isDemoMode) { return  of(this.demoModeMockDataService.playbooksUrls); }
        return this.playbooksApiService.getLinkUrls();
    }

    getLogsToPrint(): Observable<PlaybooksPrintLogUrl[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.playbooksLogsToPrint); }
        return this.playbooksApiService.getLogsToPrint();
    }

    getMonthlyReport(): Observable<PlaybooksMonthlyReportMonth[]> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.playbooksMonthlyReport); }
        return this.playbooksApiService.getMonthlyReport();
    }

    getYearlyReport(): Observable<PlaybooksYearlyReport> {
        if (environment.isDemoMode) { return of(this.demoModeMockDataService.playbooksYearlyReport); }
        return this.playbooksApiService.getYearlyReport();
    }

    processPlaybooksData(): Observable<any> {
        return this.playbooksApiService.processPlaybooksData();
    }

    setCourseId(courseId: string): Observable<any> {
        return this.playbooksApiService.setCourseId(courseId);
    }

}
