/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Component({
    selector: 'toro-component-shield',
    templateUrl: './component-shield.component.html',
    styleUrls: ['./component-shield.component.less']
})
export class ComponentShieldComponent {
    @HostListener('mousedown', ['$event'])
    onMousedown(event) {
        // NOTE: This will not prohibit the handleClick() event from firing.
        event.preventDefault();
    }

    @HostBinding('class') class = 'toro-component-shield';

    @Output() shieldClick = new EventEmitter();

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    handleClick() {
        if (this.shieldClick.observers.length < 1) { return; }
        this.shieldClick.emit();
    }
}
