import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import NsnError = ToroEnums.NsnError;

@Component({
    selector: 'toro-nsn-error',
    templateUrl: './nsn-error.component.html',
    styleUrls: ['./nsn-error.component.less']
})
export class NsnErrorComponent implements OnInit {
    @HostBinding('class') class = 'toro-flex-child';

    reason: string;

    constructor(private route: ActivatedRoute,
                private authManager: AuthManagerService
    ) { }

    ngOnInit(): void {
        this.setReason(+this.route.snapshot.params?.reason);
    }

    onRetry() {
        setTimeout(() => window.open(window.location.origin, '_self'));
    }

    onClose() {
        this.authManager.startOauthSignOut();
    }

    setReason(reason: NsnError) {
        switch (reason) {
            case NsnError.NsnDashAccessError:
                this.reason = 'ERR_MSG.ACTIVE_ACCOUNT_REQUIRED';
                break;
            case NsnError.NsnLoginError:
                this.reason = 'ERR_MSG.UNABLE_TO_RETRIEVE_NSN_DATA';
                break;
            default:
                this.reason = '';
                break;
        }
    }
}
