import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { control, latLng, tileLayer, TileLayer } from 'leaflet';
import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { GreenSiteManagerService } from '../../../../../api/green-sight/green-site-manager.service';
import { KittyHawkGreenMapInfo } from '../../../../../api/green-sight/models/kitty-hawk-green-map-info.model';
import { KittyHawkLayer } from '../../../../../api/green-sight/models/kitty-hawk-layer.model';
import { KittyHawkMission } from '../../../../../api/green-sight/models/kitty-hawk-mission.model';
import { take } from 'rxjs/operators';

@Component({
    selector: 'toro-green-site-mini-map',
    templateUrl: './green-site-mini-map.component.html',
    styleUrls: ['./green-site-mini-map.component.less']
})
export class GreenSiteMiniMapComponent implements OnInit {
    @Output() mapClick = new EventEmitter<KittyHawkGreenMapInfo>();
    @Output() deleteMap = new EventEmitter<{ id: string, name: string; }>();

    @Input() attribution: string;
    @Input() latitude: number;
    @Input() longitude: number;
    @Input() zoom: number;
    @Input() missions: KittyHawkMission[];
    @Input() canDeleteMap = false;
    @Input() mapId: string;

    private _layerName: string;
    @Input() set layerName(value: string) {
        if (this._layerName === value) return;

        this._layerName = value;
        this.getGsaLayers();
    }

    get layerName(): string {
        return this._layerName;
    }

    private _selectedDate: Date;
    @Input() set selectedDate(value: Date) {
        if (this._selectedDate === value) { return; }

        this._selectedDate = value;
        this.getGsaLayers();
    }

    get selectedDate(): Date {
        return this._selectedDate;
    }

    leafletOptions: {};
    layers;

    private currentKittyHawkLayer: any;
    private leafletMap: any;
    private extendedTileLayer: any;
    private tileLayers: {};

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private greenSiteManager: GreenSiteManagerService
    ) { }

    ngOnInit(): void {
        this.configureMap();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onMapReady(leafletMap: any) {
        this.leafletMap = leafletMap;
        this.setExtendedTileLayer();
        this.getGsaLayers();

        this.leafletMap.addControl(
            control.attribution({
                position: 'bottomright',
                prefix: ''
            })
        );
    }

    onMapClick(event: any) {
        this.mapClick.emit(new KittyHawkGreenMapInfo(this.latitude, this.longitude, this.zoom));
    }

    onDeleteClick(event: any) {
        event.cancelBubble = true;
        this.deleteMap.emit({id: this.mapId, name: this.attribution});
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private configureMap() {
        this.leafletOptions = {
            layers: [
                tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                    opacity: 0.6,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    attribution: this.attribution || ''
                })
            ],
            zoom: this.zoom - 3,
            zoomControl: false,
            scrollWheelZoom: false,
            attributionControl: false,
            center: latLng(this.latitude, this.longitude),
            dragging: false,
        };
    }

    private getGsaLayers() {
        if (!this.leafletMap) { return; }

        this.tileLayers = {};

        // Remove any previously added layers
        if (this.leafletMap && this.currentKittyHawkLayer) {
            this.leafletMap.removeLayer(this.currentKittyHawkLayer);
        }

        // Get the first mission for selectedDate.
        const mission = this.missions.find(m => moment(m.created).isSame(this.selectedDate, 'day'));
        if (!mission) { return; }

        this.greenSiteManager.getLayers(mission.uuid)
            .pipe(take(1))
            .subscribe(response => {
                response.forEach((r: KittyHawkLayer) => {
                    const uri = `https://api.greensightag.com/mission/${r.mission_uuid}/map/${r.map_type_id}/tile/{z}/{x}/{y}`;

                    this.tileLayers[r.map_type.tab_name] = new this.extendedTileLayer(uri, {
                        zoom: this.zoom - 3,
                        tileSize: 256,
                        tms: true,
                        opacity: 1.0,
                    });

                    if (r.map_type.tab_name === this.layerName) {
                        this.currentKittyHawkLayer = this.tileLayers[r.map_type.tab_name];
                        this.tileLayers[r.map_type.tab_name].addTo(this.leafletMap);
                    }
                });
            }, error => {
                // this.dashMessageService.showGenericFetchErrorMessage();
            });
    }

    private setExtendedTileLayer() {
        const gsaToken = this.authManager.dashAuthenticatedUser.gsaToken;

        this.extendedTileLayer = <any>TileLayer.extend({
            createTile(coords) {
                const tile = document.createElement('img');
                const xhr = new XMLHttpRequest();
                xhr.open('GET', this.getTileUrl(coords), true);
                xhr.responseType = 'blob';
                xhr.setRequestHeader('Accept', 'image/png');
                xhr.setRequestHeader('Authorization', 'bearer ' + gsaToken);
                xhr.onload = function(e) {
                    if (this.status === 200) {
                        const blob = this.response;
                        tile.src = (window.URL || window.webkitURL).createObjectURL(blob);
                    } else {
                        tile.style.display = 'none';
                    }
                };
                xhr.send();
                return tile;
            }
        });
    }
}
