import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import * as moment from 'moment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { SpecFsProfile, SpecPaletteDefinitionItem } from '../../../../../api/spectrum/models/spec-fs-profile.model';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DashUserPreferences } from '../../../../../api/dash-user/models/dash-user-preferences.model';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { GoogleMapLocation } from '../../../../../api/_common/models/google-map-location.model';
import { SelectItem } from 'primeng/api';
import { SpecFsCollection } from '../../../../../api/spectrum/models/spec-fs-collection.model';
import { SpecFsCollectionItem } from '../../../../../api/spectrum/models/spec-fs-collections.model';
import { SpecFsGridData } from '../../../../../api/spectrum/models/spec-fs-grid-data.model';
import { SpecFsSession } from '../../../../../api/spectrum/models/spec-fs-Session.model';
import { SpecFsSessionData } from '../../../../../api/spectrum/models/spec-fs-session-data.model';
import { SpecRangeCount } from '../models/spec-range-count.model';
import { SpecSubCollectionSummary } from '../models/spec-sub-collection-summary.model';
import { SpectrumConstants } from '../../../../../common/constants/spectrum.constants';
import { SpectrumManagerService } from '../../../../../api/spectrum/spectrum-manager.service';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroGridsterWidget } from '../../toro-gridster-widget';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UserFormatService } from '../../../../../common/services/user-format.service';


@Component({
    selector: 'toro-widget-spectrum-moisture',
    templateUrl: './widget-spectrum-moisture.component.html',
    styleUrls: ['./widget-spectrum-moisture.component.less']
})
export class WidgetSpectrumMoistureComponent extends ToroDashboardWidget implements OnInit {
    @ViewChild('gridContainer') gridContainer: ElementRef;

    iconColor = 'black';
    title = 'WIDGET.SPECTRUM_MOISTURE';

    // TODO: Temporary
    // private readonly apiKey = '0afbac583e9e3d7e429c183debcf23ef';
    private readonly deviceType = 'tdr350';
    private readonly dataType = 'vwc'

    private dashUserPrefs: DashUserPreferences;

    collections: SpecFsCollection[] = [];
    collectionNames: string[] = [];
    selectedCollection: SpecFsCollection
    selectedSession: SpecFsSession;
    subCollectionNames: string[] = [];
    collectionItems: SpecFsCollectionItem[] = [];
    apiKey: string;
    newApiKey: string;
    isApiKeyDialogDisplayed = false;
    courseListItems: SelectItem[];
    areaListItems: SelectItem[];
    filteredAreaListItems: SelectItem[];
    selectedCourseName: string;
    selectedAreaValue: string;
    selectedSessionTime = '--';

    isFreeFormCourse = false;
    gridData: any;
    gridPalette: SpecPaletteDefinitionItem[] = [];
    gridItemWidth: number;
    gridItemHeight: number;
    gridItems: number;
    gridItemData: SpecFsSessionData[];
    positionedGridItemData: SpecFsSessionData[];
    modalGridItemBasis: number;
    holeAvgMoisture = 0;
    holeAvgMoistureString = '--';
    rangeCounts: SpecRangeCount[] = [];
    subCollectionSummaries: SpecSubCollectionSummary[];
    filteredSubCollection: SpecSubCollectionSummary[] = [];
    pendingTooltipValue: SpecSubCollectionSummary;

    mapOptions: any;
    mapOverlays: any[];
    modalMapOverlays: any[];
    googleMap: any;
    mapMarkers: google.maps.Marker[];
    mapZoomLevel = 20;
    showMap = false;
    isNoMap = false;

    private _showDesktopModal = false;
    set showDesktopModal(value: boolean) {
        this._showDesktopModal = value;
        if (!this._showDesktopModal) {
            this.mapOverlays = [];

            setTimeout(() => {
                // Reapply the map markers and associated click handlers to the map.
                this.mapOverlays = this.mapMarkers;

                this.mapMarkers.forEach(marker => {
                    google.maps.event.addListener(marker, 'click', () => this.mapMarkerClick(marker));
                })
            });

            if (this.displayCols == 1) { this.selectedGridItem = null; }
        }
    }

    get showDesktopModal(): boolean {
        return this._showDesktopModal;
    }

    // FreeForm Related
    _selectedTableItem: SpecSubCollectionSummary;
    set selectedTableItem(value: SpecSubCollectionSummary) {
        if (!this.isFreeFormCourse || (this._selectedTableItem != undefined && this._selectedTableItem == value)) { return; }

        this.selectedAreaValue = value?.name;
        this._selectedTableItem = value;
        this.setGridData(value?.name);
    }

    get selectedTableItem(): SpecSubCollectionSummary {
        return this._selectedTableItem;
    }

    private _selectedGridItem: SpecFsSessionData
    set selectedGridItem(value: SpecFsSessionData) {
        this._selectedGridItem = value;

        if (value != null && !this.showDesktopModal && this.displayCols == 1) {
            this.showDesktopModal = true;
        }

        this.toggleMapMarker(value);
    }

    get selectedGridItem(): SpecFsSessionData {
        return this._selectedGridItem;
    }

    private _selectedRangeIndex = 0;
    set selectedRangeIndex(value: number) {
        if (value == -1 || value == this._selectedRangeIndex) return;

        this._selectedRangeIndex = value;
        this.setFilteredSubCollection();
    }

    get selectedRangeIndex(): number {
        return this._selectedRangeIndex;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private spectrumManager: SpectrumManagerService,
                protected translateService: TranslateService,
                private userFormatService: UserFormatService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setSessionTime();
                this.setUserValues();
                this.setupMap();
            });
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.SpectrumMoistureName;
    }

    protected getWidgetData(isManualRefresh) {
        if (isManualRefresh) { this.isBusy = true; }

        // Try to get Pogo API Key (stored in user preferences)
        this.dashUserManager.getDashUserInfo()
            .pipe(take(1))
            .subscribe({
                next: (dashUserInfo: DashUserInfo) => {
                    this.dashUserPrefs = dashUserInfo.preferences;
                    if (this.dashUserPrefs.spectrumApiKey != null || environment.isDemoMode) {
                        this.apiKey = this.dashUserPrefs.spectrumApiKey;
                        this.getSpectrumData(isManualRefresh);
                        return;
                    }

                    if (this.apiKey == null) {
                        this.isBusy = false;
                        this.setIsUnableToFetchData('STRINGS.PROVIDE_SPECTRUM_API_KEY');
                        return;
                    }

                },
                error: () => {
                    this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);
                }
            });
    }

    private getSpectrumData(isManualRefresh) {
        if (isManualRefresh) { this.isBusy = true; }

        // TODO: TEMPORARY DATE SELECTION
        const startDateUtc = moment.utc('2023-10-01').startOf('day').toDate();
        const endDateUtc = moment.utc('2023-11-30').endOf('day').toDate();

        const sources: Observable<any>[] = [
            this.spectrumManager.getFieldScoutData(this.apiKey, this.deviceType, startDateUtc, endDateUtc).pipe(take(1)),
            this.spectrumManager.getFieldScoutCollections(this.apiKey).pipe(take(1)),
        ]

        forkJoin(sources)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe({
                next: ([fsData, fsCollectionItems]) => {
                    this.collectionItems = fsCollectionItems?.collections;
                    this.collections = fsData.collections;

                    this.courseListItems = [];
                    this.collectionNames = this.collections.map(c => {
                        this.courseListItems.push({ label: c.collectionName, value: c.collectionName });
                        return c.collectionName
                    });

                    this.selectedCourseName = this.courseListItems[0].label;

                    this.clearIsUnableToFetchData();
                    this.setSelectedCollectionInfo();
                    this.lastUpdateTimestamp = new Date();
                },
                error: err => {
                    this.dashMessageService.showWidgetDataFetchErrorMessage(this.title);

                    if (err?.error?.Error_1 == 'Invalid API Key') {
                        this.setIsUnableToFetchData('STRINGS.PROVIDE_SPECTRUM_API_KEY');
                    }
                }
            })
    }

    // TODO: TEMPORARY - REMOVE MENU ITEM TO TOGGLE DISPLAY MODES.

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.SET_SPECTRUM_API_KEY'),
                icon: 'pi pi-fw pi-sign-in',
                command: this.showApiKeyDialog.bind(this)
            },
            // {
            //     label: 'Toggle Display Mode',
            //     icon: 'pi pi-fw pi-table',
            //     command: this.toggleDisplayMode.bind(this)
            // },
            { separator: true }
        );
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        if (this.displayCols == 1) { this.selectedGridItem = null }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onLinkClick() {
        this.broadcastService.toggleSystemOverlay.next({ show: true, text: 'STRINGS.NAVIGATING_TO_SPECTRUM' });
        setTimeout(() => window.open(environment.spectrumSiteUrl, '_blank'), 1000);
        setTimeout(() => this.broadcastService.toggleSystemOverlay.next({ show: false }), 2000);
    }

    onSetApiKey() {
        this.isApiKeyDialogDisplayed = false;

        // Ignore changes to the account id when in demo mode.
        if (environment.isDemoMode) { return; }

        // If the Course Id was not changed, don't do anything.
        if (this.apiKey === this.newApiKey || this.newApiKey == null) { return; }

        this.apiKey = this.newApiKey;
        this.isBusy = true;

        // Store the new Account Id in the user preferences.
        this.dashUserPrefs.spectrumApiKey = this.newApiKey;
        this.dashUserManager.updateDashUserPreferences(this.dashUserPrefs).subscribe();

        setTimeout(() => this.getWidgetData(true), 1000);

        const eventDetails = `API Key: ${this.newApiKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.SpectrumWidgetSavedApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    onCancelApiKeyDialog() {
        this.isApiKeyDialogDisplayed = false
        this.analyticsService.widgetEvent(AnalyticsEvent.SpectrumWidgetCancelApiKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName);
    }

    onCourseChange(event: any) {
        this.setSelectedCollectionInfo(event.value);
    }

    onSubCollectionChange(event: any) {
        if (this.isFreeFormCourse && this.isGridsterInMobileMode) {
            this.selectedTableItem = this.filteredSubCollection.find(item => item.name == event.value);
        }
        this.setGridData(event.value);
    }

    protected onMapReady(event: any) {
        this.googleMap = event.map;
    }

    onShowMiniModal() {
        // Set the gridData to null and re-fetch the data to force the grid to redraw the grid items at the proper size.
        this.gridData = null;
        this.setGridData(this.selectedAreaValue);
        this.showMiniModeModal = true;
    }

    onShowTooltip(item: SpecSubCollectionSummary) {
        if (item == null) {
            // Close the currently displayed tooltip.
            this.mapMarkers.forEach((marker: any) => marker.infoWindow?.close());
            return;
        }

        if (this.displayCols == 1) { this.showDesktopModal = true }

        this.pendingTooltipValue = item;
        this.showPendingTooltip();
    }

    onTableRowClick(item: SpecSubCollectionSummary) {
        if (!this.isFreeFormCourse || this._selectedTableItem != item) { return; }

        this.selectedAreaValue = item.name;

        if (this.displayCols == 1 && !this.isResizing) {
            setTimeout(() => this.showDesktopModal = true);
        }
    }

    protected toggleDisplayMode(event: any) {
        this.isFreeFormCourse = !this.isFreeFormCourse;
        this.setSelectedCollectionInfo(this.selectedCourseName);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setSelectedCollectionInfo(collectionName: string = null) {
        if (this.collections.length > 0) {
            this.selectedCollection = this.collections.find(c => c.collectionName == collectionName) || this.collections[0];

            if (this.selectedCollection.sessions.length > 0) {
                this.isFreeFormCourse = false;
                this.selectedSession = this.selectedCollection.sessions[this.selectedCollection.sessions.length - 1];

                const filteredSessionData = this.selectedSession.sessionData.filter(d => d.dataType == this.dataType);
                if (!this.isFreeFormCourse && filteredSessionData[0].gridRow != null && filteredSessionData[0].gridColumn != null) {
                    this.subCollectionNames = Array.from(new Set(filteredSessionData.map(d => d.subCollectionName))).sort((a, b) => +a - +b);
                } else {
                    this.isFreeFormCourse = true;
                    this.gridItemData = filteredSessionData;
                    if (this.gridItemData[0].subCollectionName == null) {
                        this.groupFreeFormGridItems();
                    }
                    this.subCollectionNames = Array.from(new Set(this.gridItemData.map(d => d.subCollectionName))).sort((a, b) => +a - +b);
                }

                this.setSessionTime();

                this.areaListItems = [];
                this.areaListItems = this.subCollectionNames.map(s => {
                    return { value: s, label: isNaN(+s) ? s : `Hole ${s}` }
                })
            }

            this.getCollectionProfile();

            if (!this.isFreeFormCourse) {
                this.selectedAreaValue = this.areaListItems[0].value;
                this.setGridData(this.selectedAreaValue);
            }
        }
    }

    private buildCourseSummaryData() {
        this.subCollectionSummaries = [];

        // Build Sub Collection Summaries
        this.subCollectionNames.forEach(name => {
            const items = name != undefined
                ? this.selectedSession.sessionData.filter(d => d.subCollectionName == name && d.dataType == this.dataType)
                : this.selectedSession.sessionData.filter(d => d.dataType == this.dataType)
            if (items == null) return;

            const avgMoisture = items.reduce((acc, item) => acc + item.decimalDataValue, 0) / items.length;
            const lowMoisture = items.reduce((max, item) => Math.min(max, item.decimalDataValue), items[0]?.decimalDataValue ?? Infinity);

            this.subCollectionSummaries.push(new SpecSubCollectionSummary({
                name: name,
                avgMoisture: avgMoisture,
                highMoisture: items.reduce((max, item) => Math.max(max, item.decimalDataValue), items[0]?.decimalDataValue ?? 0),
                lowMoisture: lowMoisture,
                rangeIndex: this.gridPalette.findIndex(p => p.lowValue <= avgMoisture && p.highValue >= avgMoisture) || 99,
                lowColor: this.gridPalette.find(p => p.lowValue <= lowMoisture && p.highValue >= lowMoisture)?.color || 'gray'
            }));
        })

        this.setFilteredSubCollection();

        // Build Range Counts
        this.rangeCounts = [];
        for (let i = 0; i < this.gridPalette.length; i++) {
            this.rangeCounts.push(new SpecRangeCount({
                count: this.subCollectionSummaries.reduce((acc, item) => { return (item.rangeIndex == i) ? acc + 1 : acc; }, 0),
                color: this.gridPalette[i].color
            }))
        }

        this.selectedRangeIndex = 0;
    }

    private setGridData(subCollectionName: string) {
        this.isNoMap = false;
        this.selectedGridItem = null;
        this.gridItemData = this.selectedSession.sessionData.filter(d => d.subCollectionName == subCollectionName && d.dataType == this.dataType);

        if (this.gridItemData != null && this.gridItemData.length > 0) {

            // Grid View
            if (this.gridItemData[0].gridIdentifier != null) {
                this.spectrumManager.getFieldScoutGridData(this.apiKey, this.gridItemData[0].gridIdentifier)
                    .pipe(take(1))
                    .subscribe({
                        next: (result: SpecFsGridData) => {
                            this.gridData = result;
                            if (this.gridData != null) {
                                const containerWidth = this.gridContainer?.nativeElement.offsetWidth || 0;
                                const containerHeight = this.gridContainer?.nativeElement.offsetHeight || 0;

                                this.gridItems = this.gridData.gridDefinition.rows * this.gridData.gridDefinition.columns;
                                this.gridItemWidth = Math.floor(containerWidth / this.gridData.gridDefinition.columns) - 5;
                                this.gridItemHeight = Math.floor(containerHeight / this.gridData.gridDefinition.rows) - 5;

                                // Calculate the flex basis for modal grid cards. Subtract 2 to account for margin/padding;
                                this.modalGridItemBasis = 100 / this.gridData.gridDefinition.columns - 2;

                                // Position the grid data. We don't always get entries for each row/col in the grid.
                                let aggregateHoleMoisture = 0;
                                this.positionedGridItemData = [];
                                for (let r = 0; r < result.gridDefinition.rows; r++) {
                                    for (let c = 0; c < result.gridDefinition.columns; c++) {
                                        const itemData = this.gridItemData.find(item => item.gridRow == r + 1 && item.gridColumn == c + 1);
                                        if (itemData != null) { aggregateHoleMoisture += itemData.decimalDataValue}

                                        this.positionedGridItemData.push(itemData || new SpecFsSessionData({}));
                                    }
                                }

                                this.holeAvgMoisture = aggregateHoleMoisture / this.gridItemData.length;

                                this.setUserValues();
                                this.setupMap();

                                if (this.isFreeFormCourse && this.selectedTableItem.showPopup) {
                                    this.selectedTableItem.showPopup = false;
                                    setTimeout(() => this.showDesktopModal = true);
                                }
                            }
                        },
                        error: err => {
                            this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.title, "Unable to retrieve grid data.");
                        }
                    })
            } else {
                // Free Form
                this.isFreeFormCourse = true;
                this.gridItems = null;

                // Group Free Form Grid Items
                if (this.gridItemData[0].subCollectionName == null) {
                    this.groupFreeFormGridItems();
                }

                this.setUserValues();
                this.setupMap();

                if (this.isFreeFormCourse && this.selectedTableItem.showPopup) {
                    this.selectedTableItem.showPopup = false;
                    setTimeout(() => this.showDesktopModal = true);
                }
            }
        } else {
            this.setNoMap();
        }
    }

    private groupFreeFormGridItems(): void {
        const groups = this.groupGeoObjects(this.gridItemData, 75);

        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].length; j++) {
                groups[i][j].subCollectionName = `${this.translateService.instant('STRINGS.GROUP')} ${i + 1}`;
            }
        }
    }

    private haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const toRadians = (degree: number) => degree * (Math.PI / 180);
        const R = 6371e3; // Earth's radius in meters

        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δφ = toRadians(lat2 - lat1);
        const Δλ = toRadians(lon2 - lon1);

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // in meters
        return distance;
    }

    public groupGeoObjects(objects: SpecFsSessionData[], maxDistance: number): SpecFsSessionData[][] {
        const groups: SpecFsSessionData[][] = [];
        const visited: boolean[] = Array(objects.length).fill(false);

        const distanceThreshold = maxDistance * 0.3048; // converting feet to meters

        const findGroup = (index: number, group: SpecFsSessionData[]) => {
            visited[index] = true;
            group.push(objects[index]);

            for (let i = 0; i < objects.length; i++) {
                if (!visited[i]) {
                    const distance = this.haversineDistance(
                        objects[index].gpsLatitude,
                        objects[index].gpsLongitude,
                        objects[i].gpsLatitude,
                        objects[i].gpsLongitude
                    );
                    if (distance <= distanceThreshold) {
                        findGroup(i, group);
                    }
                }
            }
        };

        for (let i = 0; i < objects.length; i++) {
            if (!visited[i]) {
                const group: SpecFsSessionData[] = [];
                findGroup(i, group);
                groups.push(group);
            }
        }

        return groups;
    }


    private setNoMap() {
        this.isNoMap = true;
        this.googleMap = null;
        this.mapOptions = null;
        this.mapOverlays = null;
    }

    private setUserValues() {
        this.positionedGridItemData.forEach(item => {
            item.dataValue = this.userFormatService.toUserNumber(item.decimalDataValue, 0, 1);
        })

        this.holeAvgMoistureString = this.userFormatService.toUserNumber(this.holeAvgMoisture, 0, 1);
    }

    private getCollectionProfile() {
        if (this.selectedCollection == null) return;

        const profileId = this.collectionItems?.find(i => i.name == this.selectedCollection.collectionName)?.profileId || 0;

        this.spectrumManager.getFieldScoutProfile(this.apiKey, profileId)
            .pipe(take(1))
            .subscribe({
                next: (result: SpecFsProfile) => {
                    this.gridPalette = result?.deviceProfiles.find(p => p.deviceType == this.deviceType)?.dataTypeProfiles?.find(p => p.dataType == this.dataType)?.paletteDefinition;
                    this.buildCourseSummaryData();
                },
                error: err => {
                    this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.title, "Unable to retrieve collection profile.");
                }
            })
    }

    private showApiKeyDialog() {
        this.newApiKey = this.apiKey;
        this.isApiKeyDialogDisplayed = true;

        const eventDetails = `API Key: ${this.apiKey}`;
        this.analyticsService.widgetEvent(AnalyticsEvent.SpectrumWidgetShowApkKeyDialog, AnalyticsCategory.Interaction, this.analyticsWidgetName, eventDetails);
    }

    private setSessionTime() {
        this.selectedSessionTime = `${this.userFormatService.toUserDateString(this.selectedSession.sessionStartTime)}  ${this.userFormatService.toUserTimeString(this.selectedSession.sessionStartTime)}`;
    }

    private findCenter(data: SpecFsSessionData[]) {
        data = data.filter(d => d.gpsLongitude != null);

        if (data.length == 1) {
            return {
                lat: data[0].gpsLatitude,
                lon: data[0].gpsLongitude
            };
        }

        const total = data.reduce((acc, location) => {
            acc.gpsLatitude += location.gpsLatitude;
            acc.gpsLongitude += location.gpsLongitude;
            return acc;
        }, { gpsLatitude: 0, gpsLongitude: 0 });

        return {
            lat: total.gpsLatitude / data.length,
            lon: total.gpsLongitude / data.length,
        }
    }

    private setupMap() {
        this.showMap = false;

        setTimeout(() => this.mapOptions = null);

        // Place in setTimeout to give css transition time to complete.
        setTimeout(() => {
            const centerPoint = this.findCenter(this.gridItemData);
            const location = new GoogleMapLocation({
                lat: centerPoint.lat,
                lng: centerPoint.lon
            });

            this.mapOptions = {
                center: location,
                zoom: this.mapZoomLevel,
                minZoom: this.mapZoomLevel,
                maxZoom: this.mapZoomLevel,
                mapTypeControl: false,
                mapTypeId: 'satellite',
                gestureHandling: 'cooperative',
                scaleControl: false,
                zoomControl: false,
                rotateControl: false,
                fullscreenControl: false,
                streetViewControl: false
            };

            this.mapMarkers = [];

            this.gridItemData.forEach(item => {
                let svgMarker = SpectrumConstants.map_marker;
                svgMarker = svgMarker.replace(/currentColor/g, this.getMarkerColor(item.decimalDataValue));

                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(item.gpsLatitude, item.gpsLongitude),
                    map: this.googleMap,
                    icon: {
                        url: `data:image/svg+xml;charset=UTF-8, ${encodeURIComponent(svgMarker)}`,
                        scaledSize: new google.maps.Size(25, 25),
                    }
                });
                marker['item'] = item;

                // Create and add the InfoWindow to the marker.
                const infoWindow = new google.maps.InfoWindow({ content: `${item.dataValue}` });
                infoWindow.addListener('closeclick', () => this.selectedGridItem = null);
                marker['infoWindow'] = infoWindow;

                this.mapMarkers.push(marker);

                google.maps.event.addListener(marker, 'click', () => {
                    this.mapMarkerClick(marker);
                });

                // google.maps.event.addListener(marker, 'mouseover', () => {
                //     this.mapMarkerMouseOver(marker);
                // });
                //
                // google.maps.event.addListener(marker, 'mouseout', () => {
                //     this.mapMarkerMouseOut(marker);
                // });

            });

            this.mapOverlays = this.mapMarkers;
            this.modalMapOverlays = [...this.mapOverlays];

            // this.lastUpdateTimestamp = new Date();
            // this.isManualDataRefresh = false;

            setTimeout(() => {
                this.showMap = true;

                if (this.isFreeFormCourse && this.selectedTableItem.showTooltip) {
                    this.pendingTooltipValue = this.selectedTableItem;
                    this.showPendingTooltip();
                }
            }, 500);
        }, 500);
    }

    private showPendingTooltip() {
        if (this.pendingTooltipValue == null) { return; }

        this.pendingTooltipValue.showTooltip = false;

        const gridItem = this.gridItemData.find(item => item.subCollectionName == this.pendingTooltipValue.name && item.decimalDataValue == this.pendingTooltipValue.lowMoisture);
        if (gridItem == null) return;

        this.toggleMapMarker(gridItem)

        this.pendingTooltipValue = null;
    }

    private getMarkerColor(value: number): string {
        return this.gridPalette.find(p => p.lowValue <= value && p.highValue >= value)?.color || 'black';
    }

    mapMarkerClick(marker) {
        this.selectedGridItem = marker.item;
    }

    private toggleMapMarker(item: SpecFsSessionData) {
        if (this.mapMarkers == null) return;

        this.mapMarkers.forEach((marker: any) => marker.infoWindow?.close())

        const selectedMarker: any = this.mapMarkers.find(m => m['item'] == item)
        selectedMarker?.infoWindow.open(this.googleMap, selectedMarker);
    }

    private setFilteredSubCollection() {
        if (this.subCollectionSummaries == null || this.subCollectionSummaries.length < 1) return;

        this.filteredSubCollection = this.subCollectionSummaries.filter(c => c.rangeIndex == this.selectedRangeIndex);

        if (this.isFreeFormCourse) {
            this.areaListItems = [];
            this.areaListItems = this.filteredSubCollection.map(s => {
                return {
                    value: s.name,
                    label: (isNaN(+s.name) ? (s.name != undefined
                        ? s.name : this.translateService.instant('STRINGS.ALL'))
                        : `${this.translateService.instant('STRINGS.HOLE')} ${s.name}`) }
            })
        }

        this.selectedTableItem = this.filteredSubCollection?.length > 0 ? this.filteredSubCollection[0] : null;
    }
}
