export class KittyHawkGeoJson {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    type: string;
    coordinates: number[][];
}
