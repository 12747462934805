import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MyTurfPartsOrder } from './models/my-turf-parts-order.model';
import { MyTurfTokenInfo } from '../auth/models/my-turf-token-info.model';
import { MyTurfUnavailableAssets } from './models/my-turf-unavailable-assets.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MyTurfApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiEndpoint(): Observable<string> {
        return this.apiService.apiRequest<any>(MyTurfApiService.apiEndpointUrl, HttpMethod.Get)
            .pipe(map(result => result.content));
    }

    getPartsOrders(): Observable<MyTurfPartsOrder[]> {
        return this.apiService.apiRequest<any>(MyTurfApiService.partsOrdersUrl, HttpMethod.Get)
            .pipe(map((response: MyTurfPartsOrder[]) => response.map(order => new MyTurfPartsOrder(order))));
    }

    getToken(): Observable<MyTurfTokenInfo> {
        return this.apiService.apiRequest<any>(MyTurfApiService.tokenUrl, HttpMethod.Get)
            .pipe(map((response: MyTurfTokenInfo) => new MyTurfTokenInfo(response)));
    }

    getUnavailableAssets(): Observable<MyTurfUnavailableAssets[]> {
        return this.apiService.apiRequest<any>(MyTurfApiService.unavailableAssetsUrl, HttpMethod.Get)
            .pipe(map((response: MyTurfUnavailableAssets[]) => response.map(asset => new MyTurfUnavailableAssets(asset))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}myturf`; }

    private static get apiEndpointUrl(): string { return `${MyTurfApiService.baseUrl}/apiEndpoint`; }

    private static get partsOrdersUrl(): string { return `${MyTurfApiService.baseUrl}/partsOrders`; }

    private static get tokenUrl(): string { return `${MyTurfApiService.baseUrl}/token`; }

    private static get unavailableAssetsUrl(): string { return `${MyTurfApiService.baseUrl}/unavailableAssets`; }
}
