import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { YouTubeVideoInfo } from '../models/you-tube-video-info.model';

@Component({
    selector: 'toro-video-panel-card',
    templateUrl: './video-panel-card.component.html',
    styleUrls: ['./video-panel-card.component.less']
})
export class VideoPanelCardComponent implements OnInit {

    @Input() videoInfo: YouTubeVideoInfo;

    protected showDesktopModal = false;
    protected thumbNailUrl: SafeResourceUrl;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.thumbNailUrl = this.getSafeThumbUrl(this.videoInfo.videoId);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    toggleDesktopModal() {
        this.showDesktopModal = true;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getSafeThumbUrl(videoId: string) {
        let thumbUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(thumbUrl);
    }
}
