import { Component, Input } from '@angular/core';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;

@Component({
    selector: 'toro-asset-status-icon',
    templateUrl: './asset-status-icon.component.html',
    styleUrls: ['./asset-status-icon.component.less']
})
export class AssetStatusIconComponent {

    private _assetStatus: CalAmpDeviceStatus;
    get assetStatus(): CalAmpDeviceStatus {
        return this._assetStatus;
    }

    @Input() set assetStatus(value: CalAmpDeviceStatus) {
        if (this._assetStatus === value) { return; }
        this._assetStatus = value;
        this.setIcon();
    }

    label = '';
    color = 'gray';
    isCircle = false;
    isOff = false;
    isNeedsAttention = false;
    isNotUsed = false;
    isUnavailable = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setIcon() {
        this.resetStatus();

        switch (this.assetStatus) {
            case CalAmpDeviceStatus.Off:
                this.isOff = true;
                this.label = 'STRINGS.OFF';
                break;
            case CalAmpDeviceStatus.Idle:
                this.isCircle = true;
                this.label = 'STRINGS.IDLE';
                this.color = 'orange';
                break;
            case CalAmpDeviceStatus.Transport:
                this.isCircle = true;
                this.label = 'STRINGS.TRANSPORTING';
                this.color = 'blue';
                break;
            case CalAmpDeviceStatus.Work:
                this.isCircle = true;
                this.label = 'STRINGS.WORKING';
                this.color = 'green';
                break;
            // case AssetTrackingAssetStatus.NeedsAttention:
            //     this.isNeedsAttention = true;
            //     this.label = 'STRINGS.NEEDS_ATTENTION';
            //     break;
            // case AssetTrackingAssetStatus.NotUsed:
            //     this.isNotUsed = true;
            //     this.label = 'STRINGS.NOT_USED';
            //     break;
            // case AssetTrackingAssetStatus.Unavailable:
            //     this.isUnavailable = true;
            //     this.label = 'STRINGS.UNAVAILABLE';
            //     break;
            // case AssetTrackingAssetStatus.On:
            //     this.isCircle = true;
            //     this.label = 'STRINGS.ON';
            //     this.color =  '#ffd800';
            //     break;
        }
    }

    private resetStatus() {
        this.isCircle = false;
        this.isOff = false;
        this.isNeedsAttention = false;
        this.isNotUsed = false;
        this.isUnavailable = false;
    }
}
