/* eslint-disable @typescript-eslint/dot-notation */
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;
import * as moment from 'moment';
import { Duration } from 'moment';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { ToroStartingTime } from '../../_common/models/toro-starting-time.model';
import { TurfGuardSensorAlert } from './turf-guard-sensor-alert.model';

export class TurfGuardSensorDetail {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.Interval) { this.interval = moment.duration(json.Interval); }
            if (json.StartingTime) { this.startingTime = new ToroStartingTime(json.StartingTime); }
            if (json.Alert) { this.alert = new TurfGuardSensorAlert(json.Alert); }
        }
    }

    interval: Duration;
    invalidValue: number;
    startingTime: ToroStartingTime;
    units: any;
    values: number[];
    sensorType: TurfGuardSensorType;
    displayValue: number;
    parentNode: any;
    alert: TurfGuardSensorAlert;
    limits: any;
    name: string;
    id: number;

}
