
export class PogoLocation {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    lat: number;
    lng: number;
}
