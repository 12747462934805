interface String {
    toTitleCase(value: string): string;
    pascalCaseToCamelCase(value?: string): string;
}

String.prototype.toTitleCase = function() {
    return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

String.prototype.pascalCaseToCamelCase = function() {
        return this.substr(0, 1).toLowerCase() + this.substr(1);
};
