import { ApiService, HttpMethod } from '../_common/api.service';
import { EtResult } from './models/et-result.model';
import { FrostRiskData } from './models/frost-risk-data.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalWeatherForecast } from './models/local-weather-forecast.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WeatherGraphsData } from './models/weather-graphs-data.model';

@Injectable({
    providedIn: 'root'
})
export class WeatherApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getEvapotranspiration(): Observable<EtResult> {
        return this.apiService.apiRequest<any>(WeatherApiService.evapotranspirationUrl, HttpMethod.Get)
            .pipe(map((response: EtResult) => new EtResult(response)));
    }

    getFrostRisk(): Observable<FrostRiskData> {
        return this.apiService.apiRequest<any>(WeatherApiService.frostRiskUrl, HttpMethod.Get)
            .pipe(map((response: FrostRiskData) => {
                return new FrostRiskData(response);
            }));
    }

    getLocalWeatherForecast(): Observable<LocalWeatherForecast> {
        return this.apiService.apiRequest<any>(WeatherApiService.localWeatherForecastUrl, HttpMethod.Get)
            .pipe(map((response: LocalWeatherForecast) => new LocalWeatherForecast(response)));
    }

    getWeatherForecast(): Observable<any> {
        return this.apiService.apiRequest<any>(WeatherApiService.weatherForecastUrl, HttpMethod.Get)
            .pipe(map((response: LocalWeatherForecast) => new LocalWeatherForecast(response)));
    }

    forceWeatherDataUpdate(siteId: number): Observable<any> {
        return this.apiService.apiRequest<any>(WeatherApiService.getForceWeatherDataUpdateUrl(siteId), HttpMethod.Get)
    }

    getWeatherGraphsData(): Observable<WeatherGraphsData> {
        return this.apiService.apiRequest<any>(WeatherApiService.weatherGraphsUrl, HttpMethod.Get)
            .pipe(map((response: WeatherGraphsData) => new WeatherGraphsData(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}weather`; }

    private static get evapotranspirationUrl(): string { return `${WeatherApiService.baseUrl}/evapotranspiration`; }

    private static get frostRiskUrl(): string { return `${WeatherApiService.baseUrl}/frostRisk`; }

    private static get localWeatherForecastUrl(): string { return `${WeatherApiService.baseUrl}/localWeatherForecast`; }

    private static get weatherForecastUrl(): string { return `${WeatherApiService.baseUrl}/weatherForecast`; }

    private static get weatherGraphsUrl(): string { return `${WeatherApiService.baseUrl}/weatherGraphs`; }

    private static getForceWeatherDataUpdateUrl(siteId: number): string {
        return `${WeatherApiService.baseUrl}/forceProcessPendingWeather?siteId=${siteId}`;
    }
}
