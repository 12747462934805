import { GenericMqttMessageContent } from '../generic-mqtt-message-content.model';
import { GetCoursesCourse } from './get-courses-course.model';

export class GetCoursesContent extends GenericMqttMessageContent {

    constructor(json: any = null) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.result) this.result = json.result.map(c => new GetCoursesCourse({...c, installationId: json.installationId}));
        }
    }

    result: GetCoursesCourse[];
}
