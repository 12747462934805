export class TurfCloudLaborStat {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    job1: string;
    job2: string;
    job3: string;

}
