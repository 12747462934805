import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'toro-turf-guard-mini-mode-gauge',
    templateUrl: './turf-guard-mini-mode-gauge.component.html',
    styleUrls: ['./turf-guard-mini-mode-gauge.component.less']
})
export class TurfGuardMiniModeGaugeComponent implements OnInit {

    @Input() rangeStart = 0;
    @Input() rangeEnd = 100;
    @Input() lowThreshold = 25;
    @Input() highThreshold = 75;
    @Input() lowColor = '#5a93d1';
    @Input() middleColor = '#6bb878';
    @Input() highColor = '#df5353';
    @Input() width = '100px';
    @Input() rangeUnits = '';
    @Input() unitsAlignment: 'top' | 'center' | 'bottom' = 'center';

    private _value = 50;
    @Input() set value(val: number) {
        this._value = val;
        this.valuePercent = val / this.range * 100;
    }

    get value(): number {
        return this._value;
    }

    lowWidth = 0;
    middleWidth = 0;
    highWidth = 0;
    valuePercent = 0;

    // =========================================================================================================================================================
    // 'C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
        this.lowWidth = this.lowThreshold / this.range * 100;
        this.middleWidth = (this.highThreshold - this.lowThreshold) / this.range * 100;
        this.highWidth = 100 - (this.lowWidth + this.middleWidth);

        this.value = this._value;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    get range(): number {
        return this.rangeEnd - this.rangeStart;
    }

}
