<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="false"
            [showLinkButton]="false"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
    >

        <div class="toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane">
                <div class="twa-container" [ngClass]="{'is-mobile': isGridsterInMobileMode}">
                    <ng-container [ngTemplateOutlet]="currentWeather" [ngTemplateOutletContext]="{usage: 'mini-mode'}"></ng-container>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="false"
            [showLinkButton]="false"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-flex-child toro-component-shield-host">

            <div class="twa-container">
                <ng-container [ngTemplateOutlet]="currentWeather"></ng-container>
                <ng-container [ngTemplateOutlet]="forecastChart"></ng-container>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #currentWeather let-usage="usage">
    <div id="twa-source">
        <p-multiSelect
                [options]="sourcesList"
                [(ngModel)]="selectedSourcesIds"
                [styleClass]="'sources-selector'"
                [displaySelectedLabel]="true"
                [selectedItemsLabel]="'STRINGS.N_SOURCES_SELECTED' | translate"
                [appendTo]="'body'"
                [defaultLabel]="'STRINGS.SELECT_SOURCES' | translate | titlecase"
                (onPanelHide)="onSourcesSelectionChange()"
        ></p-multiSelect>
    </div>

    <div id="twa-data" class="toro-component-shield-host">
        <toro-weather-averages-datum-card
                *ngFor="let datum of weatherAveragesData"
                [datum]="datum"
                [isMobile]="isGridsterInMobileMode && usage == 'mini-mode'"
                [(selectedType)]="selectedWeatherType"
        ></toro-weather-averages-datum-card>
        <toro-component-shield *ngIf="isGridsterInMobileMode && usage == 'mini-mode'" (shieldClick)="onMobileClick()"></toro-component-shield>
    </div>
</ng-template>

<ng-template #forecastChart let-usage="usage">
    <div id="twa-chart">
        <toro-weather-averages-chart
                *ngIf="forecastWeather != null && isWidgetInitialized"
                [isModal]="(usage === 'modal' ? true : false)"
                [forecastWeather]="isDataRetrievalPending ? null : forecastWeather"
                [weatherType]="selectedWeatherType"
                [isBusy]="isDataRetrievalPending"
                [(clearChart)]="clearChart"
                (chartClick)="onChartClick($event)"
        ></toro-weather-averages-chart>
    </div>
</ng-template>

<ng-template #weatherGraphLegend>
    <div id="wa-chart-legend-container">
        <toro-source-legend-card
                *ngFor="let item of legendItems"
                [legendItem]="item"
        ></toro-source-legend-card>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="title" (cancel)="showModalGraph = false">
    <div id="twgd-chart-dlg-container">
        <ng-container [ngTemplateOutlet]="forecastChart" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="weatherGraphLegend" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
</toro-weather-graph-chart-dlg>

