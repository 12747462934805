
<div id="toro-expando-panel-container">
    <div #areaDiv *ngFor="let area of runningStationsDetails" class="tep-area-container" [ngClass]="{ hideChildren : areaDiv['class'] !== 'showChildren' }">
        <span class="tep-area-header" (click)="areaDiv['class'] = areaDiv['class'] == 'showChildren' ? '' :  'showChildren'">
            {{ area.name }} ({{ area.areaStationsCount }})
            <fa-icon [icon]="areaDiv['class'] !== 'showChildren' ? 'plus-circle' : 'minus-circle'"></fa-icon>
        </span>

        <div #holeDiv *ngFor="let hole of area.holes" class="tep-hole-container" [ngClass]="{ hideChildren : holeDiv['class'] !== 'showChildren' }">
            <span class="tep-hole-header" (click)="holeDiv['class'] = holeDiv['class'] == 'showChildren' ? '' :  'showChildren'" >
                {{ hole.name }} ({{ hole.holeStationsCount }})
                <fa-icon [icon]="holeDiv['class'] !== 'showChildren' ? 'plus-circle' : 'minus-circle'"></fa-icon>
            </span>

            <div class="tep-station-wrapper">
                <div *ngFor="let station of hole.stations" class="tep-station-container">
                    <fa-icon icon="tint"></fa-icon> {{ station.name }}
                </div>
            </div>
        </div>

    </div>
</div>
