/* eslint-disable @typescript-eslint/dot-notation */
import { TurfGuardHoleLimit } from './turf-guard-hole-limit.model';

export class TurfGuardHoleLimits {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });

            if (json.Temperature != null) { this.temperature = new TurfGuardHoleLimit(json.Temperature); }
            if (json.Moisture != null) { this.moisture = new TurfGuardHoleLimit(json.Moisture); }
            if (json.Salinity != null) { this.salinity = new TurfGuardHoleLimit(json.Salinity); }

        }
    }

    temperature: TurfGuardHoleLimit;
    moisture: TurfGuardHoleLimit;
    salinity: TurfGuardHoleLimit;

}
