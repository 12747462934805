<div id="toro-app-footer-container" class="toro-flex-child" [ngClass]="language">
    <div id="toro-app-footer-left-pane">
        <div id="toro-app-footer-left-pane-row1">{{ 'TORO.COPYRIGHT' | translate }}</div>
        <div id="toro-app-footer-left-pane-row2">
            <a [href]="'TORO.DMCA_COPYRIGHT_LINK' | translate" target="_blank">{{ 'STRINGS.DMCA_COPYRIGHT_POLICY' | translate}}</a><span class="footer-link-spacer">|</span>
            <a [href]="'TORO.TERMS_LINK' | translate" target="_blank">{{ 'STRINGS.TERMS_OF_USE' | translate}}</a><span class="footer-link-spacer">|</span>
            <a [href]="'TORO.PRIVACY_POLICY_LINK' | translate" target="_blank">{{ 'STRINGS.PRIVACY_POLICY' | translate}}</a>
        </div>
        <div id="toro-app-footer-left-pane-row3">{{ 'STRINGS.VERSION_PREFIX' | translate }}{{ appVersion }} <span *ngIf="buildConfiguration">({{ buildConfiguration }})</span></div>
    </div>

    <div id="toro-app-footer-api-endpoints-container" *ngIf="!isMobile && showApiEndpoint">
        <div><span class="footer-api-endpoint-label">IntelliDash API Endpoint:</span> {{ apiEndpoint }}</div>
        <div><span class="footer-api-endpoint-label">NSN API Endpoint:</span> {{ nsnApiEndpoint }}</div>
        <div><span class="footer-api-endpoint-label">myTurf API Endpoint:</span> {{ myTurfApiEndpoint }}</div>
    </div>

    <div id="toro-app-footer-right-pane">
        <div id="toro-app-footer-connect-label">{{ 'STRINGS.CONNECT_WITH_US' | translate }}:</div>
        <div id="toro-app-footer-social-links">
            <a class="fa fa-facebook" [href]="'TORO.FACEBOOK_LINK' | translate" target="_blank"><fa-icon [icon]="['fab', 'facebook-f']"></fa-icon></a>
            <a class="fa fa-twitter" [href]="'TORO.TWITTER_LINK' | translate" target="_blank"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></a>
            <a class="fa fa-youtube" [href]="'TORO.YOU_TUBE_LINK' | translate" target="_blank"><fa-icon [icon]="['fab', 'youtube']"></fa-icon></a>
            <a class="fa fa-pinterest" [href]="'TORO.PINTEREST_LINK' | translate" target="_blank"><fa-icon [icon]="['fab', 'pinterest']"></fa-icon></a>
            <a class="fa fa-instagram" [href]="'TORO.INSTAGRAM_LINK' | translate" target="_blank"><fa-icon [icon]="['fab', 'instagram']"></fa-icon></a>
        </div>
    </div>
</div>

