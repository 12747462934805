import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'toro-mini-mode-widget-modal',
    templateUrl: './mini-mode-widget-modal.component.html',
    styleUrls: ['./mini-mode-widget-modal.component.less']
})
export class MiniModeWidgetModalComponent implements OnInit {

    @Output() cancel = new EventEmitter();

    @Input() title = '';
    @Input() isTitleCase = true;

    isVisible = false;
    sidebarHeight: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit() {
        // const footerHeight = document.getElementById('toro-app-footer-container');
        // this.sidebarHeight = `calc(100% - ${footerHeight.clientHeight + 2}px)`;
        this.sidebarHeight = `calc(100%)`;

        setTimeout(() => this.isVisible = true);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.isVisible = false;
        setTimeout(() => this.cancel.emit(), 500);
    }

}
