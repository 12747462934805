import * as Highcharts from 'highcharts';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'toro-soil-scout-gauge',
    templateUrl: './soil-scout-gauge.component.html',
    styleUrls: ['./soil-scout-gauge.component.less']
})
export class SoilScoutGaugeComponent {
    @HostBinding('class') class = 'toro-soil-scout-gauge'

    @Output() showDetails = new EventEmitter();

    @Input() Highcharts = Highcharts;
    @Input() gaugeOptions: any = null;
    @Input() isGaugeLoaded = false;
    @Input() sensorAlertsCount: number

    constructor() { }

    onShowDetails() {
        this.showDetails.emit();
    }
}
