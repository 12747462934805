import { PlaybooksGddSinceAppValue } from './playbooks-gdd-since-app-value.model';

export class PlaybooksGddSinceAppItem {

    constructor(json: any = null) {
        if (json) {
            // Object.assign(this, json);

            // Handle camel casing because this one api returns Pascal Cased values.
            if (json.GDD0C_SinceApp) { this.gdd0c = new PlaybooksGddSinceAppValue(json.GDD0C_SinceApp); }
            if (json.GDD10C_SinceApp) { this.gdd10c = new PlaybooksGddSinceAppValue(json.GDD10C_SinceApp); }
            if (json.GDD32_SinceApp) { this.gdd32 = new PlaybooksGddSinceAppValue(json.GDD32_SinceApp); }
            if (json.GDD50_SinceApp) { this.gdd50 = new PlaybooksGddSinceAppValue(json.GDD50_SinceApp); }
        }
    }

    gdd0c: PlaybooksGddSinceAppValue;
    gdd10c: PlaybooksGddSinceAppValue;
    gdd32: PlaybooksGddSinceAppValue;
    gdd50: PlaybooksGddSinceAppValue;
}
