import { ApiService, HttpMethod } from '../_common/api.service';
import { DashUserInfo } from './models/dash-user-info.model';
import { DashUserPreferences } from './models/dash-user-preferences.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SendEmailModel } from './models/send-email.model';
import { UpdateDashUserPreferences } from './models/update-dash-user-preferences.model';

@Injectable({
    providedIn: 'root'
})
export class DashUserApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDashUserInfo(): Observable<DashUserInfo> {
        return this.apiService.apiRequest<any>(DashUserApiService.userUrl, HttpMethod.Get)
            .pipe(map((dashUserInfo: DashUserInfo) => new DashUserInfo(dashUserInfo)));
    }

    sendFeedback(sem: SendEmailModel): Observable<any> {
        return this.apiService.apiRequest<any>(DashUserApiService.sendFeedbackUrl, HttpMethod.Post, sem);
    }

    updateDashUserPreferences(userPreferences: DashUserPreferences): Observable<any> {
        return this.apiService.apiRequest<any>(
            DashUserApiService.updateUserUrl, HttpMethod.Put, JSON.stringify(new UpdateDashUserPreferences(userPreferences))
        );
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}user`; }

    private static get sendFeedbackUrl(): string { return `${DashUserApiService.baseUrl}/sendFeedback`; }

    private static get updateUserUrl(): string { return `${DashUserApiService.baseUrl}/userPreferences`; }

    private static get userUrl(): string { return `${DashUserApiService.baseUrl}`; }

}
