<div id="pw-tabbed-chart-container">
    <ng-container [ngTemplateOutlet]="tabs" [ngTemplateOutletContext]="{type: type}"></ng-container>
</div>

<ng-template #tabs let-usage="usage" let-type="type">
    <div id="pw-tabs">
        <div id="pw-tab-buttons">
            <div class="pw-tab-btn"
                 [ngClass]="{'tab-selected': selectedTab === ChartPeriod.Historic}"
                 (click)="onTabClick(ChartPeriod.Historic)"
            >
                <span class="prevent-select">{{ 'STRINGS.HISTORICAL' | translate }}</span>
            </div>
            <div class="pw-tab-btn"
                 *ngIf="type != ChartType.Et"
                 [ngClass]="{'tab-selected': selectedTab === ChartPeriod.Forecast}"
                 (click)="onTabClick(ChartPeriod.Forecast)"
            >
                <span class="prevent-select">{{ 'STRINGS.FORECAST' | translate }}</span>
            </div>
        </div>
        <div id="pw-tab-graph-selectors">
            <div class="pw-tab-btn pw-chart-btn"
                 *ngIf="type != ChartType.Et"
                 [ngClass]="{'tab-selected': selectedChart === ChartType.Precip}"
                 (click)="onChartSelect(ChartType.Precip)"
            >
                <span class="prevent-select">{{ 'STRINGS.PRECIP_ABBR' | translate }}</span>
            </div>
            <div class="pw-tab-btn pw-chart-btn"
                 *ngIf="selectedTab != ChartPeriod.Forecast && type != ChartType.Precip"
                 [ngClass]="{'tab-selected': selectedChart === ChartType.Et}"
                 (click)="onChartSelect(ChartType.Et)"
            >
                <span class="prevent-select">{{ 'CASE_SENSITIVE.ET' | translate }}</span>
            </div>
        </div>
    </div>
    <div id="pw-tabs-chart">
        <ng-container [ngTemplateOutlet]="chart" [ngTemplateOutletContext]="{usage: usage}"></ng-container>
    </div>
</ng-template>

<ng-template #chart let-usage="usage">
    <div id="twa-chart">
        <toro-perry-weather-chart
                *ngIf="chartData != null"
                [isModal]="(usage === 'modal')"
                [chartDataType]="selectedChartSeries"
                [chartData]="chartData"
                [isBusy]="isBusy"
                (chartClick)="onChartClick($event)"
        ></toro-perry-weather-chart>
    </div>
</ng-template>

<toro-weather-graph-chart-dlg *ngIf="showModalGraph;" [title]="title" (cancel)="showModalGraph = false">
    <div id="twgd-chart-dlg-container">
        <ng-container [ngTemplateOutlet]="tabs" [ngTemplateOutletContext]="{usage: 'modal', type: type}"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="weatherGraphLegend" [ngTemplateOutletContext]="{usage: 'modal'}"></ng-container>
</toro-weather-graph-chart-dlg>

<ng-template #weatherGraphLegend>
    <div id="pw-chart-legend-container">
        {{ chartLegend | translate }}
    </div>
</ng-template>
