export class LynxStation {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    holeId: number;
    name: string;
    lynxId: number;

}
