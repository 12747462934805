/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KittyHawkGreen } from './models/kitty-hawk-green.model';
import { KittyHawkLayer } from './models/kitty-hawk-layer.model';
import { KittyHawkLocation } from './models/kitty-hawk-location.model';
import { KittyHawkMission } from './models/kitty-hawk-mission.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GreenSiteApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getGreens(locationId: string): Observable<KittyHawkGreen[]> {
        return this.apiService.apiRequest<any>(GreenSiteApiService.getGreensUrl(locationId), HttpMethod.Get)
            .pipe(map((response: KittyHawkGreen[]) => response.map(green => new KittyHawkGreen(green)).sort(((a, b) => a.at_hole - b.at_hole))));
    }

    getLayers(missionUuid: string): Observable<KittyHawkLayer[]> {
        return this.apiService.apiRequest<any>(GreenSiteApiService.getLayersUrl(missionUuid), HttpMethod.Get)
            .pipe(map((response: KittyHawkLayer[]) => response.map(layer => new KittyHawkLayer(layer))));
    }

    getLocation(locationId: string): Observable<KittyHawkLocation> {
        return this.apiService.apiRequest<any>(GreenSiteApiService.getLocationUrl(locationId), HttpMethod.Get)
            .pipe(map((response: KittyHawkLocation) => new KittyHawkLocation(response)));
    }

    getMissions(locationId: string): Observable<KittyHawkMission[]> {
        return this.apiService.apiRequest<any>(GreenSiteApiService.getMissionsUrl(locationId), HttpMethod.Get)
            .pipe(map((response: KittyHawkMission[]) => response.map(mission => new KittyHawkMission(mission))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseGreenSightUrl(): string { return 'https://api.greensightag.com/'; }

    private static getGreensUrl(locationId): string {return `${GreenSiteApiService.baseGreenSightUrl}location/${locationId}/marker/1`; }

    private static getLayersUrl(missionUuid): string {return `${GreenSiteApiService.baseGreenSightUrl}mission/${missionUuid}/map?expand=map_type`; }

    private static getLocationUrl(locationId): string {return `${GreenSiteApiService.baseGreenSightUrl}location/${locationId}`; }

    private static getMissionsUrl(locationId): string {return `${GreenSiteApiService.baseGreenSightUrl}location/${locationId}/mission`; }
}
