export class TurfGuardSensorAlert {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });
        }
    }

    alertType: string;
    name: string;
    id: number;

}
