import { GraphAxisExtentModel } from './models/graph-axis-extent.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToroEnums } from '../enumerations/toro.enums';
import { WeatherGraphsData } from '../../api/weather/models/weather-graphs-data.model';

import WeatherGraphType = ToroEnums.WeatherGraphType;

@Injectable({
    providedIn: 'root'
})
export class WeatherGraphNormalizationService {

    // Subjects
    yAxisExtentChange = new Subject<GraphAxisExtentModel>();

    private readonly windGraphYExtent: GraphAxisExtentModel;
    private readonly temperatureGraphYExtent: GraphAxisExtentModel;
    private readonly precipitationGraphYExtent: GraphAxisExtentModel;
    private readonly radiationGraphYExtent: GraphAxisExtentModel;
    private readonly humidityGraphYExtent: GraphAxisExtentModel;
    private notificationTimerRef: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() {
        this.windGraphYExtent = new GraphAxisExtentModel(WeatherGraphType.Wind, undefined, undefined);
        this.temperatureGraphYExtent = new GraphAxisExtentModel(WeatherGraphType.Temperature, undefined, undefined);
        this.precipitationGraphYExtent = new GraphAxisExtentModel(WeatherGraphType.Precipitation, undefined, undefined);
        this.radiationGraphYExtent = new GraphAxisExtentModel(WeatherGraphType.SolarRadiation, undefined, undefined);
        this.humidityGraphYExtent = new GraphAxisExtentModel(WeatherGraphType.RelativeHumidity, undefined, undefined);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getYAxisExtent(graphType: WeatherGraphType) {
        let axisExtent: GraphAxisExtentModel;

        switch (graphType) {
            case WeatherGraphType.Wind:
                axisExtent = this.windGraphYExtent;
                break;
            case WeatherGraphType.Temperature:
                axisExtent = this.temperatureGraphYExtent;
                break;
            case WeatherGraphType.Precipitation:
                axisExtent = this.precipitationGraphYExtent;
                break;
            case WeatherGraphType.RelativeHumidity:
                axisExtent = this.humidityGraphYExtent;
                break;
            case WeatherGraphType.SolarRadiation:
                // TODO: Legacy. No longer used. Verify and remove.
                axisExtent = this.radiationGraphYExtent;
                break;
            default:
                return;
        }

        return axisExtent;
    }

    setYAxisExtents(graphData: WeatherGraphsData) {
        this.setYAxisExtent(WeatherGraphType.Wind, graphData.wind.minValue, graphData.wind.maxValue);
        this.setYAxisExtent(WeatherGraphType.Temperature, graphData.temperature.minValue, graphData.temperature.maxValue);
        this.setYAxisExtent(WeatherGraphType.Temperature, graphData.dewPoint.minValue, graphData.dewPoint.maxValue);
        this.setYAxisExtent(WeatherGraphType.Precipitation, graphData.precipitation.minValue, graphData.precipitation.maxValue);
        this.setYAxisExtent(WeatherGraphType.RelativeHumidity, graphData.relativeHumidity.minValue, graphData.relativeHumidity.maxValue);
        this.setYAxisExtent(WeatherGraphType.SolarRadiation, graphData.solarRadiation.minValue, graphData.solarRadiation.maxValue);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setYAxisExtent(graphType: WeatherGraphType, min: number, max: number) {
        let axisExtent: GraphAxisExtentModel;
        let minValue: number = null;

        if (this.notificationTimerRef) { clearTimeout(this.notificationTimerRef); }

        switch (graphType) {
            case WeatherGraphType.Wind:
                axisExtent = this.windGraphYExtent;
                minValue = 0;
                break;
            case WeatherGraphType.Temperature:
                axisExtent = this.temperatureGraphYExtent;
                break;
            case WeatherGraphType.Precipitation:
                axisExtent = this.precipitationGraphYExtent;
                minValue = 0;
                break;
            case WeatherGraphType.RelativeHumidity:
                axisExtent = this.humidityGraphYExtent;
                minValue = 0;
                break;
            case WeatherGraphType.SolarRadiation:
                // TODO: Legacy. No longer used. Verify and remove.
                axisExtent = this.radiationGraphYExtent;
                minValue = 0;
                break;
            default:
                return;
        }

        // Update appropriate values
        if ((axisExtent.min == null || axisExtent.min > min) && min >= minValue) { axisExtent.min = min; }
        if (axisExtent.max == null || axisExtent.max < max) { axisExtent.max = max; }

        // Attempt to only send change notifications once.
        this.notificationTimerRef = setTimeout(() => this.sendGraphExtentNotification(), 100);
    }

    private sendGraphExtentNotification() {
        this.yAxisExtentChange.next({ ...this.windGraphYExtent});
        this.yAxisExtentChange.next({ ...this.temperatureGraphYExtent});
        this.yAxisExtentChange.next({...this.precipitationGraphYExtent});
        this.yAxisExtentChange.next({ ...this.humidityGraphYExtent});
        this.yAxisExtentChange.next({...this.radiationGraphYExtent});
    }

}
