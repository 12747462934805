export class KittyHawkGreenMapInfo {

    constructor(lat: number, lng: number, zoom: number) {
        this.lat = lat;
        this.lng = lng;
        this.zoom = zoom;
    }

    lat: number;
    lng: number;
    zoom: number;

}
