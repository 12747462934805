/* eslint-disable @typescript-eslint/member-ordering */
import { ApiService, HttpMethod } from '../_common/api.service';
import { AlertSettingsAndParameters } from './models/alert-settings-and-parameters.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToroNotification } from './models/toro-notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getNotifications(includeRead = true, lastAlertId = null): Observable<ToroNotification[]> {
        return this.apiService.apiRequest<any>(NotificationApiService.getNotificationsUrl(includeRead, lastAlertId), HttpMethod.Get)
            .pipe(map((response: ToroNotification[]) => response.map(r => new ToroNotification(r))));
    }

    markNotificationAsRead(notificationId: number): Observable<void> {
        return this.apiService.apiRequest<any>(NotificationApiService.getMarkNotificationAsReadUrl(notificationId), HttpMethod.Put);
    }

    markAllNotificationsAsRead(): Observable<void> {
        return this.apiService.apiRequest<any>(NotificationApiService.markAllNotificationAsReadUrl, HttpMethod.Put);
    }

    deleteNotification(notificationId: number): Observable<void> {
        return this.apiService.apiRequest<any>(NotificationApiService.getDeleteNotificationUrl(notificationId), HttpMethod.Delete);
    }

    deleteAllNotifications(): Observable<void> {
        return this.apiService.apiRequest<any>(NotificationApiService.deleteAllNotificationUrl, HttpMethod.Delete);
    }

    getAlertSettingsAndParameters(): Observable<AlertSettingsAndParameters> {
        return this.apiService.apiRequest<any>(NotificationApiService.alertSettingsAndParametersUrl, HttpMethod.Get)
            .pipe(map(response => new AlertSettingsAndParameters(response)));
    }

    setAlertSettingsAndParameters(settings: AlertSettingsAndParameters): Observable<void> {
        return this.apiService.apiRequest<any>(NotificationApiService.alertSettingsAndParametersUrl, HttpMethod.Post, settings);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}alerts`; }

    private static getNotificationsUrl(includeRead: boolean, lastAlertId: number): string {
        return `${NotificationApiService.baseUrl}?includeRead=${includeRead}&lastAlertId=${lastAlertId ?? ''}`;
    }

    private static getMarkNotificationAsReadUrl(notificationId: number): string {
        return `${NotificationApiService.baseUrl}/markAsRead?id=${notificationId}`;
    }

    private static get markAllNotificationAsReadUrl(): string { return `${NotificationApiService.baseUrl}/markAllAsRead`; }

    private static getDeleteNotificationUrl(notificationId: number): string {
        return `${NotificationApiService.baseUrl}/delete?id=${notificationId}`;
    }

    private static get deleteAllNotificationUrl(): string { return `${NotificationApiService.baseUrl}/deleteAll`; }

    private static get alertSettingsAndParametersUrl(): string { return `${NotificationApiService.baseUrl}/settings`; }
}
