<div id="toro-nsn-error">
    <div id="toro-nsn-error-content">
        <div id="toro-nsn-error-header">
            <img src="assets/images/toro-nsn-logo.png" alt="Toro NSN Logo">
            <img id="intellidash-logo" src="assets/images/toro-intellidash-logo_r_sans.png" alt="Toro NSN Logo">
        </div>
        <div id="toro-nsn-error-body">
            <div>
                {{'ERR_MSG.NSN_LOGIN_ERROR' | translate: {appName: 'TORO.APP_NAME' | translate} }}
                <p>{{'STRINGS.REASON' | translate | titlecase }}: {{reason | translate: {appName: 'TORO.APP_NAME' | translate} }}</p>
                <p>{{'STRINGS.PLEASE_CONTACT_NSN' | translate}}: <a href="https://my.toronsn.com/contact/infomobile">https://my.toronsn.com/contact/infomobile</a></p>
            </div>
        </div>
        <div id="toro-nsn-error-buttons">
            <div class="twp-button" (click)="onRetry()">
                <span>{{'STRINGS.RETRY' | translate}}</span>
            </div>
            <div class="twp-button" (click)="onClose()">
                <span>{{'STRINGS.CLOSE' | translate}}</span>
            </div>
        </div>
    </div>
</div>
