import * as moment from 'moment';
import { PogoImageExtent } from './pogo-image-extent.model';
import { PogoLocation } from './pogo-location.model';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import PogoMoistureRating = ToroEnums.PogoMoistureRating;

export class PogoZoneConditions {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.timestamp) this.timestamp = moment.utc(json.timestamp).toDate();
            if (json.min_location) this.min_location = new PogoLocation(json.min_location);
            if (json.max_location) this.max_location = new PogoLocation(json.max_location);
            if (json.img_extent) this.img_extent = new PogoImageExtent(json.img_extent);
            if (json.approach_marker) this.approach_marker = new PogoLocation(json.approach_marker)
            if (json.moisture_avg_rating) this.moistureRating = this.getPogoMoistureRating(json.moisture_avg_rating);
        }
    }

    timestamp: Date;
    moisture_avg: number;
    moisture_avg_color: string;
    moisture_avg_rating: string;
    moistureRating: PogoMoistureRating;
    moisture_du: number;
    moisture_du_color: string;
    moisture_min: number;
    moisture_max: number;
    min_location: PogoLocation;
    max_location: PogoLocation;
    moisture_tpi: string;
    moisture_tpi_color: string;
    moisture_img: string;
    img_extent: PogoImageExtent;
    approach_marker: PogoLocation;

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getPogoMoistureRating(ratingString: string) {
        switch (ratingString) {
            case 'Critically Low':
                return PogoMoistureRating.CriticallyLow;
            case 'Low':
                return PogoMoistureRating.Low;
            case 'Good':
            case 'Optimal':
                return PogoMoistureRating.Good;
            case 'High':
                return PogoMoistureRating.High;
            case 'Critically High':
                return PogoMoistureRating.CriticallyHigh;
        }
    }
}
