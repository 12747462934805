import WeatherAverageType = ToroEnums.WeatherAverageType;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WeatherAveragesDatum } from '../models/weather-averages-datum.model';

@UntilDestroy()
@Component({
    selector: 'toro-weather-averages-datum-card',
    templateUrl: './weather-averages-datum-card.component.html',
    styleUrls: ['./weather-averages-datum-card.component.less']
})
export class WeatherAveragesDatumCardComponent implements OnInit {

    @Input() datum: WeatherAveragesDatum
    @Input() isMobile = false;

    @Output() selectedTypeChange = new EventEmitter<WeatherAverageType>();
    @Input() set selectedType(value: WeatherAverageType) {
        this.isSelected = value == this.datum.type;
    }

    isSelected = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    ngOnInit(): void {
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSelect() {
        if (this.isSelected) return;

        this.isSelected = true;
        this.selectedTypeChange.emit(this.datum.type);
    }
}
