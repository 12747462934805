import { PlaybooksGddSinceAppArea } from './playbooks-gdd-since-app-area.model';

export class PlaybooksGddSinceAppRecord {

    constructor(json: any = null) {
        if (json) {
            // Object.assign(this, json);

            // Handle camel casing because this one api returns Pascal Cased values.
            if (json.GDDSinceApp) { this.gddSinceApp = json.GDDSinceApp.map(a => new PlaybooksGddSinceAppArea(a)); }
        }
    }

    gddSinceApp: PlaybooksGddSinceAppArea[];
}
