import * as moment from 'moment';
import { SoilScoutNotificationMeasurement } from './soil-scout-notification-measurement.model';

export class SoilScoutNotification {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.start) this.start = moment.utc(json.start).toDate();
            if (json.resolved) this.resolved = moment.utc(json.resolved).toDate();
            if (json.acknowledged) this.acknowledged = moment.utc(json.acknowledged).toDate();
            if (json.measurements) this.measurements = json.measurements.map(m => new SoilScoutNotificationMeasurement(m));
        }
    }

    id: number;
    rule_id: number;
    start: Date = null;
    resolved: Date = null;
    acknowledged: Date = null;
    measurements: SoilScoutNotificationMeasurement[] = [];
}
