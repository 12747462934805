/* eslint-disable @typescript-eslint/dot-notation */
export class TurfGuardSensorLimit {

    constructor(json: any = null) {
        if (json) {
            Object.keys(json).forEach(key => {
                this[key.pascalCaseToCamelCase()] = json[key];
            });
        }
    }

    maximum: number;
    minimum: number;
    span: number;

}
