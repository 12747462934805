import { Component, Input, OnInit } from '@angular/core';
import { PinSheetEntry } from '../../../../../../api/ez-locator/models/pin-sheet-entry.model';

@Component({
    selector: 'toro-ez-locator-left-right-placement',
    templateUrl: './ez-locator-left-right-placement.component.html',
    styleUrls: ['./ez-locator-left-right-placement.component.less']
})
export class EzLocatorLeftRightPlacementComponent implements OnInit {

    @Input() pinSheet: PinSheetEntry;

    isLeftPlacement = false;
    isRightPlacement = false;

    constructor() { }

    ngOnInit(): void {
        this.isLeftPlacement = this.pinSheet.holeSide.includes('L');
        if (!this.isLeftPlacement) this.isRightPlacement = this.pinSheet.holeSide.includes('R');
    }

}
