<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [lastUpdated]="lastUpdated"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [showInfoButton]="true"
        [(alertText)]="alertText"
        [(isUnableToFetchData)]="isUnableToFetchData"
        (showInfo)="onShowInfo()"
>

    <div id="toro-lynx-pump-station-widget-content" class="toro-component-shield-host">
        <div class="toro-flex-child lps-chart-container" [ngClass]="{'isMobile': isGridsterInMobileMode}">
            <div class="lps-chart-title">{{ 'STRINGS.PRESSURE' | translate }}</div>
            <highcharts-chart
                    class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isPressureChartLoaded}"
                    [Highcharts]="Highcharts"
                    [options]="pressureChartOptions"
            >
            </highcharts-chart>
        </div>
        <div class="toro-flex-child lps-chart-container" [ngClass]="{'isMobile': isGridsterInMobileMode}">
            <div class="lps-chart-title">{{ 'STRINGS.FLOW' | translate }}</div>
            <highcharts-chart
                    class="toro-highcharts-chart" [ngClass]="{'fade-in-chart': isFlowChartLoaded}"
                    [Highcharts]="Highcharts"
                    [options]="flowChartOptions"
            >
            </highcharts-chart>
        </div>
        <toro-component-shield></toro-component-shield>
    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<!-- Dialogs -->
<toro-pump-station-details-dlg *ngIf="showInfoDialog" [pumpStation]="pumpStation" (closeDialog)="showInfoDialog = false"></toro-pump-station-details-dlg>
