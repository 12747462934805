import { DashUserPreferences } from './dash-user-preferences.model';

export class UpdateDashUserPreferences {

    constructor(dashUserPreferences: DashUserPreferences) {
        this.preferencesJson = JSON.stringify(dashUserPreferences);
    }

    preferencesJson: string;

}
