
export class EzLocatorAccessId {
    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    clientAccessId: string;
}
