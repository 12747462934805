<div class="green-site-map-container" leaflet
     [leafletOptions]="leafletOptions"
     [leafletLayers]="layers"
     (leafletMapReady)="onMapReady($event)"
     (leafletClick)="onMapClick($event)">

    <div class="gsmm-delete-button" *ngIf="canDeleteMap" (click)="onDeleteClick($event)">
        <fa-icon icon="times"></fa-icon>
    </div>
</div>

