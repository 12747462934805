import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { PogoProperty } from '../../../../../api/pogo/models/pogo-property.model';
import { PogoZone } from '../../../../../api/pogo/models/pogo-zone.model';

@UntilDestroy()
@Component({
  selector: 'toro-spec-modal',
  templateUrl: './spec-modal.component.html',
  styleUrls: ['./spec-modal.component.less']
})
export class SpecModalComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() gotoSite = new EventEmitter();
  @Output() zoneSelectionChange = new EventEmitter<PogoZone>();

  @Input() title = '';
  @Input() isTitleCase = true;
  @Input() showLinkButton = false;

  @Input() property: PogoProperty;
  @Input() isMiniModal = false;
  @Input() selectedZones: PogoZone[];

  private _selectedZone: PogoZone;
  @Input() set selectedZone(value: PogoZone) {
    this._selectedZone = value;
    this.zoneSelectionChange.next(value);
  }

  get selectedZone(): PogoZone {
    return this._selectedZone;
  }

  // =========================================================================================================================================================
  // C'tor and Lifecycle Hooks
  // =========================================================================================================================================================

  constructor(private deviceManager: DeviceManagerService) { }

  ngOnInit(): void {
    this.deviceManager.windowResize
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onClose());
  }

  // =========================================================================================================================================================
  // Event Handlers
  // =========================================================================================================================================================

  onGotoSite() {
    this.gotoSite.next(null);
  }

  onClose() {
    this.cancel.next(null);
  }

}
