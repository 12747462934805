import { PinSheetEntry } from './pin-sheet-entry.model';

export class DailyPinSheet {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.date) this.date = new Date(json.date);
            if (json.pinSheetEntries) {
                this.pinSheetEntries = [];
                json.pinSheetEntries.map(e => this.pinSheetEntries.push(new PinSheetEntry(e)));
            }
        }
    }

    date: Date;
    pinSheetEntries: PinSheetEntry[];
}
