import * as moment from 'moment';

export class PlaybooksPrintLogUrl {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            // TODO: Determine proper timezone
            if (json.applicationLogDate) { this.applicationLogDate = moment(json.applicationLogDate, 'LLL').toDate(); }
            if (json.url) { this.url = json.url.replace('http:\\', 'http://').replace(/\\/g, '/'); }
        }
    }

    applicationAreaLog: string;
    applicationLogAttachedCourse: string;
    applicationLogCourse: string;
    applicationLogDate: Date;
    validation: string;
    url: string;
}
