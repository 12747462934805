export class SpecFieldScoutCollections {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.collections != null) this.collections = json.collections.map(c => new SpecFsCollectionItem(c))
        }
    }

    collections: SpecFsCollectionItem[];
}

export class SpecFsCollectionItem {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    name: string;
    profileId?: number = null;
}
