
export class WizardStep {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    sidebarTitle: string;
    sidebarDescription: string;
    contentTitle: string;
    contentDescription: string;
}
