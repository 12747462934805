import { MockLynxCloudStationStatusItem } from './mock-lynx-cloud-station-status-item.model';
import { StationStatuses } from '../../api/lynx-cloud/models/station-status/station-statuses.model';

export class MockLynxCloudStationStatuses extends StationStatuses{

    constructor(json: any = null) {
        super(null, null);

        if (json) {
            Object.assign(this, json);

            if (json.runningStations) this.runningStations = json.runningStations.map(s => new MockLynxCloudStationStatusItem(s));
            if (json.ranUnderStations) this.ranUnderStations = json.ranUnderStations.map(s => new MockLynxCloudStationStatusItem(s));
        }
    }

}
