export class ClubListEntry {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    clientAccessId: string;
    clientName: string;
    clientAccessTypeId: number;
    clientAccessTypeDesc: string;
}
