/*
 * NOTE: DO NOT USE THESE FUNCTIONS DIRECTLY FROM THIS NAMESPACE.
 * 		 You should reference them from the RbUtils Namespace.
 * 		 E.g., ToroUtils.Translate.instant
 * 		 See: _toro.utils.ts
 */

import { AppInjector } from '../../shared/shared.module';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export namespace XXUseToroUtilsNamespace {

    export abstract class Translate {

        private static _translateService: TranslateService;

        private static get translateService() {
            if (!Translate._translateService) {
                Translate._translateService = AppInjector.get(TranslateService);
            }
            return Translate._translateService;
        }

        static instant(key: string | Array<string>, interpolateParams?: object): string | any {
            return Translate.translateService.instant(key, interpolateParams);
        }

        static stream(key: string | Array<string>, interpolateParams?: object): Observable<string | any> {
            return Translate.translateService.stream(key, interpolateParams);
        }

    }
}
