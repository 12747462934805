import { SoilScoutDeviceMeasurement } from './soil-scout-device-measurement.model';
import { SoilScoutDeviceModemStatus } from './soil-scout-device-modem-status.model';
import { SoilScoutLocation } from './soil-scout-location.model';

export class SoilScoutDevice {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.location) this.location = new SoilScoutLocation(json.location);
            if (json.last_measurement) this.last_measurement = new SoilScoutDeviceMeasurement(json.last_measurement);
            if (json.last_modem_status) this.last_modem_status = new SoilScoutDeviceModemStatus(json.last_modem_status);
        }
    }

    id: number;
    serial_number: number;
    name: string;
    imei: string;
    device_type: string;
    voltage_external: number;
    voltage_battery: number;
    location: SoilScoutLocation;
    site: number;
    last_seen: Date;
    device_status: string;
    last_modem_status: SoilScoutDeviceModemStatus;
    last_measurement: SoilScoutDeviceMeasurement;
    groups: number[] = [];
    antenna_type: string;
    antenna_orientation: string;
    rain_mm_per_pulse: number;
    has_battery: boolean;

}
