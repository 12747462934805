<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-local-weather-widget-content mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div id="tlw-content" class="toro-mini-mode-content">

                        <div id="tlw-icon-content" class="tlw-content-row">
                            <i class="tmm-ul-icon toro-id-tg-forecast"></i>
                        </div>

                        <div id="tlw-value-content" class="tlw-content-row">
                            <div id="tlw-current-value"><span>{{currentTemp || '-'}}</span><span>°{{tempUnits}}</span></div>
                            <div>
                                <span class="tlw-value-datum-container">{{'STRINGS.HIGH' | translate}}<span class="tlw-datum-value">{{forecastHighTemp}}</span><span class="tlw-datum-units">°{{tempUnits}}</span></span>
                                <span id="tlw-value-low" class="tlw-value-datum-container">
                                    <span class="tlw-datum-value">{{forecastLowTemp}}</span>
                                    <span class="tlw-datum-units">°{{tempUnits}}</span>{{'STRINGS.LOW' | translate}}
                                </span>
                            </div>
                        </div>

                        <div id="tlw-forecast-content" class="tlw-content-row">
                            <div class="tmm-bot-left-container tmm-ellipsis-text">{{conditionsString}}</div>
                        </div>

                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-local-weather-widget-content prevent-select">
            <div *ngIf="displayCols === 1" class="toro-widget-content-pane widget-left-pane" [ngClass]="{'fade-on-resize': isResizing}">
                <div id="lwf-temperature-pane">
                    <div id="lwf-current-temp-container">
                        <div id="lwf-current-temp">{{currentTemp || '-'}}</div>
                        <div id="lwf-current-temp-degree">o</div>
                        <div id="lwf-current-temp-scale">{{tempUnits}}</div>
                    </div>
                    <div class="lwf-high-low-temp-pane">
                        <div class="lwf-high-low-temp-wrapper">
                            <div class="lwf-high-low-temp">{{forecastHighTemp || '-'}}</div>
                            <div class="lwf-high-low-temp-degree">o</div>
                        </div>
                        <div class="lwf-high-low-temp-label">{{'STRINGS.HIGH' | translate}}</div>
                    </div>
                    <div class="lwf-high-low-temp-pane">
                        <div class="lwf-high-low-temp-wrapper">
                            <div class="lwf-high-low-temp">{{forecastLowTemp || '-'}}</div>
                            <div class="lwf-high-low-temp-degree">o</div>
                        </div>
                        <div class="lwf-high-low-temp-label">{{'STRINGS.LOW' | translate}}</div>
                    </div>
                </div>
                <div id="lwf-condition-pane">
                    <div id="lwf-condition-icon">
                        <i class="weather-icon" [class]="weatherIcon?.primaryIcon?.icon" [ngStyle]="{'color': weatherIcon?.primaryIcon?.color}"></i>
                        <i class="weather-icon" [class]="weatherIcon?.overlayIcon?.icon" [ngStyle]="{'color': weatherIcon?.overlayIcon?.color}"></i>
                    </div>
                    <div id="lwf-condition-text">{{ conditionsString }}</div>
                </div>
            </div>

            <div *ngIf="displayCols === 2" class="toro-widget-content-pane widget-right-pane" [ngClass]="{'fade-on-resize': isResizing}">
                <div class="rounded-top-square-bottom-panel today-container">
                    <div class="today-temp-container">
                        <div id="today-label-container">
                            <div class="today-label-icon">
                                <i class="toro-id-tg-forecast"></i>
                                <span class="today-label">{{'STRINGS.TODAY' | translate}}</span>
                            </div>
<!--                            <div id="today-location">-->
<!--                                {{ site?.name }}-->
<!--                            </div>-->
                        </div>
                        <div class="today-weather-container" >
                            <div id="lwf-icon">
                                <i class="weather-icon" [class]="weatherIcon?.primaryIcon?.icon" [ngStyle]="{'color': weatherIcon?.primaryIcon?.color}"></i>
                                <i class="weather-icon" [class]="weatherIcon?.overlayIcon?.icon" [ngStyle]="{'color': weatherIcon?.overlayIcon?.color}"></i>
                            </div>
                            <div class="today-weather-temp">{{currentTemp}}<span class="today-weather-temp-units">°{{tempUnits}}</span></div>
                        </div>
                    </div>
                    <div class="today-data-container">
                        <div class="today-datum-container" >
                            <div class="today-datum-ico-label"><fa-icon icon="tint"></fa-icon><span class="today-datum-label" >{{'STRINGS.PRECIPITATION' | translate}}</span></div>
                            <div class="today-datum-value">{{rainfall}}</div>
                        </div>
                        <div class="today-datum-container" [ngClass]="language">
                            <div class="today-datum-ico-label"><i class="toro-id-wind"></i><span class="today-datum-label">{{'STRINGS.WIND' | translate}}</span></div>
                            <div class="today-datum-value wind">{{windSpeed}}</div>
                        </div>
                        <div class="today-datum-container humidity" [ngClass]="language">
                            <div class="today-datum-ico-label"><i class="toro-id-humidity"></i><span class="today-datum-label">{{'STRINGS.HUMIDITY' | translate}}</span></div>
                            <div class="today-datum-value">{{humidity}}</div>
                        </div>
                        <div class="today-datum-container">
                            <div class="today-datum-ico-label"><i class="toro-id-dew-point"></i><span class="today-datum-label">{{'STRINGS.DEW_POINT' | translate}}</span></div>
                            <div class="today-datum-value">{{dewPoint}}</div>
                        </div>
                    </div>
                </div>
                <div class="daily-forecasts-container" *ngIf="tenDayForecast">
                    <toro-daily-forecast-card
                            *ngFor="let item of 5 | fill; index as i"
                            [isTomorrow]="i === 0"
                            [dailyForecast]="tenDayForecast[i + 1]"
                    ></toro-daily-forecast-card>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
