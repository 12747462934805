import * as moment from 'moment';

export class CalAmpMotionLogInfo {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            // TODO: Clean up! =================================================================================================================================

            // Cal/Amp API sends values back in UTC.
            // this.timeOfFix = moment.utc(json.timeOfFix).toDate();

            // Asset Tracking API passes dates back in course local time. We'll treat all dates as local, which will not modify the date/time value.
            this.timeOfFix = moment(json.timeOfFix).toDate();

            // TODO: ===========================================================================================================================================

            this.vehicleBusRPM = +json.vehicleBusRPM;
            this.vehicleBusSpeed = +json.vehicleBusSpeed;
        }
    }

    latitude: number;
    longitude: number;
    speed: number;
    heading: number;
    satellites: number;
    timeOfFix: Date;
    hdop: number;
    vehicleBusRPM: number;
    vehicleBusSpeed: number;
    altitude: number;
}
