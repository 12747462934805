export class SendEmailModel {

    constructor(recipients: string[], subject: string, body: string, attachment?: File) {
        this.recipients = recipients;
        this.subject = subject;
        this.body = body;
        this.attachment = attachment;
    }

    recipients: string[] = [];
    subject: string;
    body: string;
    attachment: File;
}
