import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-dashboard-widget-alert-banner',
    templateUrl: './dashboard-widget-alert-banner.component.html',
    styleUrls: ['./dashboard-widget-alert-banner.component.less']
})
export class DashboardWidgetAlertBannerComponent {
    @Output() alertTextChange = new EventEmitter<string>();

    private _alertText: string;
    @Input() set alertText(value: string) {
        if (this._alertText === value) return;

        this._alertText = value;
        this.alertTextChange.emit(value);
    }

    get alertText(): string {
        return this._alertText;
    }

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    onHideAlertBanner() {
        this.alertText = null;
    }

}
