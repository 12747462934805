<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>

    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [showEmptyFooter]="isGridsterInMobileMode"
            [showLinkButton]="true"
            (gotoSite)="onNsnClick();"
    >

        <div class="toro-nsn-widget-content toro-flex-child mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">

                <div class="toro-mini-mode-content-panel">
                    <div class="toro-mini-mode-content">
                        <div class="tn-icon-row"><fa-icon icon="bullhorn"></fa-icon></div>
                        <div class="tn-label-row">
                            <div class="tmm-bot-left-container tmm-ellipsis-text" style="bottom: unset;">{{'STRINGS.ANNOUNCEMENTS' | translate}}</div>
                        </div>
                    </div>

                    <div class="toro-mini-mode-content tn-value" [ngClass]="{'fetch-error': isUnableToFetchAnnouncements}">
                        {{isUnableToFetchAnnouncements ? announcementsCount : '!'}}
                    </div>
                </div>

                <div class="toro-mini-mode-content-panel" >
                    <div class="toro-mini-mode-content">
                        <div class="tn-icon-row"><fa-icon icon="shipping-fast"></fa-icon></div>
                        <div class="tn-label-row">
                            <div class="tmm-bot-left-container tmm-ellipsis-text" >{{'STRINGS.SHIPMENTS' | translate}}</div>
                        </div>
                    </div>
                    <div class="toro-mini-mode-content tn-value" [ngClass]="{'fetch-error': isUnableToFetchShipments}">
                        {{!isUnableToFetchShipments ? shipmentsCount : '!'}}
                    </div>
                </div>

                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>

</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
    >

        <div class="toro-nsn-widget-content toro-flex-child toro-link-button-host toro-component-shield-host">

            <div class="tn-row-one">
                <div class="tnro-left-pane">
                    <a class="toro-component-shield-host" (click)="onNsnClick()">
                        <img src="../../../../../../assets/images/toro-nsn-logo.png" alt="">
                        <toro-component-shield></toro-component-shield>
                    </a>
                </div>
                <div class="tnro-right-pane">
                    <div class="tnro-rp-header">
                        {{'TORO.NSN_CONNECT' | translate}}
                    </div>
                    <div class="tnro-rp-body">
                        <div class="nsn-connect-devices-list-container">
                            <div>
                                <toro-nsn-connect-device-card *ngFor="let device of nsnConnectDevices" [deviceInfo]="device"></toro-nsn-connect-device-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tn-row-two">
                <div class="tnrt-content-pane">
                    <a [href]="toroNsnAnnouncementsUrl" target="_blank" class="toro-component-shield-host" (click)="onAnnouncementsClick()">
                        <fa-icon icon="bullhorn"></fa-icon>
                        <div>{{'STRINGS.ANNOUNCEMENTS' | translate}}</div>
                        <div class="toro-notification-count tnrt-announce" [ngClass]="{'fetch-error': isUnableToFetchAnnouncements}">
                            {{!isUnableToFetchAnnouncements ? announcementsCount : '!'}}
                        </div>
                        <toro-component-shield></toro-component-shield>
                    </a>
                </div>
                <div class="tnrt-content-pane">
                    <a [href]="toroNsnShipmentsUrl" target="_blank" class="toro-component-shield-host" (click)="onShipmentsClick()">
                        <fa-icon icon="shipping-fast"></fa-icon>
                        <div>{{'STRINGS.SHIPMENTS' | translate}}</div>
                        <div class="toro-notification-count tnrt-ship" [ngClass]="{'fetch-error': isUnableToFetchShipments}">
                            {{!isUnableToFetchShipments ? shipmentsCount : '!'}}
                        </div>
                        <toro-component-shield></toro-component-shield>
                    </a>
                </div>
            </div>

            <div class="tn-row-three">
                <div class="tnrt-header">
                    {{'NSN Service'}}
                </div>
                <div class="tnrt-body">
                    <div class="tnrt-content-pane tnrt-expiry"><span class="tnrt-content-label">{{'STRINGS.EXPIRATION' | translate}}: </span>{{serviceExpiry}}</div>
                    <div class="tnrt-content-pane tnrt-status"><span class="tnrt-content-label">{{'STRINGS.STATUS' | translate}}: </span>{{serviceStatus}}</div>
                </div>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
