/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import * as moment from 'moment';
import { KittyHawkMapType } from './kitty-hawk-map-type.model';

export class KittyHawkLayer {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.created_on) this.created_on = moment.utc(json.created_on).toDate();
            if (json.map_type) this.map_type = new KittyHawkMapType(json.map_type);
        }
    }

    map_id: number;
    mission_uuid: string;
    map_type_id: number;
    status_id: number;
    left: any;                      // TODO: Change to proper type.
    center: any;                    // TODO: Change to proper type.
    right: any;                     // TODO: Change to proper type.
    created_on: Date;
    histogram: any;                 // TODO: Change to proper type.
    map_type: KittyHawkMapType;

}
