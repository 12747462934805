import * as Highcharts from 'highcharts';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashUserManagerService } from '../../../../../../api/dash-user/dash-user-manager.service';
import { TaskTrackerCostSummary } from '../../../../../../api/task-tracker/models/task-tracker-cost-summary.model';
import { TaskTrackerDailySummary } from '../../../../../../api/task-tracker/models/task-tracker-daily-summary.model';
import { TaskTrackerDailySummaryDepartment } from '../../../../../../api/task-tracker/models/task-tracker-daily-summary-department.model';
import { TranslateService } from '@ngx-translate/core';
import { UserFormatService } from '../../../../../../common/services/user-format.service';

@UntilDestroy()
@Component({
    selector: 'toro-task-tracker-labor-summary-chart',
    templateUrl: './task-tracker-labor-summary-chart.component.html',
    styleUrls: ['./task-tracker-labor-summary-chart.component.less']
})
export class TaskTrackerLaborSummaryChartComponent implements OnInit {
    @HostBinding('class') class = 'task-tracker-chart-component';
    @ViewChild('ttChartContainer') ttChartContainer: ElementRef;

    @Output() chartClick = new EventEmitter();

    private areasCount = 0;

    private _data: TaskTrackerDailySummary;
    @Input() set data(value: TaskTrackerDailySummary) {
        if (value == null) { return; }
        this._data = value;
    }

    get data(): TaskTrackerDailySummary {
        return this._data;
    }

    private _departmentIdsFilter: number[];
    @Input() set departmentIdsFilter(value: number[]) {
        this._departmentIdsFilter = value;

        if (this.data != null) {
            this.setLaborSummarySeriesData();
            this.updateChart();
        }
    }

    get departmentIdsFilter(): number[] {
        return this._departmentIdsFilter;
    }

    Highcharts = Highcharts;
    chart: Highcharts.Chart;
    chartOptions: any = null;
    chartContainerWidth: number;
    chartContainerHeight: number;
    laborSummarySeriesData: any;
    hasNoChartData = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private dashUserManager: DashUserManagerService,
                private translateService: TranslateService,
                private userFormatService: UserFormatService
    ) { }

    ngOnInit() {
        this.dashUserManager.dashUserPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                setTimeout(() => {
                    this.chart = null;
                    this.setupChart();
                });
            })
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private updateChart() {
        if (!this.chart) {
            setTimeout(() => this.setupChart());
        } else {
            this.chart.update({
                series: this.laborSummarySeriesData,
            });
        }
    }

    private setSizeOfChartContainer() {
        if (!this.ttChartContainer) { return; }
        this.chartContainerWidth = this.ttChartContainer.nativeElement.offsetWidth - 2;
        this.chartContainerHeight = this.ttChartContainer.nativeElement.offsetHeight - 2;
    }

    // TODO: TEMPORARY ===============
    /* eslint-disable @typescript-eslint/member-ordering */
    private readonly numBars = 30;

    // TODO: =========================

    private setupChart() {
        const self = this;
        const minBarWidth = 20;
        const minBarSpacing = 12;
        const maxBarsWithoutScroll = 10;

        this.setSizeOfChartContainer();

        this.chartOptions = {
            chart: {
                type: 'column',
                width: self.chartContainerWidth,
                height: self.chartContainerHeight,
                events: {
                    load(event) {self.chart = event.target; },
                    click() { self.chartClick.emit(); }
                },
                plotBackgroundColor: '#f3f3f3',
                spacing: [8, 0, (self.areasCount > 10 ? 15 : 5), 5],
                scrollablePlotArea: {
                    minWidth: self.areasCount > maxBarsWithoutScroll ? self.areasCount * (minBarWidth + minBarSpacing) : 0
                },
            },
            credits: { enabled: false },
            title: { text: '' },
            legend: {
                enabled: false,
                align: 'right',
                verticalAlign: 'top',
                reversed: true
            },
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: { enabled: false },
                labels: {
                    formatter() { return '<span>' + self.userFormatService.toUserCurrency(this.value, 0, 0) + '</span>'; }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const userValue = self.userFormatService.toUserCurrency(this.y);
                    return '<span>' + this.name + '</span><br><span>' + this.payType + ': ' + userValue + '</span>';
                },
                useHTML: true,
                followTouchMove: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    cursor: 'pointer',
                    marker: { states: { hover: { enabled: false, } } },
                    enableMouseTracking: true,  // Determines if mouse selection occurs on bars.
                },
                series: {
                    pointWidth: self.areasCount > 6 ? 20 : null,
                    events: {
                        click() { self.chartClick.emit(); }
                    },
                },
            },
            series: self.laborSummarySeriesData,
        };
    }

    private setLaborSummarySeriesData() {
        const costByAreaDict: { [name: string]: { regPay: number, otPay: number } } = {};
        const regPay: { name: string, y: number, payType: string }[] = [];
        const otPay: { name: string, y: number, payType: string }[] = [];

        const filteredDepartments: TaskTrackerDailySummaryDepartment[] = (this.departmentIdsFilter != null)
            ? this.data.departments.filter(d => this.departmentIdsFilter.includes(d.id))
            : this.data.departments.slice();

        this.areasCount = 0;

        filteredDepartments.forEach((dept: TaskTrackerDailySummaryDepartment) => {
            // TODO: TEMPORARY ===============
            let x = 0;
            // TODO: =========================

            dept.costSummaries.forEach((costSummary: TaskTrackerCostSummary) => {
                // TODO: TEMPORARY =================
                if (++x > this.numBars) { return; }
                // TODO: ===========================

                const costByArea = costByAreaDict[costSummary.name];
                if (costByArea) {
                    costByArea.regPay += costSummary.regWages;
                    costByArea.otPay += costSummary.otWages;
                    return;
                }

                ++this.areasCount;
                costByAreaDict[costSummary.name] = { regPay: costSummary.regWages, otPay: costSummary.otWages };
            });
        });

        Object.keys(costByAreaDict).forEach(key => {
            regPay.push({
                name: key,
                y: costByAreaDict[key].regPay,
                payType: this.translateService.instant('STRINGS.REG_PAY')
            });

            otPay.push({
                name: key,
                y: costByAreaDict[key].otPay,
                payType: this.translateService.instant('STRINGS.OVERTIME')
            });
        });

        this.laborSummarySeriesData = [
            {
                name: this.translateService.instant('STRINGS.OVERTIME'),
                color: '#f0ad4e',
                data: otPay
            },
            {
                name: this.translateService.instant('STRINGS.REG_PAY'),
                color: '#4eb6f2',
                data: regPay,
            }
        ];

        this.hasNoChartData = (otPay.length < 1) && (regPay.length < 1);
        if (this.hasNoChartData) { this.chart = null; }
    }

}
