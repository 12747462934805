<div id="spec-range-selector-container" class="toro-component-shield-host">
    <toro-spec-range-selector-btn
            *ngIf="showAll && rangeCounts?.length > 0"
            [style]="'flex: 1 1 ' + buttonFlexBasis + '%;'"
            [text]="'STRINGS.ALL' | translate"
            [(selectedIndex)]="selectedIndex"
    ></toro-spec-range-selector-btn>
    <toro-spec-range-selector-btn
            *ngFor="let item of rangeCounts; let i = index;"
            [style]="'flex: 1 1 ' + buttonFlexBasis + '%;'"
            [rangeCount]="item"
            [index]="i"
            [(selectedIndex)]="selectedIndex"
    ></toro-spec-range-selector-btn>

    <toro-component-shield *ngIf="!allowSelection" (shieldClick)="onClick()"></toro-component-shield>
</div>
