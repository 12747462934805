import { GreenSiteApiService } from './green-site-api.service';
import { Injectable } from '@angular/core';
import { KittyHawkGreen } from './models/kitty-hawk-green.model';
import { KittyHawkLocation } from './models/kitty-hawk-location.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GreenSiteManagerService {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private greenSiteApiService: GreenSiteApiService
    ) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getGreens(locationId: string): Observable<KittyHawkGreen[]> {
        return this.greenSiteApiService.getGreens((locationId));
    }

    getLayers(missionUuid: string): Observable<any> {
        return this.greenSiteApiService.getLayers(missionUuid);
    }

    getLocation(locationId: string): Observable<KittyHawkLocation> {
        return this.greenSiteApiService.getLocation(locationId);
    }

    getMissions(locationId: string): Observable<any> {
        return this.greenSiteApiService.getMissions(locationId);
    }

}
