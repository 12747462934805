<div id="step-faq-container">
    <div id="faq-header">
        {{'CASE_SENSITIVE.FAQS' | translate}}
    </div>
    <div id="faqs-container">
        <p-accordion styleClass="wiz-faqs" [multiple]="false">
            <p-accordionTab *ngFor="let faq of faqs" [header]="faq.question | translate">
                <div id="faq-answer-container" [ngSwitch]="faq.id">
                    <div *ngSwitchCase="WizardFaq.NotUpdating" [innerHTML]="faq.answer | translate: { url: 'https://my.toronsn.com/Support'}"></div>
                    <div *ngSwitchCase="WizardFaq.CustomIntegrations" [innerHTML]="faq.answer | translate: { email: 'NSNTech@toro.com'}"></div>
                    <div *ngSwitchDefault>{{faq.answer | translate}}</div>
                    <img [src]="faq.image" [style.height]="faq.imageHeight" alt="">
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
