export class PlaybooksGddSinceAppValue {

    constructor(json: any = null) {
        if (json) {
            // Object.assign(this, json);

            // Handle camel casing because this one api returns Pascal Cased values.
            if (json.SinceApp) { this.sinceApp = +json.SinceApp; }
        }
    }

    sinceApp: number;
}
