import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LynxCloudApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    init(): Observable<string> {
        return this.apiService.apiRequest<any>(LynxCloudApiService.initUrl, HttpMethod.Get)
            .pipe(
                map((initMessage: any) => {
                    const bytes = atob(initMessage.content);
                    return new TextDecoder().decode(new Uint8Array(bytes.split('').map(char => char.charCodeAt(0))));
                })
            );
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}LynxCloud`; }

    private static get initUrl(): string { return `${this.baseUrl}/init`}
}
