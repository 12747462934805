import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SpecFieldScoutDataResponse } from './models/spec-field-scout-data-response.model';
import { SpecFsGridData } from './models/spec-fs-grid-data.model';
import { SpecFsProfile } from './models/spec-fs-profile.model';
import { SpecFieldScoutCollections } from './models/spec-fs-collections.model';

@Injectable({
    providedIn: 'root'
})
export class SpectrumApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getFieldScoutData(apiKey: string, deviceType: string, startDate: Date, endDate: Date): Observable<SpecFieldScoutDataResponse> {
        return this.apiService.apiRequest<any>(this.getFieldScoutDataUrl(apiKey, deviceType, startDate, endDate), HttpMethod.Get)
            .pipe(map((response: SpecFieldScoutDataResponse) => new SpecFieldScoutDataResponse(response)))
    }

    getFieldScoutProfile(apiKey: string, profileId = 0): Observable<SpecFsProfile> {
        return this.apiService.apiRequest<any>(this.getFieldScoutProfileUrl(apiKey, profileId), HttpMethod.Get)
            .pipe(map((response: SpecFsProfile) => new SpecFsProfile(response)));
    }

    getFieldScoutGridData(apiKey: string, gridId: number): Observable<SpecFsGridData> {
        return this.apiService.apiRequest<any>(this.getFieldScoutGridDataUrl(apiKey, gridId), HttpMethod.Get)
            .pipe(map((response: SpecFsGridData) => new SpecFsGridData(response)));
    }

    getFieldScoutCollections(apiKey: string): Observable<SpecFieldScoutCollections> {
        return this.apiService.apiRequest<any>(this.getFieldScoutCollectionsUrl(apiKey), HttpMethod.Get)
            .pipe(map((response: SpecFieldScoutCollections) => new SpecFieldScoutCollections(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}spectrum`; }

    private getFieldScoutDataUrl(apiKey: string, deviceType: string, startDate: Date, endDate: Date): string {
        return `${SpectrumApiService.baseUrl}/FieldScoutData?apiKey=${apiKey}&deviceType=${deviceType}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }

    private getFieldScoutProfileUrl(apiKey: string, profileId: number): string {
        return `${SpectrumApiService.baseUrl}/FieldScoutProfile?apiKey=${apiKey}&profileId=${profileId}`;
    }

    private getFieldScoutGridDataUrl(apiKey: string, gridId: number): string {
        return `${SpectrumApiService.baseUrl}/FieldScoutGridData?apiKey=${apiKey}&gridId=${gridId}`;
    }

    private getFieldScoutCollectionsUrl(apiKey: string): string {
        return `${SpectrumApiService.baseUrl}/FieldScoutCollections?apiKey=${apiKey}`;
    }

}
