
export class PlaybooksMonthlyApplicationTotalCost {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

        }
    }

    monthlyTotal: number;
    ytd: number;
}
