import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-widget-button',
    templateUrl: './toro-widget-button.component.html',
    styleUrls: ['./toro-widget-button.component.less']
})
export class ToroWidgetButtonComponent {
    @Output() buttonClick = new EventEmitter();

    @Input() text: string;
    @Input() disabled: boolean;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClick() {
        this.buttonClick.emit();
    }
}
