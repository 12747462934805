/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import * as moment from 'moment';

export class KittyHawkMission {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.created) this.created = moment.utc(json.created).toDate();
            if (json.scheduled_for) this.scheduled_for = moment.utc(json.scheduled_for).toDate();
        }
    }

    id: number;
    created: Date;
    scheduled_for: Date;
    uuid: string;
    is_deleted: boolean;
    vehicle_uuid: string;
    location_uuid: string;
    status_id: number;
    deprecated_status: string;
    bts_directory: string;
    is_tile_tms_format: boolean;
    is_reupload: boolean;
    is_geotagged: boolean;
    is_uploaded: boolean;
    processed_with_gcp: boolean;
    files_status: number;
    geotag_status: number;
    stitch_status: number;
    tile_status: number;
    location_segment_uuid: any;             // TODO: Change to proper type.
    latitude: number;
    longitude: number;
    comments: string;
    mission_number: any;                    // TODO: Change to proper type.
    ndvi_mean: number;
    ndvi_stddev: number;
    temperature_mean: number;
    temperature_stddev: number;

}
