import { GenericMqttMessageContent } from './generic-mqtt-message-content.model';

export class GenericMqttMessage {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json['client-id']) this.clientId = json['client-id']
            if (json['message-id']) this.messageId = json['message-id']
            if (json['content-type']) this.contentType = json['content-type']
            if (json.content) {
                this.content = new GenericMqttMessageContent(this.isObject(json.content) ? json.content : JSON.parse(json.content));
            }
        }
    }

    subject: string;
    messageId: string;
    clientId: string;
    contentType: string;
    content: GenericMqttMessageContent;

    private isObject(value: any): boolean {
        return value !== null && typeof value === 'object';
    }

}
