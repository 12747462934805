/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class KittyHawkMapType {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: number;
    filename: string;
    tab_name: string;
    display_order: number;
    is_enabled: boolean;
    is_internal: boolean;
    is_make_tiles: boolean;
    display_blending: string;
    help_text: string;
    icon: string;

}
