import { Component } from '@angular/core';

@Component({
  selector: 'toro-no-stations-running',
  templateUrl: './no-stations-running.component.html',
  styleUrls: ['./no-stations-running.component.less']
})
export class NoStationsRunningComponent {

  constructor() { }

}
