import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'toro-waiting-indicator',
    templateUrl: './waiting-indicator.component.html',
    styleUrls: ['./waiting-indicator.component.less']
})
export class WaitingIndicatorComponent {
    @Output() okClick = new EventEmitter();

    @Input() backgroundColor = 'transparent';
    @Input() color: string;
    @Input() fontSize = '16px';
    @Input() iconSize = '40px';
    @Input() isVisible = true;
    @Input() text: string;
    @Input() percentComplete = -1;

    private _error: string = null;
    @Input() set error(value: string) {
        this._error = value;
        this.showError = (this._error && this._error.length > 0);
    }

    get error(): string {
        return this._error;
    }

    showError = false;

    constructor() {
    }

    onOkClick() {
        this.okClick.emit();
    }

}
