<toro-dashboard-widget-container
        [title]="title"
        [titleIcon]="titleIcon"
        [isBusy]="isBusy"
        [lastUpdated]="lastUpdated"
        [associatedWidget]="associatedWidget"
        [widgetMenuItems]="widgetMenuItems"
        [(alertText)]="alertText"
>

    <div style="background-color: cornflowerblue; flex: 1; overflow-x: hidden">
        <div style="height: 500px;">
            Widget Content
            <div>Display Cols: {{ displayCols }}</div>
        </div>
    </div>

</toro-dashboard-widget-container>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>
