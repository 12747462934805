import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TurfCloudApiService } from './turf-cloud-api.service';
import { TurfCloudCourseTrakk } from './models/turf-cloud-course-trakk.model';
import { TurfCloudLaborStatistics } from './models/turf-cloud-labor-statistics.model';

@Injectable({
    providedIn: 'root'
})
export class TurfCloudManagerService {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private turfCloudApiService: TurfCloudApiService
    ) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCourseTrakk(): Observable<TurfCloudCourseTrakk> {
        return this.turfCloudApiService.getCourseTrakk();
    }

    getLaborStats(date: Date): Observable<TurfCloudLaborStatistics> {
        return this.turfCloudApiService.getLaborStats(date);
    }
}
