import { SoilScoutGaugeRange } from './soil-scout-gauge-range.model';

export class SoilScoutThresholdChange {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    moistureGaugeRange: SoilScoutGaugeRange;
    salinityGaugeRange: SoilScoutGaugeRange;
    temperatureGaugeRange: SoilScoutGaugeRange;
}
