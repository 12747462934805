import { TaskTrackerMeasurementDetail } from './task-tracker-measurement-detail.model';

export class TaskTrackerMeasurement {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.mostRecent) { this.mostRecent = json.mostRecent.map(d => new TaskTrackerMeasurementDetail(d)); }
            if (json.previous) { this.previous = json.previous.map(d => new TaskTrackerMeasurementDetail(d)); }
        }
    }

    id: number;
    name: string;
    type: string;
    calByZone: number;
    measurementByAreaType: string;
    mostRecent: TaskTrackerMeasurementDetail[];
    avgMeasurementMostRecent: number;
    avgCalByZoneMostRecent: number;
    previous: TaskTrackerMeasurementDetail[];
    avgMeasurementPrevious: number;
    avgCalByZoneMostPrevious: number;
}
