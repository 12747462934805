<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-evapotranspiration-widget-container mini-mode-widget-container">

            <div class="toro-widget-content-pane toro-component-shield-host">
                <div class="toro-mini-mode-content-panel">
                    <div class="toro-mini-mode-content">
                        <i class="tmm-ul-icon toro-id-evapotranspiration"></i>
                        <div id="tew-mm-data-container">
                            <div id="tew-mm-data-today" class="tew-mm-datum-container">
                                <span class="tmm-large-value-label">{{'STRINGS.TODAY' | translate}}</span>
                                <span class="tew-mm-datum-value-container">
                                    <div class="toro-et-today-value">{{ dailyEtForDisplay[0]?.value || '--' }}</div>
                                    <div class="toro-et-today-units">{{ dailyEtForDisplay[0]?.units || '--' }}</div>
                                </span>
                            </div>
                            <div id="tew-mm-data-tomorrow" class="tew-mm-datum-container">
                                <span class="tmm-large-value-label">{{'STRINGS.TOMORROW' | translate}}</span>
                                <span class="tew-mm-datum-value-container">
                                    <fa-icon class="toro-et-trend-icon" [icon]="dailyEtForDisplay[1]?.icon?.icon || 'arrows-alt-h'" [style.color]="dailyEtForDisplay[1]?.icon?.color"></fa-icon>
                                    <div class="toro-et-tomorrow-value" [style.color]="dailyEtForDisplay[1]?.icon?.color">{{ dailyEtForDisplay[1]?.value || '--' }}</div>
                                    <div class="toro-et-tomorrow-units" [style.color]="dailyEtForDisplay[1]?.icon?.color">{{ dailyEtForDisplay[1]?.units || '--' }}</div>
                                </span>
                            </div>
                        </div>
                        <div class="tmm-bot-left-container"><div>{{'STRINGS.FORECAST' | translate}}</div></div>
                    </div>
                </div>
                <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-evapotranspiration-widget-container toro-component-shield-host">
            <div id="toro-evapotranspiration-widget-header" [ngClass]="{'fade-on-resize': isResizing}">{{'STRINGS.FORECAST' | translate}}</div>

            <div id="toro-evapotranspiration-widget-content">
                <div *ngIf="displayCols === 1" class="toro-widget-content-pane toro-size-one" [ngClass]="{'fade-on-resize': isResizing}">
                    <ng-container [ngTemplateOutlet]="evapotranspirationToday"></ng-container>
                </div>

                <div *ngIf="displayCols === 2" class="toro-widget-content-pane toro-size-two" [ngClass]="{'fade-on-resize': isResizing}">
                    <ng-container [ngTemplateOutlet]="evapotranspirationToday"></ng-container>

                    <div id="toro-et-forecast-container">
                        <div class="toro-et-forecast" *ngFor="let item of dailyEtForDisplay?.slice(1); let i = index;">
                            <div class="toro-et-today-label">{{ i !== 0 ? item.dateString : 'STRINGS.TOMORROW' | translate }}</div>
                            <fa-icon class="toro-et-trend-icon" [icon]="item.icon?.icon || 'arrows-alt-h'" [style.color]="item?.icon?.color"></fa-icon>
                            <div class="toro-et-value-container" [ngClass]="{'metric-units': unitsSystem === UnitsSystem.Metric}">
                                <div class="toro-et-tomorrow-value" [style.color]="item?.icon?.color">{{ item?.value || '--' }}</div>
                                <div class="toro-et-tomorrow-units" [style.color]="item?.icon?.color">{{ item?.units || '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <toro-component-shield></toro-component-shield>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<ng-template #evapotranspirationToday>
    <div class="toro-et-today-container toro-flex-child">
        <div class="toro-et-today-today">
            <div class="toro-et-today-label">{{'STRINGS.TODAY' | translate}}</div>
            <div class="toro-et-value-container" [ngClass]="{'metric-units': unitsSystem === UnitsSystem.Metric}">
                <div class="toro-et-today-value">{{ dailyEtForDisplay[0]?.value || '--' }}</div>
                <div class="toro-et-today-units">{{ dailyEtForDisplay[0]?.units || '--' }}</div>
            </div>
        </div>
        <div class="toro-et-today-tomorrow">
            <div class="toro-et-today-label">{{'STRINGS.TOMORROW' | translate}}</div>
            <fa-icon class="toro-et-trend-icon" [icon]="dailyEtForDisplay[1]?.icon?.icon || 'arrows-alt-h'" [style.color]="dailyEtForDisplay[1]?.icon?.color"></fa-icon>
            <div class="toro-et-value-container" [ngClass]="{'metric-units': unitsSystem === UnitsSystem.Metric}">
                <div class="toro-et-tomorrow-value" [style.color]="dailyEtForDisplay[1]?.icon?.color">{{ dailyEtForDisplay[1]?.value || '--' }}</div>
                <div class="toro-et-tomorrow-units" [style.color]="dailyEtForDisplay[1]?.icon?.color">{{ dailyEtForDisplay[1]?.units || '--' }}</div>
            </div>
        </div>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetSingleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
