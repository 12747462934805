import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TurfGuardWidgetData } from '../../../../../api/turf-guard/models/turf-guard-widget-data.model';

@Component({
    selector: 'toro-turf-guard-chart-dlg',
    templateUrl: './turf-guard-chart-dlg.component.html',
    styleUrls: ['./turf-guard-chart-dlg.component.less']
})
export class TurfGuardChartDlgComponent {
    @Output() cancel = new EventEmitter();

    @Input() title = '';
    @Input() turfGuardWidgetData: TurfGuardWidgetData;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor() { }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onClose() {
        this.cancel.emit();
    }

}
