export class SoilScoutDeviceMeasurement {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    timestamp: Date;
    moisture: number;           // API provides data in decimal format (e.g., 0.183 = 18.3%)
    temperature: number;        // API provides data in Celsius
    conductivity: number;
    salinity: number;           // API provides data in dS/m
    field_capacity: number;
    wilting_point: number;
    water_balance: number;
    dielectricity: number;

}
