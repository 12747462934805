import { CachedObject } from '../../_common/cached-object';
import { SelectItem } from 'primeng/api';
import { TaskTrackerDailySummaryDepartment } from './task-tracker-daily-summary-department.model';

export class TaskTrackerDailySummary extends CachedObject {
    private static cacheDurationInMinutes = 60;
    private _departmentsList: SelectItem[];
    private _filteredDepartments: TaskTrackerDailySummaryDepartment[];

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(json: any = null) {
        super(TaskTrackerDailySummary.cacheDurationInMinutes);

        if (json) {
            Object.assign(this, json);

            if (json.boardDate) { this.boardDate = new Date(json.boardDate); }
            if (json.departments) { this.departments = json.departments.map(d => new TaskTrackerDailySummaryDepartment(d)); }
        }

        this._filteredDepartments = this.departments.slice();
    }

    boardDate: Date;
    companyId: number;
    companyName: string;
    departments: TaskTrackerDailySummaryDepartment[] = [];

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    setDepartmentsFilter(filteredList: number[]) {
        this._filteredDepartments = this.departments.filter(d => filteredList?.includes(d.id));
    }

    get totalCompanyLaborDollars(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.totalLaborDollars, 0);
    }

    get totalCompanyLaborHours(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.totalHours, 0);
    }

    get totalCompanyTasksCompleted(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.totalTaskCompleted, 0);
    }

    get totalCompanyTasksOutstanding(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.totalTaskOutstanding, 0);
    }

    get totalCompanyScheduledEmployees(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.scheduledEmployees, 0);
    }

    get totalCompanyOffEmployees(): number {
        return this._filteredDepartments.reduce((sum, d) => sum += d.offEmployees, 0);
    }

    get departmentsList(): SelectItem[] {
        if (this._departmentsList == null) {
            this._departmentsList = [];
            this.departments.forEach(d => this._departmentsList.push({ value: d.id, label: d.name }));
        }
        return this._departmentsList;
    }

}
