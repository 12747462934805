import { Component, OnInit } from '@angular/core';
import { DatumRowEditorType, DatumTextJustify } from '../decision-tree-datum-row/decision-tree-datum-row.component';
import { finalize, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { MenuItem, SelectItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashMessageService } from '../../../../../common/services/dash-message.service';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { DecisionTreeConfig } from '../../../../../api/decision-tree/models/decision-tree-config.model';
import { DecisionTreeManagerService } from '../../../../../api/decision-tree/decision-tree-manager.service';
import { DeviceManagerService } from '../../../../../common/services/device-manager.service';
import { environment } from '../../../../../../environments/environment';
import { SensorThresholdsModel } from '../../../../../api/decision-tree/models/sensor-thresholds.model';
import { SensorThresholdsUpdateModel } from '../../../../../api/decision-tree/models/sensor-thresholds-update.model';
import { ToroAnalyticsEnums } from '../../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../../toro-dashboard-widget';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { ToroUtils } from '../../../../../common/utils/_toro.utils';
import { TranslateService } from '@ngx-translate/core';
import { TurfGuardManagerService } from '../../../../../api/turf-guard/turf-guard-manager.service';
import { TurfGuardSensor } from '../../../../../api/turf-guard/models/turf-guard-sensor.model';
import { UserFormatService } from '../../../../../common/services/user-format.service';
import { WeatherManagerService } from '../../../../../api/weather/weather-manager.service';
import { WidgetManagerService } from '../../../../../api/widgets/widget-manager.service';

import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import TurfGuardSensorType = ToroEnums.TurfGuardSensorType;

enum DecisionTreePanel {
    Thresholds,
    WidgetInfo,
    Breakdown
}

enum DatumRowColors {
    SoilMoistureTextAndBorder = '#eea345',
    SoilMoistureBackground = '#fcedda',
    CanopyCoverTextAndBorder = '#78ae80',
    CanopyCoverBackground = '#e4efe6',
    EstimatedRainTextAndBorder = '#bf2b73',
    EstimatedRainBackground = '#f2d5e3',
    ThresholdTextColor = '#525252',
}

enum DTIrrigationStatus {
    Configuring,
    Irrigate,
    HoldIrrigation
}

@UntilDestroy()
@Component({
    selector: 'toro-widget-decision-tree',
    templateUrl: './widget-decision-tree.component.html',
    styleUrls: ['./widget-decision-tree.component.less']
})
export class WidgetDecisionTreeComponent extends ToroDashboardWidget implements OnInit {
    DecisionTreePanel = DecisionTreePanel;
    DatumRowEditorType = DatumRowEditorType;
    DatumTextJustify = DatumTextJustify;
    DatumRowColors = DatumRowColors
    DTIrrigationStatus = DTIrrigationStatus;

    iconColor = 'black';
    title = 'WIDGET.DECISION_TREE';

    private readonly NO_VALUE = '--';

    private readonly transitionTimeInMs = 500;
    private readonly defaultSoilMoistureThreshold = 35;
    private readonly defaultCanopyCoverThreshold = 80;
    private readonly defaultEstimateRainThreshold = 0.3;
    private readonly defaultCanopyCover = 90;

    isInfoPanelVisible = false;
    isInfoPanelDisplayed = false;
    isThresholdsPanelVisible = false;
    isThresholdsPanelDisplayed = false;
    isBreakdownPanelVisible = false;
    isBreakdownPanelDisplayed = false;
    isMetric = false;

    infoText: string;
    infoIcon: any;

    soilMoisture: number = null;
    soilMoistureMin = 0;
    soilMoistureMax = 100;
    soilMoistureThresholdMin = 0;
    soilMoistureThresholdMax = 100;
    percentCanopyCoverMin = 0;
    percentCanopyCoverMax = 100;
    estimatedRainInches = 0.5;
    estimatedRainMinInches = 0.01;
    estimatedRainMaxInches = 0.5;
    estimatedRainUnits = 'in';
    estimatedRainThresholdUnits = '"';
    estimatedRain = this.estimatedRainInches;
    estimatedRainMin = this.estimatedRainMinInches;
    estimatedRainMax = this.estimatedRainMaxInches
    miniWidgetMenuItems: MenuItem[] = [];

    private _percentCanopyCover = this.defaultCanopyCover;
    set percentCanopyCover(value: number) {
        this._percentCanopyCover = value;
        this.widgetConfig.canopyCoverPercentage = value;
        this.updateDecisionTreeConfig();
        this.updateUIComponents();
    }

    get percentCanopyCover(): number {
        return this._percentCanopyCover;
    }

    private _soilMoistureThreshold = this.defaultSoilMoistureThreshold;
    set soilMoistureThreshold(value: number) {
        this._soilMoistureThreshold = value;
        this.setIrrigationStatus();

        if (value != this.defaultSoilMoistureThreshold) {
            this.updatePerSensorThresholds();
        }
    }

    get soilMoistureThreshold(): number {
        return this._soilMoistureThreshold;
    }

    _percentCanopyCoverThreshold = this.defaultCanopyCoverThreshold;
    set percentCanopyCoverThreshold(value: number) {
        this._percentCanopyCoverThreshold = value;
        this.widgetConfig.canopyCoverThreshold = value;
        this.setIrrigationStatus();
        this.updateDecisionTreeConfig();
    }

    get percentCanopyCoverThreshold(): number {
        return this._percentCanopyCoverThreshold;
    }

    _estimateRainThreshold = this.defaultEstimateRainThreshold;
    set estimateRainThreshold(value: number) {
        this._estimateRainThreshold = value;
        this.widgetConfig.estimatedRainThreshold = !this.isMetric ? value : this.userFormatService.millimetersToInches(value);
        this.setIrrigationStatus();
        this.updateDecisionTreeConfig();
    }

    get estimateRainThreshold(): number {
        return this._estimateRainThreshold;
    }

    language='en-us';
    zoneListItems: SelectItem[];
    sensorListItems: SelectItem[];
    sensorsList: TurfGuardSensor[];
    sensorThresholds: SensorThresholdsModel[];
    selectedZoneId: number;
    selectedSensorNumber: number;
    isButtonPanelVisible = false;
    irrigationStatus = DTIrrigationStatus.Configuring;
    greenCanopyIconSize = '22px'
    estRainIconSize = '22px';

    private widgetConfig: DecisionTreeConfig;

    // Mini Mode Vars
    selectedZoneName = '--';
    selectedSensorName = '--';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                protected analyticsService: AnalyticsService,
                protected broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private decisionTreeManager: DecisionTreeManagerService,
                protected translateService: TranslateService,
                private turfGuardManager: TurfGuardManagerService,
                private userFormatService: UserFormatService,
                private weatherManager: WeatherManagerService,
                private widgetManager: WidgetManagerService
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
                this.setDisplayValues();
            });

        this.language = this.dashUserManager.language;

        this.updateUIComponents();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    get analyticsWidgetName(): string {
        return AnalyticsEvent.DecisionTreeWidgetName;
    }

    protected getWidgetData(isManualRefresh) {
        const sources: Observable<any>[] = [
            this.decisionTreeManager.getDecisionTreeConfig(this.associatedWidget),
            this.turfGuardManager.getTurGuardWidgetData(TurfGuardSensorType.Moisture).pipe(take(1)),
            this.weatherManager.getWeatherGraphsData().pipe(take(1)),
        ];

        forkJoin(sources)
            .subscribe(([decisionTreeConfig, turfGuardWidgetData, weatherGraphData]) => {
                this.widgetConfig = decisionTreeConfig;
                this.estimatedRainInches = weatherGraphData.precipitation.values.map(v => v >= 0 ? v : 0).reduce((a, b) => a + b, 0);
                this.zoneListItems = turfGuardWidgetData.holesByZone.map(h => {
                    return { value: h.number, label: h.name };
                })

                this.setDisplayValues();
                this.clearIsUnableToFetchData();
                this.lastUpdateTimestamp = weatherGraphData.lastUpdated;
                this.setSavedConfigValues();

                this.hasNoData = weatherGraphData?.precipitation?.values.length === 0 ?? true;
                if (this.hasNoData) { this.isBusy = false; }
            }, () => {
                this.isUnableToFetchData = true;
                this.isBusy = false;
                if (this.isWidgetInitialized) { this.dashMessageService.showWidgetDataFetchErrorMessage(this.title); }
            });
    }

    protected setWidgetMenu() {
        super.setWidgetMenu();

        this.miniWidgetMenuItems = [...this.widgetMenuItems];

        this.widgetMenuItems.unshift(
            {
                label: ToroUtils.Translate.instant('STRINGS.WIDGET_INFO'),
                icon: 'pi pi-fw pi-info',
                command: this.toggleInfoPanel.bind(this),
            },
            { separator: true }
        );
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCloseSubPanel(subPanel: DecisionTreePanel) {
        switch (subPanel) {
            case DecisionTreePanel.Thresholds:
                this.toggleThresholdsPanel();
                break;
            case DecisionTreePanel.WidgetInfo:
                this.toggleInfoPanel();
                break;
            case DecisionTreePanel.Breakdown:
                this.toggleBreakdownPanel();
                break;
        }
    }

    onShowBreakdown() {
        this.toggleBreakdownPanel();
    }

    onZoneChange(event: any) {
        this.selectedZoneId = event.value;
        this.selectedSensorNumber = null;

        this.widgetConfig.selectedZoneId = this.selectedZoneId;
        this.widgetConfig.selectedSensorNumber = this.selectedSensorNumber;
        if (!environment.isDemoMode) { this.updateDecisionTreeConfig(); }

        this.setSoilMoistureDisplay();
        this.getSensorData(this.selectedZoneId);
        this.updateUIComponents();
    }

    onSensorChange(event: any) {
        this.selectedSensorNumber = event.value;

        this.widgetConfig.selectedSensorNumber = this.selectedSensorNumber;
        this.widgetConfig.avgSoilMoisture = this.soilMoisture;
        if (!environment.isDemoMode) { this.updateDecisionTreeConfig(); }

        this.setSoilMoistureDisplay();
        this.updateUIComponents();
    }

    toggleThresholdsPanel() {
        if (!this.isThresholdsPanelVisible) {
            this.hideOtherPanels(DecisionTreePanel.Thresholds);
            this.isThresholdsPanelVisible = true;
            setTimeout(() => this.isThresholdsPanelDisplayed = true)
        } else {
            this.isThresholdsPanelDisplayed = false;
            setTimeout(() => this.isThresholdsPanelVisible = false, this.transitionTimeInMs);
        }

        this.setMenuItemStates();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDisplayValues() {
        switch (this.language) {
            case 'ja-jp':
                this.greenCanopyIconSize = '17px'
                break;
            case 'de-de':
                this.greenCanopyIconSize = '17px'
                this.estRainIconSize = '17px'
                break;
            case 'fr-fr':
            case 'es-es':
                this.greenCanopyIconSize = '11px'
                break;
            default:
                this.greenCanopyIconSize = '22px';
                break;
        }

        // _estimateRainThreshold hold the value in the current user's units preference. The userFormatService.rainfall call below expects the value
        // to be in inches. Because of that, if we come to this method, and we were in metric already, convert the value back to imperial.
        if (this.isMetric) {
            this._estimateRainThreshold = this.userFormatService.millimetersToInches(this._estimateRainThreshold);
        }

        this.isMetric = this.userFormatService.isMetric;
        this.estimatedRain = <number>this.userFormatService.rainfall(this.estimatedRainInches);
        this.estimatedRainMin = <number>this.userFormatService.rainfall(this.estimatedRainMinInches);
        this.estimatedRainMax = <number>this.userFormatService.rainfall(this.estimatedRainMaxInches);
        this._estimateRainThreshold = <number>this.userFormatService.rainfall(this._estimateRainThreshold);

        this.estimatedRainUnits = this.userFormatService.precipitationUnits;
        this.estimatedRainThresholdUnits = this.userFormatService.rainfallUnits;
    }

    private updateUIComponents() {
        this.isButtonPanelVisible = false;
        this.infoIcon = 'exclamation-circle';
        this.irrigationStatus = DTIrrigationStatus.Configuring;

        if (this.selectedZoneId == null) {
            this.infoText = 'STRINGS.SELECT_A_ZONE';
            return;
        }

        if (this.selectedSensorNumber == null) {
            this.infoText = 'STRINGS.SELECT_A_SENSOR'
            return;
        }

        if (this.percentCanopyCover == null) {
            this.infoText = 'STRINGS.ADD_A_GREEN_SCORE'
            return;
        }

        if (this.isGridsterInMobileMode) {
            const selectedZone = this.zoneListItems.find(z => z.value === this.selectedZoneId);
            this.selectedZoneName = selectedZone ? selectedZone.label : this.NO_VALUE;

            const selectedSensor = this.sensorListItems.find((s => s.value === this.selectedSensorNumber))
            this.selectedSensorName = selectedSensor ? selectedSensor.label : this.NO_VALUE;
        }

        this.setIrrigationStatus();
        this.isButtonPanelVisible = true;
    }

    private setIrrigationStatus() {
        let irrigate = false;

        if (this.soilMoisture < this.soilMoistureThreshold) {
            if (this._percentCanopyCover < this.percentCanopyCoverThreshold) {
                if (this.estimatedRain < this.estimateRainThreshold) {
                    irrigate = true;
                }
            }
        }

        if (irrigate) {
            this.irrigationStatus = DTIrrigationStatus.Irrigate;
            this.infoText = 'STRINGS.IRRIGATE';
            this.infoIcon = 'check-circle';
        } else {
            this.irrigationStatus = DTIrrigationStatus.HoldIrrigation;
            this.infoText = 'STRINGS.HOLD_IRRIGATION';
            this.infoIcon = 'pause-circle';
        }
    }

    private setSavedConfigValues() {
        if (this.widgetConfig.canopyCoverPercentage != null) { this._percentCanopyCover = this.widgetConfig.canopyCoverPercentage; }
        if (this.widgetConfig.soilSensorThreshold != null) { this._soilMoistureThreshold = this.widgetConfig.soilSensorThreshold; }
        if (this.widgetConfig.canopyCoverThreshold != null) { this._percentCanopyCoverThreshold = this.widgetConfig.canopyCoverThreshold; }
        if (this.widgetConfig.estimatedRainThreshold != null) { this._estimateRainThreshold = this.widgetConfig.estimatedRainThreshold; }

        // Convert to metric if appropriate.
        if (this.isMetric) { this._estimateRainThreshold = <number>this.userFormatService.rainfall(this._estimateRainThreshold); }

        if (this.widgetConfig.selectedZoneId != null) {
            this.selectedZoneId = this.widgetConfig.selectedZoneId;
            this.getSensorData(this.selectedZoneId);
            return;
        }

        this.updateUIComponents();
        this.isBusy = false;
    }

    private updateDecisionTreeConfig() {
        this.widgetManager.updateWidgetConfig(this.associatedWidget.type, this.widgetConfig)
            .pipe(take(1))
            .subscribe(() => {

            }, () => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    private updatePerSensorThresholds() {
        if (environment.isDemoMode) { return; }

        const thresholds = this.sensorThresholds.find(t => t.sensorNumber == this.selectedSensorNumber);

        // TODO: Currently, we are only storing the soil sensor threshold with the sensor. If that changes, this logic will need to as well.
        if (thresholds != null && this.soilMoistureThreshold === thresholds.soilSensorThreshold) return;

        const selectedSensor = this.sensorsList.find(s => s.sensorNumber == this.selectedSensorNumber);
        if (selectedSensor == null) return;

        const user = this.authManager.dashAuthenticatedUser;
        const sensorThresholdsUpdateModel: SensorThresholdsUpdateModel = {
            siteId: user.siteId,
            userId: user.sid,
            holeNumber: selectedSensor.holeNumber,
            sensorNumber: selectedSensor.sensorNumber,
            soilSensorThreshold: this.soilMoistureThreshold,
            // canopyCoverThreshold: this.percentCanopyCoverThreshold,
            // rainThreshold: this.estimateRainThreshold
        }

        this.decisionTreeManager.addUpdateSensorThresholds(sensorThresholdsUpdateModel)
            .pipe(
                take(1),
                switchMap(() => this.decisionTreeManager.getSensorThresholds(selectedSensor.holeNumber).pipe(take(1)))
            )
            .subscribe((sensorThresholds: SensorThresholdsModel[]) => {
                this.sensorThresholds = sensorThresholds;
            }, () => {
                this.dashMessageService.showGenericSaveErrorMessage();
            })
    }

    private getSensorData(zoneNumber: number) {
        const sources: Observable<any>[] = [
            this.turfGuardManager.getTurfGuardSensors([zoneNumber]).pipe(take(1)),
            this.decisionTreeManager.getSensorThresholds(zoneNumber).pipe(take(1)),
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                this.updateUIComponents();
                this.isBusy = false;
            }))
            .subscribe(([sensors, sensorThresholds]) => {
                this.sensorsList = sensors;
                this.sensorThresholds = sensorThresholds;

                this.sensorListItems = sensors.map(s => {
                    return { value: s.sensorNumber, label: s.sensorNumber.toString() };
                })

                if (this.widgetConfig.selectedSensorNumber != null) {
                    this.selectedSensorNumber = this.widgetConfig.selectedSensorNumber;

                    this.setSoilMoistureDisplay();
                }

                this.performSensorThresholdsMaintenance();
            }, (err) => {
                this.dashMessageService.showWidgetDataFetchDetailedErrorMessage(this.title, 'ERR_MSG.UNABLE_TO_RETRIEVE_SENSOR_DATA');
            });
    }

    private performSensorThresholdsMaintenance() {
        const sensorNumbers = this.sensorsList.map(s => s.sensorNumber);
        const obsoleteThresholdsIcs = this.sensorThresholds.filter(t => !sensorNumbers.includes(t.sensorNumber)).map(s => s.id);

        if (obsoleteThresholdsIcs.length < 1) return;

        this.decisionTreeManager.deleteSensorThresholds(obsoleteThresholdsIcs).subscribe()
    }

    private setSoilMoistureDisplay() {
        if (this.sensorsList == null || this.sensorsList.length < 1) {
            this.soilMoisture = null;
            return;
        }

        const sensor = this.sensorsList.find(s => s.sensorNumber == this.selectedSensorNumber);
        if (sensor != null) {
            // Guard against invalid negative values.
            const moisture = +sensor.moisture.displayValue.toFixed(2);
            this.soilMoisture = moisture >= 0 ? moisture : 0;
        } else {
            this.soilMoisture = null;
        }

        this.setThresholds(sensor?.sensorNumber);
    }

    private setThresholds(sensorNumber?: number) {
        const sensorThresholds = this.sensorThresholds.find(t => t.sensorNumber === sensorNumber);
        this.soilMoistureThreshold = sensorThresholds != null ? sensorThresholds.soilSensorThreshold : this.defaultSoilMoistureThreshold;
    }

    private toggleInfoPanel() {
        if (!this.isInfoPanelVisible) {
            this.hideOtherPanels(DecisionTreePanel.WidgetInfo);
            this.isInfoPanelVisible = true;
            setTimeout(() => this.isInfoPanelDisplayed = true)
        } else {
            this.isInfoPanelDisplayed = false;
            setTimeout(() => this.isInfoPanelVisible = false, this.transitionTimeInMs);
        }

        this.setMenuItemStates();
    }

    private toggleBreakdownPanel() {
        if (!this.isBreakdownPanelVisible) {
            this.hideOtherPanels(DecisionTreePanel.Breakdown);
            this.isBreakdownPanelVisible = true;
            setTimeout(() => this.isBreakdownPanelDisplayed = true)
        } else {
            this.isBreakdownPanelDisplayed = false;
            setTimeout(() => this.isBreakdownPanelVisible = false, this.transitionTimeInMs);
        }

        this.setMenuItemStates();
    }

    private hideOtherPanels(displayedPanel: DecisionTreePanel) {
        if (displayedPanel !== DecisionTreePanel.Breakdown) {
            this.isBreakdownPanelDisplayed = false;
            this.isBreakdownPanelVisible = false;
        }
        if (displayedPanel !== DecisionTreePanel.Thresholds) {
            this.isThresholdsPanelDisplayed = false;
            this.isThresholdsPanelVisible = false;
        }
        if (displayedPanel !== DecisionTreePanel.WidgetInfo) {
            this.isInfoPanelDisplayed = false;
            this.isInfoPanelVisible = false;
        }
    }

    private setMenuItemStates() {
        setTimeout(() => {
            this.widgetMenuItems[DecisionTreePanel.Thresholds].disabled = this.isThresholdsPanelVisible;
            this.widgetMenuItems[DecisionTreePanel.WidgetInfo].disabled = this.isInfoPanelVisible;
        }, this.transitionTimeInMs * 2)
    }

}
