<p-dialog visible="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>
        <div id="sensor-dlg-header-container">
            <div class="toro-flex-child">{{title | translate | titlecase}}</div>
            <div class="toro-flex-child" id="sensor-dlg-header-alerts">
                <toro-turf-guard-alerts-header [turfGuardWidgetData]="turfGuardWidgetData"></toro-turf-guard-alerts-header>
            </div>
        </div>
    </p-header>

    <div id="toro-turf-guard-sensors-dlg-container" [ngClass]="{'fade-in-on-load': isDialogLoaded}">
        <div id="tgsd-left-pane" *ngIf="isInitialized">
            <ng-container [ngTemplateOutlet]="zoneListbox"></ng-container>
        </div>

        <div id="tgsd-right-pane" class="toro-flex-child">
            <div #tgChartContainer id="tgsd-chart-container" class="toro-flex-child">
                <highcharts-chart *ngIf="chartOptions"  [Highcharts]="Highcharts" [options]="chartOptions">
                </highcharts-chart>
                <div *ngIf="chartOptions && selectedZones?.length < 1" class="tgsd-no-zone-selected" [ngClass]="{'fade-in-warning': showNoZonesWarning}">{{'STRINGS.NO_ZONE_IS_SELECTED' | translate}}</div>
            </div>

            <div id="tgsd-zone-selector-container">
                <div id="tgsd-zone-selector-label">{{mobileSelectedZonesLabel}}</div>
                <p-button type="button" [label]="'STRINGS.CHANGE' | translate" (onClick)="changeZoneSelection()"></p-button>
            </div>

            <div id="tgsd-table-container" class="toro-flex-child">
                <p-table [value]="selectedSensorsDetails" [scrollable]="true" scrollHeight="flex" selectionMode="single" [(selection)]="selectedSensor">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'holeNumber'">{{'STRINGS.ZONE' | translate | titlecase}}<p-sortIcon [field]="'holeNumber'"></p-sortIcon></th>
                            <th [pSortableColumn]="'sensorNumber'">{{'STRINGS.SENSOR' | translate | titlecase}}<p-sortIcon [field]="'sensorNumber'"></p-sortIcon></th>
                            <th [pSortableColumn]="colFieldDisplayValue">{{colDisplayHeaderLabel | translate | titlecase}}<p-sortIcon [field]="colFieldDisplayValue"></p-sortIcon></th>
                            <th [pSortableColumn]="colFieldLimitMin">{{'STRINGS.LOW_LIMIT' | translate | titlecase}}<p-sortIcon [field]="colFieldLimitMin"></p-sortIcon></th>
                            <th [pSortableColumn]="colFieldLimitMax">{{'STRINGS.HIGH_LIMIT' | translate | titlecase}}<p-sortIcon [field]="colFieldLimitMax"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sensor>
                        <tr #tableRow [attr.id]="sensor.sensorNumber" [pSelectableRow]="sensor" [ngClass]="{'sensor-alert': sensorHasAlert(sensor)}">
                            <td>{{sensor.holeNumber}}</td>
                            <td>{{sensor.sensorNumber}}</td>
                            <td>{{getSensorValue(sensor)}}</td>
                            <td>{{getSensorLowLimit(sensor)}}</td>
                            <td>{{getSensorHighLimit(sensor)}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <div *ngIf="chartOptions && selectedZones?.length < 1" class="tgsd-no-zone-selected" [ngClass]="{'fade-in-warning': showNoZonesWarning}">{{'STRINGS.NO_ZONE_IS_SELECTED' | translate}}</div>
            </div>

        </div>
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="onClose()"></p-button>
    </p-footer>
</p-dialog>

<ng-template #zoneListbox>
    <p-listbox [options]="filteredZones" [(ngModel)]="selectedZones" [multiple]="true" [checkbox]="true" [showToggleAll]="false"  (onChange)="onZoneSelection($event)">
        <p-header>
            <div id="tgsd-list-filter" [ngClass]="language">
                <div class="tgsd-filter-button zones" [class]="language" [ngClass]="{'tgsd-selected': listFilter === ListFilter.AllZones}" (click)="toggleListFilter(ListFilter.AllZones)">
                    {{'STRINGS.ALL_ZONES' | translate | titlecase}}
                </div>
                <div class="tgsd-filter-button alerts" [class]="language" [ngClass]="{'tgsd-selected': listFilter === ListFilter.AlertsOnly}" (click)="toggleListFilter(ListFilter.AlertsOnly)">
                    {{'STRINGS.ALERTS_ONLY' | translate | titlecase}}
                </div>
            </div>
        </p-header>
        <ng-template let-zone pTemplate="item">
            <span #listItem [attr.id]="zone.value.number" [ngClass]="{'sensor-alert': zone.value.hasAlert}">{{zone.label}}</span>
        </ng-template>
    </p-listbox>
</ng-template>

<p-dialog [visible]="showZoneSelectionDialog" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%', maxHeight: '95%'}">
    <p-header>{{'STRINGS.ZONES' | translate}}</p-header>
    <div id="tg-zones-selector-dlg-container">
        <ng-container [ngTemplateOutlet]="zoneListbox"></ng-container>
    </div>
    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CLOSE' | translate" (onClick)="showZoneSelectionDialog = false;"></p-button>
    </p-footer>
</p-dialog>
