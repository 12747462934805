import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashboardWidgetHeaderComponent } from '../_dashboard-widget-header/dashboard-widget-header.component';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { filter } from 'rxjs/operators';
import { IWidgetDataSettings } from '../../../../api/_common/interfaces/widget-data-settings.interface';
import { LocalCacheService } from '../../../../common/services/local-cache.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ToroGridsterWidget } from '../toro-gridster-widget';

@UntilDestroy()
@Component({
    selector: 'toro-dashboard-widget-container',
    templateUrl: './dashboard-widget-container.component.html',
    styleUrls: ['./dashboard-widget-container.component.less']
})
export class DashboardWidgetContainerComponent implements OnInit, OnDestroy {
    @ViewChild('widgetHeader') widgetHeader: DashboardWidgetHeaderComponent;

    middleContentShieldSubscription: Subscription;
    subscriptionTimerRef;

    @Output() removeWidget = new EventEmitter();
    @Output() widgetSettings = new EventEmitter();
    @Output() alertTextChange = new EventEmitter<string>();
    @Output() showInfo = new EventEmitter();
    @Output() gotoSite = new EventEmitter();
    @Output() isUnableToFetchDataChange = new EventEmitter<boolean>();

    // This is either very clever or...
    // We use the 'Event Emitters' observers array to determine whether or not we should enable a clickable UX for the middle
    // content on the widget header. If we wire up a static event subscription method, e.g., (middleContentClick)="onSomeMethod()", this
    // will immediately increase the observers array even though we may not actually be subscribing downstream. This mechanism ensures
    // we only increase the observers array if we are truly subscribing from widget through to the ComponentShield Component.
    // This mechanism has been implemented in this component and the DashboardWidgetHeader Component.
    private _middleContentClick = new EventEmitter();
    @Output() get middleContentClick() {

        if (!this.middleContentShieldSubscription) {
            clearTimeout(this.subscriptionTimerRef);
            this.subscriptionTimerRef = setTimeout(() => {
                if (this.widgetHeader && this._middleContentClick.observers.length > 0) {
                    this.middleContentShieldSubscription = this.widgetHeader.middleContentClick.subscribe(() => {
                        this.middleContentClick.emit();
                    });
                }
            });
        }

        return this._middleContentClick;
    }

    @Input() title: string;
    @Input() titleIcon: string | TemplateRef<any>;
    @Input() showTitleIcon = false;
    @Input() middleContent: TemplateRef<any>;
    @Input() customToolbar: TemplateRef<any>;
    @Input() isBusy = false;
    @Input() spinnerText: string;
    @Input() lastUpdated: { duration: string, scale: string };
    @Input() associatedWidget: ToroGridsterWidget;
    @Input() widgetMenuItems: MenuItem[];
    @Input() menuStyleClass = 'toro-widget-menu'
    @Input() showInfoButton = false;
    @Input() unableToFetchDataReason: string;
    @Input() disableWidgetAlertBanner = false;      // Permanently disable banner for widgets that will not use it.
    @Input() hasNoData = false;
    @Input() analyticsWidgetName: string;
    @Input() showEmptyFooter = false;
    @Input() unableToFetchDataLinkHtml: string;
    @Input() isMiniModeWidget = false;
    @Input() showLinkButton = false;

    private _alertText: string;
    @Input() set alertText(value: string) {
        if (this._alertText === value) return;

        this._alertText = value;
        this.alertTextChange.emit(value);
    }

    get alertText(): string {
        return this._alertText;
    }

    private _isUnableToFetchData = false;
    @Input() set isUnableToFetchData(value: boolean) {
        if (this._isUnableToFetchData === value) return;

        this._isUnableToFetchData = value;
        this.isUnableToFetchDataChange.emit(value);
    }

    get isUnableToFetchData(): boolean {
        return this._isUnableToFetchData;
    }

    isSettingsDialogVisible = false;
    isWidgetDraggable = false;
    language = 'en-us';

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private localCacheService: LocalCacheService
    ) { }

    ngOnInit() {
        this.isWidgetDraggable = !this.localCacheService.widgetsLocked;

        this.broadcastService.showDataSettingsDialog
            .pipe(
                untilDestroyed(this),
                filter((widgetConfig: IWidgetDataSettings) => widgetConfig.widgetType === this.associatedWidget.type)
            )
            .subscribe(() => {
                this.isSettingsDialogVisible = true;
            });

        this.broadcastService.toggleWidgetDrag
            .pipe(untilDestroyed(this))
            .subscribe((state: { isLocked: boolean }) => this.isWidgetDraggable = !state.isLocked)

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
            });

        this.language = this.dashUserManager.language;

    }

    ngOnDestroy() {
        if (this.middleContentShieldSubscription) { this.middleContentShieldSubscription.unsubscribe(); }
    }

    // =========================================================================================================================================================
    // Public Properties
    // =========================================================================================================================================================

    // private _hideWidgetAlertBanner = false;
    get hideWidgetAlertBanner(): boolean {
        return !(<IWidgetDataSettings>this.associatedWidget.config).showStaleDataAlert;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    // onWidgetSettings() {
    //     this.widgetSettings.emit();
    // }
    //
    // onHideAlertBanner() {
    //     this.alertText = null;
    // }

    onShowInfo() {
        this.showInfo.emit();
    }

    onGotoSite() {
        this.gotoSite.emit();
    }
}
