/* eslint-disable @angular-eslint/directive-class-suffix */
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppInjector } from '../../../shared/shared.module';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { CalAmpAvlEvent } from '../../cal-amp/models/cal-amp-avl-event.model';
import { CalAmpDevice } from '../../cal-amp/models/cal-amp-device.model';
import { CalAmpMotionLogEvent } from '../../cal-amp/models/cal-amp-motion-log-event.model';
import { Directive } from '@angular/core';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import { UserFormatService } from '../../../common/services/user-format.service';

import CalAmpDeviceStatus = ToroEnums.CalAmpDeviceStatus;

@UntilDestroy()
@Directive()
export class AssetTrackingData {

    private readonly _userFormatService: UserFormatService;
    private readonly _broadcastService: BroadcastService;

    totalHours = '0';
    assetsWorking = 0;
    assetsIdle = 0;
    assetsTransporting = 0;
    assetsOff = 0;

    earliestMotionLogDate: moment.Moment;
    latestMotionLogDate: moment.Moment;
    latestAvlEventDate: moment.Moment;

    lastUpdated: Date;
    assets: CalAmpDevice[];
    hasMotionLogEvents = false;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(calAmpDevices: CalAmpDevice[]) {
        this.assets = calAmpDevices;
        this.lastUpdated = new Date();

        this._userFormatService = AppInjector.get(UserFormatService);
        this._broadcastService = AppInjector.get(BroadcastService);

        this._broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => this.setValues());
    }

     // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    update(calAmpDevices: CalAmpDevice[]) {
        this.assets = calAmpDevices;
        this.lastUpdated = new Date();
    }

    get hasAssets(): boolean {
        return this.assets != null && this.assets.length > 0;
    }

    setAvlData(assetEvents: Array<Array<CalAmpAvlEvent>>) {
        if (!this.hasAssets) {
            this.setValues();
            return;
        }

        assetEvents.forEach(eventSet => {
            const relatedAsset = this.assets.find(a => eventSet.length > 0 && a.esn === eventSet[0].deviceEsn);
            if (relatedAsset == null) { return; }

            relatedAsset.calAmpAvlEvents = eventSet;
        });

        this.setLatestAvlTime();
        this.setValues();
    }

    setMotionLogData(motionLogEvents: Array<Array<CalAmpMotionLogEvent>>) {
        this.hasMotionLogEvents = false;
        if (!this.hasAssets) { return; }

        motionLogEvents.forEach(eventSet => {
            if (eventSet.length < 1) { return; }

            const relatedAsset = this.assets.find(a => a.esn === +eventSet[0].deviceEsn);
            if (relatedAsset == null) { return; }

            relatedAsset.motionLogEvents = eventSet;
            this.hasMotionLogEvents = true;
        });

        this.setMotionLogDates();
    }

    clearSortedAssets() {
        this.hasMotionLogEvents = false;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setValues() {
        const totalHours = this.assets.reduce((sum, a) => sum += a.hours, 0);
        this.totalHours = this._userFormatService.toUserNumber(totalHours, 1, 2);

        this.assetsWorking = this.assets.filter(a => a.currentStatus === CalAmpDeviceStatus.Work).length;
        this.assetsIdle = this.assets.filter(a => a.currentStatus === CalAmpDeviceStatus.Idle).length;
        this.assetsTransporting = this.assets.filter(a => a.currentStatus === CalAmpDeviceStatus.Transport).length;
        this.assetsOff = this.assets.filter(a => a.currentStatus === CalAmpDeviceStatus.Off).length;
    }

    private setMotionLogDates() {
        let earliestDate = moment().add(1, 'years');
        let latestDate = moment().subtract(1, 'years');
        this.assets.forEach(device => {
            if (device.earliestMotionLogFix) {
                const currentMoment = moment(device.earliestMotionLogFix.timeOfFix);
                if (earliestDate > currentMoment) { earliestDate = currentMoment; }
            }

            if (device.latestMotionLogFix) {
                const currentMoment = moment(device.latestMotionLogFix.timeOfFix);
                if (latestDate < currentMoment) { latestDate = currentMoment; }
            }
        });

        this.earliestMotionLogDate = earliestDate;
        this.latestMotionLogDate = latestDate;
    }

    private setLatestAvlTime() {
        let latestDate = moment().subtract(1, 'years');
        this.assets.forEach(device => {
            const currentMoment = moment(device.latestAvlEventDate);
            if (latestDate < currentMoment) { latestDate = currentMoment; }
        });

        this.latestAvlEventDate = latestDate;
    }

}
