import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { ComponentShieldComponent } from '../../../../shared/components/component-shield/component-shield.component';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { LocalCacheService } from '../../../../common/services/local-cache.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';

import WidgetType = ToroEnums.WidgetType;
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'toro-dashboard-widget-header',
    templateUrl: './dashboard-widget-header.component.html',
    styleUrls: ['./dashboard-widget-header.component.less']
})
export class DashboardWidgetHeaderComponent implements OnInit, OnDestroy {
    @ViewChild('middleContentShield') middleContentShield: ComponentShieldComponent;

    middleContentShieldSubscription: Subscription;
    subscriptionTimerRef;

    private readonly PADDING_OFFSET = 16;

    @Output() widgetSettings = new EventEmitter();
    @Output() showInfo = new EventEmitter();
    @Output() gotoSite = new EventEmitter()

    // This is either very clever or...
    // We use the 'Event Emitters' observers array to determine whether or not we should enable a clickable UX for the middle
    // content on the widget header. If we wire up a static event subscription method, e.g., (middleContentClick)="onSomeMethod()", this
    // will immediately increase the observers array even though we may not actually be subscribing downstream. This mechanism ensures
    // we only increase the observers array if we are truly subscribing from widget through to the ComponentShield Component.
    // This mechanism has been implemented in this component and the DashboardWidgetContainer Component.
    private _middleContentClick = new EventEmitter();
    @Output() get middleContentClick() {

        if (!this.middleContentShieldSubscription) {
            clearTimeout(this.subscriptionTimerRef);
            this.subscriptionTimerRef = setTimeout(() => {
                if (this.middleContentShield && this._middleContentClick.observers.length > 0) {
                    this.middleContentShieldSubscription = this.middleContentShield.shieldClick.subscribe(() => {
                        this.middleContentClick.emit();
                    });
                }
            }, 100);
        }

        return this._middleContentClick;
    }

    @Input() title: string;
    @Input() titleIcon: string | TemplateRef<any>;
    @Input() showTitleIcon = false;
    @Input() middleContent: TemplateRef<any>;
    @Input() customToolbar: TemplateRef<any>;
    @Input() widgetId: number;
    @Input() widgetTypeId: WidgetType;
    @Input() showInfoButton = false;
    @Input() showLinkButton = false;
    @Input() menuStyleClass = 'toro-widget-menu'

    private _widgetMenuItems: MenuItem[];
    @Input() set widgetMenuItems(value: MenuItem[]) {
        this._widgetMenuItems = value;
        this.setMenuItems();
    }

    get widgetMenuItems(): MenuItem[] {
        return this._widgetMenuItems;
    }

    menuItems: MenuItem[];
    hasMiddleContentClickSubscribers = false;
    isMovable = true;
    maxHeaderTextWidth = 'unset';
    language = 'en-us'

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private dashUserManager: DashUserManagerService,
                private deviceManager: DeviceManagerService,
                private localCacheService: LocalCacheService,
                private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.setMenuItems();

        this.hasMiddleContentClickSubscribers = this.middleContentClick.observers.length > 0;

        this.broadcastService.toggleWidgetDrag
            .pipe(untilDestroyed(this))
            .subscribe((state: {isLocked: boolean}) => this.isMovable = !state.isLocked)

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setHeaderMaxWidth();
            });

        this.broadcastService.userPreferencesChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.language = this.dashUserManager.language;
                this.menuStyleClass = `${this.menuStyleClass} ${this.language}`;
            });

        this.isMovable = !this.localCacheService.widgetsLocked;
        this.language = this.dashUserManager.language;
        this.menuStyleClass = `${this.menuStyleClass} ${this.language}`
        this.setHeaderMaxWidth();
    }

    ngOnDestroy() {
        if (this.middleContentShieldSubscription) { this.middleContentShieldSubscription.unsubscribe(); }
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get isTitleIconString(): boolean {
        return this.titleIcon instanceof String;
    }

    get isTitleIconTemplate(): boolean {
        return this.titleIcon instanceof TemplateRef;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onRemoveWidget(event) {
        this.broadcastService.removeWidget.next({ widgetId: this.widgetId, widgetTypeId: this.widgetTypeId });
    }

    onShowInfo() {
        this.showInfo.emit();
    }

    onGotoSite() {
        this.gotoSite.emit();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setMenuItems() {
        this.menuItems = [
            { label: this.translateService.instant('CASE_SENSITIVE.REMOVE_WIDGET'), icon: 'pi pi-fw pi-trash', command: this.onRemoveWidget.bind(this) }
        ];

        if (this.widgetMenuItems && this.widgetMenuItems.length > 0) {
            this.menuItems.splice(0, 0, { separator: true });
            this.menuItems.splice(0, 0, ...this.widgetMenuItems);
        }
    }

    private setHeaderMaxWidth() {
        setTimeout(() => {
            // Set max width of title bar text
            const headerWidth = document.getElementById(`dashboard-header-${this.widgetId}`)?.clientWidth || 0;
            const toolbarWidth = document.getElementById(`header-toolbar-${this.widgetId}`)?.clientWidth || 0;
            const middleWidth = document.getElementById(`header-middle-${this.widgetId}`)?.clientWidth || 0;
            this.maxHeaderTextWidth = `${headerWidth - (toolbarWidth + middleWidth + this.PADDING_OFFSET)}` + 'px';
        }, 100);
    }

}
