import * as moment from 'moment';
import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SoilScoutCsvMeasurement } from './models/soil-scout-csv-measurement.model';
import { SoilScoutDevice } from './models/soil-scout-device.model';
import { SoilScoutGroup } from './models/soil-scout-group.model';
import { SoilScoutLogin } from './models/soil-scout-login.model';
import { SoilScoutLoginResponse } from './models/soil-scout-login-response.model';
import { SoilScoutNotification } from './models/soil-scout-notification.model';
import { SoilScoutRule } from './models/soil-scout-rule.model';
import { SoilScoutSite } from './models/soil-scout-site.model';
import { SoilScoutTokenRefresh } from './models/soil-scout-token-refresh.model';

@Injectable({
    providedIn: 'root'
})
export class SoilScoutApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient,
                private localCacheService: LocalCacheService
    ) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    login(login: SoilScoutLogin): Observable<SoilScoutLoginResponse> {
        return this.apiService.apiRequest<any>(this.loginUrl, HttpMethod.Post, login)
            .pipe(map((response: SoilScoutLoginResponse) => new SoilScoutLoginResponse(response)));
    }

    authTokenRefresh(refreshToken: SoilScoutTokenRefresh): Observable<SoilScoutLoginResponse> {
        return this.apiService.apiRequest<any>(this.authTokenRefreshUrl, HttpMethod.Post, refreshToken)
            .pipe(map((response: SoilScoutLoginResponse) => new SoilScoutLoginResponse(response)));
    }

    getSites(): Observable<SoilScoutSite[]> {
        return this.apiService.apiRequest<any>(this.sitesUrl, HttpMethod.Get)
            .pipe(map((response: SoilScoutSite[]) => response.map(r => new SoilScoutSite(r))));
    }

    getDevices(siteId: number) : Observable<SoilScoutDevice[]> {
        return this.apiService.apiRequest<any>(this.getDevicesUrl(siteId), HttpMethod.Get)
            .pipe(map((response: SoilScoutDevice[]) => response.map(d => new SoilScoutDevice(d))));
    }

    getGroups(siteId: number) : Observable<SoilScoutGroup[]> {
        return this.apiService.apiRequest<any>(this.getGroupsUrl(siteId), HttpMethod.Get)
            .pipe(map((response: SoilScoutGroup[]) => response.map(d => new SoilScoutGroup(d))));
    }

    getNotifications(siteId: number) : Observable<SoilScoutNotification[]> {
        return this.apiService.apiRequest<any>(this.getNotificationsUrl(siteId), HttpMethod.Get)
            .pipe(map((response: SoilScoutNotification[]) => response.map(n => new SoilScoutNotification(n))));
    }

    getRules(siteId: number) : Observable<SoilScoutRule[]> {
        return this.apiService.apiRequest<any>(this.getRulesUrl(siteId), HttpMethod.Get)
            .pipe(map((response: SoilScoutRule[]) => response.map(r => new SoilScoutRule(r))));
    }

    // getMeasurements(siteId: number, deviceIds: number[], cursor: string = null): Observable<SoilScoutMeasurementResponse> {
    //     return this.apiService.apiRequest<any>(this.getMeasurementsUrl(siteId, deviceIds, cursor), HttpMethod.Get)
    //         .pipe(map((response: SoilScoutMeasurementResponse) => {
    //             return new SoilScoutMeasurementResponse(response);
    //         }));
    // }

    getMeasurementsCSV(siteId: number, deviceIds: number[]): Observable<SoilScoutCsvMeasurement[]> {
        return this.apiService.apiRequest<any>(this.getMeasurementsCsvUrl(siteId, deviceIds), HttpMethod.Get)
            .pipe(map((response: SoilScoutCsvMeasurement[]) => response.map(m => new SoilScoutCsvMeasurement(m))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}soilScout`; }

    private get loginUrl() { return `${SoilScoutApiService.baseUrl}/login/`; }

    private get authTokenRefreshUrl() { return `${SoilScoutApiService.baseUrl}/token/refresh`; }

    private get sitesUrl() { return `${SoilScoutApiService.baseUrl}/sites?token=${this.localCacheService.soilScoutAccessToken}`; }

    private getDevicesUrl(siteId: number) {
        return `${SoilScoutApiService.baseUrl}/sites/${siteId}/devices?token=${this.localCacheService.soilScoutAccessToken}`;
    }

    private getGroupsUrl(siteId: number) {
        return `${SoilScoutApiService.baseUrl}/sites/${siteId}/groups?token=${this.localCacheService.soilScoutAccessToken}`;
    }

    private getNotificationsUrl(siteId: number) {
        return `${SoilScoutApiService.baseUrl}/sites/${siteId}/alerts/notifications?token=${this.localCacheService.soilScoutAccessToken}`;
    }

    private getRulesUrl(siteId: number) {
        return `${SoilScoutApiService.baseUrl}/sites/${siteId}/alerts/rules?token=${this.localCacheService.soilScoutAccessToken}`;
    }

    // private getMeasurementsUrl(siteId: number, deviceIds: number[], cursor: string = null) {
    //     const to = moment.utc();
    //     const from = moment.utc().subtract(7, 'days');
    //
    //     const deviceList = deviceIds.reduce((list, id) => `${list ? list + ',' : ''}${id}`, '');
    //     const uri = `${SoilScoutApiService.baseUrl}/sites/${siteId}/measurements/?deviceIds=${deviceList}&since=${from.toISOString()}&until=${to.toISOString()}&token=${this.localCacheService.soilScoutAccessToken}`;
    //
    //     return (cursor == null) ? uri : `${uri}&cursor=${cursor}`;
    // }

    private getMeasurementsCsvUrl(siteId: number, deviceIds: number[]) {
        const to = moment.utc();
        const from = moment.utc().subtract(7, 'days');

        const deviceList = deviceIds.reduce((list, id) => `${list ? list + ',' : ''}${id}`, '');
        return `${SoilScoutApiService.baseUrl}/sites/${siteId}/measurements/csv/?deviceIds=${deviceList}&since=${from.toISOString()}&until=${to.toISOString()}&token=${this.localCacheService.soilScoutAccessToken}`;
    }
}
