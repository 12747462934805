import { StationStatusStation } from './station-status-station.model';
import { ToroEnums } from '../../../../common/enumerations/toro.enums';
import LynxStationStatus = ToroEnums.LynxStationStatus;

export class StationStatusItem {

    constructor(areaName: string, holeName: string, station: StationStatusStation) {
        this.areaName = areaName;
        this.holeName = holeName;
        this.station = station;

        this.stationName = this.station?.name;

        if (this.station != null) {
            this.status = this.station.isRunning ? LynxStationStatus.Running : this.station.isRanUnder ? LynxStationStatus.RanUnder : LynxStationStatus.Unknown;
        }
    }

    areaName: string;
    holeName: string;
    stationName: string;
    status: LynxStationStatus = LynxStationStatus.Unknown;

    station: StationStatusStation;
}
