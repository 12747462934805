<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="true"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-lynx-current-weather-widget-content toro-flex-child tmm-carousel-host">

            <div class="tmm-carousel-container" [ngClass]="{'single-item': weatherStations?.length <= 1}">
                <p-carousel [value]="weatherStations" [numVisible]="1" [numScroll]="1" contentClass="toro-carousel-content" dotsContainerClass="toro-dots-container">
                    <ng-template let-station pTemplate="item">

                        <div class="toro-widget-content-pane toro-component-shield-host">
                            <div class="toro-mini-mode-content-panel">

                                <div class="lws-mm-ws-container">
                                    <div class="lws-mm-ws-name">{{station.name}}</div>
                                    <div class="lws-mm-ws-data">

                                        <div class="lws-mm-ws-datum">
                                            <div id="lws-mm-temp" class="tmm-icon-value-row-container">
                                                <div class="tmm-icon-value-row">
                                                    <div><i class="toro-id-temperature"></i></div>
                                                    <span class="tmm-iv-datum"><span class="tmm-iv-value">{{temperature[station.index]}}</span><span class="tmm-iv-value-label">°{{tempUnits}}</span></span>
                                                </div>
                                            </div>
                                            <div class="tmm-bot-left-container">
                                                <div class="tmm-ellipsis-text">{{'STRINGS.TEMPERATURE' | translate}}</div>
                                            </div>
                                        </div>

                                        <div class="lws-mm-ws-datum">
                                            <div id="lws-mm-et" class="tmm-icon-value-row-container">
                                                <div class="tmm-icon-value-row">
                                                    <div><i class="toro-id-evapotranspiration"></i></div>
                                                    <span class="tmm-iv-datum"><span class="tmm-iv-value">{{et[station.index]}}</span><span class="tmm-iv-value-label">{{etUnits}}</span></span>
                                                </div>
                                                <div class="tmm-bot-left-container">
                                                    <div class="tmm-ellipsis-text">{{'STRINGS.EVAPOTRANSPIRATION_ABBR' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="lws-mm-ws-datum">
                                            <div id="lws-mm-last-24" class="tmm-icon-value-row-container">
                                                <div id="tmm-ivr-high-low" class="tmm-icon-value-row">
                                                    <div><i class="toro-id-temperature"></i></div>
                                                    <div class="tmm-icon-value-item tmm-split-value">
                                                        <span class="tmm-iv-datum"><span class="tmm-iv-value">{{dailyHighTemperature[station.index]}}</span><span class="tmm-iv-value-label">°{{tempUnits}}</span></span>
                                                        <span class="tmm-iv-datum"><span class="tmm-iv-value">{{dailyLowTemperature[station.index]}}</span><span class="tmm-iv-value-label">°{{tempUnits}}</span></span>
                                                    </div>
                                                </div>
                                                <div id="tmm-ivr-precip" class="tmm-icon-value-row">
                                                    <div id="tmm-icon"><i class="toro-id-cloud-Rain"></i></div>
                                                    <div class="tmm-icon-value-item">
                                                        <span class="tmm-iv-datum"><span class="tmm-iv-value">{{this.precipitation[station.index]}}</span><span class="tmm-iv-value-label">{{precipitationUnits}}</span></span>
                                                    </div>
                                                </div>
                                                <div class="tmm-bot-left-container">
                                                    <div class="tmm-ellipsis-text">{{'STRINGS.LAST_24HRS' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <toro-component-shield (shieldClick)="onLaunchModalWidget()"></toro-component-shield>
                        </div>

                    </ng-template>
                </p-carousel>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [hasNoData]="hasNoData"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="true"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
    >

        <div class="toro-lynx-current-weather-widget-content toro-flex-child">
            <div id="tlcw-content-pane" class="no-bottom-border">
                <div id="tlcw-station-list">
                    <toro-lynx-current-weather-card
                            *ngFor="let station of weatherStations"
                            [weatherStation]="station"
                            [staleDataThresholdInMinutes]="staleDataThresholdInMinutes"
                            [hideAlertBanner]="hideWidgetAlertBanner"
                    ></toro-lynx-current-weather-card>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon icon="square" [ngStyle]="{'color': iconColor}"></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>
