/* eslint-disable @typescript-eslint/dot-notation */
import * as moment from 'moment';
import { CurrentWeather } from './current-weather.model';
import { DailyWeatherForecast } from './daily-weather-forecast';
import { ForecastWeather } from './forecast-weather.model';

export class LocalWeatherForecast {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);

            if (json.currentWeatherModel) this.currentWeather = new CurrentWeather(json.currentWeatherModel);
            if (json.forecast) this.forecastWeather = new ForecastWeather(json.forecast);
            if (json.tenDayForecast) this.tenDayForecast = json.tenDayForecast.map(f => new DailyWeatherForecast(f));
            if (json.lastUpdated) this.lastUpdated = moment.utc(json.lastUpdated).toDate();

            delete this['currentWeatherModel'];
            delete this['forecast'];
        }
    }

    currentWeather: CurrentWeather;
    forecastWeather: ForecastWeather;
    tenDayForecast: DailyWeatherForecast[];
    lastUpdated: Date;
}
