import { WeatherIcon } from './weather-icon.model';

export class WeatherIconInfo {

    constructor(primaryIcon: WeatherIcon = null, overlayIcon: WeatherIcon = null) {
        this.primaryIcon = primaryIcon;
        this.overlayIcon = overlayIcon;
    }

    primaryIcon: WeatherIcon;
    overlayIcon: WeatherIcon;

}
