<div id="sso-datum-container" (click)="sensorClicked()">
    <div id="ssod-header" class="prevent-select">
        <div id="ssod-title">
            <img [src]="'assets/images/soil-scout/' + datumIcon" alt="">
            <span id="datum-title">{{ datumTitle | translate }}</span>
        </div>
        <div id="ssod-arrow"><fa-icon icon="arrow-right"></fa-icon> </div>
    </div>
    <div id="ssod-chart">
        <div id="ssod-gauge">
            <div id="ssod-bar" [ngStyle]="{'background-image': barColor, 'width': barWidth + '%'}"></div>
            <div id="ssod-bar-value">{{displayValue}}</div>
        </div>
    </div>

</div>
