import { PwDailyHistorical } from './pw-daily-historical.model';

export class PwHistorical {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.dailyHistorical) this.dailyHistorical = json.dailyHistorical.map(h => new PwDailyHistorical(h));
        }
    }

    dailyHistorical: PwDailyHistorical[];

}
